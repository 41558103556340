<template>

    <div class="select-box-input-list">

        <select-box-input :label="label" :name="name" :rules="rules"

            :options="options" :value="getSelectedValue" 

            @input="updateSelectedOption" />

    </div>

</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            options: {
                type: Array,
                default() {
                    return []
                }
            },
            value: {
                type: Array,
                required: true
            },
            rules: {
                type: String,
                required: false
            }
        },
        
        methods: {
            updateSelectedOption(selected) {
                let type = this.getSelectedValue
                let count = 0
                
                // Delete old value if exist
                if (type !== '') {
                    this.$emit('input', { type, count })
                }
                
                type = selected
                count = 1
                
                // Add new value
                this.$emit('input', { type, count })
            }
        },
        
        computed: {
            getSelectedValue() {            
                for (let item of this.value) {
                    if (item.count) {
                        return item.type
                    }
                }

                return ''
            }
        }
    }
</script>

<style lang="scss">
    .select-box-input-list {
        display: flex;
        flex-direction: column;
    }
</style>
