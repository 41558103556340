<template>
    <header class="workflow-navigation-bar">

        <confirm-before-exit-modal
            ref="confirmModal"
            @exit="$emit('exit')"
        />

        <ul class="list">
            <li class="nav-item logo">
                <logo/>
            </li>
            <li class="nav-item title">{{ workflow.title }}</li>
            <li class="nav-item sub-title">{{ workflow.subTitle }}</li>
            <li class="nav-item exit" @click="$refs.confirmModal.show()"></li>
        </ul>

        <div class="step-progress" v-if="workflow.isInsert">

            <div class="progress-bar" :style="{ 'width': getProgress + '%' }"></div>

            <span class="step-position" :style="{ 'left': getProgress + '%' }">

               {{ $t('step_status', {position: totalStepFinished, total: totalStep}) }}

           </span>

        </div>

    </header>
</template>

<script>
import Logo from '~/components/logo'
import ConfirmBeforeExitModal from '~/components/v2/modal/confirm-before-exit'
import Workflow from "@/shared/Workflow";

export default {
    components: {
        Logo,
        ConfirmBeforeExitModal
    },

    props: {
        workflow: {
            type: Workflow,
            required: true
        },
    },

    computed: {
        totalStep() {
            return this.workflow.steps.length;
        },
        totalStepFinished() {
            return this.workflow.steps.filter((step) => step.isFinished).length
        },
        getProgress() {
            return (this.totalStepFinished * 100) / this.totalStep
        }
    }
}
</script>

<i18n>
{
    "fr": {
        "step_status": "Étape {position} sur {total}",
        "back": "Retour"
    }
}
</i18n>

<style lang="scss">
$white: #ffffff;
$white-bar: #EFEFEF;
$progress-bar-background-color: rgba(#6c6c6c, 0.25);
$progress-bar-color: #BED000;
$header-height: 68px;

.workflow-navigation-bar {
    min-height: $header-height;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;

    .list {
        display: flex;
        flex-direction: row;
        flex: 1;
        background: $white;
        color: white;

        .nav-item {
            display: flex;
            align-items: center;
            padding: 15px 30px;

            &.logo {
                border-right: 1px solid $white-bar;
            }

            &.title {
                font-size: 13.5pt;
                color: #393939;
            }

            &.sub-title {
                margin-top: 3px;
                font-size: 11pt;
                color: #9B9B9B;
            }

            &.exit {
                $icon-width: 16px;

                margin-left: auto;
                border-left: 1px solid $white-bar;
                position: relative;
                flex-basis: $icon-width;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &:before, &:after {
                    position: absolute;
                    content: '';
                    display: flex;
                    background: #525254;
                    border-radius: 2px;
                    width: 2px;
                    height: $icon-width;
                }

                &:before {
                    transform: rotate(45deg);
                }

                &:after {
                    transform: rotate(-45deg);
                }
            }

        }
    }

    .step-progress {
        position: relative;
        height: 4px;
        width: 100%;
        background: $progress-bar-background-color;

        .progress-bar {
            content: '';
            position: absolute;
            display: flex;
            left: 0;
            top: 0;
            width: 30%;
            height: 100%;
            background: $progress-bar-color;
        }

        .step-position {
            height: 24px;
            position: absolute;
            background: $progress-bar-color;
            left: 30%;
            top: 0;
            font-size: 11pt;
            font-weight: 300;
            color: white;
            padding: 5px 15px;
            transform: translateX(-100%);
            white-space: nowrap;
        }
    }
}
</style>
