export default () => {
    return {
        created_at: '',
        email_address: '',
        email_verified: false,
        full_name: '',
        id: '',
        password: '',
        password_reset_redirect_url: 'auth/renew-password',
        onboarding_progress: 0,
        property_management_company: {},
        role: '',
        swan_user: {
            verification_status: '',
            verification_url: ''
        },
        last_login_at: ''
    }
}
