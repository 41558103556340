import {queryBuilder, queryResponse} from '~/utils/query'

export const strict = true

export const actions = {
    async fetchGuaranteeFunds({dispatch}, params) {
        try {

            const response = await dispatch('tyltoApi/get', ['guarantee-funds', {params: queryBuilder(params)}], {root: true})

            return queryResponse(response)

        } catch (error) {

            throw error
        }
    },

    async fetchGuaranteeFund({dispatch}, guaranteeFundId) {
        try {

            let guaranteeFund = await dispatch('tyltoApi/get', 'guarantee-funds/' + guaranteeFundId, {root: true})

            return guaranteeFund

        } catch (error) {

            throw error
        }
    },
}
