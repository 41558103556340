<template>

    <div class="tooltip" v-show="visible">

        <p class="description" v-html="text" />

        <button-input class="button background-white" :text="buttonText" @click.stop="confirm" />

    </div>

</template>

<script>    
    export default {
        name: 'tooltip',

        data() {
            return {
                visible: true
            }
        },

        mounted() {
            this.visible = this.defaultVisible
        },
        
        props: {
            defaultVisible: {
                type: Boolean,
                default: true
            },
            text: {
                type: String,
                required: true
            },
            buttonText: {
                type: String,
                default() {
                    return this.$t('close')
                }
            }
        },
        
        methods: {
            confirm() {
                this.$emit('confirm')

                this.visible = false
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "close": "Ok, merci!"
        }
    }
</i18n>

<style lang="scss">
    $tooltip-background: #BED000;
    
    .tooltip {
        z-index: 22;
        position: absolute;
        box-shadow: 0 4px 18px 0 rgba(4,16,41,.19);
        background: $tooltip-background;
        border-radius: 10px;
        padding: 24px 30px;
        max-width: 200px;
        top: 50%;
        right: -18px;
        transform: translate(100%, -50%);
        
        .description {
            font-size: 12pt;
            color: white;
        }
        
        .button {
            box-shadow: 0 2px 0 0 rgba(0,0,0,.1);
            margin-top: 42px;
            font-size: 11pt;
            width: 100%;
        }
        
        &:before {
            position: absolute;
            display: flex;
            content: "";
            width: 10px;
            height: 10px;
            background: $tooltip-background;
            top: 50%;
            left: 0;
            transform: translate(-50%,-50%) rotate(45deg);
        }

        &.top {
            top: 0;
            right: 0;
            transform: translate(0%, -100%);
            
            &:before {
                top: 100%;
                left: 87%;
                transform: translate(-50%,-50%) rotate(45deg);
            }
        }
    }
</style>