export default () => {
    return {
        address: {},
        amount_of_security: 0,
        business_card_number: '',
        business_card_place_of_issue: '',
        business_name: '',
        business_logo: '',
        created_at: '',
        id: '',
        incorporation_date: '',
        management_and_ownership: [],
        email_address: '',
        phone_number: '',
        identification_number: '',
        trade_name: '',
        share_capital: 0,
        is_email_notification_enabled: false,
        accounting_code: '',
        swan_account_holder: {
            verification_status: ''
        },
        swan_onboarding: {
            onboarding_state: '',
            onboarding_url: ''
        },
        stripe_subscription: {},
        web_banking: '',
        default_fee_vat_rate: 0,
        relationships: {
            bank_accounts: [],
            fees: [],
            mandates: [],
            payment_method: {},
            guarantee_fund: {},
            logo: {},
            mediator: {}
        }
    }
}
