<template>
    
    <modal class="delete-landlord-modal" :title="$t('delete-landlord')" :options="modalOptions" @submitted="hide" @canceled="hide" @close="hide" ref="modal">

        <p class="subtitle" v-html="$t('text', { fullName: fullName })" />

    </modal>

</template>

<script>
    import Modal from '~/components/modal'
    import { mapGetters } from 'vuex'
    
    export default {
        components: {
            Modal
        },

        computed: {
            ...mapGetters({
                fullName: 'profile/getFullName'
            })
        },

        data() {
            return {
                modalOptions: {
                    submit: {
                        action: () => this.deleteLandlord()
                    }
                }
            }
        },

        methods: {
            show() {
                this.$refs.modal.show()
            },

            hide() {
                this.$refs.modal.hide()
            },

            async deleteLandlord() {
                try {
                    
                    await this.$store.dispatch('profile/deleteLandlord')

                    this.$router.push('/dashboard/landlords')

                    this.$toast.success(this.$t('landlord-deleted'), { icon: 'check' })
                
                } catch(errors) {

                    throw errors
                }
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "text": "Êtes-vous sûr de vouloir supprimer le propriétaire <b>{fullName}</b> ?",
            "delete-landlord": "Suppression du propriétaire",
            "landlord-deleted": "Suppression effectuée."
        }
    }
</i18n>