<template>
    
    <div class="property-address-table-cell">

        <span class="line">{{ line1.charAt(0).toUpperCase() + line1.substring(1) }}</span>

        <span class="line">{{ line2 }}</span>

    </div>

</template>

<script>
    export default {
        props: {
            address: {
                type: Object,
                required: true
            }
        },

        computed: {
            line1() {
                let line1 = typeof this.address.house_number !== 'undefined' 
                    ? this.address.house_number + ' ' 
                    : ''
                
                line1 += this.address.street_name

                return line1
            },

            line2() {
                return this.address.postal_code + ', ' + this.address.city
            }
        }
    }
</script>

<style lang="scss">
    .property-address-table-cell {
        display: flex;
        flex-direction: column;
    }
</style>
