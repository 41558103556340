import { mapGetters } from 'vuex'

export default {
    methods: {
        /**
         * [nbPiecesPrincipales description]
         * @return {[type]} [description]
         */
        getMainLivingAreasCount(property) {
            const mainAreasCodes = this.$store.getters['property/getPiecesDeSejour']

            if (typeof property.items === 'undefined') return 0;

            return property.items.filter(item => mainAreasCodes.includes(item.type)).map(item => item.count).reduce((a, b) => a + b, 0)
        },

        /**
         * @return {String}
         * @param property
         */
        getPropertyNatureShorthand(property) {
            const dwellingType = this.$t(`mixins.property-shorthand.${property.dwelling_type}`),
                mainLivingAreasCount = this.getMainLivingAreasCount(property),
                roomInfo = property.dwelling_type === 'flat' || property.dwelling_type === 'house' ?
                mainLivingAreasCount > 1 ? this.$t('mixins.property-shorthand.rooms', { nb: mainLivingAreasCount }) : this.$t('mixins.property-shorthand.studio')
                : '',
                area = this.$t('mixins.property-shorthand.area', { area: (property.area / 100).toLocaleString() }),
                reference = this.$t('mixins.property-shorthand.reference', { reference: property.reference }),
                caseNumber = typeof property.case_number !== 'undefined' ? this.$t('mixins.property-shorthand.case_number', { caseNumber: property.case_number }) : '';


            return `${dwellingType} ${roomInfo ? `  •  ${roomInfo}` : ''}  •  ${area}  •  ${reference} ${typeof property.case_number !== 'undefined' ? `  • ${caseNumber}` : ''}`;
        }
    },

    computed: {
        ...mapGetters({
            IsFurnished: 'property/getIsFurnished'
        })
    }
}
