<template>

    <ValidationProvider tag="div" class="form-group" :vid="name" :name="label ? label.toLowerCase() : ''" :rules="rules" v-slot="{ errors, validate, reset }" :custom-messages="customErrorMessages" :disabled="disabled" data-vv-scope="form-1" ref="provider">

        <div class="proxy" :errors="$emit('errors', errors)" v-show="false" />

        <label class="label" :for="id" v-if="label && showLabel" :style="customLabelStyle">

            <p class="label" v-html="label"></p>

            <status-tag class="status-tag" type="warning" icon="missing" v-if="missing">{{ $t('missing') }}</status-tag>

        </label>

        <div class="form-group-input">

            <slot v-bind:id="id" v-bind:validate="validate" v-bind:errors="errors" v-bind:reset="reset" v-bind:setErrors="setErrors" :class="{ 'error': errors.length }"></slot>

            <tooltip :text="tooltip" ref="tooltip" v-if="tooltip.length" />

        </div>

        <span v-if="errors.length" class="error client-side">{{ errors[0] }}</span>

    </ValidationProvider>

</template>

<script>
    import Tooltip from '~/components/tooltip'
    import StatusTag from '~/components/status-tag'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            Tooltip,
            StatusTag
        },

        props: {
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            rules: {
                type: [String, Object],
                default: ''
            },
            customErrorMessages: {
                type: Object,
                default: () => ({})
            },
            tooltip: {
                type: String,
                default: '',
            },
            missing: {
                type: Boolean,
                default: false
            },
            showLabel: {
                type: Boolean,
                default: true
            },
            disabled: {
                type: Boolean,
                default: false
            },
            customLabelStyle: {
                type: Object,
                default: () => ({})
            }
        },

        computed: {
            id() {
                return this.name + '-' + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)
            },

            validationErrors() {
                return this.validationErrorFields
                    .filter(error => typeof error.source !== 'undefined' && typeof error.source.pointer !== 'undefined')
                    .filter(error => error.source.pointer.includes(this.name))
                    .map(error => error.detail)
            },

            ...mapGetters({
                validationErrorFields: 'workflow/getErrorFields'
            })
        },

        methods: {
            setErrors(errors) {
                return this.$refs.provider.setErrors([errors])
            }
        },

        watch: {
            validationErrors(errors) {
                return this.$nextTick(() => this.$refs.provider.setErrors(errors))
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "missing": "Manquant"
        }
    }
</i18n>

<style lang="scss">
    $label-color: #393939;
    $error-color: #F5A623;

    .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 36px;
        // align-items: flex-start;

        &:last-child {
            margin-bottom: 0;
            align-items: initial;
        }

        .label {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            color: $label-color;
            font-weight: 600;
            font-size: 11pt;
            font-style: normal;

            p {
                margin: 0
            }

            .status-tag {
                margin-left: 12px;
            }
        }

        .form-group-input {
            position: relative;
            display: flex;
            flex-direction: column;
            flex: 1;
            width: 100%;
        }

        .error {
            margin-top: 8px;
            font-size: 11pt;
            color: $error-color;
        }
    }
</style>
