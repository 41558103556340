<template>

    <div class="user-settings-drop-down" @click="toggleDropDown">

        <progress-circle-chart class="progress-chart" :value="userOnboardingProgress" :width="30" :progressionNumberFontSize="0" circleBgColor="#EFEFEF" circleProgressBgColor="#BED000" v-if="!isProfilCompleted"/>

        <div :class="['user-infos', { 'collapsed': collapsed }]">

            <p class="auth-user">{{ userFullName ? userFullName : userEmailAddress }}</p>

            <p class='progression-info' v-if="userOnboardingProgress !== 100" >{{ 'Profil complété à ' + userOnboardingProgress + '%' }}</p>

        </div>

        <drop-down class="drop-down" :options="userOptions" @selectOption="redirect($event)" :open="!collapsed" @click-outside="toggleDropDown" />

    </div>

</template>

<script>
    import { mapGetters } from 'vuex'
    import UserSettingsDropDown from '~/components/navigation/user-settings-drop-down'
    import DropDown from '~/components/drop-down'
    import ProgressCircleChart from '~/components/widget/progress-circle-chart'
    import Modal from '~/components/modal'
    import { CreditTransferIcon } from '~/components/icons/button-icons'
    import SvgIcon from '~/components/svg-icon'


    export default {

        components: {
            UserSettingsDropDown,
            DropDown,
            ProgressCircleChart,
            Modal,
            CreditTransferIcon,
            SvgIcon
        },

        data() {
            return {
                collapsed: true,
                isProfilCompleted: false,
            }
        },

        methods: {
            toggleDropDown() {

                this.collapsed = !this.collapsed
            },

            copy() {
                navigator.clipboard.writeText('OskarLbi')

                this.$toast.success(this.$t('copied'), { icon: 'check' })
            },

            redirect(path) {
                if (path.includes('https')) {

                    window.open(path, '_blank')

                } else {

                    this.$router.push(path)
                }
            },

            show() {
                this.$refs.modal.show()
            },

            hide() {
                this.$refs.modal.hide()
            },
        },

        computed: {
            ...mapGetters({
                userFullName: 'user/getFullName',
                userOnboardingProgress: 'user/getOnboardingProgress',
                userEmailAddress: 'user/getEmailAddress',
                swanOnboarding: 'property-manager/getSwanOnboarding',
                getWebBanking: 'property-manager/getWebBanking',
            }),

            userOptions() {
                let options = [
                    // Nous la remettrons en place lorsqu’on aura traiter le sujet du multi comptes pour une même agence.
                    // {
                    //     value: '/auth/switch-account',
                    //     text: this.$t('switch_account'),
                    //     icon: 'exchange',
                    //     iconColor: '#767676'
                    // },
                    {
                        value: '/settings',
                        text: this.$t('personal_settings'),
                        icon: 'account',
                        iconColor: '#767676'
                    },
                    {
                        value: '/settings/company-details',
                        text: this.$t('company_details'),
                        icon: 'settings',
                        iconColor: '#767676'
                    },
                    {
                        value: 'https://support.oskar.immo/',
                        text: this.$t('help'),
                        icon: 'help',
                        iconColor: '#767676'
                    },
                    {
                        value: '/oskar-courses',
                        text: this.$t('oskar_courses'),
                        icon: 'play',
                        iconColor: '#767676'
                    },
                    {
                        value: "/viewer/CGU/cgu",
                        text: this.$t('CGU'),
                        icon: 'cgu',
                        iconColor: '#767676'
                    },
                    {
                        value: '/auth/logout',
                        text: this.$t('signout'),
                        icon: 'logout',
                        iconColor: '#767676'
                    }
                ]

                if (this.swanOnboarding?.onboarding_state === 'Completed') {

                    options.splice(2, 0, {
                        value: this.getWebBanking,
                        text: this.$t('web_banking'),
                        icon: 'bank',
                        iconColor: '#767676'
                    })

                    return options

                } else {

                    return options
                }
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "signout": "Se déconnecter",
            "personal_settings": "Profil et préférences",
            "company_details": "Paramètres de l'agence",
            "switch_account": "Changer de compte",
            "web_banking": "Compte de paiement en ligne",
            "help": "Aide & support",
            "CGU": "Conditions générales d'utilisation",
            "title": "Conditions générales d'utilisation",
            "text": "Pour accéder aux conditions générales d'utilisation, un mot de passe vous sera demandé pour des raisons de sécurité.",
            "password": "OskarLbi",
            "password_title": "Mot de passe : ",
            "redirectCgu": "Accéder aux CGU",
            "copied": "Mot de passe copié !",
            "oskar_courses": "Formations Oskar"
        }
    }
</i18n>

<style lang="scss">
    $header-height: 68px;
    $drop-down-item-color: #767676;

    .user-settings-drop-down {
        max-width: 270px;
        position: relative;
        display: flex;
        align-items: center;

        .progress-chart {
            position: initial;
            margin-right: 12px;
        }

        .user-infos {
            position: relative;
            padding-right: 30px;
            white-space: nowrap;
            overflow: hidden;
            cursor: pointer;

            &:after {
                position: absolute;
                top: 50%;
                right: 0;
                content: '';
                display: flex;
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 5px solid #393939;
                transform: translateY(-50%);
                transition: transform .2s ease-in-out;
            }

            &.collapsed:after{
                transform: translateY(-50%) rotate(180deg);
            }
        }

        .auth-user {
            color: #393939;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .progression-info {
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            color: rgba(57, 57, 57, 0.6);
        }

        .drop-down {
            border: 1px solid #D4D9E7;
            border-radius: 4px;
            box-shadow: 0 5px 20px 0 rgba(4,16,41,0.02);
            background: white;
            color:  #0E0A07;
            font-size: 11pt;
            max-height: 285px;
            min-width: 350px;
            overflow-y: auto;
            transform-origin: top;
            font-weight: 400;
            top: calc(#{$header-height} - 25px);
            right: 0;
            position: absolute;

                .drop-down-option {
                    cursor: pointer;
                    margin-bottom: 10px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #F2F2F2;
                    display: flex;
                    align-items: center;
                    font-weight: 400 !important;
                }
            }


        .drop-down::-webkit-scrollbar {
            display: none;
        }
    }
</style>
