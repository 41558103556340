import { utils } from 'jsonapi-vuex'
import { queryBuilder, queryResponse } from '~/utils/query'

export default {
    async createRent({ dispatch, rootGetters, getters, commit }, rentalId) {
        try {

            commit('setRentableType', 'rents')

            let rentData = await dispatch('formatRentRequest')

            let id = typeof rentalId === 'number' ? rentalId : rootGetters['rental/getId']

            const rent = await dispatch('tyltoApi/post', [rentData, { url: 'rentals/' + id + '/rents' }], { root: true })

            await dispatch('fillRentable', rent)

            return rent

        } catch(error) {

            throw error
        }
    },

    async createTenantCost({ dispatch, rootGetters }) {
        try {

            const tenantCost = await dispatch('formatRentRequest')

            const response = await dispatch('tyltoApi/post', [tenantCost, { url: 'rentals/' + rootGetters['rental/getId'] + '/costs' }], { root: true })

            dispatch('fillRent', response)

            return response

        } catch (error) {

            throw error
        }
    },

    async deleteRent({ dispatch, getters }, { rentableType, rentableId }) {
        try {

            let id = typeof rentableId !== 'undefined' ? rentableId : getters['getId']

            let type = typeof rentableType !== 'undefined' ? rentableType : getters['getRentableType']

            return await dispatch('tyltoApi/delete', `${type}/${id}`, { root: true })

        } catch(error) {

            throw error
        }
    },

    async fetchRentalRelationship({ dispatch, commit, getters }, { rentableType, rentableId }) {
        try {

            let rentRental = {}

            const id = typeof rentableId !== 'undefined' ? rentableId : getters['getId']

            const type = typeof rentableType !== 'undefined' ? rentableType : getters['getRentableType']

            const { data } = await this.$axios({ method: 'get', url: `${type}/${id}/relationships/rental` })

            if (data['data'] !== null) rentRental = await dispatch('rental/fetchRental', data['data']['id'], { root: true })

            commit('setRelatedRental', rentRental)

            return rentRental

        } catch(error) {

            throw error
        }
    },

    async fetchRentReceiptRelationship({ dispatch, commit, getters }, { rentableType, rentableId }) {
        try {

            let rentReceipt = {}

            const id = typeof rentableId !== 'undefined' ? rentableId : getters['getId']

            const type = typeof rentableType !== 'undefined' ? rentableType : getters['getRentableType']

            const { data } = await this.$axios({ method: 'get', url: `${type}/${id}/relationships/rent_receipt` })

            if (data['data'] !== null) rentReceipt = await dispatch('file/fetchFile', data['data']['id'], { root: true })

            commit('setRelatedRentReceipt', rentReceipt)

            return rentReceipt

        } catch(error) {

            throw error
        }
    },

    async fetchRentNoticeRelationship({ dispatch, commit, getters }, { rentableType, rentableId }) {
        try {

            let rentNotice = {}

            const id = typeof rentableId !== 'undefined' ? rentableId : getters['getId']

            const type = typeof rentableType !== 'undefined' ? rentableType : getters['getRentableType']

            const { data } = await this.$axios({ method: 'get', url: `${type}/${id}/relationships/rent_notice` })

            if (data['data'] !== null) rentNotice = await dispatch('file/fetchFile', data['data']['id'], { root: true })

            commit('setRelatedRentNotice', rentNotice)

            return rentNotice

        } catch(error) {

            throw error
        }
    },

    async fetchPaymentsRelationship({ dispatch, commit, getters }, { rentableType, rentableId }) {
        try {

            let rentPayments = []

            const id = typeof rentableId !== 'undefined' ? rentableId : getters['getId']

            const type = typeof rentableType !== 'undefined' ? rentableType : getters['getRentableType']

            const { data } = await this.$axios({ method: 'get', url: `${type}/${id}/relationships/payments` })

            if (data['data'] !== null) rentPayments = await Promise.all(data['data'].map(payment => dispatch('payment/fetchPayment', payment.id, { root: true })))

            commit('setRelatedPayments', rentPayments)

            return rentPayments

        } catch (error) {

            throw error
        }
    },

    async fetchTenantsRelationship({ dispatch, commit, getters }, { rentableType, rentableId }) {
        try {

            let rentTenants = []

            const id = typeof rentableId !== 'undefined' ? rentableId : getters['getId']

            const type = typeof rentableType !== 'undefined' ? rentableType : getters['getRentableType']

            const { data } = await this.$axios({ method: 'get', url: `${type}/${id}/relationships/tenants` })

            if (data['data'] !== null) rentTenants = await Promise.all(data['data'].map(async tenant => await dispatch('profile/fetchTenant', tenant['id'], { root: true })))

            commit('setRelatedTenants', rentTenants)

            return rentTenants

        } catch (error) {

            throw error
        }
    },

    async fetchRents({ dispatch }, params) {
        try {

            const response = await dispatch('tyltoApi/get', ['rents', { params: queryBuilder(params) }], { root: true })

            return queryResponse(response)

        } catch(error) {

            throw error
        }
    },

    async fetchRentsNotPaid({ dispatch, rootGetters }, params) {
        try {

            const response = await dispatch('tyltoApi/get', [`/property-management-companies/${rootGetters['property-manager/getId']}/rents`, { params: queryBuilder(params) }], { root: true })

            return queryResponse(response)

        } catch(error) {

            throw error
        }
    },

    async fetchRentalRents({ dispatch }, { params }) {
        try {

            const response = await dispatch('tyltoApi/get', 'rentals/' + params.rentalId + '/rents', { root: true })

            return queryResponse(response)

        } catch(error) {

            throw error
        }
    },

    async fetchPropertyRentables({ dispatch, rootGetters }, params) {
        try {

            const response = await dispatch('tyltoApi/get', ['properties/' + rootGetters['property/getId'] + '/rentables', { params: queryBuilder(params) }], { root: true })

            return queryResponse(response)

        } catch(error) {

            throw error
        }
    },

    async fetchPropertyRents({ dispatch, rootGetters }, args) {
        try {

            let id = typeof args?.params?.propertyId !== 'undefined' ? args?.params?.propertyId : rootGetters['property/getId']

            const response = await dispatch('tyltoApi/get', ['properties/' + id + '/rents', { params: queryBuilder(args) }], { root: true })

            return queryResponse(response)

        } catch(error) {

            throw error
        }
    },

    async fetchLandlordRents({ dispatch, rootGetters }, params) {
        try {

            let id = typeof params.landlordId !== 'undefined' ? params.landlordId : rootGetters['profile/getId']

            const response = await dispatch('tyltoApi/get', ['landlords/' + id + '/rents', { params: queryBuilder(params) }], { root: true })

            return queryResponse(response)

        } catch(error) {

            throw error
        }
    },

    async fetchTenantRents({ dispatch, rootGetters }, params) {
        try {

            let id = typeof params.tenantId !== 'undefined' ? params.tenantId : rootGetters['profile/getId']

            const response = await dispatch('tyltoApi/get', ['tenants/' + id + '/rents', { params: queryBuilder(params) }], { root: true })

            return queryResponse(response)

        } catch(error) {

            throw error
        }
    },

    async fetchTenantRentables({ dispatch, rootGetters }, params) {
        try {

            let id = typeof params.tenantId !== 'undefined' ? params.tenantId : rootGetters['profile/getId']

            const response = await dispatch('tyltoApi/get', ['tenants/' + id + '/rentables', { params: queryBuilder(params) }], { root: true })

            return queryResponse(response)

        } catch(error) {

            throw error
        }
    },

    async fetchRentalRentables({ dispatch, rootGetters }, params) {
        try {

            let id = typeof params.rentalId !== 'undefined' ? params.rentalId : rootGetters['rental/getId']

            const response = await dispatch('tyltoApi/get', ['rentals/' + id + '/rentables', { params: queryBuilder(params) }], { root: true })

            return queryResponse(response)

        } catch (error) {

            throw error
        }
    },

    async fetchPropertyManagementCompanyRents({ dispatch, rootGetters }, params) {
        try {

            let id = typeof params.propertyManagementCompanyId !== 'undefined' ? params.propertyManagementCompanyId : rootGetters['property-manager/getId']

            const response = await dispatch('tyltoApi/get', ['property-management-companies/' + id + '/rents', { params: queryBuilder(params) }], { root: true })

            return queryResponse(response)

        } catch(error) {

            throw error
        }
    },

    async fetchRentable({ dispatch, rootGetters }, { rentableId, rentableType }) {
        try {

            let id = typeof rentableId !== 'undefined' ? rentableId : getters['getId']

            let type = typeof rentableType !== 'undefined' ? rentableType : getters['getRentableType']

            return await dispatch('tyltoApi/get', `${rentableType}/${rentableId}`, { root: true })

        } catch(error) {

            throw error
        }
    },

    async fetchAndFillRentable({ dispatch }, { rentableId, rentableType }) {
        try {

            let rentable = await dispatch('fetchRentable', { rentableId, rentableType })

            dispatch('fillRentable', rentable)

            return rentable

        } catch(error) {

            throw error
        }
    },

    async fillRentable({ commit }, rentable) {
        commit('resetDefaultState')

        if (typeof rentable['_jv'] !== 'undefined') {
            commit('setRentableType', rentable['_jv']['type'])
            commit('setId', rentable['_jv']['id'])
        }

        if (typeof rentable['amount_due'] !== 'undefined')
            commit('setAmountDue', rentable['amount_due'])

        if (typeof rentable['amount_paid'] !== 'undefined')
            commit('setAmountPaid', rentable['amount_paid'])

        if (typeof rentable['items'] !== 'undefined')
            commit('setItems', rentable['items'])

        if (typeof rentable['payment_date'] !== 'undefined')
            commit('setPaymentDate', rentable['payment_date'])

        if (typeof rentable['payment_due_date'] !== 'undefined')
            commit('setPaymentDueDate', rentable['payment_due_date'])

        if (typeof rentable['period_end_date'] !== 'undefined')
            commit('setPeriodEndDate', rentable['period_end_date'])

        if (typeof rentable['period_start_date'] !== 'undefined')
            commit('setPeriodStartDate', rentable['period_start_date'])

        if (typeof rentable['reference'] !== 'undefined')
            commit('setReference', rentable['reference'])

        if (typeof rentable['status'] !== 'undefined')
            commit('setStatus', rentable['status'])

        if (typeof rentable['total_amount'] !== 'undefined')
            commit('setTotalAmount', rentable['total_amount'])

        if (typeof rentable['vat_amount'] !== 'undefined')
            commit('setVatAmount', rentable['vat_amount'])

        if (typeof rentable['payout_date'] !== 'undefined')
            commit('setPayoutDate', rentable['payout_date'])

        if (typeof rentable['invoice_number'] !== 'undefined')
            commit('setInvoiceNumber', rentable['invoice_number'])

        if (typeof rentable['date'] !== 'undefined')
            commit('setDate', rentable['date'])

        if (typeof rentable['rental'] !== 'undefined' && Object.keys(rentable['rental']).length)
            commit('setRelatedRental', rentable['rental'])

        if (typeof rentable['rent_notice'] !== 'undefined' && Object.keys(rentable['rent_notice']).length)
            commit('setRelatedRentNotice', rentable['rent_notice'])

        if (typeof rentable['rent_receipt'] !== 'undefined' && Object.keys(rentable['rent_receipt']).length)
            commit('setRelatedRentReceipt', rentable['rent_receipt'])

        if (typeof rentable['tenants'] !== 'undefined' && rentable['tenants'].length)
            commit('setRelatedTenants', rentable['tenants'])
    },

    async formatRentRequest({ getters }) {
        let rent = {
            _jv: {
                type: getters['getRentableType'],
                relationships: {}
            }
        }

        if (getters['getItems'].length) {
            rent['items'] = getters['getItems'].map(rentItem => {

                if(rentItem.description === '') delete rentItem.description

                return rentItem
            })
        }

        if (getters['getPeriodEndDate'] !== '') {
            rent['period_end_date'] = getters['getPeriodEndDate']
        }

        if (getters['getPeriodStartDate'] !== '') {
            rent['period_start_date'] = getters['getPeriodStartDate']
        }

        if (getters['getStatus'] !== '') {
            rent['status'] = getters['getStatus']
        }

        if (getters['getPaymentDate'] !== '') {
            rent['payment_date'] = getters['getPaymentDate']
        }

        if (getters['getPaymentDueDate'] !== '') {
            rent['payment_due_date'] = getters['getPaymentDueDate']
        }

        if (getters['getAmountDue'] !== '') {
            rent['amount_due'] = getters['getAmountDue']
        }

        if (getters['getRefundAmount'] !== 0) {
            rent['refund_amount'] = getters['getRefundAmount']
        }

        if (getters['getPayoutDate'] !== '') {
            rent['payout_date'] = getters['getPayoutDate']
        }


        if (getters['getInvoiceNumber'] !== '') {
            rent['invoice_number'] = getters['getInvoiceNumber']
        }

        if (getters['getDate'] !== '') {
            rent['date'] = getters['getDate']
        }

        if (getters['getId'] !== '') {
            rent['_jv'].id = getters['getId']
        }

        return rent
    },

    async updateRent({ dispatch, getters, commit }) {
        try {

            commit('setRentableType', 'rents')

            let rent = await dispatch('formatRentRequest')

            if (!Object.keys(getters['getRelatedRentNotice']).length && getters['getStatus'] === 'unpaid') await dispatch('createRentNoticeRelationship')

            else if (!Object.keys(getters['getRelatedRentReceipt']).length && getters['getStatus'] === 'paid') await dispatch('createRentReceiptRelationship')

            return await dispatch('tyltoApi/patch', rent, { root: true })

        } catch(error) {

            throw error
        }
    },

    async updateRentNoticeRelationship({ getters, commit }) {
        try {

            let rentNotice = {
                data: null
            }

            if (Object.keys(getters['getRelatedRentNotice']).length) {
                rentNotice['data'] = {
                    type: 'files',
                    id: getters['getRelatedRentNotice']['_jv'].id
                }
            }

            const response = await this.$axios({ method: 'patch', url: 'rents/' + getters['getId'] + '/relationships/rent_notice', data: rentNotice })

            commit('tyltoApi/deleteRecord', { _jv: { type: 'rents', id: getters['getId'] } }, { root: true })

            return response

        } catch(error) {

            throw error
        }
    },

    async updateRentReceiptRelationship({ getters, commit }) {
        try {

            let rentReceipt = {
                data: null
            }

            if (Object.keys(getters['getRelatedRentReceipt']).length) {
                rentReceipt['data'] = {
                    type: 'files',
                    id: getters['getRelatedRentReceipt']['_jv'].id
                }
            }

            const response = await this.$axios({ method: 'patch', url: 'rents/' + getters['getId'] + '/relationships/rent_receipt', data: rentReceipt })

            commit('tyltoApi/deleteRecord', { _jv: { type: 'rents', id: getters['getId'] } }, { root: true })

            return response

        } catch(error) {

            throw error
        }
    },

    async updatePaymentsRelationship({ getters }, params) {
        try {

            let rentableId = typeof params.rentableId !== 'undefined' ? params.rentableId : getters['getId']

            let rentableType = typeof params.rentableType !== 'undefined' ? params.rentableType : getters['getRentableType']

            let payments = {
                data: getters['getRelatedPayments'].map(payment => {
                    return {
                        type: 'payments',
                        id: payment['_jv'].id
                    }
                })
            }

            return await this.$axios({ method: 'patch', url: `${rentableType}/${rentableId}/relationships/payments`, data: payments })

        } catch(error) {

            throw error
        }
    }
}
