<template>
    
    <ul class="breadcrumb">
        
        <button-input class="grey-light-border-color back" :text="$t('back')" iconImg="back" iconColor="#525254" @click="$router.push(redirectTo)" v-if="redirectTo" />

        <li class="item" v-for="(item, key) in items" :key="key">

            <nuxt-link class="text" :to="item.redirectPath" no-prefetch v-if="item.redirectPath">{{ item.text }}</nuxt-link>

            <span class="text" v-else>{{ item.text }}</span>

        </li>
        
    </ul>

</template>

<script>
    export default {
        props: {
            items: {
                type: Array,
                required: true
            },
            redirectTo: {
                type: String,
                required: false
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "back": "Retour"
        }
    }
</i18n>

<style lang="scss">
    $active-color: #767676;
    
    .breadcrumb {
        display: flex;
        flex-direction: row;

        .back {
            margin-right: 12px;
            height: 32px;
            min-height: inherit;
            background: white;
            padding-right: 12px;
            padding-left: 12px;
            font-size: 9.75pt;

            .icon {
                height: 10px;
            }
        }

        .item {
            display: flex;
            align-items: center;
            color: #767676;
            opacity: 0.75;

            .text {
                font-size: 11pt;
                font-weight: 500;
                color: inherit;
            }

            &:after {
                transform: rotate(45deg);
                margin: 0 12px;
                content: '';
                display: flex;
                width: 5px;
                height: 5px;
                border-right: 1px solid #525254;
                border-top: 1px solid #525254;
            }

            &:last-child {
                margin-right: 0;
                color: $active-color;
                opacity: 1;

                &:after {
                    display: none;
                }
            }
        }
    }
</style>
