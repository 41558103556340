<template>

    <div :class="['property-drop-down', { 'collapsed': collapsed || loading }]">

        <span class="selected-property" @click="toggleDropDown">

            <template v-if="propertyId">{{ propertyAddress.formatted }}</template>

            <template v-else>{{ label }}</template>

        </span>
    
        <spinner-loader v-if="loading" />
        
        <drop-down class="property-select" :options="fetchedOptions" :open="!collapsed" @click-outside="toggleDropDown" v-else>

            <template v-slot:option="{ option }">

                <div class="property-option-container" @click="selectProperty(option.value)">

                    <dropdown-item iconImg='property' :mainInfo="option.line1 " :secondInfo="option.line2"/> 

                </div>
                
            </template>

        </drop-down>

    </div>

</template>

<script>
    import DropDown from '~/components/drop-down'
    import DropdownItem from '~/components/drop-down-item'
    import SpinnerLoader from '~/components/spinner-loader'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            DropDown,
            DropdownItem,
            SpinnerLoader
        },

        data() {
            return {
                collapsed: true,
                fetchedOptions: [],
                loading: false
            }
        },

        props: {
            label: {
                type: String,
                required: true
            },
            options: {
                type: [Array, Function],
                required: true
            }
        },

        methods: {
            selectProperty(property) {
                
                this.$emit('input', property)

                this.collapsed = true
            },

            async toggleDropDown() {
                try {
                    
                    this.loading = true

                    if (this.collapsed) {
                    
                        if (Array.isArray(this.options)) this.fetchedOptions = this.options

                        else this.fetchedOptions = await this.options.call()
                    
                    } else {

                        this.fetchedOptions = []
                    }

                } catch(error) {
                
                    throw error

                } finally {

                    this.collapsed = !this.collapsed

                    this.loading = false
                }
            }
        },

        computed: {
            ...mapGetters({
                propertyId: 'property/getId',
                propertyAddress: 'property/getAddress'
            })
        }
    }
</script>

<style lang="scss">
    $collapsed-color: #BED000;

    .property-drop-down {
        display: flex;
        align-items: center;
        position: relative;
        height: 100%;
        color: #393939;
        padding: 5px 30px;
        box-sizing: border-box;
        transition: all .2s ease-in-out;

        .selected-property {
            position: relative;
            padding-right: 30px;
            color: inherit;
            font-size: 18px;
            white-space: nowrap;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;

            &:after {
                position: absolute;
                top: 50%;
                right: 0;
                content: '';
                display: flex;
                width: 0; 
                height: 0; 
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 5px solid $collapsed-color;
                transform: translateY(-50%);
                transition: transform .2s ease-in-out;
            }
        }

        &.collapsed {            
            .selected-property:after {
                transform: translateY(-50%) rotate(180deg);
                border-bottom: 5px solid #393939;
            }
        }

        &:not(.collapsed) {
            box-shadow: 0 2px 8px 0 rgba(0,0,0,.23);
            background: #393939;
            color: $collapsed-color;
        }

        .property-select {
            position: absolute;
            top: 100%;
            box-shadow: 0 8px 8px 0 rgba(0,0,0,.1);
            display: flex;
            left: 0;
            border-radius: 0;
            border: 0;
            padding: 0;
            max-height: 70vh;
            overflow: auto;

            .property-option-container {
                width: 100%;
                padding: 0;
                display: flex;
                align-items: center;
                border-radius: 6px;

                .icon {
                    margin-right: 36px;
                }

                .proprerty-option {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    height: 86px;
                    margin-right: 36px;

                }
            }
        }
        
        .property-select::-webkit-scrollbar {
            display: none;
        }
    }
</style>
