<template>

    <div class="header-instant-search">

        <search-input
            class="search-input"
            name="table-search"
            :placeholder="placeholder"
            v-model="textSearched"
            @input="debounceSearch"
            :iconImg="true"
            :iconColor="iconColor"
        />

        <drop-down class="ais-results"
            :options="results"
            :loading="loading"
            @selectOption="redirect"
            v-click-outside="() => isShown = false"
            v-if="isShown"
            :labelEmptyResult="$t('no_results')"
        >

            <template v-slot:option="{ option }">

                <drop-down-item
                    :iconImg="option._jv.type"
                    :mainInfo="mainInfo(option)"
                    :secondInfo="secondInfo(option)"
                />

            </template>

        </drop-down>

    </div>

</template>

<script>
    import FullNameMixin from '~/components/mixins/full-name'
    import PropertyNatureShorthand from '~/components/mixins/property-nature-shorthand'
    import DropDownItem from '~/components/drop-down-item'
    import DropDown from '~/components/drop-down'

    export default {
        mixins: [FullNameMixin, PropertyNatureShorthand],

        components: {
            DropDownItem,
            DropDown
        },

        props: {
            placeholder: {
                type: String,
                required: false
            },
            iconColor: {
                type: String,
                default: '#525254'
            },
        },

        data() {
            return {
                loading: false,
                textSearched: '',
                results: [],
                debounceTimeout: null,
                isShown: false,
            }
        },

        methods: {
            redirect(value, text, model) {
                this.isShown = false
                this.$router.push(this.getRoute(model))
            },

            getRoute(model) {
                const typeToPath = {
                    properties: 'property',
                    landlords: 'landlord',
                    tenants: 'tenant',
                    suppliers: 'supplier'
                }

                const basePath = typeToPath[model._jv.type]
                return basePath ? `/${basePath}/${model._jv.id}` : undefined
            },

            mainInfo(model) {
                return model._jv.type === 'properties' ? (model.address ? model.address.formatted : '') : this.getProfileFullName(model)
            },

            secondInfo(model) {
                return model._jv.type === 'properties' ? this.getPropertyNatureShorthand(model) : model.email_address || model.phone_number
            },

            async fetchSearchResults() {

                if (!this.textSearched.length) {
                    this.isShown = false;
                    return
                }

                this.loading = true
                this.isShown = true;

                const params = {
                    filters: {
                        search: this.textSearched
                    },
                    relationships : 'empty',
                    limit : 3
                }

                const fetchPromises = [
                    this.$store.dispatch('supplier/fetchSuppliers', params),
                    this.$store.dispatch('property-manager/fetchTenants', params),
                    this.$store.dispatch('property-manager/fetchLandlords',params),
                    this.$store.dispatch('property-manager/fetchProperties', params)
                ];

                const responses = await Promise.all(fetchPromises)

                this.loading = false

                this.results = responses.flatMap(({ response }) => response)
            },

            debounceSearch() {
                clearTimeout(this.debounceTimeout);
                this.debounceTimeout = setTimeout(() => this.fetchSearchResults(), 150)
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "no_results": "Aucun résultat pour cette recherche."
        }
    }
</i18n>

<style lang="scss">
    $grey: #F7F9FF;

    .header-instant-search {
        .search-input {
            align-items: initial;

            .group {
                background: transparent;
                border: 1px solid transparent;
                height: 100%;
                box-sizing: border-box;

                .icon {
                    right: -3px;
                    top: -1px;
                    left: inherit;
                    height: 100%;
                    width: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: white;
                    border: 1px solid #E4E4E4;
                    box-sizing: border-box;
                    border-radius: 65px;
                    transform: inherit;
                }

                .input {
                    height: 44px;
                    color: #525254;
                    background: transparent;
                    padding-right: 48px;
                    padding-left: 24px;
                    border: 0;

                    &::placeholder {
                        color: #525254;
                    }
                }
            }
        }

        .ais-results {
            z-index: 20;
            position: absolute;
            top: 50px;
            width: 100%;
            max-height: 400px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            background: white;
            box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
        }
    }
</style>


