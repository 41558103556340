import { queryBuilder, queryResponse } from '~/utils/query'

export default {
    async createInventory({ getters, dispatch }) {
        try {

            if (getters['getId'] !== '') return await dispatch('updateInventory')
            
            let requestData = await dispatch('formatInventoryRequest')

            const inventory = await dispatch('tyltoApi/post', [requestData, { url: `rentals/${getters['getRelatedRental']['_jv'].id}/inventories` }], { root: true })

            await dispatch('createNewInventoryItems', inventory['_jv'].id)

            if (inventory['_jv']['relationships']['document']['data'] === null) await dispatch('createInventoryDocument', inventory['_jv'].id)

            await dispatch('fillInventory', inventory)
                        
            return inventory

        } catch(error) {

            throw error
        }
    },

    async deleteInventory({ dispatch, getters }, id) {
        try {
         
            let inventoryId = typeof id !== 'undefined' ? id : getters['getId']
            
            return await dispatch('tyltoApi/delete', 'inventories/' + inventoryId, { root: true })

        } catch(error) {

            throw error
        }
    },

    async createInventoryDocument({ dispatch, getters, commit }, inventoryId) {
        try {

            let document = {
                _jv : {
                    type: 'files'
                }
            }

            let id = getters['getId'] !== '' ? getters['getId'] : inventoryId

            const inventoryDocument = await dispatch('tyltoApi/post', [document, {url: `inventories/${id}/documents`}], { root: true })

            commit('setRelatedDocument', inventoryDocument)

            commit('tyltoApi/deleteRecord', { _jv: { type: 'inventories', id: id } }, { root: true })

            return inventoryDocument

        } catch(error) {
            
            throw error
        }
    },

    async createNewInventoryItems({ dispatch, getters, commit }, inventoryId) {
        try {

            const notUploadedInventoryItems = getters['getRelatedItems'].filter(item => typeof item['_jv'] === 'undefined')

            const inventoryItems = await Promise.all(notUploadedInventoryItems.map(item => {
                let request = Object.assign({
                    _jv: {
                        type: 'inventory-items'
                    }
                }, item)

                if (typeof item.picture !== 'undefined' && Object.keys(item.picture).length) {
                    delete request['picture']
                    
                    request['_jv']['relationships'] = {
                        photos: {
                            data: [{
                                type: 'files',
                                id: item.picture['_jv'].id
                            }]
                        }
                    }
                }

                let id = getters['getId'] !== '' ? getters['getId'] : inventoryId
                
                return dispatch('tyltoApi/post', [request, { url: `inventories/${id}/inventory-items` }], { root: true })
            }))

            commit('setRelatedItems', inventoryItems)

            return inventoryItems

        } catch(error) {

            throw error
        }
    },

    async fetchInventoryItem({ dispatch, rootGetters }, inventoryItemId) {
        try {

            let inventoryItem = rootGetters['tyltoApi/get'](`inventory-items/${inventoryItemId}`)
            
            if (typeof inventoryItem === 'undefined' || !Object.keys(inventoryItem).length) {

                inventoryItem = await dispatch('tyltoApi/get', `inventory-items/${inventoryItemId}`, { root: true })
            }

            return inventoryItem

        } catch (error) {
            
            throw error
        }
    },

    async updateInventoryItem({ dispatch }, inventoryItem) {
        try {

            let request = {
                data: null
            }
            
            if (Object.keys(inventoryItem['picture']).length) {
                request['data'] = [{
                    type: 'files',
                    id: inventoryItem['picture']['_jv'].id
                }]
            }

            await this.$axios({ method: 'patch', url: `/inventory-items/${inventoryItem['_jv'].id}/relationships/pictures`, data: request })

            return await dispatch('tyltoApi/patch', inventoryItem, { root: true })

        } catch(error) {

            throw error
        }
    },

    async deleteInventoryItem({ dispatch }, inventoryItemId) {
        try {

            return await dispatch('tyltoApi/delete', `inventory-items/${inventoryItemId}`, { root: true })

        } catch(error) {

            throw error
        }
    },

    async fetchInventories({ dispatch }, params) {
        try {

            const response = await dispatch('tyltoApi/get', ['inventories', { params: queryBuilder(params) }], { root: true })

            return queryResponse(response)

        } catch(error) {

            throw error
        }
    },

    async fetchPropertyInventories({ dispatch }, params ) {
        try {

            const response = await dispatch('tyltoApi/get', `/properties/${params.propertyId}/inventories`, { root: true })

            return queryResponse(response)

        } catch(error) {

            throw error
        }
    },

    async fetchInventory({ dispatch, rootGetters }, inventoryId) {
        try {

            let inventory = rootGetters['tyltoApi/get'](`inventories/${inventoryId}`)

            if (typeof inventory === 'undefined' || !Object.keys(inventory).length) {

                inventory = await dispatch('tyltoApi/get', `inventories/${inventoryId}`, { root: true })
            }

            return inventory

        } catch(error) {

            throw error
        }
    },

    async fetchAndFillInventory({ dispatch }, inventoryId) {
        try {

            let inventory = await dispatch('fetchInventory', inventoryId)

            await dispatch('fillInventory', inventory)

            return inventory

        } catch(error) {

            throw error
        }
    },

    async fetchDocumentRelationship({ dispatch, getters, commit }, inventoryId) {
        try {
            
            let inventoryDocument = {}

            if (Object.keys(getters['getRelatedDocument']).length && !!getters['getRelatedDocument'].is_signed) inventoryDocument = getters['getRelatedDocument']

            else {

                const id = getters['getId'] !== '' ? getters['getId'] : inventoryId
                
                const { data } = await this.$axios({ method: 'get', url: `inventories/${id}/relationships/document` })

                if (data['data'] !== null) inventoryDocument = await dispatch('file/fetchFile', data['data']['id'], { root: true })
            }

            commit('setRelatedDocument', inventoryDocument)

            return inventoryDocument

        } catch(error) {

            throw error
        }
    },

    async fetchItemsRelationship({ dispatch, getters, commit }, inventoryId) {
        try {

            let inventoryItems = {}

            if (getters['getRelatedItems'].length) inventoryItems = getters['getRelatedItems']

            else {
                
                const id = getters['getId'] !== '' ? getters['getId'] : inventoryId
                
                const { data } = await this.$axios({ method: 'get', url: `inventories/${id}/relationships/items` })

                inventoryItems = await Promise.all(data['data'].map(relationship => dispatch('inventory/fetchInventoryItem', relationship.id, { root: true })))
            }

            commit('setRelatedItems', inventoryItems)

            return inventoryItems

        } catch(error) {

            throw error
        }
    },

    async fetchRentalRelationship({ dispatch, commit, getters }, inventoryId) {
        try {

            let rental = {}

            if (Object.keys(getters['getRelatedRental']).length) rental = getters['getRelatedRental']

            else {
                
                const id = getters['getId'] !== '' ? getters['getId'] : inventoryId
                
                const { data } = await this.$axios({ method: 'get', url: `inventories/${id}/relationships/rental` })

                if (data['data'] !== null) rental = await dispatch('rental/fetchRental', data['data']['id'], { root: true })
            }

            commit('setRelatedRental', rental)

            return rental

        } catch(error) {

            throw error
        }
    },

    async fillInventory({ commit }, inventory) {
        commit('resetDefaultState')

        if (typeof inventory['type'] !== 'undefined') {
            commit('setType', inventory['type'])
        }

        if (typeof inventory['_jv'] !== 'undefined') {
            commit('setId', inventory['_jv'].id)
        }

        if (typeof inventory['comment'] !== 'undefined') {
            commit('setComment', inventory['comment'])
        }
    },

    formatInventoryRequest({ getters }) {
        let inventory = {
            type: getters['getType'],
            _jv: {
                type: 'inventories'
            }
        }

        if (getters['getId'] !== '') {
            inventory['_jv'].id = getters['getId']
        }

        if (getters['getComment'] !== '') {
            inventory['comment'] = getters['getComment']
        }

        if (Object.keys(getters['getRelatedDocument']).length) {
            inventory['_jv']['relationships'] = {
                document: {
                    data: {
                        type: 'files',
                        id: getters['getRelatedDocument']['_jv'].id
                    }
                }
            }
        }

        return inventory
    },

    async updateDocumentRelationship({ getters, commit }) {
        try {

            let request = {
                data: null
            }
            
            if (Object.keys(getters['getRelatedDocument']).length) {
                request['data'] = {
                    type: 'files',
                    id: getters['getRelatedDocument']['_jv'].id
                }
            }

            const response = await this.$axios({ method: 'patch', url: 'inventories/' + getters['getId'] + '/relationships/document', data: request })

            commit('tyltoApi/deleteRecord', { _jv: { type: 'inventories', id: getters['getId'] } }, { root: true })

            return response
        
        } catch(error) {

            throw error
        }
    },

    async updateInventory({ dispatch, getters, rootGetters }) {
        try {
            
            let request = await dispatch('formatInventoryRequest')

            const cachedInventory = rootGetters['tyltoApi/get'](`inventories/${getters['getId']}`)

            await dispatch('createNewInventoryItems')
            
            if (cachedInventory['_jv']['relationships']['document']['data'] !== null) await dispatch('updateDocumentRelationship')
                
            if (!Object.keys(getters['getRelatedDocument']).length || getters['getRelatedDocument'].is_autogenerated) await dispatch('createInventoryDocument')

            const inventory = await dispatch('tyltoApi/patch', request, { root: true })

            await dispatch('fillInventory', inventory)

            return inventory
        
        } catch(error) {

            throw error
        }
    }
}