export default {
    getActiveStep(state, getters) {
        for (let step of getters['getSteps']) {
            if (step.active) return step.name
        }

        return ''
    },

    getCompletedSteps(state, getters) {
        return getters['getSteps'].filter(step => step.complete)
    },

    getDumpName(state) {
        return state.dump_name
    },

    getErrorMessage(state) {
        return state.error.message
    },

    getErrorStatusCode(state) {
        return state.error.status_code
    },

    getErrorFields(state) {
        return state.error.fields
    },

    getExternalFileFormData(state) {
        return state.external_file.formdata
    },

    getExternalFile(state) {
        return state.external_file.file
    },

    getExternalFileName(state) {
        return state.external_file.file_name
    },

    getExternalFileIsSigned(state) {
        return state.external_file.is_signed
    },

    getExternalFileTypes(state) {
        return state.external_file.types
    },

    getExternalFilePreview(state) {
        return state.external_file.preview
    },

    getFields(state, getters, rootState, rootGetters) {
        return state.fields.map(field => {
            let value = rootGetters[field.getter]

            return Object.assign(field, {
                value: value,
            })
        })
    },

    getLeaseAgreementIsSigned(state) {
        return state.lease_agreement_is_signed
    },

    getRedirectAction(state) {
        return state.redirect_action
    },

    getSaveAction(state) {
        return state.save_action
    },

    getStatus(state) {
        return state.status
    },

    getSteps(state) {
        return state.steps.filter(step => !step.hidden)
    },

    getTitle(state) {
        return state.title
    },

    isStepsCompleted(state, getters) {
        return getters['getCompletedSteps'].length === getters['getSteps'].length
    },

    isExternalFileActive(state) {
        return state.external_file.is_active
    },

    isLoading(state) {
        return state.loading
    },

    isCreateMode(state) {
        return state.mode === 'create'
    },

    isEditMode(state) {
        return state.mode === 'edit'
    },

    isUpdateMode(state) {
        return state.mode === 'update'
    }
}
