<template>
    
    <div class="spinner-loader">
    
        <div class="lds-ellipsis">
    
            <div></div>
    
            <div></div>
    
            <div></div>
    
            <div></div>
    
        </div>
    
    </div>

</template>

<script>

    export default {}

</script>

<style lang="scss">
    $default-width: 10px;
    $default-height: 10px;

    .spinner-loader {
        padding-right: 50px;
        display: flex;
        justify-content: center;

        &.white .lds-ellipsis div{
            background: rgba(255, 255, 255, 0.2);
        }

        .lds-ellipsis {
            display: inline-block;
            position: relative;
            width: $default-width;
            height: $default-height;

            div {
                position: absolute;
                // top: 27px;
                width: $default-width;
                height: $default-height;
                border-radius: 50%;
                background: rgba(0, 0, 0, 0.2);
                animation-timing-function: cubic-bezier(0, 1, 1, 0);

                &:nth-child(1) {
                    left: 6px;
                    animation: lds-ellipsis1 0.6s infinite;
                }

                &:nth-child(2) {
                    left: 6px;
                    animation: lds-ellipsis2 0.6s infinite;
                }

                &:nth-child(3) {
                    left: 26px;
                    animation: lds-ellipsis2 0.6s infinite;
                }

                &:nth-child(4) {
                    left: 45px;
                    animation: lds-ellipsis3 0.6s infinite;
                }
            }
        }
    }

    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }

    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }

    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(19px, 0);
      }
    }
</style>
