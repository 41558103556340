import { render, staticRenderFns } from "./courses-navigation-bar-header.vue?vue&type=template&id=394131f5&"
import script from "./courses-navigation-bar-header.vue?vue&type=script&lang=js&"
export * from "./courses-navigation-bar-header.vue?vue&type=script&lang=js&"
import style0 from "./courses-navigation-bar-header.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports