<template>
    
    <modal class="delete-property-modal" :title="$t('delete-property')" :options="modalOptions" @submitted="hide" @canceled="hide" @close="hide" ref="modal">

        <form-info :text="$t('delete_warning')" :type="'error'" v-if="relatedRentals.length" />

        <p class="subtitle" v-html="$t('text', { address: address.formatted })" />

    </modal>

</template>

<script>
    import Modal from '~/components/modal'
    import FormInfo from '~/components/form-info'
    import { mapGetters } from 'vuex'
    
    export default {
        components: {
            FormInfo,
            Modal
        },

        computed: {
            ...mapGetters({
                address: 'property/getAddress',
                relatedRentals: 'property/getRelatedRentals'
            })
        },

        data() {
            return {
                modalOptions: {
                    submit: {
                        action: () => this.deleteProperty()
                    }
                }
            }
        },

        methods: {
            show() {
                this.$refs.modal.show()
            },

            hide() {
                this.$refs.modal.hide()
            },

            async deleteProperty() {
                try {
                    
                    await this.$store.dispatch('property/deleteProperty')

                    this.$router.push('/dashboard/properties')

                    this.$toast.success(this.$t('property-deleted'), { icon: 'check' })
                
                } catch(errors) {

                    throw errors
                }
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "text": "Êtes-vous sûr de vouloir supprimer le bien situé au <b>{address}</b> ?",
            "delete-property": "Suppression du bien",
            "property-deleted": "Suppression effectuée.",
            "delete_warning": "Un contrat de location est en cours. Pour supprimer le bien vous devez rompre le contrat d'abord."
        }
    }
</i18n>