export default {
    getCreatedAt(state) {
        return state.created_at
    },

    getEmailAddress(state) {
        return state.email_address
    },

    getEmailVerified(state) {
        return state.email_verified
    },

    getFullName(state) {
        return state.full_name
    },

    getId(state) {
        return state.id
    },

    getPassword(state) {
        return state.password
    },

    getPasswordResetRedirectUrl(state) {
        return state.password_reset_redirect_url
    },

    getOnboardingProgress(state) {
        return state.onboarding_progress
    },

    getPropertyManagementCompany(state) {
        return state.property_management_company
    },

    getRole(state) {
        return state.role
    },

    getSwanUser(state) {
        return state.swan_user
    },

    getLastLogin(state) {
        return state.last_login_at
    }
}
