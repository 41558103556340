export default {
    "error": {
        "401": "Il semble qu'un problème soit survenu : Vérifiez que vous êtes connecté à Internet et que vos identifiants de connexion sont corrects.",
        "default": "Il semble qu'un problème soit survenu : Notre équipe a été prévenue. Si vous voulez apporter votre aide, indiquez ce qui s'est passé ci-dessous.",
        "file_upload_failed": "L'upload du fichier à échoué",
        "fee_already_exists": "Un honoraire du même type existe déjà"
    },
    "validate": {
        "object": {
            "not_empty": "Le champ {_field_} n'est pas valide"
        },
        "array": {
            "length": "La liste doit contenir exactement {length} élements"
        },
        "date": {
            "valid": "Le champ {_field_} n'est pas valide",
            "after": "Le champ {_field_} doit être postérieur au {limit}",
            "before": "Le champ {_field_} doit être antérieur au {limit}"
        },
        "phone_number": "Le champs {_field_} n'est pas valide",
        "password_doesnt_match": "Les mots de passe ne correspondent pas",
        "step_nested_form_must_be_close": "Le formulaire doit être rempli ou fermé pour continuer",
        "max_number": "Le champ {_field_} doit être inférieur ou égal à {max}",
        "exact_length": "Le champ {_field_} doit comporter {length} caractères"
    },
    "property": {
        "utilities": {
            "electric_water_heater": "Chauffe-eau électrique",
            "gas_boiler_water_heater": "Chaudière à gaz",
            "gas_water_heater": "Chauffe-eau à gaz",
            "oil_boiler_water_heater": "Chaudière à fuel",
            "solar_water_heater": "Chauffe-eau solaire",
            "wood_boiler_water_heater": "Chaudière à bois",
            "shared_electric_water_heater": "Chauffe-eau électrique",
            "shared_gas_boiler_water_heater": "Chaudière à gaz",
            "shared_gas_water_heater": "Chauffe-eau à gaz",
            "shared_oil_boiler_water_heater": "Chaudière à fuel",
            "shared_solar_water_heater": "Chauffe-eau solaire",
            "shared_wood_boiler_water_heater": "Chaudière à bois",
            "central_no_water_heater": "Sans",
            "central_geothermal_water_heater": "Géothermie",
            "central_mixed_water_heater": "Mixte",
            "central_aerothermal_water_heater": "Aérothermie",
            "central_thermodynamic_water_heater": "Thermodynamique",
            "central_heat_pump_water_heater": "Pompe à chaleur",
            "electric_heater": "Chauffage électrique",
            "gas_boiler_heater": "Chauffage à gaz",
            "oil_boiler_heater": "Chauffage à fuel",
            "wood_boiler_heater": "Chauffage à bois",
            "shared_electric_boiler_heater": "Chaudière électrique",
            "shared_electric_heater": "Chauffage électrique",
            "shared_gas_boiler_heater": "Chauffage à gaz",
            "shared_oil_boiler_heater": "Chauffage à fuel",
            "shared_wood_boiler_heater": "Chauffage à bois",
            "central_no_heater": "Sans",
            "central_aerothermal_heater": "Aérothermique",
            "central_coal_heater": "Charbon",
            "central_air_conditioning_heater": "Climatisation",
            "central_city_gas_heater": "Gaz de ville",
            "central_geothermal_heater": "Géothermique",
            "central_wood_pellet_heater": "Granule",
            "central_mixed_heater": "Mixte",
            "central_heat_pump_heater": "Pompe à chaleur",
            "central_solar_heat_heater": "Solaire",
            "private": "privée",
            "common": "collectif",
            "central": "central",
            "cable": "Câble",
            "digital_terrestrial_television": "TNT",
            "satellite": "Satellite",
            "asymmetric_digital_subscriber_line": "ADSL",
            "optical_fiber": "Fibre optique",
            "bell": "Sonnerie",
            "cooker": "Cuisinière",
            "cooker_hood": "Hotte de cuisine",
            "cooking_plate": "Plaque de cuisson",
            "dishwasher": "Lave-vaisselle",
            "dryer": "Sèche-linge",
            "electronic_lock": "Serrure électronique",
            "fireplace": "Cheminée",
            "freezer": "Congélateur",
            "intercom": "Interphone",
            "jacuzzi": "Jacuzzi",
            "kitchen_furniture": "Meuble de cuisine",
            "microwave_oven": "Four à micro-ondes",
            "oven": "Four",
            "refrigerator": "Réfrigérateur",
            "swimming_pool": "Piscine",
            "washing_machine": "Lave-linge",
            "attic": "Grenier / Combles aménagés",
            "balcony": "Balcon",
            "cellar": "Cave",
            "loggia": "Loggia",
            "garden": "Jardin",
            "parking_space": "Place de stationnement",
            "terrace": "Terrasse",
            "veranda": "Véranda",
            "empty_heater": "Aucun",
        },
        "rooms": {
            "bedroom": "chambre",
            "dining_room": "salle à manger",
            "laundry_room": "buanderie",
            "living_room": "séjour",
            "office": "bureau",
            "vestibule": "vestibule",
            "bathroom": "salle de bain",
            "kitchen": "cuisine",
            "restroom": "W.C. séparé",
            "shower_room": "salle de douche",
            "attic": "grenier / combles aménagés",
            "balcony": "balcon",
            "cellar": "cave",
            "loggia": "loggia",
            "garden": "jardin",
            "parking_space": "place de stationnement",
            "terrace": "terrasse",
            "veranda": "véranda",
            "bicycle_parking": "parking à vélo",
            "caretaker_service": "gardien",
            "elevator": "ascenseur",
            "green_space": "espaces verts",
            "shared_laundry_room": "Buanderie commune",
            "playground": "aire et équipements de jeux",
            "shared_shower_room": "douche sur le palier",
            "shared_restroom": "W.C. sur le palier"
        },
        "construction_date": {
            "/1948-12-31": "Avant le 31 décembre 1948",
            "1949-01-01/1974-12-31": "Entre le 1 janvier 1949 et le 31 décembre 1974",
            "1975-01-01/1989-12-31": "Entre le 1 janvier 1975 et le 31 décembre 1989",
            "1990-01-01/2004-12-31": "Entre le 1 janvier 1990 et le 31 décembre 2004",
            "2005-01-01/": "Après le 1 janvier 2005"
        },
        "building_ownership_type": {
            "private": "Monopropriété",
            "shared": "Co-propriété"
        },
        "settlement_type": {
            "shared": "Collectif",
            "private": "Individuel"
        },
        "mixed": "Mixte",
        "dwelling": "Habitation",
        "dwelling_type": {
            "flat": "appartement",
            "house": "maison",
            "loft": "loft",
            "business_premises": "local commercial",
            "parking": "parking",
            "other": "autre"
        }
    },
    "contract": {
        "unknown": "Aucun contract",
        "mandate": "Mandat de gestion {numeroRegistre}",
        "rental": "Contrat de location",
        "from": "Prenant effet le {startDate}",
        "to": "au {endDate}",
        "upcoming": "À venir",
        "ongoing": "En cours",
        "ended": "Terminé",
        "ending": "Bientôt terminé"
    },
    "documents": {
        "asplc": "Surface privative ou loi Carrez",
        "audit_energetique": "Audit énergétique",
        "crep": "Risque d'exposition au plomb",
        "diagnostic_termites": "Termites dans le bâtiment",
        "diagnostic_bruit": "Bruit des aéroports",
        "dpe": "Performance énergétique",
        "dpe_collectif": "DPE Collectif",
        "dtg": "Diagnostic technique global",
        "eianc": "Assainissement non collectif",
        "eiie": "Installation intérieure d'électricité",
        "eiig": "Installation intérieure de gaz",
        "empampca": "Présence d'amiante",
        "erp": "État des risques et pollutions",
        "iprm": "Présence d'un risque de mérule",
        "guaranty_agreement": "Acte de caution solidaire",
        "checkin_inventory": "État des lieux d'entrée",
        "annex_to_rental": "Annexe du contrat de location",
        "cni": "Carte d'identité",
        "proof_of_address": "Justificatif de domicile",
        "proof_of_work": "Justificatif de situation professionnelle",
        "proof_of_income": "Justificatif de revenus",
        "bank_details": "RIB",
        "cost_invoice": "Facture de dépenses",
        "lease_agreement": "Contrat de location",
        "management_report": "Compte rendu de gestion",
        "mandate": "Mandat de gestion",
        "rent_notice": "Appel de loyer",
        "rent_receipt": "Quittance de loyer",
        "shared_ownership_rules": "Règlement de copropriété",
        "tax_notice": "Avis d'imposition",
        "signed_guarantee_deed": "Acte de caution signé",
        "security_deposit_notice": "Appel de dépôt de garantie",
        "security_deposit_receipt": "Quittance de dépôt de garantie",
        "cost_notice": "Appel exceptionnel",
        "cost_receipt": "Quittance d'appel exceptionnel",
        "title_deed": "Titre de propriété",
        "last_general_meeting": "Dernier procès-verbal d'assemblée générale",
        "last_fund_request": "Dernier appel de fonds"
    },
    "fees": {
        "representation_in_general_meeting_of_co_owners": "Représentation aux assemblées générales des co-propriétaires",
        "checkin_inventory_landlord_share": "État des lieux d’entrée (part propriétaire)",
        "checkin_inventory_tenant_share": "État des lieux d’entrée (part locataire)",
        "checkout_inventory": "État des lieux de sortie",
        "everyday_management": "Gestion courante",
        "maintenance_and_repairs": "Réparations et travaux de maintenance",
        "matchmaking_and_negociation": "Entremise et négociation",
        "rent_guarantee_insurance": "Garantie loyers impayés",
        "tax_return_filling_support": "Aide à la déclaration des revenus fonciers",
        "visits_management_file_making_lease_agreement_drafting_landlord_share": "Organisation des visites, constitution du dossier et rédaction du bail  (part propriétaire)",
        "visits_management_file_making_lease_agreement_drafting_tenant_share": "Organisation des visites, constitution du dossier et rédaction du bail  (part locataire) ",
        "additional_services": "Prestations supplémentaires",
        "rental_insurance": "Assurance locataire",
        "guarantor_insurance": "Garant location",
        "home_insurance": "Assurance habitation pour le compte des locataires"
    },
    "rental_item_types": {
        "all": "Toutes les sommes perçues",
        "cleaning_charges": "Frais de ménage",
        "fixed_service_charges": "Forfait de charge",
        "guarantor_insurance": "Assurance locataire",
        "rent": "Loyer (hors charges)",
        "rent_supplement": "Supplément de loyer",
        "security_deposit": "Dépôt de garantie",
        "service_charges_adjustment": "Régularisation de charges",
        "variable_service_charges": "Provision sur charges",
        "periodic_service_charges": "Paiement périodique des charges sans provision",
    },
    "rent": {
        "paid": "Payé",
        "unpaid": "Impayé",
        "ready": "À venir",
        "upcoming": "À venir",
        "draft": "Brouillon",
        "open": "À payer",
        "canceled": "Annulé",
        "refund": "Remboursé",
        "partial_refund": "Remboursé partiellement"
    },
    "bank_account": {
        "oskar": "Oskar",
        "bnp_paribas": "BNP Paribas"
    },
    "accounting": {
        "journal": {
            "operations_diverses": "Opérations diverses"
        },
        "entries": {
            "accounting_entries_totals": "Solde des écritures",
        }
    },
    "payment_method": {
        "sepa_credit_transfer": "Virement programmé",
        "virtual_iban": "IBAN virtuel",
        "external_sepa_direct_debit": "Prélèvement automatique",
        "cash": "Espèces",
        "check": "Chèque"
    },
    "mixins": {
        "property-shorthand": {
            "house": "Maison",
            "flat": "Appartement",
            "parking": "Place de parking",
            "business_premises": "Local commercial",
            "loft": "Loft",
            "other": "Autre",
            "rooms": "{nb} pièces",
            "area": "{area} m²",
            "studio": "Studio",
            "unfurnished": "vide",
            "is_furnished": "meublé",
            "reference": "N° de lot : <span>{reference}</span>",
            "case_number": "N° de dossier : <span>{caseNumber}</span>"
        },
        "widgetFilter": {
            "this_month": "Ce mois-ci",
            "last_3_months": "3 derniers mois",
            "last_6_months": "6 derniers mois",
            "this_year": "Cette année"
        }
    },
    "costs": {
        "non_resident_caretaker": "Employé d'immeuble (non logé sur place)",
        "resident_caretaker": "Gardien/concierge (habitant sur place)",
        "elevator": "Ascenseurs et monte-charge",
        "shared_heating_and_water": "Eau froide, eau chaude et chauffage collectif",
        "private_heating_and_water": "Installations individuelles",
        "common_areas_maintenance": "Entretien des parties communes intérieures",
        "outdoor_space_maintenance": "Espaces extérieurs",
        "rental_public_taxes": "Taxes et redevances",
        "repair_and_maintenance_works": "Travaux réparation et entretien",
        "improvement_works": "Travaux d'amélioration",
        "construction_works": "Travaux (re) construction et d'agrandissement",
        "expenses_on_behalf_of_tenants": "Charges acquittées pour le compte des locataires et restant définitivement à la charge des propriétaires",
        "provisions_for_shared_building_ownership": "Provisions pour charges de copropriété",
        "management_fees": "Frais de gestion",
        "insurance_premium": "Primes d'assurance",
        "public_taxes": "Taxes et contributions déductibles d'impôt",
        "borrowing_interest": "Intérêts d'emprunt",
        "other_expenses": "Autres charges",
        "checkin_inventory_landlord_share": "État des lieux d’entrée",
        "visits_management_file_making_lease_agreement_drafting_landlord_share": "Organisation des visites, constitution du dossier et rédaction du bail",
        "matchmaking_and_negociation": "Entremise et négociation",
        "everyday_management": "Gestion courante"
    },
    "notifications": {
        "property": {
            "created": {
                "term1": "Création du lot situé au"
            }
        },
        "rental": {
            "created": {
                "term1": "Nouveau ",
                "term2": "créé, prenant effet le {data}."
            },
            "signed": "La signature du <a href='/viewer/rental/{rentalId}'> contrat de location</a>prenant effet le {startDate} a été complétée par tout les signataires."
        },
        "landlord": {
            "created": {
                "term1": "Le profil propriétaire de",
                "term2": "a été créé."
            },
            "information": "Le propriétaire <a href='/landlord/{landlordId}' class='activity-link'>{landlord}</a> a modifié/ajouté les informations suivantes: {fields}.",
            "payout_bank_account": {
                "created": "le propriétaire <a href='/landlord/{landlordId}' class='activity-link'>{landlord}</a> a ajouté des informations bancaires.",
                "updated": "le propriétaire <a href='/landlord/{landlordId}' class='activity-link'>{landlord}</a> a modifié ses informations bancaires.",
                "deleted": "le propriétaire <a href='/landlord/{landlordId}' class='activity-link'>{landlord}</a> a supprimé des informations bancaires."
            },
            "document": {
                "created": "Le propriétaire <a href='/landlord/{landlordId}' class='activity-link'>{landlord}</a> a ajouté le document suivant à son dossier: {fields}|Le propriétaire <a href='/landlord/{landlordId}' class='activity-link'>{landlord}</a> a ajouté les documents suivants à son dossier: {fields}",
                "deleted": "Le propriétaire <a href='/landlord/{landlordId}' class='activity-link'>{landlord}</a> a supprimé le document suivant à son dossier: {fields}|Le propriétaire <a href='/landlord/{landlordId}' class='activity-link'>{landlord}</a> a supprimé les documents suivants à son dossier: {fields}"
            }
        },
        "supplier": {
            "created": {
                "term1": "Le profil fournisseur de",
                "term2": "a été créé."
            },
        },
        "tenant": {
            "created": {
                "term1": "Le profil locataire de",
                "term2": "a été créé."
            },
            "information": "Le locataire <a href='/tenant/{tenantId}' class='activity-link'>{tenant}</a> a modifié/ajouté les informations suivantes: {fields}.",
            "payment_method": "le locataire <a href='/tenant/{tenantId}' class='activity-link'>{tenant}</a> a modifié/ajouté le moyen de paiement suivant: {fields}.",
            "document": {
                "created": "Le locataire <a href='/tenant/{tenantId}' class='activity-link'>{tenant}</a> a ajouté le document suivant à son dossier: {fields} | Le locataire <a href='/tenant/{tenantId}' class='activity-link'>{tenant}</a> a ajouté les documents suivants à son dossier: {fields}",
                "deleted": "Le locataire <a href='/tenant/{tenantId}' class='activity-link'>{tenant}</a> a supprimé le document suivant à son dossier: {fields}|Le locataire <a href='/tenant/{tenantId}' class='activity-link'>{tenant}</a> a supprimé les documents suivants à son dossier: {fields}"
            }
        },
        "mandate": {
            "created": {
                "term1": "Création du",
                "term2": "au registre des mandats."
            },
            "signed": "La signature du <a href='/viewer/mandate/{mandateId}'> mandat de gestion</a> numéro {number} a été complétée par tout les signataires."
        },
        "invitation": {
            "created": {
                "term1": "Invitation envoyée à",
                "term2": "pour rejoindre la plateforme."
            },
            "accepted": {
                "term1": "Invitation acceptée par",
                "term2": "pour rejoindre la plateforme."
            }
        },
        "signature": {
            "created": "Une demande de signature a été envoyé par mail à {signer}.",
            "signature_request_has_been_completed": "La signature du <a href='/viewer/file/{fileId}' class='activity-link'>document</a> a été complétée par tous les signataires le {date}.",
            "signed": "{signatoriesTranslation} <a href='{redirectPath}' class='activity-link'>{signer}</a> {verb} signé un document le {date}.",
            "signatory": "Le signataire ",
            "signatories": "Les signataires ",

        }
    },
    "legal_person": {
        "address": "Adresse",
        "birth_date": "Date de naissance",
        "birth_place": "Lieu de naissance",
        "first_name": "Prénom",
        "gender": "genre",
        "last_name": "Nom",
        "nationality": "Nationalité",
        "email_address": "Adresse email",
        "phone_number": "Numéro de téléphone",
        "business_name": "Dénomination sociale",
        "identification_number": "Numéro SIREN",
        "incorporation_date": "Date de création",
        "share_capital": "Capital social",
        "trade_name": "Nom commercial",
        "matrimonial_status": "Etat matrimonial",
        "payout_delay": "Delai de reversement automatique",
    },
    mandate: {
        register: "Registre des mandats",
        number: "Numéro au registre",
        start_date: "Date de début du contrat",
        term: "Durée du contrat",
        missions: "Missions",
        mandate_other_specific_clauses: "Clauses particulières du mandat",
        other_specific_clauses: "Clause particulière",
        mandate_annexes: "Annexes du mandat",
        annexes: "Annexes",
    }


}
