import { jsonapiModule } from 'jsonapi-vuex'

const config = { 
    cleanPatch: true,
    clearOnUpdate: true,
    preserveJson: true,
    recurseRelationships: false,
    followRelationshipsData: false,
    headers: {
        'Accept': 'application/vnd.api+json'
    }
}

export default function({ $axios, store }) {
    store.registerModule('tyltoApi', jsonapiModule($axios, config))
}
