<template>
    
    <div>

        <spinner-loader v-if="isLoading" />

        <div name="tenant-payment-method-details" class="tenant-payment-method-details" ref="widget" v-else>
        
            <div class="payment-method">

                <readonly-input class="readonly" name="test" :label="$t('payment_method')" :value="$t(paymentMethod.type)" />

            </div>

            <div class="payment-method" v-if="paymentMethod.bank_name">

                <readonly-input class="readonly" name="test" :label="$t('bank_name')" :value="paymentMethod.bank_name" />

            </div>

            <div class="payment-method">

                <readonly-input class="readonly" name="test" :label="$t('iban')" :value="paymentMethod.iban"/>

                <div class="icon" @click="copy(paymentMethod.iban)">

                    <svg-icon class="icon" width="20" height="20" :viewbox="'0 0 20 20'"><credit-transfer-icon /></svg-icon>

                </div>

            </div>

            <div class="payment-method" v-if="paymentMethod.swift_code">

                <readonly-input class="readonly" name="test" :label="$t('bic')"  :value="paymentMethod.swift_code"/>

                <div class="icon" @click="copy(paymentMethod.swift_code)">

                    <svg-icon class="icon" width="20" height="20" :viewbox="'0 0 20 20'"><credit-transfer-icon /></svg-icon>

                </div>

            </div>

        </div>

    </div>

</template>

<script>
    import Widget from '~/components/widget'
    import SvgIcon from '~/components/svg-icon'
    import { CreditTransferIcon } from '~/components/icons/button-icons'
    import SpinnerLoader from '~/components/spinner-loader'

    export default {
            
        components: {
            Widget,
            SvgIcon,
            CreditTransferIcon,
            SpinnerLoader
        },

        name: 'TenantPaymentMethodDetails',

        data() {
            return {
                paymentMethod: {},
                isLoading: false,
            }
        },

        async fetch() {
            try {

                this.isLoading = true

                const paymentMethods = await this.$store.dispatch('profile/fetchTenantPaymentMethodsRelationship')

                this.paymentMethod = paymentMethods.find(paymentMethod => paymentMethod.default === true)

                if (typeof this.paymentMethod === 'undefined') {

                    this.paymentMethod = paymentMethods.find(paymentMethod => paymentMethod.bank_name === 'Swan')
                }
                
            } catch (error) {

                throw error

            } finally {

                this.isLoading = false
            }
        },

        methods: {
            copy(value) {
                navigator.clipboard.writeText(value)

                this.$toast.success(this.$t('copied'), { icon: 'check' })
            },
        }
    }

</script>

<i18n>
{
    "fr": {
        "bank_name": "Nom d'établissement",
        "iban": "IBAN",
        "bic": "BIC",
        "payment_method": "Moyen de paiement",
        "virtual_iban": "IBAN virtuel",
        "copied": "Copié !",
        "external_sepa_direct_debit": "Prélèvement"
    }
}

</i18n>

<style lang="scss" scoped>
    .tenant-payment-method-details {

        padding: 0 !important ;
        .payment-method {
            height: fit-content;
            border-bottom: 1px solid #F2F2F2 ;
            padding: 16px 0;
            margin: 0 ;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &:last-child {
                border: none;
            }
            
            .form-group {
                margin: 0;
            }

            &::v-deep .readonly {
                .text {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    color: #767676 !important;
                }
            }
    
            .icon {
                cursor: pointer;
            }

            &:not(:last-of-type) {
                margin-bottom: 16px;
            }
        }
    }
</style>

