<template>
    
    <modal class="delete-tenant-modal" :title="$t('delete-tenant')" :options="modalOptions" @submitted="hide" @canceled="hide" @close="hide" ref="modal">

        <p class="subtitle" v-html="$t('text', { fullName: fullName })" v-if="!isTenantOngoingRental" />

        <p class="subtitle" v-html="$t('tenant_ongoing_rental')" v-else />

    </modal>

</template>

<script>
    import Modal from '~/components/modal'
    import { mapGetters } from 'vuex'
    
    export default {
        components: {
            Modal
        },

        data() {
            return {
                
            }
        },

        computed: {
            isTenantOngoingRental() {
                return this.relatedRentals.length > 0
            },

            modalOptions() {
                return {
                    submit: {
                        action: () => this.deleteTenant(),
                        disableButton: this.relatedRentals.length > 0
                    }
                }
            },
            
            ...mapGetters({
                fullName: 'profile/getFullName',
                relatedRentals: 'profile/getRelatedRentals',
            }),
        },
        
        methods: {
            show() {
                this.$refs.modal.show()
            },

            hide() {
                this.$refs.modal.hide()
            },

            async deleteTenant() {
                try {
                    
                    await this.$store.dispatch('profile/deleteTenant')

                    this.$router.push('/dashboard/tenants')

                    this.$toast.success(this.$t('tenant-deleted'), { icon: 'check' })
                
                } catch(errors) {

                    throw errors
                }
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "text": "Êtes-vous sûr de vouloir supprimer le locataire <b>{fullName}</b> ?",
            "delete-tenant": "Suppression du locataire",
            "tenant-deleted": "Suppression effectuée.",
            "tenant_ongoing_rental": "Un contrat de location est en cours. <br> Veuillez le clore avant de supprimer le locataire."
        }
    }
</i18n>