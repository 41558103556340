<template>

    <div class="bank-number-table-cell">
        
        {{ last4Digits ? $t('last_4_digits', { number: last4Digits }) : '' }}

    </div>
    
</template>

<script>
    export default {
        props: {
            last4Digits: {
                type: String,
                required: false
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "last_4_digits": "•••• {number}"
        }
    }
</i18n>

<style lang="scss">
    $purple: #471BB5;
    
    .bank-number-table-cell {
        display: flex;
        // color: inherit;
        color: $purple;
        font-size: inherit;
        font-family: inherit;
        font-weight: inherit;

        .icon {
            margin-right: 7px;
        }
    }
</style>
