<template>

    <div class="notifications">

        <widget :title="$t('notifications')" :fetch="fetchActivities" ref="widget">

            <table-list class="table" :tHead="headers" :tBodies="rows" v-if="activities.length"/>

        </widget>

        <pagination class="pagination" :page="page" :totalPages="nbPages" @updatePage="page = $event" :key="nbPages"/>

    </div>

</template>

<script>
    import Widget from '~/components/widget'
    import FullNameMixin from '~/components/mixins/full-name'
    import Pagination from '~/components/table-list/pagination'
    import NotificationsProfileChanges from '~/components/mixins/notifications-profile-changes'


    export default {

        layout: 'dashboard',

        middleware: 'manager',

        mixins: [FullNameMixin, NotificationsProfileChanges],

        data() {
            return {
                activities: [],
                page: 1,
                nbPages: 1,
            }
        },

        components: {
            Widget,
            Pagination
        },

        methods: {
            async fetchActivities(){
                try {

                    const queryParams = {
                        page: this.page,
                    }

                    const { response, meta } = await this.$store.dispatch('activity/fetchActivities', queryParams)

                    this.activities = response

                    this.nbPages = meta['page_count']

                } catch(error) {

                    throw error
                }
            },
        },

        computed: {
            rows() {
                return this.activities.map(activity => {
                    let redirectPath = '/dashboard/notifications'
                    let descriptionData =  this.$t('no_description')

                    if (Object.keys(activity.resource).length) {

                        if (activity.type === 'tenants.created') {
                            redirectPath = `/tenant/${activity.resource['data'].id}`
                            descriptionData = `${this.$t('notifications.tenant.created.term1')} <a href="${redirectPath}" class="activity-link">${this.getLegalPersonsFullName(activity.resource.data.attributes.legal_persons)}</a> ${this.$t('notifications.tenant.created.term2')}`
                        }

                        else if (activity.type === 'landlords.created') {
                            redirectPath = `/landlord/${activity.resource['data'].id}`
                            descriptionData = `${this.$t('notifications.landlord.created.term1')} <a href="${redirectPath}" class="activity-link">${this.getLegalPersonsFullName(activity.resource.data.attributes.legal_persons)}</a> ${this.$t('notifications.landlord.created.term2')}`
                        }

                        else if (activity.type === 'supplier.created') {
                            redirectPath = `/supplier/${activity.resource['data'].id}`
                            descriptionData = `${this.$t('notifications.supplier.created.term1')} <a href="${redirectPath}" class="activity-link">${this.getLegalPersonFullName(activity.resource.data.attributes.legal_person)}</a> ${this.$t('notifications.supplier.created.term2')}`
                        }

                        else if (activity.type === 'properties.created') {
                            redirectPath = `/property/${activity.resource['data'].id}`
                            descriptionData = `${this.$t('notifications.property.created.term1')} ${activity.resource.data.attributes.address.formatted}`
                        }

                        else if (activity.type === 'rental.created') {
                            redirectPath = `/property/${activity.resource['data'].relationships.property['data'].id}/rental/${activity.resource['data'].id}`
                            descriptionData = `${this.$t('notifications.rental.created.term1')} ${this.$t('rentals')} ${this.$t('notifications.rental.created.term2', { data: this.$moment(activity.resource.data.attributes.start_date).format('DD/MM/YYYY')})}`
                        }

                        else if (activity.type === 'signature_request_has_been.completed') {
                            redirectPath = `/viewer/file/${activity.resource['data'].relationships.file['data'].id}`;

                            descriptionData = `${this.$t('notifications.signature.signature_request_has_been_completed', {
                                date: this.$moment(activity.resource['data'].attributes.created_at).format('DD/MM/YYYY à HH:mm'),
                                fileId: activity.resource['data'].relationships.file['data'].id
                            })}`;
                        }

                        else if (activity.type === 'signer_signature.created') {
                            const legalPersons = activity.resource.data.attributes.legal_person || activity.resource.data.attributes.legal_persons;

                            descriptionData = `${this.$t('notifications.signature.created',
                            {
                                signer: Array.isArray(legalPersons)
                                    ? this.getLegalPersonsFullName(legalPersons)
                                    : this.getLegalPersonFullName(legalPersons),
                            })}`;
                        }

                        else if (activity.type === 'signer_signature.signed') {
                            let legalPersons = activity.resource.data.attributes.legal_person || activity.resource.data.attributes.legal_persons;
                            let verb;
                            let signatoriesTranslation;

                            if (Array.isArray(legalPersons)) {
                                signatoriesTranslation = this.$t('notifications.signature.signatories');
                                legalPersons = this.getLegalPersonsFullName(legalPersons);
                                verb = 'ont';
                            } else {
                                signatoriesTranslation = this.$t('notifications.signature.signatory');
                                legalPersons = this.getLegalPersonFullName(legalPersons);
                                verb = 'a';
                            }

                            descriptionData = `${this.$t('notifications.signature.signed',
                            {
                                date: this.$moment(activity.resource['data'].attributes.created_at).format('DD/MM/YYYY à hh:mm'),
                                redirectPath: activity.resource.data.type === 'tenants' ? `/tenant/${activity.resource.data.id}` : '/',
                                signer: legalPersons,
                                signatoriesTranslation: signatoriesTranslation,
                                verb: verb,
                            })}`;
                        }

                        else if (activity.type === 'mandate.created') {
                            redirectPath = `/property/${activity.resource['data'].relationships.property['data'].id}/mandate/${activity.resource['data'].id}`
                            descriptionData = `${this.$t('notifications.mandate.created.term1')} Mandat n°${activity.resource.data.attributes.number} ${this.$t('notifications.mandate.created.term2')}`
                        }

                        else if (activity.type === 'rental.signed') {
                            redirectPath = `/viewer/rental/${activity.resource['data'].relationships.signable_resource.data.id}`
                            descriptionData = this.$t('notifications.rental.signed')
                        }

                        else if (activity.type === 'mandate.signed') {
                            redirectPath = `/viewer/mandate/${activity.resource['data'].relationships.signable_resource.data.id}`
                            descriptionData = this.$t('notifications.mandate.signed', { number: activity.resource['included'].find(resource => resource.id === activity.resource['data'].relationships.signable_resource.data.id).attributes.number, mandateId: activity.resource['data'].relationships.signable_resource.data.id })
                        }

                        else if (activity.type === 'signature.signed') {
                            redirectPath = `/viewer/file/${activity.resource['included'].find(ressource => ressource.type === 'signature-requests').relationships.file['data'].id}}`
                            descriptionData = `${this.$t('notifications.signature.signed', { date: this.$moment(activity.resource['data'].attributes.created_at).format('DD/MM/YYYY à hh:mm') })}`
                        }

                        else if (activity.type === 'tenant_information.updated') {
                            if (activity?.data_changes !== undefined) {``
                                const fields = this.getFieldsChanged(activity)

                                redirectPath = `/tenant/${activity.resource['data'].id}`
                                descriptionData = `${this.$t('notifications.tenant.information',
                                {
                                    tenant: this.getLegalPersonsFullName(activity.resource.data.attributes.legal_persons),
                                    fields: fields.join(', '),
                                    tenantId: activity.resource['data'].id
                                })}`
                            }
                        }

                        else if (activity.type === 'tenants_payments_methods.updated') {
                            if (activity?.data_changes !== undefined) {
                                descriptionData = `${this.$t('notifications.tenant.payment_method', { tenant: this.getLegalPersonsFullName(activity.resource.data.attributes.legal_persons), fields: this.$t(`payment_method.${activity?.data_changes?.[0].changes.type}`), tenantId: activity.resource['data'].id })}`
                            }
                        }

                        else if (activity.type === 'tenants_documents.created') {
                            if (activity?.data_changes !== undefined) {
                                let fieldsTranslation = [];

                                activity?.data_changes?.[0].changes.types.forEach((field) => {
                                    fieldsTranslation.push(this.$t(`documents.${field}`))
                                });

                                descriptionData = `${this.$tc('notifications.tenant.document.created', fieldsTranslation.length, {
                                    tenant: this.getLegalPersonsFullName(activity.resource.data.attributes.legal_persons),
                                    fields: fieldsTranslation,
                                    tenantId: activity.resource['data'].id})
                                }`
                            }
                        }

                        else if (activity.type === 'tenants_documents.deleted') {
                            if (activity?.data_changes !== undefined) {
                                let fieldsTranslation = [];

                                activity?.data_changes?.[0].changes.types.forEach((field) => {
                                    fieldsTranslation.push(this.$t(`documents.${field}`))
                                });

                                descriptionData = `${this.$tc('notifications.tenant.document.deleted', {
                                    tenant: this.getLegalPersonsFullName(activity.resource.data.attributes.legal_persons),
                                    fields: fieldsTranslation,
                                    tenantId: activity.resource['data'].id}, fieldsTranslation.length)
                                }`
                            }
                        }

                        else if (activity.type === 'invitation_created.notification'){
                            descriptionData = `${this.$t('notifications.invitation.created.term1')} ${activity.resource.data.attributes.email_address} ${this.$t('notifications.invitation.created.term2')}`
                        }

                        else if (activity.type === 'invitation_accepted.notification') {
                            descriptionData = `${this.$t('notifications.invitation.accepted.term1')} ${activity.resource.data.attributes.email_address} ${this.$t('notifications.invitation.accepted.term2')}`
                        }

                        else if (activity.type === 'landlord_information.updated') {
                            if (activity?.data_changes !== undefined) {
                                const fields = this.getFieldsChanged(activity)

                                redirectPath = `/landlord/${activity.resource['data'].id}`
                                descriptionData = `${this.$t('notifications.landlord.information',
                                {
                                    landlord: this.getLegalPersonsFullName(activity.resource.data.attributes.legal_persons),
                                    fields: fields.join(', '),
                                    landlordId: activity.resource['data'].id
                                })}`
                            }
                        }

                        else if (activity.type === 'landlords_payout_bank_accounts.updated') {
                            if (activity?.data_changes !== undefined) {
                                descriptionData = `${this.$t('notifications.landlord.payout_bank_account.updated', { landlord: this.getLegalPersonsFullName(activity.resource.data.attributes.legal_persons), landlordId: activity.resource['data'].id })}`
                            }
                        }

                        else if (activity.type === 'landlords_payout_bank_accounts.created') {
                            if (activity?.data_changes !== undefined) {
                                descriptionData = `${this.$t('notifications.landlord.payout_bank_account.created', { landlord: this.getLegalPersonsFullName(activity.resource.data.attributes.legal_persons), landlordId: activity.resource['data'].id })}`
                            }
                        }

                        else if (activity.type === 'landlords_payout_bank_accounts.deleted') {
                            if (activity?.data_changes !== undefined) {
                                descriptionData = `${this.$t('notifications.landlord.payout_bank_account.deleted', { landlord: this.getLegalPersonsFullName(activity.resource.data.attributes.legal_persons), landlordId: activity.resource['data'].id })}`
                            }
                        }

                        else if (activity.type === 'landlords_documents.created') {
                            if (activity?.data_changes !== undefined) {
                                let fieldsTranslation = [];

                                activity?.data_changes?.[0].changes.types.forEach((field) => {
                                    fieldsTranslation.push(this.$t(`documents.${field}`))
                                });

                                descriptionData = `${this.$tc('notifications.landlord.document.created', fieldsTranslation.length, {
                                    landlord: this.getLegalPersonsFullName(activity.resource.data.attributes.legal_persons),
                                    fields: fieldsTranslation.join(', '),
                                    landlordId: activity.resource['data'].id})
                                }`
                            }
                        }

                        else if (activity.type === 'landlords_documents.deleted') {
                            if (activity?.data_changes !== undefined) {
                                let fieldsTranslation = [];

                                activity?.data_changes?.[0].changes.types.forEach((field) => {
                                    fieldsTranslation.push(this.$t(`documents.${field}`))
                                });

                                descriptionData = `${this.$tc('notifications.landlord.document.deleted', fieldsTranslation.length, {
                                    landlord: this.getLegalPersonsFullName(activity.resource.data.attributes.legal_persons),
                                    fields: fieldsTranslation.join(', '),
                                    landlordId: activity.resource['data'].id})
                                }`
                            }
                        }
                    }

                    return {
                        type: Object.keys(activity.resource).length ? this.$t(activity.resource['data'].type) : this.$t('no_type'),
                        description: descriptionData,
                        redirectTo: redirectPath
                    }
                })
            },

            headers() {
                return [
                    {
                        label: this.$t("type"),
                        field: "type",
                        type: "string",
                        width: 0.3,
                        highlight: true
                    },
                    {
                        label: this.$t("description"),
                        field: "description",
                        type: "string",
                        highlight: true
                    }
                ]
            }
        },

        watch: {
            async page() {
                await this.$refs.widget.fetch()
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "landlords.created": "Propriétaire créé",
            "property-management-companies": "Signature",
            "tenants.created": "Locataire créé",
            "properties.created": "Bien créé",
            "mandate.created": "Mandat crée",
            "rental.created": "Contrat de location créé",
            "rental.signed": "Contrat de location signé",
            "no_type": "Type inconnu",
            "no_description": "Description indisponible",
            "notifications": "Activités",
            "description": "Description",
            "rentals": "Contrat de bail",
            "tenants": "Signature",
            "properties": "Bien",
            "landlords": "Propriétaire",
            "mandates": "Mandat de gestion",
            "signatures": "Signature",
            "type": "Type",
            "signature-requests": "Signature",
            "invitations": "Invitations",
            "suppliers": "Fournisseur"
        }
    }
</i18n>

<style lang="scss">
    .notifications {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .activity-link {
            cursor: pointer;
            color: #BED000;
        }
    }
</style>
