<template>
    
    <widget class="tenant-rental-list" :title="$t('current_rental')" :fetch="fetchRentalProperties">

        <template v-if="filteredRentals.length">
        
            <ul class="rental-list-container">

                <li class="rental-list-content" v-for="(rental, index) in filteredRentals" :key="index" @click="redirectToProperty(rental['_jv']['relationships']['property']['data'].id)">

                    <property-item :property="rental.property"/>

                </li>
                
            </ul>

            <div class="buttons" v-if="rentals.length > limit">

                <button-input class="see_more background-transparent green-text-color icon-reverse" :text="$t('see_all')" iconImg="down-blue" :loading="loading" @click="limit = -1" v-if="limit >= 0" />

                <button-input class="see_more background-transparent green-text-color icon-reverse" :text="$t('reduce')" iconImg="up-blue" :loading="loading" @click="limit = 2" v-else />  

            </div>

        </template>

        <p class="empty" v-else>{{ $t('no_rental') }}</p>

    </widget>

</template>

<script>
    import Widget from '~/components/widget'
    import SvgIcon from '~/components/svg-icon'
    import TenantIcon from '~/components/icons/table-cell-icons/tenant-icon'
    import FullNameMixin from '~/components/mixins/full-name'
    import PropertyItem from '~/components/property-item'
    import TenantListProperty from '~/components/tenant-list-property'
    import SpinnerLoader from '~/components/spinner-loader'
    
    export default {
        name: "TenantRentalListWidget",
        
        mixins: [FullNameMixin],

        components: { 
            Widget,
            SvgIcon,
            SpinnerLoader,
            TenantIcon,
            PropertyItem,
            TenantListProperty
        },

        data() {
            return {
                rentals: [],
                filteredRentals: [],
                limit: 2,
                loading: false
            }
        },

        methods: {
            async fetchRentalProperties() {
                this.loading = true

                try {
                    
                    const rentals = await this.$store.dispatch('profile/fetchTenantRentalsRelationship')

                    this.rentals = await Promise.all(rentals.map(async rental => {
                        this.$store.commit('rental/setRelatedProperty', [])

                        const property = await this.$store.dispatch('rental/fetchPropertyRelationship', rental['_jv'].id)

                        return { ...rental, property }
                    }))

                    this.filteredRentals = this.rentals.slice(0, this.limit === -1 ? this.rentals.length : this.limit)

                } catch (error) {

                    throw error

                } finally {
                    
                    this.loading = false
                }
            },

            redirectToProperty(property) {
                
                this.$router.push(`/property/${property}`)
            },

            getRentalTenantsId(rental) {
                
                return rental['_jv']['relationships']['tenants']['data'].map(rel => rel.id)
            }
        },

        watch: {
            limit: {
                immediate: false,
                handler() {
                    this.fetchRentalProperties()
                }
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "current_rental": "Location(s) en cours",
            "no_tenant": "Aucun locataire",
            "no_rental": "Aucune location en cours.",
            "nb_tenants": "{nb} locataires",
            "see_more": "Voir plus",
            "see_all": "Afficher plus de détails",
            "reduce": "Réduire"
        }
    }
</i18n>

<style lang="scss" scoped>
    .tenant-rental-list {
        .rental-list-container {
            width: 100%;
            padding: 0;
            border-radius: 6px;
        
            .rental-list-content {
                padding: 0px 0px 10px 0px;
                margin-bottom: 10px;
                border-bottom: 1px solid #F2F2F2;
                
                &:last-child {
                    margin-bottom: 0;
                    border-bottom: 0;
                    padding-bottom: 0;
                }
                
                .relationship-infos {
                    margin-top: 6px;
                }
            }
        }

        .buttons {
            display: flex;
            justify-content: center;

            .see_more {
                margin-top: 18px;
            }
        }
    }
</style>
