import FullNameMixin from '~/components/mixins/full-name'

export default {
    mixins: [FullNameMixin],

    methods: {
        /**
         * [getContractName description]
         * @param  {[type]} profile [description]
         * @return {[type]}         [description]
         */
        getContractName(contract) {
            if (typeof contract['_jv'] === 'undefined') return this.$t('contract.unknown')
            
            else if (contract['_jv']['type'] === 'mandates') return this.$t('contract.mandate', { numeroRegistre: contract.number })

            else if (contract['_jv']['type'] === 'rentals') return contract.name
        },

        /**
         * [getContractDetails description]
         * @param  {[type]} profile [description]
         * @return {[type]}         [description]
         */
        getContractPeriod(contract) {
            if (typeof contract['_jv'] === 'undefined') return ''
            
            let period = this.$t('contract.from', { startDate: this.$moment(contract.start_date).format('DD/MM/YYYY') })

            if (typeof contract.end_date !== 'undefined') return period + ' ' + this.$t('contract.to', { endDate: this.$moment(contract.end_date).format('DD/MM/YYYY') })

            return period
        }
    }
}
