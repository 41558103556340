<template>

    <div :class="['landlord-list-table-cell', { 'empty': !Object.keys(landlord).length }]" v-if="!$fetchState.error">

        <svg-icon class="icon" iconColor="#BED000" width="24" height="18" :viewbox="'0 0 21 20'"><landlord-icon /></svg-icon>

        <spinner-loader v-if="loading && $fetchState.pending" />

        <span class="landlord" v-else-if="!Object.keys(landlord).length">-</span>

        <div class="landlord" @click.stop="showLandlordSidebar" v-else>

            {{ getProfileFullName(landlord) }}

            <landlord-sidebar ref="LandlordSidebar" :landlordId="landlord['_jv'].id" />

        </div>

    </div>

</template>

<script>
    import SvgIcon from '~/components/svg-icon'
    import LandlordIcon from '~/components/icons/table-cell-icons/landlord-icon'
    import FullNameMixin from '~/components/mixins/full-name'
    import SpinnerLoader from '~/components/spinner-loader'
    import Arrow from '~/components/arrow'
    import LandlordSidebar from '~/components/sidebar/landlord-sidebar'

    export default {

        fetchOnServer: false,

        mixins: [FullNameMixin],

        components: {
            SpinnerLoader,
            Arrow,
            LandlordSidebar,
            SvgIcon,
            LandlordIcon
        },

        props: {
            landlordId: {
                type: [String, Number],
                required: true
            },
            propLandlord: {
                type : Object
            }
        },

        data() {
            return {
                loading: false,
                landlord: {}
            }
        },

        fetchDelay: 1000,

        async fetch() {

            if (this.propLandlord !== undefined) {
                this.landlord = this.propLandlord;
                return;
            }

            const { store } = this.$nuxt.context

            this.loading = true

            this.landlord = await store.dispatch('profile/fetchLandlord', this.landlordId)

            this.loading = false
        },

        methods: {
            showLandlordSidebar() {
                return this.$refs.LandlordSidebar.show()
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "nb_landlords": "{nb} propriétaires"
        }
    }
</i18n>

<style lang="scss">
    $landlord: #BED000;

    .landlord-list-table-cell {
        display: flex;

        &.empty {
            opacity: .5;
        }

        .landlord {
            display: flex;
            align-items: center;
            color: $landlord;
            text-decoration-color: $landlord;

            .arrow {
                border-bottom-color: $landlord;
                margin-left: 9px;
            }

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .icon {
            margin-right: 7px;
            min-width: 24px;
        }
    }
</style>
