<template>

    <div class="transition" v-if="onboardingSteps !== 0 && !onboardingFinished && isManager" :class="modal">

        <div id="agentCard" :class="card" :style="cssVars" style=" flex-flow:row" @click="goTo(onboardingSteps)" v-if="onboardingSteps !== 99 && onboardingSteps !== 1 && onboardingSteps !== 10">

            <dynamical-agent v-if="onboardingSteps >= 2 && onboardingSteps < 99" />
                
        </div>
            
        <welcome-agent-page v-if="onboardingSteps === 1" :step="onboardingSteps" :cardStyle="cssVars" :cardClass="card" />

        <void-agent-page v-if="onboardingSteps === 99 && !onboardingFinished" :step="onboardingSteps" ref="modal" :cardStyle="cssVars" :cardClass="card"/>

    </div>

</template>

<script>

    import VoidAgentPage from '~/components/onboarding-templates/void-agent-page.vue'
    import WelcomeAgentPage from '~/components/onboarding-templates/welcome-agent-page.vue'
    import dynamicalAgent from '~/components/onboarding-templates/dynamical-agent.vue'
    import { mapGetters } from 'vuex'

    export default {

        components: { 
            dynamicalAgent, 
            VoidAgentPage, 
            WelcomeAgentPage 
        },

        data() {
            return {
                step: 0,
                onboardingFinished: false,
            };
        },

        watch: {
            onboardingSteps(newCount, oldCount) {
                this.step = newCount
            }
        },

        async mounted() {
            const onboarding = await this.$axios({method: 'get', url: `/fapi/onboardings/${this.getAuthUser['_jv'].id}`})

            this.onboardingFinished = onboarding.data.onboarding_finished

            this.$store.commit('onboarding/setInitializeSteps', { userId: this.getAuthUser['_jv'].id })

            if(this.onboardingSteps === 99){
                this.$refs.modal.show()
            }
        },

        methods: {
            goTo(step) {
                if (step === 3) {
                    this.$router.push({ path: '/workflow/property/create' })
                }
            }
        },

        computed: {
            onboardingAgentData() {
                if (this.$store.getters['onboarding/getOnboardingAgentData'].id) {

                    return this.$store.getters['onboarding/getOnboardingAgentData']
                }
            },

            onboardingSteps() {
                if (this.$store.getters['onboarding/getOnboardingSteps']) {

                    return this.$store.getters['onboarding/getOnboardingSteps']
                }
            },

            agentPosition() {
                if (this.$store.getters['onboarding/getAgentPosition'].left) {

                    return this.$store.getters['onboarding/getAgentPosition']
                }
            },

            cssVars() {
                if (this.onboardingAgentData) {
                    return {
                        "--z-index": this.onboardingAgentData.cardExtStyle.zIndex,
                        "--card-bg-color": this.onboardingAgentData.cardExtStyle.cardBgColor,
                        "--height": this.onboardingAgentData.cardExtStyle.height + "px",
                        "--width": this.onboardingAgentData.cardExtStyle.width + "px",
                        "--positionType": this.onboardingAgentData.cardExtStyle.cardPositionType,
                        "--positionY": this.onboardingAgentData.cardExtStyle.cardPositionY + "px",
                        "--positionX": this.onboardingAgentData.cardExtStyle.cardPositionX + "px",
                        "--flex": this.onboardingAgentData.cardExtStyle.flex,
                        "--border": this.onboardingAgentData.cardExtStyle.borderRadius + "px",
                        "--opacity": this.onboardingAgentData.cardExtStyle.opacity
                    }
                }

                return null
            },

            modal() {
                if (this.onboardingAgentData) {
                    return this.onboardingAgentData.settings.modal ? "modal" : null;
                }

                return null
            },
            card() {
                if (this.onboardingAgentData) {
                    let cardStyle = [];
                    this.onboardingAgentData.settings.card ? cardStyle.push("card") : null;
                    this.onboardingAgentData.cardExtStyle.center ? cardStyle.push("align-center") : null
                    this.onboardingAgentData.cardExtStyle.isTopLeft ? cardStyle.push("topLeft") : cardStyle.push("bottomRight");

                    return cardStyle
                }

                return null
            },

            ...mapGetters({
                getAuthUser: 'getAuthUser',
                isManager: 'isManager'
            })
        },
    }

</script>

<style lang="scss" scoped>
    $orange: #BED000;

    .transition {
        transition: .8s !important;
    }

    .align-center {
        margin: 0 auto 0 auto;
    }

    .topLeft {
        top: var(--positionY);
        left: var(--positionX);
    }

    .bottomRight {
        bottom: var(--positionY);
        right: var(--positionX);
    }

    .card {
        cursor: pointer;
        position: var(--positionType);
        width: var(--width);
        min-height: var(--height);
        background-color: var(--card-bg-color);
        z-index: 21;
        border-radius: var(--border);
        flex-flow: var(--flex) !important;
        display: flex;
        border: 5px solid $orange;
        opacity: var(--opacity);
    }

    .fade {
        animation: fadeIn 1s forwards;
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        50% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
</style>

