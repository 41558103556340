import defaultState from './default'

export default {
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },

    setAmount(state, value) {
        state.amount = value
    },

    setDate(state, value) {
        state.date = value
    },

    setDescription(state, value) {
        state.description = value
    },

    setId(state, value) {
        state.id = value
    },

    setInvoiceNumber(state, value) {
        state.invoice_number = value
    },

    setItems(state, value) {
        state.items = value
    },

    setPayoutDate(state, value) {
        state.payout_date = value
    },

    setVatAmount(state, value) {
        state.vat_amount = value
    },
    
    setPayoutReservedAt(state, value) {
        state.payout_reserved_at = value
    },

    setRelatedInvoice(state, value) {
        state.relationships.invoice = value
    },

    setRelatedSupplier(state, value) {
        state.relationships.supplier = value
    }
}