export default ({ $axios, store, redirect }) => {

    $axios.onError(error => {
        if (error.response.status === 401 ||
            error.response.status === 419) {

            if (window.location.pathname !== '/onboarding/') {
                redirect('/auth/logout')
            }

        }
    })

}
