<template>

    <div :class="['step-field', type]" ref="field">

        <text-input :label="label" :name="name" v-model="data" :rules="rules" :mask="mask" :placeholder="placeholder" :tooltip="tooltip" v-if="type === 'text'"/>

        <radio-input :label="label" :name="name" :options="options" :icons="icons" v-model="data" :rules="rules" :placeholder="placeholder" v-else-if="type === 'radio-button'"/>

        <readonly-input :label="label" :name="name" :value="data ? $t('provided') : ''" :verified="data" :placeholder="placeholder" v-else-if="type === 'readonly'"/>

        <select-box-input :label="label" :name="name" :options="options" v-model="data" :rules="rules" :placeholder="placeholder" v-else-if="type === 'select-box'"/>

        <check-box-input :label="label" :name="name" v-model="data" :rules="rules" :placeholder="placeholder" v-else-if="type === 'check-box'"/>

        <number-input :label="label" :name="name" :options="configs" v-model="data" :rules="rules" :placeholder="placeholder" v-else-if="type === 'number'"/>

        <amount-input :label="label" :name="name" v-model="data" :rules="rules" :options="configs" :placeholder="placeholder" v-else-if="type === 'amount'"/>

        <date-input :label="label" :name="name" :options="configs" v-model="data" :rules="rules" :placeholder="placeholder" v-else-if="type === 'date'"/>

        <date-period-input :label="label" :name="name" :options="configs" v-model="data" :rules="rules" :placeholder="placeholder" v-else-if="type === 'date-period'"/>

        <file-upload-input :label="label" :name="name" v-model="data" :rules="rules" :placeholder="placeholder" :tooltip="tooltip" v-else-if="type === 'file-upload'"/>

        <address-input :label="label" :name="name" v-model="data" :rules="rules" :options="configs" :placeholder="placeholder" v-else-if="type === 'address-autocomplete'"/>

        <phone-number-input :label="label" :name="name" v-model="data" :rules="rules" :placeholder="placeholder" v-else-if="type === 'phone-number'" />

        <text-area-input :label="label" :name="name" v-model="data" :rules="rules" :placeholder="placeholder" v-else-if="type === 'text-area'"/>

        <password-input :label="label" :name="name" v-model="data" :rules="rules" :placeholder="placeholder" :autocomplete="autocomplete" v-else-if="type === 'password'"/>

        <counter-input-list :label="label" :name="name" :options="options" v-model="data" :rules="rules" :placeholder="placeholder" v-else-if="type === 'counter-list'" />

        <check-box-input-list :label="label" :name="name" :options="options" v-model="data" :rules="rules"  :placeholder="placeholder" v-else-if="type === 'check-box-list'" />

        <select-box-input-list :label="label" :name="name" :options="options" v-model="data" :value="data" :rules="rules" :placeholder="placeholder" v-else-if="type === 'select-box-list'"/>

        <text-area-input-list :label="label" :name="name" v-model="data" :rules="rules" :placeholder="placeholder" v-else-if="type === 'text-area-list'"/>

        <payment-method :label="label" :name="name" :options="options" v-model="data" :rules="rules" @deletePaymentMethod="$emit('deletePaymentMethod')" v-else-if="type === 'payment-method'" />

        <bank-account :label="label" :name="name" v-model="data" :rules="rules" :options="configs" v-else-if="type === 'bank-account'" />

        <management-and-ownership :label="label" :name="name" v-model="data" :rules="rules" v-else-if="type === 'management-and-ownership'" />

        <fees-input-list :label="label" :name="name" :options="options" v-model="data" :defaultClose="configs.defaultClose" v-else-if="type === 'fees-list'" />

        <specific-clauses-input-list :label="label" :name="name" v-model="data" :rules="rules" :placeholder="placeholder" v-else-if="type === 'specific-clauses-list'" />

        <p v-else>{{ $t('unknown_input_type') }}</p>

    </div>

</template>

<script>
    import CounterInputList from '~/components/inputs-group/counter-input-list'
    import CheckBoxInputList from '~/components/inputs-group/check-box-input-list'
    import SelectBoxInputList from '~/components/inputs-group/select-box-input-list'
    import TextAreaInputList from '~/components/inputs-group/text-area-input-list'
    import AddressInput from '~/components/inputs-group/address-input'
    import PaymentMethod from '~/components/inputs-group/payment-method'
    import BankAccount from '~/components/inputs-group/bank-account'
    import ManagementAndOwnership from '~/components/inputs-group/management-and-ownership'
    import FeesInputList from '~/components/inputs-group/fees-input-list'
    import SpecificClausesInputList from '~/components/inputs-group/specific-clauses-input-list'

    export default {
        components: {
            AddressInput,
            CounterInputList,
            CheckBoxInputList,
            SelectBoxInputList,
            TextAreaInputList,
            PaymentMethod,
            BankAccount,
            ManagementAndOwnership,
            FeesInputList,
            SpecificClausesInputList
        },

        props: {
            name: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: false
            },
            getter: {
                type: String,
                required: true
            },
            setter: {
                type: String | Object,
                required: true
            },
            placeholder: {
                type: String,
                required: false
            },
            type: {
                type: String,
                required: true
            },
            icons: {
                type: Boolean,
                required: false
            },
            mask: {
                type: String,
                required: false
            },
            options: {
                type: Array | Object,
                default() {
                    return []
                }
            },
            configs: {
                type: Object,
                default() {
                    return {}
                }
            },
            rules: {
                type: String,
                required: false,
                default: ""
            },
            tooltip: {
                type: String,
                required: false
            },
            defaultReadOnly: {
                type: String,
                required: false
            },
            autocomplete: {
                type: String,
                required: false
            }
        },

        mounted() {
            this.$store.commit('workflow/addField', {
                name: this.name,
                label: this.label,
                getter: this.getter,
                setter: this.setter,
                type: this.type,
                options: this.options,
                initialValue: this.data,
                id: this.$parent.id,
                rules: this.rules
            })
        },

        computed: {
            data: {
                get: function() {
                    return this.$store.getters[this.getter]
                },
                set: function(newValue) {

                    if (this.getter === 'rental/getRentAmount' ||
                        this.getter === 'rental/getRentSupplementAmount'
                    ) {
                        this.$store.commit(this.setter.setter, {type: this.setter.type, amount: newValue, vat_rate: this.setter.vat_rate})
                    }

                    else this.$store.commit(this.setter, newValue)
                }
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "unknown_input_type": "Type de données inconnu",
            "provided": "Fourni"
        }
    }
</i18n>

<style lang="scss">
    .step-field {
        margin-bottom: 36px;

        &:last-child {
            margin-bottom: 0;
        }

        .form-group {
            align-items: initial;
        }
    }
</style>
