<template>

    <modal class="settle-supplier-account-modal preview" :title="titleFormat" :options="modalOptions" @canceled="hide" @close="hide" ref="modal">

        <spinner-loader v-if="loading" />

        <template v-else>

            <select-costs-related-to-payout :loading="loading" :costs="supplierCosts" @updateSelectedEntries="payoutRelatedCosts = $event" v-if="step === 0" />

            <select-property-management-company-bank-account v-else-if="step === 1" />

            <select-beneficiary-payout-bank-account v-else-if="step === 2" />

            <payout-consent-result :payouts="[newPayout]" :isConfirmedWire="isConfirmedWire" v-else-if="step === 3" />

        </template>

    </modal>

</template>

<script>
    import Modal from '~/components/modal'
    import FullNameMixin from '~/components/mixins/full-name'
    import SelectCostsRelatedToPayout from '~/components/modal/steps/select-costs-related-to-payout'
    import SelectPropertyManagementCompanyBankAccount from '~/components/modal/steps/select-property-management-company-bank-account'
    import SelectBeneficiaryPayoutBankAccount from '~/components/modal/steps/select-beneficiary-payout-bank-account'
    import PayoutConsentResult from '~/components/modal/steps/payout-consent-result'
    import { mapGetters } from 'vuex'

    export default {
        mixins: [FullNameMixin],

        components: {
            Modal,
            SelectCostsRelatedToPayout,
            SelectPropertyManagementCompanyBankAccount,
            SelectBeneficiaryPayoutBankAccount,
            PayoutConsentResult
        },

        data() {
            return {
                supplierCosts: [],
                fullscreen: false,
                loading: false,
                step: 0,
                newPayout: {},
                isConfirmedWire: false,
                selectedCosts: []
            }
        },

        async fetch () {
            try {

                if (typeof this.$route.query.payout_id !== 'undefined') {

                    this.newPayout = await this.$store.dispatch('payout/fetchPayout', this.$route.query.payout_id)

                    this.isConfirmedWire = true

                    this.step = 3
                }

            } catch (error) {

                throw error
            }
        },

        methods: {
            async createPayout() {
                try {

                    this.$store.commit('payout/setInitiatedAt', this.$moment().format('YYYY-MM-DD HH:mm:ss'))

                    this.$store.commit('payout/setRedirectUrl', `${window.location.origin}/supplier/${this.supplierId}?payout_id=`)

                    this.$store.commit('payout/setReference', this.selectedCosts.map(cost => cost.invoice_number).join(' '))

                    this.newPayout = await this.$store.dispatch('payout/createPayout')

                    if (this.newPayout['_jv']['meta']['consent_url']) {

                        window.location.replace(this.newPayout['_jv']['meta']['consent_url'])
                    }

                } catch (error) {

                    throw error
                }
            },

            async show() {
                try {

                    this.loading = true

                    this.$refs.modal.show()

                    await this.$store.dispatch('accounting-account/fetchAndFillAccountingAccount', this.supplierAccountingAccount['_jv'].id)

                    const payoutBankAccounts = await this.$store.dispatch('bank-account/fetchSupplierPayoutBankAccounts', this.supplierId)

                    this.$store.commit('profile/setRelatedPayoutBankAccounts', payoutBankAccounts.response)

                    await this.$store.dispatch('property-manager/fetchBankAccounts')

                    const { response } = await this.$store.dispatch('cost/fetchSupplierCosts', {
                        params: {
                            supplierId: this.supplierId,
                        },
                        filters : {
                            property_id: `is_empty:false`,
                            payout_date: `is_empty:true`
                        }
                    })

                    this.supplierCosts = response

                } catch (error) {

                    throw error

                } finally {

                    this.loading = false
                }
            },

            async nextStep() {
                if (this.step === 2) await this.createPayout()

                if (this.step < 3) this.step += 1

                else this.hide()
            },

            hide() {
                this.$store.commit('payout/resetDefaultState')

                this.$store.commit('bank-account/resetDefaultState')

                this.step = 0

                this.selectedCosts = []

                this.payoutRelatedCosts = []

                this.newPayout = {}

                this.$refs.modal.hide()

                this.$emit('closed')
            }
        },

        computed: {
            titleFormat() {
                return this.step === 0 ? this.$t('settle_supplier', {supplier: this.getLegalPersonsFullName(this.supplierLegalPersons)}) : this.$t('transfer_supplier', {supplier: this.getLegalPersonsFullName(this.supplierLegalPersons), amount: this.$n(this.isConfirmedWire ? this.payoutAmount / 100 : this.amount / 100, 'currency')})
            },

            disableButtonFormat() {
                switch (this.step) {

                    case 0 : return this.balance <= 0

                    case 1 : return this.isBankAccountNotAllowed

                    case 2: return !Object.keys(this.payoutBeneficiary).length

                    case 3 : return false

                    default: this.amount > this.balance
                }
            },

            buttonTextFormat() {
                switch (this.step) {

                    case 1: return this.$t('confirm_bank_account')

                    case 2: return this.$t('confirm_payout_bank_account')

                    case 3: return this.$t('back_to_supplier')

                    default: return this.$t('validate')
                }
            },

            modalOptions() {
                return {
                    largeOption: {
                        mode: 'expanded',
                        subtitle: this.$t('settle_account_subtitle'),
                        icon: 'settle-account'
                    },
                    submit: {
                        disableButton: this.disableButtonFormat,
                        buttonText: this.buttonTextFormat,
                        action: this.nextStep
                    }
                }
            },

            isBankAccountNotAllowed() {
                if (!Object.keys(this.payoutRelatedBankAccount).length) return true

                else if (this.payoutRelatedBankAccount.bank_name === 'Swan' && this.payoutRelatedBankAccount.balance < this.amount) return true

                return false
            },

            payoutRelatedCosts: {
                get() {
                    return this.$store.getters['payout/getRelatedCosts']
                },
                set(value) {
                    this.$store.commit('payout/setRelatedCosts', value)
                }
            },

            ...mapGetters({
                payoutRelatedBankAccount: 'payout/getRelatedBankAccount',
                payoutBeneficiary: 'payout/getRelatedBeneficiary',
                balance: 'accounting-account/getBalance',
                amount: 'payout/getAmount',
                supplierId: 'profile/getId',
                supplierLegalPersons: 'profile/getLegalPersons',
                supplierAccountingAccount: 'profile/getRelatedAccountingAccount',
                payoutAmount: 'payout/getAmount'
            })
        }
    }
</script>

<i18n>
    {
        "fr": {
            "amount": "Montant",
            "back_to_supplier": "Retour à la fiche du fournisseur",
            "settle_supplier": "Solder le compte de {supplier}",
            "transfer_supplier": "Faire un virement de {amount} à {supplier}",
            "confirm_payment": "Confirmer le rapprochement",
            "validate": "Confirmer le montant",
            "settle_account_subtitle": "Choisir le montant pour effectuer un virement au fournisseur",
            "balance": "Solde actuel",
            "confirm_payout_bank_account": "Confirmer le compte bancaire bénéficiaire",
            "confirm_bank_account": "Confirmer le compte bancaire émetteur"
        }
    }
</i18n>

<style lang="scss" scoped>
    .preview{
        z-index: 19 !important;
    }
</style>
