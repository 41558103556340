export default function ({ $axios }, inject) {
    const api = $axios.create({
        headers: {
            common: {
                'Content-Type': 'application/json'
            }
        }
    })

    api.setBaseURL('/')

    inject('api', api)
}
