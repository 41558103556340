<template>
    
    <div class="dashboard-tenant-layout" v-if="isAuthTenant">

        <tenant-navigation-bar :key="`tenant-navigation-${tenantId}`"/>

        <dashboard-customer-header backgroundImage="background-tenant-header" :mainNav="dashbordTenantMainNav" />

        <error-banner :title="$t('error_message')" :details="$t('contact_support')" v-if="errorMessage" />

        <div class="dashboard-content">
            
            <nuxt-child />
                    
        </div>

    </div>

</template>

<script>
    import TenantNavigationBar from '~/components/navigation/header/tenant-navigation-bar'
    import ErrorBanner from '~/components/error-banner'
    import DashboardCustomerHeader from '~/components/navigation/header/dashboard-customer-header'
    import { mapGetters } from 'vuex'
    
    export default {
        components: {
            TenantNavigationBar,
            DashboardCustomerHeader,
            ErrorBanner,
        },

        async fetch() {

            const { store } = this.$nuxt.context

            await store.dispatch('profile/fetchTenantRentalsRelationship')
        },

        data() {
            return {
                errorMessage: ''
            }
        },

        computed: {
            dashbordTenantMainNav() {
                return [
                    {
                        name: this.$t('dashboard'),
                        path: '/dashboard/tenant/' + this.tenantId
                    },
                    {
                        name: this.$t('rentals'),
                        path: '/dashboard/tenant/' + this.tenantId + '/rentals'
                    },
                    {
                        name:this.$t('informations'),
                        path: '/dashboard/tenant/' + this.tenantId + '/informations'
                    },
                    {
                        name: this.$t('payment-methods'),
                        path: '/dashboard/tenant/' + this.tenantId + '/payment-methods'
                    },
                    {
                        name: this.$t('documents'),
                        path: '/dashboard/tenant/' + this.tenantId + '/documents'
                    },
                ]
            },

            ...mapGetters({
                tenantId: 'profile/getId',
                isAuthTenant: 'isAuthTenant',
                tenantRentals: 'profile/getRelatedRentals'
            })
        },
        
        errorCaptured(error) {
            
            if (!!error.response && !!error.response.status && parseInt(error.response.status) >= 500) {
                
                this.errorMessage = error
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "dashboard": "Accueil",
            "documents": "Documents",
            "payment-methods": "Moyens de paiement",
            "informations": "Informations",
            "error_message": "Une erreur est survenue",
            "contact_support": "Contactez le support en envoyant un e-mail à support@oskar.immo si le problème persiste.",
            "rentals": "Mes locations"
        }
    }
</i18n>

<style lang="scss" scoped>
    $width: 1180px;
    $background: #EFEFEF;

    .dashboard-tenant-layout {
        min-height: 100vh;
        background: $background;

        .dashboard-content {
            max-width: $width;
            margin: 0 auto;
            padding: 45px 22px;

            @include tablet { 
                padding: 36px 18px;
            }
        }
    }
</style>