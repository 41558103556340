<template>

    <form-group class="password-input" :label="label" :name="name" :rules="rules" v-slot="{ id, errors }" ref="group">

        <input :class="['input', { 'errors': errors.length }]" :type="passwordFieldType" :id="id" :value="value" :placeholder="placeholder" @input="emitInput($event.target.value)" :autocomplete="autocomplete"/>

        <div class="icon" @click="togglePasswordVisibility">

            <svg-icon class="view" :iconColor="passwordFieldType === 'password' ? '#8F94A0' : '#BED000'" :viewbox="'0 0 24 24'" :width="30" :height="30" v-if="value.length > 0"><view-icon /></svg-icon>
            
        </div>

    </form-group>

</template>

<script>
    import FormGroup from '~/components/inputs/form-group'
    import ViewIcon from '~/components/icons/button-icons/view-icon'
    import SvgIcon from '~/components/svg-icon'

    export default {
        components: { 
            FormGroup,
            ViewIcon,
            SvgIcon
        },
        
        props: {
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            value: {
                type: String,
                required: true
            },
            placeholder: {
                type: String,
                default: ''
            },
            rules: {
                type: [String, Object],
                default: ''
            },
            autocomplete: {
                type: String,
                default: 'on'
            }
        },

        data() {
            return {
                passwordFieldType: 'password',
            }
        },

        methods: {
            emitInput(value) {
                this.$emit('input', value, this.name)
            },

            togglePasswordVisibility() {
                this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
            }
        }
    }
</script>

<style lang="scss">
    $input-height: 50px;
    $border-color: #DEE3EF;

    .password-input {
        .input {
            height: $input-height;
            border: 1px solid $border-color;
            border-radius: 4px;
            padding: 0 20px;
            font-size: 11pt;
            box-sizing: border-box;

            &.errors {
                background-color: #FBE8E8;
                border-color: #F5A623;
            }

            &::placeholder {
                opacity: 0.5;
            }
        }

        .icon{
            height: 30px;
            width: 30px;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            z-index: 1;
        }
    }
</style>
