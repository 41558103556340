<template>

    <modal class="breach-of-rental-modal" :title="$t('breach-of-rental')" :options="modalOptions" @submitted="hide" @canceled="hide" @close="hide" ref="modal">

        <spinner-loader v-if="loading"/>

        <template v-else>

            <select-box-input class="input-form" name="rental-select" :label="$t('rental-select')" v-model="rentalSelect" :options="rentalsOptions" :rules="'required'" v-if="!oneEntry" @input="fillRental" />

            <step-field class="input-form" name="end_date" :label="$t('end_date')" type="date" getter="rental/getEndDate" setter="rental/setEndDate" :configs="{ disabledDates: ['beforeToday'] }" :rules="`required|date_after:${rentalStartDate}`" />

        </template>

    </modal>

</template>

<script>
import Modal from '~/components/modal'
import ContractNameMixin from '~/components/mixins/contract-name'
import SpinnerLoader from '~/components/spinner-loader'
import {mapGetters} from 'vuex'

export default {
    mixins: [ContractNameMixin],

    components: {
        Modal,
        SpinnerLoader
    },

    data() {
        return {
            rentalSelect: '',
            rentalsOptions: [],
            oneEntry: false,
            loading: false,
            modalOptions: {
                submit: {
                    action: () => this.breachOfRental()
                }
            }
        }
    },

    props: {
        rentals: {
            type: Array,
            required: true
        }
    },

    computed: {
                ...mapGetters({
            rentalStartDate: 'rental/getStartDate',
            endDate: 'rental/getEndDate'
        })
    },

    methods: {
        async show() {
            try {
                this.$refs.modal.show()

                this.loading = true

                let func = async (rental) => ({
                    value: rental,
                    text: this.getContractName(rental)
                })

                this.rentalsOptions = await Promise.all(this.rentals.filter(rental => typeof rental.end_date === 'undefined').map(rental => func(rental)))

                if (this.rentalsOptions.length === 1) {

                    this.oneEntry = true

                    this.fillRental(this.rentalsOptions[0].value)
                }

            } catch (error) {

                throw error

            } finally {

                this.loading = false
            }
        },

        hide() {
            this.$store.commit('rental/setEndDate', '')

            this.$refs.modal.hide()
        },

        async fillRental(rental) {

            await this.$store.dispatch('rental/fillRental', rental)
        },

        async breachOfRental() {
            try {

                await this.$store.dispatch('rental/cancelRental')

                this.$store.commit('property/setRelatedRentals', [])

                this.$store.commit('property/setRelatedTenants', [])

                await Promise.all[
                    this.$store.dispatch('property/fetchRentalsRelationship'),
                    this.$store.dispatch('property/fetchTenantsRelationship')
                ]

                this.$toast.success(this.$t('rental-deleted'), {icon: 'check'})

            } catch (errors) {

                throw errors
            }
        }
    }
}
</script>

<i18n>
{
    "fr": {
        "text": "Sélectionnez le contrat de location à supprimer.",
        "breach-of-rental": "Rupture du contrat de location",
        "rental-deleted": "Rupture du contrat programmée.",
        "rental-select-form": "Selection",
        "rental-select": "Sélectionnez le contrat à rompre",
        "rental-date-et-motif-de-rupture": "Date et préavis de départ",
        "end_date": "Date de rupture"
    }
}
</i18n>

<style lang="scss">
.modal .date .vfc-main-container {
    position: fixed;
}
</style>
