<template>

    <div class="tenant-layout">

        <smart-navigation-bar v-if="isManager" />

        <tenant-navigation-bar v-else />
        
        <profile-header class="profile-header" role="tenants" />

        <error-banner :title="$t('error_message')" :details="$t('contact_support')" v-if="errorMessage" />

        <div class="tenant-container">

            <div class="tenant-content layout-row">
    
                <div class="layout-col">

                    <nuxt-child />

                </div>

                <div class="layout-col col-5">

                    <contact-widget v-if="!isInformationPage" />

                    <spinner-loader class="loader" v-if="tenantFetchLoading" />

                    <tenant-actions-buttons-widget :tenant="tenant" v-else/>

                    <profile-payment-method-widget />

                    <tenant-rental-list-widget />

                </div>
            
            </div>

        </div>

    </div>

</template>

<script>
    import ProfileHeader from '~/components/navigation/header/profile-header'
    import TenantNavigationBar from '~/components/navigation/header/tenant-navigation-bar'
    import SmartNavigationBar from '~/components/navigation/header/smart-navigation-bar'
    import ErrorBanner from '~/components/error-banner'
    import ContactWidget from '~/components/widget/contact-widget'
    import TenantRentalListWidget from '~/components/widget/tenant-rental-list-widget'
    import TenantActionsButtonsWidget from '~/components/widget/tenant-actions-buttons-widget'
    import TenantMainNav from '~/components/navigation/header/tenant-main-nav'
    import ProfilePaymentMethodWidget from '~/components/widget/profile-payment-method-widget'
    import SpinnerLoader from '~/components/spinner-loader'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            ProfileHeader,
            ErrorBanner,
            ContactWidget,
            TenantRentalListWidget,
            TenantActionsButtonsWidget,
            TenantNavigationBar,
            SmartNavigationBar,
            ProfilePaymentMethodWidget,
            TenantMainNav,
            SpinnerLoader
        },

        data() {
            return {
                tenant: {},
                errorMessage: '',
                tenantFetchLoading: false
            }
        },

        async fetch() {
            try {

                this.tenantFetchLoading = true

                this.tenant = await this.$store.dispatch('profile/fetchTenant', this.$route.params.tenantId)

            } finally {

                this.tenantFetchLoading = false
            }
            
        },

        computed: {
            ...mapGetters({
                isManager: 'isManager'
            }),

            isInformationPage() {
                return this.$route.path.includes('/tenant/') && this.$route.path.includes('/informations')
            }
        },

        errorCaptured(error) {
            
            if (!!error.response && !!error.response.status && parseInt(error.response.status) >= 500) {
                
                this.errorMessage = error
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "error_message": "Une erreur est survenue",
            "contact_support": "Contactez le support en envoyant un e-mail à support@oskar.immo si le problème persiste."
        }
    }
</i18n>

<style lang="scss" scoped>
    $width: 1180px;
    $background: #EFEFEF;

    .tenant-layout {
        min-height: 100vh;
        background: $background;

        .tenant-container {
            max-width: $width;
            margin-left: auto;
            margin-right: auto;

            .tenant-content {
                padding: 45px 0;
            }
        }
    }

    .loader {
        margin-top: 20px;
    }
</style>
