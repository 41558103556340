export default {
    getDescription(state) {
        return state.description
    },

    getDeliveryDate(state) {
        return state.delivery_date
    },

    getExpirationDate(state) {
        return state.expiration_date
    },

    getExtension(state) {
        return state.extension
    },

    getFields(state) {
        return state.fields
    },

    getFile(state) {
        return state.file
    },

    getIsAutomaticallyGenerated(state) {
        return state.is_autogenerated
    },

    getId(state) {
        return state.id
    },

    getIsSignable(state) {
        return state.is_signable
    },

    getIsSigned(state) {
        return state.is_signed
    },

    getMimeType(state) {
        return state.mime_type
    },

    getName(state) {
        return state.name
    },

    getSignatureRequestEmbeddedUrl(state) {
        return state.signature_request.embedded_url
    },

    getSignatureRequestId(state) {
        return state.signature_request.id
    },

    getSignatureRequestSignatures(state) {
        return state.signature_request.signatures
    },

    getSignatureRequestSigners(state) {
        return state.signature_request.signers
    },

    getSignatureRequestStatus(state, getters) {
        if (getters['getIsSigned']) return 'complete'

        return state.signature_request.status !== '' ? state.signature_request.status : 'not_started'
    },

    getSize(state) {
        return state.size
    },

    getRedirectPath(state) {
        return state.redirect_path
    },

    getUpdatePath(state) {
        return state.update_path
    },

    getTitle(state) {
        return state.title
    },

    getTypes(state) {
        return state.types
    },

    getUrl(state) {
        return state.url
    }
}
