const queryBuilder = function (params) {
    try {

        let queryParams = {}

        if (typeof params === 'undefined') return queryParams

        if (typeof params.relationships !== 'undefined') {

            queryParams['relationships'] = params.relationships
        }

        if (typeof params.page !== 'undefined') {

            queryParams['page'] = params.page
        }

        if (typeof params.filters !== 'undefined') {

            for (let key in params.filters) {

                let filterName = `filter[${key}]`

                queryParams[filterName] = params.filters[key]
            }
        }

        if (typeof params.groups_by !== 'undefined') {

            for (let key in params.groups_by) {

                let groupByName = `group_by[${key}]`

                queryParams[groupByName] = params.groups_by[key]
            }
        }

        if (typeof params.aggregates !== 'undefined') {

            for (let key in params.aggregates) {

                let aggregateName = `aggregate[${key}]`

                queryParams[aggregateName] = params.aggregates[key]
            }
        }

        if (typeof params.sort !== 'undefined') {

            let sortName = `sort`

            queryParams[sortName] = params.sort
        }

        if (typeof params.scope !== 'undefined') {

            let scopeName = `scope`

            queryParams[scopeName] = params.scope
        }

        if (typeof params.include !== 'undefined') {

            let includeName = `include`

            queryParams[includeName] = params.include
        }

        if (typeof params.limit !== 'undefined') {

            let includeName = `limit`

            queryParams[includeName] = params.limit
        }

        return queryParams

    } catch(error) {

        console.error('Error in queryBuilder')

        throw error
    }
}

const queryResponse = (jsonApiVuexResponse) => {
    try {

        const { ['_jv']: _jv, ...response } = jsonApiVuexResponse

        return {
            response: _jv['json']['data'].map(a => response[a.id]),
            meta: _jv['json']['meta'],
            data: _jv['json']['data'],
            included: _jv['json']['included']
        }

    } catch(error) {

        console.error('Error in queryResponse')

        throw error
    }
}

export {
    queryBuilder,
    queryResponse
}
