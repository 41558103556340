<template>

    <overlay :disabled="!open">

        <Transition name="modal" appear>

            <div
                :class="['modal', {} ]"
                v-if="open"
            >
                <div
                    :class="['inner-modal']"
                >
                    <div class="right-container">
                        <div
                            class="icon-close-modal"
                            @click="$emit('close')"
                        >
                            <svg-icon
                                :viewbox="'0 0 32 32'"
                                width="32"
                                height="32"
                            >
                                <close-icon/>
                            </svg-icon>

                        </div>

                        <div class="title-container">

                            <h2 class="modal__title">
                                <slot name="modal-title"></slot>
                            </h2>

                        </div>

                        <div
                            :class="['form-container', {}]"
                        >
                            <div class="inner">
                                <slot name="modal-content"></slot>
                            </div>

                            <div
                                :class="['modal__button-group', {}]"
                            >
                                <div>

                                    <slot name="modal-button"></slot>
                                </div>

                            </div>

                        </div>


                    </div>

                </div>

            </div>

        </Transition>

    </overlay>

</template>

<script>
import SvgIcon from '~/components/svg-icon'
import {CloseIcon} from '~/components/icons/button-icons'
import Overlay from '~/components/v2/overlay'

export default {
    components: {
        Overlay,
        SvgIcon,
        CloseIcon,
    },

    data() {
        return {
            open   : false,
        }
    },

    methods: {
        show() {
            this.open = true
        },

        hide() {
            this.open = false
        },
    }
}
</script>


<style lang="scss" scoped>
$linear-gradient: linear-gradient(to top, white, transparent);
$green: #BED000;

.modal {
    z-index: 100;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.modal-enter-active {
        animation: scale-anim .2s ease-in-out;
    }

    &.modal-leave-active {
        animation: scale-anim .2s reverse ease-in-out;
    }

    &.large-option {
        .inner-modal {
            height: 675px;
            padding: 0;
            max-height: 100vh;
            width: 1032px;

            &.border-green {
                border: 5px solid $green;
                box-shadow: none !important;
            }

            .left-container {
                display: flex;
                flex-direction: column;
                background-color: #EFEFEF;
                left: 0;
                top: 0;
                height: 100%;
                max-width: 295px;
                border-right: solid 2px #DEE3EF;
                display: flex;
                align-items: center;
                justify-content: center;

                .left-inner-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 0 28px 0 28px;

                    .logo_container {
                        width: 105px;
                        height: 105px;
                        border-radius: 100%;
                        background: #FFFFFF;
                        box-shadow: 0px 3px 0px #E6E8F9;
                        margin-bottom: 24px;
                        position: relative;

                        .logo {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }

                    .title {
                        margin-bottom: 8px;
                        font-size: 15pt;
                        text-align: center;
                    }

                    .subtitle {
                        font-size: 10.5pt;
                        text-align: center;
                        color: #767676;
                    }
                }
            }

            .right-container {
                overflow: auto;
                width: 100%;
                margin-top: 20px;

                .title {
                    margin-bottom: 16px;
                    font-size: 15pt;
                    text-align: center;
                }

                .form-input {
                    height: 100%;

                    &.expanded {
                    }

                    &::v-deep form {
                        display: flex;
                        flex-direction: column;
                    }

                    &::v-deep .form-info.error {
                        margin-left: 45px;
                        margin-right: 45px;
                    }

                    .form-container {
                        height: 100%;
                        justify-content: space-between;
                        display: flex;
                        flex-direction: column;
                        flex: 1 1 0%;
                        overflow: hidden;

                        .inner {
                            padding: 0 27px;
                            height: 100%;
                        }

                        &.center {
                            justify-content: center;
                        }

                        .modal__button-group {
                            margin-bottom: 0;
                            padding: 20px 24px 12px 24px;
                            border-top: 1px solid #DEE3EF;
                            flex-direction: row;
                            justify-content: center;
                            position: sticky;

                            &.footer-slot {
                                border-top: 1px solid #DEE3EF;
                                flex-direction: row;
                                justify-content: space-between;
                                padding-top: 14px;
                                padding-bottom: 14px;

                                .group {
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: flex-start;
                                    flex-direction: row-reverse;

                                    .button {
                                        &:first-child {
                                            margin-left: 12px;
                                        }

                                        &.cancel {
                                            font-size: 10.5pt;
                                            padding: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.expanded .inner-modal {
            height: calc(100% - 30px);
            width: calc(100% - 30px);
        }

        .card {
            cursor: pointer;
            position: var(--positionType);
            width: 900px !important;
            height: fit-content !important;
            background-color: var(--card-bg-color);
            z-index: 21;
            border-radius: var(--border);
            flex-flow: var(--flex) !important;
            display: flex;
            border: 5px solid $green;
            opacity: var(--opacity);
            box-shadow: none;
        }

        .align-center {
            margin: 0 auto 0 auto;
        }
    }

    .inner-modal {
        position: relative;
        z-index: 100;
        background: white;
        box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.12);
        border-radius: 10px;
        max-height: 60vh;
        overflow: auto;
        width: 364px;
        transform: translateY(-50deg);
        padding: 60px;
        padding-bottom: 0;
        flex-direction: row;
        display: flex;

        .right-container {
            width: 100%;
        }

        .icon-close-modal {
            position: absolute;
            cursor: pointer;
            top: 24px;
            right: 25px;
            z-index: 10;
        }

        .title-container-largeOptions {
            display: flex;
            align-items: center;
            position: relative;
            margin-left: 27px;

            .back {
                cursor: pointer;
                width: 16px;
                height: 16px;
                position: absolute;
                top: 50%;
                left: 8px;
                transform: translate(-50%, -50%);
            }

            .modal__rightContainerTitle {
                font-size: 24pt;
                text-align: center;
                margin-bottom: 15px;
                font-weight: 600;
            }
        }

        .title-container {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .back {
                cursor: pointer;
                width: 16px;
                height: 16px;
                position: absolute;
                top: 50%;
                left: 8px;
                transform: translate(-50%, -50%);
            }

            .modal__title {
                font-size: 16pt;
                text-align: center;
                margin-bottom: 25px;
            }
        }

        &::v-deep .subtitle,
        &::v-deep .subtitle b {
            text-align: center;
            color: #8D939F;
        }

        &::v-deep .password {
            text-align: center;
            margin-top: 10px;
        }


        &::v-deep .link {
            text-decoration: underline;
            color: #BED000;
            cursor: pointer;
        }

        &::v-deep .readonly-container {
            height: fit-content;
            border: solid 2px #DEE3EF;
            border-radius: 10px;
            padding: 18px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;

            .icon {
                cursor: pointer;
            }

            &:not(:last-of-type) {
                margin-bottom: 16px;
            }
        }

        .form-container {
            justify-content: space-between;
            display: flex;
            flex-direction: column;

            .inner {
                overflow-y: auto;
                position: relative;
                padding-bottom: 12px;
                text-align: left;

                &:after {
                    display: flex;
                    position: sticky;
                    content: '';
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 24px;
                    background: $linear-gradient;
                }
            }

            .modal__button-group {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 12px;
                margin-bottom: 35px;

                .with-cancel-option {
                    display: flex;
                    align-items: center;
                    flex-direction: row-reverse;
                    justify-content: space-between;

                    .cancel {
                        margin-right: 5px;
                    }
                }

                &:not(.footer-slot) .button {
                    min-width: 212px;
                }
            }
        }
    }
}
</style>
