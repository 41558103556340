<template>
    
    <modal class="signers-email-address-missing-modal" :title="$t('signers-email-address-missing')" :options="modalOptions" @submitted="hide" @canceled="hide" @close="hide" ref="modal">

        <p class="subtitle" v-html="$t('text')" />

    </modal>

</template>

<script>
    import Modal from '~/components/modal'
    
    export default {
        components: {
            Modal
        },

        data() {
            return {
                modalOptions: {
                    submit: {
                        action: () => {}
                    }
                }
            }
        },

        methods: {
            show() {
                this.$refs.modal.show()
            },

            hide() {
                this.$refs.modal.hide()
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "signers-email-address-missing": "Adresses email manquantes",
            "text": "Veuillez renseigner les adresses email de tout les signataires du document avant d'envoyer les signatures électroniques."
        }
    }
</i18n>