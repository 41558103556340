import { queryBuilder, queryResponse } from '~/utils/query'

export const strict = true

export const defaultState = () => {
    return {
        account_name: '',
        balance: 0,
        bank_name: '',
        bic: '',
        holder_name: '',
        iban: '',
        default: false,
        id: '',
        type: '',
        relationships: {
            accouting_account: {},
            property_management_company: {},
            owner: {}
        }
    }
}

export const state = defaultState

export const mutations = {
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },

    setAccountName(state, value) {
        state.account_name = value
    },

    setBalance(state, value) {
        state.balance = value
    },

    setBankName(state, value) {
        state.bank_name = value
    },

    setBic(state, value) {
        state.bic = value
    },

    setHolderName(state, value) {
        state.holder_name = value
    },

    setIban(state, value) {
        state.iban = value
    },

    setDefault(state, value) {
        state.default = value
    },

    setId(state, value) {
        state.id = value
    },

    setType(state, value) {
        state.type = value
    },

    setRelatedAccoutingAccount(state, value) {
        state.relationships.accouting_account = value
    },

    setRelatedPropertyManagementCompany(state, value) {
        state.relationships.property_management_company = value
    },

    setRelatedOwner(state, value) {
        state.relationships.owner = value
    }
}

export const getters = {
    getAccountName(state) {
        return state.account_name
    },

    getBalance(state) {
        return state.balance
    },

    getBankName(state) {
        return state.bank_name
    },

    getBic(state) {
        return state.bic
    },

    getHolderName(state) {
        return state.holder_name
    },

    getIban(state) {
        return state.iban
    },

    getLast4Digits(state, getters) {
        return getters['getIban'].slice(getters['getIban'].length - 4)
    },

    getDefault(state) {
        return state.default
    },

    getId(state) {
        return state.id
    },

    getType(state) {
        return state.type
    },

    getRelatedAccountingAccount(state) {
        return state.relationships.accouting_account
    },

    getRelatedPropertyManagementCompany(state) {
        return state.relationships.property_management_company
    },

    getRelatedOwner(state) {
        return state.relationships.owner
    }
}

export const actions = {
    async createProfilePayoutBankAccount({ dispatch, rootGetters }, profileId) {
        try {

            let payoutBankAccountData = await dispatch('formatPayoutBankAcountRequest')

            let id = typeof profileId === 'string' ? profileId : rootGetters['profile/getId']

            return await dispatch('tyltoApi/post', [payoutBankAccountData, { url: rootGetters['profile/getRole'] + '/' + id + '/payout-bank-accounts' }], { root: true })

        } catch(error) {

            throw error
        }
    },

    async formatPayoutBankAcountRequest({ getters }) {
        let payoutBankAccountRequest = {
            bank_name: getters['getBankName'],
            bic: getters['getBic'],
            holder_name: getters['getHolderName'],
            iban: getters['getIban'],
            _jv: {
                type: 'payout-bank-accounts'
            }
        }

        if (getters['getId'] !== '') payoutBankAccountRequest['_jv'].id = getters['getId']

        if (getters['getDefault'] !== '') payoutBankAccountRequest['default'] = getters['getDefault']

        return payoutBankAccountRequest
    },

    async fillPayoutBankAccount({ commit }, payoutBankAccount) {
        commit('resetDefaultState')

        if (typeof payoutBankAccount['bank_name'] !== 'undefined')
            commit('setBankName', payoutBankAccount['bank_name'])

        if (typeof payoutBankAccount['bic'] !== 'undefined')
            commit('setBic', payoutBankAccount['bic'])

        if (typeof payoutBankAccount['holder_name'] !== 'undefined')
            commit('setHolderName', payoutBankAccount['holder_name'])

        if (typeof payoutBankAccount['iban'] !== 'undefined')
            commit('setIban', payoutBankAccount['iban'])

        if (typeof payoutBankAccount['default'] !== 'undefined')
            commit('setDefault', payoutBankAccount['default'])

        if (typeof payoutBankAccount['_jv'] !== 'undefined') {
            commit('setId', payoutBankAccount['_jv'].id)
            commit('setType', payoutBankAccount['_jv'].type)
        }
    },

    async fetchPayoutBankAccount({ dispatch, rootGetters }, payoutBankAccountId) {
        try {

            let payoutBankAccount = rootGetters['tyltoApi/get'](`payout-bank-accounts/${payoutBankAccountId}`)

            if (typeof payoutBankAccount === 'undefined' || !Object.keys(payoutBankAccount).length) {
                payoutBankAccount = await dispatch('tyltoApi/get', 'payout-bank-accounts/' + payoutBankAccountId, { root: true })
            }

            await dispatch('fillPayoutBankAccount', payoutBankAccount)

            return payoutBankAccount

        } catch(error) {

            throw error
        }
    },

    async fetchAccountingAccountRelationship({ dispatch, getters, commit }, bankAccountId) {
        try {

            let accountingAccount = {}

            const id = typeof bankAccountId !== 'undefined' ? bankAccountId : getters['getId']

            const { data } = await this.$axios({ method: 'get', url: `bank-accounts/${id}/relationships/accounting_account` })

            if (data['data'] !== null) accountingAccount = await dispatch('accounting-account/fetchAccountingAccount', data['data']['id'], { root: true })

            commit('setRelatedAccoutingAccount', accountingAccount)

            return accountingAccount

        } catch(error) {

            throw error
        }
    },

    async fetchOwnerRelationship({ dispatch, commit, getters }, payoutBankAccountId) {
        try {

            let payoutBankAccountOwner = {}

            const request = {
                _jv: {
                    type: 'payout-bank-accounts',
                    id: payoutBankAccountId,
                    relationships: {
                        owner: undefined
                    }
                }
            }

            const { owner } = await dispatch('tyltoApi/getRelated', request, { root: true })

            payoutBankAccountOwner = Object.values(Object.values(owner)[0])[0]

            commit('setRelatedOwner', payoutBankAccountOwner)

            return payoutBankAccountOwner

        } catch(error) {

            throw error
        }
    },

    async fetchLandlordPayoutBankAccounts({ dispatch }, landlordId) {
        try {

            const payoutBankAccounts = await dispatch('tyltoApi/get', `landlords/${landlordId}/payout-bank-accounts`, { root: true })

            return queryResponse(payoutBankAccounts)

        } catch(error) {

            throw error
        }
    },

    async fetchPropertyManagementCompanyPayoutBankAccounts({ dispatch }, propertyManagementCompanyId) {
        try {

            const payoutBankAccounts = await dispatch('tyltoApi/get', `property-management-companies/${propertyManagementCompanyId}/payout-bank-accounts`, { root: true })

            return queryResponse(payoutBankAccounts)

        } catch(error) {

            throw error
        }
    },

    async fetchSupplierPayoutBankAccounts({ dispatch }, supplierId) {
        try {

            const payoutBankAccounts = await dispatch('tyltoApi/get', `suppliers/${supplierId}/payout-bank-accounts`, { root: true })

            return queryResponse(payoutBankAccounts)

        } catch(error) {

            throw error
        }
    },

    async updatePayoutBankAccount({ dispatch }) {
        try {

            const requestData = await dispatch('formatPayoutBankAcountRequest')

            const payoutBankAccount = await dispatch('tyltoApi/patch', requestData, { root: true })

            await dispatch('fillPayoutBankAccount', payoutBankAccount)

            return payoutBankAccount
        } catch(error) {

            throw error
        }
    },

    async deletePayoutBankAccount({ dispatch, commit, getters }, payoutBankAccountId) {
        try {

            let id = payoutBankAccountId !== '' || payoutBankAccountId !== undefined ? payoutBankAccountId : getters['getId']

            await dispatch('tyltoApi/delete', 'payout-bank-accounts/' + id, { root: true })

            commit('resetDefaultState')

        } catch(error) {

            throw error
        }
    },

    async createPropertyManagementCompanyBankAccount({ dispatch }, propertyManagementCompanyId) {
        try {

            let request = await dispatch('formatBankAcountRequest')

            const bankAccount = await dispatch('tyltoApi/post', [request, { url: `property-management-companies/${propertyManagementCompanyId}/bank-accounts` }], { root: true })

            await dispatch('fillBankAccount', bankAccount)

            return bankAccount

        } catch(error) {

            throw error
        }
    },

    async deleteBankAccount({ dispatch, getters, commit }, bankAccountId) {
        try {

            let id = getters['getId'] !== '' ? getters['getId'] : bankAccountId

            await dispatch('tyltoApi/delete', 'bank-accounts/' + id, { root: true })

            commit('resetDefaultState')

        } catch(error) {

            throw error
        }
    },

    async fetchBankAccount({ dispatch, rootGetters }, bankAccountId) {
        try {

            let bankAccount = rootGetters['tyltoApi/get'](`bank-accounts/${bankAccountId}`)

            if (typeof bankAccount === 'undefined' || !Object.keys(bankAccount).length) {
                bankAccount = await dispatch('tyltoApi/get', 'bank-accounts/' + bankAccountId, { root: true })
            }

            return bankAccount

        } catch(error) {

            throw error
        }
    },

    async fetchAndFillBankAccount({ dispatch }, bankAccountId) {
        try {

            let bankAccount = await dispatch('fetchBankAccount', bankAccountId)

            await dispatch('fillBankAccount', bankAccount)

            return bankAccount

        } catch(error) {

            throw error
        }
    },

    async fetchBankAccountTransactions({ dispatch }, { page, filters, params, aggregates, sort }) {
        try {

            const response = await dispatch('tyltoApi/get', [`bank-accounts/${params.bankAccountId}/bank-account-transactions`, { params: queryBuilder({ page, filters, aggregates, sort }) }], { root: true })

            return queryResponse(response)

        } catch(error) {

            throw error
        }
    },

    async fetchPropertyManagementCompanyRelationship({ dispatch, getters, commit }, bankAccountId) {
        try {

            let propertyManagementCompany = {}

            if (Object.keys(getters['getRelatedPropertyManagementCompany']).length) propertyManagementCompany = getters['getRelatedPropertyManagementCompany']

            else {
                const id = getters['getId'] !== '' ? getters['getId'] : bankAccountId

                const { data } = await this.$axios({ method: 'get', url: `bank-account/${id}/relationships/property_management_company` })

                if (data['data'] !== null) propertyManagementCompany = await dispatch('property-manager/fetchPropertyManagerCompany', data['data']['id'], { root: true })
            }

            commit('setRelatedPropertyManagementCompany', propertyManagementCompany)

            return propertyManagementCompany

        } catch(error) {

            throw error
        }
    },

    async formatBankAcountRequest({ getters }) {
        let bankAccountRequest = {
            account_name: getters['getAccountName'],
            bank_name: getters['getBankName'],
            bic: getters['getBic'],
            holder_name: getters['getHolderName'],
            iban: getters['getIban'],
            _jv: {
                type: 'bank-accounts'
            }
        }

        if (getters['getBalance'] !== '') bankAccountRequest['balance'] = getters['getBalance']

        if (getters['getId'] !== '') bankAccountRequest['_jv'].id = getters['getId']

        if (Object.keys(getters['getRelatedPropertyManagementCompany']).length)
            bankAccountRequest['_jv']['relationships'] = {
                property_management_company: {
                    data: {
                        type: 'property-management-companies',
                        id: getters['getRelatedPropertyManagementCompany']['_jv'].id
                    }
                }
            }

        return bankAccountRequest
    },

    async fillBankAccount({ commit }, bankAccount) {
        commit('resetDefaultState')

        if (typeof bankAccount['account_name'] !== 'undefined')
            commit('setAccountName', bankAccount['account_name'])

        if (typeof bankAccount['balance'] !== 'undefined')
            commit('setBalance', bankAccount['balance'])

        if (typeof bankAccount['bank_name'] !== 'undefined')
            commit('setBankName', bankAccount['bank_name'])

        if (typeof bankAccount['bic'] !== 'undefined')
            commit('setBic', bankAccount['bic'])

        if (typeof bankAccount['holder_name'] !== 'undefined')
            commit('setHolderName', bankAccount['holder_name'])

        if (typeof bankAccount['iban'] !== 'undefined')
            commit('setIban', bankAccount['iban'])

        if (typeof bankAccount['_jv'] !== 'undefined') {
            commit('setId', bankAccount['_jv'].id)
            commit('setType', bankAccount['_jv'].type)
        }

        if (typeof bankAccount['property_management_company'] !== 'undefined' && Object.keys(bankAccount['property_management_company']).length)
            commit('setRelatedPropertyManagementCompany', bankAccount['property_management_company'])
    },

    async updateBankAccount({ dispatch }) {
        try {

            let request = await dispatch('formatBankAcountRequest')

            const bankAccount = await dispatch('tyltoApi/patch', request, { root: true })

            await dispatch('fillBankAccount', bankAccount)

            return bankAccount

        } catch(error) {

            throw error
        }
    }
}
