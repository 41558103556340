<template>
    
    <client-only v-if="mapPosition.lat && mapPosition.lat">

        <div class="google-map" ref="map"></div>

    </client-only>

</template>

<script>
    export default {
        data() {
            return {
                map: null,
                marker: null,
                mapZoom: 5,
                mapPosition: {
                    lat: 46.52863469527167,
                    lng: 2.43896484375
                }
            }
        },

        props: {
            location: {
                type: Object,
                required: false
            }
        },

        updated() {
            if (typeof this.$refs.map === 'undefined' || this.map === null) {
                
                let element = this.$refs.map
                
                this.map = new google.maps.Map(element, {
                    zoom: this.mapZoom,
                    center: this.mapPosition,
                    scrollwheel: false,
                    navigationControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    draggable: false
                })
            }

            if (Object.keys(this.location).length) this.panTo(this.location.lat, this.location.lng)
        },

        methods: {
            panTo(lat, lng) {
                
                this.mapPosition.lat = lat

                this.mapPosition.lng = lng

                if (this.map !== null) {

                    if (this.marker !== null) this.marker.setMap(null)

                    this.marker = new google.maps.Marker({
                        position: this.mapPosition,
                        map: this.map
                    })

                    this.map.panTo(this.mapPosition)

                    this.map.setZoom(16)
                }
            }
        },

        watch: {
            location: {
                immediate: false,
                handler(newLocation) {
                    if (Object.keys(newLocation).length) this.panTo(newLocation.lat, newLocation.lng)
                }
            }
        }
    }
</script>

<style lang="scss">
    .google-map {
        width: 100%;
        height: 244px;
        border-radius: 4px;
        overflow: hidden;
    }
</style>
