<template>
    
    <aside class="workflow-sidebar">

        <ul class="step-list">

            <li :class="['step', { 'active': activeStep === step.name }]" 

                v-for="(step, stepKey) in stepList" :key="stepKey" 

                @click="switchStep(step.name)">{{ step.label }}

                <ul :class="['sub-section', { 'collapsed': activeStep !== step.name }]">

                    <li :class="['sub-section-title', {'active': field.valid }]" v-for="(field, sectionKey) in getStepFieldsFilteredByStep(step.name)" :key="sectionKey" v-show="field.label">

                        <span :class="['completion-icon', {'active': field.valid }]"></span>

                        {{ field.label }}

                    </li>
                    
                </ul>

            </li>
            
        </ul>
        
    </aside>

</template>

<script>
    import { mapGetters } from 'vuex'
    import {validate} from 'vee-validate'

    export default {
        data() {
            return {
                stepFieldsWithValidator: []
            }
        },
        
        watch:{
            stepFields: {
                immediate:true,
                async handler(stepFields) {
                    let stepFieldsWithValidator = []

                    for (const field of stepFields) {

                        let { valid } = await validate(field.value, field.rules)

                        if (typeof field.value === 'array' && field.value.length === 0) valid = false

                        if (typeof field.value === 'object' && Object.keys(field.value).length === 0) valid = false

                        if (typeof field.value === 'string' && field.value === '') valid = false

                        if (typeof field.value === 'boolean' && field.value === false) valid = false

                        if (typeof field.value === 'number' && field.value === 0) valid = false

                        if (['disable','password'].includes(field.type)) valid = true

                        stepFieldsWithValidator.push(Object.assign(field, {
                        valid: valid
                        }))
                    }

                    this.stepFieldsWithValidator = stepFieldsWithValidator;
                }
            }
        },

        methods: {
            switchStep(code) {
                if (code !== this.activeStep) this.$store.dispatch('workflow/switchStep', code)
            },

            getStepFieldsFilteredByStep(code) {
                return this.stepFieldsWithValidator.filter(field => field.id === code)
            },
        },

        computed: {
            ...mapGetters({
                activeStep: 'workflow/getActiveStep',
                stepList: 'workflow/getSteps',
                stepFields: 'workflow/getFields'
            })
        }
    }
</script>

<style lang="scss">
    $step-color: #8F94A0;
    $step-color-active: #BED000;
    $color-dark-grey: #1F1F1F;

    .workflow-sidebar {
        margin-top: 60px;

        .step-list {
            position: sticky;
            top: 130px;
        }

        .step {
            color: $color-dark-grey;
            font-size: 12pt;
            font-weight: 600;
            line-height: 32px;
            cursor: pointer;

            &.active {
                margin-top: 15px;
                margin-bottom: 15px;
            }

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
        
        $size: 12px;
        $grey: #8F94A0;
        $color-black: #0E0A07;
        
        .sub-section {
            &.collapsed {
                display: none;
            }

            .sub-section-title {
                color: $color-black;
                display: flex;
                flex-direction: row;
                align-items: center;
                font-family: 'Montserrat';
                font-size: 11pt;
                
                &.active {
                    color : $step-color-active
                }

                .completion-icon {
                    display: flex;
                    position: relative;
                    width: $size;
                    height: $size;
                    border: 2px solid $grey;
                    border-radius: 100%;
                    align-items: center;
                    justify-content: center;
                    margin-right: 5px;
                    flex-shrink: 0;
                    
                    &:after {
                        border-radius: 2px;
                        position: absolute;
                        display: flex;
                        content: '';
                        width: calc(#{$size} / 3);
                        height: calc(#{$size} / 6);
                        transform: rotate(-45deg);
                    }
                    
                    &.active {
                        border : 2px solid $step-color-active;
                    
                        &:after {
                                border-bottom: 2px solid $step-color-active;
                                border-left: 2px solid $step-color-active;
                            }
                    }
                }
            }
        }
    }
</style>
