<template>

    <div class="landlord-main-nav">
    
        <main-nav class="nav-buttons" :items="landlordMainNav" />

    </div>

</template>

<script>
    import MainNav from '~/components/navigation/header/main-nav'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            MainNav
        },

        computed: {
            ...mapGetters({
                landlordId: 'profile/getId'
            }),

            landlordMainNav() {
                return [
                    {
                        path: '/landlord/' + this.landlordId,
                        name: this.$t('dashboard')
                    },
                    {
                        path: '/landlord/' + this.landlordId + '/payouts',
                        name: this.$t('payouts')
                    },
                    // {
                    //     path: '/landlord/' + this.landlordId + '/management-reports',
                    //     name: this.$t('management-reports')
                    // },
                    {
                        path: '/landlord/' + this.landlordId + '/informations',
                        name: this.$t('informations')
                    },
                    {
                        path: '/landlord/' + this.landlordId + '/documents',
                        name: this.$t('documents')
                    }
                ]
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "dashboard": "Dashboard",
            "payouts": "Virements",
            "management-reports": "Rapports de gestion",
            "informations": "Informations",
            "documents": "Documents"
        }
    }
</i18n>

<style lang="scss">
    $landlord-header-nav-color-active: #BED000;
    $landlord-header-nav-color: #8F94A0;
    $landlord-header-nav-border-color: #D9DFF0;

    .landlord-main-nav {
        display: flex;
        flex-direction: row;
        align-items: center;
       

        .nav-buttons {
            flex: 1;
            margin-top: 20px;

            .list-items .item {
                color: $landlord-header-nav-color;

                &.active, &:hover{
                    color: $landlord-header-nav-color-active;
                    border-color: $landlord-header-nav-color-active;
                }
            }
        }

        .actions {
            width: 125px;

            .input {
                box-shadow: 0 3px 0 0 #E6E8F9;
                background: white;
                height: 32px;
            }

            .drop-down {
                right: 0;
            }
        }
    }
</style>
