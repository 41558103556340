<template>

    <div class="tenant-action-buttons-widget margin-widget" v-if="isManager">

        <button-input :iconImg="'edit'" iconColor="#FFFFFF" class="action list" :text="$t('edit_tenant')" :redirectTo=" `/tenant/${tenantId}/update`" />

        <button-input :iconImg="'account'" iconColor="#FFFFFF" class="action list" :text="$t('invit_tenant')" @click="$refs.invitProfileModal.show()" v-if="!hasUser"/>

        <button-input class="action action-delete" iconColor="#FFFFFF" :iconImg="'delete'" :text="$t('delete_tenant')" @click="$refs.deleteTenantModal.show()" />

        <delete-tenant-modal ref="deleteTenantModal" />

        <invite-profile-modal type="tenant" ref="invitProfileModal" />

        <edit-payment-method ref="editPaymentMethodModal" />
        
    </div>

</template>

<script>
    import DeleteTenantModal from '~/components/modal/delete-tenant'
    import InviteProfileModal from '~/components/modal/invite-profile'
    import EditPaymentMethod from '~/components/modal/edit-payment-method'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            DeleteTenantModal,
            InviteProfileModal,
            EditPaymentMethod
        },

        props: {
            tenant: {
                type: Object,
                required: true
            }
        },

        computed: {
            ...mapGetters({
                tenantId: 'profile/getId',
                isManager: 'isManager',
                paymentMethod: 'profile/getRelatedPaymentMethods'
            }),

            hasUser() {
                return !!this.tenant._jv.json.included.find(inc => inc.type === 'users')
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "delete_tenant": "Supprimer le locataire",
            "edit_tenant": "Modifier le locataire",
            "invit_tenant": "Créer un espace personnel",
            "update_payment_method": "Modifier le moyen de paiement"
        }
    }
</i18n>

<style lang="scss">
    .tenant-action-buttons-widget {
        display: flex;
        flex-direction: column;
                
        .action {

            &.list{
                color: #FFFFFF;
                background: #393939;
            }
        }
    }
</style>
