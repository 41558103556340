import LandlordIcon from '~/components/icons/table-cell-icons/landlord-icon'
import PaymentIcon from '~/components/icons/table-cell-icons/payment-icon'
import PropertyIcon from '~/components/icons/table-cell-icons/property-icon'
import SupplierIcon from '~/components/icons/table-cell-icons/supplier-icon'
import TenantIcon from '~/components/icons/table-cell-icons/tenant-icon'

export {
    LandlordIcon,
    PaymentIcon,
    PropertyIcon,
    SupplierIcon,
    TenantIcon
}
