<template>
    
    <div class="landlord-layout">

        <smart-navigation-bar v-if="isManager" />

        <landlord-navigation-bar v-else />

        <profile-header class="profile-header" role="landlords" />

        <error-banner :title="$t('error_message')" :details="$t('contact_support')" v-if="errorMessage" />

        <div class="landlord-container">

            <div class="landlord-content layout-row">
    
                <div class="layout-col">

                    <nuxt-child />

                </div>

                <div class="layout-col col-5">

                    <contact-widget />

                    <spinner-loader class="loader" v-if="landlordFetchLoading" />

                    <landlord-actions-buttons-widget :landlord="landlord" @closed="handleClose" v-else/>

                    <landlord-property-list-widget />

                </div>
            
            </div>

        </div>
        
    </div>

</template>

<script>
    import LandlordNavigationBar from '~/components/navigation/header/landlord-navigation-bar'
    import SmartNavigationBar from '~/components/navigation/header/smart-navigation-bar'
    import ProfileHeader from '~/components/navigation/header/profile-header'
    import LandlordMainNav from '~/components/navigation/header/landlord-main-nav'
    import ErrorBanner from '~/components/error-banner'
    import ContactWidget from '~/components/widget/contact-widget'
    import LandlordPropertyListWidget from '~/components/widget/landlord-property-list-widget'
    import LandlordActionsButtonsWidget from '~/components/widget/landlord-actions-buttons-widget'
    import SpinnerLoader from '~/components/spinner-loader'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            ProfileHeader,
            LandlordNavigationBar,
            SmartNavigationBar,
            LandlordMainNav,
            ErrorBanner,
            ContactWidget,
            LandlordPropertyListWidget,
            LandlordActionsButtonsWidget,
            SpinnerLoader
        },

        

        data() {
            return {
                landlord: {},
                errorMessage: '',
                landlordFetchLoading: false
            }
        },

        async fetch() {
            try {

                this.landlordFetchLoading = true

                this.landlord = await this.$store.dispatch('profile/fetchLandlord', this.$route.params.landlordId)

            } finally {

                this.landlordFetchLoading = false
            }
                

        },

        computed: {
            ...mapGetters({
                isManager: 'isManager'
            })
        },

        methods: {
            handleClose() {
                this.$nuxt.refresh()
            }
        },

        errorCaptured(error) {
            
            if (!!error.response && !!error.response.status && parseInt(error.response.status) >= 500) {
                
                this.errorMessage = error
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "error_message": "Une erreur est survenue",
            "contact_support": "Contactez le support en envoyant un e-mail à support@oskar.immo si le problème persiste."
        }
    }
</i18n>

<style lang="scss" scoped>
    $width: 1180px;
    $background: #EFEFEF;

    .landlord-layout {
        min-height: 100vh;
        background: $background;

        .landlord-container {
            max-width: $width;
            margin-left: auto;
            margin-right: auto;

            .landlord-content {
                padding: 45px 0;
            }
        }
    }

    .loader {
        margin-top: 20px;
    }
</style>
