import defaultState from './default'

export default {
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },

    setDescription(state, value) {
        state.description = value
    },

    setDeliveryDate(state, value) {
        state.delivery_date = value
    },

    setExpirationDate(state, value) {
        state.expiration_date = value
    },

    setExtension(state, value) {
        state.extension = value
    },

    setFields(state, value) {
        state.fields = value
    },

    setFile(state, value) {
        state.file = value
    },

    setIsAutomaticallyGenerated(state, value) {
        state.is_autogenerated = value
    },

    setId(state, value) {
        state.id = value
    },

    setIsSignable(state, value) {
        state.is_signable = value
    },

    setIsSigned(state, value) {
        state.is_signed = value
    },

    setMimeType(state, value) {
        state.mime_type = value
    },

    setName(state, value) {
        state.name = value
    },

    setSignatureRequestEmbeddedUrl(state, value) {
        state.signature_request.embedded_url = value
    },

    setSignatureRequestId(state, value) {
        state.signature_request.id = value
    },

    setSignatureRequestSigners(state, value) {
        state.signature_request.signers = value
    },

    setSignatureRequestSignatures(state, value) {
        state.signature_request.signatures = value.filter(value => Object.keys(value).length)
    },

    setSignatureRequestStatus(state, value) {
        state.signature_request.status = value
    },

    setSize(state, value) {
        state.size = value
    },

    setRedirectPath(state, value) {
        state.redirect_path = value
    },

    setUpdatePath(state, value) {
        state.update_path = value
    },

    setTitle(state, value) {
        state.title = value
    },

    setTypes(state, value) {
        state.types = value
    },

    setUrl(state, value) {
        state.url = value
    }
}
