<template>

    <div class="validation-status-table-cell">

        <svg-icon width="24" height="24" viewBox="0 0 24 24" v-if="active"><active-icon /></svg-icon>

        <svg-icon width="24" height="24" viewBox="0 0 24 24" v-else><unactive-icon /></svg-icon>

    </div>

</template>

<script>
    import SvgIcon from '~/components/svg-icon'
    import UnactiveIcon from '~/components/icons/table-cell-icons/unactive-icon'
    import ActiveIcon from '~/components/icons/table-cell-icons/active-icon'

    export default {
        components: {
            SvgIcon,
            UnactiveIcon,
            ActiveIcon
        },

        props: {
            active: {
                type: Boolean,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .validation-status-table-cell {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>