<template>

    <div class="signature-table-cell">

        <spinner-loader v-if="loading" />

        <status-tag v-else :type="signatureRequestStatus" :icon="signatureRequestStatus">{{ $t(signatureRequestStatus) }}</status-tag>

    </div>

</template>

<script>
    import SpinnerLoader from '~/components/spinner-loader'

    export default {
        components: {
            SpinnerLoader
        },

        async fetch() {

            const { store } = this.$nuxt.context

            if (typeof this.fileId !== 'undefined' && this.fileId !== null) {

                this.file = await store.dispatch('file/fetchFile', this.fileId)

                if (Object.keys(this.file).length && !this.file.is_signed) {

                    this.signatureRequest = await store.dispatch('file/fetchSignatureRequestRelationship', this.file['_jv'].id)
                }
            }

            this.loading = false
        },

        props: {
            fileId: {
                type: [Number, String],
                required: false
            }
        },

        data() {
            return {
                loading: true,
                file: {},
                signatureRequest: {}
            }
        },

        computed: {
            signatureRequestStatus() {
                
                if (Object.keys(this.file).length && this.file.is_signed) return 'complete'

                else if (Object.keys(this.signatureRequest).length) return this.signatureRequest.status
                
                return 'not_started'
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "complete": "Signé",
            "ongoing": "En attente",
            "not_started": "Non signé"
        }
    }
</i18n>