<template>

    <form-group class="text-area-input" :label="label" :name="name" :rules="rules" v-slot="{ id, errors }">

        <textarea :class="[{ 'errors': errors.length }]"
                  :style="minHeightStyle"
                  :placeholder="placeholderIfExist"
                  :id="id" :value="value"
                  @input="emitInput($event.target.value)"
        />

    </form-group>

</template>

<script>
    import FormGroup from '~/components/inputs/form-group'

    export default {
        components: {
            FormGroup
        },

        props: {
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            value: {
                type: String,
                required: true
            },
            placeholder: {
                type: String,
                default: ''
            },
            rules: {
                type: [String, Object],
                default: ''
            },
            minHeight: {
                type: Number,
                default: 200
            }
        },

        methods: {
            emitInput(value) {
                this.$emit('input', value, this.name)
            }
        },

        computed: {
            minHeightStyle() {
                return {
                    'min-height': this.minHeight + 'px'
                }
            },

            placeholderIfExist() {
                if (this.placeholder !== '') return 'Ex : '+ this.placeholder
            }
        }
    }
</script>

<style lang="scss">
    $border-color: #E4E4E4;

    .text-area-input {
        textarea {
            resize: vertical;
            border: 1px solid $border-color;
            border-radius: 4px;
            padding: 20px;
            font-size: 11pt;
            box-sizing: border-box;
            resize: none;

            &.errors {
                background-color: #FBE8E8;
                border-color: #F5A623;
            }


        }
    }
</style>
