import { queryBuilder, queryResponse } from '~/utils/query'

export const strict = true

export const defaultState = () => {
    return {
        description: '',
        amount: 0,
        id: '',
        trade_date: '',
        payment_method_type: '',
        reference: '',
        check_number: '',
        issuer: '',
        issuer_bank_name: '',
        canceled_at: '',
        canceled_reason: '',
        relationships: {
            bank_account: {}
        }
    }
}

export const state = defaultState

export const mutations = {
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },

    setDescription(state, value) {
        state.description = value
    },

    setAmount(state, value) {
        state.amount = value
    },

    setCheckNumber(state, value) {
        state.check_number = value
    },

    setId(state, value) {
        state.id = value
    },

    setIssuer(state, value) {
        state.issuer = value
    },

    setIssuerBankName(state, value) {
        state.issuer_bank_name = value
    },

    setTradeDate(state, value) {
        state.trade_date = value
    },

    setCanceledAt(state, value) {
        state.canceled_at = value
    },

    setCanceledReason(state, value) {
        state.canceled_reason = value
    },

    setPaymentMethodType(state, value) {
        state.payment_method_type = value
    },

    setReference(state, value) {
        state.reference = value
    },

    setRelatedBankAccount(state, value) {
        state.relationships.bank_account = value
    }
}

export const getters = {
    getDescription(state) {
        return state.description
    },

    getAmount(state) {
        return state.amount
    },

    getCheckNumber(state) {
        return state.check_number
    },

    getId(state) {
        return state.id
    },

    getIssuer(state) {
        return state.issuer
    },

    getIssuerBankName(state) {
        return state.issuer_bank_name
    },

    getTradeDate(state) {
        return state.trade_date
    },

    getCanceledAt(state) {
        return state.canceled_at
    },

    getCanceledReason(state) {
        return state.canceled_reason
    },

    getPaymentMethodType(state) {
        return state.payment_method_type
    },

    getReference(state) {
        return state.reference
    },

    getRelatedBankAccount(state) {
        return state.relationships.bank_account
    }
}

export const actions = {
    async createRentablePayment({ commit, state, dispatch, rootGetters }, params) {
        try {

            let request = await dispatch('formatPaymentRequest')

            const id = typeof params.rentableId !== 'undefined' ? params.rentableId : rootGetters['rent/getId']

            const payment = await dispatch('tyltoApi/post', [request, { url: `${params.rentableType}/${id}/payments` } ], { root: true })

            await dispatch('fillPayment', payment)

            return payment

        } catch (error) {

            throw error
        }
    },

    async deletePayment({ dispatch }, paymentId) {
        try {

            return await dispatch('tyltoApi/delete', `payments/${paymentId}`, { root: true })

        } catch(error) {

            throw error
        }
    },

    async fetchPropertyManagerPayments({ dispatch }, { params, filters, page, aggregates, sort }) {
        try {

            const response = await dispatch('tyltoApi/get', [`property-management-companies/${params.propertyManagerId}/payments`, { params: queryBuilder({ filters, page, aggregates, sort }) }], { root: true })

            return queryResponse(response)

        } catch (error) {

            throw error
        }
    },

    async fetchPayment({ dispatch, rootGetters }, paymentId) {
        try {

            const id = typeof paymentId !== 'undefined' ? paymentId : rootGetters['payment/getId']

            const payment = await dispatch('tyltoApi/get', `payments/${id}`, { root: true })

            await dispatch('fillPayment', payment)

            return payment

        } catch (error) {

            throw error
        }
    },

    async updatePayment({ dispatch, rootGetters }, paymentId) {
        try {

            let request = await dispatch('formatPaymentRequest')

            const id = typeof paymentId !== 'undefined' ? paymentId : rootGetters['payment/getId']

            const payment = await dispatch('tyltoApi/patch', request, { root: true })

            await dispatch('fillPayment', payment)

            return payment

        } catch (error) {

            throw error
        }
    },

    async fillPayment({ commit }, payment) {
        commit('resetDefaultState')

        if (typeof payment['_jv'] !== 'undefined')
            commit('setId', payment['_jv'].id)

        if (typeof payment['amount'] !== 'undefined')
            commit('setAmount', payment['amount'])

        if (typeof payment['check_number'] !== 'undefined')
            commit('setCheckNumber', payment['check_number'])

        if (typeof payment['description'] !== 'undefined')
            commit('setDescription', payment['description'])

        if (typeof payment['issuer'] !== 'undefined')
            commit('setIssuer', payment['issuer'])

        if (typeof payment['issuer_bank_name'] !== 'undefined')
            commit('setIssuerBankName', payment['issuer_bank_name'])

        if (typeof payment['trade_date'] !== 'undefined')
            commit('setTradeDate', payment['trade_date'])

        if (typeof payment['canceled_at'] !== 'undefined')
            commit('setCanceledAt', payment['canceled_at'])

        if (typeof payment['canceled_reason'] !== 'undefined')
            commit('setCanceledReason', payment['canceled_reason'])

        if (typeof payment['payment_method_type'] !== 'undefined')
            commit('setPaymentMethodType', payment['payment_method_type'])

        if (typeof payment['reference'] !== 'undefined')
            commit('setReference', payment['reference'])

        if (typeof payment['bank_account'] !== 'undefined')
            commit('setRelatedBankAccount', payment['bank_account'])
    },

    async formatPaymentRequest({ getters, commit }) {
        let payment = {
            payment_method_type: getters['getPaymentMethodType'],
            _jv: {
                type: 'payments'
            }
        }

        if (getters['getId'] !== '') {
            payment['_jv'].id = getters['getId']
        }

        if (getters['getAmount'] !== 0) {
            payment['amount'] = getters['getAmount']
        }

        if (getters['getCheckNumber'] !== '') {
            payment['check_number'] = getters['getCheckNumber']
        }

        if (getters['getDescription'] !== '') {
            payment['description'] = getters['getDescription']
        }

        if (getters['getIssuer'] !== '') {
            payment['issuer'] = getters['getIssuer']
        }

        if (getters['getIssuerBankName'] !== '') {
            payment['issuer_bank_name'] = getters['getIssuerBankName']
        }

        if (getters['getTradeDate'] !== '') {
            payment['trade_date'] = getters['getTradeDate']
        }

        if (getters['getReference'] !== '') {
            payment['reference'] = getters['getReference']
        }

        if (getters['getCanceledAt'] !== '') {
            payment['canceled_at'] = getters['getCanceledAt']
        }

        if (getters['getCanceledReason'] !== '') {
            payment['canceled_reason'] = getters['getCanceledReason']
        }

        if (Object.keys(getters['getRelatedBankAccount']).length) {
            payment['_jv']['relationships'] = {
                bank_account: {
                    data: {
                        type: 'bank-accounts',
                        id: getters['getRelatedBankAccount']['_jv'].id
                    }
                }
            }
        }

        return payment
    },

    async updatePayment({ getters, dispatch }, paymentId) {
        try {

            let id = typeof paymentId !== 'undefined' ? paymentId : getters['getId']

            let request = await dispatch('formatPaymentRequest')

            const payment = await dispatch('tyltoApi/patch', [request, { url: `payments/${id}` } ], { root: true })

            await dispatch('fillPayment', payment)

            return payment

        } catch (error) {

            throw error
        }
    }
}
