import { queryResponse } from '~/utils/query'

export const strict = true

export const actions = {
    async fetchMediators({ dispatch }) {
        try {

            const response = await dispatch('tyltoApi/get', 'mediators', { root: true })

            return queryResponse(response)

        } catch(error) {

            throw error
        }
    },

    async fetchMediator({ dispatch }, mediatorId) {
        try {

            let mediators = await dispatch('tyltoApi/get', 'mediators/' + mediatorId, { root: true })

            return mediators

        } catch(error) {

            throw error
        }
    },
}