import defaultState from './default'

export default {
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },

    setCreatedAt(state, value) {
        state.created_at = value
    },

    setEmailAddress(state, value) {
        state.email_address = value
    },

    setEmailVerified(state, value) {
        state.email_verified = value
    },

    setFullName(state, value) {
        state.full_name = value
    },

    setId(state, value) {
        state.id = value
    },

    setPassword(state, value) {
        state.password = value
    },

    setOnboardingProgress(state, value) {
        state.onboarding_progress = value
    },

    setPropertyManagementCompany(state, value) {
        state.property_management_company = value
    },

    setRole(state, value) {
        state.role = value
    },

    setSwanUser(state, value) {
        if (typeof value.verification_status !== 'undefined') {
            state.swan_user.verification_status = value.verification_status
        }
        if (typeof value.verification_url !== 'undefined') {
            state.swan_user.verification_url = value.verification_url
        }
    },

    setLastLogin(state, value) {
        state.last_login_at = value
    }
}
