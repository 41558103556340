<template>

    <div class="table-list">

        <spinner-loader v-if="loading || initialLoading" />

        <template v-else>

            <div v-if="!groupedTBodies.length">

                <div :class="[ {'empty-resources' : !options.displayReceiptEmptyData }, { 'empty-receipt' : options.displayReceiptEmptyData}]">

                    <svg-icon class="icon" width="24" height="32" :viewbox="'0 0 12 16'" v-if="!options.displayReceiptEmptyData"><document-icon /></svg-icon>

                    <p v-if="!options.emptyText" v-html="$t('empty_data')"></p>

                    <p v-else v-html="options.emptyText"></p>

                </div>

            </div>

            <div class="grouped-table" v-for="(tBodyGroup, groupKey) in groupedTBodies" :key="groupKey" v-else>

                <h4 class="groupby-title" v-if="options.groupBy">{{ tBodyGroup[0][options.groupBy] }}</h4>

                <table class="table" cellspacing="0" :style="options.tableCustomStyle">

                    <thead :class="['table-header', { 'round-border': 'round_border' in options && options['round_border'], 'hide-header': ('displayHeader' in options && !options.displayHeader) }]">

                        <tr>

                            <th class="header-col selected-entry-column-header" v-if="'selectEntries' in options && options.selectEntries" :style="options.tableHeaderCustomStyle"></th>

                            <th :class="['header-col', { 'has-refinement': typeof getRefinement(column) !== 'undefined', 'hidden-mobile': column.hiddenOnMobile, 'right': column.position === 'right', 'empty': !column.label }]" v-html="getColumnContent(column)" :style="getTableHeaderCustomStyle(column.width, tHead)" v-for="(column, key) in tHead" :key="key" />

                            <th class="header-col actions-column-header" v-if="hasActions(tBodyGroup)" :style="actionsColumnHeaderStyle"></th>

                        </tr>

                    </thead>

                    <table-body :uniqId="uniqId" :class="{ 'round-border': 'round_border' in options && options['round_border'], 'highlight': tBody.highlight }"

                        :tBody="tBody"

                        :tHead="tHead"

                        :tBodyKey="tBodyKey"

                        :selectedEntries="'selectEntries' in options && options.selectEntries ? selectedEntries : undefined"

                        :selectAllEntries="options.selectAllEntries"

                        @updateSelectedEntries="updateSelectedEntries"

                        :style="options.tableBodyCustomStyle"

                        :tableCellCustomStyle="options.tableCellCustomStyle"

                        :max-selected-entries="maxSelectedEntries"

                        v-for="(tBody, tBodyKey) in tBodyGroup" :key="tBodyKey" />

                </table>

            </div>

        </template>



    </div>

</template>

<script>
    import SvgIcon from '~/components/svg-icon'
    import DocumentIcon from '~/components/icons/empty-resource-icons/document-icon'
    import SpinnerLoader from '~/components/spinner-loader'

    export default {
        components: {
            SvgIcon,
            DocumentIcon,
            SpinnerLoader
        },

        async fetch() {
            try {

                this.loading = true

                const fetchedTBodies = await Promise.all(this.tBodies.map(tBody => {

                    if (typeof tBody === 'function') return tBody.call()

                    return tBody
                }))

                const groupByValues = fetchedTBodies.map(tBody => tBody[this.options.groupBy])

                const uniqueGroupByValues = [...new Set(groupByValues)]

                this.groupedTBodies = uniqueGroupByValues.map(groupByAttribute => {

                    return fetchedTBodies.filter(tBody => this.options.groupBy !== 'undefined' ? tBody[this.options.groupBy] === groupByAttribute : true)
                })

            } catch(error) {

                throw error

            } finally {

                this.loading = false
            }
        },

        props: {
            tHead: {
                type: Array,
                required: true
            },
            tBodies: {
                type: Array,
                required: true
            },
            options: {
                type: Object,
                default() {
                    return {}
                }
            },
            refinements: {
                type: [Object, undefined],
                default: undefined,
            },
            initialLoading: {
                type: Boolean,
                default: false
            },
            maxSelectedEntries: {
                type: Number,
                default: Infinity,
            },
        },

        data() {
            return {
                selectedEntries: [],
                groupedTBodies: [],
                loading: false,
                uniqId: null
            }
        },

        beforeMount() {
            this.uniqId = crypto.randomUUID().replaceAll('-', '');
        },

        methods: {
            hasActions(tBodies) {

                return tBodies.filter(tBody => typeof tBody.actions !== 'undefined' && Object.keys(tBody.actions).length).length > 0
            },

            getRefinement(column) {
                return this.refinements?.items?.find(({ attribute }) => attribute === column?.facet?.attribute);
            },

            getColumnContent(column) {
                if (typeof this.getRefinement(column) === 'undefined') {
                    return column.label;
                }

                if (this.getRefinement(column).refinements.length === 0) {
                    return column.label;
                }

                return `${column.label} <span class="refinement-counter">${this.getRefinement(column).refinements.length}</span>`;
            },

            getTableHeaderCustomStyle(colWidth, tHead) {

                let tableHeaderCustomStyleOption = 'tableHeaderCustomStyle' in this.options ? this.options.tableHeaderCustomStyle : {}

                let width = typeof colWidth !== 'undefined' ? colWidth : 1

                let base = tHead.map(header => typeof header.width !== 'undefined' ? header.width : 1)
                    .reduce((a, b) => a + b)

                return Object.assign({ 'width': width * 100 / base + '%' }, tableHeaderCustomStyleOption)
            },

            updateSelectedEntries(entries) {

                this.selectedEntries = entries

                this.$emit('updateSelectedEntries', this.selectedEntries)
            },

            resetSelectedEntries() {

                this.selectedEntries = []
            }
        },

        watch: {
            tBodies: {
                async handler() {
                    while(this.$fetchState.pending) {
                        await new Promise((r) => setTimeout(r, 100))
                    }

                    this.$fetch()
                },
                deep: true
            }
        },

        computed: {
            actionsColumnHeaderStyle() {

                let headerWidth = {}

                if (this.groupedTBodies.length) headerWidth = {
                    width: `${Math.max(...this.groupedTBodies[0].map(tBody => (tBody.actions ? Object.keys(tBody.actions).length : 1) * 25))}px`
                }

                return Object.assign(headerWidth, this.options.tableHeaderCustomStyle)
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "empty_data": "Il n'y a aucune donnée à afficher pour le moment car la liste est vide."
        }
    }
</i18n>

<style lang="scss">
    $border-color: #F2F2F2;
    $grey-text-secondary: #B7BDCD;
    $grey-background:rgba(0, 0, 0, 0.2);
    $grey-light: #F2F2F2;
    $dark-grey: #525254;

    .table-list {
        display: flex;
        flex-direction: column;

        .empty-resources {
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
                text-align: center;
                margin-top: 8px;
            }
        }

        .empty-receipt {
            display: flex;
            padding: 0px 24px;
            align-items: flex-start;
            gap: 10px;
            align-self: stretch;
            border-radius: 12px;
            background:  $grey-light;
            height: 68px;
            flex-direction: column;
            justify-content: center;
            flex: 1 0 0;

            p {
                color: $dark-grey;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }

        .groupby-title {
            background: $grey-background;
            padding: 0px 8px;
            border-radius: 6px;
            color: white;
            text-transform: uppercase;
            font-size: 10.5pt;
            font-weight: 600;
            line-height: 40px;
        }

        .table {
            table-layout: fixed;
            border-collapse: separate;
            border-spacing: 0;
            width: 100%;

            .table-header {
                .header-col {
                    &:not(.empty) {
                        @include body;
                        padding: 0 8px;
                        padding-bottom: 14px;
                        border-bottom: 1px solid $border-color;
                        text-align: left;
                        font-size: 7.5pt;
                        font-weight: 600;
                        text-transform: uppercase;
                        color: $grey-text-secondary;
                    }

                    &.hidden-mobile {
                        @include desktop {
                            display: none;
                        }
                    }

                    &:first-child {
                        padding-left: 5px;
                    }

                    &:last-child {
                        padding-right: 5px;
                    }

                    &.has-refinement {
                        color: #471AB5;

                        .refinement-counter {
                            margin-left: 4px;
                            font-weight: bold;
                            text-transform: uppercase;
                            background-color: #4D0EBC;
                            min-width: 20px;
                            border-radius: 10px;
                            color: #FFF;
                            font-size: 8pt;
                            text-align: center;
                            margin-right: 8px;
                            line-height: 16px;
                            padding: 0 6px;
                        }
                    }

                    &.selected-entry-column-header {
                        width: 22px;
                        padding: 0 16px;

                        & ~ .header-col {
                            padding-left: 0px;
                        }
                    }

                    &.actions-column-header {
                        padding: 0 12px;

                        & ~ .header-col {
                            padding-left: 0px;
                        }
                    }

                    &.right {
                        text-align: right;
                    }
                }

                &.round-border {
                    th:first-child {
                        padding-left: 24px !important;
                    }

                    th:last-child {
                        padding-right: 24px !important;
                    }
                }

                &.hide-header {
                    .header-col {
                        padding-top: 0px;
                        padding-bottom: 0px;
                        height: 0px;
                        opacity: 0;
                    }
                }
            }
        }
    }
</style>
