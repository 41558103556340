<template>

    <tbody :class="['table-body', { 'open': !collapsed || !reconciliationcollapsed && expandedLines.length, 'hover': hover, 'selected': isSelectedEntry }]" @mouseover="hover = true" @mouseleave="hover = false">

        <tr class="table-row">

            <td class="table-cell selected-entry-table-cell" :style="tableCellCustomStyle" v-if="selectedEntries">

                <check-box-input class="check-box" :name="`selected_entry_${uniqId}_${tBody.entry['_jv'].id}`" v-model="isSelectedEntry" :label="' '" />

            </td>

            <table-cell :class="['table-cell', { 'right': column.position === 'right' }]"

                :data="tBody[column.field]"

                :model="tBody[column.model]"

                :type="column.type"

                :highlight="column.highlight"

                :hiddenOnMobile="column.hiddenOnMobile"

                :collapsed="collapsed"

                :reconciliationcollapsed="reconciliationcollapsed"

                :customStyle="tableCellCustomStyle"

                :style="getColumnWidth(column.width, tHead)"

                :colspan="column.colspan"

                @click="callAction(typeof tBody.actions !== 'undefined' ? tBody.actions.default : () => {})"

                @expandLines="expandLines"

                v-for="(column, key) in tHead" :key="`column-${key}`" />

            <td class="table-cell actions-table-cell" :style="tableCellCustomStyle" v-if="tBody.actions && !tBody.hideArrow">

                <div class="icon-group">

                    <div class="icon-container" @click.stop="" v-if="tBody.actions.dropdown">

                        <svg-icon class="icon" width="24" height="24" viewBox="0 0 24 24" iconColor="#BED000" @click.native="isDropdownExpanded = true"><more-icon /></svg-icon>

                        <dropdown class="dropdown" :options="tBody.actions.dropdown" @selectOption="callAction($event), isDropdownExpanded = false" @click-outside="isDropdownExpanded = false" v-if="isDropdownExpanded"/>

                    </div>

                    <template v-else>

                        <div class="icon-container" v-if="tBody.actions.edit">

                            <button-input @click.stop="callAction(tBody.actions.edit)" class="button background-phantom" iconColor="#525254" iconImg="edit" />

                        </div>

                        <div class="icon-container" v-if="tBody.actions.delete">

                            <button-input @click.stop="callAction(tBody.actions.delete.call(this, tBodyKey))" class="button background-phantom" iconImg="delete" />

                        </div>

                        <div class="icon-container" @click.stop="callAction(tBody.actions.default)" v-if="tBody.actions.default">

                            <svg-icon class="icon" width="10" height="16" iconColor="#393939"><next-icon /></svg-icon>

                        </div>

                    </template>

                </div>

            </td>

        </tr>

        <Transition name="table-list">

            <tr class="table-row table-expanded" v-if="!collapsed || !reconciliationcollapsed">

                <td class="table-cell-expanded" :colspan="tHead.length + (selectedEntries ? 1 : 0)" :style="{...tableCellCustomStyle, 'padding-left': '54px'}">

                    <table class="table" cellspacing="0">

                        <tr class="table-row expanded" v-for="(row, rowKey) in expandedLines" :key="rowKey">

                            <table-cell v-for="(column, key) in row" :key="`expanded-column-${key}`" :class="['table-cell', { 'right': column.position === 'right' }]" :data="column.data" :type="column.type" :colspan="column.colspan" :highlight="column.highlight" :style="getColumnWidth(column.width, tHead)" :customStyle="{'height': 'height' in tableCellCustomStyle ? tableCellCustomStyle.height : '', 'padding-left': '0'}" @click="callAction(typeof column.action !== 'undefined' ? column.action : () => {})"/>

                        </tr>

                    </table>

                </td>

            </tr>

        </Transition>

    </tbody>

</template>

<script>
    import SvgIcon from '~/components/svg-icon'
    import { AddNewIcon, NextIcon, MoreIcon } from '~/components/icons/button-icons'
    import SmoothEditIcon from '~/components/icons/button-icons/smooth-edit-icon'
    import SpinnerLoader from '~/components/spinner-loader'
    import Dropdown from '~/components/drop-down'

    export default {
        components: {
            SvgIcon,
            AddNewIcon,
            NextIcon,
            SmoothEditIcon,
            SpinnerLoader,
            Dropdown,
            MoreIcon
        },

        props: {
            tHead: {
                type: Array,
                required: true
            },
            tBody: {
                type: Object,
                required: true
            },
            tBodyKey: {
                type: Number,
                required: true
            },
            tableCellCustomStyle: {
                type: Object,
                default() {
                    return {}
                }
            },
            selectedEntries: {
                type: Array,
                required: false
            },
            selectAllEntries: {
                type: Boolean,
                required: false,
                default: false
            },
            uniqId: {
                type: String,
                required: true,
            },
            maxSelectedEntries: {
                type: Number,
                default: Infinity,
            },
        },

        data() {
            return {
                collapsed: true,
                reconciliationcollapsed: true,
                expandedLines: [],
                isDropdownExpanded: false,
                hover: false
            }
        },

        mounted() {
            if (this.selectAllEntries){
                setTimeout(() => this.isSelectedEntry = true, 100)
            }
        },

        methods: {
            expandLines(expandedLines, type) {
                if (type === 'bank-account-reconciliation') {
                    this.collapsed = true
                    this.reconciliationcollapsed = !this.reconciliationcollapsed
                } else {
                    this.reconciliationcollapsed = true
                    this.collapsed = !this.collapsed
                }

                this.expandedLines = expandedLines
            },

            getColumnWidth(colWidth, tHead) {

                let width = typeof colWidth !== 'undefined' ? colWidth : 1

                let base = tHead.map(header => typeof header.width !== 'undefined' ? header.width : 1)
                    .reduce((a, b) => a + b)

                return {
                    'width': width * 100 / base + '%'
                }
            },

            async callAction(action) {

                if (typeof action !== 'undefined') return await action.call(this, this.tBodyKey)
            }
        },

        computed: {
            isSelectedEntry: {
                get() {

                    if (typeof this.selectedEntries === 'undefined') return false

                    let selected = undefined

                    if (typeof this.tBody.entry !== 'undefined') {

                        selected = this.selectedEntries.find(entry => entry['_jv'].type === this.tBody.entry['_jv'].type && entry['_jv'].id === this.tBody.entry['_jv'].id)
                    }

                    return typeof selected !== 'undefined' ? true : false
                },
                set(newValue) {
                    if (newValue) {

                        let copy = [...this.selectedEntries]

                        copy.push(this.tBody.entry)

                        this.$emit('updateSelectedEntries', copy.slice(-this.maxSelectedEntries))

                    } else {

                        let index = this.selectedEntries.findIndex(entry => entry['_jv'].type === this.tBody.entry['_jv'].type && entry['_jv'].id === this.tBody.entry['_jv'].id)

                        if (index >= 0) {

                            let copy = [...this.selectedEntries]

                            copy.splice(index, 1)

                            this.$emit('updateSelectedEntries', copy)
                        }
                    }
                }
            }
        }
    }
</script>

<style lang="scss">
    $blue-background: rgba(87,141,255,.06);
    $border-color: #F2F2F2;

    .table-body {
        position: relative;
        transition: 0.1s ease-in;
        height: 100%;

        .table-row {

            .table-cell {
                height: 90px;
                box-sizing: border-box;
                padding: 20px 15px;
                position: relative;

                &.right {
                    text-align: right;

                    > * {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                    }
                }

                &:first-child {
                    padding-left: 5px;
                }

                &:last-child {
                    padding-right: 5px;
                }

                &.selected-entry-table-cell {
                    width: 20px;
                    padding: 0 16px;

                    & ~ .table-cell {
                        padding-left: 0px;
                    }

                    .check-box {
                        width: 100%;
                        margin-right: 0;
                    }
                }

                &.actions-table-cell {
                    padding-right: 10px;
                    overflow: initial;
                    position: relative;

                    .icon-group {
                        display: flex;
                        align-items: center;
                        gap: 12px;
                        justify-content: flex-end;
                    }
                }
            }

            .icon-container {
                display: flex;
                justify-content: center;
                align-items: center;

                .icon.delete {
                    transform: rotate(45deg);
                }

                .dropdown {
                    position: absolute;
                    top: 59px;
                    left: -98px;
                    z-index: 9;
                }

                .button {
                    margin-right: 8px;
                    width: 28px;
                    height: 28px;
                    min-height: 0px;
                    border-radius: 4px;
                    padding: 0;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        & ~ .table-body .table-row:not(.expanded) .table-cell {
            border-top: 1px solid $border-color;
        }

        &.hover,
        &.open,
        &.selected {
            .table-row {
                .table-cell {
                    cursor: pointer;
                    background: #EFEFEF;

                    &:first-child {
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                    }

                    &:last-child {
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                    }
                }
            }

            & ~ .table-body .table-row .table-cell {
                border-color: transparent;
            }
        }

        &.round-border {
            &:not(.selected) .table-row:not(.expanded) .table-cell {
                background: transparent;
            }

            &.selected .table-row:not(.expanded) .table-cell {
                border: 0 !important;
            }

            .table-row:not(.expanded) .table-cell {
                border-top: 1px solid #DEE3EF;
                border-bottom: 1px solid #DEE3EF;

                &:first-child {
                    border-left: 1px solid #DEE3EF;
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    padding-left: 24px !important;
                }

                &:last-child {
                    border-right: 1px solid #DEE3EF;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    padding-right: 24px !important;
                }
            }

            &.highlight .table-row:not(.expanded) .table-cell {
                border-color: #BED000;
            }
        }

        .table-expanded {
            border-collapse: collapse;
            border-spacing: 0;
            transform-origin: top;

            .table-cell-expanded {
                background: rgba(87, 141, 255, 0.06);
                border-radius: 10px;
                padding-left: 15%;
                position: relative;
                top: -4px;

                &.table-list-enter-active {
                    animation: scale-anim .2s ease-in;
                }

                &.table-list-leave-active {
                    animation: scale-anim .2s reverse ease-out;
                }

                .table {
                    table-layout: fixed;
                    width: 100%;

                    .table-row {
                        .table-cell {
                            background: transparent !important;
                            border-top: 1px solid rgba(143, 148, 160, 0.2) !important;
                            border-radius: 0;
                        }

                        &:first-child .table-cell {
                            border-top: 0 !important;
                        }
                    }
                }
            }
        }
    }
</style>
