<template>
    
    <div class="auth-template">

        <img class="img-logo" :src="logo" alt="Logo Oskar">

        <nuxt />

        <spinner-loader></spinner-loader>
        
    </div>

</template>

<script>
    import SpinnerLoader from '~/components/spinner-loader'
    import Logo from '~/static/img/logo/logo_oskar.svg';

    export default {
        components: { 
            SpinnerLoader
        },

        data() {
            return {
                logo: Logo
            }
        }
    }
</script>

<style lang="scss" scoped>
    .auth-template {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        flex-direction: column;
        
        .img-logo {
            width: 150px;
            transform: translateY(-50%);
        }
    }
</style>
