var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",staticClass:"form-input",attrs:{"tag":"div","vid":_vm.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var handleSubmit = ref.handleSubmit;
var errors = ref.errors;
var invalid = ref.invalid;
var reset = ref.reset;
var fields = ref.fields;
var failed = ref.failed;
return [(_vm.formErrors)?_c('form-info',{attrs:{"type":"error","text":_vm.formattedFormErrors}}):_vm._e(),_vm._v(" "),_c('form',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { reset(); _vm.formErrors = '' }),expression:"() => { reset(); formErrors = '' }"}],style:(_vm.formStyle),on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.formAction)}}},[_vm._t("default",null,{"errors":errors,"validate":validate,"invalid":invalid,"reset":reset,"fields":fields,"failed":failed,"setErrors":_vm.setErrors})],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }