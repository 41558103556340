<template>

    <svg :width="width" :height="height" viewBox="0 0 53 56" fill="none" xmlns="http://www.w3.org/2000/svg">

        <path opacity="0.1" d="M23.5166 45.2828C31.4678 42.6125 33.3929 48.6688 38.7815 42.8891C62.9805 12.8535 13.4785 3.07909 13.5406 16.6599C12.8294 22.7939 6.32151 24.0802 5.88607 30.599C5.45063 37.1178 15.5655 47.9532 23.5166 45.2828Z" fill="#BED000"/>

        <path d="M40.0604 28.1628C40.0604 32.2962 38.6162 35.8252 35.8681 38.4513C33.918 40.3159 30.9143 42.9292 30.9143 45.6923V46.9085C30.9143 47.4993 30.4353 47.9783 29.8446 47.9783H22.8379C22.2471 47.9783 21.7682 47.4993 21.7682 46.9086V45.6923C21.7682 42.9003 18.7526 40.2816 16.8143 38.4513C14.1282 35.9172 12.6221 32.1486 12.6221 28.1628V28.0783C12.6339 24.4401 14.0903 20.9556 16.671 18.3912C19.2517 15.8268 22.7453 14.3924 26.3835 14.4036C30.0216 14.4148 33.5064 15.8707 36.0712 18.451C38.6361 21.0312 40.071 24.5246 40.0604 28.1628Z" fill="#FFF3DB"/>
        
        <path d="M29.3901 52.4168H23.2927C23.1508 52.4168 23.0148 52.3604 22.9145 52.2601C22.8142 52.1598 22.7578 52.0238 22.7578 51.8819C22.7578 51.7401 22.8142 51.604 22.9145 51.5037C23.0148 51.4034 23.1508 51.347 23.2927 51.347H29.3901C29.5319 51.347 29.668 51.4034 29.7683 51.5037C29.8686 51.604 29.9249 51.7401 29.9249 51.8819C29.9249 52.0238 29.8686 52.1598 29.7683 52.2601C29.668 52.3604 29.5319 52.4168 29.3901 52.4168Z" fill="#FFF3DB"/>
        
        <path d="M26.9969 55.9999H25.6846C25.2288 56.0002 24.7816 55.8763 24.3909 55.6415C24.333 55.6077 24.2881 55.5554 24.2635 55.493C24.2389 55.4307 24.236 55.3619 24.2552 55.2976C24.2728 55.2317 24.3118 55.1734 24.366 55.132C24.4202 55.0905 24.4867 55.0682 24.5549 55.0685H28.1266C28.1949 55.0682 28.2613 55.0905 28.3155 55.132C28.3698 55.1734 28.4087 55.2317 28.4263 55.2976C28.4456 55.3619 28.4426 55.4307 28.418 55.4931C28.3934 55.5554 28.3485 55.6077 28.2906 55.6415C27.9 55.8763 27.4527 56.0002 26.9969 55.9999Z" fill="#FFF3DB"/>
        
        <path d="M29.3908 54.107L23.2573 54.1063L23.2383 54.0656C23.2202 53.9907 23.2194 53.9126 23.2361 53.8374C23.2528 53.7621 23.2865 53.6917 23.3345 53.6315C23.3833 53.5697 23.4454 53.5198 23.5162 53.4855C23.5871 53.4513 23.6648 53.4335 23.7435 53.4337H28.9378C29.0165 53.4335 29.0942 53.4513 29.165 53.4855C29.2359 53.5198 29.298 53.5697 29.3468 53.6315C29.3944 53.6909 29.428 53.7604 29.4448 53.8346C29.4617 53.9089 29.4615 53.986 29.4442 54.0602L29.4346 54.1015L29.3908 54.107Z" fill="#FFF3DB"/>
        
        <path d="M12.1566 15.5556C12.0273 15.5558 11.9023 15.5089 11.8051 15.4237L7.2118 11.4143C7.10657 11.3206 7.0426 11.1891 7.03377 11.0485C7.02494 10.9078 7.07197 10.7694 7.16465 10.6632C7.25732 10.5571 7.38815 10.4918 7.52869 10.4815C7.66924 10.4713 7.80815 10.5169 7.91524 10.6085L12.5085 14.6179C12.5906 14.6895 12.6488 14.7845 12.6754 14.8901C12.7021 14.9957 12.6958 15.1069 12.6576 15.2088C12.6193 15.3108 12.5508 15.3986 12.4613 15.4606C12.3718 15.5226 12.2655 15.5558 12.1566 15.5558V15.5556Z" fill="#FFF3DB"/>
        
        <path d="M39.7636 15.555C39.6547 15.555 39.5484 15.5218 39.4589 15.4598C39.3693 15.3978 39.3009 15.31 39.2626 15.208C39.2244 15.1061 39.2181 14.9949 39.2448 14.8893C39.2714 14.7837 39.3296 14.6888 39.4117 14.6172L44.0048 10.6074C44.1119 10.5158 44.2508 10.4702 44.3914 10.4804C44.5319 10.4907 44.6627 10.556 44.7554 10.6621C44.8481 10.7683 44.8951 10.9067 44.8863 11.0474C44.8775 11.188 44.8135 11.3195 44.7083 11.4132L40.115 15.4226C40.0178 15.5079 39.8929 15.555 39.7636 15.555Z" fill="#FFF3DB"/>
        
        <path d="M30.9139 50.5188H21.7678C21.6259 50.5188 21.4899 50.4625 21.3896 50.3622C21.2893 50.2619 21.2329 50.1258 21.2329 49.984C21.2329 49.8421 21.2893 49.7061 21.3896 49.6058C21.4899 49.5054 21.6259 49.4491 21.7678 49.4491H30.9139C31.0557 49.4491 31.1918 49.5054 31.2921 49.6058C31.3924 49.7061 31.4487 49.8421 31.4487 49.984C31.4487 50.1258 31.3924 50.2619 31.2921 50.3622C31.1918 50.4625 31.0557 50.5188 30.9139 50.5188Z" fill="#FFF3DB"/>
        
        <path d="M26.1166 36.5463L24.2147 47.9784H23.1289L25.0608 36.3709C25.0841 36.2309 25.162 36.1058 25.2775 36.0233C25.3929 35.9407 25.5364 35.9074 25.6764 35.9307C25.8165 35.954 25.9415 36.0319 26.024 36.1473C26.1066 36.2628 26.1399 36.4063 26.1166 36.5463Z" fill="#FFAE02"/>
        
        <path d="M29.7713 47.9784H28.6856L26.7836 36.5463C26.7603 36.4063 26.7937 36.2628 26.8762 36.1473C26.9588 36.0319 27.0838 35.954 27.2238 35.9307C27.3638 35.9074 27.5073 35.9407 27.6228 36.0233C27.7382 36.1058 27.8162 36.2309 27.8394 36.3709L29.7713 47.9784Z" fill="#FFAE02"/>
        
        <path d="M22.8647 36.1639C22.8647 36.022 22.9211 35.886 23.0214 35.7857C23.1217 35.6854 23.2578 35.629 23.3996 35.629H29.2823C29.4242 35.629 29.5602 35.6854 29.6605 35.7857C29.7608 35.886 29.8172 36.022 29.8172 36.1639C29.8172 36.3057 29.7608 36.4418 29.6605 36.5421C29.5602 36.6424 29.4242 36.6987 29.2823 36.6987H23.3996C23.2578 36.6987 23.1217 36.6424 23.0214 36.5421C22.9211 36.4418 22.8647 36.3057 22.8647 36.1639Z" fill="#FFAE02"/>
        
        <path d="M26.341 10.2158C26.1992 10.2158 26.0631 10.1595 25.9628 10.0592C25.8625 9.95886 25.8062 9.82281 25.8062 9.68096V0.53486C25.8062 0.393006 25.8625 0.256962 25.9628 0.156657C26.0631 0.0563511 26.1992 0 26.341 0C26.4829 0 26.6189 0.0563511 26.7192 0.156657C26.8195 0.256962 26.8759 0.393006 26.8759 0.53486V9.68096C26.8759 9.82281 26.8195 9.95886 26.7192 10.0592C26.6189 10.1595 26.4829 10.2158 26.341 10.2158Z" fill="#FFF3DB"/>
    
    </svg>

</template>

<script>
    export default{

        props: {
            width:{
                type: String
            },
            height: {
                type: String
            }
        }
    }
</script>
