<template>

    <div :class="['burger-menu', { 'active': menuIsOpen }]" @click.stop="toggleMenu">

        <span></span>
        <span></span>
        <span></span>
        <span></span>

    </div>

</template>

<script>
import { mapGetters } from 'vuex'

export default {

    methods: {
        toggleMenu() {
            this.$store.commit('setBurgerMenuIsOpen', !this.menuIsOpen)
        }
    },

    computed: {
        ...mapGetters({
            menuIsOpen: 'getBurgerMenuIsOpen',
        }),
    }

}
</script>

<style lang="scss">
    .burger-menu {
        display: block;
        width: 25px;
        height: 21px;
        position: relative;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        z-index: 5;
        cursor: pointer;

        span {
            position: absolute;
            height: 3px;
            width: 100%;
            background: white;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;

            &:nth-child(1) {
                top: 0px;
            }

            &:nth-child(2),&:nth-child(3) {
                top: 8px;
            }

            &:nth-child(4) {
                top: 16px;
            }
        }
        
        &.active {
            position: fixed;
        }

        &.active span {
            background:#471BB5;

            &:nth-child(1) {
                top: 6px;
                width: 0%;
                left: 50%;
            }

            &:nth-child(2) {
                transform: rotate(45deg);
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
            }

            &:nth-child(4) {
                top: 6px;
                width: 0%;
                left: 50%;
            }
        }
    }
</style>