export default ({ app, store, $config }) => {

    const hubspotElementId = 'hs-script-loader'

    app.router.afterEach(() => {

        let hubspotScriptElement = document.getElementById(hubspotElementId)

        if (!!$config.hubspotTrackingCode) {

            if (hubspotScriptElement !== null) return

            let newHubspotScriptElement = document.createElement('script')

            newHubspotScriptElement.setAttribute('type', 'text/javascript')

            newHubspotScriptElement.setAttribute('async', 'async')

            newHubspotScriptElement.setAttribute('defer', 'defer')

            newHubspotScriptElement.setAttribute('src', `//js-na1.hs-scripts.com/${$config.hubspotTrackingCode}.js`)

            newHubspotScriptElement.setAttribute('id', hubspotElementId)

            document.head.appendChild(newHubspotScriptElement) // Add script HTML Element to document
        }
    })
}
