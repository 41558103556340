<template>

    <div class="user-settings-drop-down" @click="toggleDropDown">

        <div :class="['user-infos', { 'collapsed': collapsed }]">

            <p class="auth-user">{{ fullName ? fullName : userEmailAddress }}</p>

        </div>

        <drop-down class="drop-down" :options="userOptions" @selectOption="redirectTo" :open="!collapsed" @click-outside="toggleDropDown">

            <template v-slot:header>

                <div class="profile-container">

                    <div class="auth-container">

                        <div class="icon auth-icon">

                            <svg-icon :iconStrokeColor="'#393939'" width="20" height="20" :viewbox="'0 0 17 18'"><profile-auth-icon /></svg-icon>

                        </div>

                        <div>

                            <p class="auth-name">{{ fullName ? fullName : userEmailAddress }}</p>

                            <p class="auth-description"> {{authProfileDescription}} </p>

                        </div>

                    </div>

                    <spinner-loader v-if="loading" />

                    <div class="account-container" v-if="!loading && authAccount.length">

                        <div class="account" v-for="(account, key) in authAccount" :key="key" @click.stop="redirectAccount(account)">

                            <div>

                                <p class="account-name">{{ getProfileFullName(account) }}</p>

                                <p class="account-description">{{ accountDescription(account) }}</p>

                            </div>

                            <svg-icon class="next-account" :iconColor="'#BED000'" :iconStrokeColor="'#393939'" width="16" height="16" :viewbox="'0 0 10 16'"><arrow-icon /></svg-icon>

                        </div>

                    </div>

                </div>

            </template>

        </drop-down>

    </div>

</template>

<script>
    import { mapGetters } from 'vuex'
    import SpinnerLoader from '~/components/spinner-loader'
    import UserSettingsDropDown from '~/components/navigation/user-settings-drop-down'
    import DropDown from '~/components/drop-down'
    import SvgIcon from '~/components/svg-icon'
    import ArrowIcon from '~/components/icons/button-icons/arrow-icon'
    import ProfileAuthIcon from '~/components/icons/profile/profile-auth-icon'
    import FullNameMixin from '~/components/mixins/full-name'
    import ProgressCircleChart from '~/components/widget/progress-circle-chart'

    export default {
        mixins:[FullNameMixin],

        components: {
            UserSettingsDropDown,
            DropDown,
            SvgIcon,
            ArrowIcon,
            ProfileAuthIcon,
            ProgressCircleChart,
            SpinnerLoader
        },

        data() {
            return {
                loading: false,
                collapsed: true,
                authAccount: []
            }
        },

        async fetch() {
           const { store } = this.$nuxt.context

            this.loading = true

            let { response: landlords } = await store.dispatch('profile/fetchLandlords')

            let { response: tenants } = await store.dispatch('profile/fetchTenants')
            
            
            if (this.profileRole === 'landlords') {
                await store.dispatch('profile/fetchLandlordPropertiesRelationship')
                landlords = Object.values(landlords).filter(landlord => landlord['_jv'].id !== this.profileId)
                tenants =  Object.values(tenants)
            }

            else if (this.profileRole === 'tenants') {
                landlords =  Object.values(landlords)
                tenants = Object.values(tenants).filter(tenant => tenant['_jv'].id !== this.profileId)
            }

            this.authAccount = landlords.concat(tenants)

            this.loading = false
        },

        methods: {
            redirectTo(path) {

                this.$router.push(path)
            },

            toggleDropDown() {

                this.collapsed = !this.collapsed
            },

            async redirectAccount(account) {

                this.$store.commit('setAuthProfile', account)

                if (account['_jv'].type === 'landlords') {
                    this.$router.push('/dashboard/landlord/' + account['_jv'].id)
                }

                if (account['_jv'].type === 'tenants') {
                    this.$router.push('/dashboard/tenant/' + account['_jv'].id)
                }

                this.toggleDropDown()

            },

            accountDescription(account) {
                if (account['_jv'].type === 'landlords') {
                    return `${this.$t(account['_jv'].type)} - ` // ${Object.values(account.properties).length} ${this.$t('properties')}`
                }

                if (account['_jv'].type === 'tenants') {
                    return `${this.$t(account['_jv'].type)} -` // ${Object.values(account.rentals).length} ${this.$t('properties')}`
                }
            }
        },

        computed: {
            ...mapGetters({
                fullName: 'profile/getFullName',
                userEmailAddress: 'user/getEmailAddress',
                userOnboardingProgress: 'user/getOnboardingProgress',
                profileId: 'profile/getId',
                profileRole: 'profile/getRole',
                landlordProperties: 'profile/getRelatedProperties',
                tenantProperties: 'profile/getRelatedRentals'
            }),

            userOptions() {
                return [
                    {
                        value: `/settings?${this.profileRole}=${this.profileId}`,
                        text: this.$t('settings'),
                        icon: 'settings',
                        iconColor: "#393939"
                    },
                    {
                        value: this.profileRole === 'landlords' ? `/landlord/${this.profileId}/update`: `/tenant/${this.profileId}/update`,
                        text: this.$t('personal_settings'),
                        icon: 'account',
                        iconColor: "#393939"
                    },
                    {
                        value: '/auth/logout',
                        text: this.$t('signout'),
                        icon: 'logout',
                        iconColor: "#393939"
                    }
                ]
            },

            authProfileDescription() {
                if (this.profileRole === 'landlords') {
                    return `${this.$t('landlords')} - ${this.landlordProperties.length} ${this.$t('properties')}`
                }

                else if (this.profileRole === 'tenants') {
                    return `${this.$t('tenants')} - ${this.tenantProperties.length} ${this.$t('properties')}`
                }
            },

            completion() {
                return this.userOnboardingProgress ? 'Profil complété à ' + this.userOnboardingProgress + '%' : 'Profil complété à ' + 0 + '%'
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "signout": "Se déconnecter",
            "personal_settings": "Paramètres personnel",
            "settings": "Profil et préferences",
            "switch_account": "Changer de compte",
            "landlords": "Propriétaire",
            "tenants": "Locataire",
            "properties": "biens"
        }
    }
</i18n>

<style lang="scss" scoped>
    $header-height: 68px;
    $drop-down-item-color: #393939;

    .user-settings-drop-down {
        max-width: 270px;
        position: relative;
        display: flex;
        align-items: center;

        .progress-chart {
            position: initial;
            margin-right: 12px;
        }

        .user-infos {
            position: relative;
            padding-right: 30px;
            white-space: nowrap;
            overflow: hidden;
            cursor: pointer;

            &:after {
                position: absolute;
                top: 50%;
                right: 0;
                content: '';
                display: flex;
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 5px solid #393939;
                transform: translateY(-50%);
                transition: transform .2s ease-in-out;
            }

            &.collapsed:after{
                transform: translateY(-50%) rotate(180deg);
            }
        }

        .auth-user {
            color: #393939;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .progression-info {
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            color: rgba(#393939,.6);
        }

        .drop-down {
            position: absolute;
            right: 0;
            top: calc(#{$header-height} - 15px);
            width: max-content;
            max-height: initial;
            color: $drop-down-item-color;
            font-size: 12pt;
            box-shadow: 0 4px 16px 0 rgba(0,0,0,.2);

            .profile-container {
                margin: 18px 18px 0px 18px;

                .auth-container {
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #EFEFEF;
                    padding-bottom: 24px;

                    .auth-icon {
                        width: 50px;
                        height: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 16px;
                        border-radius: 100%;
                        background-color: #EFEFEF;
                    }

                    .auth-name {
                        font-weight: 400;
                    }

                    .auth-description {
                        color: #8D939F;
                    }
                }

                .account-container {
                    border-bottom: 1px solid #EFEFEF;
                    padding-bottom: 24px;

                    .account {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 24px;
                        align-items: center;
                        cursor: pointer;

                        .next-account {
                            transform: rotate(180deg);
                        }

                        .account-name {
                            color: #BED000;
                            font-weight: 400;
                        }

                        .account-description {
                            color: #8D939F;
                        }
                    }
                }
            }
        }
        .drop-down::-webkit-scrollbar {
            display: none;
        }
    }
</style>
