<template>

    <jumbotron :class="['dashboard-tenant-header', backgroundImage]" 

        :title="fullName" 

        :subTitle="$t('created_at', { date: $moment(createdAt).format('DD/MM/YYYY') })"

        :headerNav="mainNav"

        iconPath="tenants">


    </jumbotron>

</template>

<script>
    import Jumbotron from '~/components/navigation/header/jumbotron'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            Jumbotron
        },

        props: {
            mainNav: {
                type: Array,
                required: true
            },
            backgroundImage: {
                type: String,
                required: true
            },

        },

        computed: {
            ...mapGetters({
                fullName: 'profile/getFullName',
                createdAt: 'profile/getCreatedAt',
                tenantId: 'profile/getId'
            })
        }

    }
</script>

<i18n>
    {
        "fr": {
            "created_at": "Créé le {date}"
        }
    }
</i18n>