<template>
    
    <div class="multi-step-resume" v-html="resume"></div>

</template>

<script>
    export default {
        props: {
            resume: {
                type: String,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .multi-step-resume {
        @include body;
        color: #8F94A0;
        margin-top: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &::v-deep b {
            @include body;
            font-weight: 600;
            color: #8F94A0;
        }
    }
</style>
