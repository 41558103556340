<template>
    
    <div :class="['form-info', type]">

        <svg-icon class="icon" :viewbox="'0 0 32 32'" :width="'32'" :height="'32'" :iconStrokeColor="'#FB5D5D'" v-if="type === 'error'"><close-icon /></svg-icon>

        <svg-icon class="icon" width="24" height="24" :iconColor="FormIconColor" :viewbox="'0.5 0 24 24'"  v-else><form-info-icon /></svg-icon>

        <div class="content">

            <p class="info-text">
                
                <span v-html="text"></span>
                
                <span class="see-more" v-if="textClickable" @click="reserseClick"> {{ clickableText }}</span>
                
            </p>

            <slot></slot>

        </div>

    </div>

</template>

<script>
    import SvgIcon from '~/components/svg-icon'
    import CloseIcon from '~/components/icons/button-icons/close-icon'
    import { FormInfoIcon } from '~/components/icons'

    export default {
        props: {
            text: {
                type: [String, Error],
                required: false
            },
            type: {
                type: String,
                default: 'info'
            },
            textClickable: {
                type: String,
                default: ''
            }
        },

        components: {
            SvgIcon,
            FormInfoIcon,
            CloseIcon
        },

        data() {
            return {
                isClick: false
            }
        },

        methods: {
            reserseClick() {
                this.isClick = !this.isClick
                this.$emit('textClicked')
            }
        },

        computed: {
            clickableText() {
                if (this.textClickable === 'see_more') {
                    return this.isClick ? this.$t('see_less') : this.$t('see_more')
                }

                return this.$t(this.textClickable)
            },

            FormIconColor() {
                if(this.type === 'error') {
                    return '#FFAE02'
                } else return '#BED000'
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "see_more": "Voir plus",
            "see_less": "Voir moins ",
            "click_here": "Cliquez ici"
        }
    }
</i18n>

<style lang="scss" scoped>
    $info-background: rgba(190, 208, 0, 0.2);
    $info-color: rgba(82, 82, 84, 1);
    $error-background: #FBE8E8B8;
    $error-color: #FB5D5D;

    .form-info {
        display: flex;
        padding: 14px 41px 14px 15px;
        flex-direction: row;
        margin-bottom: 25px;
        border-radius: 10px;
        text-align: left;

        .icon {
            margin-right: 16px;
            min-width: 24px;
            align-self: center;
        }
        
        .content {
            display: flex;
            flex-direction: column;

            .info-text, .info-text * {
                @include body;
                color: inherit;
                font-weight: 400;
                margin-top: 3px;

                ul{
                    margin-top: 5px;

                    li{
                        list-style: inside;
                    }
                }

                a {
                    text-decoration: underline;
                }

                a:not(.outline) {
                    color: inherit;
                }

                b {
                    font-weight: bold;
                    font-size: inherit
                }
            }
        }

        &.info {
            background: $info-background;
            color: $info-color;
        }

        &.error {
            background: $error-background;
            color: $error-color;
            align-items: center;
        }

        .see-more {
            text-decoration: underline;
            color: #BED000;
            cursor: pointer;
        }
    }
</style>
