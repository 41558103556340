<template>

    <div class="workflow-layout">

        <workflow-navigation-bar @exit="redirectBack" />

        <error-banner :title="$t('workflow_error')" :details="errorMessage" v-if="errorMessage" />

        <section class="layout-row content">

            <workflow-sidebar class="layout-col col-3 sidebar" />
        
            <div class="layout-col col-10 steps">

                <spinner-loader v-if="$fetchState.pending"/>
                
                <nuxt id="page" ref="page" v-else/>

            </div>

            <div class="layout-col col-3 preview-pdf-container">

                <template v-if="preview && Object.keys(file).length">

                    <div class="pdf-miniature" v-show="!fullscreen">

                        <spinner-loader v-if="!loaded" class="spinner-loader" />

                        <div class="container" v-show="loaded">

                            <button-input class="preview-button background-phantom green-text-color" :text="$t('open')" @click="fullscreen = true" />
                            
                            <img class="preview-img" :src="file.url" v-if="file.mime_type === 'image/png' || file.mime_type === 'image/jpeg'"/>

                            <div class="preview" v-else-if="file.mime_type === 'application/pdf'">

                                <vue-pdf-embed class="pdf" :source="file.url" :page="1" @rendered="pageRendered" ref="pdf"/>

                            </div>

                        </div>

                    </div>

                    <preview-pdf :file="file" v-if="fullscreen" @close="fullscreen = false" />

                </template>

            </div>

        </section>

        <workflow-button-group class="workflow-button-group" @onComplete="onSave" @saveAndQuit="saveAndQuit" />

    </div>

</template>

<script>
    import WorkflowNavigationBar from '~/components/navigation/header/workflow-navigation-bar-header'
    import WorkflowButtonGroup from '~/components/navigation/footer/workflow-button-group-footer'
    import WorkflowSidebar from '~/components/navigation/sidebar/workflow-sidebar'
    import ErrorBanner from '~/components/error-banner'
    import PreviewPdf from '~/components/preview-pdf'
    import SpinnerLoader from '~/components/spinner-loader'
    import { mapGetters } from 'vuex'

    export default {
        name: 'workflow',

        middleware: ['reset-store', 'fetch-user'],

        components: { 
            WorkflowNavigationBar, 
            WorkflowButtonGroup, 
            WorkflowSidebar,
            ErrorBanner,
            PreviewPdf,
            SpinnerLoader
        },

        async fetch() {

            const { route, store } = this.$nuxt.context

            if (typeof route.query.dumpId !== 'undefined') {
                
                await store.dispatch('workflow/restoreDump', route.query.dumpId)
            }
        },

        mounted() {
            if (typeof this.$route.query.step !== 'undefined') {

                this.$store.commit('workflow/setActiveStep', this.$route.query.step)
            }
        },

        data() {
            return {
                fullscreen: false,
                loaded: false
            }
        },

        methods: {
            async onSave() {
                return await this.$store.dispatch('workflow/saveAction')
            },

            redirectBack() {
                return this.$store.dispatch('workflow/redirectAction')
            },

            async saveAndQuit() {
                await this.$store.dispatch('workflow/createDumpState')

                this.$toast.success(this.$t('state_saved', { icon: 'check' }))

                await this.redirectBack()
            },

            pageRendered() {
                this.loaded = true
            }
        },

        computed: {
            ...mapGetters({
                isUpdateMode: 'workflow/isUpdateMode',
                isEditMode: 'workflow/isEditMode',
                errorMessage: 'workflow/getErrorMessage',
                preview: 'workflow/getExternalFilePreview',
                file: 'workflow/getExternalFile',
                fileMimeType: 'file/getMimeType',
                activeStep: 'workflow/getActiveStep',
                completedSteps: 'workflow/getCompletedSteps',
                steps: 'workflow/getSteps'
            })
        },

        errorCaptured(error) {
            // Display error message only for form submission
            if (!!error.response && !!error.response.status && parseInt(error.response.status) >= 500) {
                
                this.errorMessage = error
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "state_saved": "Le formulaire a été sauvegardé.",
            "workflow_error": "Création impossible",
            "server_error": "Erreur lors de la création.",
            "error_message": "Une erreur est survenue",
            "contact_support": "Contactez le support en envoyant un e-mail à support@oskar.immo si le problème persiste.",
            "open": "Ouvrir"
        }
    }
</i18n>

<style lang="scss" scoped>
    $background: #EFEFEF;
    $button-group-height: 68px;
    $button-group-margin: 60px;
    $color-black: #0E0A07;

    .workflow-layout {
        background: $background;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: calc(100vh - #{$button-group-height});
        margin-bottom: $button-group-margin;

        .content {
            display: flex;
            flex-direction: row;
            flex: 1;
            margin-top: 40px;
            padding-left: 30px;
            padding-right: 30px;

            .sidebar {
                flex-shrink: 1;
            }

            .steps {
                align-items: center;

                &::v-deep #page {
                    width: 100%;
                    min-width: 682px;
                }

                &::v-deep .step-form {
                    display: block;
                    box-shadow: 0px 7px 9px rgba(141, 141, 141, 0.1), 0px 4px 13px rgba(141, 141, 141, 0.08);
                    border-radius: 10px;
                    background: white;
                    color: $color-black;
                    margin-bottom: 10px;

                    &.active {
                        margin-bottom: 35px;
                        margin-top: 25px;
                
                        .step-content {
                            padding: 80px;
                            padding-top: 56px;

                            .form-active {
                                transform-origin: top;
                                display: flex;
                                flex-direction: column;
                            }

                            .header {
                                margin-bottom: 34px;
                            }
                        }
                    }

                    &:first-child {
                        margin-top: 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &:not(.active) {
                        box-shadow: 0px 7px 9px rgba(141, 141, 141, 0.1), 0px 4px 13px rgba(141, 141, 141, 0.08);
                    }

                    .step-content {
                        display: flex;
                        flex-direction: column;
                        padding: 40px 80px;

                        .form-active {
                            transform-origin: top;
                        }

                        .header {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            .title-group {
                                display: flex;
                                align-items: center;
                                flex: 1;

                                .title {
                                    font-size: 24px;
                                    cursor: pointer;
                                }

                                .status-tag {
                                    margin-left: 13px;
                                }
                            }
                        }
                    }

                    .button-group {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        border-top: 1px solid #F2F2F2;
                        padding: 40px 80px;
                        margin-top: -32px;

                        .button.next,
                        .button.save {
                            padding-left: 60px;
                            padding-right: 60px;
                        }
                    }
                }
            }

            .preview-pdf-container {
                z-index: 20;
                position: sticky;
                align-self: flex-start;
                top: calc(100vh - 300px);

                &.fullscreen {
                    position: initial;   
                }

                .pdf-miniature {
                    width: 160px;
                    border: 3px solid #BED000;
                    border-radius: 8px;
                    box-shadow: 0 3px 0 0 rgba(218, 220, 240, 1);
                    overflow: hidden;
                    height: 204px;
                    background: rgba(0,0,0,0.08);
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .container {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                        
                        .preview-button {
                            position: absolute;
                            height: 28px;
                            right: 5px;
                            top: 5px;
                            z-index: 10;
                            padding: 0px 10px;
                            border-radius: 5px;
                            opacity: 0;
                            transition: ease-in-out 0.2s;
                            min-height: inherit;
                        }

                        .preview,
                        .preview-img {
                            // max-height: 180px;
                            max-width: 160px;
                            cursor: pointer;
                            transition: ease-in-out 0.2s;

                            .pdf::v-deep canvas {
                                width: 100% !important;
                                height: auto !important;
                            }
                        }
                    }
                }

                .pdf-miniature:hover {
                    .preview-button {
                        opacity: 100;
                        transition: ease-in-out 0.2s;
                    }

                    .preview,
                    .preview-img  {
                        filter: brightness(70%);
                        transition: ease-in-out 0.2s;
                    }
                }
            }
        }
    }
</style>
