<template>

    <form-group :class="['search-input']" :label="label" :name="name" :rules="rules" ref="group">

        <template v-slot="{ errors }">

            <div :class="['group', { 'errors': errors.length, 'loading': loading }]">

                <div class="icon">

                    <svg-icon :iconColor="iconColor" width="16" height="16" v-if="iconImg && !loading"><search-icon /></svg-icon>

                    <spinner-loader class="spinner" v-if="loading" />

                </div>

                <input class="input" type="text" :name="name" :placeholder="placeholder ? `Ex : ${placeholder}` : ''" v-model="search" v-bind="inputAttrs" v-on="inputEvents" :style="inputStyle" autocomplete="off" ref="input">

            </div>

        </template>

    </form-group>

</template>

<script>
    import SvgIcon from '~/components/svg-icon'
    import SearchIcon from '~/components/icons/input-icons/search-icon'
    import FormGroup from '~/components/inputs/form-group'
    import SpinnerLoader from '~/components/spinner-loader'

    export default {
        components: { 
            SvgIcon,
            SearchIcon,
            FormGroup,
            SpinnerLoader
        },

        props: {
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            value: {
                type: String,
                required: true
            },
            placeholder: {
                type: String,
                required: false
            },
            rules: {
                type: [String, Object],
                default: ''
            },
            iconImg: {
                type: Boolean,
                default: true
            },
            iconColor: {
                type: String,
                default: 'rgba(38, 39, 43, 0.48)'
            },
            inputAttrs: {
                type: Object,
                required: false
            },
            inputEvents: {
                type: Object,
                required: false
            },
            inputStyle: {
                type: Object,
                required: false
            },
            loading: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            search: {
                get() {
                    return this.value
                },
                set(newVal) {
                    this.$emit('input', newVal)
                }
            }
        }
    }
</script>

<style lang="scss">
    $input-height: 50px;
    $border-color: #DEE3EF;

    .search-input {
        &.errors .group .input,
        .group.errors .input {
            background-color: #FBE8E8 !important;
            border-color: #F5A623 !important;
        }

        .spinner {
            align-items: center;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        .group {
            position: relative;
            display: flex;

            .input {
                height: $input-height;
                border: 1px solid $border-color;
                border-radius: 4px;
                padding: 0 20px;
                font-size: 11pt;
                box-sizing: border-box;
                flex: 1;

                &::placeholder {
                    opacity: 0.5;
                }

                &.errors {
                    background-color: #FBE8E8;
                    border-color: #F5A623;
                }
            }

            &.loading .input {
                color: rgba(30,30,30,.6);
            }

            .icon {
                position: absolute;
                transform: translateY(-50%);
                top: 50%;
                left: 16px;

                & ~ .input {
                    padding-left: 48px;
                }
            }
        }

        &.disabled {
            opacity: 0.5;
        }
    }
</style>
