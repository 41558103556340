export default {
    getType(state) {
        return state.type
    },

    getId(state) {
        return state.id
    },

    getCheckoutInventory(state) {
        return state.checkout_inventory
    },

    getComment(state) {
        return state.comment
    },

    getRelatedDocument(state) {
        return state.relationships.document
    },

    getRelatedItems(state) {
        return state.relationships.items
    },

    getRelatedRental(state) {
        return state.relationships.rental
    },
}