export default () => {
    return {
        document_fields: {
            duties: [],
            other_specific_clauses: [],
        },
        end_date: '',
        id: '',
        number: '',
        start_date: '',
        term: 0,
        relationships: {
            document: {},
            property: {},
            annexes: []
        }
    }
}
