<template>

    <modal
        class="confirm-before-exit-modal"
        @close="hide"
        ref="modal"
    >
        <template v-slot:modal-title>
            {{ $t('save_before_exit') }}
        </template>

        <template v-slot:modal-content>
            <p class="subtitle" v-html="$t('text')"/>
        </template>

        <template v-slot:modal-button>
            <button-input
                :class="['button background-success grey-darker-text-color', {}]"
                type="button"
                :text="$t('confirm')"
                :textStyle="{ 'white-space': 'wrap' }"
                @click="$emit('exit')"
            />
        </template>

    </modal>

</template>

<script>
import Modal from '~/components/v2/modal'
import ButtonInput from "@/components/inputs/button-input.vue";

export default {
    components: {
        ButtonInput,
        Modal
    },

    methods: {
        show() {
            this.$refs.modal.show()
        },

        hide() {
            this.$refs.modal.hide()
        },
    }
}
</script>

<i18n>
{
    "fr": {
        "save_before_exit": "Quitter sans sauvegarder",
        "text": "Si vous confirmer, les données saisies dans le formulaire seront supprimées.",
        "confirm": "Confirmer"
    }
}
</i18n>
