<template>

    <div class="bank-name-table-cell">
        
        <template v-if="Object.keys(bankAccount).length">

            <svg-icon class="logo" width="16" height="16" viewBox="0 0 28 28" :iconColor="'#9166FF'" v-if="bankAccount.bank_name === 'Swan'"><swan-logo /></svg-icon>

            <img-sprite class="logo border" :path="bankLogoSprite" :width="36" :height="36" :dpi="300" :total="bankOptions.length" :horizontalShift="bankOptions.indexOf(bankAccount.bank_name) + 1" v-else-if="bankOptions.includes(bankAccount.bank_name)" />
            
            <div class="texts">
                <span class="text">{{ bankAccount.bank_name }}</span>

                <span class="iban">{{ formatIban(bankAccount.iban) }}</span>
            </div>
        </template>

    </div>
    
</template>

<script>
    import SvgIcon from '~/components/svg-icon'
    import ImgSprite from '~/components/img-sprite'
    import { SwanLogo } from '~/components/icons/bank-icons'
    import BankAccountIconMixin from '~/components/mixins/icons/bank-account'
    
    export default {
        mixins: [BankAccountIconMixin],

        async fetch() {

            const { store } = this.$nuxt.context

            if (this.bankAccountId !== undefined && this.bankAccountId !== null && this.bankAccountId !== '') {
                this.bankAccount = await store.dispatch('bank-account/fetchBankAccount', this.bankAccountId)
            }
        },

        components: {
            SvgIcon,
            ImgSprite,
            SwanLogo
        },

        props: {
            bankAccountId: {
                type: [String, Number],
                required: true
            }
        },

        data() {
            return {
                bankAccount: {}
            }
        },

        methods: {
            formatIban(iban) {
                const firstTwoDigits = iban.slice(0, 2)
                
                const lastFourDigits = iban.slice(-4)

                const hiddenPart = iban.slice(2, -19).replace(/\w/g, '•')

                return `(${firstTwoDigits} ${hiddenPart} ${lastFourDigits})`
            }
        }
    }
</script>

<style lang="scss" scoped>
    $border-color: #DEE3EF;
    $gris-foncer: #393939;
    .bank-name-table-cell {
        display: flex;
        align-items: center;
        
        .logo {
            margin-right: 10px;
            flex-shrink: 0;

            &.border {
                border: 1px solid $border-color;
                border-radius: 6px;
                padding: 3px;
            }
        }

        .texts{
            display: flex;
            flex-direction: column;

            .text {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            .iban {
                color: $gris-foncer;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
            }
        }
    }
</style>
