<template>

    <form-group class="iban-element" :label="label" :name="name" :rules="rules" ref="formGroup">

        <input type="text" :value="state" v-show="false" />
    
        <div class="element" ref="iban" />
    
    </form-group>

</template>

<script>
    import FormGroup from '~/components/inputs/form-group'

    export default {
        components: { 
            FormGroup
        },
        
        props: {
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            value: {
                type: [String, Object],
                required: true
            },
            rules: {
                type: [String, Object],
                default: ''
            }
        },

        mounted() {
            const element = this.$stripeJs.createElement('iban', this.style)
            
            element.mount(this.$refs.iban)

            element.on('change', ({complete, error}) => {

                this.state = complete ? element : ''
                
                this.$emit('input', complete ? element : '')
                
                this.ibanElementError = error ? error.message : ''
            })
        },

        data() {
            return {
                state: '',
                ibanElementError: '',
                supportedCountries: ['SEPA'],
                placeholderCountry: 'FR',
                style: {
                    base: {
                        color: '#0E0A07',
                        fontSize: '11pt',
                        lineHeight: '46px',
                        fontWeight: '300'
                    },
                    invalid: {
                        iconColor: '#F5A623',
                        color: '#0E0A07',
                    }
                }
            }
        },

        methods: {
            setErrors(errors) {
                this.$nextTick(() => !!this.$refs.formGroup ? this.$refs.formGroup.setErrors(errors) : null)
            }
        },

        watch: {
            ibanElementError: {
                immediate: true,
                handler(error) {
                    if (error.length) this.setErrors([error])
                }
            }
        }
    }
</script>

<style lang="scss">
    $input-height: 50px;
    $border-color: #DEE3EF;

    .iban-element {
        .element {
            height: $input-height;
            border: 1px solid $border-color;
            border-radius: 4px;
            padding: 0 20px;
            font-size: 11pt;
            box-sizing: border-box;
        }
    }
</style>