import { queryBuilder, queryResponse } from '~/utils/query'
import notifications from "@/pages/dashboard/notifications.vue";

export const strict = true

export const defaultState = () => {
    return {
        message: '',
        id: '',
        type: '',
        relationships: {
            receiver: {},
            file: {},
        }
    }
}

export const state = defaultState

export const mutations = {
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },

    setMessage(state, value) {
        state.message = value
    },

    setId(state, value) {
        state.id = value
    },

    setType(state, value) {
        state.type = value
    },

    setRelatedReceiver(state, value) {
        state.relationships.receiver = value
    },

    setRelatedFile(state, value) {
        state.relationships.file = value
    }
}

export const getters = {
    getMessage(state) {
        return state.message
    },

    getId(state) {
        return state.id
    },

    getType(state) {
        return state.type
    },

    getRelatedReceiver(state) {
        return state.relationships.receiver
    },

    getRelatedFile(state) {
        return state.relationships.file
    }
}

export const actions = {
    async formatNotificationRequest({ getters }) {
        let notificationRequest = {
            message: getters['getMessage'],
            _jv: {
                type: 'notifications',
            }
        }

        if (getters['getId'] !== '')
            notificationRequest['_jv'].id = getters['getId']

        if (getters['getMessage'] !== '')
            notificationRequest['message'] = getters['getMessage']

        notificationRequest['_jv']['relationships'] = {}

        if (Object.keys(getters['getRelatedReceiver']).length) {
            notificationRequest['_jv']['relationships']['receiver'] = {
                data: {
                    type: getters['getRelatedReceiver']['_jv'].type,
                    id: getters['getRelatedReceiver']['_jv'].id
                }
            }
        }

        if (Object.keys(getters['getRelatedFile']).length)
            notificationRequest['_jv']['relationships']['file'] = {
                data: {
                    type: 'files',
                    id: getters['getRelatedFile']['_jv'].id
                }
            }


        return notificationRequest
    },

    async createNotification({ dispatch, getters }, { params }) {
        try {

            let request = await dispatch('formatNotificationRequest')

            let notifyType = typeof params.notify !== 'undefined' ? params.notify : getters['getType']

            const notification = await dispatch('tyltoApi/post', [request, { url: 'notifications/notify/' + notifyType }], { root: true })

            await dispatch('fillNotification', notification)

            return notification

        } catch(error) {

            throw error
        }
    },

    async fillNotification({ commit }, notification) {
        commit('resetDefaultState')

        if (typeof notification.message !== 'undefined')
            commit('setMessage', notification.message)

        if (typeof notification.receiver !== 'undefined' && Object.keys(notification.receiver).length)
            commit('setRelatedReceiver', notification.receiver)

        if (typeof notification.file !== 'undefined' && Object.keys(notification.file).length)
            commit('setRelatedFile', notification.file)

        if (typeof notification.type !== 'undefined')
            commit('setType', notification.type)

        if (typeof notification['_jv'] !== 'undefined') {
            commit('setId', notification['_jv'].id)
        }
    }
}
