<template>

    <div class="progression-table-cell">

        <label class="label">{{ label }}</label>

        <ul class="steps">

            <li :class="['step', { 'active': steps.indexOf(label) >= key }]" v-for="(step, key) in steps" :key="key"></li>

        </ul>

    </div>

</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                required: true
            },
            steps: {
                type: Array,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    $green: #119243;
    $grey: #ECEAF0;
    
    .progression-table-cell {
        .steps {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin-top: 8px;
            margin-left: -4px;
            margin-right: -4px;

            .step {
                flex: 1;
                height: 2px;
                margin: 0 4px;
                border-radius: 40px;
                background: $grey;

                &.active {
                    background: $green;
                }
            }
        }
    }
</style>