import {queryResponse} from "@/utils/query";

export default {

    async fetchAutomations({ dispatch }) {
        return await dispatch('tyltoApi/get', 'automations', { root: true });
    },

    async UpdateAllAutomations({ dispatch }, automations) {
        const dataPatch = automations.map((automation) => {
           return {
               id : automation['id'],
               type : 'automations',
               attributes : {
                   is_activated : automation['is_activated'],
                   activated_at : automation['activated_at'],
               },
           }
        });

        await this.$axios({ method: 'patch', url: 'automations', data : { data : dataPatch } })
    },
}
