<template>

    <div id="hellosign-viewer"></div>

</template>

<script>
    export default {
        props: {
            embeddedUrl: {
                type: String,
                required: true
            }
        },

        mounted() {
            const locale = this.$helloSign.locales.FR_FR

            const client = new this.$helloSign({
                clientId: this.$config.helloSignKey,
                container: document.getElementById('hellosign-viewer'),
                skipDomainVerification: true
            })

            client.open(this.embeddedUrl, {
                allowCancel: true,
                locale: locale
            })

            client.on('sign', (data) => this.$emit('success'))

            client.on('close', () => this.$emit('fail'))
        }
    }
</script>
