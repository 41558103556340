export default {
    getDocumentFieldsDuties(state) {
        return state.document_fields.duties
    },

    getDocumentFieldsOtherSpecificClauses(state) {
        return state.document_fields.other_specific_clauses
    },

    getEndDate(state) {
        return state.end_date
    },

    getId(state) {
        return state.id
    },

    getNumber(state) {
        return state.number
    },

    getStartDate(state) {
        return state.start_date
    },

    getTerm(state) {
        return state.term
    },

    getRelatedDocument(state) {
        return state.relationships.document
    },

    getRelatedProperty(state) {
        return state.relationships.property
    },

    getRelatedAnnexes(state) {
        return state.relationships.annexes
    }
}
