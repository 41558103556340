<template>
    
    <nav class="tenant-navigation-bar">

        <ul class="smart-list">
            
            <li class="smart-item logo"> 

                <logo :redirectPath="`/dashboard/tenant/${tenantId}`" /> 

            </li>

            <li class="smart-item user-settings">
                
                <customer-settings-drop-down />

            </li>

        </ul>

        <ul class="mobile-smart-list">

            <li class="smart-item"> 

                <burger-menu />

            </li>
            
            <li class="smart-item logo"> 

                <logo :redirectPath="`/dashboard/tenant/${tenantId}`" /> 

            </li>

            <li class="smart-item" @click="authToggleMenu">

                <svg-icon :iconStrokeColor="'#FFFFFF'" width="28" height="28" :viewbox="'0 0 17 18'"><profile-auth-icon /></svg-icon>

            </li>

        </ul>

        <!-- <property-list-menu :properties="rentalOptions" :isProperty="false" @input="redirectToRental"/> -->

        <!-- <customer-auth-menu ref="customAuthMenu" @closeMenu="authToggleMenu"/> -->

    </nav>

</template>

<script>
    import Logo from '~/components/logo'
    import PropertyDropDown from '~/components/navigation/property-drop-down'
    import CustomerSettingsDropDown from '~/components/navigation/customer-settings-drop-down'
    import PropertyNatureShorthandMixin from '~/components/mixins/property-nature-shorthand'
    import BurgerMenu from '~/components/navigation/header/burger-menu'
    import PropertyListMenu from '~/components/navigation/property-list-menu'
    import CustomerAuthMenu from '~/components/navigation/customer-auth-menu'
    import SvgIcon from '~/components/svg-icon'
    import ProfileAuthIcon from '~/components/icons/profile/profile-auth-icon'
    import { mapGetters } from 'vuex'
    
    export default {
        mixins: [PropertyNatureShorthandMixin],
        
        components: { 
            Logo,
            PropertyDropDown,
            CustomerSettingsDropDown,
            BurgerMenu,
            PropertyListMenu,
            CustomerAuthMenu,
            ProfileAuthIcon,
            SvgIcon
        },

        data() {
            return {
                authMenuIsOpen: false
            }
        },

        methods: {
            async rentalOptions() {

                const { response } = await this.$store.dispatch('rental/fetchRentals')

                let properties = await Promise.all(response.map(async rental => await this.$store.dispatch('rental/fetchPropertyRelationship', rental['_jv'].id)))
                
                return properties.map(property => {
                    return {
                        value: property.rental,
                        line1: Object.keys(property).length ? property.address.formatted : this.$t('unknown_address'),
                        line2: this.getPropertyNatureShorthand(property)
                    }
                })
            },

            redirectToRental(rental) {
                
                this.$router.push(`/dashboard/tenant/${this.tenantId}/rental/${rental['_jv'].id}`)
            },

            authToggleMenu() {
                
                this.authMenuIsOpen = !this.authMenuIsOpen

                return this.authMenuIsOpen ? this.$refs.customAuthMenu.openMenu() : this.$refs.customAuthMenu.closeMenu()
            }
        },

        computed: {
            ...mapGetters({
                tenantId: 'profile/getId'
            })
        }
    }
</script>

<i18n>
    {
        "fr": {
            "rentals": "Vos locations",
            "unknown_address": "Adresse inconue"
        }
    }
</i18n>

<style lang="scss">
    $white: white;
    $header-height: 68px;
    $border-color: #EFEFEF;

    .tenant-navigation-bar {
        position: relative;
        min-height: $header-height;
        display: flex;
        flex-direction: column;
        box-shadow: 0 2px 8px 0 rgba(0,0,0,.23);
        z-index: 9;

        .smart-list {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex: 1;
            background: $white;
            color: white;

            @include tablet { 
                display: none;
            }

            .smart-item {
                display: flex;
                align-items: center;
                padding: 10px 30px;

                &.logo {
                    flex-basis: 70px;
                }
            }
        }

        .mobile-smart-list {
            display: none;
            flex-direction: row;
            flex: 1;
            background: $white;
            color: white;

            @include tablet { 
                display: flex;
            }         
            
            .smart-item {
                display: flex;
                align-items: center;
                padding: 10px 30px;

                &.logo {
                    padding: 0;
                    flex: 3;
                }
            }
        }
    }
</style>
