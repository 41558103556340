<template>

    <client-only>

        <form-group class="address-finder" :label="label" :name="name" :rules="rules" v-slot="{ errors }" ref="formGroup">

            <input :value="value" :name="name" :label="label" :rules="rules" v-show="false">

            <div class="search-field">

                <input :class="['input', { 'errors': errors.length }]" :placeholder="placeholder" name="autocomplete" type="text" @focus="displayTooltip = true" @keydown.backspace="handleBackspace" ref="searchTextField">

                <tooltip :text="$t('set_manual_address')" :buttonText="$t('manual_entry')" @confirm="setManualAddress" v-show="displayTooltip" />

            </div>

            <google-map class="map" :location="addressLocation" v-if="mapDisplay" />

        </form-group>

    </client-only>

</template>

<script>
    import FormGroup from '~/components/inputs/form-group'
    import GoogleMap from '~/components/google-map'
    import Tooltip from '~/components/tooltip'

    export default {
        components: {
            FormGroup,
            GoogleMap,
            Tooltip
        },

        props: {
            name: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: false
            },
            value: {
                type: Object,
                required: true
            },
            placeholder: {
                type: String,
                required: false
            },
            mapDisplay: {
                type: Boolean,
                default: false
            },
            rules: {
                type: String,
                required: false
            },
            countryOption: {
                type: Array,
                required: false,
            }
        },

        updated() {
            if (typeof this.$refs.searchTextField !== 'undefined') {

                let input = this.$refs.searchTextField

                let options = {
                    componentRestrictions: {
                        country: this.countryOption
                    },
                    fields: this.fields
                }

                let autocomplete = new google.maps.places.Autocomplete(input, options)

                autocomplete.addListener('place_changed', () => {

                    let place = autocomplete.getPlace()

                    this.setPlace(place)
                })
            }
        },

        data() {
            return {
                fields: ['geometry', 'address_components', 'formatted_address'],
                addressLocation: {},
                displayTooltip: false
            }
        },

        methods: {
            handleBackspace() {
                if (Object.keys(this.value).length !== 0) {
                    this.$emit('input', {});
                }
            },

            setPlace(place) {
                let emitAddress = {
                    formatted: place.formatted_address,
                    country: place.address_components.find(element => element.types.includes('country')).short_name,
                    street_name: place.address_components.find(element => element.types.includes('route')).long_name
                }

                if (place.address_components.filter(element => element.types.includes('locality')).length) {
                    emitAddress['city'] = place.address_components.find(element => element.types.includes('locality')).long_name
                }

                else if (place.address_components.filter(element => element.types.includes('postal_town')).length) {
                    emitAddress['city'] = place.address_components.find(element => element.types.includes('postal_town')).long_name
                }

                if (place.address_components.filter(element => element.types.includes('administrative_area_level_1')).length) {
                    emitAddress['state'] = place.address_components.find(element => element.types.includes('administrative_area_level_1')).long_name
                }

                if (place.address_components.filter(element => element.types.includes('street_number')).length) {
                    emitAddress['house_number'] = place.address_components.find(element => element.types.includes('street_number')).long_name
                }

                if (place.address_components.filter(element => element.types.includes('postal_code')).length) {
                    emitAddress['postal_code'] = place.address_components.find(element => element.types.includes('postal_code')).long_name
                }

                else throw this.$t('no_postal_code')

                this.addressLocation = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                }

                this.displayTooltip = false

                this.$emit('input', emitAddress)
            },

            setManualAddress() {

                this.$emit('manual', true)

                this.displayTooltip = false

                this.addressLocation = {}

                this.$emit('input', {})
            },

            geocode(search) {

                const geocoder = new google.maps.Geocoder()

                let callback = function (results, status) {

                    if (status === 'OK') {
                        this.addressLocation = {
                            lat: results[0].geometry.location.lat(),
                            lng: results[0].geometry.location.lng()
                        }
                    }
                }

                geocoder.geocode({ address: search }, callback.bind(this))
            }
        },

        watch: {
            value: {
                immediate: true,
                handler(newVal) {
                    if (Object.keys(newVal).length) {

                        this.$nextTick(() => this.$refs.searchTextField.value = newVal.formatted)

                        if (!Object.keys(this.addressLocation).length) this.geocode(newVal.formatted)

                    } else {

                        this.$nextTick(() => this.$refs.searchTextField.value = '')

                        this.addressLocation = {}
                    }
                }
            }
        }
    }

</script>

<i18n>
    {
        "fr": {
            "loading": "Recherche en cours",
            "complete": "Terminé",
            "no_postal_code": "L'adresse ne contient pas de code postal",
            "manual": "Mode manuel",
            "manual_entry": "Saisir manuellement",
            "search_place": "Recherchez un lieu",
            "search_place_placeholder": "3 rue de la mairie",
            "set_manual_address": "L'adresse ne figure pas dans la liste des résultats"
        }
    }
</i18n>

<style lang="scss" scoped>
    $input-height: 50px;
    $border-color: #DEE3EF;

    .address-finder {
        display: flex;
        flex-direction: column;
        align-items: initial;
        margin-bottom: 0;

        .search-field {
            display: flex;
            position: relative;

            .input {
                flex: 1;
                height: $input-height;
                border: 1px solid $border-color;
                border-radius: 4px;
                padding: 0 20px;
                font-size: 11pt;
                box-sizing: border-box;

                &::placeholder {
                    opacity: 0.5;
                }

                &.errors {
                    background-color: #FBE8E8;
                    border-color: #F5A623;
                }
            }
        }

        .map {
            margin-top: 10px;
        }
    }
</style>
