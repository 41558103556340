export default async function ({store, redirect}) {

    const landlords = await store.dispatch('profile/fetchLandlords')

    if (landlords.response.length) {
        const landlord = landlords.response[0];
        if (landlord.length === 0) {
            return redirect('/');
        }
        await store.dispatch('profile/fillLandlord', landlord);
        store.commit('setAuthProfile', landlord);
    }
}
