<template>
    
    <nav class="smart-navigation-bar">

        <ul class="smart-list">
            
            <li class="smart-item logo"> 

                <logo /> 

            </li>

            <li class="smart-item search-bar">
                
                <instant-search-input class="search-input" name="navigivation-instant-search-input" :placeholder="$t('search-input-placeholder')" iconImg="search" />

            </li>

            <li class="smart-item user-settings">
                
                <user-settings-drop-down />

            </li>

        </ul>

    </nav>

</template>

<script>
    import Logo from '~/components/logo'
    import UserSettingsDropDown from '~/components/navigation/user-settings-drop-down'
    import InstantSearchInput from '~/components/inputs/instant-search-input.vue'
    
    export default {
        components: { 
            Logo, 
            UserSettingsDropDown,
            InstantSearchInput
        },
    }
</script>

<i18n>
    {
        "fr": {
            "search-input-placeholder": "Rechercher un propriétaire, un lot, un locataire …"
        }
    }
</i18n>

<style lang="scss">
    $background-search-bar: #EFEFEF;
    $header-height: 68px;

    .smart-navigation-bar {
        position: relative;
        min-height: $header-height;
        display: flex;
        flex-direction: column;
        box-shadow: 0 2px 8px 0 rgba(0,0,0,.23);
        z-index: 9;

        .smart-list {
            display: flex;
            flex-direction: row;
            flex: 1;
            background: white;

            .smart-item {
                display: flex;
                align-items: center;
                padding: 10px 30px;

                &.logo {
                    flex-basis: 70px;
                }

                &.search-bar {
                    flex-basis: 100%;
                    display: flex;
         
                    .search-input {
                        flex: 1;
                        padding-left: 0;
                        border-radius: 20px;
                        position: relative;
                        background: $background-search-bar;
                        border: none;
                        height: 44px;
                        box-sizing: border-box;
                        color: white;
                    }
                }
            }
        }
    }
</style>
