<template>

    <div class="update-layout">

        <update-navigation-bar-header @back="redirectAction" v-if="isManager || isAuthTenant || isAuthLandlord" />

        <template v-else>

            <landlord-navigation-bar-header v-if="profileRole === 'landlords'"/>

            <tenant-navigation-bar-header v-else-if="profileRole === 'tenants'"/>

        </template>

        <error-banner :title="$t('error_message')" :details="$t('contact_support')" v-if="errorMessage" />

        <section class="layout-row content">

            <update-sidebar class="sidebar list" />

            <div class="layout-col steps">
                
                <nuxt class="container" ref="page" />

            </div>

        </section>

    </div>

</template>

<script>
    import UpdateNavigationBarHeader from '~/components/navigation/header/update-navigation-bar-header'
    import LandlordNavigationBarHeader from '~/components/navigation/header/landlord-navigation-bar.vue'
    import TenantNavigationBarHeader from '~/components/navigation/header/tenant-navigation-bar.vue'
    import UpdateSidebar from '~/components/navigation/sidebar/update-sidebar'
    import ErrorBanner from '~/components/error-banner'
    import { mapGetters } from 'vuex'

    export default {

        middleware: ['reset-store', 'fetch-user'],
        
        components: {
            UpdateNavigationBarHeader,
            LandlordNavigationBarHeader,
            TenantNavigationBarHeader,
            UpdateSidebar,
            ErrorBanner
        },

        async fetch(){
            try {

                const { store } = this.$nuxt.context

                if (!this.isManager) {
                    const tenantId = this.$route.query.tenants
                    const landlordId = this.$route.query.landlords

                    if (tenantId) {
                        await store.dispatch('profile/fetchAndFillTenant', tenantId)
                    } else if (landlordId) {
                        await store.dispatch('profile/fetchAndFillLandlord', landlordId)
                    }
                }

            } catch (error) {
                
            }
        },

        mounted() {
            this.$store.commit('workflow/setEditMode')

            if (typeof this.$route.query.step !== 'undefined') {

                this.$store.commit('workflow/setActiveStep', this.$route.query.step)
            }
        },

        data() {
            return {
                errorMessage: ''
            }
        },

        computed: {
            ...mapGetters({
                profileRole: 'profile/getRole',
                activeStep: 'workflow/getActiveStep',
                steps: 'workflow/getSteps',
                isManager: 'isManager',
                redirectAction: 'workflow/getRedirectAction',
                isAuthTenant: 'isAuthTenant',
                isAuthLandlord: 'isAuthLandlord'
            })
        },

        errorCaptured(error) {
            
            if (!!error.response && !!error.response.status && parseInt(error.response.status) >= 500) {
                
                this.errorMessage = error
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "update_error": "Erreur lors de la mise à jour.",
            "error_message": "Une erreur est survenue",
            "contact_support": "Contactez le support en envoyant un e-mail à support@oskar.immo si le problème persiste.",
            "back": "Retour"
        }
    }
</i18n>

<style lang="scss" scoped>
    $background: #EFEFEF;
    $button-group-height: 68px;
    $button-group-margin: 60px;
    $color-black: #0E0A07;

    .update-layout {
        background: $background;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100vh;

        .content {
            display: flex;
            flex-direction: row;
            flex: 1;
            margin-top: 40px;
            padding-left: 30px;
            padding-right: 30px;

            .sidebar {
                max-width: 240px;
                flex-shrink: 1;
            }

            .steps {
                align-items: center;

                &::v-deep .container {
                    width: 60vw;
                    min-width: 682px;
                    margin-right: 160px;
                }

                &::v-deep .step-form {
                    display: block;
                    box-shadow: 0 3px 18px 0 #E0E6F6;
                    border-radius: 10px;
                    background: white;
                    color: $color-black;
                    margin-bottom: 10px;

                    &.active {
                        margin-bottom: 35px;
                        margin-top: 25px;
                
                        .step-content {
                            padding: 80px;
                            padding-top: 56px;

                            .form-active {
                                transform-origin: top;
                                display: flex;
                                flex-direction: column;
                            }

                            .header {
                                margin-bottom: 34px;
                            }
                        }
                    }

                    &:first-child {
                        margin-top: 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &:not(.active) {
                        box-shadow: 0 3px 18px 0 #E0E6F6, 0 3px 0 0 #DADCF0;
                    }

                    .step-content {
                        display: flex;
                        flex-direction: column;
                        padding: 40px 80px;

                        .form-active {
                            transform-origin: top;
                        }

                        .header {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            .title-group {
                                display: flex;
                                align-items: center;
                                flex: 1;

                                .title {
                                    font-size: 24px;
                                    cursor: pointer;
                                }

                                .status-tag {
                                    margin-left: 13px;
                                }
                            }
                        }
                    }

                    .button-group {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        border-top: 1px solid #F2F2F2;
                        padding: 40px 80px;
                        margin-top: -32px;

                        .button.next,
                        .button.save {
                            padding-left: 60px;
                            padding-right: 60px;
                        }
                    }
                }
            }
        }

        .update-button-group {
            display: flex;
            align-items: center;
            min-height: $button-group-height;
            position: fixed;
            bottom: 0;
            right: 0;
            left: 0;
        }
    }
</style>
