<template>
    
    <div class="multi-step-progression">
        
        <svg class="stat-circle" viewBox="0 0 20 20" ref="statCircle">
            
            <circle class="bg" cx="10" cy="10" r="8"></circle>
    
            <circle class="progress" cx="10" cy="10" r="8" :data-percentage="completion" :style="{ 'stroke-dashoffset': strokeDashOffset + 'px' }"></circle>
        
        </svg>

    </div>

</template>

<script>
    import { mapGetters } from 'vuex'
    
    export default {
        props: {
            fieldsBag: {
                type: Object,
                required: true
            },
            failed: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                initialized: false
            }
        },

        watch: {
            failed: {
                immediate: true,
                handler(failed) {
                    if (failed) this.initialized = true
                }
            }
        },
        
        computed: {
            completion() {
                const allFields = Object.values(this.fieldsBag);
                const validFields = allFields.filter(field => field.changed);
                const completionPercentage = (validFields.length / allFields.length) * 100;
                return completionPercentage;
            },

            strokeDashOffset() {
                return -51 - ((51 / 100) * this.completion);
            },

            errorFields() {
                return Object.values(this.fieldsBag).map(field => field.invalid).reduce((a, b) => a + b, 0)
            }
        }
    }
</script>

<style lang="scss">
    $size: 32px;
    $green: #18C95D;

    .multi-step-progression {
        display: flex;
        align-items: center;
        justify-items: center;
        
        .stat-circle {
            width: $size;
            
            circle.bg {
                fill: none;
                stroke: #DDE3F4;
                stroke-width: 2;
            }

            circle.progress {
                fill: none;
                stroke: $green;
                stroke-width: 2;
                stroke-dasharray: 51 51;
                stroke-dashoffset: -51;
                stroke-linecap: round;
            }
        }

        &.completed {
            display: flex;
            position: relative;
            width: $size;
            height: $size;
            background: $green;
            border-radius: 100%;
            align-items: center;
            justify-content: center;

            &:after {
                border-radius: 2px;
                position: absolute;
                display: flex;
                content: '';
                width: calc(#{$size} / 3);
                height: calc(#{$size} / 6);
                border-bottom: 3px solid white;
                border-left: 3px solid white;
                transform: rotate(-45deg);
            }

            .stat-circle {
                display: none;
            }
        }
    }
</style>
