export const strict = true

export const defaultState = () => {
    return {
        'two_letters_code': '',
        code: '',
        description: ''
    }
}

export const state = defaultState

export const mutations = {
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },
    
    set2LettersCode(state, value) {
        state['two_letters_code'] = value
    },

    setCode(state, value) {
        state.code = value
    },

    setDescription(state, value) {
        state.description = value
    }
}

export const getters = {
    get2LettersCode(state) {
        return state['two_letters_code']
    },

    getCode(state) {
        return state.code
    },

    getDescription(state) {
        return state.description
    }
}

export const actions = {
    async fetchAccountingJournal({ dispatch, rootGetters }, accountingJournalId) {
        try {

            let accountingJournal = rootGetters['tyltoApi/get'](`accounting-journals/${accountingJournalId}`)

            if (typeof accountingJournal === 'undefined' || !Object.keys(accountingJournal).length) {
            
                accountingJournal = await dispatch('tyltoApi/get', 'accounting-journals/' + accountingJournalId, { root: true })
            }

            await dispatch('fillAccountingJournal', accountingJournal)

            return accountingJournal
        
        } catch(error) {

            throw error
        }
    },

    async fillAccountingJournal({ commit }, accountingJournal){
        commit('resetDefaultState')

        if (typeof accountingJournal['two_letters_code'] !== 'undefined')
            commit('set2LettersCode', accountingJournal['two_letters_code'])

        if (typeof accountingJournal['code'] !== 'undefined')
            commit('setCode', accountingJournal['code'])

        if (typeof accountingJournal['description'] !== 'undefined')
            commit('setDescription', accountingJournal['description'])
    },
}



