<template>

    <div class="supplier-main-nav">
    
        <main-nav class="nav-buttons" :items="supplierMainNav" />

    </div>

</template>

<script>
    import MainNav from '~/components/navigation/header/main-nav'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            MainNav
        },

        computed: {
            ...mapGetters({
                supplierId: 'profile/getId'
            }),

            supplierMainNav() {
                return [
                    {
                        path: '/supplier/' + this.supplierId,
                        name: this.$t('dashboard')
                    },
                    {
                        path: '/supplier/' + this.supplierId + '/informations',
                        name: this.$t('informations')
                    }
                ]
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "dashboard": "Dashboard",
            "informations": "Informations"
        }
    }
</i18n>

<style lang="scss">
    $supplier-header-nav-color-active: #BED000;
    $supplier-header-nav-color: #8F94A0;
    $supplier-header-nav-border-color: #D9DFF0;

    .supplier-main-nav {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid $supplier-header-nav-border-color;

        .nav-buttons {
            flex: 1;
            margin-top: 20px;

            .list-items .item {
                color: $supplier-header-nav-color;

                &.active, &:hover{
                    color: $supplier-header-nav-color-active;
                    border-color: $supplier-header-nav-color-active;
                }
            }
        }

        .actions {
            width: 125px;

            .input {
                box-shadow: 0 3px 0 0 #E6E8F9;
                background: white;
                height: 32px;
            }

            .drop-down {
                right: 0;
            }
        }
    }
</style>
