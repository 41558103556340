import localeca920940 from '../../locales/fr-FR.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"silentFallbackWarn":true,"silentTranslationWarn":true,"fallbackLocale":"fr","numberFormats":{"fr":{"currency":{"style":"currency","currency":"EUR"}}}},
  vueI18nLoader: true,
  locales: [{"name":"Français","code":"fr","iso":"fr-FR","file":"fr-FR.js"},{"name":"English","code":"en","iso":"en-EN","file":"en-EN.js"}],
  defaultLocale: "fr",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/tmp/build_615449a0/locales",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"name":"Français","code":"fr","iso":"fr-FR","file":"fr-FR.js"},{"name":"English","code":"en","iso":"en-EN","file":"en-EN.js"}],
  localeCodes: ["fr","en"],
  additionalMessages: [],
}

export const localeMessages = {
  'fr-FR.js': () => Promise.resolve(localeca920940),
  'en-EN.js': () => import('../../locales/en-EN.js' /* webpackChunkName: "lang-en-EN.js" */),
}
