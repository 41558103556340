export default {
    async createFee({ dispatch }) {
        try {

            let feesData = await dispatch('formatFeesRequest')

            return await dispatch('tyltoApi/post', [feesData, { url: 'fees' }], { root: true })

        } catch(error) {

            throw error
        }
    },

    async createPropertyManagerFee({ dispatch, rootGetters, getters }, propertyManagerId) {
        try {
            
            let feesData = await dispatch('formatFeesRequest')

            let id = typeof propertyManagerId === 'number' ? propertyManagerId : rootGetters['property-manager/getId']

            return await dispatch('tyltoApi/post', [feesData, { url: 'property-management-companies/' + id + '/fees' }], { root: true })

        } catch(error) {

            throw error
        }
    },

    async createPropertyFee({ dispatch, rootGetters, getters }, propertyId) {
        try {
            
            let feesData = await dispatch('formatFeesRequest')

            let id = typeof propertyId === 'number' ? propertyId : rootGetters['property/getId']

            return await dispatch('tyltoApi/post', [feesData, { url: 'properties/' + id + '/fees' }], { root: true })

        } catch(error) {

            throw error
        }
    },

    async createLandlordFee({ dispatch, rootGetters, getters }, landlordId) {
        try {
            
            let feesData = await dispatch('formatFeesRequest')

            let id = typeof landlordId === 'number' ? landlordId : rootGetters['profile/getId']

            return await dispatch('tyltoApi/post', [feesData, { url: 'landlords/' + id + '/fees' }], { root: true })

        } catch(error) {

            throw error
        }
    },

    async createRentalFee({ dispatch, rootGetters, getters }, rentalId) {
        try {
            
            let feesData = await dispatch('formatFeesRequest')

            let id = typeof rentalId === 'number' ? rentalId : rootGetters['rental/getId']

            return await dispatch('tyltoApi/post', [feesData, { url: 'rentals/' + id + '/fees' }], { root: true })

        } catch(error) {

            throw error
        }
    },

    async fetchFee({ dispatch, rootGetters }, feeId) {
        try {

            let fee = rootGetters['tyltoApi/get'](`fees/${feeId}`)

            if (typeof fee === 'undefined' || !Object.keys(fee).length) {

                fee = await dispatch('tyltoApi/get', 'fees/' + feeId, { root: true })
            }

            dispatch('fillFee', fee)

            return fee

        } catch(error) {

            throw error
        }
    },

    async updateFee({ dispatch }) {
        try {
            
            let fees = await dispatch('formatFeesRequest')

            return await dispatch('tyltoApi/patch', fees, { root: true })
        
        } catch(error) {

            throw error
        }
    },

    async deleteFee({ dispatch, getters }, id) {
        try {

            let feesId = typeof id !== 'undefined' ? id : getters['getId']
            
            return await dispatch('tyltoApi/delete', 'fees/' + feesId, { root: true })

        } catch(error) {

            throw error
        }
    },

    fillFee({ commit }, fees) {
        commit('resetDefaultState')

        if (typeof fees['amount_in_percent'] !== 'undefined')
            commit('setAmountInPercent', fees['amount_in_percent'])

        if (typeof fees['amount_in_currency'] !== 'undefined')
            commit('setAmountInCurrency', fees['amount_in_currency'])

        if (typeof fees['type'] !== 'undefined')
            commit('setType', fees['type'])

        if (typeof fees['applies_to'] !== 'undefined')
            commit('setAppliesTo', fees['applies_to'])

        if (typeof fees['_jv'] !== 'undefined')
            commit('setId', fees['_jv']['id'])

        if (typeof fees['level'] !== 'undefined' && Object.keys(fees['level']).length)
            commit('setRelatedLevel', fees['level'])
    },

    async formatFeesRequest({ getters }) {
        let fees = {
            type: getters['getType'],
            _jv: {
                type: 'fees'
            }
        }
        
        if (getters['getAmountInPercent'] > 0) {
            
            fees['amount_in_percent'] = getters['getAmountInPercent']

            if (getters['getAppliesTo'].filter(fee => fee !== '').length) {
                fees['applies_to'] = getters['getAppliesTo']
            }
        }
        
        else fees['amount_in_currency'] = getters['getAmountInCurrency']

        if (getters['getId'] !== '') {
            fees['_jv'].id = getters['getId']
        }

        if (Object.keys(getters['getRelatedLevel']).length) {
            fees['_jv'].relationships = {
                level: {
                    type: getters['getRelatedLevel']['_jv'].type,
                    id: getters['getRelatedLevel']['_jv'].id
                }
            }
        }

        return fees
    }
}
