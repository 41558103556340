import { queryBuilder, queryResponse } from '~/utils/query'

export const strict = true

export const actions = {
    async fetchActivities({ dispatch }, params) {
        try {
            
            const response = await dispatch('tyltoApi/get', ['notifications', { params: queryBuilder(params) }], { root: true })

            return queryResponse(response)

        } catch(error) {

            throw error
        }
    }
}