<template>
    
    <div class="accounting-account-table-cell">

        <spinner-loader v-if="loading" />

        <template v-else>{{ $t('account_number', { number: accountingAccount.number }) }}</template>

    </div>

</template>

<script>
    import SpinnerLoader from '~/components/spinner-loader'

    export default {
        components: {
            SpinnerLoader
        },

        props: {
            accountingAccountId: {
                type: [Number, String],
                required: true
            }
        },

        data() {
            return {
                loading: true
            }
        },

        async created() {
            try {
                
                this.loading = true

                this.accountingAccount = await this.$store.dispatch('accounting-account/fetchAccountingAccount', this.accountingAccountId)
            
            } catch(error) {

                throw error

            } finally {

                this.loading = false
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "account_number": "#{number}"
        }
    }
</i18n>
