<template>
    
    <nav class="main-nav">

        <ul class="list-items">
            
            <nuxt-link :class="['item', { 'active': $route.path === item.path }, { 'disabled': item.disabled }]" no-prefetch

                v-for="(item, key) in items" :key="key"

                :to="item.path" tag="li"

                @click="activeItem = key">

                {{ item.name }}

                </nuxt-link>

        </ul>
        
    </nav>

</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        props: {
            items: {
                type: Array,
                required: true
            },
            styleMode: {
                type: String,
                default: 'classic'
            }
        },

        data() {
            return {
                activeItem: 0
            }
        },

        computed: {
            ...mapGetters({
                relatedBankAccounts: 'property-manager/getRelatedBankAccounts'
            })
        },
    }
</script>

<style lang="scss">
    $color-active: #BED000;

    .main-nav {
        .list-items {
            display: flex;
            flex-direction: row;

            .item {
                padding: 0 35px;
                padding-bottom: 20px;
                border-bottom: 4px solid transparent;
                transition: all .4s ease-in-out;
                white-space: nowrap;
                cursor: pointer;
                color: #767676;
                font-weight: 600;

                &.active,
                &:hover {
                    color: $color-active;
                    border-color: $color-active;
                    opacity: 1;
                }

                &.disabled {
                    opacity: .5;
                    pointer-events: none;
                }
            }
        }

        .main-nav::-webkit-scrollbar{
            display: none;
        }
    }
</style>
