<template>

    <modal class="share-to-sign-modal" :title="$t('share-to-sign')" :options="modalOptions" @submitted="hide" @canceled="hide" @close="hide" ref="modal">

        <spinner-loader v-if="loading" />

        <template v-else>

            <form-info :text="$t('verification_of_signers')" />

            <div class="signers" v-for="(sign, index) in profileSigners"  :key="index">

                <readonly-input class="readonly" name="test" :label="$t('full_name')" :value="sign.name" />

                <readonly-input class="readonly" name="test" :label="$t('email_adress')" :value="sign.email_address" />

            </div>

        </template>

    </modal>

</template>

<script>
    import Modal from '~/components/modal'
    import FullNameMixin from '~/components/mixins/full-name'
    import SpinnerLoader from '~/components/spinner-loader'
    import FormInfo from '~/components/form-info'
    import { mapGetters } from 'vuex'

    export default {
        mixins: [FullNameMixin],

        components: {
            Modal,
            SpinnerLoader,
            FormInfo
        },

        data() {
            return {
                loading: false,
                profileSigners: [],
                modalOptions: {
                    submit: {
                        disableButton: this.checkSignersEmails,
                        action: () => this.createSignatureRequest()
                    }
                }
            }
        },

        computed: {
            checkSignersEmails() {
                return this.profileSigners.every(signer => signer.email_address)
            },

            ...mapGetters({
                signers: 'file/getSignatureRequestSigners',
                propertyManagerEmail: 'property-manager/getEmailAddress',
                businessName: 'property-manager/getBusinessName',
                getAuthUser: 'getAuthUser'
            })
        },

        methods: {
            async show() {
                try {
                    this.loading = true

                    this.$refs.modal.show()

                    let fetchProfile = async (profile) => {

                        if (profile.type === 'property-management-companies') {

                            let obj = {name: this.businessName, email_address: this.propertyManagerEmail}

                            this.profileSigners.push(obj)
                        }

                        if (profile.type === 'landlords') {

                            let landlord = await this.$store.dispatch('profile/fetchLandlord', profile.id)

                            let obj = {name: this.getProfileFullName(landlord), email_address: landlord.email_address}

                            this.profileSigners.push(obj)
                        }

                        if (profile.type === 'tenants') {

                            let tenant = await this.$store.dispatch('profile/fetchTenant', profile.id)

                            if (tenant.entity === 'couple' && tenant.legal_persons.every(legalPerson => legalPerson.email_address)) {
                                tenant.legal_persons.forEach(legalPerson => {
                                    let obj = {
                                        name: legalPerson.full_name,
                                        email_address: legalPerson.email_address
                                    }
                                    this.profileSigners.push(obj)
                                });
                            } else {
                                let obj = {
                                    name: this.getProfileFullName(tenant),
                                    email_address: tenant.email_address
                                }

                                this.profileSigners.push(obj)
                            }
                        }
                    }

                    this.$store.commit('onboarding/setOnboardingSteps', { step: 10, userId: this.getAuthUser['_jv'].id })

                    await Promise.all(this.signers['data'].map(profile => fetchProfile(profile)))

                } catch(error) {

                    throw error

                } finally {

                    this.loading = false
                }
            },

            hide() {
                this.$refs.modal.hide()

                this.profileSigners = []
            },

            async createSignatureRequest() {
                try {

                    return await this.$store.dispatch('file/createSignatureRequest')

                } catch(error) {

                    throw error

                } finally {

                    this.$store.commit('onboarding/setOnboardingSteps', { step: 99, userId: this.getAuthUser['_jv'].id })

                    this.$emit('submitted')
                }
            },
        }
    }
</script>

<i18n>
    {
        "fr": {
            "share-to-sign": "Vérification des adresses e-mails",
            "full_name": "Nom complet",
            "email_adress": "Adresse e-mail",
            "verification_of_signers": "Veuillez vérifier les addresses e-mails avant de confirmer la demande de signature"
        }
    }
</i18n>

<style lang="scss">
    .share-to-sign-modal {
        .input-form ~ .input-form {
            margin-top: 20px;
        }
        .modal__button-group {
            margin-bottom: 20px;
        }
    }
</style>

<style lang="scss" scoped>
    .signers {
        display: flex;
        flex-direction: row;

        .readonly {
            width: 50%;
        }
    }

    .signers:not(:last-of-type) {
        margin-bottom: 12px;
    }
</style>
