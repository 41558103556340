import Vue from 'vue'
import ButtonTableCell from '~/components/table-list/table-cell/button'
import StatusTag from '~/components/status-tag'
import TenantList from '~/components/table-list/table-cell/tenant-list'
import Landlord from '~/components/table-list/table-cell/landlord'
import PaymentDetails from '~/components/table-list/table-cell/payment-details'
import Supplier from '~/components/table-list/table-cell/supplier'
import Document from '~/components/table-list/table-cell/document'
import DocumentItem from '~/components/document-item'
import Contract from '~/components/table-list/table-cell/contract'
import StatusLabel from '~/components/table-list/table-cell/status-label'
import BankNumber from '~/components/table-list/table-cell/bank-number'
import MultiLines from '~/components/table-list/table-cell/multi-lines'
import PropertyAddress from '~/components/table-list/table-cell/property-address'
import Property from '~/components/table-list/table-cell/property'
import BankName from '~/components/table-list/table-cell/bank-name'
import BankAccountTransaction from '~/components/table-list/table-cell/bank-account-transaction'
import BankAccountTransactionReconciliation from '~/components/table-list/table-cell/bank-account-transaction-reconciliation'
import AccountingEntries from '~/components/table-list/table-cell/accounting-entries'
import AccountingJournal from '~/components/table-list/table-cell/accounting-journal'
import AccountingAccount from '~/components/table-list/table-cell/accounting-account'
import Progression from '~/components/table-list/table-cell/progression'
import Signature from '~/components/table-list/table-cell/signature'
import Duration from '~/components/table-list/table-cell/duration'
import Inventory from '~/components/table-list/table-cell/inventory'
import EnergyDiagnoses from '~/components/table-list/table-cell/energy-diagnoses'
import DownloadViewer from '~/components/table-list/table-cell/download-viewer'
import ClipboardText from '~/components/table-list/table-cell/clipboard-text'
import ValidationStatus from '~/components/table-list/table-cell/validation-status'

import TableCell from '~/components/table-list/table-cell'
import TableBody from '~/components/table-list/table-body'
import TableListGroup from '~/components/table-list/table-list-group'
import TableList from '~/components/table-list'

const components = {
    StatusTag,
    TenantList,
    Landlord,
    PaymentDetails,
    Supplier,
    Document,
    DocumentItem,
    DownloadViewer,
    StatusLabel,
    BankNumber,
    ButtonTableCell,
    MultiLines,
    Contract,
    PropertyAddress,
    Duration,
    Property,
    BankName,
    Signature,
    BankAccountTransaction,
    BankAccountTransactionReconciliation,
    EnergyDiagnoses,
    ClipboardText,
    ValidationStatus,
    AccountingEntries,
    AccountingJournal,
    AccountingAccount,
    Progression,
    Inventory,
    TableCell,
    TableBody,
    TableList,
    TableListGroup,
}

Object.entries(components).forEach(([name, component]) => {

    Vue.component(name, component)
})
