<template>
    
    <div class="accounting-jounal-table-cell">

        <template v-if="Object.keys(accountingJournal).length">{{ accountingJournal['two_letters_code'] }}</template>

        <template v-else>-</template>

    </div>

</template>

<script>
    export default {
        async fetch() {

            const { store } = this.$nuxt.context
        
            this.accountingJournal = await store.dispatch('accounting-journal/fetchAccountingJournal', this.accountingJournalId)
        },

        props: {
            accountingJournalId: {
                type: [String, Number],
                required: true
            }
        },

        data() {
            return {
                accountingJournal: {}
            }
        }
    }
</script>