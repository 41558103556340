<template>

    <div :class="['status-label', type]" :style="{ '--font-weight': fontWeight, '--font-size': fontSize }" v-html="label"></div>

</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                required: true
            },
            type: {
                type: String,
                required: true
            },
            fontWeight: {
                type: String,
                default: "500" 
            },
            fontSize: {
                type: String,
                default: "14px" 
            }
        }
    }
</script>

<style lang="scss">
    $green: #18C95D;
    $red: #FB5D5D;
    $orange: #F5A623;
    $black: #333333;

    .status-label {
        @include body;
        font-size: var(--font-size, 14px);
        white-space: nowrap;
        font-weight: var(--font-weight, 400);
        text-overflow: ellipsis;
        overflow: hidden;

        &.success {
            color: $green;
        }

        &.warning {
            color: $orange;
        }

        &.error {
            color: $red;
        }

        &.neutral {
            color: $black;
        }
    }
</style>
