import { queryResponse, queryBuilder } from '~/utils/query'

export const strict = true

export const defaultState = () => {
    return {
        period_start_date: '',
        period_end_date: '',
        relationships: {
            document: {}
        }
    }
}

export const state = defaultState

export const mutations = {
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },

    setPeriodStartDate(state, value) {
        state.period_start_date = value
    },

    setPeriodEndDate(state, value) {
        state.period_end_date = value
    },

    setRelatedDocument(state, value) {
        state.relationships.document = value
    }
}

export const getters = {
    getPeriodStartDate(state) {
        return state.period_start_date
    },

    getPeriodEndDate(state) {
        return state.period_end_date
    },

    getRelatedDocument(state) {
        return state.relationships.document
    }
}

export const actions = {
    async createManagementReport({ dispatch }, landlordId) {
        try {
            
            let request = await dispatch('formatManagementReportRequest')

            const managementReport = await dispatch('tyltoApi/post', [request, { url: '/landlords/' + landlordId + '/management-reports' }], { root: true })

            await dispatch('fillManagementReport', managementReport)

            return managementReport

        } catch(error) {

            throw error
        }
    },

    async fetchManagementReports({ dispatch, rootGetters }, params) {
        try {
            
            const response = await dispatch('tyltoApi/get', ['landlords/' + rootGetters['profile/getId'] + '/management-reports', { params: queryBuilder(params) }], { root: true })

            return queryResponse(response)
        
        } catch(error) {
            
            throw error
        }
    },

    async fetchManagementReport({ dispatch, rootGetters }, managementReportId) {
        try {

            let managementReport = rootGetters['tyltoApi/get'](`management-reports/${managementReportId}`)

            if (typeof managementReport === 'undefined' || !Object.keys(managementReport).length) {
                
                managementReport = await dispatch('tyltoApi/get', 'management-reports/' + managementReportId, { root: true })
            }

            await dispatch('fillManagementReport', managementReport)
            
            return managementReport
        
        } catch(error) {

            throw error
        }
    },

    async formatManagementReportRequest({ getters }) {
        let managementReportRequest = {
            period_start_date: getters['getPeriodStartDate'],
            period_end_date: getters['getPeriodEndDate'],
            _jv: {
                type: 'management-reports'
            }
        }

        return managementReportRequest
    },

    async fillManagementReport({ commit, dispatch }, managementReport) {
        commit('resetDefaultState')
        
        if (typeof managementReport['period_start_date'] !== 'undefined')
            commit('setPeriodStartDate', managementReport['period_start_date'])

        if (typeof managementReport['period_end_date'] !== 'undefined')
            commit('setPeriodEndDate', managementReport['period_end_date'])
        
        if (typeof managementReport['_jv']['relationships']['document']['data'].id !== 'undefined') {
            const document = await dispatch('file/fetchFile', managementReport['_jv']['relationships']['document']['data'].id, { root: true })
            
            commit('setRelatedDocument', document)
        }
            

    }        

}
