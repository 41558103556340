<template>

    <form-group class="phone-number-input" :label="label" :name="name" :rules="rules" v-slot="{ errors, setErrors, reset }" ref="formGroup">

        <input :value="value" v-show="false">

        <vue-tel-input :class="['vue-tel-input', { 'errors': errors.length }]" :defaultCountry="'FR'" :inputOptions="inputOptions" v-model="phoneNumber" @input="updatePhoneNumber" @validate="validPhoneNumber($event, setErrors, reset)" />

    </form-group>

</template>

<script>
    import FormGroup from '~/components/inputs/form-group'
    import { VueTelInput } from 'vue-tel-input'
    import 'vue-tel-input/dist/vue-tel-input.css'

    export default {
        props: {
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            value: {
                type: String,
                required: true
            },
            rules: {
                type: [String, Object],
                default: ''
            }
        },

        data() {
            return {
                phoneNumber: this.value,
                result: {}
            }
        },

        components: {
            VueTelInput,
            FormGroup
        },

        methods: {
            updatePhoneNumber(number, phoneObject) {
                if (phoneObject.number !== this.value && typeof phoneObject.number !== 'undefined' && phoneObject.valid === true) {
                    
                    this.result = phoneObject
                    
                    this.$emit('input', phoneObject.number)
                }

                else if (this.phoneNumber === '') {
                    
                    this.$emit('input', '')
                }

                else if (this.result.valid && !phoneObject.valid) {
                                        
                    this.$emit('input', '')
                }
            },

            validPhoneNumber(phoneObject, setErrors, reset) {
                if(Object.keys(phoneObject).length) {

                    if (phoneObject.object !== '' && !phoneObject.valid) {

                        setErrors([this.$t('phone_number_invalid', { label: this.label.toLowerCase() })])

                    } else {
            
                        reset()
                    }
                }
            }
        },

        computed: {
            inputOptions() {
                return {
                    styleClasses: 'input-tel',
                    placeholder: this.$t('phone_placeholder')
                }
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "phone_placeholder": "Ex : 06 13 46 79 79",
            "phone_number_invalid": "Le champs {label} est invalide"
        }
    }
</i18n>

<style lang="scss">
    $input-height: 50px;
    $border-color: #DEE3EF;
    $separator-color: #F2F2F2;

    .phone-number-input {
        .vue-tel-input {
            border: none !important;
            box-shadow: none !important;

            .input-tel,
            .vti__dropdown {
                height: $input-height;
                border: 1px solid $border-color;
                border-radius: 4px;
                padding: 0 20px;
                font-size: 11pt;
                box-sizing: border-box;
                flex: 1;

                &::placeholder {
                    opacity: 0.5;
                }
            }

            .vti__dropdown {
                flex: 0 0 96px;
                margin-right: 10px;
                box-sizing: border-box;
                position: relative;

                .vti__dropdown-list {
                    padding: 10px;
                    border: 2px solid $border-color;
                    border-radius: 10px;
                    box-shadow: 0 5px 20px 0 rgba(4,16,41,0.02);
                    position: absolute;
                    bottom: -5px;
                    top: initial;
                    left: 0;
                    transform: translateY(100%);

                    .vti__dropdown-item {
                        padding: 10px 10px;
                        
                        & ~ .vti__dropdown-item {
                            border-top: 1px solid $separator-color;
                        }

                        &:hover {
                            background: #EFEFEF;
                            border-radius: 10px;
                        }
                    }
                }
            }

            &.errors {
                .input-tel,
                .vti__dropdown {
                    background-color: #FBE8E8;
                    border-color: #F5A623;
                }
            }
        }
    }
</style>
