<template>

    <form-group class="date-input" v-click-outside="closeDatePicker" :label="label" :name="name" :rules="computedRules" v-slot="{ errors }">

        <input class="hidden" type="text" :name="name" v-model="value" ref="hiddenInput" v-show="false" />

        <text-input
            class="mask-date-input"
            :mask="dateMask"
            name="mask-date-input"
            :value="dateValue"
            @input="emitInput($event, rules, name)"
            :placeholder="datePlaceholder"
            @focus="openDatePicker"
            iconImg="calendar"
            :iconColor="'#525254'"
            :iconReverse="true"
        />

        <FunctionalCalendar
            class="functionnal-calendar"
            ref="calendar"
            :disabled-dates="disabledDates"
            :markedDates="markedDates"
            :change-month-function="true"
            :change-year-function="true"
            :is-date-picker="true"
            :date-format="'dd/mm/yyyy'"
            :limits="{min: minDate.split('-').reverse().join('/'),
            max: maxDate.split('-').reverse().join('/')}"
            v-model="calendarData"
            @input="setDateValueWithCalendarPicker($event, rules, name)"
            v-show="datePickerOpen && !('disableDatePicker' in options && options.disableDatePicker)"
        />

    </form-group>

</template>

<script>
    import '~/assets/scss/functional-calendar.scss'
    import FormGroup from '~/components/inputs/form-group'

    export default {
        components: {
            FormGroup
        },

        data() {
            return {
                datePickerOpen: false,
                markedDates: [],
                calendarData: {}
            }
        },

        props: {
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            value: {
                type: String,
                required: true
            },
            rules: {
                type: String,
                default: ''
            },
            options: {
                type: Object,
                default() {
                    return {}
                }
            }
        },

        methods: {
            openDatePicker(event) {

                this.$emit('focus', event)

                this.datePickerOpen = !this.datePickerOpen
            },

            closeDatePicker() {

                this.datePickerOpen = false
            },

            setDateValueWithCalendarPicker(calendarData, rules, name) {

                if (!Object.keys(calendarData).length) this.emitInput('',  rules, name)

                this.emitInput(this.$moment(calendarData.selectedDate, this.datePlaceholder).format(this.datePlaceholder),  rules, name)
            },

            emitInput(date, rules, name) {

                this.$refs.hiddenInput.dispatchEvent(new Event('input'))

                if (date === '') return this.$emit('input', '')

                if (this.$moment(date, this.datePlaceholder, true).isValid()) {

                    this.$emit('input', this.$moment(date, this.datePlaceholder).format(this.dateFormat), rules, name)
                }
            }
        },

        computed: {
            maxDate() {
                if (this.rules.split('date_before:').length > 1) return this.rules.split('date_before:')[1]

                return ''
            },

            minDate() {
                if (this.rules.split('date_after:').length > 1) this.rules.split('date_after:')[1]

                return ''
            },

            dateValue() {
                if (this.value === '') return ''

                return this.$moment(this.value, this.dateFormat).format(this.datePlaceholder)
            },

            dateFormat() {
                return typeof this.options.format !== 'undefined' ? this.options.format : 'YYYY-MM-DD'
            },


            disabledDates() {
                return typeof this.options.disabledDates !== 'undefined' ? this.options.disabledDates : []
            },

            datePlaceholder() {
                let str = this.dateFormat

                let regEx = new RegExp('(-{2})([DMY-]+)')

                str = str.replace(regEx, '$2')

                // str = str.split('Y').join('A')

                // str = str.split('M').join('M')

                // str = str.split('D').join('J')

                // str = str.toLowerCase()

                str = str.split('-')

                str = str.reverse()

                str = str.join('/')

                return str
            },

            dateMask() {
                let regEx = new RegExp('([DMYjma])', 'g')

                return this.datePlaceholder.replace(regEx, '#').replace(/\//g, " / ")
            },

            computedRules() {

                let str = `date_is_valid:${this.dateFormat}`

                if (this.rules.length) {

                    str += `|${this.rules}`
                }

                return str
            }
        },

        watch: {
            value: {
                immediate: true,
                handler() {
                    this.closeDatePicker()
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .date-input {
        position: relative;

        .mask-date-input {
            align-items: initial;
            margin-bottom: 0;

            &::v-deep .icon{
                cursor: pointer;
            }
        }

        .functionnal-calendar {
            z-index: 9;
            position: absolute;
            top: calc(100% + 5px);
            left: 0px;
            padding-bottom: 15px;
        }
    }
</style>
