<template>

    <div :class="['payment-method-banner', {'error': backgroundOrange }]">

        <p class="details" v-if="stripeSubscription.status !== 'trialing'"> {{ $t(`status_${stripeSubscription.status}`) }} </p>

        <p class="details" v-if="stripeSubscription.status === 'trialing'"> {{ trialPeriodMessage }} </p>

        <p class="add-payment-method" @click="addPaymentMethodRedirection" v-if="stripeSubscription.status !== 'incomplete_expired' && stripeSubscription.status !== 'past_due'"> {{ $t('add_payment_method') }} </p>

        <p class="add-payment-method" @click="showModal = !showModal" v-if="stripeSubscription.status === 'incomplete_expired'"> {{ $t('contact_social_service') }} </p>

        <trial-period-modal ref="trialPeriod" v-if="showModal" @hide="showModal = false"/>

    </div>

</template>

<script>
    import { mapGetters } from 'vuex'
    import TrialPeriodModal from '~/components/modal/trial-period-modal'

    export default {
        components: {
            TrialPeriodModal
        },

        data() {
            return {
                showModal: false
            }
        },

        methods: {
            addPaymentMethodRedirection() {
                this.$router.push({
                    path: '/settings/company-details',
                    query: {
                        step: 'payment_method'
                    }
                })
            }
        },

        computed: {
            ...mapGetters({
                stripeSubscription: 'property-manager/getStripeSubscription',
            }),

            trialPeriodMessage() {
                let today = this.$moment(new Date()).format('YYYY/MM/DD')

                let daysLeft = this.$moment(this.stripeSubscription.trial_end).diff(today, 'days')

                if (daysLeft > 0) {

                    return this.$t('trial_end', { days: daysLeft })

                } else {

                    let daysExceeds = this.$moment(this.stripeSubscription.trial_end).fromNow();

                    return this.$t('trial_end_exceeds', { time: daysExceeds })
                }
            },

            backgroundOrange() {
                return this.stripeSubscription.status === 'incomplete_expired' || this.stripeSubscription.status === 'canceled' || this.stripeSubscription.status === 'unpaid' ? true : false 
            }
        }
    }

</script>

<i18n>
    {
        "fr": {
            "add_payment_method": "Ajouter un moyen de paiement",
            "trial_end": "Votre période d’essai expire dans {days} jours.",
            "trial_end_exceeds": "Votre période d’essai a expriré {time}",
            "status_incomplete": "La tentative du premier paiement de votre abonnement a échouée, cliquez-ici pour mettre à jour le moyen de paiement.",
            "status_incomplete_expired": "Le premier paiement de votre abonnement a échoué, cliquez ici pour contacter le service commercial.",
            "status_past_due": "Le paiement de votre dernière charge a échoué. Une nouvelle tentative de paiement va s’exécuter automatiquement.",
            "status_canceled": "Le paiement de votre dernière charge a échoué, cliquez-ici pour mettre à jour le moyen de paiement.",
            "status_unpaid": "Le paiement de votre dernière charge a échoué, cliquez-ici pour mettre à jour le moyen de paiement.",
            "contact_social_service": "Contactez le service commercial"
        }
    }
</i18n>

<style lang="scss">
    .payment-method-banner {
        width: 100%;
        height: 42px;
        background: #D8DEEB;
        display: flex;
        align-items: center;
        justify-content: center;

        .details {
            font-size: 14px;
        }

        .add-payment-method {
            margin: 0px 8px 0px 8px;
            font-size: 14px;
            font-weight: 400;
            color: #BED000;
            text-decoration: underline;
            cursor: pointer;
        }

        &.error {
            background: #FFAE02;
        }
    }
</style>
