export const strict = true

export const defaultState = () => {
    return {
        birth_date: '',
        first_name: '',
        is_representative: false,
        phone_number: '',
        last_name: '',
        email_address: '',
        address: {},
        verification: {
            personal_id_document_front: '',
            personal_id_document_back: '',
            additionnal_document: ''
        }
    }
}

export const state = defaultState

export const mutations = {
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },

    setBirthDate(state, value) {
        state.birth_date = value
    },
    
    setFirstName(state, value) {
        state.first_name = value
    },
    
    setIsRepresentative(state, value) {
        state.is_representative = value
    },
    
    setPhoneNumber(state, value) {
        state.phone_number = value
    },
    
    setLastName(state, value) {
        state.last_name = value
    },
    
    setEmailAddress(state, value) {
        state.email_address = value
    },
    
    setAddress(state, value) {
        state.address = value
    },
    
    setVerificationPersonalIdDocumentFront(state, value) {
        state.verification.personal_id_document_front = value
    },
    
    setVerificationPersonalIdDocumentBack(state, value) {
        state.verification.personal_id_document_back = value
    },
    
    setVerificationAdditionnalDocument(state, value) {
        state.verification.additionnal_document = value
    }
}

export const getters = {
    getBirthDate(state) {
        return state.birth_date
    },
    
    getFirstName(state) {
        return state.first_name
    },
    
    getIsRepresentative(state) {
        return state.is_representative
    },
    
    getPhoneNumber(state) {
        return state.phone_number
    },
    
    getLastName(state) {
        return state.last_name
    },
    
    getEmailAddress(state) {
        return state.email_address
    },
    
    getAddress(state) {
        return state.address
    },
    
    getVerificationPersonalIdDocumentFront(state) {
        return state.verification.personal_id_document_front
    },
    
    getVerificationPersonalIdDocumentBack(state) {
        return state.verification.personal_id_document_back
    },
    
    getVerificationAdditionnalDocument(state) {
        return state.verification.additionnal_document
    }
}

export const actions = {
    async createManagerAndOwnership({ dispatch, getters, commit }) {
        try {

            let managerAndOwner = {
                first_name: getters['getFirstName'],
                last_name: getters['getLastName'],
            }

            if (Object.keys(getters['getAddress']).length) {
                managerAndOwner['address'] = getters['getAddress']
            }

            commit('resetDefaultState')

            return managerAndOwner

        } catch (error) {

            throw error
        }
    }
}