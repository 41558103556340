import defaultState from './default'

export default {
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },

    resetDefaultFieldsState(state) {
        let initialState = defaultState()

        Object.assign(state, initialState.document_fields)
    },

    setDocumentFieldsDuties(state, value) {
        state.document_fields.duties = value
    },

    setDocumentFieldsOtherSpecificClauses(state, value) {
        state.document_fields.other_specific_clauses = value
    },

    setEndDate(state, value) {
        state.end_date = value
    },

    setId(state, value) {
        state.id = value
    },

    setNumber(state, value) {
        state.number = value
    },

    setStartDate(state, value) {
        state.start_date = value
    },

    setTerm(state, value) {
        state.term = value
    },

    setRelatedDocument(state, value) {
        state.relationships.document = value
    },

    setRelatedProperty(state, value) {
        state.relationships.property = value
    },
    
    setRelatedAnnexes(state, value) {
        state.relationships.annexes = value
    }
}
