import { queryBuilder, queryResponse } from '~/utils/query'

export const strict = false

export const defaultState = () => {
    return {
        amount: 0,
        created_at: '',
        updated_at: '',
        id: ''
    }
}

export const state = defaultState

export const mutations = {
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },

    setAmount(state, value){
        state.amount = value
    },

    setCreatedAt(state, value){
        state.created_at = value
    },

    setUpdatedAt(state, value){
        state.updated_at = value
    },

    setId(state, value) {
        state.id = value
    }
}

export const getters = {
    getAmount(state){
        return state.amount 
    },

    getCreatedAt(state){
        return state.created_at 
    },

    getUpdatedAt(state){
        return state.updated_at 
    },

    getId(state){
        return state.id 
    }
}

export const actions = {
    async fetchPropertyManagerTreasuryStatements({ commit, rootGetters, dispatch }, params) {
        try {
            
            const queryParams = queryBuilder(params)

            const response = await dispatch('tyltoApi/get', [`property-management-companies/${rootGetters['property-manager/getId']}/treasury-statements`, { params: queryParams }], { root: true })

            return queryResponse(response)
        
        } catch(error) {

            throw error
        }
    },

    async filltreasuryStatement({ commit }, treasuryStatement) {
        commit('resetDefaultState')

        if (typeof treasuryStatement.amount !== 'undefined') 
            commit('setAmount', treasuryStatement.amount)

        if (typeof treasuryStatement.created_at !== 'undefined')
            commit('setCreatedAt', treasuryStatement.created_at)

        if (typeof treasuryStatement.updated_at !== 'undefined')
            commit('setUpdatedAt', treasuryStatement.updated_at)
        
        if (typeof treasuryStatement.id !== 'undefined')
            commit('setId', treasuryStatement.id)
    }
}