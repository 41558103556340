<template>

    <div class="viewer-layout">

        <viewer-navigation-bar @update="$router.push(updatePath)" @redirect="redirect" />

        <error-banner :title="$t('error_message')" :details="$t('contact_support')" v-if="errorMessage" />

        <signers-email-address-missing-modal ref="signersEmailAdressMissing" />

        <section class="content">

            <template v-if="isSignable">

                <p class="reading-warning" v-html="$t('details.not_started')" v-if="signatureStatus === 'not_started'" />

                <template v-else-if="signatureStatus === 'ongoing'">

                    <p class="reading-warning" v-if="!signatureEmbeddedUrl">

                        <template v-if="completedSignaturesAndSigners.length">{{ $t('details.completed', { signers: completedSignaturesAndSigners.map(signatureAndSigner => `${getProfileFullName(signatureAndSigner.signer)} ${$t('recorded_at', { date: $moment(signatureAndSigner.signature.updated_at).fromNow() })}`).join(', ') }) }}</template>

                        <br/>

                        <template v-if="uncompletedSignatures.length">{{ $t('details.waiting_signatories', { other_signatories: uncompletedSignatures.map(signer => getProfileFullName(signer)).join(', ') }) }}</template>

                    </p>

                    <p class="reading-warning" v-html="$t('details.ongoing')" v-else />

                </template>

            </template>
        
            <hellosign-iframe :embeddedUrl="signatureEmbeddedUrl" v-if="signatureEmbeddedUrl" @success="afterSignature" @fail="closeWindow" />

            <document-viewer :fileUrl="fileUrl" :mimes="fileMimeType" v-else-if="fileUrl" />

        </section>

        <viewer-button-group class="embedded-button-group" @afterSignature="afterSignature" @submitted="refreshSignature">
            
            <nuxt ref="viewer"/>

        </viewer-button-group>

    </div>

</template>

<script>
    import DocumentViewer from '~/components/document-viewer'
    import ViewerNavigationBar from '~/components/navigation/header/viewer-navigation-bar-header'
    import ViewerButtonGroup from '~/components/navigation/footer/viewer-button-group-footer'
    import SignersEmailAddressMissingModal from '~/components/modal/signers-email-address-missing'
    import HellosignIframe from '~/components/hellosign-iframe'
    import ErrorBanner from '~/components/error-banner'
    import FullNameMixin from '~/components/mixins/full-name'
    import { mapGetters } from 'vuex'

    export default {
        mixins: [FullNameMixin],

        components: { 
            DocumentViewer,
            ViewerNavigationBar, 
            ViewerButtonGroup,
            SignersEmailAddressMissingModal,
            HellosignIframe,
            ErrorBanner
        },

        data() {
            return {
                errorMessage: '',
                signer: {},
                uncompletedSignatures: [],
                completedSignaturesAndSigners: [],
                signatureRequestSignatures: []
            }
        },

        async fetch() {

            const { store } = this.$nuxt.context

            this.signatureRequestSignatures = await store.dispatch('file/fetchSignatureRequestSignatures', { params: { fileId: this.fileId } })
            
            const uncompletedSignatures = this.signatureRequestSignatures.filter(signature => signature.status !== 'complete')

            const completedSignatures = this.signatureRequestSignatures.filter(signature => signature.status === 'complete')
            
            if (this.profilId){

                this.signer = Object.values(this.signatureRequestSignatures).filter(signature => signature['_jv']['relationships']['signer']['data'].type === this.profilRole)

            } else if (this.isManager) {

                this.signer = Object.values(this.signatureRequestSignatures).filter(signature => signature['_jv']['relationships']['signer']['data'].type === 'property-management-companies')

            }

            if (this.signer.length){
                
                if (this.signer[0]['status'] === 'ongoing' && this.signer[0]['status'] !== 'complete') {

                    await store.commit('file/setSignatureRequestEmbeddedUrl', this.signer[0]['embedded_signing_url'])
                } 
            }

            this.uncompletedSignatures = await Promise.all(uncompletedSignatures.map(async signature => {
                if (signature['_jv']['relationships']['signer']['data'].type === 'landlords'){

                   return store.dispatch('profile/fetchLandlord', signature['_jv']['relationships']['signer']['data'].id)

                } else if (signature['_jv']['relationships']['signer']['data'].type === 'tenants'){

                    return store.dispatch('profile/fetchTenant', signature['_jv']['relationships']['signer']['data'].id)

                } else if (signature['_jv']['relationships']['signer']['data'].type === 'property-management-companies'){

                    return store.dispatch('property-manager/fetchPropertyManagementCompany', signature['_jv']['relationships']['signer']['data'].id)

                }
            }))

            this.completedSignaturesAndSigners = await Promise.all(completedSignatures.map(async signature => {
                if (signature['_jv']['relationships']['signer']['data'].type === 'landlords'){

                    const signer = await store.dispatch('profile/fetchLandlord', signature['_jv']['relationships']['signer']['data'].id)

                   return {signer, signature}

                } else if (signature['_jv']['relationships']['signer']['data'].type === 'tenants'){

                    const signer = await store.dispatch('profile/fetchTenant', signature['_jv']['relationships']['signer']['data'].id)

                    return {signer, signature}

                } else if (signature['_jv']['relationships']['signer']['data'].type === 'property-management-companies'){

                    const signer = await store.dispatch('property-manager/fetchPropertyManagementCompany', signature['_jv']['relationships']['signer']['data'].id)

                    return {signer, signature}

                }
            }))
        },

        methods: {
            afterSignature() {
                
                this.$toast.success(this.$t('signature_accepted'), { icon: 'check' })
            },

            redirect() {

                return this.$router.push(this.redirectPath)
            },

            refreshSignature() {

                this.$fetch()
            },

            closeWindow() {
                if (this.profilRole || this.isManager) {

                    this.$router.push("/")

                } else {
                    
                    window.close()
                }
            }
        },

        computed: {
            ...mapGetters({
                profilId: 'profile/getId',
                profilRole: 'profile/getRole',
                fileId: 'file/getId',
                fileUrl: 'file/getUrl',
                fileMimeType: 'file/getMimeType',
                signatureEmbeddedUrl: 'file/getSignatureRequestEmbeddedUrl',
                signatureStatus: 'file/getSignatureRequestStatus',
                signatureRequestId: 'file/getSignatureRequestId',
                isSignable: 'file/getIsSignable',
                redirectPath: 'file/getRedirectPath',
                updatePath: 'file/getUpdatePath',
                isManager: 'isManager'
            }),
        },

        errorCaptured(error) {

            if (!!error.response && !!error.response.status && parseInt(error.response.status) >= 500) {
                
                this.errorMessage = error
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "url_missing": "La signature électronique n'a pas pu démarrer car un lien est manquant.",
            "signature_accepted": "La signature a bien été enregistrée.",
            "error_message": "Une erreur est survenue",
            "contact_support": "Contactez le support en envoyant un e-mail à support@oskar.immo si le problème persiste.",
            "details": {
                "not_started": "Veuillez lire attentivement le document puis démarrer la signature.",
                "ongoing": "Ajoutez votre signature à la fin du document.",
                "completed": "{signers}.",
                "waiting_signatories": "En attente de la signature de {other_signatories}."
            },
            "recorded_at": "a signé le document {date}"
        }
    }
</i18n>

<style lang="scss" scoped>
    $background-color: #393939;
    $button-group-margin: 60px;
    $button-group-height: 68px;
    $viewer-width: 983px;
    $viewer-margin-top: 44px;
    $header-height: 68px;

    .viewer-layout {
        background: $background-color;
        display: flex;  
        flex-direction: column;
        justify-content: center;
        min-height: 100vh;
        margin-bottom: $button-group-margin;
        
        .content {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding-top: $viewer-margin-top;

            .reading-warning {
                color: white;
                justify-content: center;
                text-align: center;
                display: flex;
                font-size: 18px;
                margin-bottom: 36px;
            }

            #hellosign-viewer {
                width: 983px;
                height: calc(100vh - #{$viewer-margin-top} - #{$header-height});
                margin: 0 auto;
                border-radius: 4px;
                overflow: hidden;
                flex-grow: 1;
            }
        }
    }
</style>
