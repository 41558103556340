export default function ({ store }) {
    store.commit('workflow/resetDefaultState')
    
    store.commit('file/resetDefaultState')    
    store.commit('property-manager/resetDefaultState')
    store.commit('rental/resetDefaultState')
    store.commit('mandate/resetDefaultState')
    store.commit('property/resetDefaultState')
    store.commit('inventory/resetDefaultState')
    store.commit('profile/resetDefaultState')
    store.commit('rent/resetDefaultState')
    store.commit('cost/resetDefaultState')
    store.commit('payment-method/resetDefaultState')
    store.commit('management-and-ownership/resetDefaultState')
    store.commit('invitation/resetDefaultState')
    store.commit('onboarding/resetDefaultState')
    store.commit('fees/resetDefaultState')
    // store.commit('payout/resetDefaultState')
    
    store.commit('accounting-account/resetDefaultState')
    store.commit('accounting-entry/resetDefaultState')
    store.commit('accounting-journal/resetDefaultState')
    store.commit('bank-account-transaction/resetDefaultState')
    store.commit('bank-account/resetDefaultState')

    store.commit('setDemoPeriodIsExpirate', false)
    store.commit('setBurgerMenuIsOpen', false)
}