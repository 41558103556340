<template>

    <sidebar :options="sidebarOptions" ref="sidebar" class="landlord-sidebar">

        <div class="section-header contact">

            <svg-icon :iconColor="'#333333'" width="16" height="16" viewBox="0 0 24 24"><phone-icon /></svg-icon>

            <h3 class="title">{{ $t('contact') }}</h3>

        </div>

        <readonly-input name="email_address" class="email-address" :label="$t('email_address')" :value="emailAddress" />

        <readonly-input name="phone_number" class="phone-number" :label="$t('phone_number')" :value="phoneNumber" />

        <div v-for="(person, index) in legalPersons" :key="index">

            <hr class="separator-min">

            <readonly-input name="name" class="readonly-spacing" :label="person.type === 'natural_person' ? $t('name') :  $t('business_name')" :value="getLegalPersonFullName(person)" />

            <readonly-input name="address" class="readonly-spacing" :label="$t('address')" :value="person.address ? person.address.formatted : ''" />

            <readonly-input name="birth_date" class="readonly-spacing" :label="$t('birth_date')" :value="person.birth_date ? $moment(person.birth_date).format('Do MMMM YYYY') : ''" />

        </div>

        <hr class="separator">

        <div class="section-header bankaccount">

            <svg-icon :iconColor="'#333333'" width="16" height="16"><bankaccount-icon /></svg-icon>

            <h3 class="title">{{ $t('bank_account') }}</h3>

        </div>

        <div v-if="bankAccounts.length">

            <div class="bank-accounts" v-for="(account, index) in bankAccounts" :key="index">

                <readonly-input name="bank_account_holder_name" class="readonly-spacing" :label="$t('bank_account_holder_name')" :value="account.holder_name" />

                <readonly-input name="bank_account" class="readonly-spacing" :label="$t('bank')" :value="account.bank_name" />

                <readonly-input name="bank_account_iban" class="readonly-spacing" :hideSensitivePaymentInformations="true" :label="$t('bank_account_iban')" :value="account.iban ? account.iban : ''" />

                <hr class="separator-min">

            </div>

        </div>

        <p v-else> {{ $t('no_bank') }}</p>

    </sidebar>

</template>

<script>
    import Sidebar from '~/components/sidebar'
    import SvgIcon from '~/components/svg-icon'
    import { PhoneIcon, BankaccountIcon } from '~/components/icons/button-icons'
    import ButtonInput from '~/components/inputs/button-input'
    import FullNameMixin from '~/components/mixins/full-name'
    import { mapGetters } from 'vuex'

    export default {
        mixins: [FullNameMixin],

        components: {
            Sidebar,
            SvgIcon,
            PhoneIcon,
            BankaccountIcon,
            ButtonInput
        },

        data() {
            return {
                landlord: {},
                loading: false
            }
        },

        props: {
            landlordId: {
                type: [Number, String],
                required: true
            }
        },

        methods: {
            async fetchLandlord() {
                try {

                    this.landlord = await this.$store.dispatch('profile/fetchAndFillLandlord', this.landlordId)

                    await this.$store.dispatch('profile/fetchDefaultPayoutBankAccountRelationship')

                } catch(error) {

                    throw error
                }
            },

            resetLandlord() {
                this.$store.commit('profile/resetDefaultState')
            },

            redirectToLandlord() {
                this.$router.push(`/landlord/${this.landlordId}`)
            },

            show() {
                return this.$refs.sidebar.show()
            },

            hide() {
                return this.$refs.sidebar.hide()
            }
        },

        computed: {
            sidebarOptions() {
                return {
                    header: {
                        type: this.$t('landlord'),
                        title: this.getProfileFullName(this.landlord),
                        subTitle: this.landlord.created_at ? `Profil créé le ${this.$moment(this.landlord.created_at).format('Do MMMM YYYY')}` : ''
                    },
                    fetch: this.fetchLandlord,
                    close: this.resetLandlord,
                    submit: {
                        action: () => this.redirectToLandlord()
                    }
                }
            },

            ...mapGetters({
                emailAddress: 'profile/getEmailAddress',
                phoneNumber: 'profile/getPhoneNumber',
                legalPersons: 'profile/getLegalPersons',
                bankAccounts: 'profile/getRelatedPayoutBankAccounts'
            })
        }
    }
</script>

<i18n>
    {
        "fr": {
            "see_file": "Voir la fiche complète",
            "landlord": "Propriétaire",
            "email_address": "Adresse email",
            "name": "Nom complet",
            "business_name": "Dénomination sociale",
            "phone_number": "Téléphone",
            "address": "Adresse",
            "bank_account_holder_name": "Titulaire",
            "bank": "Banque",
            "bank_account_iban": "IBAN",
            "contact": "Contact",
            "bank_account": "Compte bancaire",
            "no_bank": "Aucun compte bancaire fourni",
            "birth_date": "Date de naissance"
        }
    }
</i18n>

<style lang="scss" scoped>
    .sidebar {
        .container  {
            .section-header {
                margin-bottom: 12px;
            }
            .separator {
                margin-bottom: 16px ;
            }
        }
    }

    .readonly-spacing {
        align-items: initial;
    }
    
    .bank-accounts:last-child {
        .separator-min {
            height: 0 !important;
        }
    }
</style>