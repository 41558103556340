<template>
    
    <footer class="button-group-footer">

        <div class="content">

            <div class="left-panel">
                
                <slot name="left-panel"></slot>

            </div>

            <div class="right-panel">

                <slot name="right-panel"></slot>

            </div>

        </div>

    </footer>

</template>

<script>
    export default {}
</script>

<style lang="scss" scoped>
    $button-group-height: 68px;
    
    .button-group-footer {
        z-index: 99;
        min-height: $button-group-height;
        z-index: 9;
        
        .content {
            box-shadow: 0 2px 12px 0 rgba(0,0,0,0.06), 0 1px 0 0 #F2F2F2 inset;
            background: white;
            padding: 0 15px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            min-height: $button-group-height;
            align-items: center;
            position: fixed;
            bottom: 0;
            right: 0;
            left: 0;
            
            .right-panel {
                display: flex;

                .button {
                    margin-left: 15px;
                }
            }
        }
    }
</style>