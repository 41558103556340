export default () => {
    return {
        loading: false,
        dump_name: '',
        mode: '',
        steps: [],
        fields: [],
        error: {
            message: '',
            status_code: '',
            fields: []
        },
        external_file: {
            is_active: false,
            is_signed: false,
            file: '',
            preview: false,
            file_name: '',
            formdata: '',
            types: []
        },
        lease_agreement_is_signed: false,
        redirect_action: () => {},
        save_action: () => {},
        status: '',
        title: ''
    }
}
