<template>
    
    <header class="viewer-navigation-bar">

        <ul class="list">
            
            <li class="nav-item principal">

                <div class="back" @click="$emit('update')" v-if="isUpdatePath && isSignable && !isFileSigned && isManager && signatureRequestStatus === 'not_started'">

                    <svg-icon class="icon" width="20" height="20" :iconColor="'#393939'" :viewbox="'0 0 21 20'"><back-navigation-icon /></svg-icon>

                    {{ $t('back') }}

                </div>

                <logo v-else />

            </li>

            <li class="nav-item title-group">

                <span class="title">{{ title }}</span>

                <div class="description" v-if="description">

                    <span>{{ description }}</span>

                </div>

            </li>

            <li class="nav-item validate-status" v-if="isSignable">
                
                <svg-icon class="icon" width="16" height="16" :iconStrokeColor="'#393939'" :viewbox="'0 0 19 17'"><signature-icon /></svg-icon>

                <span>{{ $t(signatureStatus) }}</span>

            </li>

            <li class="nav-item exit" @click="redirectPath ? $emit('redirect') : $router.back()"></li>

        </ul>

    </header>

</template>

<script>
    import Logo from '~/components/logo'
    import SvgIcon from '~/components/svg-icon'
    import BackNavigationIcon from '~/components/icons/navigation-icons/back-navigation-icon'
    import SignatureIcon from '~/components/icons/button-icons/signature-icon'
    import { mapGetters } from 'vuex'
    
    export default {
        components: { 
            Logo,
            SvgIcon,
            BackNavigationIcon,
            SignatureIcon
        },

        computed: {
            ...mapGetters({
                title: 'file/getTitle',
                description: 'file/getDescription',
                signatureStatus: 'file/getSignatureRequestStatus',
                isFileSigned: 'file/getIsSigned',
                isSignable: 'file/getIsSignable',
                isUpdatePath: 'file/getUpdatePath',
                signatureRequestStatus: 'file/getSignatureRequestStatus',
                redirectPath: 'file/getRedirectPath',
                isManager: 'isManager'
            })
        }
    }
</script>

<i18n>
    {
        "fr": {
            "not_started": "Signature manquante",
            "ongoing": "Signature en cours",
            "complete": "Signé",
            "back": "Modifier"
        }
    }
</i18n>

<style lang="scss">
    $white: #ffffff;
    $white-bar: #EFEFEF;
    $header-height: 68px;

    .viewer-navigation-bar {
        min-height: $header-height;
        display: flex;
        background: $white;
        color: #393939;
        top: 0;
        position: sticky;
        z-index: 9;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 #F2F2F2 inset;

        .list {
            display: flex;
            flex-direction: row;
            width: 100%;

            .nav-item {
                display: flex;
                align-items: center;
                padding: 15px 30px;
                // flex: 1;
                
                &.principal {
                    border-right: 1px solid $white-bar;

                    .back {
                        display: flex;
                        align-items: center;
                        font-size: 12pt;
                        color: #393939;
                        cursor: pointer;

                        .icon {
                            margin-right: 10px;
                        }
                    }
                }

                &.title-group {
                    flex: 1;

                    .title {
                        font-size: 18px;
                        color: #393939;
                    }

                    .description,
                    .status {
                        margin-left: 27px;
                        display: flex;
                        align-items: center;
                        
                        span {
                            color: #393939;
                            opacity: .5;
                            line-height: 25px;
                        }
                    }
                }

                &.validate-status {
                    .icon {
                        margin-right: 15px;
                    }

                    span {
                    color: #393939
                    }
                }

                &.exit {
                    $icon-width: 16px;
                    
                    margin-left: auto;
                    border-left: 1px solid $white-bar;
                    position: relative;
                    flex-basis: $icon-width;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    
                    &:before, &:after {
                        position: absolute;
                        content: '';
                        display: flex;
                        background: #393939;
                        border-radius: 2px;
                        width: 2px;
                        height: $icon-width;
                    }

                    &:before {
                        transform: rotate(45deg);
                    }

                    &:after {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
</style>
