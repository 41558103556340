<template>

    <div :class="['payment-method-element', { 'edit-mode': editAction }, { 'active': active }]" >

        <div class="method">

            <svg-icon class="card-icon" :viewbox="'0 0 24 24'" :width="'24'" :height="'24'" iconColor="#BED000" v-if="editAction || !paymentMethod.type"><card-icon /></svg-icon>

            <svg-icon class="left-icon" width="24" height="24" viewbox="0 0 32 32" iconColor="#BED000" v-else-if="paymentMethod.type === 'sepa_credit_transfer'"><credit-transfer-icon /></svg-icon>
            
            <svg-icon class="left-icon" width="24" height="24" :viewbox="'0 0 32 32'" iconColor="#BED000" v-else-if="paymentMethod.type === 'virtual_iban'"><virtual-iban-icon /></svg-icon>
            
            <svg-icon class="left-icon" width="24" height="24" :viewbox="'0 0 32 32'" iconColor="#BED000" v-else-if="paymentMethod.type === 'external_sepa_direct_debit'"><external-sepa-direct-debit-icon /></svg-icon>
            
            <svg-icon class="left-icon" width="24" height="24" :viewbox="'0 0 32 32'" iconColor="#BED000" v-else-if="paymentMethod.type === 'cash'"><cash-icon /></svg-icon>
            
            <svg-icon class="left-icon" width="24" height="24" :viewbox="'0 0 32 32'" iconColor="#BED000" v-else-if="paymentMethod.type === 'check'"><check-icon /></svg-icon>

        </div>

        <div class="payment-method-label" v-if="!editAction" @click="$emit('edit')">

            <template v-if="paymentMethod.type === 'card'">{{ paymentMethod.type !== undefined ? $t(`payment_method.${paymentMethod.type }`) : paymentMethod.bank_name }} {{ $t(`payment_method.${paymentMethod.card_brand}`).toUpperCase() }}</template>

            <template v-else>{{ paymentMethod.type !== undefined ? $t(`payment_method.${paymentMethod.type }`) : paymentMethod.bank_name }}</template>

            <template v-if="paymentMethod.last_4_digits || paymentMethod.iban">{{ ' (•••••• ' + (paymentMethod.last_4_digits || paymentMethod.iban.slice(-4)) + ')' }}</template>

        </div>

        <div class="edit-mode-container" v-if="editAction" @click="$emit('edit')">

            <h1 class="edit-mode-text" v-html="editAction" />

            <svg-icon class="add-new-icon"  width="20" height="20" viewBox="0 0 24 24" :iconColor="'#BED000'" strokeWidth="2"><add-new-circle-icon /></svg-icon>
            
        </div>

        <svg-icon class="icon img" :iconColor="'#BED000'" :viewbox="'0 0 24 24'" :width="'24'" :height="'24'" @click.native="$emit('delete', paymentMethod)" v-if="paymentMethod && !active"><delete-icon /></svg-icon>

        <div class="checkmark-box" @click="$emit('updateDefaultPaymentMethod', paymentMethod)" v-if="!editAction">
                
            <span :class="['checkmark', { 'checked': paymentMethod.default }]"></span>

        </div>

    </div>

</template>

<script>
    import SvgIcon from '~/components/svg-icon'
    import PaymentMethodIcon from '~/components/icons/payment-method-icon'
    import DeleteIcon from '~/components/icons/button-icons/delete-icon'
    import { CardIcon, CashIcon, CheckIcon, CreditTransferIcon, VirtualIbanIcon, ExternalSepaDirectDebitIcon } from '~/components/icons/bank-icons'
    import AddNewCircleIcon from '~/components/icons/button-icons/add-new-circle-icon'

    export default {
        components: {
            SvgIcon,
            PaymentMethodIcon,
            DeleteIcon,
            CardIcon,
            AddNewCircleIcon,
            CashIcon,
            CheckIcon,
            CreditTransferIcon,
            ExternalSepaDirectDebitIcon,
            VirtualIbanIcon
        },

        props: {
            paymentMethod: {
                type: Object,
                required: false
            },
            active: {
                type: Boolean,
                default: false
            },
            editAction: {
                type: String,
                default: ''
            }
        }
    }
</script>

<style lang="scss">
    $height: 56px;
    $border-color: #B5BFD6;
    $border-color-delete: #d6b5b5;
    $icon-width: 48px;
    $icon-height: 28px;
    $icon-border-color: #DEE3EF;
    $radio-button-color: #BED000;
    $delete-button-color: #df0c0c;
    $background-delete: #f6a5a5;
    $radio-button-border-color: #6C6C6C;
    $radio-button-border-color-cheked: #BED000;

    .payment-method-element {
        display: flex;
        align-items: center;
        height: $height;
        background: white;
        border: 1px solid $border-color;
        border-radius: 10px;
        box-sizing: border-box;
        padding-left: 12px ;

        &.active {
            border: 1px solid $radio-button-color;
        }

        &.edit-mode {
            border: 1px solid #E4E4E4;
            padding: 12px 8px 12px 12px;

            .img {
                opacity: .3;
            }
        }

        .edit-mode-container {
            display: flex;
            width: 100%;
            align-items: center;
            cursor: pointer;
            justify-content: space-between;

            .edit-mode-text {
                color:#BED000;
                font-size: 10.5pt;
                font-weight: 600;
            }
        }


        .method {
            background: #BED00033;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            width: 32px;
            height: 28px;
            margin-right: 8px;
        }

        .payment-method-label {
            flex-basis: 100%;
            @include body;
        }

        .icon {
            cursor: pointer;
            margin-right: 10px;
        }

        .checkmark-box {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                background: #EFEFEF;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;

                .checkmark {
                    height: 14px;
                    width: 14px;
                    margin-right: 1px;
                    border: 1px solid $radio-button-border-color;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
            
                    &:after {
                        content: "";
                        display: none;
                    }
            
                    &.checked {
                        border-color: $radio-button-border-color-cheked;
                        background: $radio-button-border-color-cheked;
            
                        &:after {
                            display: block;
                            width: calc(100% - 6px);
                            height: calc(100% - 6px);
                            border-radius: 100%;
                            background: white;
                        }
                    }
                }
            }
    }
</style>
