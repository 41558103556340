<template>

    <modal :title="$t('title')" :options="modalOptions" @close="hide" ref="modal">

        <div id="welcome-agent-page" class="container">

            <div class="title-group">

                <FootSteps class="foot-step" />

                <div class="sub-title">

                    <h1 class="title">{{$t('title')}}</h1>
    
                    <p> {{$t('sub_title')}} </p>

                </div>

            </div>
    
            <div class="container-list">
    
                <div class="check-list">
                    <CheckOutline class="my-2" />
    
                    <p class="my-2">{{$t('list1')}}</p>
    
                </div>
    
                <div class="check-list ">
    
                    <CheckOutline class="my-2" />
    
                    <p class="my-2">{{$t('list2')}}</p>
    
                </div>
    
                <div class="check-list">
    
                    <CheckOutline class="my-2" />
    
                    <p class="my-2">{{$t('list3')}}</p>
    
                </div>
    
            </div>
    
            <div class="chip">
    
                <div class="my-s">{{$t('chip_title')}}</div>
    
                <p>{{$t('chip_text')}}</p>
    
            </div>
    
            <div class="list divider">
        
                <button-input  @click="setStep(0)" class="btn-agent background-transparent green-border-color green-text-color rounded-full" :text="$t('button_exit')" />
    
                <button-input  @click="setStep(2)" class="btn-agent background-green  white-text-color rounded-full" :text="$t('button_next')" />
    
            </div>
    
        </div>

    </modal>


</template>

<script>
    import Modal from '~/components/modal'
    import CheckOutline from '~/components/icons/onboarding-icons/check-outline.vue';
    import FootSteps from '~/components/icons/onboarding-icons/foot-steps.vue';
    import Button from '~/components/table-list/table-cell/button.vue';
    import { mapGetters } from 'vuex'

    export default {
        components: { 
            CheckOutline, 
            FootSteps, 
            Button,
            Modal 
        },

        props: {
            step: {
                type: Number,
                default: 0
            },
            cardStyle: {
                type: Object,
                default: () => {}
            },
            cardClass: {
                type: Array,
                default: () => []
            }
        },

        data() {
            return {
                agentData: {
                    id: 0,
                    settings: {
                        active: true,
                        modal: true,
                        card: true
                    },
                    cardExtStyle: {
                        zIndex: 150,
                        width: 900,
                        height: 680,
                        center: true,
                        cardPositionType: 'relative',
                        cardPositionX: null,
                        cardPositionY: 100,
                        isTopLeft: true,
                        borderRadius: 7,
                        opacity: 1,
                        cardBgColor: 'white'
                    }
                }
            }
        },

        beforeMount() {
            this.agentData.id = Date.now()

            this.$store.commit('onboarding/setOnboardingAgentData', this.agentData)
        },

        methods: {
            hide() {
                this.$refs.modal.hide()
            },

            async setStep(step) {
                this.$store.commit('onboarding/setOnboardingSteps', { step: step, userId: this.getAuthUser['_jv'].id })

                if (step === 0) {
                    
                    this.setAgentData({})

                    const onboardingData = {
                        onboarding_finished: true,
                        onboarding_step: 99
                    }

                    await this.$axios({ method: 'put', url: `/fapi/onboardings/${this.getAuthUser['_jv'].id}`, data: onboardingData })
                }
            },

            setAgentData(data) {
                this.$store.commit('onboarding/setOnboardingAgentData', data)
            }
        },

        computed: {
            modalOptions() {
                return {
                    defaultOpen: true,
                    largeOption: {
                        noLogoContainer: true,
                    },
                    hideButtons: true,
                    centerContainer: true,
                    innerModalStyle: {
                        'border': '5px solid #BED000',
                        'box-shadow': 'none !important',
                        'max-height': '95vh',
                        'height': 'initial'
                    }
                }
            },

            ...mapGetters({
                getAuthUser: 'getAuthUser'
            })
        }
    }
</script>

<i18n>
    {
        "fr": {
            "title": "Bienvenue sur Oskar !",
            "sub_title": "Vos premières missions, si vous les acceptez, sont les suivantes :",
            "list1": "Créer une fiche de lot",
            "list2": "Créer un contrat",
            "list3": "Signer un contrat",
            "chip_title": "Rappel",
            "chip_text": "Votre espace de démo est disponible pendant 7 jours à partir d'aujourd'hui. Ce message se détruira quand la boîte de dialogue disparaîtra",
            "button_exit": "Quitter l'onboarding",
            "button_next": "C'est parti !"
        }
    }
</i18n>

<style lang="scss" scoped>
    .container {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        width: 90%;

        .chip {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px 10px 10px 24px;
            width: 100%;
            height: 108px;
            background: #bfd0002a;
            border-left: 3px solid #BED000;
            border-radius: 0px 6px 6px 0px;
            margin-bottom: 3rem;

            .my-s {
                margin: 10px 0 10px 0;
            }
        }

        p {
            color: rgba(46, 46, 46, 0.702);
        }

        .title-group {
            display: flex;
            flex-direction: row;
            margin-bottom: 30px;

            .foot-step {
                height: 120px;
                margin: 0 auto 0 auto;
            }

            .title {
                font-size: 32px;
                text-align: left;
                font-weight: normal;
            }
            
            .sub-title {
                display: flex;
                flex-direction: column;
                color: blue !important;
                justify-content: center;
            }
        }

        .container-list {
            margin: 3% 0 auto 0;
            padding: 0 4% 0 4%;
            margin-bottom: 2rem;

            .check-list {
                width: auto;
                display: flex;
                flex-flow: row;

                .my-2 {
                    margin: 3px;
                }
            }
        }
    
        .list {
            width: auto;
            display: flex;
            flex-flow: row;
            gap: 10vh;
            justify-content: center;
            align-content: center;

            &.divider {
                height: auto;
                margin-top: auto;
            }

            .btn-agent {
                align-items: center;
                padding: 14px 16px;
                width: 382px;
                height: 44px;
            }
        }
    }
</style>
