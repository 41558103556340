<template>

    <div :class="['counter-input', { 'active': value > 0 }]">
    
        <label class="option">{{ formatted(label) }}</label>

        <button-input class="button left" text="-" @click.native="decrementCounter"></button-input>

        <span class="value">{{ value }}</span>

        <button-input class="button right" text="+" @click.native="incrementCounter"></button-input>
        
    </div>

</template>

<script>
    import FormGroup from '~/components/inputs/form-group'

    export default {
        components: { 
            FormGroup
        },
        
        props: {
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            value: {
                type: Number,
                required: true
            }
        },
        
        methods: {
            decrementCounter() {
                if (this.value > 0) this.$emit('input', this.value - 1, this.name)
            },
            
            incrementCounter() {
                this.$emit('input', this.value + 1, this.name)
            },
            
            formatted(option) {
                return option.charAt(0).toUpperCase() + option.slice(1)
            }
        }
    }
</script>

<style lang="scss">
    $input-height: 50px;
    $border-color: #DEE3EF;
    $active-color: #525254;
    $color : #BED000;

    .counter-input {
        width: 50%;
        margin-bottom: 17px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &.active {
            .button, .value {
                color: $active-color;
                font-weight: 400;
            }
        }

        &.errors {
            .button {
                background-color: #FBE8E8;
                border-color: #F5A623;
                color: #F5A623;
            }
        }

        .value {
            width: 64px;
            font-size: 11pt;
            color: #8F94A0;
            text-align: center;
            border-top: 1px solid $border-color;
            border-bottom: 1px solid $border-color;
            padding: 12px 0 12.5px 0
        }

        .option {
            width: 100%;
            margin: 8px 0px;
            font-size: 11pt;
        }

        .button {
            border: 1px solid $border-color;
            background: transparent;
            width: 45px;
            height: 45px;
            padding: 0;
            color: $border-color;

            &.left {
                border-radius: 8px 0px 0px 8px;
            }
            &.right {
                border-radius: 0px 8px 8px 0px;
            }
            
            .text {
                font-size: 25px;
            }
        }
    }
</style>
