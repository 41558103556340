<template>


    <button-group-footer class="workflow-button-group">

        <template v-slot:left-panel>

            <button-input
                :class="['item', 'background-grey-dark', 'white-text-color', { 'active': canSaveLater }]"
                :text="$t('save_and_quit')"
                @click="$emit('saveWorkflow')"
                v-if="canSaveLater"
            />

        </template>

        <template v-slot:right-panel>

            <div class="file-upload" v-if="canUploadExternalFile">

                <button-input class="button background-transparent green-text-color"
                              iconImg="upload"
                              :text="getTextUploadFile"
                              @click="$refs.inputFile.click()"
                              v-if="workflow.model.document === null"
                />

                <div
                    :class="['external-file']"
                    v-else
                >
                    <svg-icon class="icon" iconColor="#393939" width="16" height="16" :viewbox="'0 0 15 20'">
                        <document-icon/>
                    </svg-icon>

                    <span class="file-name">{{ workflow.model.document.name }}</span>
                    <button-input class="small button quit" @click="resetExternalFile"/>

                </div>

                <input
                    type="file"
                    @change="setExternalFile"
                    ref="inputFile"
                    :accept="['.jpeg','.jpg','.png','.pdf']"
                />

            </div>

            <button-input
                class="item background-success success-text-color"
                :text="$t('complete')"
                :loading="loadingSaving"
                @click="$emit('save')"
            />

        </template>
    </button-group-footer>

</template>

<script>
import SvgIcon from '~/components/svg-icon'
import {DocumentIcon} from '~/components/icons/button-icons'
import SpinnerLoader from '~/components/spinner-loader.vue'
import ButtonGroupFooter from '~/components/navigation/footer/button-group-footer'

import Workflow from "@/shared/Workflow";
import ButtonInput from "@/components/inputs/button-input.vue";
import DocumentFile from "@/models/File";

export default {
    components: {
        ButtonInput,
        SvgIcon,
        DocumentIcon,
        SpinnerLoader,
        ButtonGroupFooter
    },
    data() {
        return {
            externalFile: ''
        }
    },
    props: {
        workflow: {
            type: Workflow,
            required: true
        },
        loadingSaving: {
            type: Boolean,
            required: true
        },
        canUploadExternalFile: {
            type: Boolean,
            default: false
        },
    },

    methods: {

        setExternalFile(event) {
            const currentTarget = event.target,
                files = currentTarget.files;

            if (files.length === 0) {
                return;
            }
            const file = files[0];

            const document = new DocumentFile;

            document.file = file;
            document.name = file.name;
            document.size = file.size;
            document.mime_type = file.type;


            this.workflow.model.document = document;
        },

        resetExternalFile() {
            this.workflow.model.document = null;
        },
    },

    computed: {
        canSaveLater() {
            return this.workflow.isInsert &&
                this.workflow.dumpName !== '' &&
                this.workflow.steps[0]?.isFinished;
        },
        getTextUploadFile() {
            if (this.workflow.model.type ==='') {
                return;
            }

            return this.$t(`upload_agreement_${this.workflow.model.type}`);
        },

    }
}
</script>

<i18n>
{
    "fr": {
        "save_and_quit": "Enregistrer et continuer plus tard",
        "complete": "Enregistrer",
        "upload_agreement": "Importer un contrat",
        "upload_agreement_inventory": "Importer un état des lieux",
        "upload_agreement_mandate": "Importer un mandat",
        "upload_agreement_cost": "Importer une charge",
        "upload_agreement_lease_agreement": "Importer un contrat de location",
        "see": "Ouvrir",
        "invalid": "Erreur"
    }
}
</i18n>

<style lang="scss">
$error-text: rgba(228, 63, 63, 0.6);
$background-error-text: rgba(228, 63, 63, 0.12);

.workflow-button-group {
    .item {
        margin-right: 30px;
        padding-left: 70px;
        padding-right: 70px;

        &:first-child {
            margin-right: auto;
        }

        &:last-child {
            margin-right: 0;
        }

        &.save:not(.active) {
            opacity: 0;
        }
    }

    .file-upload {
        margin-right: 30px;

        .navigate {
            padding-right: 15px;
        }

        .external-file {
            display: flex;
            align-items: center;
            border: 1px solid #F2F2F2;
            border-radius: 10px;
            padding: 2px 12px;
            height: 44px;
            box-sizing: border-box;

            .icon {
                margin-right: 9px;
            }

            .file-name {
                margin-right: 23px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 230px;
            }

            &.errors {
                border-color: $error-text;
                background: $background-error-text;

                .file-name {
                    color: $error-text;
                }
            }

            .button {
                box-sizing: border-box;
                min-height: 26px;
                border-radius: 5px;
                font-size: 12px;

                & ~ .button {
                    margin-left: 8px;
                }

                &.quit {
                    width: 26px;

                    &:before,
                    &:after {
                        position: absolute;
                        content: '';
                        display: flex;
                        background: #393939;
                        height: 1px;
                        width: 7px;
                    }

                    &:before {
                        transform: rotate(45deg);
                    }

                    &:after {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
}
</style>
