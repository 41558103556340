export default () => {
    return {
        amount_due: '',
        amount_paid: '',
        refund_amount: 0,
        rentable_type: '',
        id: '',
        items: [],
        payment_date: '',
        payout_date: '',
        period_end_date: '',
        period_start_date: '',
        status: '',
        payment_due_date: '',
        reference: '',
        total_amount: 0,
        vat_amount: 0,
        invoice_number: '',
        date: '',
        relationships: {
            rental: {},
            rent_notice: {},
            rent_receipt: {},
            payments: [],
            tenants: []
        }
    }
}