<template>

    <div class="landlord-action-buttons-widget margin-widget" v-if="isManager">
        
        <button-input :iconImg="'edit'" iconColor="#FFFFFF" class="action list" :text="$t('edit_landlord')" :redirectTo=" `/landlord/${landlordId}/update`" />

        <button-input :iconImg="'bank'" iconColor="#FFFFFF" class="action list" :text="$t('settle_account')" @click="$refs.settleLandlordAccountModal.show()" v-if="balance >= 0"/>

        <button-input :iconImg="'account'" iconColor="#FFFFFF" class="action list" :text="$t('invit_landlord')" @click="$refs.invitProfileModal.show()" v-if="!hasUser" />

        <button-input :iconImg="'fees'" iconColor="#FFFFFF" class="action list" :text="$t('edit_fees')" @click="$refs.editLandlordFees.show()" />

        <button-input class="action action-delete" iconColor="#FFFFFF" :iconImg="'delete'" :text="$t('delete_landlord')" @click="$refs.deleteLandlordModal.show()" />

        <edit-landlord-fees ref="editLandlordFees" />

        <delete-landlord-modal ref="deleteLandlordModal" />

        <invite-profile-modal type="landlord" ref="invitProfileModal" />

        <settle-landlord-account-modal ref="settleLandlordAccountModal" @closed="$emit('closed')"/>
        
    </div>

</template>

<script>
    import DeleteLandlordModal from '~/components/modal/delete-landlord'
    import InviteProfileModal from '~/components/modal/invite-profile'
    import EditLandlordFees from '~/components/modal/edit-landlord-fees'
    import SettleLandlordAccountModal from '~/components/modal/settle-landlord-account-modal'
    import { mapGetters } from 'vuex'
    import ButtonInput from '~/components/inputs/button-input.vue'

    export default {
        components: {
            SettleLandlordAccountModal,
            DeleteLandlordModal,
            InviteProfileModal,
            EditLandlordFees,
            ButtonInput,
        },

        props: {
            landlord: {
                type: Object,
                required: true
            }
        },

        computed: {
            ...mapGetters({
                landlordId: 'profile/getId',
                balance: 'accounting-account/getBalance',
                isManager: 'isManager'
            }),

            hasUser() {
                return !!this.landlord._jv?.json?.included?.find(inc => inc.type === 'users')
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "delete_landlord": "Supprimer le propriétaire",
            "edit_landlord": "Modifier le propriétaire",
            "invit_landlord": "Créer un espace personnel",
            "edit_fees":"Modifier les honoraires",
            "settle_account": "Solder le compte"
        }
    }
</i18n>

<style lang="scss">
    .landlord-action-buttons-widget {
        display: flex;
        flex-direction: column;
        
        .action {

            &.list{
                color: #FFFFFF;
                background: #393939;
            }
        }
    }
</style>
