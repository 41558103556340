<template>

  <div :class="['property-list-menu', { 'active': burgermenuIsOpen }]" > 

    <span class="title"> {{ isProperty ? $t('properties') : $t('rental') }} </span> 

    <ul class="property-list-container" v-if="properties.length">

       <li class="property-list" v-for="(property, optionKey) in properties" :key="optionKey" @click="selectProperty(property.value)">

           <div class="property-item">

                <svg-icon class="icon" width="40" height="40" iconColor="#525254"><property-icon /></svg-icon>
                
                <div class="property-infos">

                    <p class="main-info">{{ property.line1 }}</p>

                    <p class="seccond-info">{{ property.line2 }}</p>

                    <slot></slot>

                </div>

            </div>

        </li>

    </ul>

    <p class="no-properties" v-else> {{ isProperty ? $t('no_properties') : $t('no_rental') }} </p>

  </div>

</template>

<script>
    import PropertyNatureShorthandMixin from '~/components/mixins/property-nature-shorthand'
    import SvgIcon from '~/components/svg-icon'
    import PropertyIcon from '~/components/icons/table-cell-icons/property-icon'
    import { mapGetters } from 'vuex'

export default {
    mixins: [PropertyNatureShorthandMixin],

    components: {
        SvgIcon,
        PropertyIcon
    },
    
    props: {        
        properties: {
            type: Array,
            required: true
        },

        isProperty: {
            type: Boolean,
            required: false,
            default: true
        }
    },

    methods: {
        selectProperty(property) {
            this.$emit('input', property)
        }
    },
    
    computed: {
        ...mapGetters({
            burgermenuIsOpen: 'getBurgerMenuIsOpen'
        })
    }
}
</script>

<i18n>
    {
        "fr": {
            "properties": "Vos biens",
            "no_properties": "Aucun bien",
            "rental": "Vos locations",
            "no_rental": "Aucune location"
        }
    }
</i18n>

<style lang="scss" scoped>
    $separator-color: #F2F2F2;

    .property-list-menu {
        background-color: white;
        transform: translate(-100%);
        transition: .2s ease-in-out;
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        cursor: pointer;

        &.active {
            transform: translate(0px);
        }

        .title {
            color: #471AB5;
            position: absolute;
            font-size: 16pt;
            font-weight: 400;
            top: 18px;
            right: 35px;
        }

        .property-list-container {
            border-radius: 6px;
            margin: 56px 12px 0px 12px;

            .property-list {
                padding: 0px 0px 10px 0px;
                margin-bottom: 10px;
                border-bottom: 1px solid #F2F2F2;

                &:last-child {
                    margin-bottom: 0;
                    border-bottom: 0;
                    padding-bottom: 0;
                }

                .property-item{
                    display: flex;
                    height: 86px;
                    align-items: center;
                    cursor: pointer;
                    border-radius: 6px; 
                    padding: 0px 16px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;

                    .icon {
                        min-width: 40px;
                    }
                                    
                    &:hover {
                        background: #EFEFEF;
                    }

                    .property-infos {
                        margin-left: 28px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;

                        .main-info {
                            color: rgb(51,51,51);
                            font-weight: 400;
                        }

                        .main-info,.seccond-info {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
        .no-properties {
            text-align: center;
            font-size: 13pt;
            margin-top: 80px;
        }
    }

</style>