<template>
    
    <jumbotron :class="['profile-header', profileIllustration]"

        :breadcrumb="breadcrumb"

        :breadcrumbBack="breadcrumbBack"

        :title="getProfileFullName(profile)"

        :subTitle="$t('created_at', { date: $moment(profile.created_at).format('DD/MM/YYYY') })"

        :tags="profileTag"
        
        :iconPath="role" />

</template>

<script>
    import FullNameMixin from '~/components/mixins/full-name'
    import Jumbotron from '~/components/navigation/header/jumbotron'
    import { mapGetters } from 'vuex'
    
    export default {
        mixins: [FullNameMixin],

        components: {
            Jumbotron
        },

        props: {
            role: {
                type: String,
                required: true
            }
        },

        async fetch() {

            const { store } = this.$nuxt.context

            if (this.role === 'tenants') this.profile = await store.dispatch('profile/fetchTenant', this.profileId)

            else if (this.role === 'landlords') this.profile = await store.dispatch('profile/fetchLandlord', this.profileId)

            else if (this.role === 'suppliers') this.profile = await store.dispatch('profile/fetchSupplier', this.profileId)
        },

        data() {
            return {
                profile: {}
            }
        },

        computed: {
            breadcrumb() {
                return this.isManager ? [
                    {
                        text: this.$t('dashboard'),
                        redirectPath: '/dashboard'
                    },
                    {
                        text: this.$t(this.role),
                        redirectPath: `/dashboard/${this.role}`,
                    },
                    {
                        text: this.$t('profile_name', { 
                            full_name: this.getProfileFullName(this.profile)
                        })
                    }
                ] : [] 
            },

            breadcrumbBack () {
                return `/dashboard/${this.role}`
            },

            profileTag() {
                return [
                    {
                        redirect_path: '',
                        text: this.$t(this.role)
                        
                    }
                ]
            },

            profileIllustration() {
                return this.role === 'landlords' ? 'background-landlord-header' : 'background-tenant-header'
            },

            ...mapGetters({
                profileId: 'profile/getId',
                isManager: 'isManager'
            })
        }
    }
</script>

<i18n>
    {
        "fr": {
            "landlords": "Propriétaires",
            "tenants": "Locataires",
            "dashboard": "Accueil",
            "suppliers": "Fournisseurs",
            "profile_name": "{full_name}",
            "created_at": "Créé le {date}",
            "auto": "Automatique"
        }
    }
</i18n>
