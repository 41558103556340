export default () => {
    return {
        created_at: '',
        email_address: '',
        entity: '',
        full_name: '',
        id: '',
        legal_persons: [],
        payout_delay: null,
        phone_number: '',
        role: '',
        matrimonial_status: '',
        relationships: {
            accounting_account: {},
            applied_fees: [],
            default_payout_bank_account: {},
            documents: [],
            fees: [],
            guarantors: [],
            invitations: [],
            payment_methods: [],
            payout_bank_accounts: [],
            properties: [],
            rentals: [],
            property_management_company: {},
        }
    }
}
