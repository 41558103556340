<template>
    
    <number-input class="amount-input" :label="label" :name="name" v-model="amount" :rules="rules" :options="amountOptions" :disabled="disabled" :nullable="nullable" />

</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            value: {
                type: Number,
                required: true
            },
            rules: {
                type: [String, Object],
                default: ''
            },
            options: {
                type: Object,
                default() {
                    return {}
                }
            },
            disabled: {
                type: Boolean,
                default: false
            }, 
            nullable: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            amountOptions() {
                return {
                    metric: typeof this.options.metric !== 'undefined' ? this.options.metric : '€ TTC',
                    step: typeof this.options.step !== 'undefined' ? this.options.step : 0.01,
                    decimal: typeof this.options.decimal !== 'undefined' ? this.options.decimal : 0
                }
            },

            amount: {
                get: function() {
                    return this.value
                },
                set: function(value) {
                    this.$emit('input', value)
                }
            }
        }
    }
</script>