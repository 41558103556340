<template>

  <widget class="profile-payment-method-widget background-payment-method " :fetch="fetchTenantDefaultPaymentMethod" ref="widget">
        
    <div class="container" >

        <div class="header">

            <div class="title">

                {{ $t('title') }}        
                
            </div>
            
        </div>

        <div class="payment_method">

            {{ $t(paymentMethod.type) }}

            <svg-icon class="icon img" v-if="paymentMethod.type === 'virtual_iban'" iconColor="white" viewbox="0 0 32 32" width="32" height="32" ><virtual-iban-icon /></svg-icon>

            <svg-icon class="icon img" v-if="paymentMethod.type === 'cash'" iconColor="white" viewbox="0 0 32 32" width="32" height="32" ><cash-icon /></svg-icon>

            <svg-icon class="icon img" v-if="paymentMethod.type === 'sepa_credit_transfer'" iconColor="white" viewbox="0 0 32 32" width="32" height="32" ><credit-transfer-icon /></svg-icon>

            <svg-icon class="icon img" v-if="paymentMethod.type === 'external_sepa_direct_debit'" iconColor="white" viewbox="0 0 32 32" width="32" height="32" ><external-sepa-direct-debit-icon /></svg-icon>

            <svg-icon class="icon img" v-if="paymentMethod.type === 'check'" iconColor="white" viewbox="0 0 32 32" width="32" height="32" ><check-icon /></svg-icon>

        </div>
        
        <p v-if="!paymentMethod">{{ $t('no_payment_method') }}</p>
        
        <button-input class="button background-white grey-text-color" :text="$t('detail')" iconImg="view" iconColor="#767676" @click="$refs.tenantPaymentMethodModal.show()" v-if="paymentMethod.type === 'virtual_iban' || paymentMethod.type === 'external_sepa_direct_debit'"/>

        <tenant-payment-method-modal ref="tenantPaymentMethodModal" />

    </div>

  </widget>

</template>

<script>
    import Widget from '~/components/widget'
    import SvgIcon from '~/components/svg-icon'
    import { VirtualIbanIcon, CheckIcon, CreditTransferIcon, CashIcon, ExternalSepaDirectDebitIcon  }from '~/components/icons/bank-icons'
    import TenantPaymentMethodModal from '~/components/modal/tenant-payment-method-modal'
    import { mapGetters } from 'vuex'

export default {

    components: {
        Widget,
        SvgIcon,
        TenantPaymentMethodModal,
        VirtualIbanIcon,
        CheckIcon,
        CreditTransferIcon,
        CashIcon,
        ExternalSepaDirectDebitIcon
    },

    name: 'ProfilePaymentMethodWidget',

    data() {
        return {
            paymentMethod: {},
        }
    },

    methods: {
        async fetchTenantDefaultPaymentMethod(){
            try {

                const paymentMethods = await this.$store.dispatch('profile/fetchTenantPaymentMethodsRelationship')

                this.paymentMethod = paymentMethods.find(paymentMethod => paymentMethod.default === true)

                if (typeof this.paymentMethod === 'undefined') {

                    this.paymentMethod = paymentMethods.find(paymentMethod => paymentMethod.bank_name === 'Swan')
                }
                
            } catch (error) {

                throw error
            }
        }
    },

    computed: {
        ...mapGetters({
            profileRole: 'profile/getRole',
            paymentMethodId: 'payment-method/getId'
        })
    },

    watch: {
        paymentMethodId() {
            this.fetchTenantDefaultPaymentMethod()
        }
    }
}
</script>

<i18n>
    {
        "fr": {
            "detail": "Voir le détail",
            "title": "Moyen de paiement",
            "virtual_iban": "IBAN virtuel",
            "cash": "Espèces",
            "sepa_credit_transfer": "Virement programmé",
            "external_sepa_direct_debit": "Prélevement",
            "check": "Chèque",
            "no_payment_method": "Aucune méthode de paiement"
        }
    }
</i18n>

<style lang="scss">
    $grey: white;
    .profile-payment-method-widget{
        border-radius: 12px;
        background: linear-gradient(180deg, #BED000 0%, #62AA47 100%);
        
        .container{
            color: white;
            width: 100%;

            .header {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                padding-bottom: 24px;
                border-bottom: 1px solid #F2F2F2 ;

                .title{
                    display: flex;
                    align-content: flex-start;
                    gap: 8px;
                    font-weight: 600;
                    font-size: 18pt;
                    color: white;
                }
            }

            .payment_method {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 8px;
                font-weight: 600;
                font-size: 14pt;
                color: white;
                margin: 24px 0 0;

            }

            .button{
                margin-top: 24px;
            }

            .body{
                margin-bottom: 16px;

                h2{
                    font-weight: 600;
                    font-size: 14px;
                    color: white;
                }

                h3{
                    font-weight: 200;
                    font-size: 14px;
                    color: white;
                }
            }
        }
    }
</style>