<template>

    <div :class="'inventory-table-cell'">

        <div class="date">

            <label class="label" v-if="date">{{ $moment(date).format('DD/MM/YYYY') }}</label>

            <template v-else>-</template>

        </div>

<!--        <button-input -->
<!--            class="button green-text-color background-transparent"-->
<!--            :text="$t('inventory')" -->
<!--            :redirectTo="`/workflow/property/${this.propertyId}/inventory/create`"-->
<!--            iconColor="#BED000" -->
<!--            iconImg="create" -->
<!--            v-if="!inventory && date" -->
<!--        />-->

        <button-input
            class="button grenn-text-color background-transparent"
            :text="$t('inventory')"
            :redirectTo="`/viewer/inventory/${this.inventory['_jv'].id}`"
            iconColor="#BED000"
            iconImg="view"
            v-if="inventory"
        />

    </div>

</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        props: {
            date: {
                required: false,
                type: String
            },
            inventory: {
                required: false,
                type: Object
            }
        },

        computed: {
            ...mapGetters({
                propertyId: 'property/getId'
            })
        }
    }
</script>

<i18n>
    {
        "fr": {
            "inventory": "État des lieux"
        }
    }
</i18n>

<style lang="scss">
    .inventory-table-cell {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 44px;

        .date .label {
            font-size: 10.5pt;
        }

        .button {
            margin-top: 6px;
            padding: 0;
            font-size: 10pt;
            min-height: inherit;
        }
    }
</style>
