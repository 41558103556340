<template>

    <div class="document-viewer">
    
        <client-only>

            <spinner-loader v-show="!loaded" class="spinner-loader white" />

            <template v-show="loaded">

                <div ref="viewer" class="pdf-viewer" v-click-outside="emitClickOutside" v-if="isMimeTypePdf">

                    <vue-pdf-embed id="viewer" class="viewer" :source="fileUrl" @rendered="pageRendered" ref="pdf" />

                </div>

                <img class="pdf-viewer img" :src="fileUrl" v-click-outside="emitClickOutside" v-else />

                <div class="page-selector" v-if="isMimeTypePdf && arrow">

                    <div class="prev-container" @click="firstPage">

                        <svg-icon :class="['first', { 'min': page === 1 }]" width="18" height="18" :iconStrokeColor="'#D8D8D8'" :iconColor="'#D8D8D8'" :viewbox="'0 0 18 16'" ><double-arrow-icon /></svg-icon>

                    </div>

                    <div class="prev-container" @click="previousPage">

                        <svg-icon :class="['prev', { 'min': page === 1 }]" width="16" height="16" :iconStrokeColor="'#D8D8D8'" :iconColor="'#D8D8D8'" :viewbox="'0 0 10 16'"><arrow-icon /></svg-icon>

                    </div>

                    <div class="index">{{ page }} / {{ totalPage }}</div>

                    <div class="next-container" @click="nextPage">

                        <svg-icon :class="['next', { 'max': page === totalPage }]" width="16" height="16" :iconStrokeColor="'#D8D8D8'" :iconColor="'#D8D8D8'" :viewbox="'0 0 10 16'"><arrow-icon /></svg-icon>

                    </div>

                    <div class="prev-container" @click="lastPage">

                        <svg-icon :class="['last', { 'min': page === totalPage }]" width="18" height="18" :iconStrokeColor="'#D8D8D8'" :iconColor="'#D8D8D8'" :viewbox="'0 0 18 16'" ><double-arrow-icon /></svg-icon>

                    </div>

                </div>
                
            </template>

        </client-only>

    </div>

</template>

<script>
    import SpinnerLoader from '~/components/spinner-loader'
    import SvgIcon from '~/components/svg-icon'
    import { ArrowIcon, DoubleArrowIcon } from '~/components/icons/button-icons'

    export default {
        components: {
            SvgIcon,
            SpinnerLoader,
            ArrowIcon,
            DoubleArrowIcon
        },

        props: {
            fileUrl: {
                type: String,
                required: false
            },
            mimes: {
                type: String,
                required: false
            },
            arrow: {
                type: Boolean,
                default: true
            }
        },

        data() {
            return {
                page: 1,
                loaded: false,
                totalPage: 1
            }
        },

        mounted() {
            if (!this.isMimeTypePdf) this.loaded = true
        },

        methods: {
            pageRendered() {

                let element = document.getElementById('viewer')

                let childCount = element.childElementCount

                this.totalPage = childCount

                let oberserFn = (entries) => {
                    if(entries[0].isIntersecting === true) 
                        this.page = Number(entries[0].target.getAttribute('pageIndex')) + 1
                }

                let oberserOptions = { threshold: [0.4] }

                element.children.forEach((element, index) => {

                    element.setAttribute('pageIndex', index)

                    let observer = new IntersectionObserver(oberserFn.bind(this), oberserOptions)
                    
                    observer.observe(element)
                })

                this.loaded = true

                this.$nextTick(() => this.$refs.viewer.style.transform = "translateY(0)")

            },

            firstPage(){
                let HtmlCollection = document.getElementById('viewer')

                this.$scrollTo(HtmlCollection.children[0])
            },

            lastPage(){
                let HtmlCollection = document.getElementById('viewer')

                this.$scrollTo(HtmlCollection.children[HtmlCollection.children.length - 1])
            },

            previousPage() {
                if (this.page > 1) {

                    this.page = this.page - 1

                    let HtmlCollection = document.getElementById('viewer')

                    this.$scrollTo(HtmlCollection.children[this.page - 1])
                }
            },
        
            nextPage() {
                if (this.page < this.totalPage) {

                    this.page = this.page + 1

                    let HtmlCollection = document.getElementById('viewer')

                    this.$scrollTo(HtmlCollection.children[this.page - 1])
                }
            },

            emitClickOutside($event) {
                this.$emit('clickOutside', $event)
            }
        },

        computed: {
            isMimeTypePdf() {
                return this.mimes === 'application/pdf'
            }
        },

        errorCaptured() {
            return false
        }
    }
</script>

<style lang="scss">
    .document-viewer {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .pdf-viewer {
            width: 1136px;
            max-height: 100%;
            transform: translateY(100%);
            transition: .2s ease;

            &.img {
                transform: translateY(0);
                border-radius: 4px;
            }

            #viewer > div {
                margin-bottom: 4px;
                overflow: hidden;

                &:first-child {
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                }

                &:last-child {
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }

            canvas {
                width: 100%;
                height: auto;
            }
        }
        
        .spinner-loader {
            justify-content: center;
            align-items: center;
            width: 100%;
            color: white;
            padding: 0;
        }

        .page-selector {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 83px;
            width: 55px;
            position: fixed;
            top: 50%;
            transform: translateY(-50%);
            right: 23px;
            user-select: none;

            .index {
                color: white;
            }

            .next-container,
            .prev-container {
                cursor: pointer;
                display: flex;
                justify-content: center;

                .prev,
                .next,
                .first,
                .last {
                    z-index: 10;
                    display: flex;
                    position: absolute;

                    &.min,
                    &.max {
                        opacity: .5;
                        cursor: inherit;
                    }
                }

                .first {
                    top: -35px;
                    transform: rotate(90deg);
                }

                .prev {
                    top: 0;
                    transform: rotate(90deg);
                }

                .next {
                    bottom: 0;
                    transform: rotate(-90deg);
                }

                .last {
                    bottom: -35px;
                    transform: rotate(-90deg);
                }
            }
        }
    }
</style>