export default {
    getAmountDue(state) {
        return state.amount_due
    },

    getAmountPaid(state) {
        return state.amount_paid
    },

    getRefundAmount(state) {
        return state.refund_amount
    },

    getRentableType(state) {
        return state.rentable_type
    },

    getId(state) {
        return state.id
    },

    getItems(state) {
        return state.items
    },

    getPaymentDate(state) {
        return state.payment_date
    },

    getPaymentDueDate(state) {
        return state.payment_due_date
    },

    getPeriodEndDate(state) {
        return state.period_end_date
    },

    getPeriodStartDate(state) {
        return state.period_start_date
    },

    getReference(state) {
        return state.reference
    },

    getStatus(state) {
        return state.status
    },

    getTotalAmount(state) {
        return state.total_amount
    },

    getVatAmount(state) {
        return state.vat_amount
    },

    getPayoutDate(state) {
        return state.payout_date
    },

    getInvoiceNumber(state) {
        return state.invoice_number
    },

    getDate(state) {
        return state.date
    },

    getRelatedRental(state) {
        return state.relationships.rental
    },

    getRelatedRentNotice(state) {
        return state.relationships.rent_notice
    },

    getRelatedRentReceipt(state) {
        return state.relationships.rent_receipt
    },

    getRelatedPayments(state) {
        return state.relationships.payments
    },

    getRelatedTenants(state) {
        return state.relationships.tenants
    }
}
