export default {
    getAddress(state) {
        return state.address
    },

    getReference(state) {
        return state.reference
    },

    getArea(state) {
        return state.area
    },

    getAreaTension(state) {
        return state.area_tension
    },

    getBuildingOwnershipType(state) {
        return state.building_ownership_type
    },

    getConstructionDate(state) {
        return state.construction_date
    },

    getDescription(state) {
        return state.description
    },

    getDwellingType(state) {
        return state.dwelling_type
    },

    getCaseNumber(state) {
        return state.case_number
    },

    getFiscalReferenceNumber(state) {
        return state.fiscal_reference_number
    },

    getIsFurnished(state) {
        return state.is_furnished
    },

    getIsInTenseArea(state) {
        return state.area_tension === 1
    },

    getIsInVeryTenseArea(state) {
        return state.area_tension === 2
    },

    getId(state) {
        return state.id
    },

    getItems(state) {
        return state.items
    },


    getSettlementType(state) {
        return state.settlement_type
    },

    getUserPrivileges(state) {
        return state.user_privileges
    },

    getRelatedAppliedFees(state) {
        return state.relationships.applied_fees
    },

    getRelatedAccountingAccount(state) {
        return state.relationships.accounting_account
    },

    getRelatedDiagnoses(state) {
        return state.relationships.diagnoses
    },

    getRelatedFees(state) {
        return state.relationships.fees
    },

    getRelatedLandlord(state) {
        return state.relationships.landlord
    },

    getRelatedRentals(state) {
        return state.relationships.rentals
    },

    getRelatedMandates(state) {
        return state.relationships.mandates
    },

    getRelatedTenants(state) {
        return state.relationships.tenants
    },

    getLocalAUsageCommun(state, getters) {
        let local_a_usage_commun = []
        let local_a_usage_commun_codes
            = getters['getLocalAUsageCommunOptions']

        for (let i in state.items) {
            if (local_a_usage_commun_codes.includes(state.items[i].type)) {
                local_a_usage_commun.push(state.items[i])
            }
        }

        return local_a_usage_commun
    },

    getLocalAUsageCommunOptions(state) {
        return [
            'bicycle_parking',
            'caretaker_service',
            'elevator',
            'green_space',
            'shared_laundry_room',
            'playground',
            'shared_shower_room',
            'shared_restroom'
        ]
    },

    getLocalAUsagePrive(state, getters) {
        let local_a_usage_prive = []
        let local_a_usage_prive_codes
            = getters['getLocalAUsagePriveOptions']

        for (let i in state.items) {
            if (local_a_usage_prive_codes.includes(state.items[i].type)) {
                local_a_usage_prive.push(state.items[i])
            }
        }

        return local_a_usage_prive
    },

    getLocalAUsagePriveOptions(state) {
        return [
            'attic',
            'balcony',
            'cellar',
            'loggia',
            'garden',
            'parking_space',
            'terrace',
            'veranda'
        ]
    },

    getModeDeProductionEauChaude(state, getters) {
        let mode_de_production_eau_chaude = []
        let mode_de_production_eau_chaude_codes
            = getters['getModeDeProductionEauChaudeOptions']

        for (let i in state.items) {
            if (mode_de_production_eau_chaude_codes.includes(state.items[i].type)) {
                mode_de_production_eau_chaude.push(state.items[i])
            }
        }

        return mode_de_production_eau_chaude
    },

    getModeDeProductionEauChaudePrivateOptions() {
        return [
            'electric_water_heater',
            'gas_boiler_water_heater',
            'gas_water_heater',
            'oil_boiler_water_heater',
            'solar_water_heater',
            'wood_boiler_water_heater'
        ]
    },

    getModeDeProductionEauChaudeCollectifOptions() {
        return [
            'shared_electric_water_heater',
            'shared_gas_boiler_water_heater',
            'shared_gas_water_heater',
            'shared_oil_boiler_water_heater',
            'shared_solar_water_heater',
            'shared_wood_boiler_water_heater'
        ]
    },

    getModeDeProductionEauChaudeCentralOptions() {
        return [
            'central_no_water_heater',
            'central_geothermal_water_heater',
            'central_mixed_water_heater',
            'central_aerothermal_water_heater',
            'central_thermodynamic_water_heater',
            'central_heat_pump_water_heater'
        ]
    },

    getModeDeProductionEauChaudeOptions(state, getters) {
        if (getters['getTypeDeProductionEauChaude'] === 'private')
            return getters['getModeDeProductionEauChaudePrivateOptions']

        else if (getters['getTypeDeProductionEauChaude'] === 'common')
            return getters['getModeDeProductionEauChaudeCollectifOptions']

        else if (getters['getTypeDeProductionEauChaude'] === 'central')
            return getters['getModeDeProductionEauChaudeCentralOptions']

        else return []
    },

    getModeDeProductionChauffage(state, getters) {
        let mode_de_production_chauffage = []
        let mode_de_production_chauffage_codes
            = getters['getModeDeProductionChauffageOptions']

        for (let i in state.items) {
            if (mode_de_production_chauffage_codes.includes(state.items[i].type)) {
                mode_de_production_chauffage.push(state.items[i])
            }
        }

        return mode_de_production_chauffage
    },

    getModeDeProductionChauffageCollectifOptions() {
        return [
            'shared_electric_boiler_heater',
            'shared_electric_heater',
            'shared_gas_boiler_heater',
            'shared_oil_boiler_heater',
            'shared_wood_boiler_heater'
        ]
    },

    getModeDeProductionChauffagePrivateOptions() {
        return [
            'electric_heater',
            'gas_boiler_heater',
            'oil_boiler_heater',
            'wood_boiler_heater'
        ]
    },

    getModeDeProductionChauffageCentralOptions() {
        return [
            'central_no_heater',
            'central_aerothermal_heater',
            'central_coal_heater',
            'central_air_conditioning_heater',
            'central_city_gas_heater',
            'central_geothermal_heater',
            'central_wood_pellet_heater',
            'central_mixed_heater',
            'central_heat_pump_heater',
            'central_solar_heat_heater',
        ]
    },

    getModeDeProductionChauffageEmptyOptions() {
        return [
            'empty_heater'
        ]
    },

    getModeDeProductionChauffageOptions(state, getters) {
        if (getters['getTypeDeProductionChauffage'] === 'private')
            return getters['getModeDeProductionChauffagePrivateOptions']

        else if (getters['getTypeDeProductionChauffage'] === 'common')
            return getters['getModeDeProductionChauffageCollectifOptions']

        else if (getters['getTypeDeProductionChauffage'] === 'central')
            return getters['getModeDeProductionChauffageCentralOptions']

        else if (getters['getTypeDeProductionChauffage'] === 'empty_heater')
            return getters['getModeDeProductionChauffageEmptyOptions']

        else return []
    },

    getOtherEquipements(state, getters) {
        let other_equipements = []
        let other_equipements_codes
            = getters['getOtherEquipementsOptions']

        for (let i in state.items) {
            if (other_equipements_codes.includes(state.items[i].type)) {
                other_equipements.push(state.items[i])
            }
        }

        return other_equipements
    },

    getOtherEquipementsOptions(state) {
        return [
            'bell',
            'cooker',
            'cooker_hood',
            'cooking_plate',
            'dishwasher',
            'dryer',
            'electronic_lock',
            'fireplace',
            'freezer',
            'intercom',
            'jacuzzi',
            'kitchen_furniture',
            'microwave_oven',
            'oven',
            'refrigerator',
            'swimming_pool',
            'washing_machine'
        ]
    },

    getPiecesPrincipales(state, getters) {
        let pieces_principales = []
        let pieces_principales_codes
            = getters['getPiecesDeSejour']

        for (let i in state.items) {
            if (pieces_principales_codes.includes(state.items[i].type)) {
                pieces_principales.push(state.items[i])
            }
        }

        return pieces_principales
    },

    getPiecesDeSejour(state) {
        return [
            'bedroom',
            'dining_room',
            'living_room',
            'office',
            'vestibule'
        ]
    },

    getPiecesDeServices(state) {
        return [
            'bathroom',
            'kitchen',
            'laundry_room',
            'restroom',
            'shower_room'
        ]
    },

    getPiecesSejourEtService(state, getters) {
        let pieces_sejour_et_service = []
        let pieces_sejour_et_service_codes
            = getters['getPiecesSejourEtServiceOptions']

        for (let i in state.items) {
            if (pieces_sejour_et_service_codes.includes(state.items[i].type)) {
                pieces_sejour_et_service.push(state.items[i])
            }
        }

        return pieces_sejour_et_service
    },

    getPiecesSejourEtServiceOptions(state, getters) {
        let pieces_de_sejour_codes = getters['getPiecesDeSejour']
        let pieces_de_services_codes = getters['getPiecesDeServices']

        return pieces_de_sejour_codes.concat(pieces_de_services_codes)
    },

    getRaccordementInternet(state, getters) {
        let raccordement_internet = []
        let raccordement_internet_codes
            = getters['getRaccordementInternetOptions']

        for (let i in state.items) {
            if (raccordement_internet_codes.includes(state.items[i].type)) {
                raccordement_internet.push(state.items[i])
            }
        }

        return raccordement_internet
    },

    getRaccordementInternetOptions(state) {
        return [
            'asymmetric_digital_subscriber_line',
            'optical_fiber'
        ]
    },

    getRaccordementTelevision(state, getters) {
        let raccordement_television = []
        let raccordement_television_codes
            = getters['getRaccordementTelevisionOptions']

        for (let i in state.items) {
            if (raccordement_television_codes.includes(state.items[i].type)) {
                raccordement_television.push(state.items[i])
            }
        }

        return raccordement_television
    },

    getRaccordementTelevisionOptions(state) {
        return [
            'cable',
            'digital_terrestrial_television',
            'satellite'
        ]
    },

    getTypeDeProductionChauffage(state) {
        return state.type_de_production_chauffage
    },

    getTypeDeProductionChauffageOptions(state) {
        return [
            'common',
            'private',
            'central',
            'empty_heater'
        ]
    },

    getTypeDeProductionEauChaude(state) {
        return state.type_de_production_eau_chaude
    },

    getTypeDeProductionEauChaudeOptions(state) {
        return [
            'common',
            'private',
            'central'
        ]
    },

    getDpeConsumption(state) {
        return state.dpe.consumption
    },

    getDpeEmission(state) {
        return state.dpe.emission
    },

    getDpeLetter(state) {
        return state.dpe.letter
    },

    getDpeDeliveryDate(state) {
        return state.dpe.delivery_date
    },

    getDpeExpirationDate(state) {
        return state.dpe.expiration_date
    },

    isDwellingTypeEqualFlatOrHouse(state, getters) {
        return getters['getDwellingType'].includes('house') || getters['getDwellingType'].includes('flat')
    }
}
