<template>

    <div :class="['step-form', code, { 'active': isActiveStep }]" v-if="!hidden">

        <form-input :onSubmit="validateAndSaveStep" v-slot="{ reset, fields, failed}" :id="code" v-if="isStepFormVisible" @invalid="switchStep" ref="form">

            <div class="step-content">

                <div class="header">
                    
                    <div class="title-group">

                        <span class="completion-icon">{{ stepIndex + 1 }}</span>

                        <h2 class="title" @click="switchStep">{{ name }}</h2>

                        <status-tag class="status-tag" type="notification" v-if="notification && isActiveStep">{{ notification }}</status-tag>

                    </div>

                    <h3 class="subtitle" v-if="subTitle">{{ subTitle }}</h3>

                    <step-progression :class="['progression', { 'completed': status }]" :fieldsBag="fields" :code="code" :failed="failed" ref="completion" v-if="isCreateMode || isUpdateMode" />

                </div>

                <form-info :text="beforeNextStepError" type="error" v-if="beforeNextStepError && isActiveStep" />

                <step-resume class="resume" :resume="resume" ref="resume" v-show="!isActiveStep && status" />

                <div class="form-active" v-if="isActiveStep">

                    <slot />
                    
                </div>

            </div>

            <div :class="['button-group', 'edit-button-group', { 'only-submit': options.submit && !options.cancel }]" v-if="isEditMode && !noAction">

                <button-input class="button background-transparent grey-text-color grey-light-border-color" :text="options.cancel.label" @click="options.cancel.action.call()" v-if="options.cancel" />

                <button-input class="button save background-success grey-darker-text-color icon-reverse" :iconImg="options.submit.icon" :text="options.submit.label" type="submit" :loading="stepFormLoader || loading" :disabled="options.submit.disableButton" />

            </div>
                
            <div :class="['button-group', { 'collapsed': !isActiveStep }]" v-else-if="isActiveStep && !noAction">
                
                <button-input class="button background-transparent grey-text-color grey-light-border-color" :text="$t('cancel_updates')" @click="cancelUpdates(reset)" />

                <button-input class="button next background-success success-text-color" :text="isCreateMode ? $t('next') : $t('update')" type="submit" :loading="stepFormLoader" :disabled="options.submit.disableButton" />

            </div>

        </form-input>

    </div>

</template>

<script>
    import { mapGetters } from 'vuex'
    import FormInfo from '~/components/form-info'
    import StepProgression from '~/components/workflow/step-progression'
    import StepResume from '~/components/workflow/step-resume'
    import StatusTag from '~/components/status-tag'

    export default {
        components: { 
            StepProgression, 
            StepResume, 
            FormInfo,
            StatusTag
        },
         
        props: {
            name: {
                type: String,
                required: true
            },
            subTitle: {
                type: String,
                required: false
            },
            code: {
                type: String,
                required: true
            },
            resume: {
                type: String,
                default: ''
            },
            beforeNextStep: {
                type: Function,
                default() {
                    return function() {}
                }
            },
            notification: {
                type: String,
                required: false
            },
            noAction: {
                type: Boolean,
                default: false
            },
            hidden: {
                type: Boolean,
                default: false
            },
            options: {
                type: Object,
                default() {
                    return {
                        submit: {
                            label: this.$t('save'),
                            icon: ''
                        }
                    }
                }
            }
        },

        data() {
            return {
                stepFormLoader: false,
                beforeNextStepError: ''
            }
        },

        async fetch() {
            const { store } = this.$nuxt.context

            // Add step form to store
            store.commit('workflow/addOrUpdateStep', {
                active: false,
                complete: false,
                name: this.code,
                label: this.name,
                hidden: this.hidden
            })

            if (this.isUpdateMode) {
                
                await store.dispatch('workflow/nextStep', this.code)
            }
        },

        methods: {
            async validateAndSaveStep() {
                try {
                
                    this.stepFormLoader = true

                    await this.beforeNextStepFunction.call()
                    
                    if (this.isCreateMode) {
                        
                        this.$store.dispatch('workflow/nextStep', this.code)
                    
                        this.$scrollTo('.step-form.active')
                    
                    } else if (this.isUpdateMode) {

                        this.$store.commit('workflow/setCompletedStep', this.code)

                        this.$store.commit('workflow/setActiveStep', '')
                    
                    } else if (this.isEditMode) {

                        await this.$store.dispatch('workflow/saveAction')
                    }
                
                } catch(error) {

                    this.beforeNextStepError = error
                
                } finally {

                    this.stepFormLoader = false
                }
            },

            async cancelUpdates(reset) {
                
                reset()

                await this.$store.dispatch('workflow/resetInitialValues')
            },

            async beforeNextStepFunction() {
                try {
                
                    this.$store.commit('workflow/setLoading', true)

                    await this.beforeNextStep.call()
                
                } catch(error) {

                    if (typeof error.response !== 'undefined' && typeof error.response.status !== 'undefined') {
                
                        this.$store.commit('workflow/setErrorStatusCode', parseInt(error.response && error.response.status))

                        if (typeof error.response.data !== 'undefined' && typeof error.response.data.errors !== 'undefined') {
                            
                            this.$store.commit('workflow/setErrorFields', error.response.data.errors)

                            this.$store.commit('workflow/setErrorMessage', error.response.data.errors.map(error => error.detail).join('. '))
                        }
                    }

                    throw error
                
                } finally {

                    this.$store.commit('workflow/setLoading', false)
                }
            },

            switchStep() {
                
                this.$store.dispatch('workflow/switchStep', this.code)
            }
        },
        
        computed: {
            ...mapGetters({
                activeStep: 'workflow/getActiveStep',
                completedSteps: 'workflow/getCompletedSteps',
                stepList: 'workflow/getSteps',
                isCreateMode: 'workflow/isCreateMode',
                isEditMode: 'workflow/isEditMode',
                isUpdateMode: 'workflow/isUpdateMode',
                loading: 'workflow/isLoading'
            }),

            status() {
                return (this.completedSteps.map(step => step.name).includes(this.code)) ? true : false
            },

            isStepFormVisible() {
                if (this.isCreateMode || this.isUpdateMode) return true

                else if (this.isEditMode && this.isActiveStep) return true

                return false
            },

            isActiveStep() {
                return this.activeStep === this.code
            },

            stepIndex() {
                return this.stepList.map(step => step.name).indexOf(this.code)
            }
        },

        watch: {
            activeStep: {
                immediate: true,
                handler(newVal) {
                    if (newVal === this.code) {
                        this.$emit('stepFormActive', newVal)
                    }
                }
            },
            hidden: {
                immediate: false,
                handler(newVal) {
                    this.$store.commit('workflow/addOrUpdateStep', { 
                        name: this.code, 
                        hidden: newVal 
                    })
                }
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "cancel_updates": "Annuler les modifications",
            "next": "Suivant",
            "update": "Modifier",
            "save": "Sauvegarder"
        }
    }
</i18n>

<style lang="scss">
    $color-green : #BED000;

    .step-form {

        .completion-icon {
            display: flex;
            position: relative;
            width: 32px;
            height: 32px;
            border: 2px solid $color-green;
            border-radius: 100%;
            align-items: center;
            justify-content: center;
            margin-right: 12px;
            flex-shrink: 0;
            background: $color-green;
        }
    }
        
</style>