<template>

    <form-group class="range-input" :label="label" :name="name" :rules="rules">

        <div class="input">

            <vue-slider v-bind="$attrs" :value="value" @change="emitInput($event)" :min="min" :max="max" :tooltip-formatter="formatter"></vue-slider>

            <div class="marks">

                <div v-text="formatter(min)"></div>

                <div v-text="formatter(max)"></div>

            </div>

        </div>

    </form-group>

</template>

<script>
    import FormGroup from '~/components/inputs/form-group'
    import DropDown from '~/components/drop-down'
    import Arrow from '~/components/arrow'
    import VueSlider from 'vue-slider-component'
    import '~/assets/scss/vue-slider-component.scss'

    export default {
        data() {
            return {
                collapsed: true,
            }
        },

        components: {
            FormGroup,
            DropDown,
            Arrow,
            VueSlider
        },

        props: {
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            value: {
                type: Array,
                required: true
            },
            rules: {
                type: [String, Object],
                default: ''
            },
            min: {
                type: Number,
                required: true,
            },
            max: {
                type: Number,
                required: true,
            },
            formatter: {
                type: Function,
                default: (value) => value,
            },
        },

        methods: {

            emitInput(value) {
                this.$emit('input', value, this.name)
            }

        },
    }
</script>

<i18n>
    {
        "fr": {
            "select_an_option": "Sélectionnez une option"
        }
    }
</i18n>

<style lang="scss">
    $input-background: #EEF0F4;

    .range-input {
        position: relative;

        .input {
            display: flex;
            position: relative;
            background: $input-background;
            border-radius: 4px;
            padding: 14px 30px 2px;
            flex-direction: column;

            .marks {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 3px -4px 8px;

                * {
                    font-size: 9pt;
                }
            }
        }
    }
</style>
