<template>
    
    <widget class="property-contracts-widget" :title="$t('contracts')" :fetch="fetchContracts">

        <template v-slot:widget-options v-if="isManager">

            <button-input class="button background-transparent green-text-color" :text="$t('see_all')" @click="$router.push(`/property/${propertyId}/contracts`)" />

        </template>

        <template v-if="isManager || isLandlord">

            <p class="empty" v-if="!mandateRows.length">{{ $t('empty_document', { document_type: $t('mandates').toLowerCase() }) }}</p>

            <table-list :tHead="headers" :tBodies="mandateRows" :options="tableListOptions" v-else />

        </template>

        <p class="empty" v-if="!propertyRelatedRentals.length">{{ $t('empty_document', { document_type: $t('rentals').toLowerCase() }) }}</p>

        <table-list :tHead="headers" :tBodies="rentalRows" :options="tableListOptions" v-else />

    </widget>

</template>

<script>
    import Widget from '~/components/widget'
    import DocumentItem from '~/components/document-item'
    import ContractNameMixin from '~/components/mixins/contract-name'
    import { mapGetters } from 'vuex'
    
    export default {
        mixins: [ContractNameMixin],
        
        components: {
            Widget,
            DocumentItem
        },

        methods: {
            async fetchContracts() {
                try {
                    
                    const { store } = this.$nuxt.context

                    for (let mandate of this.propertyRelatedMandates) {

                        const file = await store.dispatch('mandate/fetchDocumentRelationship', mandate['_jv'].id)
                        
                        if (Object.keys(file).length && !file.is_signed) {
                            
                            await store.dispatch('file/fetchSignatureRequestRelationship', file['_jv'].id)
                        }
                    }
                    
                    for (let rental of this.propertyRelatedRentals) {
                        
                        const file = await store.dispatch('rental/fetchLeaseAgreementRelationship', rental['_jv'].id)

                        if (Object.keys(file).length && !file.is_signed) {
                            
                            await store.dispatch('file/fetchSignatureRequestRelationship', file['_jv'].id)
                        }
                    }
                
                } catch(error) {

                    throw error
                }
            },

            async getSignatureStatus(fileRelationship) {

                let file = {}, signatureRequest = {}

                if (fileRelationship !== null) {

                    file = await this.$store.dispatch('file/fetchFile', fileRelationship.id)

                    if (Object.keys(file).length && !file.is_signed) {

                        signatureRequest = await this.$store.dispatch('file/fetchSignatureRequestRelationship', file['_jv'].id)
                    }
                }
                
                if (Object.keys(file).length && file.is_signed) return 'complete'

                else if (Object.keys(signatureRequest).length) return signatureRequest.status
                
                return 'not_started'
            },

            getMandateRow(mandate) {
                
                return async () => {
             
                    const badge = await this.getSignatureStatus(mandate['_jv']['relationships']['document']['data'])
                            
                    return {
                        document: {
                            label: this.getContractName(mandate),
                            description: this.$t('start_at', { date: this.$moment(mandate.start_date).format('DD/MM/YYYY') }),
                            icon: 'mandate',
                            badge: badge
                        },
                        actions: {
                            default: () => this.$router.push(`/viewer/mandate/${mandate['_jv'].id}`)
                        }
                    }
                }
            },

            getRentalRow(rental) {

                return async () => {
                
                    const badge = await this.getSignatureStatus(rental['_jv']['relationships']['lease_agreement']['data'])
                            
                    return {
                        document: {
                            label: this.getContractName(rental),
                            description: this.$t('start_at', { date: this.$moment(rental.start_date).format('DD/MM/YYYY') }),
                            icon: 'rental',
                            badge: badge
                        },
                        actions: {
                            default: () => this.$router.push(`/viewer/rental/${rental['_jv'].id}`)
                        }
                    }
                }
            }
        },

        computed: {
            isLandlord() {
                return typeof this.landlord['_jv']['relationships']['user'] !== 'undefined' 
                    && this.landlord['_jv']['relationships']['user']['data'].id === this.authUser['_jv'].id
            },

            headers() {
                return [
                    {
                        field: 'document',
                        type: 'document-item'
                    }
                ]
            },

            tableListOptions() {
                return {
                    displayHeader: false,
                    tableCustomStyle: {
                        'border-spacing': 0
                    },
                    tableHeaderCustomStyle: {
                        border: 0
                    },
                    tableCellCustomStyle: {
                        'height': '65px',
                        'padding': '8px',
                        'background': 'white',
                        'border': 0
                    }
                }
            },

            mandateRows() {
                return this.propertyRelatedMandates.map(mandate => this.getMandateRow(mandate))
            },

            rentalRows() {
                return this.propertyRelatedRentals.map(rental => this.getRentalRow(rental))
            },

            ...mapGetters({
                propertyRelatedMandates: 'property/getRelatedMandates',
                propertyRelatedRentals: 'property/getRelatedRentals',
                propertyId: 'property/getId',
                landlord: 'property/getRelatedLandlord',
                isManager: 'isManager',
                authUser: 'getAuthUser'
            })
        }
    }
</script>

<i18n>
    {
        "fr": {
            "rental": "Contrat de location",
            "mandates": "Mandat de gestion",
            "contracts": "Contrats",
            "see_all": "Voir plus",
            "rentals": "Contrat de location",
            "start_at": "Prend effet le {date}",
            "empty_document": "Aucun {document_type}.",
            "pending_signature": "À signer",
            "no_document": "Pas de document",
            "signed": "Signé",
            "not_started": "Non commencé"
        }
    }
</i18n>

<style lang="scss" scoped>
    .property-contracts-widget {
        .button {
            padding-left: 0;
            padding-right: 0;
        }

        .empty {
            @include body;
            height: 40px;
            display: flex;
            align-items: center;

            & ~ .empty,
            & ~ .table-list {
                margin-top: 15px;
            }
        }

        .table-list ~ .empty {
            margin-top: 15px;
        }
    }
</style>
