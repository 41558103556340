import { AutoSync, OskarLogo, AllLogo, BankLogoSprite, SwanLogo } from '~/components/icons/bank-icons'

export default {
    components: {
        AutoSync,
        OskarLogo,
        AllLogo,
        BankLogoSprite,
        SwanLogo
    },

    data() {
        return {
            bankLogoSprite: BankLogoSprite,
            bankOptions: [
                'bnp_paribas',
                'swan'
            ]
        }
    }
}