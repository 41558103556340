<template>

    <header class="jumbotron">

        <div class="jumbotron-content">

            <breadcrumb class="breadcrumb" :items="breadcrumb" :redirectTo="breadcrumbBack" v-if="breadcrumb && breadcrumb.length" />

            <div class="details-group">

                <div :class="['logo', { 'background': iconPath === 'property' }]" v-if="iconPath">

                    <svg-icon v-if="iconPath === 'property'" width="60" height="60" :viewbox="'0 0 24 24'" iconColor="#393939"><property-default-icon /></svg-icon>

                    <svg-icon v-if="iconPath === 'landlords'" width="62" height="62" iconColor="#393939"><landlord-default-icon /></svg-icon>

                    <svg-icon v-if="iconPath === 'tenants'" width="62" height="62" :viewbox="'0 0 18 18'" iconColor="#393939"><tenant-default-icon /></svg-icon>

                    <svg-icon  v-if="iconPath === 'suppliers'"  width="62" height="62" class="icon" iconColor="#393939" ><supplier-icon /></svg-icon>

                </div>

                <div class="company-logo" v-else>

                    <img class="logo-url" :src="logoImgUrl" v-if="logoImgUrl" />

                    <svg-icon width="40" height="40"  :iconColor="'#BED000'" v-else><property-management-default-icon /></svg-icon>

                </div>

                <div class="description">

                    <div class="principal">

                        <h2 class="title">{{ title }}</h2>

                        <p class="secondary-title" v-html="subTitle" v-if="subTitle"></p>

                    </div>

                    <ul class="tags" v-if="tags">

                        <nuxt-link tag="li" class="tag" :to="tag.redirect_path" no-prefetch v-for="(tag, key) in tags" :key="key">

                            <svg-icon class="icon" width="20" height="20" iconColor="#393939" :viewbox="'0 0 24 24'" v-if="tag.icon"><profile-icon /></svg-icon>

                            <!-- <svg-icon class="icon" width="18" height="20" :viewbox="'0 0 24 24'" v-if="tag.icon"><profile-icon /></svg-icon> -->

                            <span class="text">{{ tag.text }}</span>

                        </nuxt-link>

                    </ul>

                </div>

            </div>

            <main-nav class="header-nav" styleMode="jumbotron" :items="headerNav" v-if="headerNav" />

            <landlord-main-nav class="header-nav" styleMode="jumbotron" v-if="iconPath === 'landlords' && isManager"/>

            <tenant-main-nav class="header-nav" styleMode="jumbotron" v-if="iconPath === 'tenants' && isManager" />

        </div>

    </header>

</template>

<script>
    import Breadcrumb from '~/components/navigation/breadcrumb'
    import SvgIcon from '~/components/svg-icon'
    import ProfileIcon from '~/components/icons/profile/profile-icon'
    import PropertyDefaultIcon from '~/components/icons/property/property-default-icon'
    import LandlordDefaultIcon from '~/components/icons/profile/landlord-default-icon'
    import TenantDefaultIcon from '~/components/icons/profile/tenant-default-icon'
    import PropertyManagementDefaultIcon from '~/components/icons/dashboard/property-management-default-icon'
    import SupplierIcon from '~/components/icons/table-cell-icons/supplier-icon'
    import MainNav from '~/components/navigation/header/main-nav'
    import LandlordMainNav from '~/components/navigation/header/landlord-main-nav'
    import TenantMainNav from '~/components/navigation/header/tenant-main-nav'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            Breadcrumb,
            SvgIcon,
            ProfileIcon,
            PropertyDefaultIcon,
            LandlordDefaultIcon,
            TenantDefaultIcon,
            PropertyManagementDefaultIcon,
            SupplierIcon,
            MainNav,
            LandlordMainNav,
            TenantMainNav
        },

        props: {
            backgroundIllustration: {
                type: String,
                default: ''
            },
            breadcrumb: {
                type: Array,
                required: false
            },
            breadcrumbBack: {
                type: String,
                default: ''
            },
            iconPath: {
                type: String,
                required: false
            },
            title: {
                type: String,
                required: true
            },
            subTitle: {
                type: String,
                required: false
            },
            tags: {
                type: Array,
                required: false
            },
            headerNav: {
                type: Array,
                required: false
            },
            redirectTo: {
                type: String,
                default: ''
            },
            logoImgUrl: {
                type: String,
                required: false
            }
        },

        computed: {
            ...mapGetters({
                isManager: 'isManager'
            })
        }
    }
</script>

<style lang="scss">
    $width: 1180px;
    $background-color: #767676;
    $color-black: #0E0A07;
    $color-dark-grey : #393939;

    .jumbotron {
        // margin: 0 calc(calc(100vw - 100%) / -2);
        position: relative;
        background-repeat: no-repeat;
        background-color: white;
        background-position: bottom right;
        background-size: auto 135%;
        color: #1F1F1F;

        &.background-property-manager-header {
            background: linear-gradient(90deg, #FFF 52%, rgba(255, 255, 255, 0) 100%), url('~/static/img/dashboard/property-management-header-illustration1920x199.jpeg') white;
            background-size: 59%;
            background-position: 100% 59%;
            background-repeat: no-repeat;
        }

        &.background-property-header {
            background: linear-gradient(90deg, #FFF 5%, rgba(255, 255, 255, 0) 100%), url('~/static/img/dashboard/property-header-illustration1920x234.jpeg') white;
            background-size: 30%;
            background-position: 100% 100%;
            background-repeat: no-repeat;
        }

        &.background-landlord-header {
            background: linear-gradient(90deg, #FFF 21.3%, rgba(255, 255, 255, 0) 100%), url('~/static/img/profile/landlord-illustration1920x191.jpeg') no-repeat, white;
            background-size: 51%;
            background-position: 124% 100%;
        }

        &.background-tenant-header {
            background: linear-gradient(90deg, #FFF 9.3%, rgba(255, 255, 255, 0) 100%), url('~/static/img/profile/tenant-illustration1920x191.jpeg') no-repeat, white;
            background-size: 35%;
            background-position: 106% 21%;
        }

        .jumbotron-content {
            max-width: $width;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .breadcrumb {
                margin-top: 21px;
                margin-bottom: -8px;
                flex-basis: 100%;
            }

            .details-group {
                display: flex;
                padding: 38px 0px;
                margin-right: 18px;

                .logo {
                    width: 70px;
                    height: 70px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-shrink: 0;
                    color: #525254;

                    &.background {
                        background-color: white;
                        border-radius: 100%;
                        box-shadow: 0px 7px 16px rgba(141, 141, 141, 0.28), 0px 4px 20px rgba(141, 141, 141, 0.15);
                    }
                }

                .company-logo {
                    width: 80px;
                    height: 80px;
                    background-color: white;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 5px;
                    box-sizing: border-box;
                    box-shadow: 0px 7px 16px rgba(141, 141, 141, 0.28), 0px 4px 20px rgba(141, 141, 141, 0.15);
                    cursor: pointer;

                    .logo-url {
                        width: 100%;
                        max-height: 100%;
                        border-radius: 100%;
                        color: #525254;
                    }
                }

                .description {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: flex-start;
                    margin-left: 25px;

                    .principal {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        color: #1F1F1F;
                        height: 100%;

                        .title {
                            margin-right: 15px;
                            font-size: 24pt;
                            color: #1F1F1F;
                            font-weight: 500;
                        }

                        .secondary-title {
                            margin-top: 4px;
                            font-size: 10.5pt;
                            color: #525254;
                            font-weight: 500;

                            & > * {
                                color: inherit;
                            }
                            span {
                                color: #BED000;
                                margin-top: 4px;
                                font-size: 10.5pt;
                                font-weight: 500;
                            }
                        }
                    }

                    .tags {
                        display: flex;
                        flex-wrap: wrap;
                        margin-top: 7px;

                        .tag {
                            display: flex;
                            flex-direction: row;
                            margin-right: 15px;
                            align-items: center;
                            background: #BED000;
                            border-radius: 65px;
                            color: $color-dark-grey;
                            padding: 9px 15px;
                            cursor: pointer;

                            &:last-child {
                                margin-right: 10px;
                            }

                            .icon {
                                margin-right: 8px;
                            }

                            .text {
                                max-width: 200px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                font-weight: 600;
                                color: $color-dark-grey;
                            }
                        }
                    }
                }
            }

            .header-nav {
                width: $width;
                overflow-x: auto;
                margin: 0 auto;
            }
        }
    }
</style>
