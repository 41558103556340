<template>

    <button-group-footer  class="viewer-button-group">

        <template v-slot:left-panel>

            <button-input class="button background-grey-dark white-text-color" iconImg="print" iconColor="#FFFFFF" :text="$t('print')" :redirectExternalLink="fileUrl" :downloadFileName="fileName" />

        </template>

        <template v-slot:right-panel>

            <slot></slot>

            <template  v-if="isSignable && isManager">

                <share-to-sign-modal ref="shareToSignModal" @submitted="isSubmitted"/>

                <button-input id="onboarding-step-9" class="button background-white success-text-color" :text="$t('share_to_sign')" iconImg="signature" iconColor="#525254" :loading="createSignatureRequestLoader" @click="$refs.shareToSignModal.show()" v-if="signatureStatus === 'not_started'"/>

                <button-input class="button background-delete-light delete-text-color" :text="$t('delete_signature_request')" iconImg="signature" iconColor="#E57373" :loading="deleteLoader" @click="deleteSignatureRequest" v-else-if="signatureStatus === 'ongoing'" />

                <button-input class="button background-success success-text-color" :text="$t('mark_as_signed')" :loading="markAsSignedLoader" @click="markFileAsSigned" v-if="signatureStatus === 'not_started'" />

                <onboarding-user-agent />

            </template>

        </template>

    </button-group-footer>

</template>

<script>
    import ShareToSignModal from '~/components/modal/share-to-sign'
    import ButtonGroupFooter from '~/components/navigation/footer/button-group-footer'
    import ButtonInput from '~/components/inputs/button-input'
    import { mapGetters } from 'vuex'
    import OnboardingUserAgent from '../../onboarding-user-agent.vue'

    export default {
        components: {
            ButtonGroupFooter,
            ButtonInput,
            ShareToSignModal,
            OnboardingUserAgent
        },

        data() {
            return {
                markAsSignedLoader: false,
                createSignatureRequestLoader: false,
                deleteLoader: false
            }
        },
        mounted(){
            this.$store.commit('onboarding/setOnboardingSteps', { step: 9, userId: this.getAuthUser['_jv'].id })
        },
        methods: {
            async markFileAsSigned() {
                try {

                    this.markAsSignedLoader = true

                    let path = this.$store.getters['file/getRedirectPath']

                    await this.$store.dispatch('file/markFileAsSigned')

                    this.$emit('afterSignature', true)

                    return this.$router.push(path)

                } catch(error) {

                    throw error

                } finally {

                    this.markAsSignedLoader = false
                }
            },

            async deleteSignatureRequest() {
                try {

                    this.deleteLoader = true

                    let path = this.$store.getters['file/getRedirectPath']

                    await this.$store.dispatch('file/deleteSignatureRequest')

                    return this.$router.push(path)

                } catch(error) {

                    throw error

                } finally {

                    this.deleteLoader = false
                }
            },

            isSubmitted() {
                this.$emit('submitted')
            }
        },

        computed: {
            ...mapGetters({
                file: 'file/getFile',
                fileUrl: 'file/getUrl',
                fileTypes: 'file/getTypes',
                fileName: 'file/getName',
                signatureStatus: 'file/getSignatureRequestStatus',
                isSignable: 'file/getIsSignable',
                fileIsSigned: 'file/getIsSigned',
                isManager: 'isManager',
                onboardingSteps: 'onboarding/getOnboardingSteps',
                onboardingAgentData: 'onboarding/getOnboardingAgentData',
                getAuthUser: 'getAuthUser'
            })
        }
    }
</script>

<i18n>
    {
        "fr": {
            "print": "Imprimer",
            "cancel": "Annuler la signature",
            "mark_as_signed": "Marquer comme signé",
            "share_to_sign": "Demander signature",
            "delete_signature_request": "Abandonner la signature"
        }
    }
</i18n>
