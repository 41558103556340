<template>
    
    <div class="property-item">

        <div class="icon">
            <svg-icon class="house-icon" viewbox="0 0 24 24" width="24" height="24" iconColor="#525254"><house-icon /></svg-icon>
        </div>

        
        <div class="content-infos">

            <p class="main-info">{{ property.address.formatted }}</p>

            <p class="seccond-info">{{ getPropertyNatureShorthand(property) }}</p>

            <slot></slot>

        </div>

        <svg-icon class="arrow-icon" width="24" height="24" viewBox="0 0 24 24" fill="#525254"><arrow-next-icon/></svg-icon>

    </div>

</template>

<script>
    import PropertyNatureShorthandMixin from '~/components/mixins/property-nature-shorthand'
    import SvgIcon from '~/components/svg-icon'
    import HouseIcon from '~/components/icons/property/house-icon'
    import { ArrowNextIcon } from '~/components/icons/button-icons'

    
    export default {
        mixins: [PropertyNatureShorthandMixin],

        components: {
            SvgIcon,
            HouseIcon,
            ArrowNextIcon
        },

        props: {
            property: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    $couleurs-primaires-light: #F5F5F5;
    .property-item{
        display: flex;
        padding: 12px;
        align-items: center;
        gap: 12px;
        align-self: stretch;
        border-radius: 8px;
        cursor: pointer;

        .icon {
            width: 40px;
            height: 40px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $couleurs-primaires-light;  
            
            .house-icon {
                width: 24px;
                height: 24px;
            }
        }
                        
        &:hover {
            background: $couleurs-primaires-light;
        }

        .content-infos {
            max-width: 220px;
            overflow: hidden;

            @include tablet {
                max-width: fit-content;
            }

            .main-info {
                font-weight: 400;
                color: #393939;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%; 
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .seccond-info {
                white-space: nowrap;
                overflow: hidden;
                color: #767676;
                text-overflow: ellipsis;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;

            }
        }
    }
</style>