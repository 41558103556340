import { utils } from 'jsonapi-vuex'
import { queryBuilder, queryResponse } from '~/utils/query'

export default {
    async createRental({ dispatch, rootGetters }) {
        try {

            let request = await dispatch('formatRentalRequest')

            const rental = await dispatch('tyltoApi/post', [request, { url: 'properties/' + rootGetters['property/getId'] + '/rentals' }], { root: true })

            await dispatch('fillRental', rental)

            return rental

        } catch (error) {

            throw error
        }
    },

    async createLeaseAgreementRelationship({ getters, commit, dispatch, rootGetters }, leaseAgreementFields) {
        try {

            let request = {
                ...leaseAgreementFields,
                _jv: {
                    type: 'files'
                }
            }

            const leaseAgreement = await dispatch('tyltoApi/post', [request, { url: 'rentals/' + getters['getId'] + '/lease-agreement' }], { root: true })

            commit('setRelatedLeaseAgreement', leaseAgreement)

            if (typeof leaseAgreement.fields !== 'undefined') await dispatch('fillLeaseAgreementFields', leaseAgreement.fields)

            return leaseAgreement

        } catch (error) {

            throw error
        }
    },

    async deleteRental({ getters }) {
        try {

            let axiosBody = {
                method: 'delete',
                url: `rentals/${getters['getId']}`
            }

            let { data } = await this.$axios(axiosBody)

            return utils.jsonapiToNorm(data['data'])

        } catch (error) {

            throw error
        }
    },

    async fetchRentalInventories({ dispatch, commit }, { params }) {
        try {

            const data = await dispatch('tyltoApi/get', ['rentals/' + params.rentalId + '/inventories', { params: queryBuilder(params) }], { root: true })

            const { response } = queryResponse(data)

            commit('setRelatedInventories', response)

            return queryResponse(data)

        } catch (error) {

            throw error
        }
    },

    async fetchLeaseAgreementRelationship({ dispatch, getters, commit }, rentalId) {
        try {

            let rentalLeaseAgreement = {}

            const id = rentalId !== undefined ? rentalId : getters['getId']

            const { data } = await this.$axios({ method: 'get', url: `rentals/${id}/relationships/lease_agreement` })

            if (data['data'] !== null) rentalLeaseAgreement = await dispatch('file/fetchFile', data['data']['id'], { root: true })

            commit('setRelatedLeaseAgreement', rentalLeaseAgreement)

            if (typeof rentalLeaseAgreement.fields !== 'undefined') {

                await dispatch('fillLeaseAgreementFields', rentalLeaseAgreement.fields)
            }

            return rentalLeaseAgreement

        } catch (error) {

            throw error
        }
    },

    async fetchRental({ dispatch, rootGetters }, rentalId) {
        try {

            let rental = rootGetters['tyltoApi/get'](`rentals/${rentalId}`)

            if (typeof rental === 'undefined' || !Object.keys(rental).length) {

                rental = await dispatch('tyltoApi/get', 'rentals/' + rentalId, { root: true })
            }

            return rental

        } catch (error) {

            throw error
        }
    },

    async fetchAndFillRental({ dispatch }, rentalId) {
        try {

            let rental = await dispatch('fetchRental', rentalId)

            await dispatch('fillRental', rental)

            return rental

        } catch (error) {

            throw error
        }
    },

    async fetchRentals({ dispatch }, params) {
        try {

            const response = await dispatch('tyltoApi/get', ['rentals', { params: queryBuilder(params) }], { root: true })

            return queryResponse(response)

        } catch (error) {

            throw error
        }
    },

    async fetchTotalRentals({ dispatch }, params) {
        try {

            let rentals = await dispatch('tyltoApi/get', ['rentals', { params: queryBuilder(params) }], { root: true })

            return rentals['_jv']['json']['meta'].total_item_count

        } catch (error) {

            throw error
        }
    },

    async fetchFeesRelationship({ dispatch, commit, getters }, params) {
        try {

            let rentalId = typeof params.rentalId !== 'undefined' ? params.rentalId : getters['getId']

            const data = await dispatch('tyltoApi/get', ['rentals/' + rentalId + '/fees', { params: queryBuilder(params) }], { root: true })

            const { response } = queryResponse(data)

            commit('setRelatedFees', response)

            return queryResponse(data)

        } catch (error) {

            throw error
        }
    },

    async fetchPropertyRentals({ dispatch }, { params, page, filters }) {
        try {

            const response = await dispatch('tyltoApi/get', ['properties/' + params.propertyId + '/rentals', { params: queryBuilder({ page, filters }) }], { root: true })

            return queryResponse(response)

        } catch (error) {

            throw error
        }
    },

    async fetchPropertyManagerRentals({ dispatch, rootGetters }, { page, filters }) {
        try {

            const response = await dispatch('tyltoApi/get', ['property-management-companies/' + rootGetters['property-manager/getId'] + '/rentals', { params: queryBuilder({ page, filters }) }], { root: true })

            return queryResponse(response)

        } catch (error) {

            throw error
        }
    },

    async fetchPropertyRelationship({ dispatch, getters, commit }, rentalId) {
        try {

            let rentalProperty = {}

            const id = rentalId !== undefined ? rentalId : getters['getId']

            const { data } = await this.$axios({ method: 'get', url: `rentals/${id}/relationships/property` })

            if (data['data'] !== null) rentalProperty = await dispatch('property/fetchProperty', data['data']['id'], { root: true })

            commit('setRelatedProperty', rentalProperty)

            return rentalProperty

        } catch (error) {

            throw error
        }
    },

    async fetchLeaseAgreementAnnexesRelationship({ dispatch, getters, commit }, rentalId) {
        try {

            let rentalLeaseAgreementAnnexes = []

            if (getters['getRelatedLeaseAgreementAnnexes'].length) rentalLeaseAgreementAnnexes = getters['getRelatedLeaseAgreementAnnexes']

            else {

                const id = getters['getId'] !== '' ? getters['getId'] : rentalId

                const { data } = await this.$axios({ method: 'get', url: `rentals/${id}/relationships/lease_agreement_annexes` })

                rentalLeaseAgreementAnnexes = await Promise.all(data['data'].map(relationship => dispatch('file/fetchFile', relationship.id, { root: true })))
            }

            commit('setRelatedLeaseAgreementAnnexes', rentalLeaseAgreementAnnexes)

            return rentalLeaseAgreementAnnexes

        } catch (error) {

            throw error
        }
    },

    async fetchTenantsRelationship({ dispatch, getters, commit }, rentalId) {
        try {

            let rentalTenants = []

            const id = rentalId !== undefined ? rentalId : getters['getId']

            const { data } = await this.$axios({ method: 'get', url: `rentals/${id}/relationships/tenants` })

            rentalTenants = await Promise.all(data['data'].map(relationship => dispatch('profile/fetchTenant', relationship.id, { root: true })))

            commit('setRelatedTenants', rentalTenants)

            return rentalTenants

        } catch (error) {

            throw error
        }
    },

    async fetchAppliedFeesRelationship({ dispatch, getters, commit }, { params }) {
        try {

            let appliedFees = []

            if (Object.keys(getters['getRelatedAppliedFees']).length) appliedFees = getters['getRelatedAppliedFees']

            else {

                const id = typeof params.rentalId !== 'undefined' ? params.rentalId : getters['getId']

                const { data } = await this.$axios({ method: 'get', url: `rentals/${id}/relationships/applied_fees` })

                appliedFees = await Promise.all(data['data'].map(relationship => dispatch('fees/fetchFee', relationship.id, { root: true })))
            }

            commit('setRelatedAppliedFees', appliedFees)

            return appliedFees

        } catch (error) {

            throw error
        }
    },

    async fetchUpcomingRentsRelationship({ dispatch, getters, commit }, rentalId) {
        try {

            let upcomingRents = []

            if (Object.keys(getters['getRelatedUpcomingRents']).length) upcomingRents = getters['getRelatedUpcomingRents']

            else {

                const id = getters['getId'] !== '' ? getters['getId'] : rentalId

                const { data } = await this.$axios({ method: 'get', url: `rentals/${id}/relationships/upcoming_rents` })

                upcomingRents = await Promise.all(data['data'].map(relationship => dispatch('rent/fetchRent', relationship.id, { root: true })))
            }

            commit('setRelatedUpcomingRents', upcomingRents)

            return upcomingRents

        } catch (error) {

            throw error
        }
    },

    async fetchSecurityDepositRelationship({ dispatch, getters, commit }, rentalId) {
        try {

            let rentalSecurityDeposit = {}

            const id = rentalId

            const { data } = await this.$axios({ method: 'get', url: `rentals/${id}/relationships/security_deposit` })

            if (data['data'] !== null) {
                rentalSecurityDeposit = await dispatch('tyltoApi/get', 'security-deposits/' + data['data'].id, { root: true })
            }

            commit('setRelatedSecurityDeposit', rentalSecurityDeposit)

            return rentalSecurityDeposit

        } catch (error) {

            throw error
        }
    },

    async fetchInseeIrls({ dispatch, commit }, params) {
        try {

            const data = await dispatch('tyltoApi/get', ['insee-irls', { params: queryBuilder(params) }], { root: true })

            return queryResponse(data)

        } catch (error) {

            throw error
        }
    },

    async fillLeaseAgreementFields({ commit }, fields) {
        commit('resetDefaultFieldsState')

        if (typeof fields['increased_reference_rent_amount'] !== 'undefined')
            commit('setLeaseAgreementFieldsIncreasedReferenceRentAmount', fields['increased_reference_rent_amount'])

        if (typeof fields['improvement_or_compliance_work'] !== 'undefined') {
            if (typeof fields['improvement_or_compliance_work']['amount'] !== 'undefined')
                commit('setLeaseAgreementFieldsImprovementOrComplianceWorkAmount', fields['improvement_or_compliance_work']['amount'])

            if (typeof fields['improvement_or_compliance_work']['description'] !== 'undefined')
                commit('setLeaseAgreementFieldsImprovementOrComplianceWorkDescription', fields['improvement_or_compliance_work']['description'])
        }

        if (typeof fields['is_furnished'] !== 'undefined')
            commit('setLeaseAgreementFieldsIsFurnished', fields['is_furnished'])

        if (typeof fields['last_rent'] !== 'undefined') {
            commit('setLeaseAgreementFieldsLastRentAdjustmentDate', fields['last_rent']['adjustment_date'])

            commit('setLeaseAgreementFieldsLastRentAmount', fields['last_rent']['amount'])

            commit('setLeaseAgreementFieldsLastRentPaymentDate', fields['last_rent']['payment_date'])
        }

        if (typeof fields['mobility_lease_justification'] !== 'undefined')
            commit('setLeaseAgreementFieldsMobilityLeaseJustification', fields['mobility_lease_justification'])

        if (typeof fields['other_specific_clauses'] !== 'undefined')
            commit('setLeaseAgreementFieldsOtherSpecificClauses', fields['other_specific_clauses'])

        if (typeof fields['reference_rent_amount'] !== 'undefined')
            commit('setLeaseAgreementFieldsReferenceRentAmount', fields['reference_rent_amount'])

        if (typeof fields['rent_supplement_reason'] !== 'undefined')
            commit('setLeaseAgreementFieldsRentSupplementReason', fields['rent_supplement_reason'])

        if (typeof fields['roomates_common_areas_description'] !== 'undefined')
            commit('setLeaseAgreementFieldsRoomatesCommonAreasDescription', fields['roomates_common_areas_description'])

        if (typeof fields['sharing_type'] !== 'undefined')
            commit('setLeaseAgreementFieldsSharingType', fields['sharing_type'])

        if (typeof fields['shorter_term_reason'] !== 'undefined')
            commit('setLeaseAgreementFieldsShorterTermReason', fields['shorter_term_reason'])

        if (typeof fields['tenant_private_areas_description'] !== 'undefined')
            commit('setLeaseAgreementFieldsTenantPrivateAreasDescription', fields['tenant_private_areas_description'])

        if (typeof fields['type'] !== 'undefined')
            commit('setLeaseAgreementFieldsType', fields['type'])

        if (typeof fields['premises_use'] !== 'undefined')
            commit('setLeaseAgreementFieldsPremisesUse', fields['premises_use'])
    },

    async fillRental({ commit }, rental) {
        commit('resetDefaultState')

        if (typeof rental['items'] !== 'undefined') {
            commit('setItems', rental['items']);
            let hasVatRate = false;
            rental['items'].forEach(item => {
                hasVatRate |= item.vat_rate > 0;
            });
            hasVatRate = !!hasVatRate;
            commit('setHasVatRate', hasVatRate);
        }




        if (typeof rental['rent_adjustment_date'] !== 'undefined')
            commit('setRentAdjustmentDate', rental['rent_adjustment_date'])

        if (typeof rental['rent_reference_index_reference_quarter'] !== 'undefined')
            commit('setRentReferenceIndexReferenceQuarter', rental['rent_reference_index_reference_quarter'])

        if (typeof rental['rent_payment_due_date'] !== 'undefined')
            commit('setRentPaymentDueDate', rental['rent_payment_due_date'])

        if (typeof rental['start_date'] !== 'undefined')
            commit('setStartDate', rental['start_date'])

        if (typeof rental['end_date'] !== 'undefined')
            commit('setEndDate', rental['end_date'])

        if (typeof rental['status'] !== 'undefined')
            commit('setStatus', rental['status'])

        if (typeof rental['security_deposit'] !== 'undefined') {

            if (typeof rental['security_deposit']['collection_date'] !== 'undefined')
                commit('setSecurityDepositCollectionDate', rental['security_deposit']['collection_date'])

            if (typeof rental['security_deposit']['is_hold'] !== 'undefined')
                commit('setSecurityDepositIsHold', rental['security_deposit']['is_hold'])

            if (typeof rental['security_deposit']['amount'] !== 'undefined')
                commit('setSecurityDepositAmount', rental['security_deposit']['amount'])
        }

        if (typeof rental['term'] !== 'undefined')
            commit('setTerm', rental['term'])

        if (typeof rental['_jv'] !== 'undefined')
            commit('setId', rental['_jv'].id)

        if (typeof rental['lease_agreement_annexes'] !== 'undefined' && Object.keys(rental['lease_agreement_annexes']).length)
            commit('setRelatedLeaseAgreementAnnexes', rental['lease_agreement_annexes'])

        if (typeof rental['applied_fees'] !== 'undefined' && Object.values(rental['applied_fees']).filter(item => Object.keys(item).length).length === rental['_jv']['relationships']['applied_fees']['data'].length)
            commit('setRelatedAppliedFees', Object.values(rental['applied_fees']))

        if (typeof rental['lease_agreement'] !== 'undefined' && Object.keys(rental['lease_agreement']).length)
            commit('setRelatedLeaseAgreement', rental['lease_agreement'])

        if (typeof rental['property'] !== 'undefined' && Object.keys(rental['property']).length)
            commit('setRelatedProperty', rental['property'])

        if (typeof rental['tenants'] !== 'undefined' && Object.values(rental['tenants']).filter(tenant => Object.keys(tenant).length).length === rental['_jv']['relationships']['tenants']['data'].length)
            commit('setRelatedTenants', Object.values(rental['tenants']))

        if (typeof rental['inventories'] !== 'undefined' && Object.values(rental['inventories']).filter(inventory => Object.keys(inventory).length).length === rental['_jv']['relationships']['inventories']['data'].length)
            commit('setRelatedInventories', Object.values(rental['inventories']))
    },

    async formatRentalRequest({ getters, rootGetters }) {
        let rental = {
            start_date: getters['getStartDate'],
            term: getters['getTerm'],
            _jv: {
                type: 'rentals',
                relationships: {
                    tenants: {
                        data: getters['getRelatedTenants'].map(tenant => {
                            return {
                                type: 'tenants',
                                id: tenant['_jv'].id
                            }
                        })
                    }
                }
            }
        }

        if (getters['getEndDate'] !== '')
            rental['end_date'] = getters['getEndDate']

        if (getters['getRentAdjustmentDate'] !== '')
            rental['rent_adjustment_date'] = getters['getRentAdjustmentDate']

        if (getters['getRentReferenceIndexReferenceQuarter'] !== '')
            rental['rent_reference_index_reference_quarter'] = getters['getRentReferenceIndexReferenceQuarter']

        if (getters['getItems'].length)
            rental['items'] = getters['getItems']

        if (getters['getStatus'] !== '')
            rental['status'] = getters['getStatus']

        if (getters['getCreateFeesLandlordsShare'] !== null)
            rental['create_fees_landlord_share'] = getters['getCreateFeesLandlordsShare']

        if (getters['getCreateFeesTenantsShare'] !== null)
            rental['create_fees_tenant_share'] = getters['getCreateFeesTenantsShare']

        let securityDeposit = {}

        if (getters['getSecurityDepositCollectionDate'] !== '')
            securityDeposit['collection_date'] = getters['getSecurityDepositCollectionDate']

        if (getters['getSecurityDepositAmount'] > 0)
            securityDeposit['amount'] = getters['getSecurityDepositAmount']

        if (getters['getSecurityDepositIsAlreadyPaid'] !== null)
            securityDeposit['is_already_paid'] = getters['getSecurityDepositIsAlreadyPaid']

        if (getters['getSecurityDepositIsHold'] !== null)
            securityDeposit['is_hold'] = getters['getSecurityDepositIsHold']

        if (Object.keys(securityDeposit).length && getters['getId'] === '')
            rental['security_deposit'] = securityDeposit

        if (getters['getRentPaymentDueDate'] !== '')
            rental['rent_payment_due_date'] = getters['getRentPaymentDueDate']

        if (getters['getId'] !== '')
            rental['_jv'].id = getters['getId']


        if (getters['getLeaseType'] !== '') {
            rental['lease_type'] = getters['getLeaseType']
        }

        if (getters['getIsFurnished'] !== '') {
            rental['is_furnished'] = getters['getIsFurnished']
        }

        if (getters['getIsShared'] !== '') {
            rental['is_shared'] = getters['getIsShared']
        }


        if (getters['getRelatedFees'].length) {
            rental['_jv']['relationships'].fees = {
                data: getters['getRelatedFees'].map(fee => {
                    return {
                        type: 'fees',
                        id: fee['_jv'].id
                    }
                })
            }
        }

        if (getters['getRelatedLeaseAgreementAnnexes'].length) {
            rental['_jv']['relationships'].lease_agreement_annexes = {
                data: getters['getRelatedLeaseAgreementAnnexes'].map(annex => {
                    return {
                        type: 'files',
                        id: annex['_jv'].id
                    }
                })
            }
        }

        if (Object.keys(getters['getRelatedLeaseAgreement']).length) {
            rental['_jv']['relationships'].lease_agreement = {
                data: {
                    type: 'files',
                    id: getters['getRelatedLeaseAgreement']['_jv'].id
                }
            }
        }

        if (Object.keys(getters['getRelatedPaymentMethod']).length) {
            rental['_jv']['relationships'].payment_method = {
                data: {
                    type: 'payment-methods',
                    id: getters['getRelatedPaymentMethod']['_jv'].id
                }
            }
        }

        return rental
    },

    async formatLeaseAgreementFields({ getters }) {
        let fields = {
            is_furnished: getters['getLeaseAgreementFieldsIsFurnished']
        }

        if (getters['getLeaseAgreementFieldsIncreasedReferenceRentAmount'] > 0)
            fields['increased_reference_rent_amount'] = getters['getLeaseAgreementFieldsIncreasedReferenceRentAmount']


        if (getters['getLeaseAgreementFieldsImprovementOrComplianceWorkAmount'] !== 0) {
            fields['improvement_or_compliance_work'] = {
                amount: getters['getLeaseAgreementFieldsImprovementOrComplianceWorkAmount']
            }

            if (getters['getLeaseAgreementFieldsImprovementOrComplianceWorkDescription'] !== '')
                fields['improvement_or_compliance_work']['description'] = getters['getLeaseAgreementFieldsImprovementOrComplianceWorkDescription']
        }

        if (getters['getLeaseAgreementFieldsLastRentAdjustmentDate'] !== '' || getters['getLeaseAgreementFieldsLastRentAmount'] > 0 || getters['getLeaseAgreementFieldsLastRentPaymentDate'] !== '') {
            fields['last_rent'] = {
                adjustment_date: getters['getLeaseAgreementFieldsLastRentAdjustmentDate'],
                amount: getters['getLeaseAgreementFieldsLastRentAmount'],
                payment_date: getters['getLeaseAgreementFieldsLastRentPaymentDate']
            }
        }

        if (getters['getLeaseAgreementFieldsMobilityLeaseJustification'] !== '')
            fields['mobility_lease_justification'] = getters['getLeaseAgreementFieldsMobilityLeaseJustification']

        if (getters['getLeaseAgreementFieldsOtherSpecificClauses'].length)
            fields['other_specific_clauses'] = getters['getLeaseAgreementFieldsOtherSpecificClauses']

        if (getters['getLeaseAgreementFieldsReferenceRentAmount'] > 0)
            fields['reference_rent_amount'] = getters['getLeaseAgreementFieldsReferenceRentAmount']

        if (getters['getLeaseAgreementFieldsRentSupplementReason'] !== '')
            fields['rent_supplement_reason'] = getters['getLeaseAgreementFieldsRentSupplementReason']

        if (getters['getLeaseAgreementFieldsRoomatesCommonAreasDescription'] !== '')
            fields['roomates_common_areas_description'] = getters['getLeaseAgreementFieldsRoomatesCommonAreasDescription']

        if (getters['getLeaseAgreementFieldsSharingType'] !== '')
            fields['sharing_type'] = getters['getLeaseAgreementFieldsSharingType']

        if (getters['getLeaseAgreementFieldsShorterTermReason'] !== '')
            fields['shorter_term_reason'] = getters['getLeaseAgreementFieldsShorterTermReason']

        if (getters['getLeaseAgreementFieldsTenantPrivateAreasDescription'] !== '')
            fields['tenant_private_areas_description'] = getters['getLeaseAgreementFieldsTenantPrivateAreasDescription']

        if (getters['getLeaseAgreementFieldsType'] !== '')
            fields['type'] = getters['getLeaseAgreementFieldsType']

        if (getters['getLeaseAgreementFieldsPremisesUse'] !== '')
            fields['premises_use'] = getters['getLeaseAgreementFieldsPremisesUse']

        return fields
    },

    async updateLeaseAgreementRelationship({ getters, commit }) {
        try {

            let leaseAgreement = {
                data: null
            }

            if (Object.keys(getters['getRelatedLeaseAgreement']).length) {
                leaseAgreement['data'] = {
                    type: 'files',
                    id: getters['getRelatedLeaseAgreement']['_jv'].id
                }
            }

            const response = await this.$axios({ method: 'patch', url: 'rentals/' + getters['getId'] + '/relationships/lease_agreement', data: leaseAgreement })

            commit('tyltoApi/deleteRecord', { _jv: { type: 'rentals', id: getters['getId'] } }, { root: true })

            return response

        } catch (error) {

            throw error
        }
    },

    async updateLeaseAgreementAnnexesRelationship({ getters, commit }) {
        try {

            let leaseAgreementAnnexes = {
                data: []
            }

            if (getters['getRelatedLeaseAgreementAnnexes'].length) {
                leaseAgreementAnnexes['data'] = getters['getRelatedLeaseAgreementAnnexes'].map(annex => {
                    return {
                        type: 'files',
                        id: annex['_jv'].id
                    }
                })
            }

            const response = await this.$axios({ method: 'patch', url: 'rentals/' + getters['getId'] + '/relationships/lease_agreement_annexes', data: leaseAgreementAnnexes })

            commit('tyltoApi/deleteRecord', { _jv: { type: 'rentals', id: getters['getId'] } }, { root: true })

            return response

        } catch (error) {

            throw error
        }
    },

    async updateTenantsRelationship({ getters, commit }) {
        try {

            let rentalTenants = {
                data: []
            }

            if (getters['getRelatedTenants'].length) {
                rentalTenants['data'] = getters['getRelatedTenants'].map(tenant => {
                    return {
                        type: 'tenants',
                        id: tenant['_jv'].id
                    }
                })
            }

            const response = await this.$axios({ method: 'patch', url: 'rentals/' + getters['getId'] + '/relationships/tenants', data: rentalTenants })

            commit('tyltoApi/deleteRecord', { _jv: { type: 'rentals', id: getters['getId'] } }, { root: true })

            return response

        } catch (error) {

            throw error
        }
    },

    async updateRental({ dispatch, getters }) {
        try {

            let request = await dispatch('formatRentalRequest')

            let rental = await this.$axios({ method: 'patch', url: `/rentals/${getters['getId']}`, data: utils.normToJsonapi(request) })

            rental = utils.jsonapiToNorm(rental.data?.data)

            await dispatch('fillRental', rental)

            return rental

        } catch (error) {

            throw error
        }
    },

    async cancelRental({ dispatch, getters }) {
        const request = {
            end_date: getters['getEndDate'],
            _jv: {
                id: getters['getId'],
                type: 'rentals'
            }
        }

        let rental = await this.$axios({ method: 'patch', url: `/rentals/${getters['getId']}`, data: utils.normToJsonapi(request) })

        rental = utils.jsonapiToNorm(rental.data?.data)

        await dispatch('fillRental', rental)

        return rental
    }
}
