export default {
    getAmount(state) {
        return state.amount
    },

    getDate(state) {
        return state.date
    },

    getDescription(state) {
        return state.description
    },

    getId(state) {
        return state.id
    },

    getInvoiceNumber(state) {
        return state.invoice_number
    },

    getItems(state) {
        return state.items
    },

    getPayoutDate(state) {
        return state.payout_date
    },

    getVatAmount(state) {
        return state.vat_amount
    },

    getPayoutReservedAt(state) {
        return state.payout_reserved_at
    },

    getRelatedInvoice(state) {
        return state.relationships.invoice
    },

    getRelatedSupplier(state) {
        return state.relationships.supplier
    }
}