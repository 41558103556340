<template>

    <form-group class="radio-input" :label="label" :name="name" :rules="rules" v-slot="{ errors }">

        <div class="option-list">

            <input type="text" :value="value" v-show="false" />

            <div :class="['option', { 'icon-mode': icons }, { 'selected': option.value === value }, { 'errors': errors.length }]" v-for="(option, key) in options" :key="key" @click="$emit('input', option.value)">

                <template v-if="icons">
                
                    <svg-icon width="40" height="40" viewBox="0 0 40 40" :iconColor="isSelected('flat')" v-if="option.value === 'flat'"><appartement-icon /></svg-icon>
                    
                    <svg-icon width="58" height="56" :iconColor="isSelected('house')" v-if="option.value === 'house'" :viewbox="'0 0 40 40'"><maison-icon /></svg-icon>
                    
                    <svg-icon width="58" height="56" viewBox="0 0 40 40" :iconColor="isSelected('parking')" v-if="option.value === 'parking'"><parking-icon /></svg-icon>
                    
                    <svg-icon width="58" height="56" :iconColor="isSelected('private')" v-if="option.value === 'private'" :viewbox="'0 0 24 24'"><individuel-icon /></svg-icon>
                    
                    <svg-icon width="58" height="56" :iconColor="isSelected('shared')" v-if="option.value === 'shared'" :viewbox="'0 0 15 18'"><collectif-icon /></svg-icon>
                    
                    <svg-icon width="40" height="40" :iconColor="isSelected('dwelling')" v-if="option.value === 'dwelling'" :viewbox="'0 0 40 40'"><habitation-icon /></svg-icon>
                    
                    <svg-icon width="40" height="40" :iconColor="isSelected('mixed')" v-if="option.value === 'mixed'" :viewbox="'0 0 40 40'"><mixte-icon /></svg-icon>

                    <svg-icon width="40" height="40" viewBox="0 0 24 24" :iconColor="isSelected('individual')" v-if="option.value === 'individual'"><natural-person-icon /></svg-icon>

                    <svg-icon width="40" height="40" viewBox="0 0 24 24" :iconColor="isSelected('company')" v-if="option.value === 'company'"><juridical-person-icon /></svg-icon>

                    <svg-icon width="40" height="40" viewBox="0 0 24 24" :iconColor="isSelected('couple')" v-if="option.value === 'couple'"><couple-icon /></svg-icon>

                    <svg-icon width="40" height="40" viewBox="0 0 24 24" :iconColor="isSelected('indivision')" v-if="option.value === 'indivision'"><indivision-icon /></svg-icon>
                    
                    <svg-icon width="40" height="40" viewBox="0 0 24 24" :iconColor="isSelected('business_premises')" v-if="option.value === 'business_premises'"><business-premises-icon /></svg-icon>

                </template>

                <svg-icon width="50" height="50" :iconColor="isSelected('bad')" v-if="option.value === 'bad'" :viewBox="'0 0 96 96'"><bad-icon /></svg-icon>

                <svg-icon width="50" height="50" :iconColor="isSelected('fair')" v-if="option.value === 'fair'" :viewBox="'0 0 96 96'"><fair-icon /></svg-icon>

                <svg-icon width="50" height="50" :iconColor="isSelected('good')" v-if="option.value === 'good'" :viewBox="'0 0 96 96'"><good-icon /></svg-icon>

                <svg-icon width="50" height="50" :iconColor="isSelected('excellent')" v-if="option.value === 'excellent'" :viewBox="'0 0 96 105'"><excellent-icon /></svg-icon>

                <div class="checked-icon" v-if="icons"></div>

                <label class="option-name" :for="option.value">{{ formatted(option.text) }}</label>

                <span :class="['checkmark', { 'checked': value===option.value }]"></span>

            </div>

        </div>

    </form-group>

</template>

<script>
    import SvgIcon from '~/components/svg-icon'
    import { AppartementIcon, MaisonIcon, ParkingIcon, IndividuelIcon, CollectifIcon, HabitationIcon, MixteIcon, BadIcon, FairIcon, GoodIcon, ExcellentIcon, NaturalPersonIcon, JuridicalPersonIcon, CoupleIcon, IndivisionIcon, BusinessPremisesIcon } from '~/components/icons/radio-input-icons'
    import FormGroup from '~/components/inputs/form-group'

    export default {   
        components: { 
            FormGroup, 
            SvgIcon,
            AppartementIcon,
            MaisonIcon,
            ParkingIcon,
            IndividuelIcon,
            CollectifIcon,
            HabitationIcon,
            MixteIcon,
            BadIcon,
            FairIcon,
            GoodIcon,
            ExcellentIcon,
            NaturalPersonIcon,
            JuridicalPersonIcon,
            CoupleIcon,
            IndivisionIcon,
            BusinessPremisesIcon
        },
        
        props: {
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            options: {
                type: Array,
                required: true,
                validator: function(options) {
                    return options.filter(option => typeof option.text !== 'undefined' && typeof option.value !== 'undefined').length > 0
                }
            },
            value: {
                type: [String, Boolean],
                required: true
            },
            rules: {
                type: [String, Object],
                default: ''
            },
            icons: {
                type: Boolean,
                default: false
            }
        },

        methods: {
            formatted(option) {
                return option.charAt(0).toUpperCase() + option.slice(1)
            },

            isSelected(option) {
                if(this.value === option) {
                    return '#BED000'
                } else return '#525254'
            },
            
            iconShift(option) {
                return this.iconSet.indexOf(option) + 1
            }
        },

        computed: {
            iconTotalWidth() {
                return this.iconDefaultWidth * this.iconSet.length * this.dpi / 100
            },
            
            iconTotalHeight() {
                return this.iconDefaultHeight * this.dpi / 100
            }
        }
    }
</script>

<style lang="scss">
    $radio-button-border-color: #DEE3EF;
    $radio-button-border-color-cheked: #BED000;
    $icon-background: #efefeF;
    $icon-toggle-color:#EFEFEF;
    $icon-toggle-size: 28px;
    $icon-toggle-background: #18C95D;
    
    .radio-input {
        .option-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .option {
                position: relative;
                cursor: pointer;
                user-select: none;
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-end;
                margin-bottom: 5px;
                margin-right: 30px;
                align-items: center;
                flex-basis: 100px;

                &.errors {
                    .checkmark {
                        background-color: #FBE8E8;
                        border-color: #F5A623;
                    } 
                }

                // &:last-child {
                //     margin-bottom: 0;
                // }

                .option-name {
                    font-size: 11pt;
                    cursor: pointer;
                }

                input[type='radio'] {
                    position: absolute;
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }
                
                
                svg {
                    border: 2px solid $icon-background;
                    background: $icon-background;
                    border-radius: 55px;
                    height: 56px;
                    width: 56px;
                    padding: 10px;
                    box-shadow: 0px 7px 9px rgba(141, 141, 141, 0.1), 0px 4px 13px rgba(141, 141, 141, 0.08);
                    margin-bottom: 8px;
                }
                
                &.icon-mode {
                    display: flex;
                    flex-direction: column;
                    
                    &.errors {
                        border-color: #F5A623;
                        background: #fbe8e8;
                    }
                    
                    .checkmark {
                        display: none;
                    }

                    .option-name {
                        margin-top: 5px;
                        text-align: center;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: #8F94A0;
                    }

                    &.selected {
                        svg {
                        border-color: $icon-toggle-color;
                        background: inherit;
                        }
                        .option-name {
                            color: inherit;
                        }

                        .checked-icon {
                            display: flex;
                            position: absolute;
                            top: 5px;
                            right: 10px;
                            width: $icon-toggle-size;
                            height: $icon-toggle-size;
                            background: $icon-toggle-background;
                            border: 4px solid white;
                            transform: translate(30%, -30%);
                            border-radius: 100%;
                            align-items: center;
                            justify-content: center;

                            &:after {
                                border-radius: 2px;
                                position: absolute;
                                display: flex;
                                content: '';
                                width: calc(#{$icon-toggle-size} / 3);
                                height: calc(#{$icon-toggle-size} / 6);
                                border-bottom: 3px solid white;
                                border-left: 3px solid white;
                                transform: rotate(-45deg);
                            }
                        }
                    }
                }

                .checkmark {
                    height: 14px;
                    width: 14px;
                    margin-right: 15px;
                    border: 3px solid $radio-button-border-color;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;

                    &:after {
                        content: "";
                        display: none;
                    }

                    &.checked {
                        border-color: $radio-button-border-color-cheked;
                        background: $radio-button-border-color-cheked;

                        &:after {
                            display: block;
                            width: calc(100% - 6px);
                            height: calc(100% - 6px);
                            border-radius: 100%;
                            background: white;
                        }
                    }
                }
            }
        }        
    }
</style>
