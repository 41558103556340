<template>

    <sidebar :options="sidebarOptions" ref="sidebar" class="tenant-sidebar">

        <div class="section-header contact">

            <svg-icon :iconColor="'#333333'" width="16" height="16" viewBox="0 0 24 24"><phone-icon /></svg-icon>

            <h3 class="title">{{ $t('contact') }}</h3>

        </div>

        <readonly-input name="email_address" class="email-address" :label="$t('email_address')" :value="emailAddress" />

        <readonly-input name="phone_number" class="phone-number" :label="$t('phone_number')" :value="phoneNumber" />

        <div v-for="(person, index) in legalPersons" :key="index">

            <hr class="separator-min">

            <readonly-input name="name" class="readonly-spacing" :label="person.type === 'natural_person' ? $t('name') :  $t('business_name')" :value="getLegalPersonFullName(person)" />

            <readonly-input name="address" class="readonly-spacing" :label="$t('address')" :value="person.address ? person.address.formatted : ''" />

            <readonly-input name="birth_date" class="readonly-spacing" :label="$t('birth_date')" :value="person.birth_date ? $moment(person.birth_date).format('Do MMMM YYYY') : ''" />

        </div>


    </sidebar>

</template>

<script>
    import Sidebar from '~/components/sidebar'
    import SvgIcon from '~/components/svg-icon'
    import { PhoneIcon, BankaccountIcon } from '~/components/icons/button-icons'
    import FullNameMixin from '~/components/mixins/full-name'
    import { mapGetters } from 'vuex'

    export default {
        mixins: [FullNameMixin],

        components: {
            Sidebar,
            SvgIcon,
            PhoneIcon,
            BankaccountIcon
        },

        data() {
            return {
                tenant: {},
                loading: false
            }
        },

        props: {
            tenantId: {
                type: [Number, String],
                required: true
            }
        },

        methods: {
            async fetchTenant() {
                try {

                    this.tenant = await this.$store.dispatch('profile/fetchAndFillTenant', this.tenantId)

                } catch(error) {

                    throw error
                }
            },

            resetTenant() {
                this.$store.commit('profile/resetDefaultState')
            },

            redirectToTenant() {
                this.$router.push(`/tenant/${this.tenantId}`)
            },

            show() {
                this.$refs.sidebar.show()
            },

            hide() {
                this.$refs.sidebar.hide()
            }
        },

        computed: {
            sidebarOptions() {
                return {
                    header: {
                        type: this.$t('tenant'),
                        title: this.getProfileFullName(this.tenant),
                        subTitle:  this.tenant.created_at ? `Profil créé le ${this.$moment(this.tenant.created_at).format('Do MMMM YYYY')}` : ''
                    },
                    fetch: this.fetchTenant,
                    close: this.resetTenant,
                    submit: {
                        action: () => this.redirectToTenant()
                    }
                }
            },

            formattedAddress() {
                return Object.keys(this.address).length ? this.address.formatted : ''
            },

            ...mapGetters({
                phoneNumber: 'profile/getPhoneNumber',
                emailAddress: 'profile/getEmailAddress',
                legalPersons: 'profile/getLegalPersons'
            })
        },
    }
</script>

<i18n>
    {
        "fr": {
            "tenant": "Locataire",
            "email_address": "Adresse email",
            "phone_number": "Téléphone",
            "address": "Adresse",
            "card_brand": "Type de carte",
            "birth_date": "Date de naissance",
            "name": "Nom complet",
            "business_name": "Dénomination sociale",
            "contact": "Contact",
            "payment_method": "Moyen de paiement",
            "identification_number": "Numéro SIREN : {nb}",
            "no_birth_date": "Date de naissance non renseignée"
        }
    }
</i18n>