<template>

    <div :class="['bank-widget', { 'active': active }, {'border': !active && border}]">

        <div class="bank-title">

            <svg-icon class="logo" width="28" height="28" viewBox="0 0 32 32" :iconColor="'#9166FF'"
                      v-if="account.bank_name === 'Swan'">
                <swan-logo/>
            </svg-icon>

            <svg-icon class="logo" width="28" height="28" viewBox="0 0 28 28" :iconColor="'#E5F9ED'" v-else>
                <all-logo/>
            </svg-icon>


            <div class="wrapper-title">
                <h4 class="title">{{ account.account_name }}</h4>
                <button-input
                    class="button btn-delete"
                    iconImg="delete"
                    v-if="account.bank_name !== 'Swan' &&
                          withBtnTrash
                    "
                    @click="$emit('deleteBankAccount', account )"
                />
            </div>

        </div>

        <div class="balance">{{ $n((account.balance || 0) / 100, 'currency') }}</div>

        <div class="last_sync">

            <span class="text">{{ lastSyncText }}</span>

        </div>
    </div>

</template>

<script>
import BankAccountIconMixin from '~/components/mixins/icons/bank-account'
import SvgIcon from '~/components/svg-icon'
import ImgSprite from '~/components/img-sprite'
import ButtonInput from "@/components/inputs/button-input.vue";

export default {

    mixins: [BankAccountIconMixin],

    components: {
        ButtonInput,
        SvgIcon,
        ImgSprite
    },

    props: {
        account     : {
            type    : Object,
            required: true
        },
        active      : {
            type   : Boolean,
            default: false
        },
        border      : {
            type   : Boolean,
            default: false
        },
        withBtnTrash: {
            type    : Boolean,
            required: false,
            default : false
        }
    },

    data() {
        return {
            bankOptions: [
                'bnp_paribas'
            ]
        }
    },

    computed: {
        lastSyncText() {
            return this.account.last_sync
                ? this.$t('last_sync', {from: this.$moment(this.account.last_sync).fromNow()})
                : this.$t('unknown_date')
        }
    }
}
</script>

<i18n>
{
    "fr": {
        "auto_sync": "Synchronisation auto.",
        "last_sync": "Actualisé {from}",
        "unknown_date": "Date inconnue"
    }
}
</i18n>

<style lang="scss" scoped>
$grey-dark: #8D939F;
$border-color: #DEE3EF;
$basic-fakeblack: #333333;

.bank-widget {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 270px;
    min-height: 108px;
    box-sizing: border-box;
    background: white;
    padding: 12px 18px;
    border-radius: 10px;
    border: 1px solid transparent;

    &.border {
        border: 1px solid #DEE3EF;
    }

    &.active {
        border-color: #BED000;
    }

    .bank-title {
        display: flex;
        flex-direction: row;
        align-items: center;


        .logo {
            margin-right: 7px;

            &.border {
                border: 1px solid $border-color;
                border-radius: 6px;
                padding: 3px;
            }
        }

        .wrapper-title {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .title {
                display: flex;
                text-transform: uppercase;
                align-items: center;
                font-weight: 600;
                font-size: 7.5pt;
                color: $grey-dark;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .btn-delete {
                background: transparent;
                display: flex;
                font-weight: normal;
                padding: 5px 8px;
            }
        }
    }

    .balance {
        color: $basic-fakeblack;
        font-family: 'Montserrat';
        font-size: 18pt;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 600;
    }

    .last_sync {
        display: flex;
        align-items: center;

        .icon {
            margin-right: 5px;
            background: #F3F4F4;
            border-radius: 100%;
            padding: 4px;
        }

        .text {
            text-transform: uppercase;
            font-weight: 600;
            color: $grey-dark;
            font-size: 7.5pt;
        }
    }
}
</style>
