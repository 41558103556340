export default () => {
    return {
        description: '',
        delivery_date: '',
        expiration_date: '',
        extension: '',
        fields: {},
        file: '',
        is_autogenerated: false,
        id: '',
        is_signable: false,
        is_signed: false,
        mime_type: '',
        name: '',
        signature_request: {
            embedded_url: '',
            id: '',
            signers: [],
            signatures: [],
            status: ''
        },
        redirect_path: '',
        update_path: '',
        size: 0,
        title: '',
        types: [],
        url: ''
    }
}
