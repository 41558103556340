export default {
    getCreatedAt(state) {
        return state.created_at
    },

    getEmailAddress(state) {
        return state.email_address
    },

    getEntity(state) {
        return state.entity
    },

    getFullName(state) {
        return state.full_name
    },

    getId(state) {
        return state.id
    },

    getLegalPersons(state) {
        return state.legal_persons
    },

    getPaymentMethod(state) {
        return state.payment_method
    },

    getPhoneNumber(state) {
        return state.phone_number
    },

    getPayoutDelay(state) {
        return state.payout_delay
    },
    
    getMatrimonialStatus(state) {
        return state.matrimonial_status
    },

    getRelatedAccountingAccount(state) {
        return state.relationships.accounting_account
    },

    getRelatedAppliedFees(state) {
        return state.relationships.applied_fees
    },

    getRelatedDocuments(state) {
        return state.relationships.documents
    },

    getRelatedFees(state) {
        return state.relationships.fees
    },

    getRelatedGuarantors(state) {
        return state.relationships.guarantors
    },

    getRelatedInvitations(state) {
        return state.relationships.invitations
    },

    getRelatedDefaultPayoutBankAccount(state) {
        return state.relationships.default_payout_bank_account
    },

    getRelatedPayoutBankAccounts(state) {
        return state.relationships.payout_bank_accounts
    },

    getRelatedPaymentMethods(state) {
        return state.relationships.payment_methods
    },

    getRelatedProperties(state) {
        return state.relationships.properties
    },

    getRelatedRentals(state) {
        return state.relationships.rentals
    },

    getRelatedPropertyManager(state) {
        return state.relationships.property_management_company
    },

    getRole(state) {
        return state.role
    },
    
    isInvitationAccepted(state, getters) {
        return getters['getRelatedInvitations'].filter(invitation => invitation.is_accepted).length > 0
    }
}
