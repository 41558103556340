<template>

    <client-only>
    
        <widget class="contact-widget" :title="$t('contact')">

            <hr class="separator" />

            <h3 class="profile-name" v-if="principalContactFullName">{{ principalContactFullName }}</h3>
            
            <div class="readonly" >

                <h4 class="label">{{ $t('email_address') }}</h4>

                <div :class="['item', { 'highlight': emailAddress }]">

                    {{ emailAddress ? emailAddress : $t('no_email_address') }}

                    <svg-icon class="icon" :iconColor="'#BED000'" width="16" height="16" :viewbox="'0 0 18 18'" v-if="isInvitationAccepted"><attached-icon /></svg-icon>

                </div>

            </div>

            <div class="readonly">

                <h4 class="label">{{ $t('phone_number') }}</h4>

                <div :class="['item', { 'highlight': phoneNumber }]">

                    {{ phoneNumber ? formatPhoneNumber(phoneNumber) : $t('no_phone_number') }}

                </div>

            </div>

        </widget>

    </client-only>

</template>

<script>
    import Widget from '~/components/widget'
    import SvgIcon from '~/components/svg-icon'
    import AttachedIcon from '~/components/icons/status-icons/attached-icon'
    import FullNameMixin from '~/components/mixins/full-name'
    import { mapGetters } from 'vuex'
    
    export default {

        mixins: [FullNameMixin],

        components: {
            Widget,
            SvgIcon,
            AttachedIcon,
        },

        methods: {
            formatPhoneNumber(phoneNumber) {
                let nationalNumber = phoneNumber.substring(3)

                nationalNumber = '0' + nationalNumber

                return nationalNumber.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')
            }
        },

        computed: {
            ...mapGetters({
                emailAddress: 'profile/getEmailAddress',
                phoneNumber: 'profile/getPhoneNumber',
                isInvitationAccepted: 'profile/isInvitationAccepted',
                legalPersons: 'profile/getLegalPersons',
                getEntity: 'profile/getEntity'
            }),

            principalContactFullName() {
                if (this.getEntity === 'couple' && this.legalPersons.length > 1) {
                    if (this.phoneNumber === '' && this.emailAddress === '') return

                    else if (typeof this.legalPersons[0].email_address !== 'undefined'
                        && this.phoneNumber === this.legalPersons[0].phone_number
                        && this.emailAddress === this.legalPersons[0].email_address) return this.getLegalPersonFullName(this.legalPersons[0])

                    else if (typeof this.legalPersons[1].email_address !== 'undefined'
                        && this.phoneNumber === this.legalPersons[1].phone_number
                        && this.emailAddress === this.legalPersons[1].email_address) return this.getLegalPersonFullName(this.legalPersons[1])

                    else if ((this.emailAddress === this.legalPersons[0].email_address) ||
                        (this.phoneNumber === this.legalPersons[0].phone_number
                        && typeof this.legalPersons[0].phone_number !== 'undefined')) return this.getLegalPersonFullName(this.legalPersons[0])
                    
                    else if ((this.emailAddress === this.legalPersons[1].email_address) ||
                        (this.phoneNumber === this.legalPersons[1].phone_number
                        && typeof this.legalPersons[1].phone_number !== 'undefined')) return this.getLegalPersonFullName(this.legalPersons[1])
                }
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "contact": "Contact principal",
            "name": " Nom complet",
            "no_name": "Nom complet inconnu",
            "no_email_address": "Adresse e-mail inconnue",
            "no_phone_number": "Numéro de téléphone inconnu",
            "no_address": "Adresse inconnue",
            "address": "Adresse",
            "email_address": "Adresse e-mail",
            "phone_number": "Téléphone"
        }
    }
</i18n>

<style lang="scss" scoped>
    $item-color: #767676;
    $label-color: #393939;
    $separator-color: #F2F2F2;
    $highlight-color: #BED000;

    .contact-widget {
        display: flex;
        flex-direction: column;
        align-self: stretch;
        .label {
            @include body;
        }

        .label.address {
            margin-top: 12px
        }

        .profile-name {
            color: $item-color;
            font-size: 14pt;
            font-style: normal;
            font-weight: 400;
            margin-bottom: 2.4vh;
        }

        .readonly{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            margin-bottom: 2.4vh;

            &:last-of-type {
                margin-bottom: 0;
            }
            .label {
                color: $label-color;
                font-size: 11pt;
                font-style: normal;
                font-weight: 600;
                line-height: 120%;
            }
            .item {
                display: flex;
                align-items: center;
                align-self: stretch;
                color: $item-color;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;

                &:not(.highlight) {
                    text-decoration: line-through;
                    opacity: .5;
                }

                .icon {
                    margin-left: 12px;
                }
            }
        }

        .separator {
            margin-bottom: 2.4vh;
            height: 0px;
            border: 0;
            border-bottom: 1px solid $separator-color;
        }
    }
</style>
