<template>

    <ValidationObserver class="form-input" tag="div" :vid="id" v-slot="{ validate, handleSubmit, errors, invalid, reset, fields, failed }" ref="observer">

        <form-info type="error" :text="formattedFormErrors" v-if="formErrors" />

        <form @submit.prevent="handleSubmit(formAction)" :style="formStyle" v-click-outside="() => { reset(); formErrors = '' }">

            <slot v-bind:errors="errors" v-bind:validate="validate" v-bind:invalid="invalid" v-bind:reset="reset" v-bind:fields="fields" v-bind:failed="failed" v-bind:setErrors="setErrors" />

        </form>

    </ValidationObserver>

</template>

<script>
    import FormInfo from '~/components/form-info'
    import snakeCase from 'lodash/snakeCase'

    export default {
        components: {
            FormInfo
        },

        props: {
            onSubmit: {
                type: Function,
                required: true
            },
            id: {
                type: String,
                required: false
            },
            formStyle: {
                type: Object,
                required: false
            }
        },

        data() {
            return {
                formErrors: ''
            }
        },

        mounted() {
            // Watch for slot props invalid change and emit value
            this.$watch(() => {
                return !!this.$refs.observer ? this.$refs.observer.errors : []
            }, (errors) => {
                if (Object.values(errors).filter(field => field.length).length) this.$emit('invalid')
                else this.$emit('valid')
            })
        },

        methods: {
            setErrors(errors) {

                return this.formErrors = errors
            },

            async formAction() {
                try {

                    this.setErrors('')

                    await this.onSubmit.call()

                } catch(errors) {

                    this.setErrors(errors)

                    throw errors
                }
            }
        },

        computed: {
            formattedFormErrors() {
                if (typeof this.formErrors === 'string') return this.formErrors

                if (!Object.keys(this.formErrors).length || typeof this.formErrors['response'] === 'undefined') return ''
                
                let error = `${this.formErrors['message']}`

                if (this.formErrors['response']['status'] === 422 && typeof this.formErrors['response']['data']['errors'] !== 'undefined') {

                    error += `<br><br>${this.formErrors['response']['data']['errors'].map(error => error.detail).join('<br>')}`

                } else if (typeof this.formErrors['response']['data']['errors'] !== 'undefined') {

                    error = `${this.formErrors['response']['data']['errors'].map(error => this.$t(`error.${snakeCase(error.detail)}`)).join('<br>')}`

                }

                return error
            }
        }
    }
</script>
