<template>

    <div class="logo-section">

        <nuxt-link :to="redirectPath" class="link" no-prefetch exact>

            <img :src="src" alt="Logo Oskar" class="logo" :style="style" />

        </nuxt-link>

    </div>

</template>

<script>
    import OskarLogo from '~/static/img/logo/logo_oskar.svg';
    import OskarLogoWhite from '~/static/img/logo/logo_oskar_white.svg';
    
    export default {
        props: {
            whiteVersion: {
                default: false,
                type: Boolean
            },
            redirectPath: {
                type: String,
                default: '/'
            },
            width: {
                type: Number,
                default: 125
            }
        },

        computed: {
            src() {
                return this.whiteVersion ? OskarLogoWhite : OskarLogo
            },

            style() {
                return {
                    width: `${this.width}px`
                }
            }
        }
    }
</script>

<style lang="scss">
    .logo-section {
        justify-content: center;
        cursor: pointer;

        .link {
            display: flex;
            align-items: center;
        }

        .logo {
            height: auto;
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 100% auto;
            position: relative;
            top: -3px;
        }
    }
</style>
