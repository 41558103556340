<template>
    
    <nav class="update-navigation-bar">

        <ul class="update-list">

            <li class="update-item back" @click="$emit('back')">

                <svg-icon class="icon" width="20" height="20" :iconColor="'##393939'" :viewbox="'0 0 21 20'"><back-navigation-icon /></svg-icon>

                {{ $t('back') }}

            </li>

            <li class="update-item title">{{ title }}</li>

            <li class="update-item saving-status">{{ status }}</li>

        </ul>

    </nav>

</template>

<script>
    import InstantSearchInput from '~/components/inputs/instant-search-input.vue'
    import SvgIcon from '~/components/svg-icon'
    import BackNavigationIcon from '~/components/icons/navigation-icons/back-navigation-icon'
    import { mapGetters } from 'vuex'
    
    export default {
        components: {
            InstantSearchInput,
            SvgIcon,
            BackNavigationIcon
        },
        
        data() {
            return {
                keywords: ''
            }
        },

        computed: {
            ...mapGetters({
                isManager: 'isManager',
                title: 'workflow/getTitle',
                status: 'workflow/getStatus'
            })
        }
    }
</script>

<i18n>
    {
        "fr": {
            "search-input-placeholder": "Rechercher un propriétaire, un lot, un locataire …",
            "back": "Retour"
        }
    }
</i18n>

<style lang="scss">
    $white: #FFFFFF;
    $white-bar: #efefef;
    $background-search-bar: #BED000;
    $header-height: 68px;

    .update-navigation-bar {
        position: relative;
        min-height: $header-height;
        display: flex;
        flex-direction: column;
        box-shadow: 0 2px 8px 0 rgba(0,0,0,.23);
        z-index: 9;

        .update-list {
            display: flex;
            flex-direction: row;
            flex: 1;
            background: $white;
            color: #393939;

            .update-item {
                display: flex;
                align-items: center;
                padding: 10px 30px;

                &.back {
                    display: flex;
                    align-items: center;
                    font-size: 12pt;
                    color: #393939;
                    cursor: pointer;
                    border-right: 1px solid $white-bar;

                    .icon {
                        margin-right: 10px;
                    }
                }

                &.title {
                    font-size: 18px;
                    color: #393939;
                }

                &.saving-status {
                    margin-top: 3px;
                    font-size: 11pt;
                    opacity: .5;
                    color: #393939;
                }
            }
        }
    }
</style>
