<template>
    
    <div class="error-banner" v-if="display">
        
        <div class="text">
            
            <h4 class="title">{{ title }}</h4>

            <p class="details">{{ details }}</p>

        </div>

        <div class="close" @click="toggleError"></div>

    </div>

</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                required: true
            },
            details: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                display: true
            }
        },

        methods: {
            toggleError() {
                this.display = false

                this.$emit('toggleError', true)
            }
        },

        watch: {
            details: {
                immediate: true,
                handler(newValue) {
                    if (newValue.length && process.browser) this.$scrollTo('body')
                }
            }
        }
    }
</script>

<style lang="scss">
    $width: 1180px;
    $background: #FF657F;

    .error-banner {
        position: relative;
        overflow: hidden;
        margin: 0 calc(calc(100vw - 100%) / -2);
        background: $background;
        height: 54px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 20px 0;

        .text {
            display: flex;
            flex-direction: row;
            max-width: $width;
            margin: 0 auto;
            flex: 1;

            .title {
                @include body;
                font-weight: 400;
                color: white;
                white-space: nowrap;
            }

            .details {
                @include body;
                color: rgba(255,255,255,.72);
                margin: 0 16px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .close {
            display: flex;
            position: absolute;
            width: 16px;
            right: 15px;
            cursor: pointer;

            &:before, &:after {
                position: absolute;
                content: '';
                display: flex;
                background: white;
                border-radius: 2px;
                height: 2px;
                width: 16px;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }
    }
</style>
