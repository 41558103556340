<template>

    <ul class="sprite-images" @click="$emit('click', $event)">

        <li class="item" v-for="key in total" :key="key" v-if="isActive(key)">

            <div class="img" :style="styleObject(key)"></div>

        </li>

    </ul>
    
</template>

<script>
    export default {
        props: {
            path: {
                type: String,
                required: true
            },
            width: {
                type: Number,
                required: true
            },
            height: {
                type: Number,
                required: true
            },
            total: {
                type: Number,
                required: false,
                default() {
                    return 1;
                }
            },
            horizontalShift: {
                type: Number,
                required: false,
                default() {
                    return 0;
                }
            },
            verticalShift: {
                type: Number,
                required: false,
                default() {
                    return 1;
                }
            },
            dpi: {
                type: Number,
                required: false,
                default() {
                    return 300;
                }
            }
        },
        methods: {
            styleObject(key) {
                let posX = 0;
                let posY = 0;
                let width = this.width / (this.dpi / 100);
                let height = this.height / (this.dpi / 100);

                posX += -1 * (key - 1) * (width / this.total);
                posY += -1 * (this.verticalShift - 1) * height;
                width = width / this.total;
                
                return {
                    backgroundImage: 'url(' + this.path + ')',
                    backgroundPosition: posX + 'px ' + posY + 'px ',
                    backgroundSize: this.width / (this.dpi / 100) + 'px ' + 'auto',
                    height: height + 'px',
                    width: width + 'px',
                    backgroundRepeat: 'no-repeat'
                }
            },
            
            isActive(key) {
                return (key !== this.horizontalShift && this.horizontalShift) ? false : true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .sprite-images {
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        flex: 0 0 auto;

        .item {
            flex: 1;
            align-items: center;
            display: flex;
            justify-content: center;
            align-items: flex-start;
        }
    }
</style>
