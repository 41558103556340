<template>

    <div :class="['supplier-list-table-cell', { 'empty': !Object.keys(supplier).length }]" v-if="!$fetchState.error">

        <svg-icon class="icon" iconColor="#578DFF" width="18" height="18"><supplier-icon /></svg-icon>

        <spinner-loader v-if="loading && $fetchState.pending" />

        <span class="supplier" v-else-if="!Object.keys(supplier).length">-</span>

        <div class="supplier" @click.stop="showSupplierSidebar"  v-else>

            {{ getProfileFullName(supplier) }}

            <supplier-sidebar ref="SupplierSidebar" :supplierId="supplier['_jv'].id" :supplierType="supplier['_jv'].type"/>

        </div>

    </div>

</template>

<script>
    import FullNameMixin from '~/components/mixins/full-name'
    import SvgIcon from '~/components/svg-icon'
    import SupplierIcon from '~/components/icons/table-cell-icons/supplier-icon'
    import SpinnerLoader from '~/components/spinner-loader'
    import SupplierSidebar from '~/components/sidebar/supplier-sidebar'

    export default {
        mixins: [FullNameMixin],

        components: {
            SpinnerLoader,
            SupplierSidebar,
            SvgIcon,
            SupplierIcon
        },

        props: {
            supplierId: {
                type: String,
                required: true
            },

            propSupplier: {
                type: Object,
            },

            supplierType: {
                type: String,
                required: true
            }
        },

        fetchDelay: 1000,

        async fetch() {

            if (this.propSupplier !== undefined) {
                this.supplier = this.propSupplier
                return
            }

            const { store } = this.$nuxt.context

            this.loading = true

            if (this.supplierType === 'suppliers') {

                this.supplier = await store.dispatch('profile/fetchSupplier', this.supplierId)

            } else if (this.supplierType === 'property-management-companies') {

                this.supplier = await store.dispatch('property-manager/fetchPropertyManagementCompany', this.supplierId)
            }

            this.loading = false
        },

        data() {
            return {
                loading: false,
                supplier: {}
            }
        },

        methods: {
            showSupplierSidebar() {

                this.$nextTick(() => {

                    if (typeof this.$refs.SupplierSidebar !== 'undefined' && typeof this.$refs.SupplierSidebar.show !== 'undefined') {

                        this.$refs.SupplierSidebar.show()
                    }
                })
            }
        }
    }
</script>

<style lang="scss">
    $supplier: #578DFF;

    .supplier-list-table-cell {
        display: flex;

        &.empty {
            opacity: .5;
        }

        .supplier {
            display: flex;
            color: $supplier;
            text-decoration-color: $supplier;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .icon {
            margin-right: 7px;
            flex-grow: 0;
            flex-shrink: 0;
        }
    }
</style>
