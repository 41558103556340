<template>

    <div :class="['bank-account-transaction-reconciliation-table-cell', { 'empty': !bankAccountTransaction }]">

        <spinner-loader v-if="loading" />

        <span class="bank-account" v-else-if="!bankAccountTransaction._jv.relationships.transactionnable.data">{{ $t('not_affected') }}</span>

        <div class="bank-account" @click.stop="expandBankAccountTransaction" v-else>

            {{ $t('affected') }}
            
            <arrow class="arrow" :active="!collapsed" :width="12" :height="8" color="#1F1F1F" />

        </div>

    </div>

</template>

<script>
    import SpinnerLoader from '~/components/spinner-loader'
    import Arrow from '~/components/arrow'
    import FullNameMixin from '~/components/mixins/full-name'

export default {
    name: 'BankAccountTransactionReconciliation',

    mixins: [FullNameMixin],

    components: {
        SpinnerLoader,
        Arrow
    },

    data() {
        return {
            loading: false
        }
    },

    props: {
        bankAccountTransaction: {
            type: Object,
            required: true
        },
        collapsed: {
            type: Boolean,
            default: true
        }
    },

    methods: {
        async fetchOwner(payoutBankAccount) {
            const ownerTypeToFetchFunction = {
                'landlords': 'profile/fetchLandlord',
                'suppliers': 'profile/fetchSupplier',
                'tenants': 'profile/fetchTenant',
                'property-management-companies': 'property-manager/fetchPropertyManagementCompany'
            }

            const fetchFunction = ownerTypeToFetchFunction[payoutBankAccount._jv.relationships.owner.data.type]

            return await this.$store.dispatch(fetchFunction, payoutBankAccount?._jv.relationships?.owner.data.id)
        },

        getRedirectPath(owner) {
            const ownerTypeToRedirectPath = {
                'landlords': `/landlord/${owner._jv.id}`,
                'suppliers': `/supplier/${owner._jv.id}`,
                'tenants': `/tenant/${owner._jv.id}`,
                'property-management-companies': `/dashboard/bank-accounts`
            }

            return ownerTypeToRedirectPath[owner._jv.type]
        },

        async expandBankAccountTransaction() {
            try {

                this.loading = true

                const transactionable = this.bankAccountTransaction._jv.relationships.transactionnable.data

                if (transactionable.type === 'payments') {

                    const payment = await this.$store.dispatch('payment/fetchPayment', transactionable.id)

                    const payable = payment._jv.relationships.payable.data

                    let rentable

                    if (payable !== null) {
                        rentable = await this.$store.dispatch('rent/fetchRentable', {
                            rentableId: payable.id,
                            rentableType: payable.type
                        })
                    }

                    let rental

                    if (rentable?._jv.relationships.rental.data !== null) {
                        rental = await this.$store.dispatch('rental/fetchRental', rentable?._jv.relationships.rental?.data?.id)
                    }

                    let rentableLabel = ''
                    
                    if (rentable['_jv'].type === 'rents') rentableLabel = this.$t('rent_period', { period: this.$moment(rentable.period_start_date).format('Do MMM YY') })

                    else if (rentable['_jv'].type === 'costs') rentableLabel = this.$t('tenant_cost_period', { period: this.$moment(rentable.period_start_date).format('Do MMM YY') })

                    else if (rentable['_jv'].type === 'security-deposits') rentableLabel = this.$t('security_deposit_period', { period: this.$moment(rentable.created_at).format('Do MMM YY') })

                    let expandedLines = [
                        [
                            {
                                type: 'document-item',
                                data: {
                                    label: rentableLabel,
                                    description: this.$t('reference', { ref: rentable.reference }),
                                    icon: rentable.status,
                                },
                                width: 4,
                                action: () => {
                                    rental !== undefined ? this.$router.push(rental._jv.relationships.property.data.id !== undefined ? `/property/${rental._jv.relationships.property.data.id}/rents` : '') : null
                                }
                            },

                            {
                                type: 'string',
                                data: ' ',
                                width: 1.5,
                                action: () => {
                                    rental !== undefined ? this.$router.push(rental._jv.relationships.property.data.id !== undefined ? `/property/${rental._jv.relationships.property.data.id}/rents` : '') : null
                                }
                            },
                            {
                                type: 'string',
                                data: this.$t('payment_reconciliated'),
                                width: 2,
                                action: () => {
                                    rental !== undefined ? this.$router.push(rental._jv.relationships.property.data.id !== undefined ? `/property/${rental._jv.relationships.property.data.id}/rents` : '') : null
                                }
                            },
                            {
                                type: 'string',
                                data: ' ',
                                width: 2,
                                action: () => {
                                    rental !== undefined ? this.$router.push(rental._jv.relationships.property.data.id !== undefined ? `/property/${rental._jv.relationships.property.data.id}/rents` : '') : null
                                }
                            },
                            {
                                type: 'string',
                                data: ' ',
                                width: 3,
                                action: () => {
                                    rental !== undefined ? this.$router.push(rental._jv.relationships.property.data.id !== undefined ? `/property/${rental._jv.relationships.property.data.id}/rents` : '') : null
                                }
                            },
                            {
                                type: 'string',
                                data: rentable.period_start_date ? this.$moment(rentable.period_start_date).format('DD/MM/YYYY') : this.$moment(rentable.created_at).format('DD/MM/YYYY'),
                                width: 2,
                                highlight: true,
                                action: () => {
                                    rental !== undefined ? this.$router.push(rental._jv.relationships.property.data.id !== undefined ? `/property/${rental._jv.relationships.property.data.id}/rents` : '') : null
                                }
                            },
                            {
                                type: 'status',
                                data: {
                                    label: this.$n((rentable.total_amount / 100), 'currency'),
                                    type: 'success'
                                },
                                width: 1.5,
                                position: 'right',
                                action: () => {
                                    rental !== undefined ? this.$router.push(rental._jv.relationships.property.data.id !== undefined ? `/property/${rental._jv.relationships.property.data.id}/rents` : '') : null
                                }
                            }
                        ]
                    ]

                    this.$emit('expandAccountingEntries', expandedLines, 'bank-account-reconciliation')

                } else if (transactionable.type === 'payouts') {

                    const payout = await this.$store.dispatch('payout/fetchPayout', transactionable.id)

                    const payoutBankAccount = await this.$store.dispatch('bank-account/fetchPayoutBankAccount', payout?._jv.relationships.beneficiary?.data.id)

                    const owner = await this.fetchOwner(payoutBankAccount)

                    const redirectPath = this.getRedirectPath(owner)

                    let expandedLines = [
                        [
                            {
                                type: 'bank-account-transaction',
                                data: {
                                    line1: this.getProfileFullName(owner),
                                    line2: this.$t(owner._jv.type),
                                    icon: ' '
                                },
                                width: 4,
                                action: () => {
                                    this.$router.push(redirectPath)
                                }
                            },
                            {
                                type: 'string',
                                data: ' ',
                                width: 1.5,
                                action: () => {
                                    this.$router.push(redirectPath)
                                }
                            },
                            {
                                type: 'string',
                                data: this.$t('payout_reconfiliated'),
                                width: 2,
                                action: () => {
                                    this.$router.push(redirectPath)
                                }
                            },
                            {
                                type: 'string',
                                data: ' ',
                                width: 2,
                                action: () => {
                                    this.$router.push(redirectPath)
                                }
                            },
                            {
                                type: 'string',
                                data: ' ',
                                width: 3,
                                action: () => {
                                    this.$router.push(redirectPath)
                                }
                            },
                            {
                                type: 'string',
                                data: this.$moment(payout.initiated_at).format('DD/MM/YYYY'),
                                width: 2,
                                highlight: true,
                                action: () => {
                                    this.$router.push(redirectPath)
                                }
                            },
                            {
                                type: 'status',
                                data: {
                                    label: this.$n(payout.amount / 100, 'currency'),
                                    type: 'success'
                                },
                                width: 1.5,
                                position: 'right',
                                action: () => {
                                    this.$router.push(redirectPath)
                                }
                            }
                        ]
                    ]

                    this.$emit('expandAccountingEntries', expandedLines, 'bank-account-reconciliation')

                } else {

                    let rentable

                    if (transactionable !== null) {
                        rentable = await this.$store.dispatch('rent/fetchRentable', {
                            rentableId: transactionable.id,
                            rentableType: transactionable.type
                        })
                    }

                    let rental

                    if (rentable?._jv.relationships.rental.data !== null) {
                        rental = await this.$store.dispatch('rental/fetchRental', rentable?._jv.relationships.rental?.data?.id)
                    }

                    let rentableLabel = ''
                    
                    if (rentable['_jv'].type === 'rents') rentableLabel = this.$t('rent_period', { period: this.$moment(rentable.period_start_date).format('Do MMM YY') })

                    else if (rentable['_jv'].type === 'costs') rentableLabel = this.$t('tenant_cost_period', { period: this.$moment(rentable.period_start_date).format('Do MMM YY') })

                    else if (rentable['_jv'].type === 'security-deposits') rentableLabel = this.$t('security_deposit_period', { period: this.$moment(rentable.created_at).format('Do MMM YY') })

                    let expandedLines = [
                        [
                            {
                                type: 'document-item',
                                data: {
                                    label: rentableLabel,
                                    description: this.$t('reference', { ref: rentable.reference }),
                                    icon: rentable.status,
                                },
                                width: 4,
                                action: () => {
                                    rental !== undefined ? this.$router.push(rental._jv.relationships.property.data.id !== undefined ? `/property/${rental._jv.relationships.property.data.id}/rents` : '') : null
                                }
                            },

                            {
                                type: 'string',
                                data: ' ',
                                width: 1.5,
                                action: () => {
                                    rental !== undefined ? this.$router.push(rental._jv.relationships.property.data.id !== undefined ? `/property/${rental._jv.relationships.property.data.id}/rents` : '') : null
                                }
                            },
                            {
                                type: 'string',
                                data: this.$t('payment_reconciliated'),
                                width: 2,
                                action: () => {
                                    rental !== undefined ? this.$router.push(rental._jv.relationships.property.data.id !== undefined ? `/property/${rental._jv.relationships.property.data.id}/rents` : '') : null
                                }
                            },
                            {
                                type: 'string',
                                data: ' ',
                                width: 2,
                                action: () => {
                                    rental !== undefined ? this.$router.push(rental._jv.relationships.property.data.id !== undefined ? `/property/${rental._jv.relationships.property.data.id}/rents` : '') : null
                                }
                            },
                            {
                                type: 'string',
                                data: ' ',
                                width: 3,
                                action: () => {
                                    rental !== undefined ? this.$router.push(rental._jv.relationships.property.data.id !== undefined ? `/property/${rental._jv.relationships.property.data.id}/rents` : '') : null
                                }
                            },
                            {
                                type: 'string',
                                data: rentable.period_start_date ? this.$moment(rentable.period_start_date).format('DD/MM/YYYY') : this.$moment(rentable.created_at).format('DD/MM/YYYY'),
                                width: 2,
                                highlight: true,
                                action: () => {
                                    rental !== undefined ? this.$router.push(rental._jv.relationships.property.data.id !== undefined ? `/property/${rental._jv.relationships.property.data.id}/rents` : '') : null
                                }
                            },
                            {
                                type: 'status',
                                data: {
                                    label: this.$n((rentable.total_amount / 100), 'currency'),
                                    type: 'success'
                                },
                                width: 1.5,
                                position: 'right',
                                action: () => {
                                    rental !== undefined ? this.$router.push(rental._jv.relationships.property.data.id !== undefined ? `/property/${rental._jv.relationships.property.data.id}/rents` : '') : null
                                }
                            }
                        ]
                    ]

                    this.$emit('expandAccountingEntries', expandedLines, 'bank-account-reconciliation')
                }
                
            } catch (error) {
                
                throw error

            } finally {

                this.loading = false
            }
        }
    },

    computed: {
        transactionType() {
            return this.bankAccountTransaction._jv.relationships.transactionnable.data.type
        },
        transactionTypeMessage() {
            return this.$t(['payments', 'payouts'].includes(this.transactionType) ? this.transactionType : 'payments')
        }
    }
}
</script>

<i18n>
    {
        "fr": {
            "rents": "Appel de loyer",
            "reference": "Réf: {ref}",
            "tenant_costs": "Appel exceptionnel",
            "security_deposits": "Dépôt de garantie",
            "payments": "Paiement",
            "payouts": "Reversement",
            "landlords": "Propriétaire",
            "suppliers": "Fournisseur",
            "tenants": "Locataire",
            "property-management-companies": "Agence",
            "payout_reconfiliated": "Reversement affecté",
            "payment_reconciliated": "Paiement affecté",
            "rent_period": "Appel du {period}",
            "tenant_cost_period": "Appel exceptionnels du {period}",
            "security_deposit_period": "Dépôt de garantie du {period}",
            "not_affected": "Non affecté",
            "affected": "Affecté"
        }
    }
</i18n>

<style lang="scss">
    .bank-account-transaction-reconciliation-table-cell {
        display: flex;

        &.empty {
            opacity: .5;
        }

        .bank-account {
            display: flex;
            align-items: center;
            font-size: 10.5pt;
            font-weight: 400;

            .arrow {
                margin-left: 9px;
            }

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
</style>