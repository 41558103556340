<template>

    <modal class="payment-method-modal" :title="modalTitle" :options="modalOptions" @back="resetForm" @submitted="hide" @canceled="hide" @close="hide" ref="modal">

        <spinner-loader v-if="loading" />

        <template v-else>

            <step-field name="payment_method" :label="$t('payment_method')" :options="paymentMethodOptions" getter="profile/getRelatedPaymentMethods" setter="profile/setRelatedPaymentMethods" ref="form" type="payment-method" />

        </template>

    </modal>

</template>

<script>
    import Modal from '~/components/modal'
    import SpinnerLoader from '~/components/spinner-loader'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            Modal,
            SpinnerLoader
        },

        data() {
            return {
                loading: false
            }
        },

        methods: {
            async show() {
                try {

                    this.$store.commit('payment-method/resetDefaultState')

                    this.$refs.modal.show()

                    this.loading = true

                } catch(error) {

                    throw error

                } finally {

                    this.loading = false
                }
            },

            async createPaymentMethod() {
                try {

                    if (this.paymentMethodType !== '') {

                        return await this.$store.dispatch('payment-method/createTenantPaymentMethod', {
                            tenantId: this.tenantId
                        })
                    }

                } catch (error) {

                    throw error
                }
            },

            async fetchTenantPaymentMethods() {
                try {

                    return await this.$store.dispatch('profile/fetchAndFillPaymentMethodRelationship', {
                        tenantId: this.tenantId
                    })

                } catch(error) {

                    throw error
                }
            },

            resetForm() {
                this.$store.commit('payment-method/resetDefaultState')
            },

            hide() {
                return this.$refs.modal.hide()
            }
        },

        computed: {
            paymentMethodOptions() {
                return {
                    onSubmit: this.createPaymentMethod,
                    onRefresh: this.fetchTenantPaymentMethods,
                    types: [
                        'sepa_direct_debit',
                        'sepa_credit_transfer',
                        'sepa_credit_transfer_recommended',
                        'card'
                    ]
                }
            },

            ...mapGetters({
                paymentMethodType: 'payment-method/getType',
                tenantPaymentMethod: 'profile/getRelatedPaymentMethods',
                tenantId: 'profile/getId',
                editMode: 'workflow/isEditMode'
            }),

            modalOptions() {
                return {
                    backButton: !!this.paymentMethodType
                }
            },

            modalTitle() {
                switch(this.paymentMethodType) {
                    case 'sepa_credit_transfer':
                        return this.$t('sepa_credit_transfer')

                    case 'sepa_direct_debit':
                        return this.$t('sepa_direct_debit')

                    case 'card':
                        return this.$t('card')

                    default:
                        return this.$t('payment_method')
                }
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "payment_method": "Choix du moyen de paiement",
            "sepa_direct_debit": "Prélèvement automatique",
            "sepa_credit_transfer": "Virement programmé",
            "card": "Carte bancaire"
        }
    }
</i18n>
