import defaultState from './default'

export default {
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },
    
    setType(state, value) {
        state.type = value
    },

    setId(state, value) {
        state.id = value
    },

    setCheckoutInventory(state, value) {
        state.checkout_inventory = value
    },

    setComment(state, value) {
        state.comment = value
    },

    setRelatedDocument(state, value) {
        state.relationships.document = value
    },

    setRelatedItems(state, value) {
        state.relationships.items = value
    },

    setRelatedRental(state, value) {
        state.relationships.rental = value
    }
}