export default () => {
    return {
        lease_agreement_fields: {
            increased_reference_rent_amount: 0,
            improvement_or_compliance_work: {
                amount: 0,
                description: ''
            },
            is_furnished: false,
            last_rent: {
                adjustment_date: '',
                amount: 0,
                payment_date: ''
            },
            mobility_lease_justification: '',
            other_specific_clauses: [],
            premises_use: '',
            reference_rent_amount: 0,
            rent_supplement_reason: '',
            roomates_common_areas_description: '',
            sharing_type: '',
            shorter_term_reason: '',
            tenant_private_areas_description: '',
            type: ''
        },
        id: '',
        lease_type: '',
        is_furnished: false,
        is_shared: false,
        hasVatRate: false,
        items: [],
        past_rents: [],
        rent_adjustment_date: '',
        rent_reference_index_reference_quarter: '',
        rent_payment_due_date: '',
        start_date: '',
        create_fees_landlord_share: null,
        create_fees_tenant_share: null,
        end_date: '',
        status: '',
        security_deposit: {
            amount: 0,
            collection_date: '',
            is_hold: null,
            is_already_paid: null
        },
        term: 0,
        relationships: {
            lease_agreement_annexes: [],
            applied_fees: [],
            lease_agreement: {},
            inventories: [],
            fees: [],
            property: {},
            tenants: [],
            payment_method: {},
            upcoming_rents: [],
            security_deposit: {},
        }
    }
}
