export const strict = false

export const defaultState = () => {
    return {
        onboardingAgentData: {},
        agentPosition: {},
        onboardingSteps: 0,
        title: '',
        description: '',
        benefits: [],
        reset_password: false,
        step: {
            index: 1,
            last: 1
        },
        hubspot_properties: {}
    }
}

export const state = defaultState

export const mutations = {
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },

    setTitle(state, value) {
        state.title = value
    },

    setOnboardingAgentData(state, value) {
        state.onboardingAgentData = value
    },

    async setOnboardingSteps(state, value) {
        let timer = 250 // Basic loading time set to wait 250/900 ms after some animations already present in the code.

        const route = this.$router.currentRoute.fullPath.split('/')[1] // To get actual route

        const stepsWithLoading = [4, 5, 6]  //Add here on witch step we need to wait.

        const onboarding = await this.$axios({method: 'get', url: `/fapi/onboardings/${value.userId}`})

        const storedStep = onboarding.data.onboarding_step

        //Basical state mutation
        state.onboardingSteps = 97

        state.onboardingSteps = value.step

        //Allow time to load the page before get position of an item
        stepsWithLoading.includes(value.step) ? timer = 900 : timer = 250

        // To make smooth transition set Opacity to 0 instantly
        if (document.getElementById('agentCard')) {

            document.getElementById('agentCard').classList.remove('fade')
        }

        // Allow to local storage to follow user steps save if sup than known
        if (value.step > storedStep) {

            await this.$axios({ method: 'put', url: `/fapi/onboardings/${value.userId}`, data: { onboarding_step: value.step } })
        }

        setTimeout(() => {
            if (value.step >= storedStep - 2) {

                let position = document.querySelector(`#onboarding-step-${value.step}`)

                //Reset Agent Position
                state.agentPosition = {}

                //Define Selector for create toggle button
                if (value.step === 6) {
                    position = document.querySelector(`#onboarding-step-2`)
                }
                if (value.step === 7) {

                    position = document.querySelector(`#onboarding-step-3`)
                }

                //Get positions & width of targeted element
                if (value.step !== 1) {

                    const rect = position?.getBoundingClientRect()

                    for (let key in rect) {

                        if (typeof rect[key] !== 'function') {
                            
                            state.agentPosition = { ...state.agentPosition, ...{ [key]: Math.round(rect[key]) } }
                        }
                    }
                }

                if (state.agentPosition.x === 99) {
                    state.agentPosition.top = 200
                    state.agentPosition.left = 200
                    state.agentPosition.x = 500
                    state.agentPosition.y = 500
                }

                //Add Fade after loading to make it smooth
                document.getElementById('agentCard')?.classList.add('fade')
            }

        }, timer)

    },
    async setInitializeSteps(state, value) {

        const onboarding = await this.$axios({method: 'get', url: `/fapi/onboardings/${value.userId}`})

        const localstep = onboarding.data.onboarding_step

        localstep ? state.onboardingSteps = localstep : state.onboardingSteps = 0
    },

    setDescription(state, value) {
        state.description = value
    },

    setBenefits(state, value) {
        state.benefits = value
    },

    setResetPassword(state, value) {
        state.reset_password = value
    },

    setStepIndex(state, value) {
        state.step.index = value
    },

    setStepLast(state, value) {
        state.step.last = value
    }
}

export const getters = {
    getTitle(state) {
        return state.title
    },

    getOnboardingAgentData(state) {
        return state.onboardingAgentData
    },

    getOnboardingSteps(state) {
        return state.onboardingSteps
    },

    getAgentPosition(state) {
        return state.agentPosition
    },

    getDescription(state) {
        return state.description
    },

    getBenefits(state) {
        return state.benefits
    },

    getResetPassword(state) {
        return state.reset_password
    },

    getStepIndex(state) {
        return state.step.index
    },

    getStepLast(state) {
        return state.step.last
    }
}
