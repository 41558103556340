export const strict = true

export const defaultState = () => {
    return {
        id: '',
        type: '',
        last_4_digits: '',
        iban: '',
        swift_code: '',
        bank_name: '',
        refund_bank_account_holder_name: '',
        holder_name: '',
        refund_iban: '',
        sepa_debit: '',
        card: '',
        default: false,
        card_brand: '',
        country: '',
        unique_mandate_reference: '',
        stripe_payment_method_id: '',
        relationships: {
            document: {}
        }
    }
}

export const state = defaultState

export const mutations = {
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },

    setId(state, value) {
        state.id = value
    },

    setType(state, value) {
        state.type = value
    },

    setLast4Digits(state, value) {
        state.last_4_digits = value
    },

    setIban(state, value) {
        state.iban = value
    },

    setSwiftCode(state, value) {
        state.swift_code = value
    },

    setBankName(state, value) {
        state.bank_name = value
    },

    setRefundBankAccountHolderName(state, value) {
        state.refund_bank_account_holder_name = value
    },

    setHolderName(state, value) {
        state.holder_name = value
    },

    setRefundIban(state, value) {
        state.refund_iban = value
    },

    setSepaDebit(state, value) {
        state.sepa_debit = value
    },

    setCard(state, value) {
        state.card = value
    },

    setCardBrand(state, value) {
        state.card_brand = value
    },

    setCountry(state, value) {
        state.country = value
    },

    setStripePaymentMethodId(state, value) {
        state.stripe_payment_method_id = value
    },

    setUniqueMandateReference(state, value) {
        state.unique_mandate_reference = value
    },

    setRelatedUmrDocument(state, value) {
        state.relationships.document = value
    },

    setDefault(state, value) {
        state.default = value
    }

}

export const getters = {
    getId(state) {
        return state.id
    },

    getType(state) {
        return state.type
    },

    getLast4Digits(state) {
        return state.last_4_digits
    },

    getIban(state) {
        return state.iban
    },

    getSwiftCode(state) {
        return state.swift_code
    },

    getBankName(state) {
        return state.bank_name
    },

    getRefundBankAccountHolderName(state) {
        return state.refund_bank_account_holder_name
    },

    getHolderName(state) {
        return state.holder_name
    },

    getRefundIban(state) {
        return state.refund_iban
    },

    getSepaDebit(state) {
        return state.sepa_debit
    },

    getCard(state) {
        return state.card
    },

    getCardBrand(state) {
        return state.card_brand
    },

    getCountry(state) {
        return state.country
    },

    getUniqueMandateReference(state) {
        return state.unique_mandate_reference
    },

    getRelatedDocument(state) {
        return state.relationships.document
    },

    getDefault(state) {
        return state.default
    }
}

export const actions = {
    async createPropertyManagerPaymentMethod({ getters, commit, dispatch, rootGetters }) {
        try {

            let request = await dispatch('formatRequest')

            const paymentMethod = await dispatch('tyltoApi/post', [request, { url: `/property-management-companies/${rootGetters['property-manager/getId']}/payment-methods` } ], { root: true })

            await dispatch('fillPaymentMethod', paymentMethod)

            return paymentMethod

        } catch (error) {

            throw error
        }
    },

    async createTenantPaymentMethod({ dispatch, rootGetters }, params) {
        try {

            let request = await dispatch('formatRequest')

            Object.keys(request).forEach(key => {
                if (request[key] === '') delete request[key]
            })

            const id = params.id ? params.id : rootGetters['profile/getId']

            const paymentMethod = await dispatch('tyltoApi/post', [request, { url: `/tenants/${id}/payment-methods` } ], { root: true })

            await dispatch('fillPaymentMethod', paymentMethod)

            return paymentMethod

        } catch (error) {

            throw error
        }
    },

    async fetchPaymentMethod({ dispatch }, paymentMethodId) {
        try {

            return await dispatch('tyltoApi/get', `/payment-methods/${paymentMethodId}`, { root: true })

        } catch (error) {

            throw error
        }
    },
    async fetchAndFillPaymentMethod({ dispatch }, paymentMethodId) {
        try {

            const paymentMethod = await dispatch('tyltoApi/get', `/payment-methods/${paymentMethodId}`, { root: true })

            await dispatch('fillPaymentMethod', paymentMethod)

            return paymentMethod

        } catch (error) {

            throw error
        }
    },

    async fillPaymentMethod({ commit }, paymentMethod) {
        commit('resetDefaultState')

        if (typeof paymentMethod['_jv'] !== 'undefined')
            commit('setId', paymentMethod['_jv'].id)

        if (typeof paymentMethod['type'] !== 'undefined')
            commit('setType', paymentMethod['type'])

        if (typeof paymentMethod['last_4_digits'] !== 'undefined')
            commit('setLast4Digits', paymentMethod['last_4_digits'])

        if (typeof paymentMethod['iban'] !== 'undefined')
            commit('setIban', paymentMethod['iban'])

        if (typeof paymentMethod['swift_code'] !== 'undefined')
            commit('setSwiftCode', paymentMethod['swift_code'])

        if (typeof paymentMethod['bank_name'] !== 'undefined')
            commit('setBankName', paymentMethod['bank_name'])

        if (typeof paymentMethod['refund_bank_account_holder_name'] !== 'undefined')
            commit('setRefundBankAccountHolderName', paymentMethod['refund_bank_account_holder_name'])

        if (typeof paymentMethod['holder_name'] !== 'undefined')
            commit('setHolderName', paymentMethod['holder_name'])

        if (typeof paymentMethod['refund_iban'] !== 'undefined')
            commit('setRefundIban', paymentMethod['refund_iban'])

        if (typeof paymentMethod['default'] !== 'undefined')
            commit('setDefault', paymentMethod['default'])

        if (typeof paymentMethod['card_brand'] !== 'undefined')
            commit('setCardBrand', paymentMethod['card_brand'])

        if (typeof paymentMethod['country'] !== 'undefined')
            commit('setCountry', paymentMethod['country'])

        if (typeof paymentMethod['unique_mandate_reference'] !== 'undefined')
            commit('setUniqueMandateReference', paymentMethod['unique_mandate_reference'])

        if (typeof paymentMethod['document'] !== 'undefined' && Object.keys(paymentMethod['document']).length)
            commit('setRelatedUmrDocument', paymentMethod['document'])
    },

    async deletePaymentMethod({ dispatch }, params) {
        try {

            const id = params.id

            return await dispatch('tyltoApi/delete', `/payment-methods/${id}`, { root: true })

        } catch (error) {

            throw error
        }
    },

    async formatRequest({ getters, commit }) {
        let paymentMethod = {
            default: getters['getDefault'],
            _jv: {
                type: 'payment-methods',
                relationships: {}
            }
        }

        if (getters['getId'] !== '') {
            paymentMethod['_jv'].id = getters['getId']
        }

        else if (getters['getType'] === 'sepa_credit_transfer') {

            paymentMethod['type'] = 'sepa_credit_transfer'

            paymentMethod['bank_name'] = getters['getBankName']

            paymentMethod['holder_name'] = getters['getHolderName']

            paymentMethod['iban'] = getters['getIban']

            paymentMethod['swift_code'] = getters['getSwiftCode']

            if (getters['getRefundIban'] !== '') paymentMethod['refund_iban'] = getters['getRefundIban']

            if (getters['getRefundBankAccountHolderName'] !== '') paymentMethod['refund_bank_account_holder_name'] = getters['getRefundBankAccountHolderName']

            return paymentMethod
        }

        else if (getters['getType'] === 'external_sepa_direct_debit') {

            paymentMethod['type'] = 'external_sepa_direct_debit'

            paymentMethod['iban'] = getters['getIban']

            paymentMethod['holder_name'] = getters['getHolderName']

            if (getters['unique_mandate_reference'] !== '') paymentMethod['unique_mandate_reference'] = getters['getUniqueMandateReference']

            if (Object.keys(getters['getRelatedDocument']).length) paymentMethod['_jv']['relationships']['document'] = {
                data: {
                    type: 'files',
                    id: getters['getRelatedDocument']
                }
            }

            return paymentMethod

        }

        else if (getters['getType'] === 'virtual_iban') {

            paymentMethod['type'] = 'virtual_iban'

            return paymentMethod
        }

        else if (getters['getType'] === 'cash') {

            paymentMethod['type'] = 'cash'

            return paymentMethod
        }

        else if (getters['getType'] === 'check') {

            paymentMethod['type'] = 'check'

            return paymentMethod
        }
    },

    async updatePaymentMethod({ getters, commit, dispatch }, params) {
        try {

            let request = await dispatch('formatRequest')

            const id = params.id

            const paymentMethod = await dispatch('tyltoApi/patch', [request, { url: `/payment-methods/${id}` } ], { root: true })

            await dispatch('fillPaymentMethod', paymentMethod)

            return paymentMethod

        } catch (error) {

            throw error
        }
    }
}
