export default () => {
    return {
        type: '',
        id: '',
        checkout_inventory: {},
        comment: '',
        relationships: {
            rental: {},
            document: {},
            items: []
        }
    }
}