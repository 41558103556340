<template>
    
    <modal id="welcome-agent-page" :title="$t('title')" @close="hide" :options="modalOptions" ref="modal">

        <div class="container">

            <FootSteps class="foot-step" />

            <h1 class="title">{{$t('title')}}</h1>

            <div class="sub-title">

                <p class="text-big"> {{$t('sub_title')}} </p>

            </div>

            <div class="list divider">

                <button-input  @click="setStep(0)" class="btn-agent background-white green-border-color green-text-color rounded-full" :text="$t('button_exit')" />

                <button-input  @click="setStep(2)" class="btn-agent background-green  white-text-color rounded-full" :text="$t('button_next')" />

            </div>

        </div>

    </modal>

</template>

<script>

    import party from 'party-js'
    import Modal from '~/components/modal'
    import CheckOutline from '~/components/icons/onboarding-icons/check-outline.vue';
    import FootSteps from '~/components/icons/onboarding-icons/foot-steps.vue';
    import Button from '~/components/table-list/table-cell/button.vue';
    import { mapGetters } from 'vuex'

    export default {

        components: { 
            Modal,
            CheckOutline, 
            FootSteps, 
            Button 
        },

        props: {
            step: {
                type: Number,
                default: 0
            },
            cardStyle: {
                type: Object,
                default: () => {}
            },
            cardClass: {
                type: Array,
                default: () => []
            }
        },

        data() {
            return {
                agentData: {
                    id: 0,
                    settings: {
                        active: true,
                        modal: true,
                        card: true
                    },
                    cardExtStyle: {
                        zIndex: 150,
                        width: 900,
                        height: 680,
                        center: true,
                        cardPositionType: 'relative',
                        cardPositionX: null,
                        cardPositionY: 100,
                        isTopLeft: true,
                        borderRadius: 7,
                        opacity: 1,
                        cardBgColor: 'white'
                    }

                }
            }
        },

        mounted() {
            this.party()
        },

        beforeMount() {
            this.agentData.id = Date.now()

            this.$store.commit('onboarding/setOnboardingAgentData', this.agentData)
        },

        methods: {

            show() {
                this.$refs.modal.show()
            },

            hide() {
                this.$refs.modal.hide()
            },
            
            async party(){
                const onboarding = await this.$axios({method: 'get', url: `/fapi/onboardings/${this.getAuthUser['_jv'].id}`})

                const onboardingFinished = onboarding.data.onboarding_finished
                
                if(!onboardingFinished){
                    
                    const element = document.getElementById('welcome-agent-page')

                    party.confetti(element, {
                        count: party.variation.range(0, 100),
                        size: party.variation.range(0.6, 1.4),
                        spread: party.variation.range(50, 80),
                    })
                }
            },

            async setStep(step) {
                this.$store.commit('onboarding/setOnboardingSteps', { step: step, userId: this.getAuthUser['_jv'].id })

                switch (step) {
                    case 0:
                        this.setAgentData({})

                        const onboardingFinished = {
                            onboarding_finished: true
                        }

                        await this.$axios({ method: 'put', url: `/fapi/onboardings/${this.getAuthUser['_jv'].id}`, data: onboardingFinished })
                    break

                    case 2:
                        const onboardingStep = {
                            onboarding_step: 1
                        }

                        await this.$axios({ method: 'put', url: `/fapi/onboardings/${this.getAuthUser['_jv'].id}`, data: onboardingStep })
                    break
                }
            },

            setAgentData(data) {
                this.$store.commit('onboarding/setOnboardingAgentData', data)
            }
        },

        computed: {
            modalOptions() {
                return {
                    defaultOpen: false,
                    largeOption: {
                        noLogoContainer: true,
                    },
                    hideButtons: false,
                    centerContainer: true,
                    innerModalStyle: {
                        'border': '5px solid #BED000',
                        'box-shadow': 'none !important'
                    }
                }
            },

            ...mapGetters({
                getAuthUser: 'getAuthUser'
            })
        }
    }
</script>

<i18n>
    {
        "fr": {
            "title": "Félicitations ! ",
            "sub_title": "Vous avez terminé notre parcours de formation, mais il vous reste encore beaucoups de fonctionnalitées à découvrir chez Oskar.",
            "chip_title": "Rappel",
            "chip_text": "Votre espace de démo est disponible pendant 7 jours à partir d'aujourd'hui. Ce message se détruira quand la boîte de dialogue disparaîtra",
            "button_exit": "Quitter l'onboarding",
            "button_next": "Recommencer le parcours"
        }
    }
</i18n>

<style lang="scss" scoped>
    .container {
        display: flex;
        flex-direction: column;

        p {
            color: rgba(46, 46, 46, 0.702);
            margin-bottom: 2rem;
        }

        .foot-step {
            height: 20%;
            margin: 0 auto 0 auto;
        }

        .sub-title {
            display: flex;
            flex-direction: row;
            margin: 25px auto 5px auto;

            .text-big{
                font-size: 1.6rem;
                text-align: center;
            }
        }
    
        .list {
            width: auto;
            display: flex;
            flex-flow: row;
            gap: 10vh;
            justify-content: center;
            align-content: center;

            &.divider {
                height: auto;
                margin-top: auto;
            }

            .btn-agent {
                align-items: center;
                padding: 14px 16px;
                width: 382px;
                height: 44px;
            }
        }

        .title {
            font-size: 32px;
            text-align: center;
            margin: 30px;
            font-weight: normal;
        }
    }
</style>
