export default {
    methods: {
        getFieldsChanged(activity) {
            const dataChanges = activity['data_changes'],
            fields = [];

            dataChanges.forEach((data) => {
                if (data['changes']) {
                    Object.keys(data['changes']).forEach((field) => {
                        if (field === "full_name") {
                            return;
                        }
                        fields.push(this.$t(`legal_person.${field}`));
                    });

                }
            })

            return fields
        }
    }
}