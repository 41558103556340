import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/accounting-account.js'), 'accounting-account.js')
  resolveStoreModules(require('../store/accounting-entry-set.js'), 'accounting-entry-set.js')
  resolveStoreModules(require('../store/accounting-entry.js'), 'accounting-entry.js')
  resolveStoreModules(require('../store/accounting-journal.js'), 'accounting-journal.js')
  resolveStoreModules(require('../store/activity.js'), 'activity.js')
  resolveStoreModules(require('../store/bank-account-transaction.js'), 'bank-account-transaction.js')
  resolveStoreModules(require('../store/bank-account.js'), 'bank-account.js')
  resolveStoreModules(require('../store/guarantee-fund.js'), 'guarantee-fund.js')
  resolveStoreModules(require('../store/invitation.js'), 'invitation.js')
  resolveStoreModules(require('../store/management-and-ownership.js'), 'management-and-ownership.js')
  resolveStoreModules(require('../store/management-report.js'), 'management-report.js')
  resolveStoreModules(require('../store/mediator.js'), 'mediator.js')
  resolveStoreModules(require('../store/notification.js'), 'notification.js')
  resolveStoreModules(require('../store/onboarding.js'), 'onboarding.js')
  resolveStoreModules(require('../store/payment-method.js'), 'payment-method.js')
  resolveStoreModules(require('../store/payment.js'), 'payment.js')
  resolveStoreModules(require('../store/payout.js'), 'payout.js')
  resolveStoreModules(require('../store/treasury-statements.js'), 'treasury-statements.js')
  resolveStoreModules(require('../store/automation/actions.js'), 'automation/actions.js')
  resolveStoreModules(require('../store/cost/actions.js'), 'cost/actions.js')
  resolveStoreModules(require('../store/cost/default.js'), 'cost/default.js')
  resolveStoreModules(require('../store/cost/getters.js'), 'cost/getters.js')
  resolveStoreModules(require('../store/cost/mutations.js'), 'cost/mutations.js')
  resolveStoreModules(require('../store/cost/state.js'), 'cost/state.js')
  resolveStoreModules(require('../store/fees/actions.js'), 'fees/actions.js')
  resolveStoreModules(require('../store/fees/default.js'), 'fees/default.js')
  resolveStoreModules(require('../store/fees/getters.js'), 'fees/getters.js')
  resolveStoreModules(require('../store/fees/mutations.js'), 'fees/mutations.js')
  resolveStoreModules(require('../store/fees/state.js'), 'fees/state.js')
  resolveStoreModules(require('../store/file/actions.js'), 'file/actions.js')
  resolveStoreModules(require('../store/file/default.js'), 'file/default.js')
  resolveStoreModules(require('../store/file/getters.js'), 'file/getters.js')
  resolveStoreModules(require('../store/file/mutations.js'), 'file/mutations.js')
  resolveStoreModules(require('../store/file/state.js'), 'file/state.js')
  resolveStoreModules(require('../store/inventory/actions.js'), 'inventory/actions.js')
  resolveStoreModules(require('../store/inventory/default.js'), 'inventory/default.js')
  resolveStoreModules(require('../store/inventory/getters.js'), 'inventory/getters.js')
  resolveStoreModules(require('../store/inventory/mutations.js'), 'inventory/mutations.js')
  resolveStoreModules(require('../store/inventory/state.js'), 'inventory/state.js')
  resolveStoreModules(require('../store/mandate/actions.js'), 'mandate/actions.js')
  resolveStoreModules(require('../store/mandate/default.js'), 'mandate/default.js')
  resolveStoreModules(require('../store/mandate/getters.js'), 'mandate/getters.js')
  resolveStoreModules(require('../store/mandate/mutations.js'), 'mandate/mutations.js')
  resolveStoreModules(require('../store/mandate/state.js'), 'mandate/state.js')
  resolveStoreModules(require('../store/profile/actions.js'), 'profile/actions.js')
  resolveStoreModules(require('../store/profile/default.js'), 'profile/default.js')
  resolveStoreModules(require('../store/profile/getters.js'), 'profile/getters.js')
  resolveStoreModules(require('../store/profile/mutations.js'), 'profile/mutations.js')
  resolveStoreModules(require('../store/profile/state.js'), 'profile/state.js')
  resolveStoreModules(require('../store/property-manager/actions.js'), 'property-manager/actions.js')
  resolveStoreModules(require('../store/property-manager/default.js'), 'property-manager/default.js')
  resolveStoreModules(require('../store/property-manager/getters.js'), 'property-manager/getters.js')
  resolveStoreModules(require('../store/property-manager/mutations.js'), 'property-manager/mutations.js')
  resolveStoreModules(require('../store/property-manager/state.js'), 'property-manager/state.js')
  resolveStoreModules(require('../store/property/actions.js'), 'property/actions.js')
  resolveStoreModules(require('../store/property/default.js'), 'property/default.js')
  resolveStoreModules(require('../store/property/getters.js'), 'property/getters.js')
  resolveStoreModules(require('../store/property/mutations.js'), 'property/mutations.js')
  resolveStoreModules(require('../store/property/state.js'), 'property/state.js')
  resolveStoreModules(require('../store/rent/actions.js'), 'rent/actions.js')
  resolveStoreModules(require('../store/rent/default.js'), 'rent/default.js')
  resolveStoreModules(require('../store/rent/getters.js'), 'rent/getters.js')
  resolveStoreModules(require('../store/rent/mutations.js'), 'rent/mutations.js')
  resolveStoreModules(require('../store/rent/state.js'), 'rent/state.js')
  resolveStoreModules(require('../store/rental/actions.js'), 'rental/actions.js')
  resolveStoreModules(require('../store/rental/default.js'), 'rental/default.js')
  resolveStoreModules(require('../store/rental/getters.js'), 'rental/getters.js')
  resolveStoreModules(require('../store/rental/mutations.js'), 'rental/mutations.js')
  resolveStoreModules(require('../store/rental/state.js'), 'rental/state.js')
  resolveStoreModules(require('../store/supplier/actions.js'), 'supplier/actions.js')
  resolveStoreModules(require('../store/user/actions.js'), 'user/actions.js')
  resolveStoreModules(require('../store/user/default.js'), 'user/default.js')
  resolveStoreModules(require('../store/user/getters.js'), 'user/getters.js')
  resolveStoreModules(require('../store/user/mutations.js'), 'user/mutations.js')
  resolveStoreModules(require('../store/user/state.js'), 'user/state.js')
  resolveStoreModules(require('../store/workflow/actions.js'), 'workflow/actions.js')
  resolveStoreModules(require('../store/workflow/default.js'), 'workflow/default.js')
  resolveStoreModules(require('../store/workflow/getters.js'), 'workflow/getters.js')
  resolveStoreModules(require('../store/workflow/mutations.js'), 'workflow/mutations.js')
  resolveStoreModules(require('../store/workflow/state.js'), 'workflow/state.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
