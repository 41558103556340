import { queryBuilder, queryResponse } from '~/utils/query'

export const strict = false

export const defaultState = () => {
    return {
        amount: 0,
        initiated_at: '',
        created_at: '',
        updated_at: '',
        reference: '',
        status: '',
        consent_url: '',
        redirect_url: '',
        id: '',
        relationships: {
            costs: [],
            payments: [],
            management_reports: {},
            beneficiary: {},
            bank_account: {}
        }
    }
}

export const state = defaultState

export const mutations = {
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },

    setAmount(state, value){
        state.amount = value
    },

    setInitiatedAt(state, value){
        state.initiated_at = value
    },

    setCreatedAt(state, value){
        state.created_at = value
    },

    setUpdatedAt(state, value){
        state.updated_at = value
    },

    setReference(state, value){
        state.reference = value
    },

    setStatus(state, value){
        state.status = value
    },

    setConsentUrl(state, value){
        state.consent_url = value
    },

    setRedirectUrl(state, value){
        state.redirect_url = value
    },

    setId(state, value) {
        state.id = value
    },

    setRelatedCosts(state, value){
        state.relationships.costs = value
    },

    setRelatedPayments(state, value){
        state.relationships.payments = value
    },

    setRelatedManagementReport(state, value){
        state.relationships.management_reports = value
    },

    setRelatedBeneficiary(state, value){
        state.relationships.beneficiary = value
    },

    setRelatedBankAccount(state, value){
        state.relationships.bank_account = value
    }
}

export const getters = {
    getAmount(state){
        return state.amount
    },

    getInitiatedAt(state){
        return state.initiated_at
    },

    getCreatedAt(state){
        return state.created_at
    },

    getUpdatedAt(state){
        return state.updated_at
    },

    getReference(state){
        return state.reference
    },

    getStatus(state){
        return state.status
    },

    getConsentUrl(state){
        return state.consent_url
    },

    getRedirectUrl(state){
        return state.redirect_url
    },

    getId(state) {
        return state.id
    },

    getRelatedCosts(state){
        return state.relationships.costs
    },

    getRelatedPayments(state){
        return state.relationships.payments
    },

    getRelatedManagementReport(state){
        return state.relationships.management_reports
    },

    getRelatedBeneficiary(state){
        return state.relationships.beneficiary
    },

    getRelatedBankAccount(state){
        return state.relationships.bank_account
    }
}

export const actions = {
    async createPayout({ dispatch, getters }){
        try {

            let payoutRequest = await dispatch('formatPayoutRequest')
            
            const payout =  await dispatch('tyltoApi/post', [payoutRequest, {url: `bank-accounts/${getters['getRelatedBankAccount']['_jv'].id}/payouts`}], { root: true })

            await dispatch('fillPayout', payout)

            return payout

        } catch (error) {

            throw error
        }
    },

    async fetchPayout({ dispatch, rootGetters }, payoutId) {
        try {

            let payout = rootGetters['tyltoApi/get'](`payouts/${payoutId}`)

            if (typeof payout === 'undefined' || !Object.keys(payout).length){

                payout = await dispatch('tyltoApi/get', `payouts/${payoutId}`, { root: true })
            }

            await dispatch('fillPayout', payout)

            return payout

        } catch (error) {

            throw error
        }
    },

    async fetchLandlordPayouts({ dispatch, rootGetters }, params) {
        try {

            const response = await dispatch('tyltoApi/get', [`landlords/${rootGetters['profile/getId']}/payouts`, { params: queryBuilder(params) }], { root: true })

            return queryResponse(response)

        } catch(error) {

            throw error
        }
    },

    async fetchBankAccountPayouts({ dispatch }, { filters, params }) {
        try {

            const response = await dispatch('tyltoApi/get', [`bank-accounts/${params.bankAccountId}/payouts`, { params: queryBuilder({ filters }) }], { root: true })

            return queryResponse(response)

        } catch(error) {

            throw error
        }
    },

    async fetchPropertyManagerPayouts({ dispatch, rootGetters }, params) {
        try {

            const response = await dispatch('tyltoApi/get', [`property-management-companies/${rootGetters['property-manager/getId']}/payouts`, { params: queryBuilder(params) }], { root: true })

            return queryResponse(response)

        } catch(error) {

            throw error
        }
    },

    async fetchCostsRelationship({ dispatch, commit, getters }, payoutId) {
        try {

            let payoutCosts = []

            const id = typeof payoutId !== 'undefined' ? payoutId : getters['getId']

            const { data } = await this.$axios({ method: 'get', url: `payouts/${id}/relationships/costs` })

            if (data['data'] !== null) payoutCosts = await Promise.all(data['data'].map(async cost => await dispatch('cost/fetchCost', cost['_jv']['id'], { root: true })))

            commit('setRelatedCosts', payoutCosts)

            return payoutCosts

        } catch (error) {

            throw error
        }
    },

    async fetchPaymentsRelationship({ dispatch, commit, getters }, params) {
        try {

            let payoutPayments = []

            if (Object.keys(getters['getRelatedPayments']).length) payoutPayments = getters['getRelatedPayments']

            else {

                const id = getters['getId'] !== '' ? getters['getId'] : params.payoutId

                const { data } = await this.$axios({ method: 'get', url: `payouts/${id}/relationships/payments` })

                if (data['data'] !== null) payoutPayments = await Promise.all(data['data'].map(async payment => await dispatch('bank-account-transaction/fetchBankAccountTransaction', payment['id'], { root: true })))
            }

            commit('setRelatedPayments', payoutPayments)

            return payoutPayments

        } catch (error) {

            throw error
        }
    },

    async fetchBeneficiaryRelationship({ dispatch, commit, getters }, payoutId) {
        try {

            let payoutBeneficiary = {}

            if (Object.keys(getters['getRelatedBeneficiary']).length) payoutBeneficiary = getters['getRelatedBeneficiary']

            else {

                const id = getters['getId'] !== '' ? getters['getId'] : payoutId

                const { data } = await this.$axios({ method: 'get', url: `payouts/${id}/relationships/beneficiary` })

                if (data['data'] !== null) payoutBeneficiary = await dispatch('bank-account/fetchPayoutBankAccount', data['data']['id'], { root: true })
            }

            commit('setRelatedBeneficiary', payoutBeneficiary)

            return payoutBeneficiary
        
        } catch (error) {

            throw error
        }
    },

    async updatePaymentsRelationship({ getters, commit }) {
        try {

            let payments = {
                data: getters['getRelatedPayments'].map(payment => {
                    return {
                        type: 'bank-account-transactions',
                        id: payment._jv.id
                    }
                })
            }

            const response = await this.$axios({ method: 'patch', url: 'payouts/' + getters['getId'] + '/relationships/payments', data: payments })

            commit('tyltoApi/deleteRecord', { _jv: { type: 'rents', id: getters['getId'] } }, { root: true })

            return response

        } catch(error) {

            throw error
        }
    },

    async createConsent({}, payoutIds) {
        try {

            const data = {
                relationships: {
                    redirect_url: `${window.location.origin}/swan/consent-callback`,
                    payouts: {
                        data: payoutIds.map((payoutId => ({
                            id: payoutId,
                            type: 'payouts'
                        })))
                    }
                }
            }

            return await this.$axios({ method: 'post', url: 'swan/consents', data })

        } catch(error) {

            throw error
        }
    },

    async formatPayoutRequest({ getters }) {
        let payoutRequest = {
            amount: getters['getAmount'],
            initiated_at: getters['getInitiatedAt'],
            _jv: {
                type: 'payouts',
                relationships: {
                    beneficiary: {
                        data: {
                            type: "payout-bank-accounts",
                            id: getters['getRelatedBeneficiary']['_jv'].id
                        }
                    }
                }
            }
        }
        
        if (getters['getReference']){
            payoutRequest.reference = getters['getReference']
        }

        if (getters['getRedirectUrl']){
            payoutRequest.redirect_url = getters['getRedirectUrl']
        }

        if (getters['getStatus']){
            payoutRequest.status = getters['getStatus']
        }

        if (getters['getId'] !== '') payoutRequest['_jv'].id = getters['getId']

        if (getters['getRelatedPayments'].length) payoutRequest['_jv'].relationships.payments = {
            data: getters['getRelatedPayments'].map(payment => {
                return {
                    type: 'payments',
                    id: payment._jv.id
                }
            })
        }

        if (getters['getRelatedCosts'].length) payoutRequest['_jv'].relationships.costs = {
            data: getters['getRelatedCosts'].map(cost => {
                return {
                    type: 'costs',
                    id: cost._jv.id
                }
            })
        }

        return payoutRequest
    },

    async fillPayout({ commit }, payout){
        commit('resetDefaultState')

        if (typeof payout['amount'] !== 'undefined')
            commit('setAmount', payout['amount'])

        if (typeof payout['initiated_at'] !== 'undefined')
            commit('setInitiatedAt', payout['initiated_at'])

        if (typeof payout['created_at'] !== 'undefined')
            commit('setCreatedAt', payout['created_at'])

        if (typeof payout['updated_at'] !== 'undefined')
            commit('setUpdatedAt', payout['updated_at'])

        if (typeof payout['reference'] !== 'undefined')
            commit('setReference', payout['reference'])

        if (typeof payout['status'] !== 'undefined')
            commit('setStatus', payout['status'])

        if (typeof payout['redirect_url'] !== 'undefined')
            commit('setRedirectUrl', payout['redirect_url'])

        if (typeof payout['_jv'] !== 'undefined') {
            commit('setId', payout['_jv'].id)

            if (typeof payout['_jv']['meta'] !== 'undefined' && payout['_jv']['meta']['consent_url'] !== 'undefined')
                commit('setConsentUrl', payout['_jv']['meta']['consent_url'])
        }

        if (typeof payout['costs'] !== 'undefined' && payout['costs'].length)
            commit('setRelatedCosts', payout['costs'])

        if (typeof payout['management_reports'] !== 'undefined' && payout['management_reports'].length)
            commit('setRelatedManagementReport', payout['management_reports'])

        if (typeof payout['bank_account'] !== 'undefined' && Object.keys(payout['bank_account']).length)
            commit('setRelatedBankAccount', payout['bank_account'])

        if (typeof payout['beneficiary'] !== 'undefined' && Object.keys(payout['beneficiary']).length)
            commit('setRelatedBeneficiary', payout['beneficiary'])

        if (typeof payout['payments'] !== 'undefined' && payout['payments'].length)
            commit('setRelatedPayments', payout['payments'])
    }
}
