<template>
    
    <header class="courses-navigation-bar">

        <ul class="list">
            
            <li class="nav-item logo"> <logo /> </li>

            <li class="nav-item title">{{ title }}</li>

            <li class="nav-item saving-status">{{ subtitle }}</li>

            <li class="nav-item exit" @click="$emit('exit')"></li>

        </ul>

    </header>

</template>

<script>
    import Logo from '~/components/logo'
    import ConfirmBeforeExitModal from '~/components/modal/confirm-before-exit'
    
    export default {
        components: { 
            Logo,
            ConfirmBeforeExitModal
        },

        props: {
            title: {
                type: String,
                required: true
            },
            subtitle: {
                type: String,
                required: true
            }
        }
    }
</script>

<style lang="scss">
    $white: #ffffff;
    $white-bar: #EFEFEF;
    $progress-bar-background-color: rgba(#6c6c6c, 0.25);
    $progress-bar-color: #BED000;
    $header-height: 68px;

    .courses-navigation-bar {
        min-height: $header-height;
        display: flex;
        flex-direction: column;
        position: sticky;
        top: 0;
        z-index: 9;

        .list {
            display: flex;
            flex-direction: row;
            flex: 1;
            background: $white;
            color: white;

            .nav-item {
                display: flex;
                align-items: center;
                padding: 15px 30px;
                
                &.logo {
                    border-right: 1px solid $white-bar;
                }

                &.title {
                    font-size: 13.5pt;
                    color: #393939;
                }

                &.saving-status {
                    margin-top: 3px;
                    font-size: 11pt;
                    color: #9B9B9B;
                }

                &.exit {
                    $icon-width: 16px;
                    
                    margin-left: auto;
                    border-left: 1px solid $white-bar;
                    position: relative;
                    flex-basis: $icon-width;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    
                    &:before, &:after {
                        position: absolute;
                        content: '';
                        display: flex;
                        background: #525254;
                        border-radius: 2px;
                        width: 2px;
                        height: $icon-width;
                    }

                    &:before {
                        transform: rotate(45deg);
                    }

                    &:after {
                        transform: rotate(-45deg);
                    }
                }

            }
        }
    }
</style>
