<template>

    <div :class="['step-group', { 'collapsed': collapsed }, { 'open': open }]">

        <div :class="['group-title', { 'collapsed': collapsed }]" v-if="name">

            <div class="icon-square" v-if="icon === 'payment-method'">

                <svg-icon class="card-icon" :viewbox="'0 0 24 24'" :width="'24'" :height="'24'" iconColor="#BED000"><card-icon /></svg-icon>

            </div>

            <div class="profile-icon" v-if="icon === 'profile'">

                <svg-icon class="svg-profile-icon" :viewbox="'0 0 24 24'" :width="'24'" :height="'24'" iconColor="#525254"><tenant-icon /></svg-icon>

            </div>

            <h3 class="group-name">{{ formatted($t(name)) }}</h3>

            <div :class="['toggle-button', { 'open': open }]" @click="toggle" v-if="collapsed"></div>

        </div>

        <tooltip class="tooltip" :text="tooltip" ref="tooltip" v-if="tooltip && !hideTooltip" />

        <div class="step-inputs" :style="customStyle" v-if="open">

            <form-info class="form-info" :text="formInfo" v-if="formInfo" />

            <slot />

        </div>

    </div>

</template>

<script>
    import Tooltip from '~/components/tooltip'
    import FormInfo from '~/components/form-info'
    import SvgIcon from '~/components/svg-icon'
    import { CardIcon } from '~/components/icons/bank-icons'
    import { TenantIcon } from '~/components/icons/table-cell-icons'

    export default {
        components: {
            Tooltip,
            FormInfo,
            SvgIcon,
            CardIcon,
            TenantIcon
        },

        data() {
            return {
                open: true,
                id: this.$parent.id,
                hideTooltip: false
            }
        },

        props: {
            name: {
                type: String,
                required: false
            },
            collapsed: {
                type: Boolean,
                default: false
            },
            tooltip: {
                type: String,
                required: false
            },
            formInfo: {
                type: String,
                required: false
            },
            customStyle: {
                type: Object,
                default() {
                    return {}
                }
            },
            icon: {
                type: String,
                default: ''
            }
        },

        created() {
            this.open = this.collapsed ? false : true
        },

        methods: {
            toggle() {
                this.open = !this.open

                this.hideTooltip = true
            },

            formatted(option) {
                return option.charAt(0).toUpperCase() + option.slice(1)
            }
        }
    }
</script>

<style lang="scss" scoped>
    $border-color: #F2F2F2;
    $button-color: #525254;

    .step-group {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 36px;
        position: relative;

        &:last-child {
            margin-bottom: 0;
        }

        &.collapsed {
            border-top: 1px solid $border-color;
            border-bottom: 1px solid $border-color;

            .group-title {
                padding: 30px 0;
            }

            &.open {
                padding-bottom: 30px;
            }
        }

        .group-title {
            position: relative;
            flex-basis: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            gap: 10px;

            &.collapsed {
                cursor: pointer;

                .group-name {
                    margin-bottom: 0;
                }
            }
            .icon-square {
                background: #BED00033;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                width: 28px;
                height: 28px;
            }

            .profile-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                width: 28px;
                height: 28px;
            }

            .group-name {
                color: #BED000;
                font-size: 13.5pt;
                font-weight: 600;

            }

            .toggle-button {
                width: 24px;
                height: 24px;
                background: #EFEFEF;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &:after {
                    content: '';
                    display: flex;
                    border-right: 2px solid $button-color;
                    border-bottom: 2px solid $button-color;
                    transform: rotate(45deg);
                    position: relative;
                    top: -2px;
                    width: 30%;
                    height: 30%;
                }

                &.open {
                    transform: rotate(180deg);
                }
            }
        }

        .step-inputs {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 20px;
            grid-row-gap: 32px;
            width: 100%;

            & > * {
                margin-bottom: 0;
            }

            .form-info {
                grid-column-start: 1;
                grid-column-end: 3;
            }

            .fullsize,
            .text-area,
            .phone-number,
            .address-autocomplete,
            .file-upload {
                grid-column-start: 1;
                grid-column-end: 3;
            }

            .form-group {
                align-items: initial;
            }
        }
    }
</style>
