export default () => {
    return {
        amount_in_percent: 0,
        amount_in_currency: 0,
        applies_to: [],
        id: '',
        type: '',
        relationships: {
            level: {}
        }
    }
}