import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _adfcbe76 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _ff45c400 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5daafbc0 = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _114dae58 = () => interopDefault(import('../pages/oskar-courses/index.vue' /* webpackChunkName: "pages/oskar-courses/index" */))
const _7817c0a8 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _b6e93b5a = () => interopDefault(import('../pages/unauthenticated-signing-page/index.vue' /* webpackChunkName: "pages/unauthenticated-signing-page/index" */))
const _b7216ff8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _368ac0ff = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _773aa060 = () => interopDefault(import('../pages/auth/renew-password.vue' /* webpackChunkName: "pages/auth/renew-password" */))
const _f2baaf64 = () => interopDefault(import('../pages/auth/reset-password.vue' /* webpackChunkName: "pages/auth/reset-password" */))
const _6056cbee = () => interopDefault(import('../pages/auth/switch-account.vue' /* webpackChunkName: "pages/auth/switch-account" */))
const _54811eca = () => interopDefault(import('../pages/dashboard/bank-accounts.vue' /* webpackChunkName: "pages/dashboard/bank-accounts" */))
const _492a69f4 = () => interopDefault(import('../pages/dashboard/landlords.vue' /* webpackChunkName: "pages/dashboard/landlords" */))
const _6b7286db = () => interopDefault(import('../pages/dashboard/notifications.vue' /* webpackChunkName: "pages/dashboard/notifications" */))
const _75509e60 = () => interopDefault(import('../pages/dashboard/properties.vue' /* webpackChunkName: "pages/dashboard/properties" */))
const _ddf13fcc = () => interopDefault(import('../pages/dashboard/suppliers.vue' /* webpackChunkName: "pages/dashboard/suppliers" */))
const _60a4f288 = () => interopDefault(import('../pages/dashboard/tenants.vue' /* webpackChunkName: "pages/dashboard/tenants" */))
const _05e9378a = () => interopDefault(import('../pages/dashboard/unpaid-costs.vue' /* webpackChunkName: "pages/dashboard/unpaid-costs" */))
const _153a8f54 = () => interopDefault(import('../pages/dashboard/unpaid-rent.vue' /* webpackChunkName: "pages/dashboard/unpaid-rent" */))
const _d16c3142 = () => interopDefault(import('../pages/error/404.vue' /* webpackChunkName: "pages/error/404" */))
const _6e2b85cc = () => interopDefault(import('../pages/onboarding/confirm_invitation.vue' /* webpackChunkName: "pages/onboarding/confirm_invitation" */))
const _0d8a4ef6 = () => interopDefault(import('../pages/onboarding/demo.vue' /* webpackChunkName: "pages/onboarding/demo" */))
const _0c2a9748 = () => interopDefault(import('../pages/settings/company-details.vue' /* webpackChunkName: "pages/settings/company-details" */))
const _23a821b1 = () => interopDefault(import('../pages/swan/callback.vue' /* webpackChunkName: "pages/swan/callback" */))
const _15e36c84 = () => interopDefault(import('../pages/swan/consent-callback.vue' /* webpackChunkName: "pages/swan/consent-callback" */))
const _2f5e3fb2 = () => interopDefault(import('../pages/dashboard/accounting/listing.vue' /* webpackChunkName: "pages/dashboard/accounting/listing" */))
const _5bdf92b6 = () => interopDefault(import('../pages/dashboard/accounting/listing/accounting-accounts.vue' /* webpackChunkName: "pages/dashboard/accounting/listing/accounting-accounts" */))
const _324320f0 = () => interopDefault(import('../pages/dashboard/accounting/listing/accounting-entries.vue' /* webpackChunkName: "pages/dashboard/accounting/listing/accounting-entries" */))
const _414e669c = () => interopDefault(import('../pages/dashboard/accounting/listing/accounting-entry-sets.vue' /* webpackChunkName: "pages/dashboard/accounting/listing/accounting-entry-sets" */))
const _1d63f828 = () => interopDefault(import('../pages/dashboard/accounting/listing/accounting-journals.vue' /* webpackChunkName: "pages/dashboard/accounting/listing/accounting-journals" */))
const _06cb73b8 = () => interopDefault(import('../pages/viewer/CGU/cgu.vue' /* webpackChunkName: "pages/viewer/CGU/cgu" */))
const _404fc4ba = () => interopDefault(import('../pages/workflow/account-statement/create.vue' /* webpackChunkName: "pages/workflow/account-statement/create" */))
const _7ad49dfd = () => interopDefault(import('../pages/workflow/landlord/create.vue' /* webpackChunkName: "pages/workflow/landlord/create" */))
const _51e08068 = () => interopDefault(import('../pages/workflow/property/create.vue' /* webpackChunkName: "pages/workflow/property/create" */))
const _a42bd7de = () => interopDefault(import('../pages/workflow/supplier/create.vue' /* webpackChunkName: "pages/workflow/supplier/create" */))
const _b5f4c61a = () => interopDefault(import('../pages/workflow/tenant/create.vue' /* webpackChunkName: "pages/workflow/tenant/create" */))
const _fdc817e2 = () => interopDefault(import('../pages/dashboard/accounting/accounting-entry-set/_accountingEntrySetId/index.vue' /* webpackChunkName: "pages/dashboard/accounting/accounting-entry-set/_accountingEntrySetId/index" */))
const _4f10d7eb = () => interopDefault(import('../pages/dashboard/landlord/_landlordId/index.vue' /* webpackChunkName: "pages/dashboard/landlord/_landlordId/index" */))
const _b7ed5e02 = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/index.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/index" */))
const _74e38fce = () => interopDefault(import('../pages/viewer/file/_fileId.vue' /* webpackChunkName: "pages/viewer/file/_fileId" */))
const _e29c2714 = () => interopDefault(import('../pages/viewer/inventory/_inventoryId.vue' /* webpackChunkName: "pages/viewer/inventory/_inventoryId" */))
const _3bcd4e65 = () => interopDefault(import('../pages/viewer/management-report/_managementReportId.vue' /* webpackChunkName: "pages/viewer/management-report/_managementReportId" */))
const _3833d476 = () => interopDefault(import('../pages/viewer/mandate/_mandateId.vue' /* webpackChunkName: "pages/viewer/mandate/_mandateId" */))
const _23c8e236 = () => interopDefault(import('../pages/viewer/payment/_paymentId.vue' /* webpackChunkName: "pages/viewer/payment/_paymentId" */))
const _6954a608 = () => interopDefault(import('../pages/viewer/rent/_rentId.vue' /* webpackChunkName: "pages/viewer/rent/_rentId" */))
const _08c8d2c4 = () => interopDefault(import('../pages/viewer/rental/_rentalId.vue' /* webpackChunkName: "pages/viewer/rental/_rentalId" */))
const _fa12135e = () => interopDefault(import('../pages/dashboard/landlord/_landlordId/documents.vue' /* webpackChunkName: "pages/dashboard/landlord/_landlordId/documents" */))
const _55796f3e = () => interopDefault(import('../pages/dashboard/landlord/_landlordId/informations.vue' /* webpackChunkName: "pages/dashboard/landlord/_landlordId/informations" */))
const _c5076328 = () => interopDefault(import('../pages/dashboard/landlord/_landlordId/management-reports.vue' /* webpackChunkName: "pages/dashboard/landlord/_landlordId/management-reports" */))
const _a7e9fd2c = () => interopDefault(import('../pages/dashboard/landlord/_landlordId/properties.vue' /* webpackChunkName: "pages/dashboard/landlord/_landlordId/properties" */))
const _4624c565 = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/documents.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/documents" */))
const _f56350ac = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/informations.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/informations" */))
const _58440f50 = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/payment-methods.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/payment-methods" */))
const _13c18f88 = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/rentals.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/rentals" */))
const _5dd73087 = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/rents.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/rents" */))
const _075c3622 = () => interopDefault(import('../pages/workflow/property/_propertyId/cost/create.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/cost/create" */))
const _41a39aca = () => interopDefault(import('../pages/workflow/property/_propertyId/inventory/create.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/inventory/create" */))
const _693326b0 = () => interopDefault(import('../pages/workflow/property/_propertyId/mandate/create.old.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/mandate/create.old" */))
const _467cdff7 = () => interopDefault(import('../pages/workflow/property/_propertyId/mandate/form.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/mandate/form" */))
const _60032194 = () => interopDefault(import('../pages/workflow/property/_propertyId/rent/create.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/rent/create" */))
const _29ac696b = () => interopDefault(import('../pages/workflow/property/_propertyId/rental/create.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/rental/create" */))
const _597b3932 = () => interopDefault(import('../pages/workflow/property/_propertyId/tenant-cost/create.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/tenant-cost/create" */))
const _24ae9478 = () => interopDefault(import('../pages/viewer/property/_propertyId/cost/_costId.vue' /* webpackChunkName: "pages/viewer/property/_propertyId/cost/_costId" */))
const _3de56b74 = () => interopDefault(import('../pages/workflow/property/_propertyId/rental/_rentalId/replace-tenant/index.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/rental/_rentalId/replace-tenant/index" */))
const _7b877d90 = () => interopDefault(import('../pages/landlord/_landlordId/index.vue' /* webpackChunkName: "pages/landlord/_landlordId/index" */))
const _754ae53a = () => interopDefault(import('../pages/property/_propertyId/index.vue' /* webpackChunkName: "pages/property/_propertyId/index" */))
const _b5f30130 = () => interopDefault(import('../pages/supplier/_supplierId/index.vue' /* webpackChunkName: "pages/supplier/_supplierId/index" */))
const _d1d2ddb8 = () => interopDefault(import('../pages/tenant/_tenantId/index.vue' /* webpackChunkName: "pages/tenant/_tenantId/index" */))
const _baaf7d14 = () => interopDefault(import('../pages/landlord/_landlordId/documents.vue' /* webpackChunkName: "pages/landlord/_landlordId/documents" */))
const _6e5115f9 = () => interopDefault(import('../pages/landlord/_landlordId/informations.vue' /* webpackChunkName: "pages/landlord/_landlordId/informations" */))
const _67acbce7 = () => interopDefault(import('../pages/landlord/_landlordId/management-reports.vue' /* webpackChunkName: "pages/landlord/_landlordId/management-reports" */))
const _7303a54b = () => interopDefault(import('../pages/landlord/_landlordId/payouts.vue' /* webpackChunkName: "pages/landlord/_landlordId/payouts" */))
const _8c472f0a = () => interopDefault(import('../pages/landlord/_landlordId/update.vue' /* webpackChunkName: "pages/landlord/_landlordId/update" */))
const _3aafe689 = () => interopDefault(import('../pages/property/_propertyId/contracts.vue' /* webpackChunkName: "pages/property/_propertyId/contracts" */))
const _d33da6a4 = () => interopDefault(import('../pages/property/_propertyId/costs.vue' /* webpackChunkName: "pages/property/_propertyId/costs" */))
const _908877c0 = () => interopDefault(import('../pages/property/_propertyId/documents.vue' /* webpackChunkName: "pages/property/_propertyId/documents" */))
const _2f18c4c2 = () => interopDefault(import('../pages/property/_propertyId/rents.vue' /* webpackChunkName: "pages/property/_propertyId/rents" */))
const _0ef413de = () => interopDefault(import('../pages/property/_propertyId/update.vue' /* webpackChunkName: "pages/property/_propertyId/update" */))
const _21c65dbe = () => interopDefault(import('../pages/supplier/_supplierId/informations.vue' /* webpackChunkName: "pages/supplier/_supplierId/informations" */))
const _7f84bcba = () => interopDefault(import('../pages/supplier/_supplierId/update.vue' /* webpackChunkName: "pages/supplier/_supplierId/update" */))
const _729b6b0a = () => interopDefault(import('../pages/tenant/_tenantId/documents.vue' /* webpackChunkName: "pages/tenant/_tenantId/documents" */))
const _6f36d836 = () => interopDefault(import('../pages/tenant/_tenantId/informations.vue' /* webpackChunkName: "pages/tenant/_tenantId/informations" */))
const _672de386 = () => interopDefault(import('../pages/tenant/_tenantId/payment-methods.vue' /* webpackChunkName: "pages/tenant/_tenantId/payment-methods" */))
const _102fc767 = () => interopDefault(import('../pages/tenant/_tenantId/update.vue' /* webpackChunkName: "pages/tenant/_tenantId/update" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/dashboard",
    component: _adfcbe76,
    name: "dashboard___fr"
  }, {
    path: "/en",
    component: _ff45c400,
    name: "index___en"
  }, {
    path: "/onboarding",
    component: _5daafbc0,
    name: "onboarding___fr"
  }, {
    path: "/oskar-courses",
    component: _114dae58,
    name: "oskar-courses___fr"
  }, {
    path: "/settings",
    component: _7817c0a8,
    name: "settings___fr"
  }, {
    path: "/unauthenticated-signing-page",
    component: _b6e93b5a,
    name: "unauthenticated-signing-page___fr"
  }, {
    path: "/auth/login",
    component: _b7216ff8,
    name: "auth-login___fr"
  }, {
    path: "/auth/logout",
    component: _368ac0ff,
    name: "auth-logout___fr"
  }, {
    path: "/auth/renew-password",
    component: _773aa060,
    name: "auth-renew-password___fr"
  }, {
    path: "/auth/reset-password",
    component: _f2baaf64,
    name: "auth-reset-password___fr"
  }, {
    path: "/auth/switch-account",
    component: _6056cbee,
    name: "auth-switch-account___fr"
  }, {
    path: "/dashboard/bank-accounts",
    component: _54811eca,
    name: "dashboard-bank-accounts___fr"
  }, {
    path: "/dashboard/landlords",
    component: _492a69f4,
    name: "dashboard-landlords___fr"
  }, {
    path: "/dashboard/notifications",
    component: _6b7286db,
    name: "dashboard-notifications___fr"
  }, {
    path: "/dashboard/properties",
    component: _75509e60,
    name: "dashboard-properties___fr"
  }, {
    path: "/dashboard/suppliers",
    component: _ddf13fcc,
    name: "dashboard-suppliers___fr"
  }, {
    path: "/dashboard/tenants",
    component: _60a4f288,
    name: "dashboard-tenants___fr"
  }, {
    path: "/dashboard/unpaid-costs",
    component: _05e9378a,
    name: "dashboard-unpaid-costs___fr"
  }, {
    path: "/dashboard/unpaid-rent",
    component: _153a8f54,
    name: "dashboard-unpaid-rent___fr"
  }, {
    path: "/en/dashboard",
    component: _adfcbe76,
    name: "dashboard___en"
  }, {
    path: "/en/onboarding",
    component: _5daafbc0,
    name: "onboarding___en"
  }, {
    path: "/en/oskar-courses",
    component: _114dae58,
    name: "oskar-courses___en"
  }, {
    path: "/en/settings",
    component: _7817c0a8,
    name: "settings___en"
  }, {
    path: "/en/unauthenticated-signing-page",
    component: _b6e93b5a,
    name: "unauthenticated-signing-page___en"
  }, {
    path: "/error/404",
    component: _d16c3142,
    name: "error-404___fr"
  }, {
    path: "/onboarding/confirm_invitation",
    component: _6e2b85cc,
    name: "onboarding-confirm_invitation___fr"
  }, {
    path: "/onboarding/demo",
    component: _0d8a4ef6,
    name: "onboarding-demo___fr"
  }, {
    path: "/settings/company-details",
    component: _0c2a9748,
    name: "settings-company-details___fr"
  }, {
    path: "/swan/callback",
    component: _23a821b1,
    name: "swan-callback___fr"
  }, {
    path: "/swan/consent-callback",
    component: _15e36c84,
    name: "swan-consent-callback___fr"
  }, {
    path: "/dashboard/accounting/listing",
    component: _2f5e3fb2,
    name: "dashboard-accounting-listing___fr",
    children: [{
      path: "accounting-accounts",
      component: _5bdf92b6,
      name: "dashboard-accounting-listing-accounting-accounts___fr"
    }, {
      path: "accounting-entries",
      component: _324320f0,
      name: "dashboard-accounting-listing-accounting-entries___fr"
    }, {
      path: "accounting-entry-sets",
      component: _414e669c,
      name: "dashboard-accounting-listing-accounting-entry-sets___fr"
    }, {
      path: "accounting-journals",
      component: _1d63f828,
      name: "dashboard-accounting-listing-accounting-journals___fr"
    }]
  }, {
    path: "/en/auth/login",
    component: _b7216ff8,
    name: "auth-login___en"
  }, {
    path: "/en/auth/logout",
    component: _368ac0ff,
    name: "auth-logout___en"
  }, {
    path: "/en/auth/renew-password",
    component: _773aa060,
    name: "auth-renew-password___en"
  }, {
    path: "/en/auth/reset-password",
    component: _f2baaf64,
    name: "auth-reset-password___en"
  }, {
    path: "/en/auth/switch-account",
    component: _6056cbee,
    name: "auth-switch-account___en"
  }, {
    path: "/en/dashboard/bank-accounts",
    component: _54811eca,
    name: "dashboard-bank-accounts___en"
  }, {
    path: "/en/dashboard/landlords",
    component: _492a69f4,
    name: "dashboard-landlords___en"
  }, {
    path: "/en/dashboard/notifications",
    component: _6b7286db,
    name: "dashboard-notifications___en"
  }, {
    path: "/en/dashboard/properties",
    component: _75509e60,
    name: "dashboard-properties___en"
  }, {
    path: "/en/dashboard/suppliers",
    component: _ddf13fcc,
    name: "dashboard-suppliers___en"
  }, {
    path: "/en/dashboard/tenants",
    component: _60a4f288,
    name: "dashboard-tenants___en"
  }, {
    path: "/en/dashboard/unpaid-costs",
    component: _05e9378a,
    name: "dashboard-unpaid-costs___en"
  }, {
    path: "/en/dashboard/unpaid-rent",
    component: _153a8f54,
    name: "dashboard-unpaid-rent___en"
  }, {
    path: "/en/error/404",
    component: _d16c3142,
    name: "error-404___en"
  }, {
    path: "/en/onboarding/confirm_invitation",
    component: _6e2b85cc,
    name: "onboarding-confirm_invitation___en"
  }, {
    path: "/en/onboarding/demo",
    component: _0d8a4ef6,
    name: "onboarding-demo___en"
  }, {
    path: "/en/settings/company-details",
    component: _0c2a9748,
    name: "settings-company-details___en"
  }, {
    path: "/en/swan/callback",
    component: _23a821b1,
    name: "swan-callback___en"
  }, {
    path: "/en/swan/consent-callback",
    component: _15e36c84,
    name: "swan-consent-callback___en"
  }, {
    path: "/viewer/CGU/cgu",
    component: _06cb73b8,
    name: "viewer-CGU-cgu___fr"
  }, {
    path: "/workflow/account-statement/create",
    component: _404fc4ba,
    name: "workflow-account-statement-create___fr"
  }, {
    path: "/workflow/landlord/create",
    component: _7ad49dfd,
    name: "workflow-landlord-create___fr"
  }, {
    path: "/workflow/property/create",
    component: _51e08068,
    name: "workflow-property-create___fr"
  }, {
    path: "/workflow/supplier/create",
    component: _a42bd7de,
    name: "workflow-supplier-create___fr"
  }, {
    path: "/workflow/tenant/create",
    component: _b5f4c61a,
    name: "workflow-tenant-create___fr"
  }, {
    path: "/en/dashboard/accounting/listing",
    component: _2f5e3fb2,
    name: "dashboard-accounting-listing___en",
    children: [{
      path: "accounting-accounts",
      component: _5bdf92b6,
      name: "dashboard-accounting-listing-accounting-accounts___en"
    }, {
      path: "accounting-entries",
      component: _324320f0,
      name: "dashboard-accounting-listing-accounting-entries___en"
    }, {
      path: "accounting-entry-sets",
      component: _414e669c,
      name: "dashboard-accounting-listing-accounting-entry-sets___en"
    }, {
      path: "accounting-journals",
      component: _1d63f828,
      name: "dashboard-accounting-listing-accounting-journals___en"
    }]
  }, {
    path: "/en/viewer/CGU/cgu",
    component: _06cb73b8,
    name: "viewer-CGU-cgu___en"
  }, {
    path: "/en/workflow/account-statement/create",
    component: _404fc4ba,
    name: "workflow-account-statement-create___en"
  }, {
    path: "/en/workflow/landlord/create",
    component: _7ad49dfd,
    name: "workflow-landlord-create___en"
  }, {
    path: "/en/workflow/property/create",
    component: _51e08068,
    name: "workflow-property-create___en"
  }, {
    path: "/en/workflow/supplier/create",
    component: _a42bd7de,
    name: "workflow-supplier-create___en"
  }, {
    path: "/en/workflow/tenant/create",
    component: _b5f4c61a,
    name: "workflow-tenant-create___en"
  }, {
    path: "/en/dashboard/accounting/accounting-entry-set/:accountingEntrySetId",
    component: _fdc817e2,
    name: "dashboard-accounting-accounting-entry-set-accountingEntrySetId___en"
  }, {
    path: "/dashboard/accounting/accounting-entry-set/:accountingEntrySetId",
    component: _fdc817e2,
    name: "dashboard-accounting-accounting-entry-set-accountingEntrySetId___fr"
  }, {
    path: "/en/dashboard/landlord/:landlordId",
    component: _4f10d7eb,
    name: "dashboard-landlord-landlordId___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId",
    component: _b7ed5e02,
    name: "dashboard-tenant-tenantId___en"
  }, {
    path: "/en/viewer/file/:fileId?",
    component: _74e38fce,
    name: "viewer-file-fileId___en"
  }, {
    path: "/en/viewer/inventory/:inventoryId?",
    component: _e29c2714,
    name: "viewer-inventory-inventoryId___en"
  }, {
    path: "/en/viewer/management-report/:managementReportId?",
    component: _3bcd4e65,
    name: "viewer-management-report-managementReportId___en"
  }, {
    path: "/en/viewer/mandate/:mandateId?",
    component: _3833d476,
    name: "viewer-mandate-mandateId___en"
  }, {
    path: "/en/viewer/payment/:paymentId?",
    component: _23c8e236,
    name: "viewer-payment-paymentId___en"
  }, {
    path: "/en/viewer/rent/:rentId?",
    component: _6954a608,
    name: "viewer-rent-rentId___en"
  }, {
    path: "/en/viewer/rental/:rentalId?",
    component: _08c8d2c4,
    name: "viewer-rental-rentalId___en"
  }, {
    path: "/en/dashboard/landlord/:landlordId?/documents",
    component: _fa12135e,
    name: "dashboard-landlord-landlordId-documents___en"
  }, {
    path: "/en/dashboard/landlord/:landlordId?/informations",
    component: _55796f3e,
    name: "dashboard-landlord-landlordId-informations___en"
  }, {
    path: "/en/dashboard/landlord/:landlordId?/management-reports",
    component: _c5076328,
    name: "dashboard-landlord-landlordId-management-reports___en"
  }, {
    path: "/en/dashboard/landlord/:landlordId?/properties",
    component: _a7e9fd2c,
    name: "dashboard-landlord-landlordId-properties___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId?/documents",
    component: _4624c565,
    name: "dashboard-tenant-tenantId-documents___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId?/informations",
    component: _f56350ac,
    name: "dashboard-tenant-tenantId-informations___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId?/payment-methods",
    component: _58440f50,
    name: "dashboard-tenant-tenantId-payment-methods___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId?/rentals",
    component: _13c18f88,
    name: "dashboard-tenant-tenantId-rentals___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId?/rents",
    component: _5dd73087,
    name: "dashboard-tenant-tenantId-rents___en"
  }, {
    path: "/en/workflow/property/:propertyId?/cost/create",
    component: _075c3622,
    name: "workflow-property-propertyId-cost-create___en"
  }, {
    path: "/en/workflow/property/:propertyId?/inventory/create",
    component: _41a39aca,
    name: "workflow-property-propertyId-inventory-create___en"
  }, {
    path: "/en/workflow/property/:propertyId?/mandate/create.old",
    component: _693326b0,
    name: "workflow-property-propertyId-mandate-create.old___en"
  }, {
    path: "/en/workflow/property/:propertyId?/mandate/form",
    component: _467cdff7,
    name: "workflow-property-propertyId-mandate-form___en"
  }, {
    path: "/en/workflow/property/:propertyId?/rent/create",
    component: _60032194,
    name: "workflow-property-propertyId-rent-create___en"
  }, {
    path: "/en/workflow/property/:propertyId?/rental/create",
    component: _29ac696b,
    name: "workflow-property-propertyId-rental-create___en"
  }, {
    path: "/en/workflow/property/:propertyId?/tenant-cost/create",
    component: _597b3932,
    name: "workflow-property-propertyId-tenant-cost-create___en"
  }, {
    path: "/en/viewer/property/:propertyId?/cost/:costId?",
    component: _24ae9478,
    name: "viewer-property-propertyId-cost-costId___en"
  }, {
    path: "/en/workflow/property/:propertyId?/rental/:rentalId?/replace-tenant",
    component: _3de56b74,
    name: "workflow-property-propertyId-rental-rentalId-replace-tenant___en"
  }, {
    path: "/dashboard/landlord/:landlordId",
    component: _4f10d7eb,
    name: "dashboard-landlord-landlordId___fr"
  }, {
    path: "/dashboard/tenant/:tenantId",
    component: _b7ed5e02,
    name: "dashboard-tenant-tenantId___fr"
  }, {
    path: "/en/landlord/:landlordId",
    component: _7b877d90,
    name: "landlord-landlordId___en"
  }, {
    path: "/en/property/:propertyId",
    component: _754ae53a,
    name: "property-propertyId___en"
  }, {
    path: "/en/supplier/:supplierId",
    component: _b5f30130,
    name: "supplier-supplierId___en"
  }, {
    path: "/en/tenant/:tenantId",
    component: _d1d2ddb8,
    name: "tenant-tenantId___en"
  }, {
    path: "/viewer/file/:fileId?",
    component: _74e38fce,
    name: "viewer-file-fileId___fr"
  }, {
    path: "/viewer/inventory/:inventoryId?",
    component: _e29c2714,
    name: "viewer-inventory-inventoryId___fr"
  }, {
    path: "/viewer/management-report/:managementReportId?",
    component: _3bcd4e65,
    name: "viewer-management-report-managementReportId___fr"
  }, {
    path: "/viewer/mandate/:mandateId?",
    component: _3833d476,
    name: "viewer-mandate-mandateId___fr"
  }, {
    path: "/viewer/payment/:paymentId?",
    component: _23c8e236,
    name: "viewer-payment-paymentId___fr"
  }, {
    path: "/viewer/rent/:rentId?",
    component: _6954a608,
    name: "viewer-rent-rentId___fr"
  }, {
    path: "/viewer/rental/:rentalId?",
    component: _08c8d2c4,
    name: "viewer-rental-rentalId___fr"
  }, {
    path: "/dashboard/landlord/:landlordId?/documents",
    component: _fa12135e,
    name: "dashboard-landlord-landlordId-documents___fr"
  }, {
    path: "/dashboard/landlord/:landlordId?/informations",
    component: _55796f3e,
    name: "dashboard-landlord-landlordId-informations___fr"
  }, {
    path: "/dashboard/landlord/:landlordId?/management-reports",
    component: _c5076328,
    name: "dashboard-landlord-landlordId-management-reports___fr"
  }, {
    path: "/dashboard/landlord/:landlordId?/properties",
    component: _a7e9fd2c,
    name: "dashboard-landlord-landlordId-properties___fr"
  }, {
    path: "/dashboard/tenant/:tenantId?/documents",
    component: _4624c565,
    name: "dashboard-tenant-tenantId-documents___fr"
  }, {
    path: "/dashboard/tenant/:tenantId?/informations",
    component: _f56350ac,
    name: "dashboard-tenant-tenantId-informations___fr"
  }, {
    path: "/dashboard/tenant/:tenantId?/payment-methods",
    component: _58440f50,
    name: "dashboard-tenant-tenantId-payment-methods___fr"
  }, {
    path: "/dashboard/tenant/:tenantId?/rentals",
    component: _13c18f88,
    name: "dashboard-tenant-tenantId-rentals___fr"
  }, {
    path: "/dashboard/tenant/:tenantId?/rents",
    component: _5dd73087,
    name: "dashboard-tenant-tenantId-rents___fr"
  }, {
    path: "/en/landlord/:landlordId?/documents",
    component: _baaf7d14,
    name: "landlord-landlordId-documents___en"
  }, {
    path: "/en/landlord/:landlordId?/informations",
    component: _6e5115f9,
    name: "landlord-landlordId-informations___en"
  }, {
    path: "/en/landlord/:landlordId?/management-reports",
    component: _67acbce7,
    name: "landlord-landlordId-management-reports___en"
  }, {
    path: "/en/landlord/:landlordId?/payouts",
    component: _7303a54b,
    name: "landlord-landlordId-payouts___en"
  }, {
    path: "/en/landlord/:landlordId?/update",
    component: _8c472f0a,
    name: "landlord-landlordId-update___en"
  }, {
    path: "/en/property/:propertyId?/contracts",
    component: _3aafe689,
    name: "property-propertyId-contracts___en"
  }, {
    path: "/en/property/:propertyId?/costs",
    component: _d33da6a4,
    name: "property-propertyId-costs___en"
  }, {
    path: "/en/property/:propertyId?/documents",
    component: _908877c0,
    name: "property-propertyId-documents___en"
  }, {
    path: "/en/property/:propertyId?/rents",
    component: _2f18c4c2,
    name: "property-propertyId-rents___en"
  }, {
    path: "/en/property/:propertyId?/update",
    component: _0ef413de,
    name: "property-propertyId-update___en"
  }, {
    path: "/en/supplier/:supplierId?/informations",
    component: _21c65dbe,
    name: "supplier-supplierId-informations___en"
  }, {
    path: "/en/supplier/:supplierId?/update",
    component: _7f84bcba,
    name: "supplier-supplierId-update___en"
  }, {
    path: "/en/tenant/:tenantId?/documents",
    component: _729b6b0a,
    name: "tenant-tenantId-documents___en"
  }, {
    path: "/en/tenant/:tenantId?/informations",
    component: _6f36d836,
    name: "tenant-tenantId-informations___en"
  }, {
    path: "/en/tenant/:tenantId?/payment-methods",
    component: _672de386,
    name: "tenant-tenantId-payment-methods___en"
  }, {
    path: "/en/tenant/:tenantId?/update",
    component: _102fc767,
    name: "tenant-tenantId-update___en"
  }, {
    path: "/workflow/property/:propertyId?/cost/create",
    component: _075c3622,
    name: "workflow-property-propertyId-cost-create___fr"
  }, {
    path: "/workflow/property/:propertyId?/inventory/create",
    component: _41a39aca,
    name: "workflow-property-propertyId-inventory-create___fr"
  }, {
    path: "/workflow/property/:propertyId?/mandate/create.old",
    component: _693326b0,
    name: "workflow-property-propertyId-mandate-create.old___fr"
  }, {
    path: "/workflow/property/:propertyId?/mandate/form",
    component: _467cdff7,
    name: "workflow-property-propertyId-mandate-form___fr"
  }, {
    path: "/workflow/property/:propertyId?/rent/create",
    component: _60032194,
    name: "workflow-property-propertyId-rent-create___fr"
  }, {
    path: "/workflow/property/:propertyId?/rental/create",
    component: _29ac696b,
    name: "workflow-property-propertyId-rental-create___fr"
  }, {
    path: "/workflow/property/:propertyId?/tenant-cost/create",
    component: _597b3932,
    name: "workflow-property-propertyId-tenant-cost-create___fr"
  }, {
    path: "/viewer/property/:propertyId?/cost/:costId?",
    component: _24ae9478,
    name: "viewer-property-propertyId-cost-costId___fr"
  }, {
    path: "/workflow/property/:propertyId?/rental/:rentalId?/replace-tenant",
    component: _3de56b74,
    name: "workflow-property-propertyId-rental-rentalId-replace-tenant___fr"
  }, {
    path: "/landlord/:landlordId",
    component: _7b877d90,
    name: "landlord-landlordId___fr"
  }, {
    path: "/property/:propertyId",
    component: _754ae53a,
    name: "property-propertyId___fr"
  }, {
    path: "/supplier/:supplierId",
    component: _b5f30130,
    name: "supplier-supplierId___fr"
  }, {
    path: "/tenant/:tenantId",
    component: _d1d2ddb8,
    name: "tenant-tenantId___fr"
  }, {
    path: "/landlord/:landlordId?/documents",
    component: _baaf7d14,
    name: "landlord-landlordId-documents___fr"
  }, {
    path: "/landlord/:landlordId?/informations",
    component: _6e5115f9,
    name: "landlord-landlordId-informations___fr"
  }, {
    path: "/landlord/:landlordId?/management-reports",
    component: _67acbce7,
    name: "landlord-landlordId-management-reports___fr"
  }, {
    path: "/landlord/:landlordId?/payouts",
    component: _7303a54b,
    name: "landlord-landlordId-payouts___fr"
  }, {
    path: "/landlord/:landlordId?/update",
    component: _8c472f0a,
    name: "landlord-landlordId-update___fr"
  }, {
    path: "/property/:propertyId?/contracts",
    component: _3aafe689,
    name: "property-propertyId-contracts___fr"
  }, {
    path: "/property/:propertyId?/costs",
    component: _d33da6a4,
    name: "property-propertyId-costs___fr"
  }, {
    path: "/property/:propertyId?/documents",
    component: _908877c0,
    name: "property-propertyId-documents___fr"
  }, {
    path: "/property/:propertyId?/rents",
    component: _2f18c4c2,
    name: "property-propertyId-rents___fr"
  }, {
    path: "/property/:propertyId?/update",
    component: _0ef413de,
    name: "property-propertyId-update___fr"
  }, {
    path: "/supplier/:supplierId?/informations",
    component: _21c65dbe,
    name: "supplier-supplierId-informations___fr"
  }, {
    path: "/supplier/:supplierId?/update",
    component: _7f84bcba,
    name: "supplier-supplierId-update___fr"
  }, {
    path: "/tenant/:tenantId?/documents",
    component: _729b6b0a,
    name: "tenant-tenantId-documents___fr"
  }, {
    path: "/tenant/:tenantId?/informations",
    component: _6f36d836,
    name: "tenant-tenantId-informations___fr"
  }, {
    path: "/tenant/:tenantId?/payment-methods",
    component: _672de386,
    name: "tenant-tenantId-payment-methods___fr"
  }, {
    path: "/tenant/:tenantId?/update",
    component: _102fc767,
    name: "tenant-tenantId-update___fr"
  }, {
    path: "/",
    component: _ff45c400,
    name: "index___fr"
  }],

  parseQuery: function(queryString) {
            return require('qs').parse(queryString)
        },
  stringifyQuery: function(object) {
            var queryString = require('qs').stringify(object)
            return queryString ? '?' + queryString : ''
        },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
