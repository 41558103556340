<template>

    <div class="paymentInfo">

        <form-group class="readonly-input" :label="label" :name="name" :missing="missing">

            <div class="content-text">

                <div v-if="!hideSensitivePaymentInformations || isVisible && hideSensitivePaymentInformations" :class="['text', name, { 'active': value, 'highlight': highlight, 'secondary-color': secondaryColor, }]" :style="inputStyle">{{ value ? value : this.default }}</div>
                
                <div v-else-if="!isVisible && hideSensitivePaymentInformations">{{ '•••• ' + value.slice(-4) }}</div>
                
                <div class="view-container" @click="showSensitiveInformations"><svg-icon class="view icon img" :iconColor="isVisible ? '#8F94A0' : '#BED000'" :viewbox="'0 0 16 13'" v-if="hideSensitivePaymentInformations"><view-icon /></svg-icon></div>

            </div>
            
        </form-group>

        <div class="clipboard" @click="copy(value)" >

            <svg-icon width="24" height="24" viewBox="0 0 24 24" fill="none" ><clipboard-icon/></svg-icon>

        </div>

    </div>

</template>

<script>
    import FormGroup from '~/components/inputs/form-group'
    import SvgIcon from '~/components/svg-icon'
    import VerifiedIcon from '~/components/icons/status-icons/verified-icon'
    import ViewIcon from '~/components/icons/button-icons/view-icon'
    import ClipboardIcon from '~/components/icons/input-icons/clipboard-icon.vue'

    export default {
        components: {
            FormGroup,
            SvgIcon,
            VerifiedIcon,
            ViewIcon,
            ClipboardIcon
        },

        data() {
            return {
                isVisible: false
            }
        },

        props: {
            default:{
                type: String,
                default(){
                    return this.$t('unknown')
                }
            },
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            paymentInfo:{
                type: Boolean,
                required: false
            },
            value: {
                required: true
            },
            verified: {
                type: Boolean,
                required: false
            },
            missing: {
                type: Boolean,
                default: false
            },
            highlight: {
                type: Boolean,
                default: false
            },
            inputStyle: {
                type: Object,
                required: false
            },
            secondaryColor: {
                type: Boolean,
                required: false
            },
            hideSensitivePaymentInformations: {
                type: Boolean,
                required: false
            }
        },

        methods: {
            showSensitiveInformations() {
                this.isVisible = !this.isVisible
            },

            copy(text) {
    
                navigator.clipboard.writeText(text)
    
                this.$toast.success(this.$t('copied'), { icon: 'check' })
            },
        },

    }
</script>

<i18n>
    {
        "fr": {
            "unknown": "Non renseigné",
            "add": "Nouveau",
            "copied": "Copié !"
        }
    }
</i18n>

<style lang="scss" scoped>
    $line-unknown-color: #8F94A0;
    $height: 40px;
    $black: #0E0A07;

    .paymentInfo{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px;
        border: 1px solid #DEE3EF;
        border-radius: 4px;

        .clipboard {
            cursor: pointer;
        }

        .readonly-input {  

            .button {
                display: flex;
                align-items: flex-start;
                min-height: $height;
                box-sizing: border-box;
                padding: 0;
                flex-direction: column;
            }

            .content-text {
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-sizing: border-box;

                .icon {
                    margin-right: 12px;
                }

                .view-container {
                    display: flex;
                    align-items: center;
                    transition: 0.3s ease-in-out;

                    .view {
                        cursor: pointer; 
                        margin: 0;
                        width: 18px;
                        height: 18px;
                        transition: 0.3s ease-in-out;
                    }
                }

                .text {
                    text-decoration: line-through;
                    color: $line-unknown-color;
                    opacity: .5;
                    font-size: 12pt;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    &.active {
                        text-decoration: initial;
                        color: $black;
                        opacity: 1;
                    }

                    &.secondary-color {
                        color: #BED000;
                        opacity: 1;
                        text-transform: uppercase;
                        font-weight: 400;
                    }
                }
            }

            &.align-right {
                .content-text {
                    align-items: flex-end;
                }
            }
        }
    }
</style>
