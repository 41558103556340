<template>

    <div class="select-property-management-company-bank-account">

        <h3 class="group-title">{{$t('select_payment_account')}}</h3>

        <div class="bank-accounts">

            <bank-account-card class="bank-account" :border="true" :account="account" v-for="(account, key) in bankAccounts" :key="key" @click.native="handleSelectedAccount(account)" :active="account.iban === selectedBankAccount.iban"/>

        </div>

        <form-info class="forminfo" :text="$t('swan_has_fund_insufficient')" type="error" v-if="selectedBankAccount.bank_name === 'Swan' && !isBankAccountHasSufficientFunds" />

        <form-info class="forminfo" :text="$t('swan_bank_wire_info')" v-else-if="selectedBankAccount.bank_name === 'Swan'" />

        <form-info class="forminfo" :text="$t('manual_bank_wire_info')" v-else-if="Object.keys(selectedBankAccount).length > 0" />

    </div>

</template>

<script>
    import BankAccountCard from '~/components/bank-account-card'
    import PaymentInfoReadonlyInfo from '~/components/inputs/payment-info-readonly-info.vue'
    import FormInfo from '~/components/form-info'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            PaymentInfoReadonlyInfo,
            BankAccountCard,
            FormInfo
        },

        async fetch() {

            const { store } = this.$nuxt.context

            await store.dispatch('property-manager/fetchBankAccounts', { params: { propertyManagerId: this.propertyManagerId }})
        },

        methods: {
            handleSelectedAccount(bankAccount) {
                this.selectedBankAccount = bankAccount
            }
        },

        computed: {
            ...mapGetters({
                amount: 'payout/getAmount',
                bankAccounts: 'property-manager/getRelatedBankAccounts',
                payoutRelatedBankAccount: 'payout/getRelatedBankAccount'
            }),

            isBankAccountHasSufficientFunds() {
                if (!Object.keys(this.payoutRelatedBankAccount).length) return true

                return this.payoutRelatedBankAccount.balance >= this.amount
            },

            selectedBankAccount: {
                get() {
                    return this.$store.getters['payout/getRelatedBankAccount']
                },
                set(newVal) {
                    this.$store.commit('payout/setRelatedBankAccount', newVal)
                }
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "select_payment_account": "Sélectionnez le compte à débiter",
            "manual_bank_wire_info": "Réalisez manuellement le virement via l’interface de votre banque.",
            "swan_bank_wire_info": "Vous allez être redirigé vers l’interface sécurisée de Swan pour vous authentifier.",
            "swan_has_fund_insufficient": "Votre compte bancaire n'a pas assez de provision pour régler vos fournisseurs."
        }
    }
</i18n>

<style lang="scss" scoped>
    .bank-accounts {
        display: flex;
        flex-direction: row;
        margin-bottom: 24px;

        .bank-account {
            cursor: pointer;

            & ~ .bank-account {
                margin-left: 32px;
            }
        }
    }

    .forminfo {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .group-title{
        font-size: 24px;
        color: #333333;
        margin-bottom: 24px;
    }
</style>
