<template>
    
    <div class="resource-minified">

        <div class="background-icon">

            <svg-icon class="icon" width="70" height="70" viewbox="0 0 69 68" iconColor="#525254" v-if="icon === 'property'"><resource-minified-property-icon /></svg-icon>

            <svg-icon class="icon" width="34" height="34" viewBox="0 0 24 24" v-else-if="icon === 'profile'"><resource-minified-profile-icon /></svg-icon>

            <svg-icon class="icon" width="34" height="34" viewBox="0 0 14 16" v-else-if="icon === 'key'"><resource-minified-key-icon /></svg-icon>

            <svg-icon class="icon" width="34" height="34" viewBox="0 0 13 16" v-else-if="icon === 'meter'"><resource-minified-meter-icon /></svg-icon>

            <svg-icon class="icon" :iconColor="'#C2CDEE'" width="34" height="34" viewBox="0 0 16 17" v-else-if="icon === 'room'"><resource-minified-room-icon /></svg-icon>

            <svg-icon class="icon" width="34" height="34" viewBox="0 0 16 16" v-else-if="icon === 'utility'"><resource-minified-utility-icon /></svg-icon>

            <svg-icon class="icon" width="34" height="34" viewBox="0 0 15 16" v-else-if="icon === 'accounting-account'"><resource-minified-accounting-account-icon /></svg-icon>
        
        </div>

        <div class="group">

            <div class="title">
                
                <h3>{{ title }}</h3>

                <status-tag class="tag" type="good" v-if="tag">{{ tag }}</status-tag>
                
            </div>

            <p class="details">{{ details }}</p>

        </div>

        <button-input class="background-transparent green-text-color" :text="action.title" @click="action.function" v-if="action" :disabled="disabled"/>
        
    </div>

</template>

<script>
    import SvgIcon from '~/components/svg-icon'
    import { ResourceMinifiedPropertyIcon, ResourceMinifiedProfileIcon, ResourceMinifiedKeyIcon, ResourceMinifiedMeterIcon, ResourceMinifiedRoomIcon, ResourceMinifiedUtilityIcon, ResourceMinifiedAccountingAccountIcon } from '~/components/icons/resource-minified'
    import StatusTag from '~/components/status-tag'
    
    export default {
        components: {
            SvgIcon,
            ResourceMinifiedPropertyIcon,
            ResourceMinifiedProfileIcon,
            ResourceMinifiedKeyIcon,
            ResourceMinifiedMeterIcon,
            ResourceMinifiedRoomIcon,
            ResourceMinifiedUtilityIcon,
            ResourceMinifiedAccountingAccountIcon,
            StatusTag
        },

        props: {
            title: {
                type: String,
                required: true
            },
            details: {
                type: String,
                required: true
            },
            tag: {
                type: String,
                required: false
            },
            action: {
                type: Object,
                required: false
            },
            icon: {
                type: String,
                default: 'property'
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "delete": "Supprimer"
        }
    }
</i18n>

<style lang="scss">
    $border-color: #DEE3EF;

    .resource-minified {
        display: flex;
        border: 1px solid $border-color;
        border-radius: 10px;
        margin-bottom: 10px;
        padding: 30px;

        .background-icon {
            width: 68px;
            height: 68px;
            border-radius: 100%;
            background: #EFEFEF;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .group {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 24px;
            flex: 1;

            .title {
                display: flex;
                flex-direction: row;
                align-items: center;
                
                & > h3 {
                    font-size: 20px;
                }

                .tag {
                    margin-left: 6px;
                }
            }

            .details {
                font-size: 11pt;
                color: #8F94A0;
            }
        }
    }
</style>
