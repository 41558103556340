export default async function ({store, redirect}) {

    const tenants = await store.dispatch('profile/fetchTenants')

    if (tenants.response.length) {
        const tenant = tenants.response[0];
        if (tenant.length === 0) {
            return redirect('/');
        }
        await store.dispatch('profile/fillTenant', tenant);
        store.commit('setAuthProfile', tenant);
    }
}
