export default {
    getLeaseAgreementFieldsOtherSpecificClauses(state) {
        return state.lease_agreement_fields.other_specific_clauses
    },

    getLeaseAgreementFieldsRoomatesCommonAreasDescription(state) {
        return state.lease_agreement_fields.roomates_common_areas_description
    },

    getLeaseAgreementFieldsIsFurnished(state) {
        return state.lease_agreement_fields.is_furnished
    },

    getLeaseAgreementFieldsTenantPrivateAreasDescription(state) {
        return state.lease_agreement_fields.tenant_private_areas_description
    },

    getLeaseAgreementFieldsPremisesUse(state) {
        return state.lease_agreement_fields.premises_use
    },

    getLeaseAgreementFieldsShorterTermReason(state) {
        return state.lease_agreement_fields.shorter_term_reason
    },

    getLeaseAgreementFieldsRentSupplementReason(state) {
        return state.lease_agreement_fields.rent_supplement_reason
    },

    getLeaseAgreementFieldsLastRentAdjustmentDate(state) {
        return state.lease_agreement_fields.last_rent.adjustment_date
    },

    getLeaseAgreementFieldsLastRentAmount(state) {
        return state.lease_agreement_fields.last_rent.amount
    },

    getLeaseAgreementFieldsLastRentPaymentDate(state) {
        return state.lease_agreement_fields.last_rent.payment_date
    },

    getRelatedFeesHomeInsurance(state) {
        const element = state.relationships.fees.find(item => item.type === 'home_insurance')

        return typeof element !== 'undefined' ? element.amount_in_currency : 0
    },

    getLeaseAgreementFieldsReferenceRentAmount(state) {
        return state.lease_agreement_fields.reference_rent_amount
    },

    getLeaseAgreementFieldsIncreasedReferenceRentAmount(state) {
        return state.lease_agreement_fields.increased_reference_rent_amount
    },

    getLeaseAgreementFieldsMobilityLeaseJustification(state) {
        return state.lease_agreement_fields.mobility_lease_justification
    },

    getLeaseAgreementFieldsImprovementOrComplianceWorkAmount(state) {
        return state.lease_agreement_fields.improvement_or_compliance_work.amount
    },

    getLeaseAgreementFieldsImprovementOrComplianceWorkDescription(state) {
        return state.lease_agreement_fields.improvement_or_compliance_work.description
    },

    getLeaseAgreementFieldsSharingType(state) {
        return state.lease_agreement_fields.sharing_type
    },

    getLeaseAgreementFieldsType(state) {
        return state.lease_agreement_fields.type
    },

    getId(state) {
        return state.id
    },

    getItems(state) {
        return state.items
    },

    getFixedServiceCharges(state) {
        const element = state.items.find(item => item.type === 'fixed_service_charges')

        return typeof element !== 'undefined' ? element.amount : 0
    },

    getPastRents(state) {
        return state.past_rents
    },

    getRentAdjustmentDate(state) {
        return state.rent_adjustment_date
    },

    getRentAmount(state) {
        const element = state.items.find(item => item.type === 'rent')

        return typeof element !== 'undefined' ? element.amount : 0
    },

    getRentAmountIncludingServiceCharges(state, getters) {
        return getters['getRentAmount']
            + getters['getFixedServiceCharges']
            + getters['getVariableServiceCharges']
    },

    getRentReferenceIndexReferenceQuarter(state) {
        return state.rent_reference_index_reference_quarter
    },

    getRentPaymentDueDate(state) {
        return state.rent_payment_due_date
    },

    getRentSupplementAmount(state) {
        const element = state.items.find(item => item.type === 'rent_supplement')

        return typeof element !== 'undefined' ? element.amount : 0
    },

    getStartDate(state) {
        return state.start_date
    },

    getCreateFeesLandlordsShare(state) {
        return state.create_fees_landlord_share
    },

    getCreateFeesTenantsShare(state) {
        return state.create_fees_tenant_share
    },

    getEndDate(state) {
        return state.end_date
    },

    getStatus(state) {
        return state.status
    },

    getSecurityDepositCollectionDate(state) {
        return state.security_deposit.collection_date
    },

    getSecurityDepositIsHold(state) {
        return state.security_deposit.is_hold
    },

    getSecurityDepositAmount(state) {
        return state.security_deposit.amount
    },

    getSecurityDepositIsAlreadyPaid(state) {
        return state.security_deposit.is_already_paid
    },

    getTerm(state) {
        return state.term
    },

    getRelatedLeaseAgreementAnnexes(state) {
        return state.relationships.lease_agreement_annexes
    },

    getRelatedAppliedFees(state) {
        return state.relationships.applied_fees
    },

    getRelatedLeaseAgreement(state) {
        return state.relationships.lease_agreement
    },

    getRelatedFees(state) {
        return state.relationships.fees
    },

    getRelatedProperty(state) {
        return state.relationships.property
    },

    getRelatedTenants(state) {
        return state.relationships.tenants
    },

    getRelatedPaymentMethod(state) {
        return state.relationships.payment_method
    },

    getRelatedUpcomingRents(state) {
        return state.relationships.upcoming_rents
    },

    getRelatedInventories(state) {
        return state.relationships.inventories
    },

    getRelatedSecurityDeposit(state) {
        return state.relationships.security_deposit
    },

    getVariableServiceCharges(state) {
        const element = state.items.find(item => item.type === 'variable_service_charges')

        return typeof element !== 'undefined' ? element.amount : 0
    },

    getHasVatRate(state) {
        return state.hasVatRate;
    },

    getLeaseType(state) {
        return state.lease_type;
    },

    getIsFurnished(state) {
        return state.is_furnished;
    },

    getIsShared(state) {
        return state.is_shared;
    },
}
