<template>
    <div class="workflow-layout">

        <workflow-navigation-bar
            :workflow="workflow"
            @exit="exit"
        />
        <error-banner
            :errors="errors"
            v-if="errors.length>0"
        />

        <section class="layout-row content">

            <workflow-sidebar
                :workflow="workflow"
            />

            <div class="layout-col col-10 steps">
                <nuxt-child
                    :workflow="workflow"
                    ref="child-workflow"
                />
            </div>

            <div class="layout-col col-3 preview-pdf-container">

                <template
                    v-if="workflow.hasPreview &&
                        workflow.model.document !== null &&
                        workflow.model.document.id !== null
                        "
                >
                    <div
                        class="pdf-miniature"
                        v-show="!IsPreviewDocumentActive"
                    >

                        <div class="container">
                            <button-input
                                class="preview-button background-phantom green-text-color"
                                :text="$t('open')"
                                @click="IsPreviewDocumentActive = true"
                            />
                            <img
                                class="preview-img"
                                :src="workflow.model.document.url"
                                v-if="workflow.model.document.mime_type === 'image/png' || workflow.model.document.mime_type === 'image/jpeg'"
                            />

                            <div
                                class="preview"
                                v-else-if="workflow.model.document.mime_type === 'application/pdf'"
                            >
                                <vue-pdf-embed
                                    class="pdf"
                                    :source="workflow.model.document.url"
                                    :page="1"
                                    ref="pdf"
                                />

                            </div>
                        </div>
                    </div>

                    <preview-pdf
                        :file="workflow.model.document"
                        v-if="IsPreviewDocumentActive"
                        @close="IsPreviewDocumentActive = false"
                    />
                </template>

            </div>
        </section>

        <workflow-button-group
            class="workflow-button-group"
            v-if="workflow.model"
            :workflow="workflow"
            :canUploadExternalFile="true"
            :loadingSaving="loadingSaving"

            @save="save"
            @saveWorkflow="saveWorkflow"
        />

    </div>
</template>

<script>

import WorkflowNavigationBar from "@/components/v2/navigation/header/workflow-navigation-bar-header.vue";
import WorkflowSidebar from "@/components/v2/navigation/sidebar/workflow-sidebar.vue";
import WorkflowButtonGroup from "@/components/v2/navigation/footer/workflow-button-group-footer.vue";

import apiDump from "@/api/Dump";
import Workflow from "@/shared/Workflow";
import PreviewPdf from "@/components/preview-pdf.vue";
import ButtonInput from "@/components/inputs/button-input.vue";
import ErrorBanner from "@/components/v2/error-banner.vue";

export default {
    name      : 'workflow',
    components: {
        ErrorBanner,
        ButtonInput,
        PreviewPdf,
        WorkflowSidebar,
        WorkflowNavigationBar,
        WorkflowButtonGroup
    },

    middleware: ['fetch-user'],

    data() {
        return {
            workflow               : new Workflow(),
            loadingSaving          : false,
            IsPreviewDocumentActive: false,
            errors                 : [],
            optionsScrollTo : {
                lazy : false
            }
        };
    },

    methods: {

        async save() {
            this.errors = [];
            this.loadingSaving = true;
            try {
                await this.$refs["child-workflow"].save();
            } catch (error) {
                const errorCode = error.response.status,
                      errors    = [];

                if (errorCode === 422) {
                    error.response.data.errors.forEach((error) => {
                        errors.push({
                                        field  : error.source.pointer,
                                        message: error.detail
                                    });
                    });

                    this.errors = errors;
                    return;
                }

                this.errors.push({
                                     message: this.$t('contact_support'),
                                 })

            } finally {
                this.loadingSaving = false;
                this.$refs["child-workflow"].setErrors(this.errors);
            }

        },

        async saveWorkflow() {
            await apiDump.postDump(this.workflow.userId, this.workflow.dumpName, this.workflow.model.getData());
            this.$toast.success(this.$t('state_saved', {icon: 'check'}));

            this.$router.push(this.workflow.urlBackDump);
        },

        exit() {
            this.$router.back();
        }
    },

    computed: {
        indexOpened() {
            return this.workflow.indexOpened;
        },
    },

    watch: {
        indexOpened: {
            immediate: false,
            handler(value) {
                const className = `.step-${value}`;
                this.$scrollTo(className, this.optionsScrollTo);
            }
        },
    }

}
</script>

<i18n>
{
    "fr": {
        "open": "Voir",
        "state_saved": "Le formulaire a été sauvegardé.",
        "contact_support": "Une erreur est survenue pendant la sauvegarde. Veuillez contacter le support Oskar."
    }
}
</i18n>

<style lang="scss" scoped>
$background: #EFEFEF;
$button-group-height: 68px;
$button-group-margin: 60px;
$color-black: #0E0A07;

.workflow-layout {
    background: $background;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - #{$button-group-height});
    margin-bottom: $button-group-margin;
}

.content {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-top: 40px;
    padding-left: 30px;
    padding-right: 30px;


    .preview-pdf-container {
        z-index: 20;
        position: sticky;
        align-self: flex-start;
        top: calc(100vh - 300px);

        &.fullscreen {
            position: initial;
        }

        .pdf-miniature {
            width: 160px;
            border: 3px solid #BED000;
            border-radius: 8px;
            box-shadow: 0 3px 0 0 rgba(218, 220, 240, 1);
            overflow: hidden;
            height: 204px;
            background: rgba(0, 0, 0, 0.08);
            display: flex;
            align-items: center;
            justify-content: center;

            .container {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                height: 100%;

                .preview-button {
                    position: absolute;
                    height: 28px;
                    right: 5px;
                    top: 5px;
                    z-index: 10;
                    padding: 0px 10px;
                    border-radius: 5px;
                    opacity: 0;
                    transition: ease-in-out 0.2s;
                    min-height: inherit;
                }

                .preview,
                .preview-img {
                    max-width: 160px;
                    cursor: pointer;
                    transition: ease-in-out 0.2s;

                    .pdf::v-deep canvas {
                        width: 100% !important;
                        height: auto !important;
                    }
                }
            }
        }

        .pdf-miniature:hover {
            .preview-button {
                opacity: 100;
                transition: ease-in-out 0.2s;
            }

            .preview,
            .preview-img {
                filter: brightness(70%);
                transition: ease-in-out 0.2s;
            }
        }
    }

    .sidebar {
        flex-shrink: 1;
    }

    .steps {
        align-items: center;

        .step-form {
            width: 100%;
            display: block;
            box-shadow: 0px 7px 9px rgba(141, 141, 141, 0.1), 0px 4px 13px rgba(141, 141, 141, 0.08);
            border-radius: 10px;
            background: white;
            color: $color-black;
            margin-bottom: 10px;

            .step-content {
                padding: 80px;
                padding-top: 56px;

                .form-active {
                    transform-origin: top;
                    display: flex;
                    flex-direction: column;
                }

                .header {
                    margin-bottom: 34px;

                    .title-group {
                        display: flex;
                        align-items: center;
                        flex: 1;

                        .title {
                            font-size: 24px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

    }

}
</style>
