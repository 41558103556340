export const strict = true

export const defaultState = () => {
    return {
        amount: '',
        balance: '',
        currency_code: '',
        description: '',
        id: '',
        reference: '',
        trade_date: '',
        value_date: '',
        origin: '',
        relationships: {
            bank_account: {}
        }
    }
}

export const state = defaultState

export const mutations = {
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },

    setAmount(state, value) {
        state.amount = value
    },

    setBalance(state, value) {
        state.balance = value
    },

    setCurrencyCode(state, value) {
        state.currency_code = value
    },

    setDescription(state, value) {
        state.description = value
    },

    setId(state, value) {
        state.id = value
    },

    setReference(state, value) {
        state.reference = value
    },

    setRelatedBankAccount(state, value) {
        state.relationships.bank_account = value
    },

    setTradeDate(state, value) {
        state.trade_date = value
    },

    setValueDate(state, value) {
        state.value_date = value
    },

    setOrigin(state, value) {
        state.origin = value
    }
}

export const getters = {
    getAmount(state) {
        return state.amount
    },

    getBalance(state) {
        return state.balance
    },

    getCurrencyCode(state) {
        return state.currency_code
    },

    getDescription(state) {
        return state.description
    },

    getId(state) {
        return state.id
    },

    getReference(state) {
        return state.reference
    },

    getRelatedBankAccount(state) {
        return state.relationships.bank_account
    },

    getTradeDate(state) {
        return state.trade_date
    },

    getValueDate(state) {
        return state.value_date
    },

    getOrigin(state) {
        return state.origin
    }
}

export const actions = {
    async updateBankReconciliationRelationship({ commit, getters }, accountingEntries) {
        try {

            let request = {
                data: accountingEntries.map(accountingEntry => {
                    return {
                        type: 'accounting-entries',
                        id: accountingEntry['_jv'].id
                    }
                })
            }

            const response = await this.$axios({ method: 'post', url: 'bank-account-transactions/' + getters['getId'] + '/relationships/bank_reconciliation', data: request })

            // commit('tyltoApi/deleteRecord', { _jv: { type: 'bank-account-transactions', id: getters['getId'] } }, { root: true })

            return response

        } catch(error) {

            throw error
        }
    },

    async createBankAccountTransaction({ dispatch }, bankAccountId) {
        try {

            let request = await dispatch('formatBankAccountTransactionRequest')

            const bankAccountTransaction = await dispatch('tyltoApi/post', [request, { url: 'bank-accounts/' + bankAccountId + '/bank-account-transactions' }], { root: true })

            await dispatch('fillBankAccountTransaction', bankAccountTransaction)

            return bankAccountTransaction

        } catch(error) {

            throw error
        }
    },

    async createRentBankAccountTransaction({ dispatch }, rentId) {
        try {

            let request = await dispatch('formatBankAccountTransactionRequest')

            const bankAccountTransaction = await dispatch('tyltoApi/post', [request, { url: 'rents/' + rentId + '/bank-account-transactions' }], { root: true })

            await dispatch('fillBankAccountTransaction', bankAccountTransaction)

            return bankAccountTransaction

        } catch(error) {

            throw error
        }
    },

    async fetchBankAccountRelationship({ getters, commit, dispatch }, bankAccountTransactionId) {
        try {

            let bankAccount = {}

            if (Object.keys(getters['getRelatedBankAccount']).length) bankAccount = getters['getRelatedBankAccount']

            else {

                const id = getters['getId'] !== '' ? getters['getId'] : bankAccountTransactionId

                const { data } = await this.$axios({ method: 'get', url: `bank-account-transactions/${id}/relationships/bank_account` })

                if (data['data'] !== null) bankAccount = await dispatch('bank-account-transaction/fetchBankAccount', data['data']['id'], { root: true })
            }

            commit('setRelatedBankAccount', bankAccount)

            return bankAccount

        } catch(error) {

            throw error
        }
    },

    async fetchBankAccountTransaction({ dispatch, rootGetters }, bankAccountTransactionId) {
        try {

            let bankAccountTransaction = rootGetters['tyltoApi/get'](`bank-account-transactions/${bankAccountTransactionId}`)

            if (typeof bankAccountTransaction === 'undefined' || !Object.keys(bankAccountTransaction).length) {

                bankAccountTransaction = await dispatch('tyltoApi/get', `bank-account-transactions/${bankAccountTransactionId}`, { root: true })
            }
            return bankAccountTransaction

        } catch(error) {

            throw error
        }
    },

    async fetchAndFillBankAccountTransaction({ dispatch }, bankAccountTransactionId) {
        try {

            let bankAccountTransaction = await dispatch('fetchBankAccountTransaction', bankAccountTransactionId)

            await dispatch('fillBankAccountTransaction', bankAccountTransaction)

            return bankAccountTransaction

        } catch(error) {

            throw error
        }
    },

    async fillBankAccountTransaction({ commit }, bankAccountTransaction) {

        commit('resetDefaultState')

        if (typeof bankAccountTransaction['amount'] !== 'undefined')
            commit('setAmount', bankAccountTransaction['amount'])

        if (typeof bankAccountTransaction['balance'] !== 'undefined')
            commit('setBalance', bankAccountTransaction['balance'])

        if (typeof bankAccountTransaction['currency_code'] !== 'undefined')
            commit('setCurrencyCode', bankAccountTransaction['currency_code'])

        if (typeof bankAccountTransaction['description'] !== 'undefined')
            commit('setDescription', bankAccountTransaction['description'])

        if (typeof bankAccountTransaction['reference'] !== 'undefined')
            commit('setReference', bankAccountTransaction['reference'])

        if (typeof bankAccountTransaction['trade_date'] !== 'undefined')
            commit('setTradeDate', bankAccountTransaction['trade_date'])

        if (typeof bankAccountTransaction['value_date'] !== 'undefined')
            commit('setValueDate', bankAccountTransaction['value_date'])

        if (typeof bankAccountTransaction['origin'] !== 'undefined')
            commit('setOrigin', bankAccountTransaction['origin'])

        if (typeof bankAccountTransaction['_jv'] !== 'undefined')
            commit('setId', bankAccountTransaction['_jv'].id)
    },

    async formatBankAccountTransactionRequest({ getters }) {
        let bankAccountTransactionRequest = {
            amount: getters['getAmount'],
            currency_code: getters['getCurrencyCode'],
            description: getters['getDescription'],
            trade_date: getters['getTradeDate'],
            _jv: {
                type: 'bank-account-transactions',
                relationships: {
                    bank_account: {
                        data: {
                            type: 'bank-accounts',
                            id: getters['getRelatedBankAccount']['_jv'].id
                        }
                    }
                }
            }
        }

        if (getters['getReference'] !== '')
            bankAccountTransactionRequest['reference'] = getters['getReference']

        if (getters['getValueDate'] !== '')
            bankAccountTransactionRequest['value_date'] = getters['getValueDate']

        if (getters['getOrigin'] !== '')
            bankAccountTransactionRequest['origin'] = getters['getOrigin']

        if (getters['getId'] !== '')
            bankAccountTransactionRequest['_jv'].id = getters['getId']

        return bankAccountTransactionRequest
    }
}
