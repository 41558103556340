import fullNameMixin from '~/components/mixins/full-name'
import { queryResponse, queryBuilder } from '~/utils/query'

function recursiveDeleteEmptyEntries(obj) {
    if (Array.isArray(obj)) {
        obj.forEach((item, index) => {
            if (typeof item === 'object') {
                recursiveDeleteEmptyEntries(item);
            }
            if (item === undefined || item === '' || (Array.isArray(item) && item.length === 0) || (typeof item === 'object' && Object.keys(item).length === 0)) {
                obj.splice(index, 1);
            }
        });
    } else {
        for (let k in obj) {
            if (obj[k] && typeof obj[k] === 'object') {
                recursiveDeleteEmptyEntries(obj[k]);
            }
            if (obj[k] === undefined || obj[k] === '' || (Array.isArray(obj[k]) && obj[k].length === 0) || (typeof obj[k] === 'object' && Object.keys(obj[k]).length === 0)) {
                delete obj[k];
            }
        }
    }
    return obj;
}

export default {
    async createLandlord({ dispatch }, propertyManagementCompanyId) {
        try {

            const requestData = await dispatch('getLandlordRequestData')

            let landlord = await dispatch('tyltoApi/post', [requestData, { url: `property-management-companies/${propertyManagementCompanyId}/landlords` }], { root: true })

            await dispatch('fillLandlord', landlord)

            return landlord

        } catch (error) {

            throw error
        }
    },

    async createSupplier({ dispatch, getters }, propertyManagementCompanyId) {
        try {

            const requestData = await dispatch('getSupplierRequestData')

            const supplier = await dispatch('tyltoApi/post', [requestData, { url: `property-management-companies/${propertyManagementCompanyId}/suppliers` }], { root: true })

            await dispatch('fillSupplier', supplier)

            return supplier

        } catch (error) {

            throw error
        }
    },

    async createTenant({ dispatch }, propertyManagementCompanyId) {
        try {

            const requestData = await dispatch('getTenantRequestData')

            const tenant = await dispatch('tyltoApi/post', [requestData, { url: `property-management-companies/${propertyManagementCompanyId}/tenants` }], { root: true })

            await dispatch('fillTenant', tenant)

            return tenant

        } catch (error) {

            throw error
        }
    },

    async createTenantGuarantor({ dispatch, getters }, guarantor) {
        try {

            let requestData = {
                ...recursiveDeleteEmptyEntries(guarantor),
                '_jv': {
                    type: 'guarantors'
                }
            }

            const newGuarantor = await dispatch('tyltoApi/post', [requestData, { url: `tenants/${getters['getId']}/guarantors` }], { root: true })

            return newGuarantor

        } catch (error) {

            throw error
        }
    },

    async deleteLandlord({ dispatch, getters, commit }) {
        try {

            await dispatch('tyltoApi/delete', '/landlords/' + getters['getId'], { root: true })

            commit('resetDefaultState')

        } catch (error) {

            throw error
        }
    },

    async deleteSupplier({ dispatch, getters, commit }) {
        try {

            await dispatch('tyltoApi/delete', '/suppliers/' + getters['getId'], { root: true })

            commit('resetDefaultState')

        } catch (error) {

            throw error
        }
    },

    async deleteTenant({ dispatch, getters, commit }) {
        try {

            await dispatch('tyltoApi/delete', '/tenants/' + getters['getId'], { root: true })

            commit('resetDefaultState')

        } catch (error) {

            throw error
        }
    },

    async fetchAccountingAccountRelationship({ dispatch, getters, commit }, params) {
        try {

            let accountingAccount = []

            let profileRole = typeof params.profileRole !== 'undefined' ? params.profileRole : getters['getRole']

            let profileId = typeof params.profileId !== 'undefined' ? params.profileId : getters['getId']

            const { data } = await this.$axios({ method: 'get', url: `${profileRole}/${profileId}/relationships/accounting_account` })

            if (data['data'] !== null) accountingAccount = await dispatch('accounting-account/fetchAccountingAccount', data['data']['id'], { root: true })

            commit('setRelatedAccountingAccount', accountingAccount)

            return accountingAccount

        } catch (error) {

            throw error
        }
    },

    async fetchAndFillLandlord({ dispatch }, landlordId) {
        try {

            let landlord = await dispatch('fetchLandlord', landlordId)

            await dispatch('fillLandlord', landlord)

            return landlord

        } catch (error) {

            throw error
        }
    },

    async fetchAndFillSupplier({ dispatch }, supplierId) {
        try {

            let supplier = await dispatch('fetchSupplier', supplierId)

            await dispatch('fillSupplier', supplier)

            return supplier

        } catch (error) {

            throw error
        }
    },

    async fetchAndFillTenant({ dispatch }, tenantId) {
        try {

            let tenant = await dispatch('fetchTenant', tenantId)

            await dispatch('fillTenant', tenant)

            return tenant

        } catch (error) {

            throw error
        }
    },

    async fetchAppliedFeesRelationship({ dispatch, commit, getters }, { params }) {
        try {

            let landlordId = typeof params.landlordId !== 'undefined' ? params.landlordId : getters['getId']

            const { data } = await this.$axios({ method: 'get', url: `landlords/${landlordId}/relationships/applied_fees` })

            const appliedFees = await Promise.all(data['data'].map(relationship => dispatch('fees/fetchFee', relationship.id, { root: true })))

            commit('setRelatedAppliedFees', appliedFees)

            return appliedFees

        } catch (error) {

            throw error
        }
    },

    async fetchFeesRelationship({ dispatch, commit, getters }, { params }) {
        try {

            let landlordId = typeof params.landlordId !== 'undefined' ? params.landlordId : getters['getId']

            const { data } = await this.$axios({ method: 'get', url: `landlords/${landlordId}/relationships/fees` })

            const fees = await Promise.all(data['data'].map(relationship => dispatch('fees/fetchFee', relationship.id, { root: true })))

            commit('setRelatedFees', fees)

            return fees

        } catch (error) {

            throw error
        }
    },

    async fetchInvitationRelationshipToLandlord({ dispatch, getters, commit }) {
        try {

            let landlordInvitations = []

            if (getters['getRelatedInvitations'].length) landlordInvitations = getters['getRelatedInvitations']

            else {

                const { data } = await this.$axios({ method: 'get', url: `landlords/${getters['getId']}/relationships/invitations` })

                landlordInvitations = await Promise.all(data['data'].map(relationship => dispatch('invitation/fetchInvitation', relationship.id, { root: true })))
            }

            commit('setRelatedInvitations', landlordInvitations)

            return landlordInvitations

        } catch (error) {

            throw error
        }
    },

    async fetchInvitationRelationshipToTenant({ dispatch, getters, commit }) {
        try {

            let tenantInvitations = []

            if (getters['getRelatedInvitations'].length) tenantInvitations = getters['getRelatedInvitations']

            else {

                const { data } = await this.$axios({ method: 'get', url: `tenants/${getters['getId']}/relationships/invitations` })

                tenantInvitations = await Promise.all(data['data'].map(relationship => dispatch('invitation/fetchInvitation', relationship.id, { root: true })))
            }

            commit('setRelatedInvitations', tenantInvitations)

            return tenantInvitations

        } catch (error) {

            throw error
        }
    },

    async fetchLandlord({ dispatch }, landlordId) {
        try {

            let landlord = await dispatch('getLandlord', landlordId)

            return landlord

        } catch (error) {

            throw error
        }
    },

    async fetchLandlordDocumentsRelationship({ dispatch, getters, commit }) {
        try {

            let landlordDocuments = []

            if (getters['getRelatedDocuments'].length) landlordDocuments = getters['getRelatedDocuments']

            else {

                const { data } = await this.$axios({ method: 'get', url: `landlords/${getters['getId']}/relationships/documents` })


                landlordDocuments = await Promise.all(data['data'].map(relationship => dispatch('file/fetchFile', relationship.id, { root: true })))
            }

            commit('setRelatedDocuments', landlordDocuments)

            return landlordDocuments

        } catch (error) {

            throw error
        }
    },

    async fetchLandlordPropertiesRelationship({ dispatch, commit, getters }) {
        try {

            let properties = await dispatch('tyltoApi/get', `landlords/${getters['getId']}/properties`, { root: true })

            delete properties['_jv']

            commit('setRelatedProperties', Object.values(properties))

            return Object.values(properties)

        } catch (error) {

            throw error
        }
    },

    async fetchLandlords({ dispatch }, params) {
        try {

            const response = await dispatch('tyltoApi/get', ['landlords', { page: queryBuilder(params) }], { root: true })

            return queryResponse(response)

        } catch (error) {

            throw error
        }
    },

    async fetchMandatesLandlord({ dispatch, getters }) {
        try {

            const response = await dispatch('tyltoApi/get', 'landlords/' + getters['getId'] + '/mandates', { root: true })

            return queryResponse(response)

        } catch (error) {

            throw error
        }
    },

    async fetchAndFillPaymentMethodsRelationship({ dispatch, commit, getters }, params) {
        try {

            let tenantId = typeof params !== 'undefined' ? params.tenantId : getters['getId']

            const { data } = await this.$axios({ method: 'get', url: `tenants/${tenantId}/relationships/payment_methods` })

            const paymentMethods = await Promise.all(data['data'].map(relationship => dispatch('payment-method/fetchAndFillPaymentMethod', relationship.id, { root: true })))

            commit('setRelatedPaymentMethods', paymentMethods)

            return paymentMethods

        } catch (error) {

            throw error
        }
    },

    async fetchDefaultPayoutBankAccountRelationship({ dispatch, getters, commit }) {
        try {

            let defaultPayoutBankAccount = {}

            const { data } = await this.$axios({ method: 'get', url: `${getters['getRole']}/${getters['getId']}/relationships/payout_bank_account` })

            if (data['data'] !== null) defaultPayoutBankAccount = await dispatch('bank-account/fetchPayoutBankAccount', data['data'].id, { root: true })

            commit('setRelatedDefaultPayoutBankAccount', defaultPayoutBankAccount)

            return defaultPayoutBankAccount

        } catch (error) {

            throw error
        }
    },

    async fetchSupplier({ dispatch }, supplierId) {
        try {

            let supplier = await dispatch('getSupplier', supplierId)

            return supplier

        } catch (error) {

            throw error
        }
    },

    async fetchTenant({ dispatch }, tenantId) {
        try {

            let tenant = await dispatch('getTenant', tenantId)

            return tenant

        } catch (error) {

            throw error
        }
    },

    async fetchTenantDocumentsRelationship({ dispatch, getters, commit }) {
        try {

            let tenantDocuments = []

            if (getters['getRelatedDocuments'].length) tenantDocuments = getters['getRelatedDocuments']

            else {

                const { data } = await this.$axios({ method: 'get', url: `tenants/${getters['getId']}/relationships/documents` })

                tenantDocuments = await Promise.all(data['data'].map(relationship => dispatch('file/fetchFile', relationship.id, { root: true })))
            }

            commit('setRelatedDocuments', tenantDocuments)

            return tenantDocuments

        } catch (error) {

            throw error
        }
    },

    async fetchTenantGuarantorsRelationship({ dispatch, getters, commit }, tenantId) {
        try {

            let tenantGuarantors = []

            if (getters['getRelatedGuarantors'].length) tenantGuarantors = getters['getRelatedGuarantors']

            else {

                const id = typeof tenantId !== 'undefined' ? tenantId : getters['getId']

                const { data } = await this.$axios({ method: 'get', url: `tenants/${id}/guarantors` })

                tenantGuarantors = await Promise.all(data['data'].map(relationship => dispatch('tyltoApi/get', `guarantors/${relationship.id}`, { root: true })))
            }

            commit('setRelatedGuarantors', tenantGuarantors)

            return tenantGuarantors

        } catch (error) {

            throw error
        }
    },

    async fetchTenantGuarantorRelatedDocumentsRelationship({ dispatch, getters, commit }, guarantorId) {
        try {

            let guarantorDocuments = []

            const { data } = await this.$axios({ method: 'get', url: `guarantors/${guarantorId}/relationships/documents` })

            guarantorDocuments = await Promise.all(data['data'].map(relationship => dispatch('file/fetchFile', relationship.id, { root: true })))

            return guarantorDocuments

        } catch (error) {

            throw error
        }
    },

    async fetchTenantPaymentMethodsRelationship({ dispatch, getters, commit }, params) {
        try {

            let tenantPaymentMethods = []

            let tenantId = typeof params !== 'undefined' ? params.tenantId : getters['getId']

            const { data } = await this.$axios({ method: 'get', url: `tenants/${tenantId}/relationships/payment_methods` })

            tenantPaymentMethods = await Promise.all(data['data'].map(relationship => dispatch('payment-method/fetchPaymentMethod', relationship.id, { root: true })))

            commit('setRelatedPaymentMethods', tenantPaymentMethods)

            return tenantPaymentMethods

        } catch (error) {

            throw error
        }
    },

    async fetchTenantRentalsRelationship({ dispatch, getters, commit }, tenantId) {
        try {

            let tenantRentals = []

            let id = typeof tenantId !== 'undefined' ? tenantId : getters['getId']

            const request = {
                _jv: {
                    type: 'tenants',
                    id: id,
                    relationships: {
                        rentals: undefined
                    }
                }
            }

            const response = await dispatch('tyltoApi/getRelated', request, { root: true })

            if (typeof response.rentals !== 'undefined') tenantRentals = Object.values(response.rentals.rentals)

            commit('setRelatedRentals', tenantRentals)

            return tenantRentals

        } catch (error) {

            throw error
        }
    },

    async fetchTenants({ dispatch }, params) {
        try {

            const response = await dispatch('tyltoApi/get', ['tenants', { params: queryBuilder(params) }], { root: true })

            return queryResponse(response)

        } catch (error) {

            throw error
        }
    },

    async fetchSuppliers({ dispatch }, params) {
        try {

            const response = await dispatch('tyltoApi/get', ['suppliers', { params: queryBuilder(params) }], { root: true })

            return queryResponse(response)

        } catch (error) {

            throw error
        }
    },

    async fillLandlord({ commit }, profile) {

        commit('resetDefaultState')

        commit('setRole', 'landlords')

        commit('setFullName', fullNameMixin.methods.getProfileFullName(profile))

        if (typeof profile.legal_persons !== 'undefined' && profile.legal_persons.length)
            commit('setLegalPersons', profile.legal_persons)

        if (typeof profile.entity !== 'undefined')
            commit('setEntity', profile.entity)

        if (typeof profile.phone_number !== 'undefined')
            commit('setPhoneNumber', profile.phone_number)

        if (typeof profile.created_at !== 'undefined')
            commit('setCreatedAt', profile.created_at)

        if (typeof profile.email_address !== 'undefined')
            commit('setEmailAddress', profile.email_address)

        if (typeof profile.matrimonial_status !== 'undefined')
            commit('setMatrimonialStatus', profile.matrimonial_status)

        if (typeof profile['_jv'] !== 'undefined')
            commit('setId', profile['_jv'].id)

        if (typeof profile.payout_delay !== 'undefined')
            commit('setPayoutDelay', profile.payout_delay)

        if (typeof profile.payout_bank_account !== 'undefined' && Object.keys(profile.payout_bank_account).length)
            commit('setDefaultPayoutBankAccount', profile.payout_bank_account)

        if (typeof profile.invitations !== 'undefined' && Object.values(profile.invitations).filter(item => Object.keys(item).length).length === profile['_jv']['relationships']['invitations']['data'].length)
            commit('setRelatedInvitations', Object.values(profile.invitations))

        if (typeof profile.properties !== 'undefined' && Object.values(profile.properties).filter(item => Object.keys(item).length).length === profile['_jv']['relationships']['properties']['data'].length)
            commit('setRelatedProperties', Object.values(profile.properties))

        if (typeof profile['_jv']['relationships'] !== 'undefined' &&
            typeof profile['_jv']['relationships']['property_management_company'] !== 'undefined' &&
            Object.keys(profile['_jv']['relationships']['property_management_company']).length) {

                commit('setRelatedPropertyManager', profile['_jv']['relationships']['property_management_company'])
        }
        if (typeof profile['_jv']['relationships'] !== 'undefined' &&
            typeof profile['_jv']['relationships']['accounting_account'] !== 'undefined' &&
            Object.keys(profile['_jv']['relationships']['accounting_account']).length) {

                commit('setRelatedAccountingAccount', profile['_jv']['relationships']['accounting_account'])
        }
    },

    async fillSupplier({ commit, dispatch }, profile) {

        commit('resetDefaultState')

        commit('setRole', 'suppliers')

        commit('setFullName', fullNameMixin.methods.getProfileFullName(profile))

        if (typeof profile.legal_person !== 'undefined' && Object.keys(profile.legal_person).length)
            commit('setLegalPersons', [profile.legal_person])

        if (typeof profile.entity !== 'undefined')
            commit('setEntity', profile.entity)

        if (typeof profile.phone_number !== 'undefined')
            commit('setPhoneNumber', profile.phone_number)

        if (typeof profile.created_at !== 'undefined')
            commit('setCreatedAt', profile.created_at)

        if (typeof profile.email_address !== 'undefined')
            commit('setEmailAddress', profile.email_address)

        if (typeof profile['_jv'] !== 'undefined')
            commit('setId', profile['_jv'].id)

        if (typeof profile.payout_bank_account !== 'undefined' && Object.keys(profile.payout_bank_account).length)
            commit('setDefaultPayoutBankAccount', profile.payout_bank_account)
    },

    async fillTenant({ commit }, profile) {

        commit('resetDefaultState')

        commit('setRole', 'tenants')

        commit('setFullName', fullNameMixin.methods.getProfileFullName(profile))

        if (typeof profile.legal_persons !== 'undefined' && profile.legal_persons.length)
            commit('setLegalPersons', profile.legal_persons)

        if (typeof profile.entity !== 'undefined')
            commit('setEntity', profile.entity)

        if (typeof profile.phone_number !== 'undefined')
            commit('setPhoneNumber', profile.phone_number)

        if (typeof profile.created_at !== 'undefined')
            commit('setCreatedAt', profile.created_at)

        if (typeof profile.email_address !== 'undefined')
            commit('setEmailAddress', profile.email_address)

        if (typeof profile.matrimonial_status !== 'undefined')
            commit('setMatrimonialStatus', profile.matrimonial_status)

        if (typeof profile['_jv'] !== 'undefined')
            commit('setId', profile['_jv'].id)

        if (typeof profile.payment_method !== 'undefined' && Object.keys(profile.payment_method).length)
            commit('setPaymentMethod', profile.payment_method)

        if (typeof profile.payout_bank_account !== 'undefined' && Object.keys(profile.payout_bank_account).length)
            commit('setDefaultPayoutBankAccount', profile.payout_bank_account)

        if (typeof profile.invitations !== 'undefined' && Object.values(profile.invitations).filter(invitation => Object.keys(invitation).length).length === profile['_jv']['relationships']['invitations']['data'].length)
            commit('setRelatedInvitations', Object.values(profile.invitations))

        if (typeof profile.rentals !== 'undefined' && Object.values(profile.rentals).filter(rental => Object.keys(rental).length).length === profile['_jv']['relationships']['rentals']['data'].length)
            commit('setRelatedRentals', Object.values(profile.rentals))

        if (
            typeof profile['_jv']['relationships'] !== 'undefined' &&
            typeof profile['_jv']['relationships']['property_management_company'] !== 'undefined' &&
            Object.keys(profile['_jv']['relationships']['property_management_company']).length
        ) {
            commit('setRelatedPropertyManager', profile['_jv']['relationships']['property_management_company'])
        }
    },

    async getLandlord({ dispatch, rootGetters }, landlordId) {
        try {

            return await dispatch('tyltoApi/get', 'landlords/' + landlordId + '?include=users', { root: true })

        } catch (error) {

            throw error
        }
    },

    async getLandlordRequestData({ getters }) {
        let landlord = {
            payout_delay: getters['getPayoutDelay'],
            email_address: getters['getEmailAddress'],
            '_jv': {
                type: 'landlords',
            }
        }

        if (getters['getLegalPersons'].length) {

            landlord['legal_persons'] = getters['getLegalPersons'].map(legalPerson => recursiveDeleteEmptyEntries(legalPerson))
            landlord['entity'] = getters['getEntity']
        }

        if (getters['getPhoneNumber'] !== '') {
            landlord['phone_number'] = getters['getPhoneNumber']
        }

        if (getters['getMatrimonialStatus'] !== '') {
            landlord['matrimonial_status'] = getters['getMatrimonialStatus']
        }

        if (Object.keys(getters['getRelatedDefaultPayoutBankAccount']).length) {
            landlord['_jv']['relationships'] = {
                payout_bank_account: {
                    data: {
                        type: 'payout-bank-accounts',
                        id: getters['getRelatedDefaultPayoutBankAccount']['_jv'].id
                    }
                }
            }
        }

        if (getters['getId'] !== '') {
            landlord['_jv']['id'] = getters['getId']
        }

        return landlord
    },

    async getSupplier({ dispatch, rootGetters }, supplierId) {
        try {

            return await dispatch('tyltoApi/get', 'suppliers/' + supplierId, { root: true })

        } catch (error) {

            throw error
        }
    },

    async getSupplierRequestData({ getters }) {
        let supplier = {
            email_address: getters['getEmailAddress'],
            _jv: {
                type: 'suppliers'
            }
        }

        if (getters['getLegalPersons'].length) {

            supplier['legal_person'] = getters['getLegalPersons'].map(legalPerson => recursiveDeleteEmptyEntries(legalPerson))[0]
            supplier['entity'] = getters['getEntity']
        }

        if (typeof getters['getPaymentMethod'] !== 'undefined' && Object.keys(getters['getPaymentMethod']).length) {

            supplier['payment_method'] = getters['getPaymentMethod']
        }

        if (getters['getPhoneNumber'] !== '') {
            supplier['phone_number'] = getters['getPhoneNumber']
        }

        if (Object.keys(getters['getRelatedDefaultPayoutBankAccount']).length) {
            supplier['_jv']['relationships'] = {
                payout_bank_account: {
                    data: {
                        type: 'payout-bank-accounts',
                        id: getters['getRelatedDefaultPayoutBankAccount']['_jv'].id
                    }
                }
            }
        }

        if (getters['getId'] !== '') {
            supplier['_jv']['id'] = getters['getId']
        }

        return supplier
    },

    async getTenant({ dispatch, rootGetters }, tenantId) {
        try {

            return await dispatch('tyltoApi/get', 'tenants/' + tenantId + '?include=users', { root: true })

        } catch (error) {

            throw error
        }
    },

    async getTenantRequestData({ getters }) {
        let tenant = {
            email_address: getters['getEmailAddress'],
            '_jv': {
                type: 'tenants'
            }
        }

        if (getters['getLegalPersons'].length) {

            tenant['legal_persons'] = getters['getLegalPersons'].map(legalPerson => recursiveDeleteEmptyEntries(legalPerson))
            tenant['entity'] = getters['getEntity']
        }

        if (getters['getPhoneNumber'] !== '') {
            tenant['phone_number'] = getters['getPhoneNumber']
        }

        if (getters['getMatrimonialStatus'] !== '') {
            tenant['matrimonial_status'] = getters['getMatrimonialStatus']
        }

        if (getters['getId'] !== '') {
            tenant['_jv']['id'] = getters['getId']
        }

        return tenant
    },

    legalPersonCompanyDetailsProgress({ }, legalPerson) {

        let profileBusinessName = typeof legalPerson.business_name !== 'undefined' ? legalPerson.business_name : ''

        let incorporationDate = typeof legalPerson.incorporation_date !== 'undefined' ? legalPerson.incorporation_date : ''

        let identificationNumber = typeof legalPerson.identification_number !== 'undefined' ? legalPerson.identification_number : ''

        let tradeName = typeof legalPerson.trade_name !== 'undefined' ? legalPerson.trade_name : ''

        let profileAddress = typeof legalPerson.address !== 'undefined' ? legalPerson.address : {}

        return [profileBusinessName, profileAddress, incorporationDate, identificationNumber, tradeName]
    },

    legalPersonIndividualDetailsProgress({ }, legalPerson) {

        let profileFirstName = typeof legalPerson.first_name !== 'undefined' ? legalPerson.first_name : ''

        let profileLastName = typeof legalPerson.last_name !== 'undefined' ? legalPerson.last_name : ''

        let profileBirthDate = typeof legalPerson.birth_date !== 'undefined' ? legalPerson.birth_date : ''

        let profileAddress = typeof legalPerson.address !== 'undefined' ? legalPerson.address : {}

        return [profileFirstName, profileLastName, profileBirthDate, profileAddress]
    },

    async landlordProfilCompleteProgress({ getters, dispatch }) {

        let landlordItems = [getters['getEmailAddress'], getters['getPhoneNumber'], getters['getRelatedDefaultPayoutBankAccount']]

        if (!getters['getLegalPersons'].length) landlordItems.push({})

        else {

            for (let legalPerson of getters['getLegalPersons']) {

                if (legalPerson['type'] === 'juridical_person') landlordItems = landlordItems.concat(await dispatch('legalPersonCompanyDetailsProgress', legalPerson))

                else if (legalPerson['type'] === 'natural_person') landlordItems = landlordItems.concat(await dispatch('legalPersonIndividualDetailsProgress', legalPerson))
            }
        }

        return await dispatch('user/progressFilter', landlordItems, { root: true })
    },

    async profileDetailsProgress({ getters, dispatch }) {

        let detailsItems = []

        if (!getters['getLegalPersons'].length) detailsItems.push({})

        else {

            for (let legalPerson of getters['getLegalPersons']) {

                if (legalPerson['type'] === 'juridical_person') detailsItems = detailsItems.concat(await dispatch('legalPersonCompanyDetailsProgress', legalPerson))

                else if (legalPerson['type'] === 'natural_person') detailsItems = detailsItems.concat(await dispatch('legalPersonIndividualDetailsProgress', legalPerson))
            }
        }

        return await dispatch('user/progressFilter', detailsItems, { root: true })
    },

    async tenantProfilCompleteProgress({ getters, dispatch }) {

        let tenantItems = [getters['getEmailAddress'], getters['getPhoneNumber']]

        if (!getters['getLegalPersons'].length) tenantItems.push({})

        else {

            for (let legalPerson of getters['getLegalPersons']) {

                if (legalPerson['type'] === 'juridical_person') tenantItems = tenantItems.concat(await dispatch('legalPersonCompanyDetailsProgress', legalPerson))

                else if (legalPerson['type'] === 'natural_person') tenantItems = tenantItems.concat(await dispatch('legalPersonIndividualDetailsProgress', legalPerson))
            }
        }

        return await dispatch('user/progressFilter', tenantItems, { root: true })
    },

    async updateLandlord({ dispatch }) {
        try {

            const requestData = await dispatch('getLandlordRequestData')

            const landlord = await dispatch('tyltoApi/patch', requestData, { root: true })

            await dispatch('fillLandlord', landlord)

            return landlord

        } catch (error) {

            throw error
        }
    },

    async updateLandlordRelatedDocuments({ getters }) {
        try {

            const requestData = {
                data: getters['getRelatedDocuments'].map(document => {
                    return {
                        type: 'files',
                        id: document['_jv'].id
                    }
                })
            }

            const documents = await this.$axios.patch(`landlords/${getters['getId']}/relationships/documents`, requestData)

            return documents

        } catch (error) {

            throw error
        }
    },

    async updatePaymentMethodsRelationship({ getters }, params) {
        try {

            let tenantId = typeof params.tenantId !== 'undefined' ? params.tenantId : getters['getId']

            const requestData = {
                data: getters['getRelatedPaymentMethods'].map(paymentMethod => {
                    return {
                        type: 'payment-methods',
                        id: paymentMethod['_jv'].id
                    }
                })
            }

            const paymentMethods = await this.$axios.patch(`tenants/${tenantId}/relationships/payment_methods`, requestData)

            return paymentMethods

        } catch (error) {

            throw error
        }
    },

    async updateSupplier({ dispatch }) {
        try {

            const requestData = await dispatch('getSupplierRequestData')

            const supplier = await dispatch('tyltoApi/patch', requestData, { root: true })

            await dispatch('fillSupplier', supplier)

            return supplier

        } catch (error) {

            throw error
        }
    },

    async updateTenant({ dispatch }) {
        try {

            const requestData = await dispatch('getTenantRequestData')

            const tenant = await dispatch('tyltoApi/patch', requestData, { root: true })

            await dispatch('fillTenant', tenant)

            return tenant

        } catch (error) {

            throw error
        }
    },

    async updateTenantGuarantor({ dispatch, commit }, guarantor){
        try {

            const { _jv, ...cleanGuarantor } = guarantor

            let request = {
                ...recursiveDeleteEmptyEntries(cleanGuarantor),
                _jv: {
                    type: 'guarantors',
                    id: guarantor['_jv'].id
                }
            }

            return await dispatch('tyltoApi/patch', request, { root: true })

        } catch (error) {

            throw error
        }
    },

    async updateTenantRelationshipGuarrantors({ getters }) {
        try {

            const relatedGuarantors = getters['getRelatedGuarantors'].filter(guarantor => guarantor['_jv'])

            const requestData = {
                data: relatedGuarantors.map(guarantor => {
                    return {
                        type: 'guarantors',
                        id: guarantor['_jv'].id
                    }
                })
            }

            const guarantors = await this.$axios.patch(`tenants/${getters['getId']}/relationships/guarantors`, requestData)

            return guarantors

        } catch (error) {

          throw error
        }
    },

    async updateTenantGuarantorRelatedDocuments({ getters }, { guarantorId, guarantorDocuments }) {
        try {

            const requestData = {
                data: guarantorDocuments.map(document => {
                    return {
                        type: 'files',
                        id: document['_jv'].id
                    }
                })
            }

            const documents = await this.$axios.patch(`guarantors/${guarantorId}/relationships/documents`, requestData)

            return documents

        } catch (error) {

            throw error
        }
    },

    async updateTenantRelationshipDocuments({ getters }) {
        try {

            const requestData = {
                data: getters['getRelatedDocuments'].map(document => {
                    return {
                        type: 'files',
                        id: document['_jv'].id
                    }
                })
            }

            const documents = await this.$axios.patch(`tenants/${getters['getId']}/relationships/documents`, requestData)

            return documents

        } catch (error) {

            throw error
        }
    }
}
