<template>

    <sidebar :options="sidebarOptions" ref="sidebar" class="supplier-sidebar">

        <div class="section-header contact">

            <svg-icon :iconColor="'#333333'" width="16" height="16" viewBox="0 0 24 24"><phone-icon /></svg-icon>

            <h3 class="title">{{ $t('contact') }}</h3>

        </div>

        <readonly-input name="email_address" class="email-address" :label="$t('email_address')" :value="supplier.email_address" />

        <readonly-input name="phone_number" class="phone-number" :label="$t('phone_number')" :value="supplier.phone_number" />

        <div v-for="(person, index) in supplierLegalPersons" :key="index">

            <hr class="separator-min">

            <readonly-input name="name" class="readonly-spacing" :label="person.type === 'natural_person' ? $t('name') :  $t('business_name')" :value="getLegalPersonFullName(person)" />

            <readonly-input name="address" class="readonly-spacing" :label="$t('address')" :value="person.address ? person.address.formatted : ''" />

            <readonly-input name="birth_date" class="readonly-spacing" :label="$t('birth_date')" :value="person.birth_date ? $moment(person.birth_date).format('Do MMMM YYYY') : ''" v-if="person.birth_date"/>

        </div>

        <hr class="separator">

        <div class="section-header bankaccount">

            <svg-icon :iconColor="'#333333'" width="16" height="16"><bankaccount-icon /></svg-icon>

            <h3 class="title">{{ $t('bank_account') }}</h3>

        </div>

        <div v-if="bankAccounts.length">

            <div class="bank-accounts" v-for="(account, index) in bankAccounts" :key="index">

                <readonly-input name="bank_account_holder_name" class="readonly-spacing" :label="$t('bank_account_holder_name')" :value="account.holder_name" />

                <readonly-input name="bank_account" class="readonly-spacing" :label="$t('bank')" :value="account.bank_name" />

                <readonly-input name="bank_account_iban" class="readonly-spacing" :label="$t('bank_account_iban')" :hideSensitivePaymentInformations="true" :value="account.iban ? account.iban : ''" />

                <hr class="separator-min">

            </div>
        
        </div>

        <p v-else> {{ $t('no_bank') }}</p>

    </sidebar>

</template>

<script>
    import Sidebar from '~/components/sidebar'
    import SvgIcon from '~/components/svg-icon'
    import { PhoneIcon, BankaccountIcon } from '~/components/icons/button-icons'
    import ButtonInput from '~/components/inputs/button-input'
    import FullNameMixin from '~/components/mixins/full-name'

    export default {
        mixins: [FullNameMixin],

        components: {
            Sidebar,
            SvgIcon,
            PhoneIcon,
            BankaccountIcon,
            ButtonInput
        },

        data() {
            return {
                supplier: {},
                loading: false,
                bankAccounts: []
            }
        },

        props: {
            supplierId: {
                type: String,
                required: true
            },
            supplierType: {
                type: String,
                required: true
            }
        },

        methods: {
            async fetchSupplier() {
                try {

                    if (this.supplierType === 'suppliers') {

                        this.supplier = await this.$store.dispatch('profile/fetchAndFillSupplier', this.supplierId)

                        this.bankAccounts = await this.$store.dispatch('profile/fetchDefaultPayoutBankAccountRelationship')
                    }

                    else {

                        this.supplier = await this.$store.dispatch('property-manager/fetchPropertyManagementCompany', this.supplierId)

                        const { response } = await this.$store.dispatch('property-manager/fetchBankAccounts')

                        this.bankAccounts = response
                    }
                    
                } catch(error) {

                    throw error
                }
            },

            resetRelatedSupplier() {
                
                this.$store.commit('profile/resetDefaultState')
            },

            redirectToSupplier() {

                this.$router.push(`/supplier/${this.supplierId}`)
            },

            show() {
                return this.$nextTick(() => {
                    if (!!this.$refs.sidebar) this.$refs.sidebar.show()
                })
            },

            hide() {
                return this.$nextTick(() => {
                    if (!!this.$refs.sidebar) this.$refs.sidebar.hide()
                })
            }
        },

        computed: {
            sidebarOptions() {
                return {
                    header: {
                        type: this.$t('supplier'),
                        title: this.getProfileFullName(this.supplier),
                        subTitle: this.supplier.created_at ? `Profil créé le ${this.$moment(this.supplier.created_at).format('Do MMMM YYYY')}` : ''
                    },
                    fetch: this.fetchSupplier,
                    close: this.resetRelatedSupplier,
                    submit: {
                        action: () => this.redirectToSupplier()
                    }
                }
            },

            supplierLegalPersons() {
                if (Object.keys(this.supplier).length === 0) return []
                
                if (typeof this.supplier.legal_persons !== 'undefined') return this.supplier.legal_persons

                else return [this.supplier.legal_person]
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "see_file": "Voir la fiche complète",
            "supplier": "Fournisseur",
            "email_address": "Adresse email",
            "name": "Nom complet",
            "business_name": "Dénomination sociale",
            "phone_number": "Téléphone",
            "address": "Adresse",
            "bank_account_holder_name": "Titulaire",
            "bank": "Banque",
            "bank_account_iban": "IBAN",
            "contact": "Contact",
            "bank_account": "Compte bancaire",
            "birth_date": "Date de naissance",
            "no_bank": "Aucun compte bancaire fourni"
        }
    }
</i18n>

<style lang="scss" scoped>
    .sidebar {
        .container  {
            .section-header {
                margin-bottom: 12px;
            }
            .separator {
                margin-bottom: 16px ;
            }
        }
    }

    .readonly-spacing {
        align-items: initial;
    }

    .bank-accounts:last-child {
        .separator-min {
            height: 0 !important;
        }
    }
</style>