<template>

    <form-group class="check-box-input-list" :label="label" :name="name" :rules="rules" v-slot="{ errors }">

        <input type="text" :value="value" v-show="false" />

        <check-box-input :class="['check-box-input', { 'errors': errors.length }]" v-for="(option, key) in options" :key="key" 

            :label="option.text" :name="option.value" 

            :value="getOptionCount(option.value)" 

            @input="updateCount" />

    </form-group>

</template>

<script>
    import FormGroup from '~/components/inputs/form-group'

    export default {
        components: { 
            FormGroup
        },
        
        props: {
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            options: {
                type: Array,
                default() {
                    return []
                }
            },
            value: {
                type: Array,
                required: true
            },
            rules: {
                type: [String, Object],
                default: ''
            }
        },
        
        methods: {
            getOptionObject(option) {
                for (let i in this.value) {
                    if (this.value[i].type === option) 
                        return this.value[i]
                }
            },
            
            getOptionCount(option) {
                return typeof this.getOptionObject(option) !== 'undefined' ? Boolean(this.getOptionObject(option).count) : false
            },
            
            updateCount(checked, type) {
                let count = checked ? 1 : 0
                this.$emit('input', {
                    type,
                    count
                })
            }
        }
    }
</script>

<style lang="scss">
    .check-box-input-list {
        display: flex;
        flex-direction: column;
    }
</style>
