// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../node_modules/material-icons/iconfont/MaterialIcons-Regular.eot");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../node_modules/material-icons/iconfont/MaterialIcons-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../node_modules/material-icons/iconfont/MaterialIcons-Regular.woff");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../node_modules/material-icons/iconfont/MaterialIcons-Regular.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Material Icons\";font-style:normal;font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");src:local(\"Material Icons\"),local(\"MaterialIcons-Regular\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\")}.material-icons{font-family:\"Material Icons\";font-weight:400;font-style:normal;font-size:24px;display:inline-block;line-height:1;text-transform:none;letter-spacing:normal;word-wrap:normal;white-space:nowrap;direction:ltr;-webkit-font-smoothing:antialiased;text-rendering:optimizeLegibility;-moz-osx-font-smoothing:grayscale;font-feature-settings:\"liga\"}.toast-container .toasted.toasted-primary{border-radius:10px;box-shadow:0 3px 50px 0 rgba(217,223,240,.52157)}.toast-container .toasted.toasted-primary.error{background:#fbe8e8;padding:20px;border:3px solid #fff;color:#000;font-weight:600;font-size:12pt}.toast-container .toasted.toasted-primary.error .material-icons{color:#ff657f;margin:0 20px 0 10px}.toast-container .toasted.toasted-primary.error .action>.material-icons{margin-left:24px;color:#000}.toast-container .toasted.toasted-primary.success{background-color:#fff;padding:20px;color:#000;font-weight:600;font-size:12pt}.toast-container .toasted.toasted-primary.success .material-icons{background-color:#d7ffe7;padding:4px;border-radius:100%;color:#18c95d;margin:0 20px 0 10px}.toast-container .toasted.toasted-primary.success .action>.material-icons{padding:0;background:none;color:#000;margin-left:24px}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
