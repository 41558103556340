<template>

    <div class="specific-clauses-input-list">

        <div class="specific-clauses-minified" v-for="(clause, clauseKey) in value" :key="clauseKey">

            <div class="specific-clauses-header">
                
                <h3 class="title">{{ `${$t('specific_clause')} ${clauseKey + 1}` }}</h3>

                <div class="buttons">

                    <button-input class="button background-transparent green-text-color" :text="$t('update')" @click="updateclause(clauseKey)"/>

                    <div class="separator"></div>

                    <button-input class="button background-transparent grey-text-color" :text="$t('delete')" @click="deleteItem(clauseKey)" />

                </div>
                
            </div>

            <p class="specific-clauses-text">{{ clause }}</p>

        </div>

        <template v-if="editMode">

            <step-nested-form :title="$t('specific_clause')" :itemCount="updateMode ? clauseIndex : value.length" :onSubmit="addNewClause" :addNewButton="false" :open="editMode" :fields="{newClause}" @close="resetClauseForm">

                <template>

                    <text-area-input class="fullsize" :label="$t('clause')" :name="`${name}`" v-model="newClause" :rules="rules" :placeholder="$t('text_area_placeholder')" />

                </template>

            </step-nested-form>
            
        </template>

        <button-input :class="['new_clauses_action', 'background-transparent', 'green-text-color', 'grey-light-border-color-square']" :text="$t('new_specific_clause')" viewbox="0 0 24 24" iconImg="add_new_circle" @click="editMode = true" v-if="!editMode" />

    </div>

</template>

<script>

    export default {
        props: {
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            value: {
                type: Array,
                required: true
            },
            placeholder: {
                type: String,
                required: false
            },
            rules: {
                type: String,
                required: false
            }
        },

        data() {
            return {
                editMode: false,
                updateMode: false,
                newClause: '',
                clauseIndex: 0
            }
        },

        methods: {
            emitValue(value, name) {             
                let res = this.value.slice(0)
                let key = Number(name.replace(this.name + '_', ''))
                res[key] = value
                this.$emit('input', res)
            },

            addNewClause() {
                let copy = [...this.value]

                if (this.clauseIndex !== -1) {
                    
                    if (this.newClause === '') return

                    copy.splice(this.clauseIndex, 1, this.newClause);

                    this.clauseIndex = -1

                    this.updateMode = false

                } else {

                    if (this.newClause === '') return

                    copy.push(this.newClause);
                }

                this.$emit('input', copy)

                this.resetClauseForm()
            },

            updateclause(key) {
                this.editMode = true
                this.updateMode = true
                this.clauseIndex = key
                this.newClause = this.value[key]
            },

            deleteItem(key) {
                this.$emit('input', this.value.slice(0, key).concat(this.value.slice(key + 1)))
            },
            
            resetClauseForm() {
                this.editMode = false
                this.updateMode = false
                this.newClause = ''
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "empty": "Aucune {type}",
            "add_new": "Ajouter un(e) {type}",
            "update": "Modifier",
            "delete": "Supprimer",
            "text_area_placeholder": "Il est expressément convenu entre les parties qu'à défaut de paiement d'une seule mensualité de loyer ou de charges à l'échéance prévue",
            "new_specific_clause": "Ajouter une clause particulière",
            "specific_clause": "Clause particulière",
            "clause": "Clause"
        }
    }
</i18n>

<style lang="scss" scoped>
    $delete-color: #FF657F;
    $add-color: #BED000;
    $borders-border-light: #E4E4E4;
    $gris-gris: #767676;
    $couleurs-primaires-title: #525254;
    
    .specific-clauses-input-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;

        &::v-deep .fullsize {
            padding-top: 14px;
        }

        .specific-clauses-minified {
            display: flex;
            padding: 24px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 32px;
            align-self: stretch;
            border-radius: 8px;
            border: 1px solid $borders-border-light;

            .specific-clauses-header {
                display: flex;
                align-items: center;
                gap: 32px;
                align-self: stretch;

                .title {
                    flex: 1 0 0;
                    color: $couleurs-primaires-title;
                    font-size: 12pt;
                    font-weight: 600;
                }

                .buttons {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 12px;

                    .separator {
                        width: 1px;
                        height: 16px;
                        background: $borders-border-light;
                    }

                    &::v-deep .button-input {
                        padding: 0;
                    }
                }
            }

            .specific-clauses-text {
                align-self: stretch;
                color: $gris-gris;
                font-size: 10.5pt;
                font-weight: 400; 
            }
        }

        .new_clauses_action {
            display: flex;
            flex-direction: row-reverse;
            font-weight: 600;
            padding: 24px;
            width: 100%;
            justify-content: space-between;

            &.margin-top {
                margin-top: 40px;
            }
        }
    }
</style>
