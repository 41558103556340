import { queryBuilder, queryResponse } from '~/utils/query'

export const strict = true

export const defaultState = () => {
    return {
        balance: 0,
        description: '',
        id: '',
        journal: '',
        number: '',
        relationships: {
            accounting_journal: {},
            property_management_company: {}
        }
    }
}

export const state = defaultState

export const mutations = {
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },

    setBalance(state, value) {
        state.balance = value
    },

    setDescription(state, value) {
        state.description = value
    },

    setId(state, value) {
        state.id = value
    },

    setJournal(state, value) {
        state.journal = value
    },

    setNumber(state, value) {
        state.number = value
    },

    setRelatedAccountingJournal(state, value) {
        state.relationships.accounting_journal = value
    },

    setRelatedPropertyManagementCompany(state, value) {
        state.relationships.property_management_company = value
    }
}

export const getters = {
    getBalance(state) {
        return state.balance
    },

    getDescription(state) {
        return state.description
    },

    getId(state) {
        return state.id
    },

    getJournal(state) {
        return state.journal
    },

    getNumber(state) {
        return state.number
    },

    getRelatedAccountingJournal(state) {
        return state.relationships.accounting_journal
    },

    getRelatedPropertyManagementCompany(state) {
        return state.relationships.property_management_company
    }
}

export const actions = {
    async fetchAccountingAccounts({ dispatch, rootGetters }, params) {
        try {

            const response = await dispatch('tyltoApi/get', [`property-management-companies/${rootGetters['property-manager/getId']}/accounting-accounts`, { params: queryBuilder(params) }], { root: true })

            return queryResponse(response)

        } catch(error) {

            throw error
        }
    },

    async fetchAccountingAccount({ dispatch, rootGetters }, accountingAccountId) {
        try {
  
            const accountingAccount = await dispatch('tyltoApi/get', `accounting-accounts/${accountingAccountId}`, { root: true })

            return accountingAccount

        } catch(error) {

            throw error
        }
    },

    async fetchAndFillAccountingAccount({ dispatch }, accountingAccountId) {
        try {

            let accountingAccount = await dispatch('fetchAccountingAccount', accountingAccountId)

            await dispatch('fillAccountingAccount', accountingAccount)

            return accountingAccount

        } catch(error) {

            throw error
        }
    },

    async fetchAccountingEntries({ dispatch, getters }, { params, aggregates, filters, sort, page }) { 
        try {

            const response = await dispatch('tyltoApi/get', [`accounting-accounts/${params.accountingAccountId}/accounting-entries`, { params: queryBuilder({ filters, aggregates, sort, page }) }], { root: true })

            return queryResponse(response)

        } catch(error) {

            throw error
        }
    },

    async fetchAccountingJournalRelationship({ dispatch, getters, commit }, accountingAccountId) {
        try {

            let accountingJournal = {}

            if (Object.keys(getters['getRelatedAccountingJournal']).length) accountingJournal = getters['getRelatedAccountingJournal']

            else {

                const id = getters['getId'] !== '' ? getters['getId'] : accountingAccountId
                
                const { data } = await this.$axios({ method: 'get', url: `accounting-accounts/${id}/relationships/accounting_journal` })

                if (data['data'] !== null) accountingJournal = await dispatch('accounting-journal/fetchAccountingJournal', data['data']['id'], { root: true })
            }

            commit('setRelatedAccountingJournal', accountingJournal)

            return accountingJournal

        } catch(error) {

            throw error
        }
    },

    async fetchPropertyManagementCompanyRelationship({ dispatch, getters, commit }, accountingAccountId) {
        try {

            let propertyManagementCompany = {}

            if (Object.keys(getters['getRelatedPropertyManagementCompany']).length) propertyManagementCompany = getters['getRelatedPropertyManagementCompany']

            else {
                
                const id = getters['getId'] !== '' ? getters['getId'] : accountingAccountId
                
                const { data } = await this.$axios({ method: 'get', url: `accounting-accounts/${id}/relationships/property_management_company` })

                if (data['data'] !== null) propertyManagementCompany = await dispatch('property-manager/fetchPropertyManagerCompany', data['data']['id'], { root: true })
            }

            commit('setRelatedPropertyManagementCompany', propertyManagementCompany)

            return propertyManagementCompany

        } catch(error) {

            throw error
        }
    },

    async createPropertyManagementCompanyAccountingAccount({ dispatch }, propertyManagementCompanyId) {
        try {

            let request = await dispatch('formatAccountingAccountRequest')

            const accountingAccount = await dispatch('tyltoApi/post', [request, { url: `property-management-companies/${propertyManagementCompanyId}/accounting-accounts` }], { root: true })

            await dispatch('fillAccountingAccount', accountingAccount)
            
            return accountingAccount

        } catch(error) {

            throw error
        }
    },

    async deleteAccountingAccount({ dispatch, getters }) {
        try {

            await dispatch('tyltoApi/delete', 'accounting-accounts/' + getters['getId'], { root: true })

            commit('resetDefaultState')

        } catch(error) {

            throw error
        }
    },

    async fillAccountingAccount({ commit }, accountingAccount) {
        commit('resetDefaultState')

        if (typeof accountingAccount['balance'] !== 'undefined')
            commit('setBalance', accountingAccount['balance'])

        if (typeof accountingAccount['description'] !== 'undefined')
            commit('setDescription', accountingAccount['description'])
        
        if (typeof accountingAccount['_jv'] !== 'undefined')
            commit('setId', accountingAccount['_jv'].id)

        if (typeof accountingAccount['journal'] !== 'undefined')
            commit('setJournal', accountingAccount['journal'])
        
        if (typeof accountingAccount['number'] !== 'undefined')
            commit('setNumber', accountingAccount['number'])

        if (typeof accountingAccount['accounting_journal'] !== 'undefined' && Object.keys(accountingAccount['accounting_journal']).length)
            commit('setRelatedAccountingJournal', accountingAccount['accounting_journal'])
        
        if (typeof accountingAccount['property_management_company'] !== 'undefined' && Object.keys(accountingAccount['property_management_company']).length)
            commit('setRelatedPropertyManagementCompany', accountingAccount['property_management_company'])
    },

    async formatAccountingAccountRequest({ getters }) {
        let accountingAccountRequest = {
            number: getters['getNumber'],
            _jv: {
                type: 'accounting-accounts'
            }
        }

        if (getters['getDescription'] !== '')
            accountingAccountRequest['description'] = getters['getDescription']

        if (getters['getId'] !== '')
            accountingAccountRequest['_jv'].id = getters['getId']

        if (getters['getJournal'] !== '')
            accountingAccountRequest['journal'] = getters['getJournal']

        if (Object.keys(getters['getRelatedPropertyManagementCompany']).length || Object.keys(getters['getRelatedAccountingJournal']).length) {
            
            accountingAccountRequest['_jv']['relationships'] = {}
            
            if (Object.keys(getters['getRelatedAccountingJournal']).length) {
                accountingAccountRequest['_jv']['relationships']['accounting_journal'] = {
                    data: {
                        type: 'accounting-journals',
                        id: getters['getRelatedAccountingJournal']['_jv'].id
                    }
                }
            }

            if (Object.keys(getters['getRelatedPropertyManagementCompany']).length) {
                accountingAccountRequest['_jv']['relationships']['property_management_company'] = {
                    data: {
                        type: 'property-management-companies',
                        id: getters['getRelatedPropertyManagementCompany']['_jv'].id
                    }
                }
            }
        }

        return accountingAccountRequest
    },

    async updateAccountingAccount() {
        try {

            let request = await dispatch('formatAccountingAccountRequest')

            const accountingAccount = await dispatch('tyltoApi/patch', request, { root: true })

            await dispatch('fillAccountingAccount', accountingAccount)

            return accountingAccount

        } catch(error) {

            throw error
        }
    }
}