<template>
    
    <modal class="confirm-before-exit-modal" :title="$t('save_before_exit')" :options="modalOptions" @submitted="submit" @canceled="hide" @close="hide" ref="modal">

        <p class="subtitle" v-html="$t('text')" />

    </modal>

</template>

<script>
    import Modal from '~/components/modal'
    
    export default {
        components: {
            Modal
        },

        data() {
            return {
                modalOptions: {
                    submit: {
                        action: () => this.submit()
                    }
                }
            }
        },

        methods: {
            submit() {
                this.$emit('action')

                this.hide()
            },

            show() {
                this.$refs.modal.show()
            },

            hide() {
                this.$refs.modal.hide()
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "save_before_exit": "Quitter sans sauvegarder",
            "text": "Si vous confirmer, les données saisies dans le formulaire seront supprimées."
        }
    }
</i18n>