export default {
    async createUser({ dispatch }) {
        try {

            const user = await dispatch('getFormatRequest')

            return await dispatch('tyltoApi/post', user, { root: true })

        } catch (error) {

            throw error

        }
    },

    async fetchUser({ rootGetters, dispatch }, userId) {
        try {

            let id = typeof userId !== 'undefined' ? userId : rootGetters['getAuthUser']['_jv'].id

            let user = {}

            user = await dispatch('tyltoApi/get', 'users/' + id, { root: true })

            return user

        } catch (error) {

            throw error
        }
    },

    async fetchAndFillUser({ dispatch }, userId) {
        try {

            let user = await dispatch('fetchUser', userId)

            await dispatch('fillUser', user)

            return user

        } catch (error) {

            throw error
        }
    },

    async updateUser({ dispatch, getters, rootGetters }) {
        try {

            const user = await dispatch('getFormatRequest')

            const id = getters['getId'] !== '' ? getters['getId'] : rootGetters['getAuthUser']['_jv'].id

            return await dispatch('tyltoApi/patch', [user, { url: `users/${id}` }], { root: true })

        } catch (error) {

            throw error
        }
    },

    progressFilter({}, items) {

        let itemsFilter = []

        items.forEach(item => {
            if (typeof item == 'string' && item !== '') {
                itemsFilter.push(item)
            }
            else if (item == null) {
                itemsFilter.push(item)
            }
            else if (typeof item == 'number' && item !== 0) {
                itemsFilter.push(item)
            }
            else if (typeof item == 'boolean' && item === true) {
                itemsFilter.push(item)
            }
            else if (typeof item == 'object' && Object.keys(item).length > 0) {
                itemsFilter.push(item)
            }
            else if (typeof item == 'array' && item.length > 0) {
                itemsFilter.push(item)
            }
        })

        return Math.round(itemsFilter.length * 100 / items.length)
    },

    async CreateOnboardingProgress({ getters, commit, rootGetters }) {
        try {

            const onboarding = await this.$axios({method: 'get', url: `/fapi/onboardings/${rootGetters.getAuthUser['_jv'].id}`})

            commit('setOnboardingProgress', onboarding.data.progression)

        } catch (error) {

            if (typeof error.response !== 'undefined' && error.response.status === 404 && error.response.data.message === 'Onboarding not found') {
                try {

                    let onboardingData = {}

                    onboardingData = {
                        user_id: rootGetters.getAuthUser['_jv'].id,
                        progression: 0
                    }

                    await this.$axios({ method: 'post', url: `/fapi/onboardings`, data: onboardingData })

                } catch (error) {

                    throw error
                }
            }
        }
    },

    async fetchPropertyManagerCompanyRelationship({ dispatch, getters, commit }, userId) {
        const id = getters['getId'] !== '' ? getters['getId'] : userId
        const { data } = await this.$axios({ method: 'get', url: `users/${id}/relationships/property_management_companies` })
        const propertyManagementCompany = await dispatch('property-manager/fetchPropertyManagementCompany', data['data'][0]['id'], { root: true })

        commit('setPropertyManagementCompany', propertyManagementCompany)
        return propertyManagementCompany
    },

    async fillUser({ commit }, user) {
        commit('resetDefaultState')

        if (typeof user.created_at !== 'undefined')
            commit('setCreatedAt', user.created_at)

        if (typeof user.email_address !== 'undefined')
            commit('setEmailAddress', user.email_address)

        if (typeof user.email_verified !== 'undefined')
            commit('setEmailVerified', user.email_verified)

        if (typeof user.full_name !== 'undefined')
            commit('setFullName', user.full_name)

        if (typeof user.property_management_companies !== 'undefined' && typeof Object.values(user.property_management_companies)[0] !== 'undefined')
            commit('setPropertyManagementCompany', Object.values(user.property_management_companies)[0])

        if (typeof user['_jv'] !== 'undefined')
            commit('setId', user['_jv'].id)

        if (typeof user['role'] !== 'undefined')
            commit('setRole', user.role + 's' )

        if (typeof user['_jv']['meta']['swan_user'] !== 'undefined')
            commit('setSwanUser', user['_jv']['meta']['swan_user'])

        if (typeof user['_jv']['meta']['last_login_at'] !== 'undefined')
            commit('setLastLogin', user['_jv']['meta']['last_login_at'])
    },

    async getFormatRequest({ getters, rootGetters }) {
        let user = {
            email_address: getters['getEmailAddress'],
            redirect_url: `${window.location.origin}/dashboard`,
            _jv: {
                type: 'users'
            }
        }

        if (getters['getPassword'] !== '') {
            user.password = getters['getPassword']
        }

        if (getters['getFullName'] !== '') {
            user.full_name = getters['getFullName']
        }

        if (getters['getRole'] !== '') {
            user['role'] = getters['getRole']
        }

        if (getters['getId'] !== '') {

            user['_jv'].id = getters['getId'] !== '' ? getters['getId'] : rootGetters['getAuthUser']['_jv'].id
        }

        return user
    },

    async resetPassword({ getters }) {
        try {

            let request = {
                email_address: getters['getEmailAddress'],
                redirect_url: `${window.location.origin}/${getters['getPasswordResetRedirectUrl']}`
            }

            await this.$axios({method: 'get', url: '/sanctum/csrf-cookie'})

            return await this.$axios({ method: 'post', url: `forgot-password`, data: request })

        } catch (error) {

            throw error
        }
    },

    async renewPassword({ getters }, form) {
        try {

            let request = {
                email_address: form.email,
                password: getters['getPassword'],
                password_confirmation: getters['getPassword'],
                token: form.token,
            }

            await this.$axios({method: 'get', url: '/sanctum/csrf-cookie'})

            return await this.$axios({ method: 'post', url: `reset-password`, data: request })

        } catch (error) {

            throw error
        }
    }
}
