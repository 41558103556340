<template>
    
    <nav class="landlord-navigation-bar">

        <ul class="smart-list">
            
            <li class="smart-item logo"> 

                <logo :redirectPath="`/dashboard/landlord/${landlordId}`" /> 

            </li>

            <li class="smart-item user-settings">
                
                <customer-settings-drop-down />

            </li>

        </ul>

        <ul class="mobile-smart-list">

            <li class="smart-item"> 

                <burger-menu />

            </li>
            
            <li class="smart-item logo"> 

                <logo :redirectPath="`/dashboard/landlord/${landlordId}`" /> 

            </li>

            <li class="smart-item" @click="authToggleMenu">

                <svg-icon :iconStrokeColor="'#FFFFFF'" width="28" height="28" :viewbox="'0 0 17 18'"><profile-auth-icon /></svg-icon>

            </li>

        </ul>

        <customer-auth-menu ref="customAuthMenu" @closeMenu="authToggleMenu"/>

    </nav>

</template>

<script>
    import Logo from '~/components/logo'
    import CustomerSettingsDropDown from '~/components/navigation/customer-settings-drop-down'
    import PropertyNatureShorthandMixin from '~/components/mixins/property-nature-shorthand'
    import BurgerMenu from '~/components/navigation/header/burger-menu'
    import CustomerAuthMenu from '~/components/navigation/customer-auth-menu'
    import SvgIcon from '~/components/svg-icon'
    import ProfileAuthIcon from '~/components/icons/profile/profile-auth-icon'

    import { mapGetters } from 'vuex'
    
    export default {
        mixins: [PropertyNatureShorthandMixin],
        
        components: { 
            Logo,
            CustomerSettingsDropDown,
            BurgerMenu,
            CustomerAuthMenu,
            ProfileAuthIcon,
            SvgIcon
        },

        data() {
            return {
                authMenuIsOpen: false
            }
        },

        methods: {
            
            authToggleMenu() {
                this.authMenuIsOpen = !this.authMenuIsOpen

                return this.authMenuIsOpen ? this.$refs.customAuthMenu.openMenu() : this.$refs.customAuthMenu.closeMenu()
            }
        },

        computed: {
            ...mapGetters({
                landlordId: 'profile/getId',
            })
        }
    }
</script>

<style lang="scss">
    $white: white;
    $header-height: 68px;
    $border-color: #EFEFEF;

    .landlord-navigation-bar {
        position: relative;
        min-height: $header-height;
        display: flex;
        flex-direction: column;
        box-shadow: 0 2px 8px 0 rgba(0,0,0,.23);
        z-index: 9;

        .smart-list {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex: 1;
            background: $white;
            color: #393939;

            @include tablet { 
                display: none;
            }


            .smart-item {
                display: flex;
                align-items: center;
                padding: 10px 30px;

                &.logo {
                    flex-basis: 70px;
                }

                &.property-switch {
                    border-left: 2px solid $border-color;
                    flex-basis: 100%;
                    flex-grow: 1;
                    padding: 0;

                    .drop-down{
                        width: 335px;
                    }
                }
            }
        }

        .mobile-smart-list {
            display: none;
            flex-direction: row;
            flex: 1;
            background: $white;
            color: #393939;

            @include tablet { 
                display: flex;
            }         
            
            .smart-item {
                display: flex;
                align-items: center;
                padding: 10px 30px;

                &.logo {
                    padding: 0;
                    flex: 3;
                }
            }
        }
    }
</style>
