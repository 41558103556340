import Vue from 'vue'
import StepForm from '~/components/workflow/step-form'
import StepField from '~/components/workflow/step-field'
import StepGroup from '~/components/workflow/step-group'
import StepNestedForm from '~/components/workflow/step-nested-form'
import FormInfo from '~/components/form-info'

const components = {
    StepForm,
    StepField, 
    StepGroup,
    StepNestedForm,
    FormInfo
}

Object.entries(components).forEach(([name, component]) => {
        
    Vue.component(name, component)
})