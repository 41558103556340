import defaultState from './default'

export default {
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },

    addItem(state, { type, count }) {

        let copy = [...state.items]

        const index = copy.map(item => item.type).indexOf(type)

        if (index >= 0) {
            if (count > 1) copy.splice(index, 1, {
                type: type,
                count: count
            })

            else copy.splice(index, 1)
        }

        else copy.push({
            type: type,
            count: count
        })

        state.items = copy
    },

    addItemHeater(state, { type, count }) {

        let copy = [...state.items]

        copy = copy.filter(item => !(item.type.includes('heater') && !item.type.includes('water_heater')))

        copy.push({
            type: type,
            count: count
        })

        state.items = copy
    },

    addItemWaterHeater(state, { type, count }) {

        let copy = [...state.items]

        copy = copy.filter(item => !item.type.includes('water_heater'));

        copy.push({
            type: type,
            count: count
        })

        state.items = copy
    },

    setAddress(state, value) {
        state.address = value
    },

    setReference(state, value) {
        state.reference = value
    },

    setArea(state, value) {
        state.area = value
    },

    setAreaTension(state, value) {
        state.area_tension = value
    },

    setBuildingOwnershipType(state, value) {
        state.building_ownership_type = value
    },

    setConstructionDate(state, value) {
        state.construction_date = value
    },

    setDescription(state, value) {
        state.description = value
    },

    setDwellingType(state, value) {
        state.dwelling_type = value
    },

    setCaseNumber(state, value) {
        state.case_number = value
    },

    setFiscalReferenceNumber(state, value) {
        state.fiscal_reference_number = value;
    },

    setIsFurnished(state, value) {
        state.is_furnished = value
    },

    setIsInTenseArea(state, value) {
        state.area_tension = value ? 1 : 0
    },

    setIsInVeryTenseArea(state, value) {
        state.area_tension = value ? 2 : 0
    },

    setId(state, value) {
        state.id = value
    },

    setItems(state, value) {
        state.items = value
    },


    setSettlementType(state, value) {
        state.settlement_type = value
    },

    setUserPrivileges(state, value) {
        state.user_privileges = value
    },

    setDpeConsumption(state, value) {
        state.dpe.consumption = value
    },

    setDpeEmission(state, value) {
        state.dpe.emission = value
    },

    setDpeLetter(state, value) {
        state.dpe.letter = value
    },

    setDpeDeliveryDate(state, value) {
        state.dpe.delivery_date = value
    },

    setDpeExpirationDate(state, value) {
        state.dpe.expiration_date = value
    },

    setRelatedAppliedFees(state, value) {
        state.relationships.applied_fees = value
    },

    setRelatedAccountingAccount(state, value) {
        state.relationships.accounting_account = value
    },

    setRelatedDiagnoses(state, value) {
        state.relationships.diagnoses = value
    },

    setRelatedFees(state, value) {
        state.relationships.fees = value
    },

    setRelatedLandlord(state, value) {
        state.relationships.landlord = value
    },

    setRelatedRentals(state, value) {
        state.relationships.rentals = value
    },

    setRelatedMandates(state, value) {
        state.relationships.mandates = value
    },

    setRelatedTenants(state, value) {
        state.relationships.tenants = value
    },

    setTypeDeProductionChauffage(state, value) {
        state.type_de_production_chauffage = value
    },

    setTypeDeProductionEauChaude(state, value) {
        state.type_de_production_eau_chaude = value
    },
}
