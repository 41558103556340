<template>

    <div class="clipboard-text-table-cell">

        <label class="label">{{ value }}</label>

        <svg-icon width="15" height="15" viewBox="0 0 24 24" fill="none" @click.native="copy"><clipboard-icon/></svg-icon>

    </div>

</template>

<script>
    import SvgIcon from '~/components/svg-icon'
    import ClipboardIcon from '~/components/icons/input-icons/clipboard-icon.vue'

    export default {
        components: {
            SvgIcon,
            ClipboardIcon
        },

        props: {
            value: {
                type: String,
                required: true
            }
        },

        methods: {
            copy() {
                navigator.clipboard.writeText(this.value)

                this.$toast.success(this.$t('copied'), { icon: 'check' })
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "copied": "Copié"
        }
    }
</i18n>

<style lang="scss" scoped>
    .clipboard-text-table-cell {
        display: flex;
        flex-direction: row;
        align-items: center;

        .label {
            margin-right: 5px;
            word-break: break-word;
        }

        svg {
            flex: none;
        }
    }
</style>
