import { loadStripe } from '@stripe/stripe-js'

class StripeJs {
    constructor(Stripe) {
        this.client = () => Stripe
    }

    async createSepaDebitPaymentMethod(sepaDebit, billingDetails) {
        let createPaymentMethod = this.client().createPaymentMethod

        let paymentMethodData = {
            type: 'sepa_debit',
            sepa_debit: sepaDebit,
            billing_details: {
                name: billingDetails.name,
                email: billingDetails.email
            }
        }

        const {paymentMethod, error} = await createPaymentMethod(paymentMethodData)

        if (typeof paymentMethod !== 'undefined') return paymentMethod

        else throw error.message
    }

    async createCardPaymentMethod(card) {
        let createPaymentMethod = this.client().createPaymentMethod

        let paymentMethodData = {
            type: 'card',
            card: card
        }

        const {paymentMethod, error} = await createPaymentMethod(paymentMethodData)

        if (typeof paymentMethod !== 'undefined') return paymentMethod

        else throw error.message
    }

    createElement(type, style) {
        const elements = this.client().elements({
            fonts: [{
                cssSrc: 'https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap'
            }]
        })

        return elements.create(type, {
            supportedCountries: ['SEPA'],
            style: style
        })
    }
}

export default async function({ $config }, inject) {

    if ($config.stripeEnabled) {

        const StripeClient = await loadStripe($config.stripePublishableKey)

        const StripeApi = new StripeJs(StripeClient)

        inject('stripeJs', StripeApi)

    }
}
