import defaultState from './default'

export default {
    setCreatedAt(state, value) {
        state.created_at = value
    },

    setEmailAddress(state, value) {
        state.email_address = value
    },

    setEntity(state, value) {
        state.entity = value
    },

    setFullName(state, value) {
        state.full_name = value
    },

    setId(state, value) {
        state.id = value
    },

    setLegalPersons(state, value) {
        state.legal_persons = value
    },

    setPaymentMethod(state, value) {
        state.payment_method = value
    },

    setPhoneNumber(state, value) {
        state.phone_number = value
    },

    setPayoutDelay(state, value) {
        state.payout_delay = value
    },

    setMatrimonialStatus(state, value) {
        state.matrimonial_status = value
    },

    setRelatedAccountingAccount(state, value) {
        state.relationships.accounting_account = value
    },

    setRelatedAppliedFees(state, value) {
        state.relationships.applied_fees = value
    },

    setRelatedDocuments(state, value) {
        state.relationships.documents = value
    },

    setRelatedFees(state, value) {
        state.relationships.fees = value
    },

    setRelatedGuarantors(state, value) {
        state.relationships.guarantors = value
    },

    setRelatedInvitations(state, value) {
        state.relationships.invitations = value
    },

    setRelatedDefaultPayoutBankAccount(state, value) {
        state.relationships.default_payout_bank_account = value
    },

    setRelatedPayoutBankAccounts(state, value) {
        state.relationships.payout_bank_accounts = value
    },

    setRelatedPaymentMethods(state, value) {
        state.relationships.payment_methods = value
    },

    setRelatedProperties(state, value) {
        state.relationships.properties = value
    },

    setRelatedRentals(state, value) {
        state.relationships.rentals = value
    },

    setRelatedPropertyManager(state, value) {
        state.relationships.property_management_company = value
    },

    setRole(state, value) {
        state.role = value
    },
    
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    }
}
