export default {
    getAmountInPercent(state) {
        return state.amount_in_percent
    },

    getAmountInCurrency(state) {
        return state.amount_in_currency
    },

    getId(state) {
        return state.id
    },

    getType(state) {
        return state.type
    },

    getAppliesTo(state) {
        return state.applies_to
    },

    getRelatedLevel(state) {
        return state.relationships.level
    }
}
