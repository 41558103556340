export default {
    async createDumpState({ rootState, getters, rootGetters }) {
        try {
            let deepCopy = {
                cost: {...rootState['cost']},
                fees: {...rootState['fees']},
                file: {...rootState['file']},
                inventory: {...rootState['inventory']},
                mandate: {...rootState['mandate']},
                profile: {...rootState['profile']},
                property: {...rootState['property']},
                rent: {...rootState['rent']},
                rental: {...rootState['rental']}
            }

            await this.$axios({ method: 'post', url: `/fapi/vuex-store-dumps`, data: {
                user_id: rootGetters['getAuthUser']['_jv'].id,
                dump_name: `oskar-dump-${getters['getDumpName']}`,
                dump_type: getters['getDumpName'],
                dump: JSON.stringify(deepCopy),
            } })
        
        } catch (error) {
        
            throw error
        }
    },

    async deleteDumpState({ getters }, dumpId) {
        try {

            return await this.$axios({ method: 'delete', url: `/fapi/vuex-store-dumps/${dumpId}` })

        } catch (error) {

            throw error
        }
    },

    async fetchDumpState({ getters, rootGetters }, dumpId) {
        try {

            const { data } = await this.$axios({ method: 'get', url: `/fapi/vuex-store-dumps/${dumpId}` })

            return JSON.parse(data.dump)

        } catch (error) {

            throw error
        }
    },

    async fetchDumpStatesFilteredByName({ rootGetters }, dumpName) {
        try {

            const { data } = await this.$axios({ method: 'get', url: `/fapi/users/${rootGetters['getAuthUser']['_jv'].id}/vuex-store-dumps/` })

            return data.filter(dump => dump.dump_name === dumpName)

        } catch (error) {

            throw error
        }
    },
    
    async nextStep({ commit, getters }, code) {

        commit('setCompletedStep', code)

        let stepIndex = getters['getSteps'].map(step => step.name).indexOf(code)

        if (stepIndex === getters['getSteps'].length - 1) return commit('setActiveStep', '')
        
        commit('setActiveStep', getters['getSteps'][stepIndex + 1].name)
    },

    async previousStep({ commit, getters }, code) {
        
        let stepIndex = getters['getSteps'].map(step => step.name).indexOf(code)

        if (stepIndex === 0) return commit('setActiveStep', '')
        
        return commit('setActiveStep', getters['getSteps'][stepIndex - 1].name)
    },

    async resetInitialValues({ commit, getters }) {
        for (let field of getters['getFields']) {
            commit(field.setter, field.initialValue, { root: true })
        }
    },

    async restoreDump({ dispatch, rootState }, dumpId) {
        try {
            
            let dump = await dispatch('fetchDumpState', dumpId)

            await this.replaceState(Object.assign(rootState, dump))

            await dispatch('deleteDumpState', dumpId)
        
        } catch (error) {

            throw error
        }
    },

    async saveAction({ commit, dispatch, getters }) {
        try {

            commit('setLoading', true)
                        
            return await getters['getSaveAction'].call()

        } catch(error) {

            if (typeof error.response !== 'undefined' && typeof error.response.status !== 'undefined') {
                
                commit('setErrorStatusCode', parseInt(error.response && error.response.status))

                if (typeof error.response.data !== 'undefined' && typeof error.response.data.errors !== 'undefined') {
                    
                    commit('setErrorFields', error.response.data.errors)

                    commit('setErrorMessage', error.response.data.errors.map(error => error.detail).join('. '))
                }
            }

            throw error

        } finally {

            commit('setLoading', false)
        }
    },

    async redirectAction({ getters }) {
        
        return await getters['getRedirectAction'].call()
    },

    async switchStep({ commit, getters }, code) {
        
        let stepList = getters['getSteps'].map(step => step.name)

        if (!getters['isCreateMode'] // If is update mode or edit mode
            || (stepList.includes(code) &&  stepList.indexOf(code) <= stepList.indexOf(getters['getActiveStep'])) // If is step before active step
            || (stepList.includes(code) && getters['getSteps'].find(step => step.name === code).complete)) // If is step is completed
            { 
            
            commit('setUncompletedStep', code)
    
            commit('setActiveStep', code)
        }  
    },

    async uploadExternalFile({ getters, commit, dispatch }) {
        try {

            commit('file/setFile', getters['getExternalFileFormData'], { root: true })

            commit('file/setTypes', getters['getExternalFileTypes'], { root: true })

            commit('file/setIsSigned', getters['getExternalFileIsSigned'], { root: true })

            const file = await dispatch('file/createFile', null, { root: true })

            commit('setExternalFile', file)

            commit('setExternalFileFormData', '')

            return file

        } catch(error) {

            throw error
        
        } finally {

            commit('file/resetDefaultState', null, { root: true })
        }
    },

    async validateAllSteps({ dispatch, getters }) {
        
        return getters['getSteps'].forEach(step => dispatch('nextStep', step.name))
    }
}