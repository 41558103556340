<template>    

    <form-group class="date-period-input" :name="name" ref="formGroup" v-click-outside="closeDatePicker">

        <step-group class="date-period-group" :name="label" :customStyle="customStyle">

            <date-input class="date-input" :label="$t('start_date')" :name="'start_date'" v-model="dateStartValue" :rules="computedRules" :options="Object.assign(options, { disableDatePicker: true })" @focus="openDatePicker" />
            
            <date-input class="date-input" :label="$t('end_date')" :name="'end_date'" v-model="dateEndValue" :rules="computedRules" :options="Object.assign(options, { disableDatePicker: true })" @focus="openDatePicker" />

            <FunctionalCalendar :class="['functional-calendar', options.datePickerOpen ]" v-model="calendarData" @input="setDateValue" :configs="calendarConfigs" v-show="datePickerOpen">

                <template v-slot:footer>

                    <div class="vfc-footer-date-period-container">

                        <div :class="['vfc-footer-button', { 'active': customDateIndex === 1 }]" @click="selectPersonalizeDate"> Personalisé </div>

                        <div :class="['vfc-footer-button', { 'active': customDateIndex === 2 }]" @click="selectLastMonth"> 30 derniers jours </div>

                        <div :class="['vfc-footer-button', { 'active': customDateIndex === 3 }]" @click="selectThreeLastMonth"> 3 derniers mois </div>

                        <div :class="['vfc-footer-button', { 'active': customDateIndex === 4 }]" @click="selectSixLastMonth"> 6 derniers mois </div>
                        
                        <div :class="['vfc-footer-button', { 'active': customDateIndex === 5 }]" @click="selectLastYear"> Cette année </div>

                    </div>

                </template>

            </FunctionalCalendar>
        
        </step-group>

    </form-group>

</template>

<script>
    import '~/assets/scss/functional-calendar.scss'
    import FormGroup from '~/components/inputs/form-group'

    export default {
        components: {
            FormGroup
        },

        data() {
            return {
                calendarData: {},
                dateStartValue: '',
                dateEndValue: '',
                customDateIndex: 1,
                datePickerOpen: false
            }
        },
        
        props: {
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            value: {
                type: String,
                required: true
            },
            rules: {
                type: [String, Object],
                default: ''
            },
            options: {
                type: Object,
                default() {
                    return {}
                }
            }
        },

        methods: {
            openDatePicker() {

                this.datePickerOpen = true
            },

            closeDatePicker() {

                this.datePickerOpen = false
            },

            setDateValue(calendarData) {
                
                if (!Object.keys(calendarData).length || typeof calendarData.dateRange === 'undefined') this.dateStartValue = this.dateEndValue = ''

                this.dateStartValue = calendarData.dateRange.start !== '' ? this.$moment(calendarData.dateRange.start, "DD-MM-YYYY").format('YYYY-MM-DD'): ''
                
                this.dateEndValue = calendarData.dateRange.end !== '' ? this.$moment(calendarData.dateRange.end, "DD-MM-YYYY").format('YYYY-MM-DD') : ''
            },

            selectPersonalizeDate() {
                this.customDateIndex = 1

                this.dateStartValue = this.dateEndValue = ''
            },

            selectLastMonth() {
                this.customDateIndex = 2

                this.dateStartValue = this.$moment().subtract(1, "month").format('YYYY-MM-DD')

                this.dateEndValue = this.$moment().format('YYYY-MM-DD')
            },

            selectThreeLastMonth() {
                this.customDateIndex = 3

                this.dateStartValue = this.$moment().subtract(3, "month").format('YYYY-MM-DD')

                this.dateEndValue = this.$moment().format('YYYY-MM-DD')
            },

            selectSixLastMonth() {
                this.customDateIndex = 4

                this.dateStartValue = this.$moment().subtract(6, "month").format('YYYY-MM-DD')

                this.dateEndValue = this.$moment().format('YYYY-MM-DD')
            },

            selectLastYear() {
                this.customDateIndex = 5

                this.dateStartValue = this.$moment().startOf('year').format('YYYY-MM-DD')

                this.dateEndValue = this.$moment().format('YYYY-MM-DD')
            }
        },

        computed: {
            calendarConfigs() {
                return {
                    dateFormat: 'dd/mm/yyyy',
                    isSeparately: true,
                    isMultiple: true,
                    calendarsCount: '2',
                    isDateRange: true,
                    changeMonthFunction: true,
                    changeYearFunction: true
                }
            },

            customStyle() {
                return this.options.customStyle
            },

            computedRules() {
                let str = 'date_is_valid'
                
                if (this.rules.length) str += `|${this.rules}`

                return str
            },

            periodFormatValue() {
                if (this.dateStartValue !== '' && this.dateEndValue !== '') {
                    
                    return `${this.$moment(this.dateStartValue, 'YYYY-MM-DD').format('YYYY-MM-DD')}/${this.$moment(this.dateEndValue, 'YYYY-MM-DD').format('YYYY-MM-DD')}`
                }

                return ''
            }
        },

        watch: {
            periodFormatValue: {
                immediate: true,
                handler(newValue, oldValue) {
                    if (newValue !== oldValue) {
                        
                        this.$emit('input', newValue)

                        this.closeDatePicker()
                    }
                }
            },

            value: {
                immediate: true,
                handler(newValue) {
                    if (newValue.length) {
                    
                        let periodArray = newValue.split('/')

                        this.dateStartValue = periodArray[0]

                        this.dateEndValue = periodArray[1]
                    }
                }
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "start_date": "Date de début",
            "end_date": "Date de fin"
        }
    }
</i18n>

<style lang="scss" scoped>
    .date-period-input {
        align-items: initial;

        .date-input {
            align-items: initial;
        }

        .functional-calendar.left {
            right: 0;
        }
    }
</style>