import defaultState from './default'

export default {
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },

    resetDefaultFieldsState(state) {
        let initialState = defaultState()

        Object.assign(state, initialState.lease_agreement_fields)
    },

    addItem(state, { type, amount, vat_rate }) {
        let copy = [...state.items]

        const index = copy.map(item => item.type).indexOf(type)

        if (index >= 0) {

            if (amount > 0) copy.splice(index, 1, {
                type: type,
                amount: amount,
                vat_rate: vat_rate,
            })

            else copy.splice(index, 1)

        } else if (amount > 0) copy.push({
            type: type,
            amount: amount,
            vat_rate: vat_rate,
        });


        state.items = [...copy]
    },

    setLeaseAgreementFieldsOtherSpecificClauses(state, value) {
        state.lease_agreement_fields.other_specific_clauses = value
    },

    setLeaseAgreementFieldsRoomatesCommonAreasDescription(state, value) {
        state.lease_agreement_fields.roomates_common_areas_description = value
    },

    setLeaseAgreementFieldsIsFurnished(state, value) {
        state.lease_agreement_fields.is_furnished = value
    },

    setLeaseAgreementFieldsTenantPrivateAreasDescription(state, value) {
        state.lease_agreement_fields.tenant_private_areas_description = value
    },

    setLeaseAgreementFieldsPremisesUse(state, value) {
        state.lease_agreement_fields.premises_use = value
    },

    setLeaseAgreementFieldsShorterTermReason(state, value) {
        state.lease_agreement_fields.shorter_term_reason = value
    },

    setLeaseAgreementFieldsRentSupplementReason(state, value) {
        state.lease_agreement_fields.rent_supplement_reason = value
    },

    setLeaseAgreementFieldsLastRentAdjustmentDate(state, value) {
        state.lease_agreement_fields.last_rent.adjustment_date = value
    },

    setLeaseAgreementFieldsLastRentAmount(state, value) {
        state.lease_agreement_fields.last_rent.amount = value
    },

    setLeaseAgreementFieldsLastRentPaymentDate(state, value) {
        state.lease_agreement_fields.last_rent.payment_date = value
    },

    setRelatedFeesHomeInsurance(state, value) {
        state.lease_agreement_fields.home_insurance = value
    },

    setLeaseAgreementFieldsReferenceRentAmount(state, value) {
        state.lease_agreement_fields.reference_rent_amount = value
    },

    setLeaseAgreementFieldsIncreasedReferenceRentAmount(state, value) {
        state.lease_agreement_fields.increased_reference_rent_amount = value
    },

    setLeaseAgreementFieldsMobilityLeaseJustification(state, value) {
        state.lease_agreement_fields.mobility_lease_justification = value
    },

    setLeaseAgreementFieldsImprovementOrComplianceWorkAmount(state, value) {
        state.lease_agreement_fields.improvement_or_compliance_work.amount = value
    },

    setLeaseAgreementFieldsImprovementOrComplianceWorkDescription(state, value) {
        state.lease_agreement_fields.improvement_or_compliance_work.description = value
    },

    setLeaseAgreementFieldsSharingType(state, value) {
        state.lease_agreement_fields.sharing_type = value
    },

    setLeaseAgreementFieldsType(state, value) {
        state.lease_agreement_fields.type = value
    },

    setId(state, value) {
        state.id = value
    },

    setItems(state, value) {
        state.items = value
    },

    setPastRents(state, value) {
        state.past_rents = value
    },

    setRentAdjustmentDate(state, value) {
        state.rent_adjustment_date = value
    },

    setRentReferenceIndexReferenceQuarter(state, value) {
        state.rent_reference_index_reference_quarter = value
    },

    setRentPaymentDueDate(state, value) {
        state.rent_payment_due_date = value
    },

    setStartDate(state, value) {
        state.start_date = value
    },

    setCreateFeesLandlordsShare(state, value) {
        state.create_fees_landlord_share = value
    },

    setCreateFeesTenantsShare(state, value) {
        state.create_fees_tenant_share = value
    },

    setEndDate(state, value) {
        state.end_date = value
    },

    setStatus(state, value) {
        state.status = value
    },

    setSecurityDepositCollectionDate(state, value) {
        state.security_deposit.collection_date = value
    },

    setSecurityDepositIsHold(state, value) {
        state.security_deposit.is_hold = value
    },

    setSecurityDepositAmount(state, value) {
        state.security_deposit.amount = value
    },

    setSecurityDepositIsAlreadyPaid(state, value) {
        state.security_deposit.is_already_paid = value
    },

    setTerm(state, value) {
        state.term = value
    },

    setRelatedLeaseAgreementAnnexes(state, value) {
        state.relationships.lease_agreement_annexes = value
    },

    setRelatedAppliedFees(state, value) {
        state.relationships.applied_fees = value
    },

    setRelatedLeaseAgreement(state, value) {
        state.relationships.lease_agreement = value
    },

    setRelatedFees(state, value) {
        state.relationships.fees = value
    },

    setRelatedInventories(state, value) {
        state.relationships.inventories = value
    },

    setRelatedProperty(state, value) {
        state.relationships.property = value
    },

    setRelatedTenants(state, value) {
        state.relationships.tenants = value
    },

    setRelatedPaymentMethod(state, value) {
        state.relationships.payment_method = value
    },

    setRelatedUpcomingRents(state, value) {
        state.relationships.upcoming_rents = value
    },

    setRelatedSecurityDeposit(state, value) {
        state.relationships.security_deposit = value
    },

    setHasVatRate(state, value) {
        state.hasVatRate = value
    },

    setLeaseType(state, value) {
        state.lease_type = value;
    },

    setIsFurnished(state, value) {
        state.is_furnished = value;
    },

    setIsShared(state, value) {
        state.is_shared = value;
    }

}
