<template>

    <div class="management_and_ownership">

        <input :value="value" v-show="false">

        <resource-minified class="person" v-for="(person, key) in value" :key="key" :title="person.first_name + ' ' + person.last_name" :details="person.address ? person.address.formatted : $t('no_manager_and_owneraddress')" :action="deleteItem(key)" />

        <p v-if="!editMode && !value.length"> {{ $t('no_manager_and_owner') }} </p>

        <hr class="separator" v-if="editMode && value.length">
        
        <step-nested-form class="create-or-update" :title="$t('create_manager_or_owner')" :itemCount="value.length" :onSubmit="createAndSetManagementAndOwnership" @close="resetForm" :fields="{ managerAndOwnerFirstName, managerAndOwnerLastName, managerAndOwnerEmailAddress, managerAndOwnerPhoneNumber, managerAndOwnerBirthDate, managerAndOwnerAddress, managerAndOwnerPersonalIdDocumentFront, managerAndOwnerPersonalIdDocumentBack, managerAndOwnerAdditionnalDocument }" :open="editMode">

            <template v-slot="{ fields }">
        
                <text-input class="step-field" name="first_name" :label="$t('first_name')" :value="fields.managerAndOwnerFirstName" @input="managerAndOwnerFirstName = $event" :placeholder="$t('first_name_placeholder')" :rules="'required'" />

                <text-input class="step-field" name="last_name" :label="$t('last_name')" :value="fields.managerAndOwnerLastName" @input="managerAndOwnerLastName = $event" :placeholder="$t('last_name_placeholder')" :rules="'required'" />

                <text-input class="step-field fullsize" name="email_address" :label="$t('email_address')" :value="fields.managerAndOwnerEmailAddress" @input="managerAndOwnerEmailAddress = $event" :placeholder="$t('email_address_placeholder')" :rules="{ required: true, email: { require_tld: false }}" />

                <phone-number-input class="step-field fullsize" name="phone_number" :label="$t('phone_number')" :value="fields.managerAndOwnerPhoneNumber" @input="managerAndOwnerPhoneNumber = $event" v-if="managerAndOwnerIsRepresentative" :rules="'required'" />
                
                <date-input class="step-field" name="birth_date" :label="$t('birth_date')" :value="fields.managerAndOwnerBirthDate" @input="managerAndOwnerBirthDate = $event" :rules="`required|date_before:${thirteenYearsBeforeToday}`" />

                <address-input class="step-field address-autocomplete" name="address" :label="$t('address')" :value="fields.managerAndOwnerAddress" @input="managerAndOwnerAddress = $event" :placeholder="$t('address_placeholder')" :rules="'required|not_empty'" />

                <file-upload-input class="step-field" name="personal_id_document_front" :label="$t('personal_id_document_front')" :value="fields.managerAndOwnerPersonalIdDocumentFront" @input="managerAndOwnerPersonalIdDocumentFront = $event" :image="true" :rules="'required'" />

                <file-upload-input class="step-field" name="personal_id_document_back" :label="$t('personal_id_document_back')" :value="fields.managerAndOwnerPersonalIdDocumentBack" @input="managerAndOwnerPersonalIdDocumentBack = $event" :image="true" :max_upload="10" />

                <file-upload-input class="step-field" name="additionnal_document" :label="$t('additionnal_document')" :value="fields.managerAndOwnerAdditionnalDocument" @input="managerAndOwnerAdditionnalDocument = $event" :max_upload="10" :extensions="['jpeg','jpg','png','pdf']" :rules="'required'" />

            </template>
    
        </step-nested-form>

        <button-input class="background-transparent green-text-color send-button" :text="$t('create_manager_or_owner')" iconImg="add_new" @click="editMode = true" v-if="!editMode" />

    </div>

</template>

<script>
    import AddressInput from '~/components/inputs-group/address-input'
    import FullNameMixin from '~/components/mixins/full-name'
    import ResourceMinified from '~/components/resource-minified'
    import { mapGetters } from 'vuex'

    
    export default {
        mixins: [FullNameMixin],

        components: {
            AddressInput,
            ResourceMinified
        },

        data() {
            return {
                thirteenYearsBeforeToday: this.$moment().subtract(13, 'years').format('DD/MM/YYYY'),
                editMode: false
            }
        },

        props: {
            value: {
                type: Array,
                required: true
            },
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            rules: {
                type: String,
                required: false
            }
        },

        watch: {
            value: {
                immediate: true,
                handler(newValue) {
                    if (!newValue.length) {
                        this.managerAndOwnerIsRepresentative = true
                    }
                }
            }
        },

        methods: {
            async createAndSetManagementAndOwnership() {
                try {
                        
                    const managerAndOwnership = await this.$store.dispatch('management-and-ownership/createManagerAndOwnership')

                    let managementAndOwnershipCopy = [...this.value]
                    
                    managementAndOwnershipCopy.push(managerAndOwnership)

                    this.$emit('input', managementAndOwnershipCopy)

                    this.$scrollTo('body')
                
                } catch(error) {
                    
                    throw error
                }
            },

            deleteItem(key) {
                return {
                    title: this.$t('delete'),
                    function: () => {
                        let managementAndOwnershipCopy = [...this.value]

                        managementAndOwnershipCopy.splice(key, 1)

                        this.$emit('input', managementAndOwnershipCopy)
                    }
                }
            },

            resetForm(close) {
                
                this.$store.commit('management-and-ownership/resetDefaultState')
                
                if (close) this.editMode = false
            }
        },

        computed: {
            managerAndOwnerFirstName: {
                get() {
                    return this.$store.getters['management-and-ownership/getFirstName']
                },
                set(newValue) {
                    return this.$store.commit('management-and-ownership/setFirstName', newValue)
                }
            },

            managerAndOwnerLastName: {
                get() {
                    return this.$store.getters['management-and-ownership/getLastName']
                },
                set(newValue) {
                    return this.$store.commit('management-and-ownership/setLastName', newValue)
                }
            },

            managerAndOwnerEmailAddress: {
                get() {
                    return this.$store.getters['management-and-ownership/getEmailAddress']
                },
                set(newValue) {
                    return this.$store.commit('management-and-ownership/setEmailAddress', newValue)
                }
            },

            managerAndOwnerPhoneNumber: {
                get() {
                    return this.$store.getters['management-and-ownership/getPhoneNumber']
                },
                set(newValue) {
                    return this.$store.commit('management-and-ownership/setPhoneNumber', newValue)
                }
            },

            managerAndOwnerBirthDate: {
                get() {
                    return this.$store.getters['management-and-ownership/getBirthDate']
                },
                set(newValue) {
                    return this.$store.commit('management-and-ownership/setBirthDate', newValue)
                }
            },

            managerAndOwnerAddress: {
                get() {
                    return this.$store.getters['management-and-ownership/getAddress']
                },
                set(newValue) {
                    return this.$store.commit('management-and-ownership/setAddress', newValue)
                }
            },

            managerAndOwnerIsRepresentative: {
                get() {
                    return this.$store.getters['management-and-ownership/getIsRepresentative']
                },
                set(newValue) {
                    return this.$store.commit('management-and-ownership/setIsRepresentative', newValue)
                }
            },

            managerAndOwnerPersonalIdDocumentFront: {
                get() {
                    return this.$store.getters['management-and-ownership/getVerificationPersonalIdDocumentFront']
                },
                set(newValue) {
                    return this.$store.commit('management-and-ownership/setVerificationPersonalIdDocumentFront', newValue)
                }
            },

            managerAndOwnerPersonalIdDocumentBack: {
                get() {
                    return this.$store.getters['management-and-ownership/getVerificationPersonalIdDocumentBack']
                },
                set(newValue) {
                    return this.$store.commit('management-and-ownership/setVerificationPersonalIdDocumentBack', newValue)
                }
            },

            managerAndOwnerAdditionnalDocument: {
                get() {
                    return this.$store.getters['management-and-ownership/getVerificationAdditionnalDocument']
                },
                set(newValue) {
                    return this.$store.commit('management-and-ownership/setVerificationAdditionnalDocument', newValue)
                }
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "create_manager_or_owner": "Ajouter un autre représentant",
            "management_and_ownership_full_name": "{first_name} {last_name}",
            "personal_id_document_front": "Pièce d'identité (recto)",
            "personal_id_document_back": "Pièce d'identité (verso)",
            "additionnal_document": "Justificatif de domicile",
            "first_name": "Prénom",
            "first_name_placeholder": "Thierry",
            "last_name": "Nom",
            "last_name_placeholder": "Dupont",
            "email_address": "Adresse e-mail",
            "phone_number": "Numéro de téléphone",
            "email_address_placeholder": "thierry.dupont@email.com",
            "address": "Adresse",
            "address_placeholder": "5 rue de la mairie",
            "birth_date": "Date de naissance",
            "cancel": "Annuler la création",
            "reset_list": "Modifier",
            "save_new": "Terminer",
            "no_manager_and_owneraddress": "Adresse inconnue",
            "no_manager_and_owner": "Aucun.",
            "create": "Nouveau",
            "delete_list": "Modifier la liste",
            "delete": "Supprimer"
        }
    }
</i18n>

<style lang="scss">
    
    .management_and_ownership {
        .person {
            & + .new_management_and_ownership,
            & + .reset_management_and_ownership {
                margin-top: 25px;
            }

            & + .create-or-update {
                padding-top: 29px;
                border-top: 1px solid #F2F2F2;
                margin-top: 44px;
            }
        }

        .separator {
            margin: 27px auto;
            height: 1px;
            width: 72px;
            background: #DEE3EF;
            border-radius: 5px;
            border: none;
        }

        .button-group-section {
            display: flex;
            justify-content: space-between;
        }

        .send-button {
            margin-top: 40px;
            padding-left: 0;
        }

        .new_management_and_ownership,
        .reset_management_and_ownership {
            align-self: flex-start;
        }
    }
</style>