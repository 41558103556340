export default class {
    id = null;
    name = '';
    file = null;
    size = null;
    mime_type = null;
    url = null;

    setData(data) {
        this.id = data['id'];
        this.setAttributes(data['attributes']);
    }

    setAttributes(attributes) {
        this.name = attributes['original_name'];
        this.size = attributes['size'];
        this.mime_type = attributes['mime_type'];
        this.url = attributes['url'];
    }

    getAttributes() {
        return {
            id: this.id,
            file: this.file,
        };
    }
}
