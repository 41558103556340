export default () => {
    return {
        address: {},
        reference:'',
        area: 0,
        area_tension: 0,
        building_ownership_type: '',
        case_number: '',
        construction_date: '',
        description: '',
        dwelling_type: '',
        fiscal_reference_number: '',
        is_furnished: false,
        id: '',
        items: [],
        settlement_type: '',
        user_privileges: [],
        type_de_production_eau_chaude: '',
        type_de_production_chauffage: '',
        dpe: {
            consumption: null,
            emission: null,
            letter: '',
            delivery_date: null,
            expiration_date: null
        },
        relationships: {
            applied_fees: [],
            accounting_account: {},
            diagnoses: [],
            fees: [],
            landlord: {},
            past_rentals: [],
            rentals: [],
            mandates: [],
            tenants: []
        }
    }
}
