<template>
    
    <div class="dashboard-landlord-layout" v-if="isAuthLandlord">

        <landlord-navigation-bar :key="`landlord-navigation-${landlordId}`" />

        <dashboard-customer-header backgroundImage="background-landlord-header" :mainNav="dashbordLandlordMainNav"/>

        <error-banner :title="$t('error_message')" :details="$t('contact_support')" v-if="errorMessage" />

        <div class="dashboard-content">

            <nuxt-child/>

        </div>

    </div>

</template>

<script>
    import LandlordNavigationBar from '~/components/navigation/header/landlord-navigation-bar'
    import ErrorBanner from '~/components/error-banner'
    import DashboardCustomerHeader from '~/components/navigation/header/dashboard-customer-header'
    import { mapGetters } from 'vuex'
    
    export default {
        components: {
            LandlordNavigationBar,
            DashboardCustomerHeader,
            ErrorBanner,
        },

        data() {
            return {
                errorMessage: ''
            }
        },

        computed: {
            dashbordLandlordMainNav() {
                return [
                    {
                        name: this.$t('dashboard'),
                        path: '/dashboard/landlord/' + this.landlordId
                    },
                    {
                        name: this.$t('properties'),
                        path: '/dashboard/landlord/' + this.landlordId + '/properties'
                    },
                    {
                        name: this.$t('informations'),
                        path: '/dashboard/landlord/' + this.landlordId + '/informations'
                    },
                    {
                        name: this.$t('documents'),
                        path: '/dashboard/landlord/' + this.landlordId + '/documents'
                    }
                ]
            },

            ...mapGetters({
                landlordId: 'profile/getId',
                isAuthLandlord: 'isAuthLandlord',
                accountingAccount: 'profile/getRelatedAccountingAccount'
            })
        },

        errorCaptured(error) {
            
            if (!!error.response && !!error.response.status && parseInt(error.response.status) >= 500) {
                
                this.errorMessage = error
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "dashboard": "Accueil",
            "properties": "Mes biens",
            "documents": "Documents",
            "informations": "Informations",
            "error_message": "Une erreur est survenue",
            "contact_support": "Contactez le support en envoyant un e-mail à support@oskar.immo si le problème persiste."
        }
    }
</i18n>

<style lang="scss" scoped>
    $width: 1180px;
    $background: #EFEFEF;

    .dashboard-landlord-layout {
        min-height: 100vh;
        background: $background;

        .dashboard-content {
            max-width: $width;
            margin: 0 auto;
            padding: 45px 22px;

            @include tablet { 
                padding: 36px 18px;
            }
        }
    }
</style>