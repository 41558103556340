import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted, {"position":"bottom-left","duration":5000,"keepOnHover":true,"iconPack":["material"],"className":"toast-notification","action":{"icon":"close","onClick":(e, toastObject) => {
                toastObject.goAway(0);
            }},"containerClass":"toast-container"})

const globals = undefined
if(globals) {
  globals.forEach(global => {
    Vue.toasted.register(global.name, global.message, global.options)
  })
}

export default function (ctx, inject) {
  inject('toast', Vue.toasted)
}
