<template>

    <div :class="['payment-list-table-cell', { 'empty': !paymentAttempts.length }]">

        <svg-icon class="icon" iconColor="#BED000" width="24" height="18"><payment-icon /></svg-icon>

        <span class="payment" v-if="!paymentAttempts.length">-</span>

        <div class="payment" @click.stop="$emit('expandPayments', expandedLines)" v-else>

            {{ $t('payment_attempt_count', { nb: paymentAttempts.length }) }}

            <arrow class="arrow" :active="!collapsed" :width="12" :height="8" color="#BED000" />

        </div>

    </div>

</template>

<script>
    import SvgIcon from '~/components/svg-icon'
    import PaymentIcon from '~/components/icons/table-cell-icons/payment-icon'
    import SpinnerLoader from '~/components/spinner-loader'
    import Arrow from '~/components/arrow'

    export default {
        components: {
            SpinnerLoader,
            SvgIcon,
            PaymentIcon,
            Arrow
        },

        props: {
            paymentAttempts: {
                type: Array,
                default() {
                    return []
                }
            },
            collapsed: {
                type: Boolean,
                default: true
            }
        },

        computed: {
            expandedLines() {
                return this.paymentAttempts.map(payment => {
                    let statusType = 'warning'

                    if (payment.status === 'failed') statusType = 'warning'

                    else if (payment.status === 'succeeded') statusType = 'success'

                    return [
                        {
                            type: 'status',
                            data: {
                                type: statusType,
                                label: this.$t('payment_attempt', { status: this.$t(payment.status) })
                            }
                        },
                        {
                            type: 'string',
                            data: this.$t('payment_method', { method: this.$t(payment.payment_method_type) }),
                            colspan: 2
                        },
                        {
                            type: 'string',
                            data: this.$t('payment_date', { date: this.$moment(payment.triggered_at).format('Do MMMM YYYY à hh:mm') }),
                            colspan: 2
                        }
                    ]
                })
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "card": "carte",
            "failed": "échoué",
            "payment_attempt_count": "{nb} tentatives",
            "payment_attempt": "Paiement {status}",
            "payment_date": "le {date}",
            "payment_infos": "{status} le {date}",
            "payment_method": "Par {method}",
            "pending": "en attente",
            "sepa_direct_debit": "prélèvement SEPA",
            "sepa_credit_transfer": "virement SEPA",
            "succeeded": "réussi"
        }
    }
</i18n>

<style lang="scss">
    $payment: #BED000;

    .payment-list-table-cell {
        display: flex;

        &.empty {
            opacity: .5;
        }

        .payment {
            display: flex;
            align-items: center;
            color: $payment;
            text-decoration-color: $payment;

            .arrow {
                border-bottom-color: $payment;
                margin-left: 9px;
            }

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .icon {
            margin-right: 7px;
        }
    }
</style>
