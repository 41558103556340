import SvgIcon from '~/components/svg-icon'
import AttachedIcon from '~/components/icons/status-icons/attached-icon'
import { WaitingIcon } from '~/components/icons/document-type-icons'
import { DownloadIcon, AddNewIcon, PrintIcon, EditIcon, TerminateIcon, SignatureIcon, DeleteIcon, RefreshIcon, ViewIcon, DownloadPurpleIcon, ArrowIcon, CloseIcon, BankaccountIcon, BankIcon, IdentityIcon, PaymentMethodIcon, FeesIcon, InfoIcon, UploadIcon, ArrowFullRight, MoreIcon, GridIcon, ListIcon, ExportIcon, HandEuro, AddNewCircleIcon, HousePrice, FolderIcon } from '~/components/icons/button-icons'

export default {
    components: {
        SvgIcon,
        DownloadIcon,
        AddNewIcon,
        PrintIcon,
        EditIcon,
        TerminateIcon,
        SignatureIcon,
        DeleteIcon,
        RefreshIcon,
        ViewIcon,
        DownloadPurpleIcon,
        ArrowIcon,
        CloseIcon,
        AttachedIcon,
        BankaccountIcon,
        BankIcon,
        IdentityIcon,
        PaymentMethodIcon,
        FeesIcon,
        InfoIcon,
        UploadIcon,
        ArrowFullRight,
        MoreIcon,
        GridIcon,
        ListIcon,
        ExportIcon,
        AddNewCircleIcon,
        HandEuro,
        HousePrice,
        FolderIcon,
        WaitingIcon
    }
}