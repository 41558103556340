<template>

    <div class="select-beneficiary-payout-bank-account">

        <h3 class="group-title">{{$t('select_profile_payment_account')}}</h3>

        <div class="profile-payout-bank-accounts">

            <payment-method-element type="bank_account" :paymentMethod="account" v-for="(account, key) in profilePayoutBankAccounts" :key="key" @click.native="handleSelectedAccount(account)" :active="account.iban === selectedPayoutBankAccount.iban"/>

        </div>

    </div>

</template>

<script>
    import PaymentMethodElement from '~/components/payment-method-element'
    import { mapGetters } from 'vuex'

    export default {
        name: 'SelectBeneficiaryPayoutBankAccount',

        components: {
            PaymentMethodElement
        },

        async fetch() {

            const { store } = this.$nuxt.context

            this.selectedPayoutBankAccount = await store.dispatch('profile/fetchDefaultPayoutBankAccountRelationship')
        },

        methods: {
            handleSelectedAccount(payoutBankAccount) {

                this.selectedPayoutBankAccount = payoutBankAccount
            }
        },

        computed : {
            selectedPayoutBankAccount: {
                get() {
                    return this.$store.getters['payout/getRelatedBeneficiary']
                },
                set(newVal) {
                    this.$store.commit('payout/setRelatedBeneficiary', newVal)
                }
            },

            ...mapGetters({
                profilePayoutBankAccounts: 'profile/getRelatedPayoutBankAccounts'
            })
        }
    }
</script>

<i18n>
    {
        "fr": {
            "select_profile_payment_account": "Sélectionnez le compte à créditer"
        }
    }
</i18n>

<style lang="scss" scoped>
    .group-title{
        font-size: 24px;
        color: #333333;
        margin-bottom: 24px;
    }

    .profile-payout-bank-accounts{
        width: 100%;

      & > * + * {
        margin-top: 16px;
      }
    }
</style>
