<template>

    <ul class="text-area-input-list">

        <p v-html="$t('empty', { type: label.toLowerCase() })" v-if="!value.length" />

        <form-group class="list" v-for="(textarea, key) in value" :key="key" :label="label + ' ' + (key + 1)" :name="name" :rules="rules">

            <text-area-input class="textarea" :name="name + '_' + key" :value="textarea" @input="emitValue" :minHeight="70" :placeholder="placeholder ? placeholder : $t('text_area_placeholder')" />

            <label class="delete" @click="deleteItem(key)">{{ $t('delete') }}</label>

        </form-group>

        <button-input class="button background-transparent green-text-color" :text="$t('add_new', { type: label.toLowerCase() })" iconImg="add_new" @click="addNew" />
            
    </ul>

</template>

<script>
    import FormGroup from '~/components/inputs/form-group'

    export default {
        components: { 
            FormGroup
        },
        
        props: {
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            value: {
                type: Array,
                required: true
            },
            placeholder: {
                type: String,
                required: false
            },
            rules: {
                type: String,
                required: false
            }
        },

        methods: {
            emitValue(value, name) {             
                let res = this.value.slice(0)
                let key = Number(name.replace(this.name + '_', ''))
                res[key] = value
                this.$emit('input', res)
            },

            addNew() {
                let res = this.value.slice(0)
                res.push('')
                this.$emit('input', res)
            },

            deleteItem(key) {
                this.$emit('input', this.value.slice(0, key).concat(this.value.slice(key + 1)))
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "empty": "Aucune {type}",
            "add_new": "Ajouter un(e) {type}",
            "delete": "Supprimer",
            "text_area_placeholder": "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        }
    }
</i18n>

<style lang="scss">
    $delete-color: #FF657F;
    $add-color: #BED000;
    
    .text-area-input-list {
        align-items: flex-start;

        .textarea {
            align-self: normal;
        }

        .delete {
            align-self: flex-end;
            color: $delete-color;
            cursor: pointer
        }

        .button {
            margin-top: 30px;
            padding-left: 0;
        }
    }
</style>
