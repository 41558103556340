export const strict = true

export const defaultState = () => {
    return {
        date: '',
        description: '',
        credit: 0,
        debit: 0,
        id: '',
        relationships: {
            accounting_account: {},
            accounting_entry_set: {},
            accounting_journal: {},
        }
    }
}

export const state = defaultState

export const mutations = {
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },

    setDate(state, value) {
        state.date = value
    },

    setDescription(state, value) {
        state.description = value
    },

    setCredit(state, value) {
        state.credit = value
    },

    setDebit(state, value) {
        state.debit = value
    },

    setId(state, value) {
        state.id = value
    },

    setRelatedAccountingAccount(state, value) {
        state.relationships.accounting_account = value
    },
    
    setRelatedAccountingEntrySet(state, value) {
        state.relationships.accounting_entry_set = value
    },

    setRelatedAccountingJournal(state, value){
        state.relationships.accounting_journal = value
    }
}

export const getters = {
    getDate(state) {
        return state.date
    },

    getDescription(state) {
        return state.description
    },

    getCredit(state) {
        return state.credit
    },

    getDebit(state) {
        return state.debit
    },

    getId(state) {
        return state.id
    },

    getRelatedAccountingAccount(state) {
        return state.relationships.accounting_account
    },
    
    getRelatedAccountingEntrySet(state) {
        return state.relationships.accounting_entry_set
    },

    getRelatedAccountingJournal(state){
        return state.relationships.accounting_journal
    }
}

export const actions = {
    async createAccountingEntrySetEntry({ dispatch, getters }, { params }) {
        try {

            let request = await dispatch('formatAccountingEntryRequest')

            const accountingEntry = await dispatch('tyltoApi/post', [request, { url: `accounting-entry-sets/${params.accountingEntrySetId}/accounting-entries` }], { root: true })

            await dispatch('fillAccountingEntry', accountingEntry)
            
            return accountingEntry
        
        } catch(error) {

            throw error
        }
    },

    async deleteAccountingEntry({ dispatch, getters, commit }) {
        try {

            await dispatch('tyltoApi/delete', 'accounting-entries/' + getters['getId'], { root: true })

            commit('resetDefaultState')
        
        } catch(error) {

            throw error
        }
    },

    async getAccountingEntrySet({ rootGetters, dispatch }, accountingEntrySetId) {
        try {

            let accountingEntry = rootGetters['tyltoApi/get'](`accounting-entry-sets/${accountingEntrySetId}`)

            if (typeof accountingEntry === 'undefined' || !Object.keys(accountingEntrySet).length) {
            
                accountingEntrySet = await dispatch('tyltoApi/get', 'accounting-entry-sets/' + accountingEntrySetId, { root: true })
            }

            return accountingEntrySet
        
        } catch(error) {

            throw error
        }
    },

    async fetchAccountingAccountRelationship({ dispatch, getters, commit }, accountingEntryId) {
        try {

            let accountingAccount = {}

            if (Object.keys(getters['getRelatedAccountingAccount']).length) accountingAccount = getters['getRelatedAccountingAccount']

            else {
                
                const id = getters['getId'] !== '' ? getters['getId'] : accountingEntryId
                
                const { data } = await this.$axios({ method: 'get', url: `accounting-entries/${id}/relationships/accounting_account` })

                if (data['data'] !== null) accountingAccount = await dispatch('accounting-account/fetchAccountingAccount', data['data']['id'], { root: true })
            }

            commit('setRelatedAccountingAccount', accountingAccount)

            return accountingAccount

        } catch(error) {

            throw error
        }
    },

    async fetchAccountingJournalRelationship({dispatch, getters, commit}, accountingEntryId){
        try {
            
            let accountingJournal = {}

            if (Object.keys(getters['getRelatedAccountingJournal']).length) accountingJournal = getters['getRelatedAccountingJournal']

            else {

                const id = getters['getId'] !== '' ? getters['getId'] : accountingEntryId

                const { data } = await this.$axios({ method: 'get', url: `accounting-entries/${id}/relationships/accounting_journal` })

                if (data['data'] !== null) accountingJournal = await dispatch('accounting-journal/fetchAccountingJournal', data['data']['id'], { root: true })
            }

            commit('setRelatedAccountingJournal', accountingJournal)

            return accountingJournal

        } catch (error) {

            throw error
        }
    },

    async fetchAccountingEntrySetRelationship({ dispatch, getters, commit }, accountingEntryId) {
        try {

            let accountingEntrySet = {}

            if (Object.keys(getters['getRelatedAccountingEntrySet']).length) accountingEntrySet = getters['getRelatedAccountingEntrySet']

            else {
                
                const id = getters['getId'] !== '' ? getters['getId'] : accountingEntryId
                
                const { data } = await this.$axios({ method: 'get', url: `accounting-entries/${id}/relationships/accounting_entry_set` })

                if (data['data'] !== null) accountingEntrySet = await dispatch('accounting-entry/fetchAccountingEntrySet', data['data']['id'], { root: true })
            }

            commit('setRelatedAccountingEntrySet', accountingEntrySet)

            return accountingEntrySet

        } catch(error) {

            throw error
        }
    },

    async formatAccountingEntryRequest({ getters }) {
        let accountingEntry = {
            date: getters['getDate'],
            description: getters['getDescription'],
            _jv: {
                type: 'accounting-entries'
            }
        }

        if (getters['getId'] !== '')
            accountingEntry['_jv'].id = getters['getId']

        if (getters['getCredit'] > 0)
            accountingEntry['credit'] = getters['getCredit']
        
        else if (getters['getDebit'] > 0)
            accountingEntry['debit'] = getters['getDebit']

        if (Object.keys(getters['getRelatedAccountingAccount']).length || Object.keys(getters['getRelatedAccountingEntrySet']).length) {
            accountingEntry['_jv']['relationships'] = {}

            if (Object.keys(getters['getRelatedAccountingAccount']).length) {
                accountingEntry['_jv']['relationships']['accounting_account'] = {
                    data: {
                        type: 'accounting-accounts',
                        id: getters['getRelatedAccountingAccount']['_jv'].id
                    }
                }
            }

            if (Object.keys(getters['getRelatedAccountingEntrySet']).length) {
                accountingEntry['_jv']['relationships']['accounting_entry_set'] = {
                    data: {
                        type: 'accounting-entry-sets',
                        id: getters['getRelatedAccountingEntrySet']['_jv'].id
                    }
                }
            }
            
            if (Object.keys(getters['getRelatedAccountingJournal']).length) {
                accountingEntry['_jv']['relationships']['accounting_journal'] = {
                    data: {
                        type: 'accounting-journals',
                        id: getters['getRelatedAccountingJournal']['_jv'].id
                    }
                }
            }
        }
        return accountingEntry
    },

    async fetchAccountingEntry({ dispatch, rootGetters }, accountingEntryId) {
        try {

            const accountingEntry = await dispatch('tyltoApi/get', 'accounting-entries/' + accountingEntryId, { root: true })
            
            return accountingEntry
        
        } catch(error) {

            throw error
        }
    },

    async fetchAndFillAccountingEntry({ dispatch }, accountingEntryId) {
        try {

            let accountingEntry = await dispatch('fetchAccountingEntry', accountingEntryId)

            await dispatch('fillAccountingEntry', accountingEntry)

            return accountingEntry
        
        } catch(error) {

            throw error
        }
    },

    async fillAccountingEntry({ commit }, accountingEntry) {
        commit('resetDefaultState')

        if (typeof accountingEntry['credit'] !== 'undefined') 
            commit('setCredit', accountingEntry['credit'])
        
        if (typeof accountingEntry['debit'] !== 'undefined')
            commit('setDebit', accountingEntry['debit'] * -1)

        if (typeof accountingEntry['date'] !== 'undefined')
            commit('setDate', accountingEntry['date'])

        if (typeof accountingEntry['description'] !== 'undefined')
            commit('setDescription', accountingEntry['description'])

        if (typeof accountingEntry['_jv'] !== 'undefined')
            commit('setId', accountingEntry['_jv'].id)

        if (typeof accountingEntry['accounting_account'] !== 'undefined' && Object.keys(accountingEntry['accounting_account']).length)
            commit('setRelatedAccountingAccount', accountingEntry['accounting_account'])

        if (typeof accountingEntry['accounting_entry_set'] !== 'undefined' && Object.keys(accountingEntry['accounting_entry_set']).length)
            commit('setRelatedAccountingEntrySet', accountingEntry['accounting_entry_set'])
        
        if (typeof accountingEntry['accounting_journal'] !== 'undefined' && Object.keys(accountingEntry['accounting_journal']).length)
            commit('setRelatedAccountingJournal', accountingEntry['accounting_journal'])
    },

    async updateAccountingEntry({ dispatch }) {
        try {

            let request = await dispatch('formatAccountingEntryRequest')

            const accountingEntry = await dispatch('tyltoApi/patch', request, { root: true })

            await dispatch('fillAccountingEntry', accountingEntry)

            return accountingEntry
        
        } catch(error) {

            throw error
        }
    }
}
