<template>

    <svg
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
        :width="width" 
        :height="height" 
        :viewBox="viewbox"
    >
        <g :fill="iconColor" :stroke="iconStrokeColor" :stroke-width="strokeWidth">

            <slot />

        </g>

    </svg>

</template>

<script>

export default {
    props: {
        width: {
            type: [Number, String],
            default: 16
        },
        height: {
            type: [Number, String],
            default: 16
        },
        viewbox: {
            type: [String ],
            default: '0 0 16 16' 
        },
        iconColor: {
            type: String
        },
        iconStrokeColor: {
            type: String
        },
        strokeWidth : {
            type: [Number, String],
            default: 1,
        }
    }
}
</script>

