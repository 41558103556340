<template>

    <div class="supplier-action-buttons-widget margin-widget" v-if="isManager">

        <button-input :iconImg="'edit'" iconColor="#FFFFFF" class="action list" :text="$t('edit_supplier')" :redirectTo=" `/supplier/${supplierId}/update`" />

        <button-input :iconImg="'bank'" iconColor="#FFFFFF" class="action list" :text="$t('settle_account')" @click="$refs.settleSupplierAccountModal.show()" />

        <button-input :iconImg="'paymentmethod'" iconColor="#FFFFFF" class="action list" :text="$t('update_bank_account')" @click="updateBankAccount" />

        <button-input class="action action-delete" :iconImg="'delete'" iconColor="#FFFFFF" :text="$t('delete_supplier')" @click="$refs.deleteSupplierModal.show()" />

        <delete-supplier-modal ref="deleteSupplierModal" />

        <settle-supplier-account-modal ref="settleSupplierAccountModal" @closed="$emit('closed')"/>

    </div>

</template>

<script>
    import DeleteSupplierModal from '~/components/modal/delete-supplier'
    import SettleSupplierAccountModal from '~/components/modal/settle-supplier-account-modal'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            DeleteSupplierModal,
            SettleSupplierAccountModal
        },

        methods: {
            updateBankAccount() {
                this.$router.push({
                    path: `/supplier/${this.supplierId}/update`,
                    query: {
                        step: 'payout_bank_accounts'
                    }
                })
            }
        },

        computed: {
            ...mapGetters({
                supplierId: 'profile/getId',
                balance: 'accounting-account/getBalance',
                isManager: 'isManager'
            })
        }
    }
</script>

<i18n>
    {
        "fr": {
            "delete_supplier": "Supprimer le fournisseur",
            "edit_supplier": "Modifier le fournisseur",
            "update_bank_account": "Modifier le relevé d'identité bancaire",
            "settle_account": "Solder le compte"
        }
    }
</i18n>

<style lang="scss">
    .supplier-action-buttons-widget {
        display: flex;
        flex-direction: column;

        .action {

            &.list{
                color: #FFFFFF;
                background: #393939;
            }
        }
    }
</style>
