import { render, staticRenderFns } from "./update.vue?vue&type=template&id=beaeb3fa&scoped=true&"
import script from "./update.vue?vue&type=script&lang=js&"
export * from "./update.vue?vue&type=script&lang=js&"
import style0 from "./update.vue?vue&type=style&index=0&id=beaeb3fa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "beaeb3fa",
  null
  
)

/* custom blocks */
import block0 from "./update.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports