<template>

    <portal :selector="'#__nuxt'" :disabled="disabled">
        <template v-slot:default="slotProps">
            <div class="overlay" v-if="!disabled">
                <slot></slot>
                <Transition name="overlay" appear>
                    <div class="background"></div>
                </Transition>
            </div>
        </template>
    </portal>

</template>

<script>
import {Portal} from '@linusborg/vue-simple-portal'

export default {

    components: {
        Portal
    },

    props: {
        disabled: {
            type   : Boolean,
            default: false
        }
    },

    beforeDestroy() {
        document.documentElement.style.overflow = 'auto'
    },

    watch: {
        disabled: {
            immediate: true,
            handler(newVal) {
                document.documentElement.style.overflow = newVal ? 'auto' : 'hidden'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
$overlay-background: #060015;

.overlay {
    z-index: 22;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .background {
        z-index: 10;
        // position: absolute;
        background: $overlay-background;
        opacity: .6;
        // top: 0;
        // left: 0;
        width: 100%;
        height: 300vh;

        &.overlay-enter-active {
            animation: opacity-overlay-anim .2s ease-in-out;
        }

        &.overlay-leave-active {
            animation: opacity-overlay-anim .2s reverse ease-in-out;
        }
    }
}
</style>
