<template>

    <form-group class="check-box" :name="name" :label="formGroupLabel" :rules="rules" @click.native="$emit('click', $event)" v-slot="{ errors }">
        
        <input type="text" :value="value" v-show="false" />

        <input type="checkbox" :id="name" :checked="value" @change="$emit('input', $event.target.checked, name)">
                
        <label class="option-name" :for="name" v-if="label.length">
            
            <span :class="['checkmark', { 'checked': value }, { 'errors': errors.length }]"></span>

            <span v-html="formatted(label)"></span> 
            
        </label>

    </form-group>

</template>

<script>
    import FormGroup from '~/components/inputs/form-group'

    export default {
        components: { 
            FormGroup 
        },

        props: {
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            value: {
                type: Boolean,
                required: true
            },
            rules: {
                type: [String, Object],
                default: ''
            },
            formGroupLabel: {
                type: String,
                default: ''
            }
        },

        methods: {
            formatted(option) {
                return option.charAt(0).toUpperCase() + option.slice(1)
            }
        }
    }
</script>

<style lang="scss">
    $checkbox-border-color: #6C6C6C;
    $checkbox-border-color-cheked: #BED000;

    .check-box {
        position: relative;
        user-select: none;
        margin-bottom: 10px;
        margin-right: 30px;

        &.errors .checkmark {
            background-color: #FBE8E8;
            border-color: #F5A623;
        }

        .form-group-input {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
            width: fit-content;
        }

        .option-name {
            @include body;
            display: flex;
            align-content: center;
            gap: 15px;

            a {
                text-decoration: underline;
            }
        }

        input[type='checkbox'] {
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            z-index: -10;
        }

        .checkmark {
            cursor: pointer;
            height: 14px;
            width: 14px;
            border: 1px solid $checkbox-border-color;
            border-radius: 4px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;

            &:after {
                content: "";
                display: none;
            }

            &.checked {
                border-color: $checkbox-border-color-cheked;
                background: $checkbox-border-color-cheked;

                &:after {
                    display: block;
                    width: calc(100% - 8px);
                    height: calc(100% - 10px);
                    border-bottom: 3px solid white;
                    border-left: 3px solid white;
                    transform: rotate(-45deg);
                    border-radius: 2px;
                    position: relative;
                    top: -2px;
                }
            }

            &.errors {
                background-color: #FBE8E8;
                border-color: #F5A623;
            }
        }
    }
</style>
