<template>
    
    <div class="progress-circle-chart">
        
        <svg class="stat-circle" viewBox="0 0 20 20" ref="statCircle" :style="{ width: `${width}px` }">
            
            <circle class="bg" cx="10" cy="10" r="8" opacity="0.3" :style="{ stroke: circleBgColor }"></circle>
    
            <circle :class="['progress', color]" cx="10" cy="10" r="8" :data-percentage="completion" :style="{ 'stroke-dashoffset': `${strokeDashOffset}px`, 'stroke': circleProgressBgColor }"></circle>
        
        </svg>
        
        <span :class="['progression-number', color]" v-if="progressionNumberFontSize" :style="{ 'font-size': `${progressionNumberFontSize}px`, 'color': circleProgressBgColor }">{{ completion }}%</span> 

    </div>

</template>

<script>    
    
    export default {

        props: {
            value: {
                type: Number,
                default: 0,
                required: false
            },
            color: {
                type: String,
                required: false
            },
            width: {
                type: Number,
                default: 86
            },
            progressionNumberFontSize: {
                type: Number,
                default: 20
            },
            circleBgColor: {
                type: String,
                default: '#EFEFEF'
            },
            circleProgressBgColor: {
                type: String,
                default: '#BED000'
            }
        },
        
        computed: {
            completion() {
                let percentage =  this.value
                
                return  percentage ? percentage : 0
            },

            strokeDashOffset() {
                return 51 - ((51 / 100) * this.completion);
            }
        }
    }
</script>

<style lang="scss">
    .progress-circle-chart {
        display: flex;
        align-items: center;
        justify-items: center;
        position: relative;
        width: max-content;

        .stat-circle {            
            circle.bg {
                fill: none;
                stroke-width: 2;
            }

            circle.progress {
                fill: none;
                stroke-width: 2;
                stroke-dasharray: 51 51;
                stroke-dashoffset: -51;
                stroke-linecap: round;
                transform: rotate(-90deg);
                transition: stroke-dashoffset 0.5s;
                transform-origin: 50% 50%;
            }
        }

        .progression-number {
            position: absolute;
            font-weight: 600;
            left: 50%;
            transform: translateX(-50%);
        }
    }
</style>
