const middleware = {}

middleware['control-landlord-infos'] = require('../middleware/control-landlord-infos.js')
middleware['control-landlord-infos'] = middleware['control-landlord-infos'].default || middleware['control-landlord-infos']

middleware['control-tenant-infos'] = require('../middleware/control-tenant-infos.js')
middleware['control-tenant-infos'] = middleware['control-tenant-infos'].default || middleware['control-tenant-infos']

middleware['customer'] = require('../middleware/customer.js')
middleware['customer'] = middleware['customer'].default || middleware['customer']

middleware['fetch-user'] = require('../middleware/fetch-user.js')
middleware['fetch-user'] = middleware['fetch-user'].default || middleware['fetch-user']

middleware['fill-auth-profile'] = require('../middleware/fill-auth-profile.js')
middleware['fill-auth-profile'] = middleware['fill-auth-profile'].default || middleware['fill-auth-profile']

middleware['ip-address'] = require('../middleware/ip-address.js')
middleware['ip-address'] = middleware['ip-address'].default || middleware['ip-address']

middleware['manager'] = require('../middleware/manager.js')
middleware['manager'] = middleware['manager'].default || middleware['manager']

middleware['reset-store'] = require('../middleware/reset-store.js')
middleware['reset-store'] = middleware['reset-store'].default || middleware['reset-store']

middleware['user-agent'] = require('../middleware/user-agent.js')
middleware['user-agent'] = middleware['user-agent'].default || middleware['user-agent']

export default middleware
