<template>

    <div class="transaction-table-cell">

        <div :class="['icon', icon ]" v-if="icon">

            <svg-icon width="24" height="24" :iconColor="icon === 'positive' ? '#18C95D' : '#FB5D5D'" viewBox="0 0 24 24" v-if="icon === 'positive'"><house-icon /></svg-icon>

            <svg-icon width="16" height="16" :iconColor="icon === 'positive' ? '#18C95D' : '#FB5D5D'" v-else-if="icon === 'negative'"><property-management-default-icon /></svg-icon>

        </div>

        <div class="transaction-infos">

            <div class="details">
            
                <label class="label">
                    
                    <p>{{ line1 }}</p>
                    
                    <status-tag class="tag" type="pending" v-if="tag">{{ tag }}</status-tag>
                
                </label>

                <span class="line-2">{{ line2 }}</span>

            </div>

        </div>

    </div>

</template>

<script>
    import SvgIcon from '~/components/svg-icon'
    import PropertyManagementDefaultIcon from '~/components/icons/dashboard/property-management-default-icon'
    import HouseIcon from '~/components/icons/property/house-icon'
    import StatusTag from '~/components/status-tag'
    
    export default {
        components: {
            SvgIcon,
            HouseIcon,
            PropertyManagementDefaultIcon,
            StatusTag
        },

        props: {
            line1: {
                type: String,
                required: true
            },
            line2: {
                type: String,
                required: false
            },
            icon: {
                type: String,
                required: false
            },
            tag: {
                type: String,
                required: false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .transaction-table-cell {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include tablet {
            padding: 15px 0px;
        }
        
        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 6px;
            margin-right: 16px;
            flex-shrink: 0;

            &.positive {
                background: #E5F9ED;
            }

            &.negative {
                background: #fbe8e8;
            }
        }

        .transaction-infos {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-basis: 100%;

            @include tablet {
                display: block;
            }

            .details {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                @include mobileL { 
                    flex-direction: column;
                }

                .label {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    flex-wrap: wrap;
                    text-transform: capitalize;
                    
                    p {
                        color: #333;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                    }
                }

                .label,
                .line-2 {
                    font-family: 'Montserrat';
                    font-size: 11pt;
                }

                .line-2 {
                    color: #8F94A0;
                    font-size: 10.5pt;
                    margin-top: 2px;
                    flex-basis: 100%;
                }
            }

            .tag {
                margin-left: 14px;
            }
        }
    }
</style>