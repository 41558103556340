<template>

    <form-group class="payment-method-input-group" name="name" :rules="rules">

        <input type="hidden" :value="value" />

        <spinner-loader v-if="loading" />

        <div class="payment-method-elements" v-else>

            <p class="empty" v-if="!value.length">{{ $t('no_payment_methods') }}</p>

            <form-info class="form-info" :text="deleteErrors.map(error => error.detail).join('<br>')" type="error" v-if="deleteErrors.length" />

            <payment-method-element :class="['element', { 'default': paymentMethod.default }]" :paymentMethod="paymentMethod" :active="paymentMethod.default" @delete="show" @updateDefaultPaymentMethod="updateDefaultPaymentMethod" v-for="(paymentMethod, key) in value" :key="key" />

            <payment-method-element class="element" :editAction="$t('new_payment_method')" @edit="editMode = true" v-if="!hideUpdate && !editMode"/>

        </div>
        
        <step-nested-form class="step-nested-form" :title="$t('new_payment_method')" :fields="{ paymentMethodType, isDefaultPaymentMethod, paymentMethodIban, paymentMethodBankName, paymentMethodSwiftCode, options }" :onSubmit="createScheduledPayment()" @close="resetForm" icon="payment-method" v-if="editMode">

            <template v-slot="{ fields }">

                <hr class="separator fullsize" />

                <div class="fullsize create-or-update" v-if="!fields.paymentMethodType && fields.options.types">

                    <template v-if="fields.options.types.includes('sepa_credit_transfer')">

                        <div class="payment-method-container" @click="paymentMethodType = 'sepa_credit_transfer'">

                            <svg-icon class="left-icon" width="32" height="32" :viewbox="'0 0 32 32'" iconColor="#BED000"><credit-transfer-icon /></svg-icon>

                            <div class="description">

                                <span class="recommended" v-if="options.types.includes('sepa_credit_transfer_recommended')">{{ $t('recommended') }}</span>

                                <h3 class="title">{{ $t('scheduled_payment') }}</h3>

                                <p class="subtitle">{{ $t('scheduled_payment_subtitle') }}</p>

                            </div>

                            <svg-icon class="arrow-icon" iconColor="#393939" iconStrokeColor="#393939" :viewbox="'0 0 10 16'"><arrow-icon /></svg-icon>

                        </div>

                    </template>

                    <template v-if="fields.options.types.includes('virtual_iban')">

                        <div :class="['payment-method-container', {'disabled': propertyManagerSwanOnboarding.onboarding_state !== 'Completed' }]" @click="paymentMethodType = 'virtual_iban'">

                            <svg-icon class="left-icon" width="32" height="32" :viewbox="'0 0 32 32'" iconColor="#BED000"><virtual-iban-icon /></svg-icon>

                            <div class="description">

                                <span class="recommended grey" v-if=" propertyManagerSwanOnboarding.onboarding_state !== 'Completed'">{{ $t('disable') }}</span>

                                <h3 class="title">{{ $t('virtual_iban') }}</h3>

                                <p class="subtitle">{{ $t('virtual_iban_subtitle') }}</p>

                            </div>

                            <svg-icon class="arrow-icon" iconColor="#393939" iconStrokeColor="#393939" :viewbox="'0 0 10 16'"><arrow-icon /></svg-icon>

                        </div>

                    </template>

                    <template v-if="fields.options.types.includes('external_sepa_direct_debit')">

                        <div class="payment-method-container" @click="paymentMethodType = 'external_sepa_direct_debit'">

                            <svg-icon class="left-icon" width="32" height="32" :viewbox="'0 0 32 32'" iconColor="#BED000"><external-sepa-direct-debit-icon /></svg-icon>

                            <div class="description">

                                <span class="recommended" v-if="options.types.includes('sepa_direct_debit_recommended')">{{ $t('recommended') }}</span>

                                <h3 class="title">{{ $t('external_sepa_direct_debit') }}</h3>

                                <p class="subtitle">{{ $t('external_sepa_direct_debit_subtitle') }}</p>

                            </div>

                            <svg-icon class="arrow-icon" iconColor="#393939" iconStrokeColor="#393939" :viewbox="'0 0 10 16'"><arrow-icon /></svg-icon>

                        </div>

                    </template>

                    <template v-if="fields.options.types.includes('cash')">

                        <div class="payment-method-container" @click="paymentMethodType = 'cash'">

                            <svg-icon class="left-icon" width="32" height="32" iconColor="#BED000" :viewbox="'0 0 32 32'"><cash-icon /></svg-icon>

                            <div class="description">

                                <span class="recommended" v-if="options.types.includes('cash_recommended')">{{ $t('recommended') }}</span>

                                <h3 class="title">{{ $t('cash') }}</h3>

                                <p class="subtitle">{{ $t('cash_subtitle') }}</p>

                            </div>

                            <svg-icon class="arrow-icon" iconColor="#393939" iconStrokeColor="#393939" :viewbox="'0 0 10 16'"><arrow-icon /></svg-icon>

                        </div>

                    </template>

                    <template v-if="fields.options.types.includes('check')">

                        <div class="payment-method-container" @click="paymentMethodType = 'check'">

                            <svg-icon class="left-icon" width="32" height="32" iconColor="#BED000" :viewbox="'0 0 32 32'"><check-icon /></svg-icon>

                            <div class="description">

                                <span class="recommended" v-if="options.types.includes('check_recommended')">{{ $t('recommended') }}</span>

                                <h3 class="title">{{ $t('check') }}</h3>

                                <p class="subtitle">{{ $t('check_subtitle') }}</p>

                            </div>

                            <svg-icon class="arrow-icon" iconColor="#393939" iconStrokeColor="#393939" :viewbox="'0 0 10 16'"><arrow-icon /></svg-icon>

                        </div>

                    </template>

                </div>

                <button-input class="back-to-button icon background-transparent grey-text-color" :iconImg="'back'" :iconColor="'#767676'" :text="$t('back')" @click="$store.commit('payment-method/resetDefaultState')" v-else />

                <div class="step-field fullsize" v-if="paymentMethodType === 'sepa_credit_transfer'">

                    <div class="form-title">

                        <svg-icon class="sepa-credit-transfer-icon" width="32" height="32" :viewbox="'0 0 32 32'" iconColor="#BED000"><credit-transfer-icon /></svg-icon>

                        <h3 class="text">{{ $t('scheduled_payment') }}</h3>

                    </div>

                    <form-info :text="$t('manual_paiment')" />

                    <bank-account-radio-input class="select-bank-account" :label="$t('sepa_credit_transfer_bank_account_source') " :name="'bank-account'" :border="true" :bankAccounts="bankAccounts" :value="selectedBankAccount" @input="selectedBankAccount = $event, fillSepaCreditTransfer(selectedBankAccount)" :rules="'required|not_empty'" />

                </div>

                <div class="step-field fullsize external_sepa_direct_debit-form" v-else-if="fields.paymentMethodType === 'external_sepa_direct_debit'">

                    <div class="form-title">

                        <svg-icon class="external_sepa_direct_debit-icon" width="32" height="32" :viewbox="'0 0 32 32'" iconColor="#BED000"><external-sepa-direct-debit-icon /></svg-icon>

                        <h3 class="text">{{ $t('external_sepa_direct_debit') }}</h3>

                    </div>

                    <text-input class="input holder-name" name="external_sepa_direct_debit_holder_name" :label="$t('external_sepa_direct_debit_holder_name')" :placeholder="$t('external_sepa_direct_debit_holder_name_placeholder')" v-model="paymentMethodHolderName" :rules="'required'"  />

                    <iban-input class="input bank-account" name="bank_account_number" :label="$t('external_sepa_direct_debit_iban_number')" :placeholder="$t('external_sepa_direct_debit_iban_number_placeholder')" v-model="paymentMethodIban" ref="iban" :rules="'required'" />

                    <text-input class="input umr" name="external_sepa_direct_debit_umr" :label="$t('external_sepa_direct_debit_umr')" :placeholder="$t('external_sepa_direct_debit_umr_placeholder')" v-model="paymentMethodUmr" />

                    <file-upload-input class="input umr-file" name="external_sepa_direct_debit_umr_file" :label="$t('external_sepa_direct_debit_umr_file')" @input="uploadUmr()" :extensions="['pdf']" v-model="umrDocument" />

                </div>

                <div class="step-field fullsize" v-else-if="fields.paymentMethodType === 'virtual_iban'">

                    <div class="form-title">

                        <svg-icon class="virtual-iban-icon" width="32" height="32" :viewbox="'0 0 32 32'" iconColor="#BED000"><virtual-iban-icon /></svg-icon>

                        <h3 class="text">{{ $t('virtual_iban') }}</h3>

                    </div>

                    <form-info :text="$t('virtual_iban_info')" />

                </div>

                <div class="step-field fullsize" v-else-if="fields.paymentMethodType === 'cash'">

                    <div class="form-title">

                        <svg-icon class="cash-icon" width="32" height="32" :viewbox="'0 0 32 32'" iconColor="#BED000"><cash-icon /></svg-icon>

                        <h3 class="text">{{ $t('cash') }}</h3>

                    </div>

                </div>

                <div class="step-field fullsize" v-else-if="fields.paymentMethodType === 'check'">

                    <div class="form-title">

                        <svg-icon class="check-icon" width="32" height="32" :viewbox="'0 0 32 32'" iconColor="#BED000"><check-icon /></svg-icon>

                        <h3 class="text">{{ $t('check') }}</h3>

                    </div>

                </div>

                <check-box-input class="fullsize isDefault" :label="$t('set_as_default_payment_method')" :name="'set-as-default-payment-method'" :value="fields.isDefaultPaymentMethod" @input="isDefaultPaymentMethod = $event" v-if="fields.paymentMethodType && value.length >= 1" />

            </template>

        </step-nested-form>

        <modal ref="deletePaymentMethod" :options="modalOptions" :title="$t('delete_payment_method')" @submitted="hide" @canceled="hide" @close="hide">

            <p>{{ $t('delete_payment_method_confirmation') }}</p>

        </modal>

    </form-group>

</template>

<script>
    import FormGroup from '~/components/inputs/form-group'
    import PaymentMethodElement from '~/components/payment-method-element'
    import FormInfo from '~/components/form-info'
    import SvgIcon from '~/components/svg-icon'
    import SpinnerLoader from '~/components/spinner-loader'
    import Modal from '~/components/modal'
    import { BankIcon, PaymentMethodIcon, ArrowIcon } from '~/components/icons/button-icons'
    import { CashIcon, CheckIcon, CreditTransferIcon, VirtualIbanIcon, ExternalSepaDirectDebitIcon } from '~/components/icons/bank-icons'
    import BankAccountRadioInput from '~/components/inputs-group/bank-account-radio-input.vue'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            FormInfo,
            PaymentMethodElement,
            FormGroup,
            SvgIcon,
            BankIcon,
            PaymentMethodIcon,
            ArrowIcon,
            CreditTransferIcon,
            SpinnerLoader,
            Modal,
            CashIcon,
            CheckIcon,
            CreditTransferIcon,
            VirtualIbanIcon,
            ExternalSepaDirectDebitIcon,
            BankAccountRadioInput
        },

        data() {
            return {
                editMode: true,
                loading: false,
                deleteErrors: [],
                selectedBankAccount: {},
                umrDocument: "",
                selectedPaymentMethod: {},
                modalOptions: {
                    submit: {
                        action: () => this.deletePaymentMethod(this.selectedPaymentMethod),
                    }
                },
            }
        },

        mounted() {
            this.editMode = !this.isAttachedPaymentMethod
        },

        props: {
            value: {
                type: Array,
                required: true
            },
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            rules: {
                type: String,
                required: false
            },
            hideUpdate: {
                type: Boolean,
                required: false,
                default: false
            },
            options: {
                type: Object,
                required: false,
                default() {
                    return {
                        onSubmit: () => {},
                        types: [
                            'virtual_iban',
                            'external_sepa_direct_debit',
                            'sepa_credit_transfer',
                            'cash',
                            'check'
                        ]
                    }
                }
            }
        },

        async fetch() {
                try {

                    const { response } = await this.$store.dispatch('property-manager/fetchBankAccounts')

                    this.bankAccounts = response

                } catch (error) {

                    throw error
                }
        },

        methods: {
            show(paymentMethod) {
                try {

                    this.$refs.deletePaymentMethod.show()

                    this.selectedPaymentMethod = paymentMethod

                } catch (error) {

                    throw error
                }
            },

            hide() {
                try {

                    this.$refs.deletePaymentMethod.hide()

                } catch (error) {

                    throw error
                }
            },

            async uploadUmr() {
                try {

                    this.loading = true

                    if (this.umrDocument === "" ) return

                    else {

                        this.$store.commit('file/setFile', this.umrDocument)

                        this.$store.commit('file/setTypes', ['sepa_direct_debit_mandate'])

                        const file = await this.$store.dispatch('file/createFile')

                        this.$store.commit('payment-method/setRelatedUmrDocument', file['_jv'].id)
                    }

                } catch(error) {

                    throw error

                } finally {

                    this.loading = false
                }
            },

            async fillSepaCreditTransfer(bankAccount) {
                try {

                    this.$store.commit('payment-method/setBankName',bankAccount.bank_name)

                    this.$store.commit ('payment-method/setIban', bankAccount.iban)

                    this.$store.commit('payment-method/setSwiftCode', bankAccount.bic)

                    this.$store.commit('payment-method/setHolderName', bankAccount.holder_name)

                } catch (error) {

                    throw error
                }
            },

            async updateDefaultPaymentMethod(paymentMethod) {
                try {

                    if (paymentMethod['default'] === false) {

                        this.loading = true

                        await this.$axios.patch('/payment-methods/' + paymentMethod['_jv'].id, {
                            data: {
                                id: paymentMethod['_jv'].id,
                                type: 'payment-methods',
                                attributes: {
                                    default: true
                                }
                            }
                        })

                        this.$store.dispatch('payment-method/fillPaymentMethod', paymentMethod)

                        if (typeof this.options.onRefresh !== 'undefined') {

                            await this.options.onRefresh.call()
                        }
                    }

                } catch(error) {

                    throw error

                } finally {

                    this.loading = false
                }
            },

            createScheduledPayment() {

                if (!this.paymentMethodType.length) return 

                else return async () => {
                    try {

                        if (typeof this.options.onSubmit !== 'undefined') {

                            await this.options.onSubmit.call()
                        }

                    } catch (error) {

                        throw error
                    }
                }
            },

            resetForm() {

                this.$store.commit('payment-method/resetDefaultState')

                this.deleteErrors = []

                this.selectedBankAccount = {}

                this.selectedPaymentMethod = {}

                this.umrDocument = ""

                this.editMode = false
            },

            async deletePaymentMethod(paymentMethod){
                try {

                    this.loading = true

                    await this.$store.dispatch('payment-method/deletePaymentMethod', {
                        id: paymentMethod['_jv'].id
                    })

                    this.$emit('deletePaymentMethod')

                } catch(errors) {

                    this.deleteErrors = errors.response.data.errors

                    throw errors

                } finally {

                    this.loading = false
                }
            }
        },

        computed: {
            isAttachedPaymentMethod() {
                return this.value.length > 0
            },

            disableButton() {
                if (this.paymentMethodType !== '') return true

                else return false
            },

            paymentMethodType: {
                get() {
                    return this.$store.getters['payment-method/getType']
                },
                set(newValue) {
                    return this.$store.commit('payment-method/setType', newValue)
                }
            },

            paymentMethodBankName: {
                get() {
                    return this.$store.getters['payment-method/getBankName']
                },
                set(newValue) {
                    return this.$store.commit('payment-method/setBankName', newValue)
                }
            },

            paymentMethodIban: {
                get() {
                    return this.$store.getters['payment-method/getIban']
                },
                set(newValue) {
                    return this.$store.commit('payment-method/setIban', newValue)
                }
            },

            paymentMethodSwiftCode: {
                get() {
                    return this.$store.getters['payment-method/getSwiftCode']
                },
                set(newValue) {
                    return this.$store.commit('payment-method/setSwiftCode', newValue)
                }
            },

            paymentMethodLast4Digits: {
                get() {
                    return this.$store.getters['payment-method/getLast4Digits']
                },
                set(newValue) {
                    return this.$store.commit('payment-method/setLast4Digits', newValue)
                }
            },

            paymentMethodHolderName: {
                get() {
                    return this.$store.getters['payment-method/getHolderName']
                },
                set(newValue) {
                    return this.$store.commit('payment-method/setHolderName', newValue)
                }
            },

            paymentMethodSepaDebit: {
                get() {
                    return this.$store.getters['payment-method/getSepaDebit']
                },
                set(newValue) {
                    return this.$store.commit('payment-method/setSepaDebit', newValue)
                }
            },

            isDefaultPaymentMethod: {
                get() {
                    return this.$store.getters['payment-method/getDefault']
                },
                set(newValue) {
                    return this.$store.commit('payment-method/setDefault', newValue)
                }
            },

            paymentMethodUmr: {
                get() {
                    return this.$store.getters['payment-method/getUniqueMandateReference']
                },
                set(newValue) {
                    return this.$store.commit('payment-method/setUniqueMandateReference', newValue)
                }
            },

            ...mapGetters({
                isManager: 'isManager',
                propertyManagerBankAccounts: 'property-manager/getRelatedBankAccounts',
                propertyManagerSwanOnboarding: 'property-manager/getSwanOnboarding'
            })
        },

        watch: {
            editMode: {
                immediate: true,
                handler(newVal) {
                    if (newVal) {

                        this.$store.commit('payment-method/resetDefaultState')
                    }
                }
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "back": "Retour",
            "new_payment_method": "Ajouter un moyen de paiement",
            "manual_paiment": "Programmez manuellement le paiement de votre loyer depuis l’interface de votre banque",
            "sepa_credit_transfer_bank_account_source": "Compte bancaire de destination",
            "scheduled_payment": "Virement programmé",
            "scheduled_payment_subtitle": "Vous programmez un virement mensuel vers le compte Oskar avec votre banque",
            "external_sepa_direct_debit": "Prélèvement automatique",
            "external_sepa_direct_debit_subtitle": "Vous communiquez votre IBAN afin que Oskar puisse procéder aux prélèvements automatiques",
            "cash": "Espèces",
            "cash_subtitle": "Vous déposez une liasse d'espèces dans un bordereau auprès de votre banque que vous à déposé vos locataires.",
            "check": "Chèque",
            "recommended": "Recommandé",
            "bank_name": "Nom de l'établissement",
            "bic": "BIC",
            "virtual_iban": "IBAN virtuel",
            "virtual_iban_subtitle": "Nous allons vous fournir un IBAN virtuel pour que vous puissiez programmer un virement mensuel depuis votre banque",
            "virtual_iban_info": "Vous pouvez programmer un virement mensuel depuis votre banque vers le compte Oskar. Nous vous fournirons un IBAN virtuel pour que vous puissiez programmer ce virement. Vous pouvez également utiliser votre IBAN personnel pour programmer ce virement.",
            "delete_payment_method": "Supprimer le moyen de paiement",
            "delete_payment_method_confirmation": "Êtes-vous sûr de vouloir supprimer ce moyen de paiement ?",
            "set_as_default_payment_method": "Définir comme moyen de paiement par défaut ?",
            "no_payment_methods": "Aucun moyen de paiement",
            "disable": "Désactiver",
            "external_sepa_direct_debit_holder_name": "Nom du titulaire",
            "external_sepa_direct_debit_holder_name_placeholder": "Thierry Dupont",
            "external_sepa_direct_debit_iban_number": "IBAN",
            "external_sepa_direct_debit_iban_number_placeholder": "FR76 9999 9002 0087 0486 0160 064",
            "external_sepa_direct_debit_umr": "Référence unique du mandat - RUM (optionnel)",
            "external_sepa_direct_debit_umr_placeholder": "HFRUJZ0PG",
            "external_sepa_direct_debit_umr_file": "Mandat de prélèvement (optionnel)",
            "check_subtitle": "Vous encaissez des chèques et les déposez auprès de votre banque sur votre compte séquestre."
        }
    }
</i18n>

<style lang="scss" scoped>
    .payment-method-input-group {

        .create-or-update {
            margin-top: 20px;
        }
        .form-group ::v-deep .form-group-input {
            display: flex;
            margin-bottom: 24px;

            .bank-widget {
                width: 100%;
            }
        }
        .back-to-button {
            justify-content: flex-start;
            padding-left: 0;
            margin-bottom: -20px !important;
        }

        .separator {
            height: 1px;
            background: #EFEFEF;
            border-radius: 5px;
            border: none;
            width: 100%;
        }

        .step-nested-form {
            margin-top: 12px;
            &::v-deep .button-group-items {
                border: none !important;
            }

            &::v-deep .step-inputs {
                grid-row-gap: 4px;
            }
        }
        
        .payment-method-elements {
            display: flex;
            grid-row-gap: 14px;
            flex-direction: column;

            .form-info {
                order: -2;
            }

            .element.default {
                order: -1;
            }

            .empty ~ .element {
                margin: 20px 0;
            }
        }

        .payment-method-container {
            cursor: pointer;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;

            & ~ .payment-method-container {
                margin-top: 24px;
                border-top: 1px solid #EFEFEF;
                padding-top: 24px;
            }

            &.disabled {
                opacity: 0.5;
                pointer-events: none;
                cursor: not-allowed;
            }

            .left-icon {
                min-width: 28px;
                margin-right: 24px;
            }

            .arrow-icon {
                transform: rotate(180deg);
                min-width: 15px;
            }

            .description {
                width: 100%;
                margin-right: 24px;

                .recommended {
                    color: #18C95D;
                    text-transform: uppercase;
                    font-size: 8pt;
                    font-weight: 800;

                    &.grey {
                        color: #8D939F;
                    }
                }

                .title {
                    color: #393939;
                    font-size: 12pt;
                    font-weight: 600;
                    margin-bottom: 4px;
                }

                .subtitle {
                    font-size: 10.5pt;
                    text-align: left;
                }
            }
        }

        .step-field {
            .readonly-container {
                border: solid 2px #DEE3EF;
                border-radius: 10px;
                padding: 18px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .icon {
                    cursor: pointer;
                }

                &:not(:last-of-type) {
                    margin-bottom: 16px;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
        .form-title {
            width: 100%;
            display: flex;
            align-items: center;
            margin: 32px 0 24px;
            gap: 8px;

            .text {
                font-size: 12pt;
                font-weight: 600;
                color: #393939
            }
        }

        .isDefault {
            margin-top: 24px;
        }
        .external_sepa_direct_debit-form {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .input {
                width: 49%;


                &.umr,
                &.umr-file {
                    width: 100%;
                }
            }

            .umr {
                border-bottom: 1px solid #F2F2F2;
                padding-bottom: 24px;
                margin-bottom: 24px;
            }

            .umr-file {
                border-bottom: 1px solid #F2F2F2;
                padding-bottom: 24px;
            }
        }
    }
</style>
