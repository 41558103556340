import {queryBuilder, queryResponse} from '~/utils/query'

export default {
    async createProperty({dispatch, getters}) {
        try {

            let propertyRequest = await dispatch('formatPropertyRequest')

            let property = await dispatch('tyltoApi/post', [propertyRequest, {url: `landlords/${getters['getRelatedLandlord']['_jv'].id}/properties`}], {root: true});

            await dispatch('fillProperty', property)

            return property

        } catch (error) {

            throw error
        }
    },

    async deleteProperty({dispatch, getters, commit}) {
        try {

            await dispatch('tyltoApi/delete', '/properties/' + getters['getId'], {root: true})

            commit('resetDefaultState')

        } catch (error) {

            throw error
        }
    },

    async fetchProperty({dispatch}, propertyId) {
        try {

            return await dispatch('tyltoApi/get', 'properties/' + propertyId, {root: true})

        } catch (error) {

            throw error
        }
    },

    async fetchAndFillProperty({dispatch}, propertyId) {
        try {

            let property = await dispatch('fetchProperty', propertyId)

            dispatch('fillProperty', property)

            return property

        } catch (error) {

            throw error
        }
    },

    async fetchFeesRelationship({dispatch, commit, getters}, params) {
        try {

            let propertyId = typeof params.propertyId !== 'undefined' ? params.propertyId : getters['getId']

            const {data} = await this.$axios({method: 'get', url: `properties/${propertyId}/relationships/fees`})

            const fees = await Promise.all(data['data'].map(relationship => dispatch('fees/fetchFee', relationship.id, {root: true})))

            commit('setRelatedFees', fees)

            return fees

        } catch (error) {

            throw error
        }
    },

    async fetchAppliedFeesRelationship({dispatch, commit, getters}, {params}) {
        try {

            let propertyId = typeof params.propertyId !== 'undefined' ? params.propertyId : getters['getId']

            const {data} = await this.$axios({
                method: 'get',
                url: `properties/${propertyId}/relationships/applied_fees`
            })

            const appliedFees = await Promise.all(data['data'].map(relationship => dispatch('fees/fetchFee', relationship.id, {root: true})))

            commit('setRelatedAppliedFees', appliedFees)

            return appliedFees

        } catch (error) {

            throw error
        }
    },

    async fetchProperties({dispatch}, params) {
        try {

            const response = await dispatch('tyltoApi/get', ['properties', {params: queryBuilder(params)}], {root: true})

            return queryResponse(response)

        } catch (error) {

            throw error
        }
    },

    async fetchPropertyAccountingEntries({dispatch, getters}, params) {
        try {

            const id = getters['getId'] !== '' ? getters['getId'] : params.propertyId

            const response = await dispatch('tyltoApi/get', [`properties/${id}/accounting-entries`, {params: queryBuilder(params)}], {root: true})

            return queryResponse(response)

        } catch (error) {

            throw error
        }
    },

    async fetchAccountingAccountRelationship({dispatch, getters, commit}, propertyId) {
        try {

            let accountingAccount = {}

            if (Object.keys(getters['getRelatedAccountingAccount']).length) accountingAccount = getters['getRelatedAccountingAccount']

            else {

                const id = getters['getId'] !== '' ? getters['getId'] : propertyId

                const {data} = await this.$axios({
                    method: 'get',
                    url: `properties/${id}/relationships/accounting_account`
                })

                if (data['data'] !== null) accountingAccount = await dispatch('accounting-account/fetchAccountingAccount', data['data']['id'], {root: true})
            }

            commit('setRelatedAccountingAccount', accountingAccount)

            return accountingAccount

        } catch (error) {

            throw error
        }
    },

    async fetchDiagnosesRelationship({dispatch, getters, commit}) {
        try {

            let files = []

            if (getters['getRelatedDiagnoses'].length) files = getters['getRelatedDiagnoses']

            else {

                const {data} = await this.$axios({
                    method: 'get',
                    url: `properties/${getters['getId']}/relationships/diagnoses`
                })

                files = await Promise.all(data['data'].map(relationship => dispatch('file/fetchFile', relationship.id, {root: true})))
            }

            commit('setRelatedDiagnoses', files)

            return files

        } catch (error) {

            throw error
        }
    },

    async fetchMandatesRelationship({dispatch, getters, commit}, propertyId) {
        try {

            let propertyMandate = []

            const id = getters['getId'] !== '' ? getters['getId'] : propertyId

            const {data} = await this.$axios({method: 'get', url: `properties/${id}/relationships/mandates`})

            propertyMandate = await Promise.all(data['data'].map(relationship => dispatch('mandate/fetchMandate', relationship.id, {root: true})))

            commit('setRelatedMandates', propertyMandate)

            return propertyMandate

        } catch (error) {

            throw error
        }
    },

    async fetchRentalsRelationship({dispatch, getters, commit}, propertyId) {
        try {

            let propertyRentals = []

            const id = propertyId !== undefined ? propertyId : getters['getId']

            const {data} = await this.$axios({method: 'get', url: `properties/${id}/relationships/rentals`})

            propertyRentals = await Promise.all(data['data'].map(relationship => dispatch('rental/fetchRental', relationship.id, {root: true})))

            commit('setRelatedRentals', propertyRentals)

            return propertyRentals

        } catch (error) {

            throw error
        }
    },

    async fetchTenantsRelationship({dispatch, getters, commit}, propertyId) {
        try {

            let propertyTenants = []

            if (getters['getRelatedTenants'].length) propertyTenants = getters['getRelatedTenants']

            else {

                const id = getters['getId'] !== '' ? getters['getId'] : propertyId

                const {data} = await this.$axios({method: 'get', url: `properties/${id}/relationships/tenants`})

                propertyTenants = await Promise.all(data['data'].map(relationship => dispatch('profile/fetchTenant', relationship.id, {root: true})))
            }

            commit('setRelatedTenants', propertyTenants)

            return propertyTenants

        } catch (error) {

            throw error
        }
    },

    async fetchLandlordRelationship({dispatch, getters, commit}, propertyId) {
        try {

            let id = typeof propertyId !== 'undefined' ? propertyId : getters['getId']

            let propertyLandlord = {}

            const {data} = await this.$axios({method: 'get', url: `properties/${id}/relationships/landlord`})

            if (data['data'] !== null) propertyLandlord = await dispatch('profile/fetchLandlord', data['data']['id'], {root: true})

            commit('setRelatedLandlord', propertyLandlord)

            return propertyLandlord

        } catch (error) {

            throw error
        }
    },

    async fillProperty({commit, dispatch}, property) {
        commit('resetDefaultState')

        if (typeof property['address'] !== 'undefined')
            commit('setAddress', property['address'])

        if (typeof property['reference'] !== 'undefined')
            commit('setReference', property['reference'])

        if (typeof property['area'] !== 'undefined')
            commit('setArea', property['area'])

        if (typeof property['area_tension'] !== 'undefined')
            commit('setAreaTension', property['area_tension'])

        if (typeof property['building_ownership_type'] !== 'undefined')
            commit('setBuildingOwnershipType', property['building_ownership_type'])

        if (typeof property['construction_date'] !== 'undefined')
            commit('setConstructionDate', property['construction_date'])

        if (typeof property['description'] !== 'undefined')
            commit('setDescription', property['description'])

        if (typeof property['dwelling_type'] !== 'undefined')
            commit('setDwellingType', property['dwelling_type'])

        if (typeof property['items'] !== 'undefined')
            await dispatch('setItems', property['items'])

        if (typeof property['case_number'] !== 'undefined')
            commit('setCaseNumber', property['case_number'])

        if (typeof property['fiscal_reference_number'] !== 'undefined')
            commit('setFiscalReferenceNumber', property['fiscal_reference_number'])

        if (typeof property['is_furnished'] !== 'undefined')
            commit('setIsFurnished', property['is_furnished'])

        if (typeof property['settlement_type'] !== 'undefined')
            commit('setSettlementType', property['settlement_type'])

        if (typeof property['dpe'] !== 'undefined') {

            if (typeof property['dpe'].consumption !== 'undefined')
                commit('setDpeConsumption', property['dpe'].consumption)

            if (typeof property['dpe'].emission !== 'undefined')
                commit('setDpeEmission', property['dpe'].emission)

            if (typeof property['dpe'].delivery_date !== 'undefined')
                commit('setDpeDeliveryDate', property['dpe'].delivery_date)

            if (typeof property['dpe'].expiration_date !== 'undefined')
                commit('setDpeExpirationDate', property['dpe'].expiration_date)

            commit('setDpeLetter', property['dpe'].letter)
        }


        if (typeof property['_jv'] !== 'undefined') {

            commit('setId', property['_jv'].id)

            if (typeof property['_jv']['meta'] !== 'undefined' && typeof property['_jv']['meta']['user_privileges'] !== 'undefined')
                commit('setUserPrivileges', property['_jv']['meta']['user_privileges'])
        }

    },

    async formatPropertyRequest({getters, rootGetters}) {
        let property = {
            address: getters['getAddress'],
            reference: getters['getReference'],
            area: getters['getArea'],
            area_tension: getters['getAreaTension'],
            case_number: getters['getCaseNumber'],
            dwelling_type: getters['getDwellingType'],
            fiscal_reference_number: getters['getFiscalReferenceNumber'],
            is_furnished: getters['getIsFurnished'],
            _jv: {
                type: 'properties',
                relationships: {
                    landlord: {
                        data: {
                            type: 'landlords',
                            id: getters['getRelatedLandlord']['_jv'].id
                        }
                    },
                }
            }
        }

        if (getters['getBuildingOwnershipType'] != '')
            property['building_ownership_type'] = getters['getBuildingOwnershipType']

        if (getters['getConstructionDate'] != '')
            property['construction_date'] = getters['getConstructionDate']

        if (getters['getDescription'] != '')
            property['description'] = getters['getDescription']

        if (getters['getItems'].length)
            property['items'] = getters['getItems']

        if (getters['getSettlementType'] != '')
            property['settlement_type'] = getters['getSettlementType']

        if (getters['getUserPrivileges'] != '')
            property['user_privileges'] = getters['getUserPrivileges']

        let dpe = {}

        if (getters['getDpeConsumption'] !== null && getters['getDpeConsumption'] >= 0)
            dpe['consumption'] = getters['getDpeConsumption']
        else if (rootGetters['workflow/isEditMode'])
            dpe['consumption'] = null

        if (getters['getDpeEmission'] !== null && getters['getDpeEmission'] >= 0)
            dpe['emission'] = getters['getDpeEmission']
        else if (rootGetters['workflow/isEditMode'])
            dpe['emission'] = null

        if (getters['getDpeDeliveryDate'] !== null && getters['getDpeDeliveryDate'] !== "")
            dpe['delivery_date'] = getters['getDpeDeliveryDate']
        else if (rootGetters['workflow/isEditMode'])
            dpe['delivery_date'] = null

        if (getters['getDpeExpirationDate'] !== null && getters['getDpeExpirationDate'] !== "")
            dpe['expiration_date'] = getters['getDpeExpirationDate']
        else if (rootGetters['workflow/isEditMode'])
            dpe['expiration_date'] = null

        if (Object.keys(dpe).length) {
            property['dpe'] = dpe
        }

        if (getters['getRelatedDiagnoses'].length) {
            property['_jv']['relationships'].diagnoses = {
                data: getters['getRelatedDiagnoses'].map(diagnose => {
                    return {
                        type: 'files',
                        id: diagnose['_jv'].id
                    }
                })
            }
        }

        if (getters['getId'] !== '')
            property['_jv']['id'] = getters['getId']

        return property
    },

    async setItems({commit, getters}, items) {

        items.forEach(item => {

            commit('addItem', item)

            if (getters['getModeDeProductionChauffageCollectifOptions'].includes(item.type)) {

                commit('setTypeDeProductionChauffage', 'common')

            } else if (getters['getModeDeProductionChauffagePrivateOptions'].includes(item.type)) {

                commit('setTypeDeProductionChauffage', 'private')

            } else if (getters['getModeDeProductionChauffageCentralOptions'].includes(item.type)) {

                commit('setTypeDeProductionChauffage', 'central')

            } else if (getters['getModeDeProductionChauffageEmptyOptions'].includes(item.type)) {

                commit('setTypeDeProductionChauffage', 'empty_heater')
            }

            if (getters['getModeDeProductionEauChaudeCollectifOptions'].includes(item.type)) {

                commit('setTypeDeProductionEauChaude', 'common')

            } else if (getters['getModeDeProductionEauChaudePrivateOptions'].includes(item.type)) {

                commit('setTypeDeProductionEauChaude', 'private')

            } else if (getters['getModeDeProductionEauChaudeCentralOptions'].includes(item.type)) {

                commit('setTypeDeProductionEauChaude', 'central')
            }
        })
    },

    async updateDiagnosesRelationship({getters, commit}) {
        try {

            let diagnoses = {
                data: []
            }

            if (getters['getRelatedDiagnoses'].length) {
                diagnoses['data'] = getters['getRelatedDiagnoses'].map(diagnose => {
                    return {
                        type: 'files',
                        id: diagnose['_jv'].id
                    }
                })
            }

            return await this.$axios({
                method: 'patch',
                url: 'properties/' + getters['getId'] + '/relationships/diagnoses',
                data: diagnoses
            })

        } catch (error) {

            throw error
        }
    },

    async updateLandlordRelationship({getters, commit}) {
        try {

            let landlord = null

            if (Object.keys(getters['getRelatedLandlord']).length) {
                landlord = {
                    data: {
                        type: 'landlords',
                        id: getters['getRelatedLandlord']['_jv'].id
                    }
                }
            }

            const response = await this.$axios({
                method: 'patch',
                url: 'properties/' + getters['getId'] + '/relationships/landlord',
                data: landlord
            })

            commit('tyltoApi/deleteRecord', {_jv: {type: 'properties', id: getters['getId']}}, {root: true})

            return response

        } catch (error) {

            throw error
        }
    },

    async updateMandatesRelationship({getters, commit}) {
        try {

            let propertyMandate = []

            propertyMandate = {
                data: getters['getRelatedMandates'].map(mandate => {
                    return {
                        type: 'files',
                        id: mandate['_jv'].id
                    }
                })
            }

            const response = await this.$axios({
                method: 'patch',
                url: 'properties/' + getters['getId'] + '/relationships/mandates',
                data: propertyMandate
            })

            commit('tyltoApi/deleteRecord', {_jv: {type: 'properties', id: getters['getId']}}, {root: true})

            return response

        } catch (error) {

            throw error
        }
    },

    async updateProperty({dispatch}) {
        try {

            let requestData = await dispatch('formatPropertyRequest')

            const property = await dispatch('tyltoApi/patch', requestData, {root: true})

            dispatch('fillProperty', property)

            return property

        } catch (error) {

            throw error
        }
    },

    async fetchRentals({dispatch, getters, commit}, propertyId) {

        const {data} = await this.$axios({method: 'get', url: `properties/${propertyId}/rentals`});

        return {
            propertyId: propertyId,
            rentals: data
        };
    },

    async fetchMandates({dispatch}, {params, page, filters}) {

        const response = await dispatch('tyltoApi/get',
            ['properties/' + params.propertyId + '/mandates', {params: queryBuilder({page, filters})}], {root: true})

        return queryResponse(response)

    },

}
