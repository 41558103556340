<template>

    <button-input class="back-to-button icon background-transparent green-text-color" @click="click" :iconColor="'#BED000'" :iconImg="'back'" :text="label" />

</template>

<script>
    export default {
        props: {
            path: {
                type: String,
                required: false
            },
            label: {
                type: String,
                required: true
            },
            onClick: {
                type: Function,
                default() {
                    return new Function
                }
            }
        },

        methods: {
            click() {
                this.onClick.call()

                if (typeof this.path !== 'undefined') return this.$router.push(this.path)
            }
        }
    }
</script>

<style lang="scss" scoped>
    $back-color: #BED000;

    .back-to-button {
        justify-content: flex-start;
        margin-top: -21px;
        margin-bottom: 24px;
        color: $back-color;
        font-weight: 400;
        padding-left: 0;
    }
</style>