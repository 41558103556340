<template>

    <modal class="tenant-payment-method-modal" :title="$t('details-tenant-payment-method')" :options="modalOptions" @submitted="hide" @canceled="hide" @close="hide" ref="modal" >

        <tenant-payment-method-details class="tenant-payment-method-details"/>

    </modal>
  
</template>

<script>
    import Modal from '~/components/modal'
    import SpinnerLoader from '~/components/spinner-loader'
    import { mapGetters } from 'vuex'
    import TenantPaymentMethodDetails from '~/components/widget/tenant-payment-method-details'

    export default {
        name: 'TenantPaymentMethodModal',

        components: {
            Modal,
            SpinnerLoader,
            TenantPaymentMethodDetails
        },

        data() {
            return {
                loading: false,
                modalOptions: {},
            }
        },

        methods: {
            show() {
                this.$refs.modal.show()
            },

            hide() {
                this.$refs.modal.hide()

                this.$emit('close')
            },
        },

        computed: {

            ...mapGetters({
                tenantId: 'profile/getId',
                paymentMethod: 'profile/getRelatedPaymentMethods',
            })
        }
    }
</script>

<i18n>
    {
        "fr": {
            "amex": "American Express",
            "card": "Carte",
            "mastercard": "Mastercard",
            "sepa_credit_transfer": "Virement SEPA",
            "sepa_direct_debit": "Prélèvement SEPA",
            "step_resume": "{type}",
            "details-tenant-payment-method": "Détails du moyen de paiement"
        }
    }
</i18n>