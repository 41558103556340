<template>
    
    <modal class="trial-period-modal" :title="$t('trial_period_expirate')" :options="modalOptions" @submitted="hide" @canceled="hide" @close="hide" ref="modal">

        <spinner-loader v-if="loading" />

        <div v-else>

            <div v-show="!calendarIsOpen">

                <form-info :text="$t('add_payment_method')" />

                <step-field name="payment_method" :label="$t('payment_method')" :options="paymentMethodOptions" getter="property-manager/getRelatedPaymentMethod" setter="property-manager/setRelatedPaymentMethod" ref="form" type="payment-method" />

                <p> {{$t('have_question')}}

                    <span class="link" @click="displayCalendar"> {{$t('click_here')}} </span>

                </p>

            </div>

            <div v-show="calendarIsOpen">

                <p class="subtitle-option-link"> {{$t('return_payment_method')}}

                    <span class="link" @click="displayCalendar"> {{$t('click_here')}} </span>

                </p>

                <div v-show="calendarIsOpen" class="meetings-iframe-container" data-src="https://meetings.hubspot.com/victor-gambert/onboarding-30-minutes?embed=true"></div>

            </div>

        </div>

    </modal>

</template>

<script>
    import Modal from '~/components/modal'
    import SpinnerLoader from '~/components/spinner-loader'
    import { mapGetters } from 'vuex'
    
    export default {
        components: {
            Modal,
            SpinnerLoader
        },

        data() {
            return {
                loading: false,
                calendarIsOpen: false
            }
        },

        mounted() {
            let newHubspotScriptElement = document.createElement('script')

            newHubspotScriptElement.setAttribute('src', `https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js`)
            
            document.head.appendChild(newHubspotScriptElement) // Add script HTML Element to document
        },

        methods: {
            async show() {
                try {
                    
                    this.$refs.modal.show()

                    this.loading = true

                } catch(error) {

                    throw error
                
                } finally {

                    this.loading = false
                }
            },

            hide() {
                this.$refs.modal.hide()
                
                this.$emit('hide')
            },

            displayCalendar() {
                
                this.calendarIsOpen = !this.calendarIsOpen
            },

            async createPropertyManagerPaymentMethod() {
                try {

                    await this.$store.dispatch('payment-method/createPropertyManagerPaymentMethod')

                    this.$toast.success(this.$t('payment_add'), { icon: 'check' })

                } catch(error) {

                    throw error
                }
            },
            
            async fetchPropertyManagerPaymentMethod() {
                try {

                    return await this.$store.dispatch('property-manager/fetchAndFillPaymentMethodRelationship')
            
                } catch(error) {

                    throw error
                }
            },

            ...mapGetters({
                paymentMethodType: 'payment-method/getType'
            })
        },

        computed: {
            paymentMethodOptions() {
                return {
                    onSubmit: this.createPropertyManagerPaymentMethod,
                    onRefresh: this.fetchPropertyManagerPaymentMethod,
                    types: [
                        'sepa_direct_debit',
                        'card'
                    ]
                }
            },

            modalOptions() {
                return {
                    defaultOpen: true,
                    hideButtons: this.calendarIsOpen ? false : true,
                    largeOption: {
                        subtitle: this.$t('trial_period_options'),
                        icon: 'time'
                    }
                }
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "trial_period_expirate": "Votre période d'essai à expirée",
            "trial_period_options": "Veuillez ajouter un moyen de paiement pour mettre fin a votre période d'essai",
            "add_payment_method": "Ajouter un moyen de paiement ci-dessous afin de valider votre compte",
            "have_question": "Si vous souhaitez contacter le service commercial de Oskar",
            "return_payment_method": "Si vous souhaitez ajouter votre moyen de paiement",
            "click_here": "cliquez ici",
            "payment_add": "Votre moyen de paiement a bien été ajouté"
        }
    }
</i18n>

<style lang="scss">    
    .trial-period-modal {
        .subtitle-option-link {
            text-align: center;
            margin-bottom: 16px;
        }
    }
</style>
