<template>
    
    <div class="error-layout">

        <img class="img-logo" :src="logo" alt="Logo Oskar">

        <h1 class="title">{{ $t('title') }}</h1>

        <h4 class="description">{{ `${$t('message')} : ${error.message}` }}</h4>
    
        <nuxt-link to="/" class="button-group" no-prefetch>
            
            <button-input class="background-grey-darker white-text-color" :text="$t('back_home')"></button-input>

        </nuxt-link>
    
    </div>

</template>

<script>
    import ButtonInput from '~/components/inputs/button-input'
    import Logo from '~/static/img/logo/logo_oskar.svg';

    export default {
        components: {
            ButtonInput 
        },

        props: ['error'],

        data() {
            return {
                logo: Logo
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "title": "Une erreur est survenue.",
            "back_home": "Retourner sur la page d'accueil",
            "message": "Message"
        }
    }
</i18n>

<style lang="scss" scoped>
    .error-layout {
        text-align: center;
        height: 100vh;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        
        .title {
            font-size: 24pt;
            font-weight: 300;
            margin-bottom: 5px;
        }

        .description {
            font-size: 14pt;
        }

        .img-logo {
            margin-bottom: 50px;
            width: 150px;
        }

        .button-group {
            display: flex;
            justify-content: center;
            margin-top: 40px;
        }
    }
</style>
