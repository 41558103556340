<template>
    
    <div class="multi-lines-table-cell">

        <span class="line" v-for="(line, key) in lines" :key="key">
            
            {{ line.charAt(0).toUpperCase() + line.substring(1) }}

        </span>

    </div>

</template>

<script>
    export default {
        props: {
            lines: {
                type: Array,
                required: true
            }
        }
    }
</script>

<style lang="scss">
    .multi-lines-table-cell {
        display: flex;
        flex-direction: column;

        .line {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
</style>
