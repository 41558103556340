<template>

    <text-input :name="name" :label="label" v-model="iban" :mask="'SS## XXXX XXXX XXXX XXXX XXXX XXX'" :rules="rules" :placeholder="placeholder" />

</template>

<script>
    import FormGroup from '~/components/inputs/form-group'

    export default {
        components: { 
            FormGroup
        },
        
        props: {
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            value: {
                type: String,
                required: true
            },
            placeholder: {
                type: String,
                default: ''
            },
            rules: {
                type: [String, Object],
                default: ''
            }
        },

        computed: {
            iban: {
                get: function() {
                    return this.value
                },
                set: function(value) {
                    if(value.length) this.$emit('input', value)
                }
            }
        }
    }
</script>