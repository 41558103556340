<template>
    
    <form-group class="readonly-input" :label="label" :name="name" :missing="missing">

        <button-input class="button background-transparent green-text-color" :text="$t('add')" iconColor="#BED000" iconImg="edit" v-if="missing && !value" @click="$emit('add')" />

        <div class="content-text" v-else>

            <svg-icon class="icon" :iconColor="'#18C95D'" width="16" height="16" v-if="verified" ><verified-icon /></svg-icon>

            <div v-if="!hideSensitivePaymentInformations || isVisible && hideSensitivePaymentInformations" :class="['text', name, { 'active': value, 'highlight': highlight, 'secondary-color': secondaryColor, }]" :style="inputStyle" v-html="value ? value : this.default"></div>

            <div v-else-if="!isVisible && hideSensitivePaymentInformations">{{ '•••• ' + value.slice(-4) }}</div>

            <div class="view-container" @click="showSensitiveInformations"><svg-icon class="view icon img" :iconColor="isVisible ? '#8F94A0' : '#BED000'" :viewbox="'0 0 24 24'" v-if="hideSensitivePaymentInformations"><view-icon /></svg-icon></div>

        </div>

    </form-group>

</template>

<script>
    import FormGroup from '~/components/inputs/form-group'
    import SvgIcon from '~/components/svg-icon'
    import VerifiedIcon from '~/components/icons/status-icons/verified-icon'
    import ViewIcon from '~/components/icons/button-icons/view-icon'

    export default {
        components: { 
            FormGroup,
            SvgIcon,
            VerifiedIcon,
            ViewIcon
        },

        data() {
            return {
                isVisible: false
            }
        },

        props: {
            default:{
                type: String,
                default(){
                    return this.$t('unknown')
                }
            },
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            value: {
                required: true
            },
            verified: {
                type: Boolean,
                required: false
            },
            missing: {
                type: Boolean,
                default: false
            },
            highlight: {
                type: Boolean,
                default: false
            },
            inputStyle: {
                type: Object,
                required: false
            },
            secondaryColor: {
                type: Boolean,
                required: false
            },
            hideSensitivePaymentInformations: {
                type: Boolean,
                required: false
            }
        },

        methods: {
            showSensitiveInformations() {
                
                this.isVisible = !this.isVisible
            }
        },
    }
</script>

<i18n>
    {
        "fr": {
            "unknown": "Non renseigné",
            "add": "Renseigner"
        }
    }
</i18n>

<style lang="scss" scoped>
    $line-unknown-color: #8F94A0;
    $height: 40px;
    $grey: #767676;

    .readonly-input {
        width: fit-content;
        height: fit-content;
        
        &::v-deep .button-input {
            min-height: fit-content;
        }
        .button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            padding: 0;
            width: fit-content;


            &::v-deep .icon {
                width: 20px;
                height: 20px;
            }
            &::v-deep .icon-offset {
                margin: 0;
            }
            &::v-deep .text {
                text-align: center;
                font-size: 13px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%;
            }
        }

        .content-text {
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;

            .icon {
                margin-right: 12px;
            }

            .view-container {
                display: flex;
                align-items: center;
                gap: 0.4vw;
                transition: 0.3s ease-in-out;

                .view {
                    cursor: pointer; 
                    margin: 0;
                    width: 24px;
                    height: 24px;
                    transition: 0.3s ease-in-out;
                }
            }

            .text {
                text-decoration: line-through;
                color: $line-unknown-color;
                opacity: .5;
                font-size: 11pt;
                overflow: hidden;
                text-overflow: ellipsis;

                &.active {
                    text-decoration: initial;
                    color: $grey;
                    opacity: 1;
                }

                &.secondary-color {
                    color: #BED000;
                    opacity: 1;
                    text-transform: uppercase;
                    font-weight: 400;
                }
            }
        }

        &.align-right {
            .content-text {
                align-items: flex-end;
            }
        }
    }
</style>
