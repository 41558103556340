<template>
    
    <modal class="edit-property-fees-modal" :title="$t('edit_fees')" :options="modalOptions" @canceled="hide" @close="hide" ref="modal">

        <spinner-loader v-if="loading" />

        <template v-else>
    
            <step-group :name="$t('default_fees')" v-if="isDefaultFees">

                <form-info class="form-info" :text="$t('edit_default_fees')" />

                <table-list class="fullsize" :tHead="parentFeesHeaders" :tBodies="parentFeesRows" v-if="propertyAppliedFees.length"/>

                <p v-else-if="!propertyAppliedFees.length"> {{ $t('no_fees') }} </p>

            </step-group>

            <step-group :name="$t('property_fees')" :formInfo="$t('set_your_property_fees')" v-else>

                <back-to-button :label="$t('back')" :onClick="() => isDefaultFees = true" />
                
                <step-field class="fullsize" name="property_fees" :label="$t('property_fees')" :options="feeListOptions" getter="property/getRelatedFees" setter="property/setRelatedFees" type="fees-list" />

            </step-group>
                    
        </template>

    </modal>

</template>

<script>
    import Modal from '~/components/modal'
    import SpinnerLoader from '~/components/spinner-loader'
    import BackToButton from '~/components/widget/back-to-button'
    import { mapGetters } from 'vuex'
    
    export default {
        components: {
            Modal,
            SpinnerLoader,
            BackToButton
        },

        data() {
            return {
                loading: false,
                isDefaultFees: true
            }
        },

        computed: {
            ...mapGetters({
                fees: 'property/getRelatedFees',
                propertyAppliedFees: 'property/getRelatedAppliedFees',
                propertyId: 'property/getId'
            }),

            feeListOptions() {
                return {
                    onSubmit: this.createPropertyFee,
                    afterSubmit: this.refreshPropertyFees,
                    excludeOptions: this.fees.map(fee => fee.type)
                }
            },

            modalOptions() {
                if (this.isDefaultFees) return {
                    largeOption: {
                        mode: 'expanded',
                        subtitle: this.$t('fees_details'),
                        icon: 'fees'
                    },
                    submit: {
                        buttonText: this.$t('add_fees'),
                        action: () => this.switchFees(),
                        iconImg: 'add_new',
                        class: 'background-transparent green-text-color'
                    }
                }

                else return {
                    largeOption: {
                        mode: 'expanded',
                        subtitle: this.$t('fees_details'),
                        icon: 'fees'
                    },
                    submit: {
                        buttonText: this.$t('close'),
                        action: this.editFees
                    }
                }
            },

            parentFeesHeaders() {
                return [
                    {
                        label: this.$t('type'),
                        field: "type",
                        type: "text",
                        highlight: true,
                        width: 1.8
                    },
                    {
                        label: this.$t('amount'),
                        field: "amount",
                        type: "string",
                        highlight: true,
                        width: 0.8
                    },
                    {
                        label: this.$t('applies_to'),
                        field: "applies_to",
                        type: "text",
                    },
                    {
                        label: this.$t('level'),
                        field: "level",
                        type: "tag"
                    }
                ]
            },

            parentFeesRows() {
                return this.propertyAppliedFees.filter(fee => fee.amount_in_currency !== 0).map(fees => {

                    let amount = ''

                    if (typeof fees.amount_in_percent !== 'undefined') amount = `${fees.amount_in_percent} %`

                    else if (typeof fees.amount_in_currency !== 'undefined') amount = this.$n(fees.amount_in_currency / 100, 'currency')
                    
                    let applies_to = ''
                    
                    if (typeof fees.applies_to !== 'undefined' && fees.applies_to.length) applies_to = fees.applies_to.map(apply => this.$t(`rental_item_types.${apply}`)).join(', ')

                    else if (typeof fees.amount_in_percent !== 'undefined') applies_to = this.$t('rental_item_types.all')

                    let row = {
                        type: this.$t(`fees.${fees.type}`),
                        amount: amount,
                        applies_to: applies_to,
                        level: {
                            type: 'default',
                            label: this.$t(`level_options.${fees['_jv']['relationships']['level']['data']['type']}`)
                        },
                    }
                    
                    if (fees['_jv']['relationships']['level']['data']['type'] === 'property-management-companies') row.actions = {
                            delete : () => this.resetPropertyFee(fees)
                    }

                    return row
                }) 
            }
        },

        methods: {
            switchFees() {
                this.isDefaultFees = !this.isDefaultFees
            },

            async createPropertyFee() {
                try {

                    await this.$store.dispatch('fees/createPropertyFee', this.propertyId)

                    return await Promise.all([
                        this.$store.dispatch('property/fetchFeesRelationship', {
                            params: {
                                propertyId: this.propertyId
                            }
                        }),
                        this.$store.dispatch('property/fetchAppliedFeesRelationship', {
                            params: {
                                propertyId: this.propertyId
                            }
                        })
                    ])

                } catch(error) {

                    throw error
                }
            },

            async resetPropertyFee(fees) {
                try {

                    this.loading = true

                    let copyFee = {...fees}

                    copyFee.amount_in_currency = 0

                    copyFee.amount_in_percent = 0

                    await this.$store.dispatch('fees/fillFee', copyFee)

                    await this.createPropertyFee()

                } catch(error) {
                    
                    throw error

                } finally {

                    this.loading = false
                }
            },

            async refreshPropertyFees() {
                try {

                    return await Promise.all([
                        this.$store.dispatch('property/fetchFeesRelationship', {
                            params: {
                                propertyId: this.propertyId
                            }
                        }),
                        this.$store.dispatch('property/fetchAppliedFeesRelationship', {
                            params: {
                                propertyId: this.propertyId
                            }
                        })
                    ])
                
                } catch(error) {
                    
                    throw error
                }
            },

            async show() {
                try {

                    this.loading = true

                    this.isDefaultFees = true

                    this.$refs.modal.show()

                    await Promise.all([
                        this.$store.dispatch('property/fetchFeesRelationship', {
                            params: {
                                propertyId: this.propertyId
                            }
                        }),
                        this.$store.dispatch('property/fetchAppliedFeesRelationship', {
                            params: {
                                propertyId: this.propertyId
                            }
                        })
                    ])
                    
                } catch(errors) {

                    this.$refs.modal.setErrors(errors)
                
                } finally {

                    this.loading = false
                }
            },

            hide() {
                this.$refs.modal.hide()

                this.$emit('closed')
            },

            async editFees() {
                try {

                    let func = async (fee) => {
                        
                        await this.$store.dispatch('fees/fillFee', fee, { root: true })

                        return await this.$store.dispatch('fees/createPropertyFee', { root: true })
                    }
                    
                    await Promise.all(this.$store.getters['property/getRelatedFees'].filter(fee => typeof fee['_jv'] === 'undefined' || typeof fee['_jv'].id === 'undefined').map(fee => func(fee)))

                    await this.$store.dispatch('property/fetchFeesRelationship', {
                        params: {
                            propertyId: this.propertyId
                        }
                    })

                    this.hide()

                    this.$toast.success(this.$t('fees_edited'), { icon: 'check' })

                } catch(errors) {

                    throw errors
                
                } finally {

                    this.$store.commit('fees/resetDefaultState')
                }
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "edit_fees": "Modification des honoraires de gestion",
            "fees_details": "Ajoutez ou supprimez vos honoraires de gestion",
            "fees_edited": "Honoraires modifiés",
            "add_fees": "Ajouter des honoraires",
            "fees": "Honoraires",
            "property_fees": "Nouveaux honoraires du bien",
            "no_fees": "Aucun honoraire prédéfini.",
            "default_fees": "Honoraires qui s'appliquent sur ce bien",
            "step_resume": "<b>{nb}</b> honoraires",
            "set_your_property_fees": "Ajouter des nouveaux honoraires qui seront uniquement appliqués à ce bien. Pour modifier un honoraire, cliquez sur la ligne.",
            "edit_default_fees": "Honoraires définis par défaut dans les paramètres de l'agence ou du propriétaire.",
            "type": "Type",
            "amount": "Montant",
            "applies_to": "S'applique sur",
            "level": "Niveau",
            "level_options": {
                "property-management-companies": "Agence",
                "properties": "Bien",
                "rentals": "Contrat de location",
                "landlords": "Propriétaire"
            },
            "back": "Retour à la liste",
            "close": "Terminer"
        }
    }
</i18n>