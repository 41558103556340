<template>
    
    <div class="property-table-cell">

        <spinner-loader v-if="loading" />

        <property-item :property="property" v-else />
    
    </div>

</template>

<script>
    import PropertyItem from '~/components/property-item'
    import SpinnerLoader from '~/components/spinner-loader'

    export default {
        components: {
            PropertyItem,
            SpinnerLoader
        },

        props: {
            propertyId: {
                type: [Number, String],
                required: true
            }
        },

        data() {
            return {
                loading: false,
                property: {}
            }
        },

        async created() {
            try {

                this.loading = true

                this.property = await this.$store.dispatch('property/fetchProperty', this.propertyId)

            } catch(error) {

                throw error

            } finally {

                this.loading = false
            }
        }
    }
</script>