<template>

    <div class="document-item" @click="redirectPath ? $router.push(redirectPath) : ''">

        <div :class="['document-icon', `document-icon-${icon}`]">

            <svg-icon viewbox="0 0 24 24" iconColor="#525254" width="24" height="24" v-if="icon === 'mandate'"><signed-icon /></svg-icon>

            <svg-icon viewbox="0 0 24 24" iconColor="#BED000" width="24" height="24" v-else-if="icon === 'rental'"><document-icon /></svg-icon>

            <svg-icon iconColor="#525254" width="32" height="32" v-else-if="icon === 'diagnose'" viewBox="0 0 32 32"><diagnose-icon /></svg-icon>

            <svg-icon iconColor="#BED000" width="15" height="17" :viewbox="'0 0 17 19'" v-else-if="icon === 'rent'"><rent-receipt-icon /></svg-icon>

            <svg-icon iconColor="#22C55E" width="32" height="32" :viewbox="'0 0 24 24'" v-else-if="icon === 'paid' || icon === 'refund' || icon === 'partial_refund'"><rent-status-icon /></svg-icon>

            <svg-icon iconColor="#EF4444" width="32" height="32" :viewbox="'0 0 24 24'" v-else-if="icon === 'unpaid'"><rent-status-icon /></svg-icon>

            <svg-icon iconColor="#F5A623" width="32" height="32" :viewbox="'0 0 24 24'" v-else-if="['upcoming', 'draft', 'open'].includes(icon) "><rent-status-icon /></svg-icon>

            <status-tag class="badge" :type="badge" :icon="'ongoing'" v-if="badge"></status-tag>
    
        </div>

        <div class="details">

            <div class="line">

                <label class="label">{{ label }}</label>

                <status-tag class="status-tag" :type="status.type" v-if="status" :icon="status.type">{{ status.label }}</status-tag>

            </div>

            <div class="description" v-if="description && description !== ''">{{ description }}</div>

        </div>

    </div>

</template>

<script>
    import SvgIcon from '~/components/svg-icon'
    import { SignedIcon, DocumentIcon, DiagnoseIcon, SignedBadgeIcon } from '~/components/icons/document-type-icons'
    import { RentStatusIcon } from '~/components/icons/rent-status-icons'
    import RentReceiptIcon from '~/components/icons/task-icons/rent-receipt-icon'
    import StatusTag from '~/components/status-tag'
    import FullNameMixin from '~/components/mixins/full-name'

    export default {
        mixins: [FullNameMixin],

        name: 'DocumentItem',

        components: {
            SvgIcon,
            SignedIcon,
            DocumentIcon,
            DiagnoseIcon,
            SignedBadgeIcon,
            RentReceiptIcon,
            StatusTag,
            RentStatusIcon,
        },

        props: {
            label: {
                type: String,
                required: true
            },
            description: {
                type: String,
                required: false
            },
            icon: {
                type: String,
                default: 'files'
            },
            redirectPath: {
                type: String,
                required: false
            },
            badge: {
                type: String,
                default: ''
            },
            status: {
                type: Object,
                required: false
            }
        }
    }
</script>

<style lang="scss">
    $icon-background: #EFEFEF;
    $date-color: #8F94A0;
    $border-color: #F2F2F2;
    $hover-background: #F1F6FF;

    .document-item {
        display: flex;

        & ~ & {
            margin-top: 24px;
        }

        .document-icon {
            position: relative;
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            background: $icon-background;
            border-radius: 6px;
            margin-right: 12px;

            &.document-icon-paid, 
            &.document-icon-refund {
                background: rgba(34, 197, 94, 0.2);

            }

            &.document-icon-unpaid {
                background: rgba(239, 68, 68, 0.2);

            }

            &.document-icon-open,
            &.document-icon-draft,
            &.document-icon-upcoming,
            &.document-icon-partial_refund,
            &.document-icon-payments {
                background: rgba(245, 166, 35, 0.2);

            }

            .badge {
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(50%, -50%);
                border: 2px solid white;
                border-radius: 100%;
                width: 25px;
                height: 25px;
                box-sizing: border-box;

                .tag {
                    padding: 3px;

                    .icon {
                        margin-right: 0 !important;
                    }
                }
            }
        }

        .details {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            min-width: 0;

            .line {
                display: flex;
                flex-direction: row;
                min-width: 0;

                .label {
                    @include body;
                    margin-bottom: 4px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-weight: 500;
                    text-transform: uppercase;
                }

                .status-tag {
                    margin-left: 8px;
                    align-items: flex-start;
                    white-space: nowrap;
                }
            }

            .description {
                flex-basis: 100%;
                font-size: 10.5pt;
                color: $date-color;
                // margin-top: 5px;
            }
        }
    }
</style>
