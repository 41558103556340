<template>

    <button-group-footer class="workflow-button-group">

        <template v-slot:left-panel>

            <button-input :class="['item', 'background-grey-dark', 'white-text-color', { 'active': completedSteps.length }]" :text="$t('save_and_quit')" @click="$emit('saveAndQuit')" v-if="dumpName" v-show="completedSteps.length  && isCreateMode" />

        </template>

        <template v-slot:right-panel>

            <div class="file-upload" v-if="isExternalFile">

                <button-input class="button background-transparent green-text-color"

                    iconImg="upload"

                    :text="externalFileTypes[0] ? $t(`upload_agreement_${externalFileTypes[0]}`) : $t('upload_agreement')"

                    @click="$refs.externalFile.browseFile()"

                    v-if="!externalFileName && !externalFileErrors.length" />

                <div :class="['external-file', { 'errors': externalFileErrors.length }]" v-else>

                    <svg-icon class="icon" iconColor="#393939" width="16" height="16" :viewbox="'0 0 15 20'"><document-icon /></svg-icon>

                    <span class="file-name" v-if="externalFileErrors.length">{{ $t('invalid') }} : {{ externalFileErrors[0] }}</span>

                    <spinner-loader v-else-if="externalFileLoader" />

                    <span class="file-name" v-else>{{ externalFileName }}</span>

                    <!-- <button-input class="small button" :text="$t('see')" /> -->

                    <button-input class="small button quit" @click="resetExternalFile" />

                </div>

                <file-upload-input :label="$t('external_file')" name="external_file" v-model="externalFile" v-show="false" @input="setExternalFileName" @error="setExternalFileErrors" :extensions="['jpeg','jpg','png','pdf']" ref="externalFile" />

            </div>

            <button-input class="item background-success success-text-color" :disabled="!complete || loading || externalFileLoader" :loading="complete && loading" :text="$t('complete')" @click="next()" />

        </template>

    </button-group-footer>

</template>

<script>
    import SvgIcon from '~/components/svg-icon'
    import { DocumentIcon } from '~/components/icons/button-icons'
    import SpinnerLoader from '~/components/spinner-loader.vue'
    import ButtonGroupFooter from '~/components/navigation/footer/button-group-footer'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            SvgIcon,
            DocumentIcon,
            SpinnerLoader,
            ButtonGroupFooter
        },

        data() {
            return {
                externalFileErrors: [],
                externalFileLoader: false
            }
        },

        computed: {
            externalFile: {
                get: function() {
                    return this.$store.getters['workflow/getExternalFileFormData']
                },
                set: function(newValue) {
                    this.$store.commit('workflow/setExternalFileFormData', newValue)
                }
            },

            externalFileName: {
                get: function() {
                    return this.$store.getters['workflow/getExternalFileName']
                },
                set: function(fileName) {
                    this.$store.commit('workflow/setExternalFileName', fileName)
                }
            },

            ...mapGetters({
                complete: 'workflow/isStepsCompleted',
                loading: 'workflow/isLoading',
                isExternalFile: 'workflow/isExternalFileActive',
                completedSteps: 'workflow/getCompletedSteps',
                externalFileTypes: 'workflow/getExternalFileTypes',
                dumpName: 'workflow/getDumpName',
                isCreateMode: 'workflow/isCreateMode',
                getAuthUser: 'getAuthUser'
            })
        },

        watch: {
            externalFile: {
                immediate: true,
                async handler(externalFile) {
                    try {

                        this.externalFileLoader = true

                        if (externalFile instanceof FormData) {

                            await this.$store.dispatch('workflow/uploadExternalFile')
                        }

                    } catch(errors) {

                        let fileErrorPointers = ['types', 'file']

                        this.resetExternalFile()

                        // this.externalFileErrors = this.validationErrors.filter(error => fileErrorPointers.includes(error.source.pointer)).map(error => error.detail)

                        // if (!this.externalFileErrors.length) this.externalFileErrors = errors.map(error => error.detail)

                    } finally {

                        this.externalFileLoader = false
                    }
                }
            }
        },

        methods: {
            async next(){
                const onboardingStep = {
                    onboarding_step: 5
                }

                await this.$axios({ method: 'put', url: `/fapi/onboardings/${this.getAuthUser['_jv'].id}`, data: onboardingStep })

                this.$emit('onComplete')
            },

            setExternalFileName(formData) {
                if (formData instanceof FormData) {
                    this.externalFileName = formData.get('file').name
                }
            },

            resetExternalFile() {
                this.$refs.externalFile.resetFile()

                this.externalFileErrors = []

                this.$store.commit('workflow/setExternalFile', '')

                this.externalFileName = ''

                this.externalFile = ''
            },

            setExternalFileErrors(errors) {
                this.externalFileErrors = errors
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "save_and_quit": "Enregistrer et continuer plus tard",
            "complete": "Enregistrer",
            "upload_agreement": "Importer un contrat",
            "upload_agreement_inventory": "Importer un état des lieux",
            "upload_agreement_mandate": "Importer un mandat",
            "upload_agreement_cost": "Importer une charge",
            "upload_agreement_lease_agreement": "Importer un contrat de location",
            "see": "Ouvrir",
            "invalid": "Erreur"
        }
    }
</i18n>

<style lang="scss">
    $error-text: rgba(228,63,63,0.6);
    $background-error-text: rgba(228,63,63,0.12);

    .workflow-button-group {
        .item {
            margin-right: 30px;
            padding-left: 70px;
            padding-right: 70px;

            &:first-child {
                margin-right: auto;
            }

            &:last-child {
                margin-right: 0;
            }

            &.save:not(.active) {
                opacity: 0;
            }
        }

        .file-upload {
            margin-right: 30px;

            .navigate {
                padding-right: 15px;
            }

            .external-file {
                display: flex;
                align-items: center;
                border: 1px solid #F2F2F2;
                border-radius: 10px;
                padding: 2px 12px;
                height: 44px;
                box-sizing: border-box;

                .icon {
                    margin-right: 9px;
                }

                .file-name {
                    margin-right: 23px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 230px;
                }

                &.errors {
                    border-color: $error-text;
                    background: $background-error-text;

                    .file-name {
                        color: $error-text;
                    }
                }

                .button {
                    box-sizing: border-box;
                    min-height: 26px;
                    border-radius: 5px;
                    font-size: 12px;

                    & ~ .button {
                        margin-left: 8px;
                    }

                    &.quit {
                        width: 26px;

                        &:before,
                        &:after {
                            position: absolute;
                            content: '';
                            display: flex;
                            background: #393939;
                            height: 1px;
                            width: 7px;
                        }

                        &:before {
                            transform: rotate(45deg);
                        }

                        &:after {
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
        }
    }
</style>
