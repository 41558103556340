import axios from "axios";

export default class {


    static deleteDump(id) {
        return axios.delete(`/proxy/fapi/vuex-store-dumps/${id}`);
    }


    static getDump(id) {
        return axios.get(`/proxy/fapi/vuex-store-dumps/${id}`);
    }

    static postDump(userId, name, data) {
        const _data = {
            user_id  : userId,
            dump_name: `oskar-dump-${name}`,
            dump_type: name,
            dump     : JSON.stringify(data),
        };

        return axios.post(`/proxy/fapi/vuex-store-dumps`, _data);
    }
}
