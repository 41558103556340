<template>

    <div class="update-layout">

        <courses-navigation-bar-header :title="$t('oskar_courses')" :subtitle="userName" @exit="redirectBack"/>

        <section class="layout-row content">

            <div class="layout-col steps">
                
                <nuxt class="container" ref="page" />

            </div>

        </section>

    </div>

</template>

<script>
    import CoursesNavigationBarHeader from '~/components/navigation/header/courses-navigation-bar-header.vue'
    import { mapGetters } from 'vuex'

    export default {    

        middleware: ['reset-store', 'fetch-user'],
        
        components: {
            CoursesNavigationBarHeader
        },

        methods: {
            redirectBack() {
                this.$router.push('/dashboard')
            }
        },

        computed: {
            ...mapGetters({
                userName: 'user/getFullName',
            })
        }
    }
</script>

<i18n>
    {
        "fr": {
            "oskar_courses": "Formations Oskar"
        }
    }
</i18n>

<style lang="scss" scoped>
    $background: #EFEFEF;
    $button-group-height: 68px;
    $button-group-margin: 60px;
    $color-black: #0E0A07;

    .update-layout {
        background: $background;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100vh;

        .content {
            display: flex;
            flex-direction: row;
            flex: 1;
            margin-top: 40px;
            margin-bottom: 40px;
            padding-left: 30px;
            padding-right: 30px;

            .sidebar {
                max-width: 240px;
                flex-shrink: 1;
            }

            .steps {
                align-items: center;

                &::v-deep .step-form {
                    display: block;
                    box-shadow: 0 3px 18px 0 #E0E6F6;
                    border-radius: 10px;
                    background: white;
                    color: $color-black;
                    margin-bottom: 10px;

                    &.active {
                        margin-bottom: 35px;
                        margin-top: 25px;
                
                        .step-content {
                            padding: 80px;
                            padding-top: 56px;

                            .form-active {
                                transform-origin: top;
                                display: flex;
                                flex-direction: column;
                            }

                            .header {
                                margin-bottom: 34px;
                            }
                        }
                    }

                    &:first-child {
                        margin-top: 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &:not(.active) {
                        box-shadow: 0 3px 18px 0 #E0E6F6, 0 3px 0 0 #DADCF0;
                    }

                    .step-content {
                        display: flex;
                        flex-direction: column;
                        padding: 40px 80px;

                        .form-active {
                            transform-origin: top;
                        }

                        .header {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            .title-group {
                                display: flex;
                                align-items: center;
                                flex: 1;

                                .title {
                                    font-size: 24px;
                                    cursor: pointer;
                                }

                                .status-tag {
                                    margin-left: 13px;
                                }
                            }
                        }
                    }

                    .button-group {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        border-top: 1px solid #F2F2F2;
                        padding: 40px 80px;
                        margin-top: -32px;

                        .button.next,
                        .button.save {
                            padding-left: 60px;
                            padding-right: 60px;
                        }
                    }
                }
            }
        }

        .update-button-group {
            display: flex;
            align-items: center;
            min-height: $button-group-height;
            position: fixed;
            bottom: 0;
            right: 0;
            left: 0;
        }
    }
</style>
