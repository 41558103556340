import { utils } from 'jsonapi-vuex'

export const strict = false

export const state = () => {
    return {
        ip_address: '',
        user_agent: '',
        auth_profile: {},
        demo_period_is_expirate: false,
        burger_menu_is_open: false
    }
}

export const mutations = {
    setIpAddress(state, value) {
        state.ip_address = value
    },

    setUserAgent(state, value) {
        state.user_agent = value
    },

    setAuthProfile(state, value) {
        state.auth_profile = value
    },

    setDemoPeriodIsExpirate(state, value) {
        state.demo_period_is_expirate = value
    },

    setBurgerMenuIsOpen(state, value) {
        state.burger_menu_is_open = value
    }
}

export const getters = {
    getIpAddress(state) {
        return state.ip_address
    },

    getUserAgent(state) {
        return state.user_agent
    },

    getAuthUser(state, getters, rootState) {
        if (typeof rootState.auth === 'undefined') return {}

        return rootState.auth.user ? utils.jsonapiToNorm(rootState.auth.user.data) : {}
    },

    getAuthProfile(state) {
        return state.auth_profile
    },

    getDemoPeriodIsExpirate(state) {
        return state.demo_period_is_expirate
    },

    getBurgerMenuIsOpen(state) {
        return state.burger_menu_is_open
    },

    isCustomer(state, getters) {
        if (!Object.keys(getters['getAuthUser']).length || typeof getters['getAuthUser']['_jv'] === 'undefined') {
            return false
        }

        return !(getters['getAuthUser']['_jv']['relationships']['property_management_companies']['data'].length > 0)
    },

    isManager(state, getters) {
        if (!Object.keys(getters['getAuthUser']).length || typeof getters['getAuthUser']['_jv'] === 'undefined') {
            return false
        }

        return getters['getAuthUser']['_jv']['relationships']['property_management_companies']['data'].length > 0
    },

    isAuthLandlord(state, getters) {
        if (!Object.keys(getters['getAuthProfile']).length) return false

        return getters['isCustomer'] && getters['getAuthProfile']['_jv'].type === 'landlords'
    },

    isAuthTenant(state, getters) {
        if (!Object.keys(getters['getAuthProfile']).length) return false

        return getters['isCustomer'] && getters['getAuthProfile']['_jv'].type === 'tenants'
    },

    isAuthenticated(state, getters, rootState) {
        if (typeof rootState.auth === 'undefined') return false

        return !!rootState.auth.loggedIn
    }
}

export const actions = {
    async signout({ dispatch }) {
        try {

            await this.$axios({ method: 'post', url: 'signout' })

        } catch(error) {

            throw error
        }
    }
}


