<template>
    
    <div class="pagination margin-widget" :style="paginationStyle">

        <div class="container">
        
            <div :class="['right', {'disabled': totalPages === 1 || page < 2}]" @click="setPage(totalPages === 1 || page < 2 ? page : page - 1)">
                
                <svg-icon class="icon" :iconColor="'#525254'" width="15" height="15" :viewbox="'0 0 15 12'"><left-arrow-icon /></svg-icon>

            </div>

            <div class="page">

                <span :class="['page-numbers', {'active': page === option.value}, {'disabled': option.value === '...'}]" v-for="(option, key) in pageList" :key="key" @click.stop="setPage(option.value)">{{ option.value }}</span>
                
            </div>

            <div :class="['right', {'disabled': totalPages === page}]" @click="setPage(totalPages === page ? page : page + 1)">
                
                <svg-icon class="icon" :iconColor="'#525254'" width="15" height="15" :viewbox="'0 0 15 12'"><right-arrow-icon /></svg-icon>

            </div>

        </div>

    </div>

</template>

<script>
    import SvgIcon from '~/components/svg-icon'
    import { LeftArrowIcon, RightArrowIcon } from '~/components/icons'
    import DropDown from '~/components/drop-down'

    export default {
        
        name: 'Pagination',
        
        components: {
            DropDown,
            SvgIcon,
            LeftArrowIcon,
            RightArrowIcon
        },

        data() {
            return {
                pageList: []
            }
        },

        props: {
            page: {
                type: Number,
                default: 1
            },
            totalPages: {
                type: Number,
                default: 1
            }
        },

        mounted() {
            this.$nextTick(() => this.setPage(this.page))
        },

        methods: {
            async setPage(page) {

                if (isNaN(page)) {
                    return;
                }

                this.$emit('updatePage', page)

                const totalPages = this.totalPages 

                const visiblePages = 5 

                let pageList = []

                if (totalPages <= visiblePages) {

                    pageList = Array.from(Array(totalPages), (_, i) => {
                        return {
                            value: i + 1
                        }
                    })

                } else {

                    if (page <= Math.ceil(visiblePages / 2)) {

                        pageList = Array.from(Array(visiblePages), (_, i) => {
                            return {
                                value: i + 1
                            }
                        })

                        pageList.push({ value: '...' })

                        pageList.push({ value: totalPages })

                    } else if (page >= totalPages - Math.floor(visiblePages / 2)) {

                        pageList.push({ value: 1 })

                        pageList.push({ value: '...' })

                        pageList = pageList.concat(
                            Array.from(Array(visiblePages - 1), (_, i) => {
                                return {
                                    value: totalPages - visiblePages + 2 + i
                                }
                            })
                        )

                    } else {

                        pageList.push({ value: 1 })

                        pageList.push({ value: '...' })

                        pageList = pageList.concat(
                            Array.from(Array(visiblePages - 2), (_, i) => {
                                return {
                                    value: page - Math.floor(visiblePages / 2) + i + 1
                                }
                            })
                        )

                        pageList.push({ value: '...' })

                        pageList.push({ value: totalPages })
                    }
                }

                this.pageList = pageList
            }
        },

        computed: {
            paginationStyle() {
                let marginBottom = ((this.totalPages - 1) * 44) / 2

                return { 
                    'margin-bottom': `calc(${marginBottom}px)`
                }
            }
        }
    }
</script>

<style lang="scss">
    $pagination-color: #525254;
    
    .pagination {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        
        .container {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: fit-content;
            height: 44px;
            border-radius: 28px;
            box-sizing: border-box;
        }

        .left, 
        .right {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 45px;
            height: 100%;
            cursor: pointer;
            
            &.disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }
        }

        .left {
            border-right: 1px solid #F2F2F2;
        }

        .right {
            border-left: 1px solid #F2F2F2;
        }

        .page {
            font-weight: bold;
            font-size: 10.5pt;
            height: 100%;
            color: $pagination-color;
            text-transform: uppercase;
            border-radius: 4px;
            display: flex;
            justify-content: flex-start;
            gap: 4px;
            align-items: center;
            flex: 1;
            position: relative;
            cursor: pointer;

            .page-numbers {
                display: inline-block;
                margin: 2px;
                padding: 10px 15px;
                background: white;
                text-decoration: none;
                color: #333;
                border-radius: 50%;
                line-height: 1;

                &.active {
                    background: #525254;
                    color: white;
                    line-height: 1;
                    border-radius: 50%;
                }

                &.disabled {
                    cursor: not-allowed;
                    opacity: 0.8;
                }
            }
        }
    }
</style>

<i18n>
    {
        "fr": {
            "page": "{page} sur {total}"
        }
    }
</i18n>
