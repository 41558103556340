<template>

    <Transition name="nested-form" v-if="open">

        <form-group class="step-nested-form" name="step_nested_form" :rules="'step_nested_form'" v-slot="{ errors }" ref="group">

            <input :value="false" v-show="false" />

            <form-input :class="['form', { 'errors': errors.length }]" :onSubmit="transformedSubmitAction" scope="form-1" v-slot="{ validate, reset, invalid }" ref="formInput">

                <svg-icon class="close-icon" :viewbox="'0 0 32 32'" :width="'32'" :height="'32'" @click.native="$emit('close', true)" ><close-icon /></svg-icon>

                <step-group :name="stepGroupName" :icon="icon">
                    <slot v-bind:fields="fields" />

                    <div :class="['button-group-items fullsize', { 'add-new-disabled': !addNewButton }, { 'disabled': disabledButton }]" v-if="onSubmit">

                        <button-input class="background-transparent green-text-color add-new" iconImg="add_new" :iconColor="invalid ? '#8D939F' : ''" :disabledText="$t('add_new_item_disabled_text')" :text="$t('save_add_new_item', { title: title.toLowerCase() })" :disabled="invalid || submitLoading" :textStyle="{ 'align-items': 'flex-start' }" @click="submitAndNewItem(validate, reset)" v-if="addNewButton && !updateMode && itemCount < limit - 1" />

                        <button-input class="background-success grey-darker-text-color small-padding" :text="defaultOnSubmitButtonText" :loading="submitLoading" type="submit" />

                        <button-input class="background-delete-light delete-text-color small-padding" :text="$t('delete')" @click="deleteAction" :loading="deleteLoading" v-if="updateMode && isDeleteToogle && isManager" />

                    </div>

                </step-group>

            </form-input>

        </form-group>

    </Transition>

</template>

<script>
    import StepGroup from '~/components/workflow/step-group'
    import FormGroup from '~/components/inputs/form-group'
    import { CardIcon } from '~/components/icons/bank-icons'
    import { CloseIcon } from '~/components/icons/button-icons'
    import SvgIcon from '~/components/svg-icon'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            StepGroup,
            CloseIcon,
            SvgIcon,
            FormGroup,
            CardIcon
        },

        props: {
            title: {
                type: String,
                default: () => this.$t('new_item')
            },
            fields: {
                type: Object,
                default: () => {}
            },
            itemCount: {
                type: Number,
                required: false
            },
            isDeleteToogle: {
                type: Boolean,
                default: true
            },
            onSubmit: {
                type: Function,
                required: false
            },
            onDelete: {
                type: Function,
                default() {
                    return new Function
                }
            },
            updateMode: {
                type: Boolean,
                default: false
            },
            addNewButton: {
                type: Boolean,
                default: true
            },
            limit: {
                type: Number,
                default: Infinity
            },
            open: {
                type: Boolean,
                default: true
            },
            icon: {
                type: String,
                default: ''
            },
            onSubmitButtonText: {
                type: String,
                default: ''
            },
            disabledButton: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                submitLoading: false,
                deleteLoading: false
            }
        },

        methods: {
            async submitAndNewItem(validate, reset) {
                try {

                    const isValid = await validate()

                    if (isValid) {

                        await this.transformedSubmitAction(false)

                        this.$scrollTo('.step-nested-form')

                        reset()
                    }

                } catch(error) {

                    throw error
                }
            },

            async transformedSubmitAction(close) {
                try {

                    this.submitLoading = true

                    if (typeof this.onSubmit !== 'undefined') await this.onSubmit.call()

                    this.$emit('close', typeof close !== 'undefined' && !close ? false : true)

                } catch(error) {

                    throw error

                } finally {

                    this.submitLoading = false
                }
            },

            async deleteAction() {
                try {

                    this.deleteLoading = true

                    await this.onDelete.call()

                    this.$emit('close', true)

                } catch(error) {

                    throw error

                } finally {

                    this.deleteLoading = false
                }
            }
        },

        computed: {
            stepGroupName() {
                if (typeof this.itemCount !== 'undefined') {

                   return this.updateMode ? `${this.$t('update_line')} ${this.itemCount + 1}` : `${this.title} ${this.itemCount + 1}`
                }

                return this.updateMode ? `${this.$t('update_line')}` : `${this.title}`
            },

            defaultOnSubmitButtonText() {
                if (this.onSubmitButtonText !== '') return this.onSubmitButtonText
                
                return this.updateMode ? this.$t('update') : this.$t('save')
            },
            
            ...mapGetters({
                isManager: 'isManager',
            })
        },

    }
</script>

<i18n>
    {
        "fr": {
            "update_line": "Modification de l'élément",
            "new_item": "Ajouter un nouveau",
            "add_new_item_disabled_text": "Terminer l'ajout de l'élément avant d'en ajouter un nouveau",
            "save_add_new_item": "Enregistrer et {title}",
            "update": "Modifier",
            "save": "Enregistrer",
            "delete": "Supprimer"
        }
    }
</i18n>

<style lang="scss" scoped>
    .step-nested-form {
        transform-origin: top;
        width: 100%;

        &.nested-form-enter-active {
            animation: scaleY-anim .2s ease-in-out;
        }

        &.nested-form-leave-active {
            animation: scaleY-anim .2s reverse ease-in-out;
        }

        .form {
            position: relative;
            border: 1px solid #DEE3EF;
            border-radius: 6px;
            padding: 27px;

            .close-icon {
                position: absolute;
                right: 27px;
                top: 23px;
                z-index: 1;

                &:hover {
                    cursor: pointer;
                }
            }

            .button-group-items {
                border-top: 1px solid #F2F2F2;
                padding-top: 28px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;

                &.add-new-disabled {
                    padding-top: 0;
                    border: 0;
                }

                .add-new {
                    margin-bottom: 28px;
                    flex-basis: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding-left: 0;

                    &.disabled {
                        background: transparent !important;
                        color: #8D939F !important;
                    }
                }

                &.disabled {
                    cursor: not-allowed;

                    > button {
                        pointer-events: none;
                        color: #8D939F !important;
                        background: transparent !important;
                    }
                }
            }

            &.errors {
                border: 2px solid #F5A623;
            }
        }
    }
</style>
