<template>

    <div class="address-input">

        <address-finder
            class="address-finder"
            :label="label"
            :name="name"
            :placeholder="placeholder"
            :value="value"
            @input="updateAddress"
            :rules="rules"
            :mapDisplay="'mapDisplay' in options && options.mapDisplay"
            :countryOption="options.countryOptions ? options.countryOptions : []"
            @manual="toggleEditMode"
            ref="address_finder"
            v-show="!editMode"
        />

        <step-nested-form :title="$t('manual_address')" :onSubmit="updateAddress" :addNewButton="false" @close="toggleEditMode" :open="editMode" :fields="manualAddress" v-if="editMode">

            <template v-slot="{ fields }">

                <text-input :label="$t('house_number')" name="house_number" :placeholder="$t('house_number_placeholder')" :value="fields.house_number" @input="manualAddress.house_number = $event" :rules="'required'" />

                <text-input :label="$t('street_name')" name="street_name" :placeholder="$t('street_name_placeholder')" :value="fields.street_name" @input="manualAddress.street_name = $event" :rules="'required'" />

                <text-input :label="$t('postal_code')" name="postal_code" :placeholder="$t('postal_code_placeholder')" :value="fields.postal_code" @input="manualAddress.postal_code = $event" :rules="'required'" />

                <text-input :label="$t('city')" name="city" :placeholder="$t('city_placeholder')" :value="fields.city" @input="manualAddress.city = $event" :rules="'required'" />

                <select-box-input
                    :label="$t('country')"
                    name="country"
                    :value="fields.country"
                    @input="manualAddress.country = $event"
                    :options="options.manualCountryOptions ? options.manualCountryOptions : countryOptions"
                    :rules="'required'"
                />

            </template>

        </step-nested-form>

        <step-group class="fullsize address-details" v-if="isAddressDetails">

            <number-input :label="$t('floor_number')" name="floor_number" :metric="$t('floor')" v-model="addressDetails.floor_number" @input="emitAddressDetailsIfAddressCompleted" :min="0" />

            <text-input :label="$t('unit_number')" name="unit_number" v-model="addressDetails.unit_number" :placeholder="$t('unit_number_placeholder')" @input="emitAddressDetailsIfAddressCompleted" />

            <text-input class="fullsize" :label="$t('additional_information')" name="additional_information" v-model="addressDetails.line_2" :placeholder="$t('additional_information_placeholder')" :rules="'max:255'" @input="emitAddressDetailsIfAddressCompleted" />

        </step-group>

    </div>

</template>

<script>
    import FormGroup from '~/components/inputs/form-group'
    import AddressFinder from '~/components/inputs-group/address-finder'
    import FormInfo from '~/components/form-info'
    import StepGroup from '~/components/workflow/step-group'

    export default {
        components: {
            FormGroup,
            AddressFinder,
            FormInfo,
            StepGroup
        },

        props: {
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            placeholder: {
                type: String,
                required: false
            },
            value: {
                type: Object,
                required: true
            },
            rules: {
                type: [String, Object],
                default: ''
            },
            options: {
                type: Object,
                required: false,
                default() {
                    return new Object
                }
            }
        },

        async fetch() {
            try {

                const response = await fetch('https://restcountries.com/v2/all')

                this.countries = await response.json()

            } catch (error) {

                error(error)
            }
        },

        data() {
            return {
                editMode: false,
                manualAddress: {},
                addressDetails: {
                    floor_number: 0,
                    line_2: '',
                    unit_number: ''
                },
                countries: []
            }
        },

        methods: {
            toggleEditMode() {

                this.editMode = !this.editMode
            },

            updateAddress(newVal) {

                if (typeof newVal !== 'undefined' && !Object.keys(newVal).length) return this.$emit('input', {})

                let address = Object.assign({...this.manualAddress}, newVal)

                if (this.isAddressDetails) address = Object.assign(address, this.cleanAddressDetails)

                if (this.editMode) Object.assign(address, {
                    formatted: `${this.manualAddress.house_number} ${this.manualAddress.street_name}, ${this.manualAddress.postal_code} ${this.manualAddress.city} ${this.manualAddress.country}`
                })

                if (typeof address.house_number !== undefined && !address.house_number) delete address.house_number

                if (typeof address.line_1 !== undefined && !address.line_1) delete address.line_1

                if (typeof address.line_2 !== undefined && !address.line_2) delete address.line_2

                if (typeof address.state !== undefined && !address.state) delete address.state

                if (typeof address.unit_number !== undefined && !address.unit_number) delete address.unit_number

                this.$emit('input', address)
            },

            emitAddressDetailsIfAddressCompleted() {

                if (Object.keys(this.value).length) {

                    this.$emit('input', Object.assign({...this.value}, this.cleanAddressDetails))
                }
            }
        },

        computed: {
            isAddressDetails() {
                return 'details' in this.options && this.options.details
            },

            cleanAddressDetails() {
                let addressDetails = {}

                if (this.addressDetails.floor_number !== 0) addressDetails['floor_number'] = String(this.addressDetails.floor_number)

                if (this.addressDetails.line_2 !== '') addressDetails['line_2'] = this.addressDetails.line_2

                if (this.addressDetails.unit_number !== '') addressDetails['unit_number'] = this.addressDetails.unit_number

                return addressDetails
            },

            countryOptions() {
                return this.countries.map(country => {
                    return {
                        value: country.alpha2Code,
                        text: country.translations.fr
                    }
                })
            }
        },

        watch: {
            value: {
                immediate: true,
                handler(newVal) {

                    if (typeof newVal.floor_number !== 'undefined') this.addressDetails.floor_number = Number(newVal.floor_number)

                    if (typeof newVal.line_2 !== 'undefined') this.addressDetails.line_2 = newVal.line_2

                    if (typeof newVal.unit_number !== 'undefined') this.addressDetails.unit_number = newVal.unit_number

                    this.manualAddress = {
                        city: '',
                        country: '',
                        formatted: '',
                        house_number: '',
                        postal_code: '',
                        street_name: ''
                    }
                }
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "address": "Adresse principale",
            "street_name": "Nom de la rue",
            "house_number": "Numéro de la rue",
            "postal_code": "Code postal",
            "city": "Ville",
            "state": "Département / État",
            "state_placeholder": "Île-de-France",
            "country": "Pays",
            "floor_number": "Étage",
            "write_auto_address": "Si vous souhaitez rechercher votre adresse",
            "floor": "étage",
            "info_floor_number": "Étage : ",
            "house_number_placeholder": "12",
            "street_name_placeholder": "boulevard de Magenta",
            "postal_code_placeholder": "75010",
            "city_placeholder": "Paris",
            "cancel_manual_search": "Annuler la saisie manuelle",
            "write_auto_address": "Pour retourner à la rechercher d'adresse automatique",
            "unit_number": "Numéro de palier",
            "unit_number_placeholder": "Numéro 21",
            "additional_information": "Informations complémentaires",
            "additional_information_placeholder": "Bâtiment B",
            "manual_address": "Saisie manuelle de l'adresse"
        }
    }
</i18n>

<style lang="scss" scoped>
    .address-input {
        .button {
            margin-top: 15px;
            width: 250px;
        }

        .address-details {
            padding-top: 30px;
        }
    }
</style>
