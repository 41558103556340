<template>

    <div class="dropdown-item" @click="selectOption(allInfo)">

        <svg-icon class="dropdown-icon" width="40" height="40" viewbox="0 0 21 21" iconColor="#BED000" v-if="iconImg === 'properties'"><property-icon /></svg-icon>

        <svg-icon class="dropdown-icon" width="40" height="40" :viewbox="'0 0 21 20'" v-if="iconImg === 'landlords'"><landlord-icon /></svg-icon>

        <svg-icon class="dropdown-icon" iconColor="#BED000" width="40" height="40" :viewbox="'0 0 24 24'" v-if="iconImg === 'tenants'"><tenant-icon /></svg-icon>

        <svg-icon class="dropdown-icon" width="40" height="40" :viewbox="'0 0 21 20'" iconColor="#BED000" v-if="iconImg === 'suppliers'"><supplier-icon /></svg-icon>

        <div class="item-infos">

            <div class="main-info"> {{ this.mainInfo }} </div>

            <span class="second-info" v-if="secondInfo" v-html="secondInfo"></span>

        </div>

    </div>

</template>

<script>
    import SvgIcon from '~/components/svg-icon'
    import { PropertyIcon, LandlordIcon, TenantIcon, SupplierIcon } from '~/components/icons/table-cell-icons'

    export default {
        components: {
            SvgIcon,
            PropertyIcon,
            LandlordIcon,
            TenantIcon,
            SupplierIcon
        },

        props:{
            iconImg: {
                type: String,
                required: false
            },
            mainInfo: {
                type: String,
                required: false
            },
            allInfo: {
                type: Object,
                required: false
            },
            secondInfo: {
                type: String,
                required: false
            }
        },

        methods: {
            selectOption(allInfo) {
                if (allInfo !== undefined) this.$emit('selectOption', allInfo)
            }
        }
    }
</script>

<style lang="scss">
    .dropdown-item{
        border-radius: 6px;
        padding: 15px 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        
        &:hover{
            background: #EFEFEF;
        }

        .dropdown-icon {
            min-width: 40px;
        }

        .item-infos{
            margin-left: 20px;

            .second-info{
                color: #8F94A0;
                
                span{
                    color: #BED000;
                }
            }
        }
    }
</style>