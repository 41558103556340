<template>

    <div class="filters">

        <label class="button" @click.stop="toggleDropDown">

            {{ $t('filters') }}

            <svg-icon class="icon" :iconColor="'#26272B'" width="16" height="16" :viewbox="'0 0 24 24'"><filter-icon /></svg-icon>

        </label>

        <Transition name="drop-down">

            <div class="drop-down-filter filter-options" v-show="open" v-click-outside="() => open && toggleDropDown()">

                <div class="drop-down-content" :style="{ top: top + 'px', left: left + 'px' }">

                    <slot></slot> 

                </div>

            </div>

        </Transition>

    </div>

</template>

<script>
    import FilterIcon from '~/components/icons/button-icons/filter-icon'
    import SvgIcon from '~/components/svg-icon'

    export default {

        name: 'TableFilters',

        components: {
            FilterIcon,
            SvgIcon
        },

        props: {
            top: {
                type: Number,
                default: 40,
            },
            left: {
                type: Number,
                default: 0,
            }
        },

        data() {
            return {
                open: false
            }
        },

        methods: {
            toggleDropDown() {
                this.open = !this.open
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "filters": "Filtres"
        }
    }
</i18n>

<style lang="scss" scoped>
    .filters{
        display: flex;
        align-items: center;
        width: 60%;
        justify-content: flex-end;
        .button {
            display: flex;
            padding: 8px 12px;
            align-items: center;
            gap: 12px;
            align-self: stretch;
            border-radius: 4px;
            border: 1px solid  #E4E4E4;
            background:  #EFEFEF;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            cursor: pointer;

            .icon {
                margin-left: 20px;
            }
        }

        .drop-down-content{
            overflow: hidden;
            border-radius: 10px;
            box-shadow: 0px 3px 6px rgba(14, 10, 7, 0.12);
            padding: 15px 20px;
            font-size: inherit;
            color: inherit;
            max-height: 600px;
            position: absolute;
            background: #FFFFFF;
            z-index: 21;
            width: fit-content;
            transform-origin: top;
            right: 0;
            margin: auto;
            
        }
    }
</style>