<template>

        <div class="contract-table-cell">

            <div class="icon">

                <svg-icon iconColor="#BED000" width="24" height="24" v-if="imgPath === 'signed'"><signed-icon /></svg-icon>

                <svg-icon iconColor="#BED000" width="24" height="24" v-if="imgPath === 'document'"><document-icon /></svg-icon>

                <svg-icon iconColor="#BED000" width="24" height="24" v-if="imgPath === 'diagnose'"><diagnose-icon /></svg-icon>

                <svg-icon iconColor="#BED000" width="24" height="24" viewBox="0 0 16 17" v-if="imgPath === 'inventory'"><resource-minified-room-icon /></svg-icon>

            </div>

          <span class="label">{{ label }}</span>

        </div> 

</template>


<script>
    import SvgIcon from '~/components/svg-icon'
    import { SignedIcon, DocumentIcon, DiagnoseIcon } from '~/components/icons/document-type-icons'
    import { ResourceMinifiedRoomIcon } from '~/components/icons/resource-minified'

    export default {
        components: {
            SvgIcon,
            SignedIcon,
            DocumentIcon,
            DiagnoseIcon,
            ResourceMinifiedRoomIcon
        },

        props: {
            label: {
                type: String,
                required: true
            },
            imgPath: {
                type: String,
            }
        },
        
        data() {
            return {
                hover: false
            }
        }
    }
</script>

<style lang="scss">
    $icon-background: #EFEFEF;

    .contract-table-cell {
        display: flex;
        align-items: center;
        .icon{
            width: 48px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            background: $icon-background;
            border-radius: 6px;
            margin-right: 12px;
            cursor: pointer;
        }
    }
</style>
