import {utils} from 'jsonapi-vuex'
import {queryResponse, queryBuilder} from '~/utils/query'

function buildFormData(formData, data, parentKey) {
    try {

        if (typeof data === 'undefined' || data === null || data instanceof Date || data instanceof File) throw `error_in_argument_while_converting_object_to_formdata_${data}_${parentKey}`

        else if (typeof data === 'object') {

            Object.keys(data).forEach(key => buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key))

        } else if (typeof data === 'array') {

            data.forEach((item, key) => buildFormData(formData, item, parentKey ? `${parentKey}[${key}]` : key))

        } else {

            const value = data === null ? '' : data

            formData.append(parentKey, value)
        }

    } catch (error) {

        throw error
    }
}

export default {
    async createFile({dispatch, getters, commit}) {
        try {

            let formdata = await dispatch('getFormatFileRequest')

            let url = 'files'

            let {data} = await this.$axios({method: 'post', url: url, data: formdata})

            const file = utils.jsonapiToNorm(data['data'])

            commit('tyltoApi/addRecords', file, {root: true})

            await dispatch('fillFile', file)

            return file

        } catch (error) {

            throw error
        }
    },

    async createSignatureRequest({dispatch, getters}) {
        try {
            let signatureRequest = {
                sign_url: `${window.location.origin}/unauthenticated-signing-page`,
                _jv: {
                    type: 'signature-requests',
                    relationships: {
                        signers: getters['getSignatureRequestSigners']
                    }
                }
            }

            const signatureRequestResponse = await dispatch('tyltoApi/post', [signatureRequest, {url: 'files/' + getters['getId'] + '/signature-requests'}], {root: true})

            await dispatch('setSignatureRequest', signatureRequestResponse)

            return signatureRequestResponse

        } catch (error) {

            throw error
        }
    },

    async deleteFile({getters, dispatch}) {
        try {

            return await dispatch('tyltoApi/delete', 'files/' + getters['getId'], {root: true})

        } catch (error) {

            throw error
        }
    },

    async deleteSignatureRequest({getters, dispatch, commit}) {
        try {

            const fileId = getters['getId']

            await dispatch('tyltoApi/delete', 'signature-requests/' + getters['getSignatureRequestId'], {root: true})

            commit('tyltoApi/deleteRecord', {_jv: {type: 'files', id: fileId}}, {root: true})

            await dispatch('resetSignatureRequest')

            return await dispatch('fetchFile', fileId)

        } catch (error) {

            throw error
        }
    },

    async fetchFile({dispatch, rootGetters}, fileId) {
        return await dispatch('tyltoApi/get', 'files/' + fileId, {root: true})
    },

    async fetchAndFillFile({dispatch}, fileId) {
        try {

            let file = await dispatch('fetchFile', fileId)

            await dispatch('fillFile', file)

            return file

        } catch (error) {

            throw error
        }
    },

    async fetchSignatureRequest({dispatch, rootGetters}, signatureRequestId) {
        try {

            let signatureRequest = rootGetters['tyltoApi/get'](`signature-requests/${signatureRequestId}`)

            if (typeof signatureRequest === 'undefined' || !Object.keys(signatureRequest).length) {

                signatureRequest = await dispatch('tyltoApi/get', `signature-requests/${signatureRequestId}`, {root: true})
            }

            return signatureRequest

        } catch (error) {

            throw error
        }
    },

    async fetchSignatureRequestRelationship({getters, dispatch, commit}, fileId) {
        try {

            if (!getters['getIsSigned'] && getters['getSignatureRequestId'] !== '') {

                commit('tyltoApi/deleteRecord', { _jv: { type: 'signature-requests', id: getters['getSignatureRequestId'] } }, { root: true })
            }

            let signatureRequest = {}

            const id = fileId ? fileId : getters['getId']

            const {data} = await this.$axios({method: 'get', url: `files/${id}/relationships/signature_request`})

            if (data['data'] !== null) signatureRequest = await dispatch('file/fetchSignatureRequest', data['data']['id'], {root: true})

            if (Object.keys(signatureRequest).length) {

                await dispatch('setSignatureRequest', signatureRequest)
            }

            return signatureRequest

        } catch (error) {

            throw error
        }
    },

    async fetchSignatureRequests({dispatch}, {params, filters}) {
        try {

            const response = await dispatch('tyltoApi/get', [`property-management-companies/${params.propertyManagementCompanyId}/signature-requests`, {params: queryBuilder({filters})}], {root: true})

            return queryResponse(response)

        } catch (error) {

            throw error
        }
    },

    async fetchFilesSignatureRequest({dispatch, getters}, fileId) {
        try {

            const id = fileId ? fileId : getters['getId']

            if (id) {

                let file = await dispatch('tyltoApi/get', 'files/' + id + '/signature-requests', {root: true})

                delete file['_jv']

            }

            return file

        } catch (error) {

            throw error
        }
    },

    async fetchSignatureRequestFileRelationship({getters, dispatch, commit}, signatureRequestId) {
        try {

            let { data } = await this.$axios({ method: 'get', url: 'signature-requests/' + signatureRequestId + '/relationships/file' })

            return await dispatch('fetchFile', data['data']['id'])

        } catch (error) {

            throw error
        }
    },

    async fetchSignaturesSignatureRequest({dispatch}, signatureRequestId) {
        try {

            let signaturesSignatureRequest = await dispatch('tyltoApi/get', 'signature-requests/' + signatureRequestId + '/signatures', {root: true})

            delete signaturesSignatureRequest['_jv']

            return signaturesSignatureRequest

        } catch (error) {

            throw error
        }
    },

    async fetchSignatureRequestSignatures({getters, dispatch, commit}, params) {
        try {

            const signatureRequest = await dispatch('fetchSignatureRequestRelationship', typeof params.params.fileId !== undefined ? params.params.fileId : getters['getId'])

            let signatureRequestSignatures = []

            if (Object.keys(signatureRequest).length) {

                const { data } = await this.$axios({ method: 'get', url: `signature-requests/${signatureRequest['_jv'].id}/relationships/signatures` })

                signatureRequestSignatures = await Promise.all(data['data'].map(relationship => dispatch('tyltoApi/get', `signatures/${relationship.id}`, {root: true})))

                commit('setSignatureRequestSignatures', signatureRequestSignatures)

                return signatureRequestSignatures
            }

        } catch (error) {

            throw error
        }
    },

    async fetchSignatureSigner({dispatch, rootGetters}, signature) {
        try {

            let signatureSigner = {}

            const { data } = await this.$axios({ method: 'get', url: `signatures/${signature['_jv'].id}/relationships/signer` })

            if (data['data'] !== null) {

                if (data['data']['type'] === rootGetters['profile/getRole']) signatureSigner = await dispatch('profile/fetchLandlord', data['data']['id'], {root: true})

                else if (rootGetters['isManager']) signatureSigner = await dispatch('property-manager/fetchPropertyManagementCompany', data['data']['id'], {root: true})

                else if (data['data']['type'] === rootGetters['profile/getRole']) signatureSigner = await dispatch('profile/fetchTenant', data['data']['id'], {root: true})
            }

            return signatureSigner

        } catch (error) {

            throw error
        }
    },

    async resetSignatureRequest({commit}) {

        commit('setSignatureRequestEmbeddedUrl', '')

        commit('setSignatureRequestId', '')

        commit('setSignatureRequestStatus', '')

        commit('setSignatureRequestSignatures', [])
    },

    async setSignatureRequest({commit}, signatureRequest) {

        commit('setSignatureRequestEmbeddedUrl', typeof signatureRequest.embedded_url !== 'undefined' ? signatureRequest.embedded_url : '')

        commit('setSignatureRequestId', typeof signatureRequest['_jv'] !== 'undefined' ? signatureRequest['_jv'].id : '')

        commit('setSignatureRequestStatus', typeof signatureRequest.status !== 'undefined' ? signatureRequest.status : '')

        commit('setSignatureRequestSignatures', Object.keys(signatureRequest['_jv']['relationships']['signatures']['data']).length ? Object.values(signatureRequest['_jv']['relationships']['signatures']['data']) : [])
    },

    async markFileAsSigned({dispatch, commit}) {
        try {

            commit('setIsSigned', true)

            return await dispatch('updateFile')

        } catch (error) {

            throw error
        }
    },

    async updateFile({dispatch}) {
        try {

            let formdata = await dispatch('getFormatFileRequest')

            let url = 'files/' + this.getters['file/getId']

            let {data} = await this.$axios({method: 'post', url: url, data: formdata})

            const file = utils.jsonapiToNorm(data['data'])

            await dispatch('fillFile', file)

            return file

        } catch (error) {

            throw error
        }
    },

    async getFormatFileRequest({getters}) {
        let formData = new FormData

        if (getters['getFile'] instanceof FormData)
            formData = getters['getFile']

        else if (Object.keys(getters['getFields']).length)
            buildFormData(formData, getters['getFields'], 'fields')

        if (getters['getTypes'].length)
            buildFormData(formData, getters['getTypes'], 'types')

        if (getters['getIsSigned'])
            formData.append('is_signed', getters['getIsSigned'])

        if (getters['getDeliveryDate'])
            formData.append('delivery_date', getters['getDeliveryDate'])

        if (getters['getExpirationDate'])
            formData.append('expiration_date', getters['getExpirationDate'])

        return formData
    },

    async fillFile({commit}, file) {

        commit('resetDefaultState')

        if (typeof file['is_autogenerated'] !== 'undefined')
            commit('setIsAutomaticallyGenerated', file['is_autogenerated'])

        if (typeof file['is_signed'] !== 'undefined')
            commit('setIsSigned', file['is_signed'])

        if (typeof file['extension'] !== 'undefined')
            commit('setExtension', file['extension'])

        if (typeof file['fields'] !== 'undefined')
            commit('setFields', file['fields'])

        if (typeof file['mime_type'] !== 'undefined')
            commit('setMimeType', file['mime_type'])

        if (typeof file['name'] !== 'undefined')
            commit('setName', file['name'])

        if (typeof file['size'] !== 'undefined')
            commit('setSize', file['size'])

        if (typeof file['types'] !== 'undefined')
            commit('setTypes', file['types'])

        if (typeof file['url'] !== 'undefined')
            commit('setUrl', file['url'])

        if (typeof file['_jv'] !== 'undefined')
            commit('setId', file['_jv'].id)

        if (typeof file['delivery_date'] !== 'undefined')
            commit('setDeliveryDate', file['delivery_date'])

        if (typeof file['expiration_date'] !== 'undefined')
            commit('setExpirationDate', file['expiration_date'])
    }
}
