import defaultState from './default'

export default {
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },

    setAmountInPercent(state, value) {
        state.amount_in_percent = value
    },

    setAmountInCurrency(state, value) {
        state.amount_in_currency = value
    },

    setId(state, value) {
        state.id = value
    },

    setType(state, value) {
        state.type = value
    },

    setAppliesTo(state, value) {
        state.applies_to = value
    },

    setRelatedLevel(state, value) {
        state.relationships.level = value
    }
}