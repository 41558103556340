<template>
    <div class="duration">
        <template v-if="durationMoment.years()">
            {{ durationMoment.years() }} {{ $tc('years', durationMoment.years()) }}
        </template>
        <template v-if="durationMoment.months()">
            {{ durationMoment.months() }} {{ $tc('months', durationMoment.months()) }}
        </template>
        <template v-if="durationMoment.days() && !durationMoment.years()">
            {{ durationMoment.days() }} {{ $tc('days', durationMoment.days()) }}
        </template>
    </div>
</template>

<script>
export default {
    props: {
        duration: {
            type: Number,
            required: true
        }
    },

    computed: {
        durationMoment() {
            return this.$moment.duration(this.duration, 'days')
        }
    }
}
</script>

<i18n>
{
    "fr": {
        "years": "an | ans",
        "months": "mois",
        "days": "jour | jours"
    }
}
</i18n>