<template>
    
    <div class="onboarding-layout">

        <error-banner :title="$t('error_message')" :details="$t('contact_support')" v-if="errorMessage" />

        <div class="layout-row content">

            <div class="layout-col illustration-side">

                <div class="content">

                    <logo :width="210" />

                    <p class="description" v-html="description" v-if="description"></p>

                    <h1 class="title" v-html="title"></h1>

                    <nuxt-child class="child" />

                </div>

                <footer class="reset-password" v-if="resetPassword">

                    <p>{{ $t('forgotten_password') }} <nuxt-link class="link" :to="'/auth/reset-password'" no-prefetch>{{ $t('renew') }}</nuxt-link></p>

                </footer>

            </div>

            <div class="layout-col login-panel" :style="illustrationStyle">

                <logo class="logo-img" :whiteVersion="true" />

                <div class="content">
                            
                    <h3 class="title" v-html="$t('title')"></h3>

                </div>

                <div class="footer-credentials">

                    <div class="powered_by">

                        {{ $t('powered_by') }}

                        <img :src="lbiLogo" alt="LBI Logo" class="logo" />

                    </div>

                    <p class="credits">{{ $t('credits', { year: $moment().year() }) }}</p>

                </div>

            </div>

        </div>

    </div>

</template>

<script>
    import Logo from '~/components/logo'
    import LbiLogo from '~/static/img/logo/lbi_logo.svg';
    import OnboardingIllustration from '~/static/img/onboarding/illustration-onboarding457x1040.jpeg'
    import SvgIcon from '~/components/svg-icon'
    import ErrorBanner from '~/components/error-banner'
    import { mapGetters } from 'vuex'
    
    export default {
        components: {
            SvgIcon,
            ErrorBanner,
            Logo
        },

        data() {
            return {
                illustrationBackground: OnboardingIllustration,
                lbiLogo: LbiLogo,
                errorMessage: ''
            }
        },

        computed: {
            illustrationStyle() {
                return {
                    'background-image': `url(${this.illustrationBackground})`, 
                    'background-size': 'cover',
                    'background-position': '60% 100%'
                }
            },

            ...mapGetters({
                title: 'onboarding/getTitle',
                description: 'onboarding/getDescription',
                stepIndex: 'onboarding/getStepIndex',
                stepLast: 'onboarding/getStepLast',
                resetPassword: 'onboarding/getResetPassword'
            })
        },

        errorCaptured(error) {
            
            if (!!error.response && !!error.response.status && parseInt(error.response.status) >= 500) {
                
                this.errorMessage = error
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "error_message": "Une erreur est survenue",
            "contact_support": "Contactez le support en envoyant un e-mail à support@oskar.immo si le problème persiste.",
            "forgotten_password": "Vous avez oublié votre mot de passe Oskar ?",
            "renew": "Renouvelez-le",
            "title": "Gérez vos locations facilement avec Oskar",
            "credits": "© {year} | Tous droits réservés",
            "powered_by": "Propulsé par"
        }
    }
</i18n>

<style lang="scss" scoped>
    $background: #BED000;
    $dark-purple: #471AB5;
    $button-color: #BED000;
    $blue: #CDDDFF;
    $black: #333333;
    $grey: #8D939F;
    $margin: 56px;
    $border-pagination: #F0F3FC;
    $blue-black: #321382;
    $success: #18C95D;

    .onboarding-layout {
        display: flex;
        height: 100vh;
        flex-direction: column;

        .content {
            flex: 1;
            height: 100%;

            .logo-img {
                flex: initial;
            }
        }

        .illustration-side {
            height: 100%;
            overflow-y: auto;
            margin: 0;
            order: 1;

            .content {
                padding: 72px 128px;
                padding-right: 190px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                @include desktop {
                    padding: 72px;
                }

                @include tablet {
                    padding: 48px;
                }

                @include mobileL {
                    padding: 25px 15px;
                }

                .pagination {
                    margin-bottom: 12px;
                    border-radius: 100%;
                    border: 1px solid $border-pagination;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 56px;
                    height: 56px;
                    box-sizing: border-box;
                    
                    .step {
                        color: $blue-black;
                        font-size: 12pt;
                        font-weight: 600;

                        &.last {
                            color: $success;
                        }
                    }
                }

                .title::v-deep {
                    margin-top: 32px;
                    margin-bottom: 16px;
                    font-size: 24pt;
                    
                    strong {
                        color: #1F1F1F;
                        font-size: 24pt;
                        font-weight: 600;
                    }
                }

                .description::v-deep {
                    font-weight: 400;
                    font-size: 10.5pt;
                    color: #525254;
                    margin-top: 16px;

                    strong {
                        font-weight: 700;
                        display: block;
                        color: #525254;
                    }
                }

                .separator {
                    margin-top: 36px;
                    background: #F0F3FC;
                    height: 1px;
                    width: 100%;
                    border: 0;
                }
            }

            .child {
                width: 100%;
                margin-top: 36px;

                &::v-deep {
                    .form-group {
                        align-items: initial;
                    }
                }
            }

            .reset-password p {
                @include body;
                padding: 32px 15px;
                margin-top: -30px;
                text-align: center;
                font-size: 12pt;
                border-top: 1px solid #E4E4E4;

                .link {
                    color: #578DFF;
                }
            }
        }

        .login-panel {
            background: $background;
            margin: 0;
            padding: 28px;

            .content {
                display: flex;
                align-items: center;
            }

            .header {
                height: 100%;
                display: flex;
                align-items: center;

                .illustration {
                    margin-left: $margin;
                    background-position: center left;
                    background-size: cover;
                    width: calc(100% - #{$margin});
                    height: 383px;
                }
            }

            .title {
                @include title;
                font-size: 39pt;
                font-weight: 700;
                color: white;
                margin-bottom: 16px;
            }

            .footer-credentials {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .powered_by {
                    display: flex;
                    align-items: center;
                    color: white;

                    .logo {
                        margin-left: 8px;
                    }
                }

                .credits {
                    color: white;
                }
            }
        }
    }
</style>