<template>

    <div class="download-viewer">

        <button-input class="see_all background-transparent green-text-color" :text="label" :iconImg="'download'" :fileId="fileId" :redirectExternalLink="target" :openExternalLink="openExternalLink" :noPadding="false" :downloadFileName="getFileName" v-if="fileId || target || openExternalLink" />

        <button-input class="see_all button background-phantom view" iconImg="view" iconColor="#525254" :redirectTo="redirectPath"/>

    </div>

</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                required: true
            },
            redirectPath: {
                type: String,
                required: true
            },
            customStyle: {
                type: Object,
                required: false
            },
            target: {
                type: String,
                required: false
            },
            fileId: {
                type: [Number, String],
                required: false
            },
            fileName: {
                type: String,
                required: false
            },
            openExternalLink: {
                type: String,
                required: false
            }
        },

        computed: {
            getFileName() {
                if (this.fileName === null || this.fileName === undefined) return this.label

                return this.fileName
            }
        }
    }
</script>

<style lang="scss">

    .download-viewer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .see_all {
            padding: 0;
        }
        .view {
            min-height: 24px;
            width: 24px;
            border-radius: 4px;
        }
    }

</style>
