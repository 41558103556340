<template>  

    <button-input class="see_all button icon-reverse background-transparent green-text-color small-padding" :text="label" iconImg="arrow-full-right" iconColor="#BED000" :noPadding="true" :redirectTo="redirectPath"/>

</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                required: true
            },
            redirectPath: {
                type: String,
                required: true
            },
            customStyle: {
                type: Object,
                required: false
            }
        }
    }
</script>

<style lang="scss">
    .see_all {
        padding: 0;
    }   
</style>
