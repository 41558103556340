export const strict = true

export const defaultState = () => {
    return {
        created_at: '',
        updated_at: '',
        id: '',
        relationships: {
            accounting_entries: {},
        }
    }
}

export const state = defaultState

export const mutations = {
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },

    setCreatedAt(state, value){
        state.created_at = value
    },

    setUpdatedAt(state, value){
        state.updated_at = value
    },

    setId(state, value) {
        state.id = value
    },

    setRelatedAccountingEntries(state, value){
        state.relationships.accounting_entries = value
    }
}

export const getters = {
    getCreatedAt(state){
        return state.created_at
    },

    getUpdatedAt(state){
        return state.updated_at
    },

    getId(state) {
        return state.id
    },

    getRelatedAccountingEntries(state){
        return state.relationships.accounting_entries
    }
}

export const actions = {
    async createAccountingEntrySet({ dispatch, getters }, { params }) {
        try {
            let request = {
                _jv: {
                    type: 'accounting-entry-sets'
                }
            }

            const accountingEntrySet = await dispatch('tyltoApi/post', [request, { url: `property-management-companies/${params.propertyManagerId}/accounting-entry-sets` }], { root: true })

            dispatch('fillAccountingEntrySet', accountingEntrySet)

            return accountingEntrySet

        } catch (error) {

            throw error
        }
    },

    async deleteAccountingEntrySet({ dispatch }, accountingEntrySetId){
        try {

            const request = {
                canceled_at: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
                _jv: {
                    id: accountingEntrySetId,
                    type: 'accounting-entry-sets'
                }
            }
                
            const accountingEntrySet = await dispatch('tyltoApi/patch', [request, { url: 'accounting-entry-sets/' + accountingEntrySetId, }], { root: true } )   
            
            return accountingEntrySet

        } catch (error) {

            throw error
        }

    },

    async fetchAccountingEntrySet({ dispatch, rootGetters }, accountingEntrySetId){
        try {
            
            let accountingEntrySet = rootGetters['tyltoApi/get'](`accounting-entry-sets/${accountingEntrySetId}`)

            if (typeof accountingEntrySet && !Object.keys(accountingEntrySet).length){
                
                accountingEntrySet = await dispatch('tyltoApi/get', 'accounting-entry-sets/' + accountingEntrySetId, { root: true } )   
            }

            return accountingEntrySet

        } catch (error) {

            throw error
        }
    },

    async fetchAccountingEntriesRelationship({ dispatch, getters, commit }, accountingEntrySetId){
        try {
            
            let accountingEntries = []

            const id = getters['getId'] !== '' ? getters['getId'] : accountingEntrySetId

            const { data } = await this.$axios({ method: 'get', url:`accounting-entry-sets/${id}/relationships/accounting_entries` })

            accountingEntries = await Promise.all(data['data'].map(accountingEntry => dispatch('accounting-entry/fetchAccountingEntry',accountingEntry.id, { root: true })))

            commit('setRelatedAccountingEntries', accountingEntries)

            return accountingEntries

        } catch (error) {

            throw error
        }
    },

    async fetchAndFillAccountingEntrySet({dispatch}, accountingEntrySetId){
        try {
            
            let accountingEntrySet = await dispatch('fetchAccountingEntrySet', accountingEntrySetId)

            await dispatch('fillAccountingEntrySet', accountingEntrySet)

            return accountingEntrySet

        } catch (error) {

            throw error
        }
    },

    async fillAccountingEntrySet({ commit }, accountingEntrySet){
        commit('resetDefaultState')

        if (typeof accountingEntrySet['created_at'] !== 'undefined')
            commit('setCreatedAt', accountingEntrySet['created_at'])
        
        if (typeof accountingEntrySet['updated_at'] !== 'undefined')
            commit('setUpdatedAt', accountingEntrySet['updated_at'])
        
        if (typeof accountingEntrySet['_jv'] !== 'undefined')
            commit('setId', accountingEntrySet['_jv'].id)
        
        if (typeof accountingEntrySet['accounting_entries'] !== 'undefined' && Object.keys(accountingEntrySet['accounting_entries']).length)
            commit('setRelatedAccountingEntries', accountingEntrySet['accounting_entries'])
    },

    async updateAccountingEntrySet({dispatch, getters}, {accountingEntries, accountingEntrySetId}){
        try {

            const id = accountingEntrySetId !== '' || accountingEntrySetId !== undefined ? accountingEntrySetId : getters['getId']
            let request = {
                data: accountingEntries.map(accountingEntry => {
                    return {
                        type: 'accounting-entries',
                        id: accountingEntry['_jv'].id
                    }
                })         
            }

            const accountingEntrySet = await this.$axios({method: 'patch', url:`accounting-entry-sets/${id}/relationships/accounting_entries`, data: request})

            await dispatch('fillAccountingEntrySet', accountingEntrySet)

            return accountingEntrySet
            
        } catch (error) {
            
            throw error
        }
    }
}
