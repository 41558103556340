<template>

    <div class="fees-input-list">

        <table-list :tHead="feesHeaders" :tBodies="feesRows" v-if="value.length" />

        <p v-else-if="!editMode">{{ $t('no_fees') }}</p>

        <hr class="separator" v-if="editMode && value.length">

        <step-nested-form class="new_fee_form" :title="feesId ? $t('update_fee') : $t('new_fees')" :itemCount="updateMode ? feeIndex : value.length" :onSubmit="createOrUpdateFee" :onDelete="deleteFee" :updateMode="updateMode" @close="resetRelatedFeesForm" :fields="{ newFeesType, amountType, newFeesAmountInCurrency, newFeesAmountInPercent, checkboxInputListNewFeesAppliesTo }" :open="editMode">

            <template v-slot="{ fields }">

                <form-info :text="$t('select_fees_type')" />

                <select-box-input class="fullsize" name="fees_type" :label="$t('fees_type')" :options="getRelatedFeesTypeOptions" :value="fields.newFeesType" @input="newFeesType = $event" :rules="feeTypesRules" :customErrorMessages="customErrorMessages" />

                <select-box-input name="fees_amount_type" :label="$t('fees_amount_type')" :options="getRelatedFeesAmountTypeOptions" :value="fields.amountType" @input="updateAmountType" :rules="'required'"/>

                <amount-input name="amount" :label="$t('amount')" :value="fields.newFeesAmountInCurrency" @input="newFeesAmountInCurrency = $event" :rules="'required'" v-if="amountType === 'currency'"/>

                <amount-input name="amount" :label="$t('amount')" :value="fields.newFeesAmountInPercent" @input="newFeesAmountInPercent = $event" :options="{ metric: '%', decimal: 2 }" :rules="'required|min_value:0|max_value:100'" v-else-if="amountType === 'percent'"/>

                <check-box-input-list class="fullsize" :label="$t('fees_applies_to')" name="fees_applies_to" :options="getAppliesToOptions" :value="fields.checkboxInputListNewFeesAppliesTo" @input="checkboxInputListNewFeesAppliesTo = $event" :rules="'required'" v-if="amountType === 'percent'" />

            </template>

        </step-nested-form>

        <button-input class="new_fees_action background-transparent green-text-color" :text="$t('new_fees')" iconImg="add_new" @click="initFeesForm" v-if="!editMode"/>

    </div>

</template>

<script>
    import CheckBoxInputList from '~/components/inputs-group/check-box-input-list'
    import FormInfo from '~/components/form-info'
    import StepGroup from '~/components/workflow/step-group'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            StepGroup,
            CheckBoxInputList,
            FormInfo
        },

        props: {
            value: {
                type: Array,
                required: true
            },
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            rules: {
                type: String,
                required: false
            },
            options: {
                type: Object,
                default() {
                    return {}
                }
            },
            defaultClose: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                editMode: false,
                updateMode: false,
                amountType: '',
                feeIndex: 0,
            }
        },

        mounted() {
            if (!this.value.length && !this.defaultClose) this.initFeesForm()
        },

        methods: {
            initFeesForm() {

                this.resetRelatedFeesForm()
                
                this.editMode = true

                this.amountType = 'percent'
            },

            resetRelatedFeesForm(close) {
                
                if (close) this.editMode = false

                this.updateMode = false

                this.amountType = ''

                this.$store.commit('fees/resetDefaultState')
            },

            updateAmountType(newValue) {
                
                this.amountType = newValue

                this.newFeesAmountInCurrency = 0
                
                this.newFeesAmountInPercent = 0
                
                this.newFeesAppliesTo = []
            },

            async createOrUpdateFee() {
                try {

                    if (this.updateMode) {
                        
                        await this.$store.dispatch('fees/updateFee')
                    
                    } else if (this.editMode) {

                        let newFeesFormat = {
                            _jv: {
                                type: 'fees'
                            },
                            type: this.newFeesType,
                            amount_type: this.amountType,
                            amount_in_currency: this.newFeesAmountInCurrency,
                            amount_in_percent: this.newFeesAmountInPercent,
                            applies_to: this.newFeesAppliesTo
                        }

                        await this.$store.dispatch('fees/fillFee', newFeesFormat)

                        await this.options.onSubmit.call()

                        this.$store.commit('fees/resetDefaultState')
                    }

                } catch(error) {

                    throw error
                
                } finally {

                    if (typeof this.options.afterSubmit !== 'undefined') {
                        
                        await this.options.afterSubmit.call()
                    }
                }
            },

            async deleteFee() {
                try {
                        
                    await this.$store.dispatch('fees/deleteFee', this.feesId)

                    this.resetRelatedFeesForm(true)

                } catch(error) {

                    throw error
                
                } finally {

                    if (typeof this.options.afterSubmit !== 'undefined') {
                        
                        await this.options.afterSubmit.call()
                    }
                }
            },

            updateLine(key) {
                
                this.newFeesType = this.value[key]['type']

                if (typeof this.value[key]['applies_to'] !== 'undefined') {
                    
                    this.newFeesAppliesTo = this.value[key]['applies_to']
                }

                if (typeof this.value[key]['amount_in_currency'] !== 'undefined' && this.value[key]['amount_in_currency'] > 0) {

                    this.amountType = 'currency'

                    this.newFeesAmountInCurrency = this.value[key]['amount_in_currency']
                }

                else if (typeof this.value[key]['amount_in_percent'] !== 'undefined' && this.value[key]['amount_in_percent'] > 0) {

                    this.amountType = 'percent'

                    this.newFeesAmountInPercent = this.value[key]['amount_in_percent']
                }

                if (typeof this.value[key]['_jv'] !== 'undefined' && typeof this.value[key]['_jv'].id !== 'undefined') {

                    this.$store.commit('fees/setId', this.value[key]['_jv'].id)
                }

                let copy = [...this.value]

                this.feeIndex = key

                this.$emit('input', copy)

                this.editMode = true

                this.updateMode = true
            }
        },

        computed: {
            ...mapGetters({
                type: 'fees/getType',
                feesId: 'fees/getId',
                propertyFees: 'property/getRelatedFees'
            }),

            feeTypesRules() {
                return { 
                    required: true, 
                    excluded: typeof this.options.excludeOptions !== 'undefined' ? this.options.excludeOptions.filter(option => option !== this.newFeesType) : ''
                }
            },

            newFeesType: {
                get() { return this.$store.getters['fees/getType'] },

                set(newValue) { this.$store.commit('fees/setType', newValue) }
            },

            newFeesAppliesTo: {
                get() {
                    return this.$store.getters['fees/getAppliesTo']
                },
                set(newValue) {
                    this.$store.commit('fees/setAppliesTo', newValue)
                }
            },

            checkboxInputListNewFeesAppliesTo: {
                get() {
                    if (this.newFeesAppliesTo.length) return this.newFeesAppliesTo.map(applyTo => {
                        return {
                            count: 1,
                            type: applyTo
                        }
                    })

                    else return [{
                        count: 1,
                        type: 'all'
                    }]
                },
                set(newValue) {

                    if (newValue.type === 'all') this.$store.commit('fees/setAppliesTo', [])

                    else {
                        
                        let index = this.newFeesAppliesTo.indexOf(newValue.type)

                        let copy = [...this.newFeesAppliesTo]

                        if (index >= 0 && newValue.count === 0) {

                            copy.splice(index, 1)

                        } else {

                            copy.push(newValue.type)
                        }

                        this.$store.commit('fees/setAppliesTo', copy)
                    }
                }
            },

            newFeesAmountInCurrency: {
                get() { return this.$store.getters['fees/getAmountInCurrency'] },

                set(newValue) {
                    this.$store.commit('fees/setAppliesTo', [])

                    this.$store.commit('fees/setAmountInCurrency', newValue)
                }
            },

            newFeesAmountInPercent: {
                get() { return this.$store.getters['fees/getAmountInPercent'] },

                set(newValue) { this.$store.commit('fees/setAmountInPercent', newValue) }
            },

            feesHeaders() {
                return [
                    {
                        label: this.$t('type'),
                        field: "type",
                        type: "text",
                        highlight: true,
                        width: 1.8
                    },
                    {
                        label: this.$t('amount'),
                        field: "amount",
                        type: "string",
                        highlight: true,
                        width: 0.8
                    },
                    {
                        label: this.$t('applies_to'),
                        field: "applies_to",
                        type: "text",
                    }
                ]
            },

            feesRows() {
                return this.value.map(fees => {
                    
                    let amount = fees.amount_in_percent !== '' && fees.amount_in_percent ? `${fees.amount_in_percent} %` : this.$n(fees.amount_in_currency / 100, 'currency')

                    let applies_to = ''
                    
                    if (typeof fees.applies_to !== 'undefined' && fees.applies_to.length) applies_to = fees.applies_to.map(apply => this.$t(`rental_item_types.${apply}`)).join(', ')

                    else if (typeof fees.amount_in_percent !== 'undefined' && fees.amount_in_percent > 0) applies_to = this.$t('rental_item_types.all')

                    return {
                        type: this.$t(`fees.${fees.type}`),
                        amount: amount,
                        applies_to: applies_to,
                        actions: {
                            default: this.updateLine
                        }
                    }
                })
            },

            getRelatedFeesTypeOptions() {
                return [
                    'everyday_management',
                    'visits_management_file_making_lease_agreement_drafting_tenant_share',
                    'visits_management_file_making_lease_agreement_drafting_landlord_share',
                    'checkin_inventory_tenant_share',
                    'checkin_inventory_landlord_share',
                    'matchmaking_and_negociation',
                    'checkout_inventory',
                    'rent_guarantee_insurance',
                    'maintenance_and_repairs',
                    'tax_return_filling_support',
                    'representation_in_general_meeting_of_co_owners',
                    'rental_insurance',
                    'additional_services'
                ]
                .map(option => {
                    return {
                        value: option,
                        text: this.$t(`fees.${option}`),
                    }
                })
            },

            getRelatedFeesAmountTypeOptions() {
                let fees = [
                    'currency',
                    'percent',
                ]

                return fees.map(option => {
                    return {
                        value: option,
                        text: this.$t(option)
                    }
                })
            },

            getAppliesToOptions() {
                let fees = [
                    "all",
                    "cleaning_charges",
                    "fixed_service_charges",
                    "guarantor_insurance",
                    "rent",
                    "rent_supplement",
                    "service_charges_adjustment",
                    "variable_service_charges"
                ]

                return fees.map(option => {
                    return {
                        value: option,
                        text: this.$t(`rental_item_types.${option}`)
                    }
                })
            },

            customErrorMessages() {
                return {
                    excluded: this.$t('excluded_error_message')
                }
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "no_fees": "Aucun honoraire prédéfini.",
            "type": "Type",
            "amount": "Montant",
            "fees_applies_to": "Application du montant de l'honoraire",
            "applies_to": "S'applique sur",
            "fees_type": "Type d'honoraire",
            "fees_amount_type": "Mode de calcul",
            "currency": "Montant fixe (€)",
            "new_fees": "Ajouter un honoraire",
            "update_fee": "Modifier un honoraire",
            "percent": "Pourcentage (%)",
            "select_fees_type": "Sélectionnez le type d'honoraire et le montant sur lequel il doit s'appliquer.",
            "add": "Nouveau",
            "modify": "Modifier",
            "delete": "Supprimer",
            "level": "Niveau",
            "level_options": {
                "property-management-companies": "Agence",
                "properties": "Bien",
                "rentals": "Contrat de location",
                "landlords": "Propriétaire"
            },
            "rental_item_types": {
                "all": "Toutes les sommes perçues"
            },
            "excluded_error_message": "Le type d'honoraire a déjà été enregistré à ce niveau."
        }
    }
</i18n>

<style lang="scss">
    .fees-input-list{
        .separator {
            margin: 27px auto;
            height: 1px;
            width: 72px;
            background: #DEE3EF;
            border-radius: 5px;
            border: none;
        }

        .new_fees_action {
            margin-top: 40px;
            padding-left: 0px;
        }
    }
</style>
