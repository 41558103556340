import { utils } from 'jsonapi-vuex'

export const strict = false

export const defaultState = () => {
    return {
        email_address: '',
        accept_url: 'onboarding/confirm_invitation',
        password: '',
        id: '',
        token: ''
    }
}

export const state = defaultState

export const mutations = {
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },

    setEmailAddress(state, value) {
        state.email_address = value
    },

    setAcceptUrl(state, value) {
        state.accept_url = value
    },

    setId(state, value) {
        state.id = Number(value)
    },

    setToken(state, value) {
        state.token = value
    },

    setPassword(state, value) {
        state.password = value
    }
}

export const getters = {    
    getEmailAddress(state) {
        return state.email_address
    },

    getAcceptUrl(state) {
        return state.accept_url
    },

    getId(state) {
        return Number(state.id)
    },

    getToken(state) {
        return state.token
    },

    getPassword(state) {
        return state.password
    }
}

export const actions = {
    async createInvitationToLandlord({ dispatch, getters, rootGetters }, emailAddress) {
        try {

            let invitation = {
                email_address: typeof emailAddress !== 'undefined' && emailAddress !== '' ? emailAddress : rootGetters['profile/getEmailAddress'],
                accept_url: `${window.location.origin}/${getters['getAcceptUrl']}`,
                _jv: {
                    type: 'invitations'
                }
            }

            const response = await dispatch('tyltoApi/post', [invitation, { url: `landlords/${rootGetters['profile/getId']}/invitations` }], { root: true })

            await dispatch('profile/fetchInvitationRelationshipToLandlord', null, { root: true })
            
            return response
        
        } catch(error) {

            throw error
        }
    },

    async createInvitationToTenant({ dispatch, getters, rootGetters }, emailAddress) {
        try {

            let invitation = {
                email_address: typeof emailAddress !== 'undefined' && emailAddress !== '' ? emailAddress : rootGetters['profile/getEmailAddress'],
                accept_url: `${window.location.origin}/${getters['getAcceptUrl']}`,
                _jv: {
                    type: 'invitations'
                }
            }

            const response = await dispatch('tyltoApi/post', [invitation, { url: `tenants/${rootGetters['profile/getId']}/invitations` }], { root: true })

            await dispatch('profile/fetchInvitationRelationshipToTenant', null, { root: true })
            
            return response
        
        } catch(error) {

            throw error
        }
    },

    async confirmInvitation({ dispatch, getters }) {
        try {

            let invitation = {
                is_accepted: true,
                password: getters['getPassword'],
                token: getters['getToken'],
                _jv: {
                    type: 'invitations',
                    id: getters['getId']
                }
            }

            await this.$axios({method: 'get', url: '/sanctum/csrf-cookie'})

            let { data } = await this.$axios({ method: 'patch', url: `invitations/${getters['getId']}`, headers: {'Authorization' : `Bearer ${getters['getToken']}`} , data: utils.normToJsonapi(invitation) })

            return utils.jsonapiToNorm(data['data'])

        } catch(error) {

            throw error
        }
    },

    async fetchInvitation({ dispatch, rootGetters }, invitationId) {
        try {

            let invitation = rootGetters['tyltoApi/get'](`invitations/${invitationId}`)

            if (typeof invitation === 'undefined' || !Object.keys(invitation).length) {
            
                invitation = await dispatch('tyltoApi/get', 'invitations/' + invitationId, { root: true })
            }

            return invitation

        } catch(error) {

            throw error
        }
    },

    async fetchAndFillInvitation({ dispatch }, invitationId) {
        try {

            let invitation = await dispatch('fetchInvitation', invitationId)

            await dispatch('fillInvitation', invitation)

            return invitation

        } catch(error) {

            throw error
        }
    },

    async fillInvitation({ commit }, invitation) {
        commit('resetDefaultState')

        if (typeof invitation['email_address'] !== 'undefined')
            commit('setEmailAddress', invitation['email_address'])

        if (typeof invitation['_jv'].id !== 'undefined')
            commit('setId', invitation['_jv'].id)        
    }
}