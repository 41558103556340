<template>
    
    <modal class="invite-profile-modal" :title="$t('send_invite')" :options="modalOptions" @submitted="hide" @canceled="hide" @close="hide" ref="modal">

        <template v-if="emailAddress">

            <form-info :text="$t('email_exist_text', { email: emailAddress })" />

            <check-box-input name="confirm_invit" :label="$t('confirm_invit_label')" :formGroupLabel="$t('confirmation')" v-model="confirmInvit" :rules="{ required: { allowFalse: false } }" />

        </template>

        <template v-else>

            <form-info :text="$t('email_not_exist_text')" />

            <text-input name="email_address" :label="$t('email_address')" :placeholder="$t('email_address_placeholder')" v-model="invitationEmail" :rules="{ required: true, email: { require_tld: false } }" />

        </template>

    </modal>

</template>

<script>
    import Modal from '~/components/modal'
    import FormInfo from '~/components/form-info'
    import { mapGetters } from 'vuex'

    export default {    
        components: {
            Modal,
            FormInfo
        },

        data() {
            return {
                confirmInvit: false,
                modalOptions: {
                    submit: {
                        action: () => this.sendInvitation()
                    }
                }
            }
        },

        props: {
            type: {
                type: String,
                required: true
            }
        },

        computed: {
            invitationEmail: {
                get() {
                    return this.$store.getters['invitation/getEmailAddress']
                },
                set(newValue) {
                    this.$store.commit('invitation/setEmailAddress', newValue)
                }
            },

            ...mapGetters({
                profileId: 'profile/getId',
                emailAddress: 'profile/getEmailAddress'
            })
        },

        methods: {
            show() {
                this.$refs.modal.show()
            },

            hide() {
                this.$store.commit('invitation/resetDefaultState')
                
                this.$refs.modal.hide()
            },

            async sendInvitation() {                
                try {
                    if (this.type === 'landlord') {
                        
                        await this.$store.dispatch('invitation/createInvitationToLandlord', this.invitationEmail)
                    }

                    else if (this.type === 'tenant') {
                        
                        await this.$store.dispatch('invitation/createInvitationToTenant', this.invitationEmail)
                    }

                    this.$toast.success(this.$t('invitation_successfuly_created', { email: this.emailAddress !== '' ? this.emailAddress : this.invitationEmail }), { icon: 'check' })

                } catch(errors) {

                    throw errors
                }
            }
        },

        onDestroy() {
            this.$store.commit('invitation/resetDefaultState')
        }
    }
</script>

<i18n>
    {
        "fr": {
            "send_invite": "Envoyer une invitation",
            "email_exist_text": "Une invitation par e-mail va être envoyée à l'adresse <b>{email}</b>. Le destinataire va devoir cliquer sur le lien présent dans l'e-mail pour se créer un compte sur Oskar et accéder à son espace personnel via un mot de passe sécurisé.",
            "email_not_exist_text": "Saisir l'adresse e-mail à laquelle vous souhaitez envoyer une invitation pour créer un espace personnel.",
            "confirm_invit_label": "J'ai vérifié l'adresse e-mail",
            "confirmation": "Confirmation",
            "email_address": "Adresse e-mail",
            "email_address_placeholder": "thierry.dupont@email.com",
            "invitation_successfuly_created": "Une invitation a été envoyée à l'adresse {email}."
        }
    }
</i18n>
