<template>

    <div :class="['energy-diagnoses', type]" v-html="label"></div>

</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                required: true
            },
            type: {
                type: String,
                required: true
            },
        },
    }
</script>

<style lang="scss" scoped>
    $A: #009c6d;
    $B: #52b153;
    $C: #77bd75;
    $D: #f4e70f;
    $E: #f0b50f;
    $F: #ec8236;
    $G: #d8221f;
    $grey: #8F94A0;

    .energy-diagnoses {
        @include body;
        width: 15%;
        white-space: nowrap;
        overflow: hidden;
        color: white;
        padding: 2px 7px;
        border-radius: 4px;
        text-align: center;

        &.A {
            background: $A;
        }

        &.B {
            background: $B;
        }

        &.C {
            background: $C;
        }

        &.D {
            background: $D;
        }

        &.E {
            background: $E;
        }

        &.F {
            background: $F;
        }

        &.G {
            background: $G;
        }

        &.grey {
            color: $grey;
        }
    }
</style>