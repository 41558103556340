<template>

    <div :class="['table-list-group', {'border' : border}]">

        <spinner-loader v-if="$fetchState.pending"/>

        <template v-else>

            <div class="header">

                <div class="left">

                    <h3 class="group-title">{{ fetchedData.title }}</h3>

                    <button-input class="group-action" @click="fetchedData.edit.call()" v-if="fetchedData.edit && !fetchedData.isCanceled && !fetchedData.CanceledTime" iconImg="edit" iconColor="#BED000"/>

                    <status-tag class="tag" type="upcoming" v-if="fetchedData.isCanceled">{{ fetchedData.canceledText }}</status-tag>

                    <status-tag class="tag" type="upcoming" v-if="fetchedData.CanceledTime">{{ $t('canceled_time', { date: $moment(fetchedData.CanceledTime).format('DD-MM-YYYY') }) }}</status-tag>

                </div>

                <h4 class="group-description">{{ fetchedData.description }}</h4>

            </div>

            <table-list class="table-first-rows" :tHead="tableListHeaders" :tBodies="getRows" :options="tableListOptions" />

            <table-body :uniqId="uniqId" class="totals" :tBody="fetchedData.totals" :tBodyKey="Number(fetchedData.id)" :tHead="tableListTotalHeaders" v-if="fetchedData.totals" />

            <button-input class="button background-transparent green-text-color icon-reverse" iconImg="arrow-full-right" iconColor="#BED000" :text="$t('see_all')" @click="redirectTo" v-show="disabled"/>

        </template>

    </div>

</template>

<script>
    import SpinnerLoader from '~/components/spinner-loader'
    import StatusTag from '~/components/status-tag'

    export default {
        components: {
            SpinnerLoader,
            StatusTag
        },

        async fetch() {

            this.fetchedData = await this.data.call()
        },

        data() {
            return {
                fetchedData: {},
                uniqId: null
            }
        },

        props: {
            data: {
                type: Function,
                required: true
            },
            tableListHeaders: {
                type: Array,
                required: true
            },
            tableListOptions: {
                type: Object,
                required: false
            },
            disabled:{
                type: Boolean,
                required: false,
                default: true
            },
            border:{
                type: Boolean,
                required: false,
                default: true
            }
        },

        beforeMount() {
            this.uniqId = crypto.randomUUID().replaceAll('-', '');
        },

        methods: {
            redirectTo(){
                this.$router.push(`/dashboard/accounting/accounting-entry-set/${this.fetchedData.id}`)
            }
        },

        computed: {
            getRows() {
                if (!Object.keys(this.fetchedData).length) return []

                return this.fetchedData.firstRows
            },

            tableListTotalHeaders() {
                return this.tableListHeaders.map((header, key) => {
                    if (key === 0) return Object.assign({...header}, {
                        type: 'string',
                        field: 'title',
                        highlight: false
                    })

                    
                    if (key === 4 || key === 5) {
                        return Object.assign({...header}, {
                            type: 'status',
                        })
                    }

                    return Object.assign({...header}, {
                        type: 'string',
                        highlight: false
                    })
                })
            }
        },

        watch: {
            data() {
                return this.$fetch()
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "see_all": "Voir toutes les écritures",
            "canceled_time": "Annulé le {date}"
        }
    }
</i18n>

<style lang="scss" scoped>
    .border{
        border: 1px solid #F2F2F2;
        border-radius: 16px;
    }
    .table-list-group {
        padding: 24px 18px;
        margin-bottom: 12px;
        display: flex;
        flex-direction: column;

        .header{
            display: flex;
            gap: 1.5rem;
            padding-bottom: 1.3rem;

            .left{
                display: flex;
                gap: .5rem;
                align-items: center;
                justify-content: center;
                .group-title{
                    font-weight: 600;
                    font-size: 16px;
                }
                .group-action{
                    min-height: 0;
                    padding: 6px;
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .button{
            width: fit-content;
            align-self: center;
        }
    }
</style>
