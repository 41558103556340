export default {
    getAddress(state) {
        return state.address
    },

    getAmountOfSecurity(state) {
        return state.amount_of_security
    },

    getBusinessCardNumber(state) {
        return state.business_card_number
    },

    getBusinessCardPlaceOfIssue(state) {
        return state.business_card_place_of_issue
    },

    getBusinessName(state) {
        return state.business_name
    },

    getCreatedAt(state) {
        return state.created_at
    },

    getIncorporationDate(state) {
        return state.incorporation_date
    },

    getManagementAndOwnership(state) {
        return state.management_and_ownership
    },

    getEmailAddress(state) {
        return state.email_address
    },

    getPhoneNumber(state) {
        return state.phone_number
    },

    getIdentificationNumber(state) {
        return state.identification_number
    },

    getId(state) {
        return state.id
    },

    getTradeName(state) {
        return state.trade_name
    },

    getShareCapital(state) {
        return state.share_capital
    },

    getIsEmailNotificationEnabled(state) {
        return state.is_email_notification_enabled
    },

    getSwanOnboarding(state) {
        return state.swan_onboarding
    },

    getSwanAccountHolder(state) {
        return state.swan_account_holder
    },

    getStripeSubscription(state) {
        return state.stripe_subscription
    },

    getWebBanking(state) {
        return state.web_banking
    },

    getAccountingCode(state) {
        return state.accounting_code
    },

    getDefaultFeeVatRate(state) {
        return state.default_fee_vat_rate
    },

    getRelatedBankAccounts(state) {
        return state.relationships.bank_accounts
    },

    getRelatedFees(state) {
        return state.relationships.fees
    },

    getRelatedMandates(state) {
        return state.relationships.mandates
    },

    getRelatedGuaranteeFund(state) {
        return state.relationships.guarantee_fund
    },

    getRelatedLogo(state) {
        return state.relationships.logo
    },

    getRelatedMediator(state) {
        return state.relationships.mediator
    },

    getRelatedPaymentMethod(state) {
        return state.relationships.payment_method
    }
}
