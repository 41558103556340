<template>

    <form-group class="card-stripe-element" :label="label" :name="name" :rules="rules" ref="formGroup" v-slot="{ errors, setErrors }">

        <div class="proxy" :value="cardElementError" @change="setErrors([cardElementError])" v-show="false" />

        <input type="text" :value="state" v-show="false" />
    
        <div :class="['element', { 'errors': errors.length }]" ref="card" />
    
    </form-group>

</template>

<script>
    import FormGroup from '~/components/inputs/form-group'

    export default {
        components: { 
            FormGroup
        },
        
        props: {
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            value: {
                type: [String, Object],
                required: true
            },
            rules: {
                type: [String, Object],
                default: ''
            }
        },

        mounted() {
            const element = this.$stripeJs.createElement('card', this.style)
            
            element.mount(this.$refs.card)

            element.on('change', async ({ complete, error }) => {

                this.state = complete ? element : ''
                
                this.$emit('input', complete ? element : '')
                
                this.cardElementError = error ? error.message : ''
            })
        },

        data() {
            return {
                state: '',
                cardElementError: '',
                style: {
                    base: {
                        color: '#0E0A07',
                        fontSize: '11pt',
                        lineHeight: '46px',
                        fontWeight: '300'
                    },
                    invalid: {
                        iconColor: '#F5A623',
                        color: '#0E0A07',
                    }
                }
            }
        }
    }
</script>

<style lang="scss">
    $input-height: 50px;
    $border-color: #DEE3EF;

    .card-stripe-element {
        .element {
            height: $input-height;
            border: 2px solid $border-color;
            border-radius: 10px;
            padding: 0 20px;
            font-size: 11pt;
            box-sizing: border-box;

            &.errors {
                background-color: #FBE8E8;
                border-color: #F5A623;
            }
        }
    }
</style>