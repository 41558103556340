<template>
    <svg :style="margin" :width="width" :height="height" viewBox="0 0 142 145" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.3" d="M103.479 128.817C120.239 103.898 138.91 116.901 141.613 88.739C144.857 -49.3206 -17.0402 30.8414 12.7441 69.276C24.0976 88.2475 8.41903 106.089 21.3944 125.552C34.3697 145.015 86.7189 153.736 103.479 128.817Z" fill="#EDE8F8" stroke="#EDE8F8" stroke-width="0.0982981"/>
        <path d="M125.87 143.626H20.085V144.357H125.87V143.626Z" fill="#3F3D56"/>
        <path d="M26.6027 81.7247C26.5197 81.5955 26.4507 81.4577 26.3971 81.3137L24.7115 76.7998C24.5153 76.2725 24.5363 75.689 24.7698 75.1771C25.0033 74.6653 25.4302 74.267 25.957 74.0695L63.5795 60.0212C64.1067 59.8249 64.6903 59.8458 65.2021 60.0793C65.714 60.3128 66.1123 60.7398 66.3097 61.2666L67.9951 65.7808C68.0926 66.0419 68.1378 66.3196 68.1279 66.5981C68.1181 66.8765 68.0535 67.1504 67.9378 67.4039C67.8221 67.6574 67.6577 67.8857 67.4538 68.0756C67.2499 68.2656 67.0106 68.4135 66.7495 68.511L29.1275 82.5593C28.6745 82.7282 28.1774 82.7376 27.7184 82.5859C27.2593 82.4341 26.8658 82.1304 26.6027 81.7247Z" fill="#EDE8F8"/>
        <path d="M63.4603 58.8548L48.1612 64.9555C47.6791 65.1005 47.2713 65.4255 47.0225 65.8632C46.7737 66.3008 46.703 66.8175 46.825 67.3059L48.6004 74.4147C48.6706 74.6955 48.8021 74.9573 48.9855 75.1812C49.1689 75.4051 49.3996 75.5856 49.6611 75.7097C49.9226 75.8338 50.2084 75.8984 50.4978 75.8988C50.7872 75.8993 51.0732 75.8356 51.3351 75.7123L66.6764 68.4914C67.1624 68.309 67.5563 67.9415 67.7719 67.4693C67.9876 66.9972 68.0074 66.4588 67.827 65.972L65.9828 60.0057C65.8006 59.5189 65.4326 59.1243 64.9597 58.9085C64.4867 58.6927 63.9475 58.6734 63.4603 58.8548Z" fill="#2F2E41"/>
        <path d="M91.458 103.143C90.9776 103.198 90.4929 103.088 90.0838 102.83C89.6747 102.573 89.3657 102.183 89.2079 101.726L76.1121 63.7619C76.0212 63.4985 75.9831 63.2197 76 62.9416C76.0168 62.6634 76.0883 62.3913 76.2103 62.1408C76.3323 61.8903 76.5025 61.6662 76.7111 61.4814C76.9197 61.2967 77.1626 61.1548 77.4261 61.064L81.9812 59.4925C82.5132 59.3094 83.0962 59.345 83.602 59.5914C84.1078 59.8378 84.4952 60.2749 84.679 60.8067L97.775 98.7711C97.9578 99.3031 97.9221 99.886 97.6757 100.392C97.4294 100.898 96.9925 101.285 96.4609 101.469L91.9059 103.04C91.7607 103.09 91.6105 103.125 91.458 103.143Z" fill="#EDE8F8"/>
        <path d="M85.6556 60.1576L90.6817 75.8426C90.8765 76.3068 90.8852 76.8282 90.7058 77.2986C90.5264 77.769 90.1728 78.1523 89.7184 78.3689L83.1041 81.5215C82.8429 81.6461 82.5572 81.7112 82.2678 81.7121C81.9783 81.7131 81.6923 81.6499 81.4302 81.5271C81.1681 81.4043 80.9365 81.2249 80.752 81.0019C80.5675 80.7788 80.4348 80.5177 80.3633 80.2372L76.1746 63.807C76.0061 63.316 76.039 62.7782 76.2661 62.3115C76.4933 61.8447 76.8961 61.4869 77.3864 61.3165L83.1628 58.9436C83.6544 58.7745 84.193 58.8074 84.6603 59.035C85.1277 59.2626 85.4856 59.6664 85.6556 60.1576Z" fill="#2F2E41"/>
        <path d="M70.4374 143.841H65.6191C65.0565 143.841 64.5171 143.617 64.1193 143.219C63.7215 142.821 63.4977 142.282 63.4971 141.719V101.56C63.4977 100.997 63.7215 100.458 64.1193 100.06C64.5171 99.6619 65.0565 99.4382 65.6191 99.4375H70.4374C71 99.4382 71.5394 99.6619 71.9372 100.06C72.335 100.458 72.5588 100.997 72.5595 101.56V141.719C72.5588 142.282 72.335 142.821 71.9372 143.219C71.5394 143.617 71 143.841 70.4374 143.841Z" fill="#2F2E41"/>
        <path d="M79.9679 143.841H75.1494C74.5868 143.841 74.0474 143.617 73.6496 143.219C73.2518 142.821 73.028 142.282 73.0273 141.719V101.56C73.028 100.997 73.2518 100.458 73.6496 100.06C74.0474 99.6619 74.5868 99.4382 75.1494 99.4375H79.9679C80.5305 99.4382 81.0698 99.6619 81.4677 100.06C81.8655 100.458 82.0892 100.997 82.0899 101.56V141.719C82.0892 142.282 81.8655 142.821 81.4677 143.219C81.0698 143.617 80.5305 143.841 79.9679 143.841Z" fill="#2F2E41"/>
        <path d="M83.9385 106.281H62.045C61.4391 106.28 60.8583 106.039 60.4298 105.611C60.0014 105.182 59.7604 104.601 59.7598 103.996V68.4292C59.7598 64.9199 61.1538 61.5543 63.6353 59.0728C66.1168 56.5913 69.4824 55.1973 72.9917 55.1973C76.5011 55.1973 79.8667 56.5913 82.3482 59.0728C84.8296 61.5543 86.2237 64.9199 86.2237 68.4292V103.996C86.223 104.601 85.9821 105.182 85.5536 105.611C85.1252 106.039 84.5443 106.28 83.9385 106.281Z" fill="#883BFF"/>
        <path d="M87.2345 114.982H76.1348V52.25L76.341 52.3063C79.467 53.1644 82.2253 55.0241 84.193 57.6003C86.1607 60.1765 87.2291 63.327 87.2345 66.5686V114.982Z" fill="#2F2E41"/>
        <path d="M68.9474 114.982H57.8477V66.5686C57.8531 63.327 58.9215 60.1765 60.8892 57.6003C62.8569 55.0242 65.6152 53.1644 68.7412 52.3063L68.9474 52.25V114.982Z" fill="#2F2E41"/>
        <path d="M72.8265 47.8406C82.4761 47.8406 90.2986 40.0181 90.2986 30.3685C90.2986 20.719 82.4761 12.8965 72.8265 12.8965C63.177 12.8965 55.3545 20.719 55.3545 30.3685C55.3545 40.0181 63.177 47.8406 72.8265 47.8406Z" fill="#EDE8F8"/>
        <path d="M92.5798 26.3455C92.5798 26.3455 93.5592 -0.75094 80.8271 8.06357C80.8271 8.06357 73.4816 -16.5844 55.5262 20.306L50.3027 25.5294C50.3027 25.5294 73.8081 22.2647 95.6812 32.3851L92.5798 26.3455Z" fill="#2F2E41"/>
        <path d="M73.093 39.0212C72.0137 38.9912 70.6706 38.9537 69.6358 38.1978C69.3276 37.9635 69.0745 37.6646 68.894 37.3222C68.7135 36.9797 68.6101 36.6019 68.591 36.2153C68.5723 35.9405 68.6174 35.6651 68.7228 35.4107C68.8281 35.1562 68.9909 34.9295 69.1984 34.7484C69.7388 34.2917 70.5281 34.1846 71.3786 34.4346L70.4974 27.9949L71.1442 27.9062L72.1802 35.4769L71.64 35.229C71.0138 34.9418 70.1541 34.7956 69.6198 35.2471C69.4887 35.3651 69.3865 35.5118 69.3212 35.6757C69.256 35.8397 69.2294 36.0164 69.2435 36.1923C69.2594 36.4801 69.337 36.7611 69.4711 37.0161C69.6053 37.2712 69.7928 37.4944 70.0209 37.6705C70.8262 38.2587 71.8968 38.3346 73.1112 38.3685L73.093 39.0212Z" fill="#2F2E41"/>
        <path d="M67.2978 28.5488H63.7812V29.2018H67.2978V28.5488Z" fill="#2F2E41"/>
        <path d="M78.3974 28.5488H74.8809V29.2018H78.3974V28.5488Z" fill="#2F2E41"/>
        <path d="M16.746 57.9276C23.9581 57.9276 29.8046 52.0811 29.8046 44.8691C29.8046 37.6571 23.9581 31.8105 16.746 31.8105C9.53401 31.8105 3.6875 37.6571 3.6875 44.8691C3.6875 52.0811 9.53401 57.9276 16.746 57.9276Z" fill="white"/>
        <path d="M33.8709 83.3892C33.3997 83.6078 32.8609 83.6303 32.373 83.4517C31.8852 83.273 31.4883 82.908 31.2697 82.4367L20.0031 58.153C19.7845 57.6818 19.7621 57.143 19.9408 56.6553C20.1194 56.1675 20.4845 55.7707 20.9557 55.552C21.4269 55.3334 21.9656 55.3109 22.4534 55.4895C22.9412 55.6681 23.3381 56.0331 23.5568 56.5043L34.8234 80.788C35.042 81.2592 35.0645 81.798 34.8859 82.2858C34.7073 82.7737 34.3422 83.1705 33.8709 83.3892Z" fill="#3F3D56"/>
        <path d="M22.6042 59.1058C19.7928 60.4102 16.6577 60.8516 13.5954 60.3742C10.5331 59.8968 7.68113 58.522 5.40015 56.4238C3.11917 54.3255 1.51162 51.598 0.780778 48.5861C0.049941 45.5743 0.228643 42.4133 1.29429 39.503C2.35993 36.5926 4.26464 34.0637 6.76757 32.2359C9.27051 30.4081 12.2592 29.3635 15.3558 29.2343C18.4524 29.1051 21.5178 29.8971 24.1643 31.51C26.8108 33.1229 28.9196 35.4844 30.224 38.2959C31.9682 42.0666 32.1455 46.3751 30.7171 50.2764C29.2886 54.1777 26.3709 57.353 22.6042 59.1058ZM11.0628 34.2298C8.95424 35.2081 7.1831 36.7897 5.97339 38.7746C4.76368 40.7594 4.16975 43.0585 4.26665 45.3809C4.36355 47.7034 5.14695 49.9449 6.5178 51.8221C7.88865 53.6993 9.78539 55.1278 11.9681 55.9271C14.1509 56.7263 16.5216 56.8603 18.7805 56.3122C21.0394 55.7641 23.0851 54.5584 24.6587 52.8477C26.2324 51.1369 27.2635 48.998 27.6215 46.7013C27.9796 44.4045 27.6485 42.0532 26.6703 39.9446C25.3557 37.1196 22.9742 34.9314 20.0482 33.86C17.1223 32.7886 13.8908 32.9216 11.0628 34.2298Z" fill="#3F3D56"/>
    </svg>

</template>
<script>
    export default{

        data(){
            return {
                margin: 'margin: 0px auto 0px auto;'
            }
        },
        
        props: {
            center: {
                type: Boolean
            },
            width:{
                type: String
            },
            height: {
                type: String
            }
        },
        
        watch:{
            center(newCount){
                newCount ? this.margin = 'margin: 0px auto 0px auto;' : this.margin = 'margin: 0px 15px 0px 0px'
            }
        }
    }
</script>
