<template>

    <overlay>

        <div class="preview-pdf">

            <div class="pdf-viewer-container">

                <div class="icon" @click="$emit('close', true)">

                    <svg-icon :viewbox="'0 0 32 32'" :width="'32'" :height="'32'"><close-icon /></svg-icon>

                </div>

                <document-viewer class="pdf-viewer" :fileUrl="file.url" :mimes="file.mime_type" :arrow="false" @clickOutside="$emit('close', true)" />

            </div>

        </div>

    </overlay>

</template>

<script>
    import SvgIcon from '~/components/svg-icon'
    import CloseIcon from '~/components/icons/button-icons/close-icon'
    import Overlay from '~/components/overlay'
    import DocumentViewer from '~/components/document-viewer'

    export default {
        components: {
            SvgIcon,
            CloseIcon,
            DocumentViewer,
            Overlay
        },

        props: {
            file: {
                type: Object,
                required: true
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "open": "Ouvrir"
        }
    }
</i18n>

<style lang="scss" scoped>
    .preview-pdf {
        z-index: 101;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .pdf-viewer-container {
            scrollbar-width: none;
            min-width: 100%;
            height: 98vh;
            border-radius: 5px;

            .pdf-viewer {
                max-width: 100%;
                width: 100%;
                height: 100%;
                overflow: auto;
            }
        }

        .icon {
            position: fixed;
            top: 20px;
            right: 20px;
            cursor: pointer;
        }
    }
</style>
