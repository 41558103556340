<template>

    <svg width="19" height="19" viewBox="0 0 16 17" :stroke="stroke" fill="none">

        <path d="M11.3327 6.71667L7.04398 11.0054C6.88126 11.1681 6.61744 11.1681 6.45472 11.0054L4.66602 9.21667" stroke="#BED000" stroke-width="1.5" stroke-linecap="round"/>
        
        <rect x="0.75" y="1.25" width="14.5" height="14.5" rx="7.25" stroke="#BED000" stroke-width="1.5" />
    
    </svg>

</template>

<script>

    export default{

        props: {
            stroke: {
                type: String,
                default: 'white'
            }
        }
    }

</script>
