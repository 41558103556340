export default () => {
    return {
        amount: 0,
        date: '',
        description: '',
        id: '',
        invoice_number: '',
        items: [],
        payout_date: '',
        vat_amount: 0,
        payout_reserved_at: '',
        relationships: {
            invoice: {},
            supplier: {}
        }
    }
}
