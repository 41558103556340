import defaultState from './default'

export default {
    addField(state, data) {
        if (!state.fields.map(field => field.name).includes(data.name)) {
            state.fields.push(data)
        }
    },

    addOrUpdateStep(state, value) {
        // Create new step
        let stepsCopy = [...state.steps]

        // Get index of current step
        let stepIndex = state.steps.map(step => step.name).indexOf(value.name)

        // If step already exists, update it
        if (stepIndex >= 0) {

            let index = state.steps.map(step => step.name).indexOf(value.name)

            stepsCopy[index] = Object.assign(state.steps[index], value)
        }

        // Else add new step
        else stepsCopy.push(value)

        // Update state
        state.steps = stepsCopy

        // Set first step as active
        if (state.steps.length === 1) state.steps[0].active = true
    },

    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },

    setApiValidationErrors(state, value) {
        state.api_validation_errors = value
    },

    setActiveStep(state, code) {
        for (let step of state.steps) {
            step.active = step.name === code ? true : false
        }
    },

    setDumpName(state, value) {
        state.dump_name = value
    },

    setCreateMode(state) {
        state.mode = 'create'
    },

    setEditMode(state) {
        state.mode = 'edit'
    },

    setUpdateMode(state) {
        state.mode = 'update'
    },

    setErrorMessage(state, value) {
        state.error.message = value
    },

    setErrorStatusCode(state, value) {
        state.error.status_code = value
    },

    setErrorFields(state, value) {
        state.error.fields = value
    },

    setExternalFileFormData(state, value) {
        state.external_file.formdata = value
    },

    setExternalFile(state, value) {
        state.external_file.file = value
    },

    setExternalFileActive(state, value) {
        state.external_file.is_active = value
    },

    setExternalFileName(state, value) {
        state.external_file.file_name = value
    },

    setExternalFileIsSigned(state, value) {
        state.external_file.is_signed = value
    },

    setExternalFileTypes(state, value) {
        state.external_file.types = value
    },

    setExternalFilePreview(state, value) {
        state.external_file.preview = value
    },

    setLeaseAgreementIsSigned(state, value) {
        state.lease_agreement_is_signed = value
    },

    setLoading(state, value) {
        state.loading = value
    },

    setCompletedStep(state, code) {
        for (let step of state.steps) {
            if (step.name === code) step.complete = true
        }
    },

    setUncompletedStep(state, code) {
        for (let step of state.steps) {
            if (step.name === code) step.complete = false
        }
    },

    setRedirectAction(state, value) {
        state.redirect_action = value
    },

    setSaveAction(state, value) {
        state.save_action = value
    },

    setStatus(state, value) {
        state.status = value
    },

    setTitle(state, value) {
        state.title = value
    }
}
