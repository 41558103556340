import Vue from 'vue'
import { ValidationObserver, ValidationProvider, extend, setInteractionMode } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/fr.json'
import * as moment from 'moment'

Vue.component('ValidationProvider', ValidationProvider)

Vue.component('ValidationObserver', ValidationObserver)

export default ({ app }) => {

    const i18n = app.i18n

    extend('not_empty', {
        validate: value => Object.keys(value).length > 0,
        message: (_, values) => i18n.t('validate.object.not_empty', values)
    })

    extend('date_is_valid',  {
        params: ["format"],
        validate: (value, { format }) => {

            let dateFormat = typeof format !== 'undefined' ? format : 'YYYY-MM-DD'

            if (value === '--02-29') {
                return false
            }

            return moment(value, dateFormat, true).isValid()
        },
        message: (_, values) => i18n.t('validate.date.valid', values)
    })

    extend("date_after", {
        params: ["limit", "included"],
        validate: (value, { limit, included }) => {

            let formatValue = moment(value, 'YYYY-MM-DD')

            let formatLimit = moment(limit, 'YYYY-MM-DD')

            return included
                ? formatValue.isSameOrAfter(formatLimit)
                : formatValue.isAfter(formatLimit)
        },
        message: (_, values) => i18n.t('validate.date.after', values)
    })

    extend("date_before", {
        params: ["limit", "included"],
        validate: (value, { limit, included }) => {

            let formatValue = moment(value, 'YYYY-MM-DD')

            let formatLimit = moment(limit, 'YYYY-MM-DD')

            return included
                ? formatValue.isSameOrBefore(formatLimit)
                : formatValue.isBefore(formatLimit)
        },
        message: (_, values) => i18n.t('validate.date.before', values)
    })

    extend("equal_password", {
        params: ["compareValue"],
        validate: (value, { compareValue }) => {
            return value === compareValue
        },
        message: (_,) => i18n.t('validate.password_doesnt_match')
    })

    extend("step_nested_form", {
        validate: (value) => {
            return value === true
        },
        message: (_,) => i18n.t('validate.step_nested_form_must_be_close')
    })

    extend("max_number", {
        params: ["max"],
        validate: (value, { max }) => {
            return value <= max
        },
        message: (_, values) => i18n.t('validate.max_number', values)
    })

    extend('exact_length', {
        params: ['length'],
        validate(value, { length }) {
            return `${value}`.length === Number(length);
        },
        message: (fieldName, { length }) => i18n.t('validate.exact_length', { _field_: fieldName, length })
    });

    Object.keys(rules).forEach(rule => {
        extend(rule, {
            ...rules[rule],
            message: messages[rule]
        })
    })

    setInteractionMode('aggressive')
}
